import { LABELS } from '../../../utils/enums/labels';
import { ADVANCED_SETTINGS_KEYS } from './constants';
import { renderMaxLengthField, renderMinLengthField } from './renderFields';

const AdvancedNumberFields = (data, setFields, setIsError) => ({
  row1: [
    {
      label: LABELS.FIELD_PROPERTIES.PLACEHOLDER,
      value: `${data?.[ADVANCED_SETTINGS_KEYS.PLACEHOLDER] || ''}`,
      valueKey: ADVANCED_SETTINGS_KEYS.PLACEHOLDER,
      placeholder: '',
      onChange: (value) =>
        setFields((ps) => ({
          ...ps,
          [ADVANCED_SETTINGS_KEYS.PLACEHOLDER]: value,
        })),
    },
    {
      label: LABELS.FIELD_PROPERTIES.HINT_TEXT,
      value: `${data[ADVANCED_SETTINGS_KEYS.HINT_TEXT] || ''}`,
      valueKey: ADVANCED_SETTINGS_KEYS.HINT_TEXT,
      placeholder: '',
      onChange: (value) =>
        setFields((ps) => ({
          ...ps,
          [ADVANCED_SETTINGS_KEYS.HINT_TEXT]: value,
        })),
    }
  ],
  row2: [
    renderMinLengthField({
      data,
      setFields,
      setIsError,
      label: LABELS.FIELD_PROPERTIES.MIN_VALUE,
    }),
    renderMaxLengthField({
      data,
      setFields,
      setIsError,
      label: LABELS.FIELD_PROPERTIES.MAX_VALUE,
    }),
  ],
  row3: [
    {
      label: LABELS.FIELD_PROPERTIES.REQUIRED,
      value: `${data?.[ADVANCED_SETTINGS_KEYS.REQUIRED] || ''}`,
      valueKey: ADVANCED_SETTINGS_KEYS.REQUIRED,
      onChange: (value) =>
        setFields((ps) => ({
          ...ps,
          [ADVANCED_SETTINGS_KEYS.REQUIRED]: value,
        })),
    },
    // {
    //   label: LABELS.FIELD_PROPERTIES.UNIQUE,
    //   value: `${data?.[ADVANCED_SETTINGS_KEYS.UNIQUE] || ''}`,
    //   valueKey: ADVANCED_SETTINGS_KEYS,
    //   onChange: (value) =>
    //     setFields((ps) => ({ ...ps, [ADVANCED_SETTINGS_KEYS.UNIQUE]: value })),
    // },
    // {
    //   label: LABELS.FIELD_PROPERTIES.READONLY,
    //   value: `${data?.[ADVANCED_SETTINGS_KEYS.READ_ONLY] || ''}`,
    //   valueKey: ADVANCED_SETTINGS_KEYS.READ_ONLY,
    //   onChange: (value) =>
    //     setFields((ps) => ({
    //       ...ps,
    //       [ADVANCED_SETTINGS_KEYS.READ_ONLY]: value,
    //     })),
    // },
    // {
    //   label: LABELS.FIELD_PROPERTIES.HIDDEN,
    //   value: `${data?.[ADVANCED_SETTINGS_KEYS.HIDDEN] || ''}`,
    //   valueKey: ADVANCED_SETTINGS_KEYS.HIDDEN,
    //   onChange: (value) =>
    //     setFields((ps) => ({ ...ps, [ADVANCED_SETTINGS_KEYS.HIDDEN]: value })),
    // },
    {
      label: LABELS.FIELD_PROPERTIES.SEARCHABLE,
      value: `${data?.[ADVANCED_SETTINGS_KEYS.SEARCHABLE] || ''}`,
      valueKey: ADVANCED_SETTINGS_KEYS.SEARCHABLE,
      onChange: (value) =>
        setFields((ps) => ({
          ...ps,
          [ADVANCED_SETTINGS_KEYS.SEARCHABLE]: value,
        })),
    },
    {
      label: LABELS.FIELD_PROPERTIES.REPORTABLE,
      value: `${data?.[ADVANCED_SETTINGS_KEYS.REPORTABLE] || ''}`,
      valueKey: ADVANCED_SETTINGS_KEYS.REPORTABLE,
      onChange: (value) =>
        setFields((ps) => ({
          ...ps,
          [ADVANCED_SETTINGS_KEYS.REPORTABLE]: value,
        })),
    },
  ],
  row4: [
    // {
    //   label: LABELS.FIELD_PROPERTIES.METRICS,
    //   value: `${data?.[ADVANCED_SETTINGS_KEYS.METRICS] || ''}`,
    //   placeholder: '',
    //   onChange: (value) =>
    //     setFields({ ...data, [ADVANCED_SETTINGS_KEYS.METRICS]: value }),
    // },
  ],
});

export default AdvancedNumberFields;
