import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Flex,
  IconButton,
  WrapItem,
  useColorModeValue,
} from '@chakra-ui/react';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { COLORS } from '../../utils/enums/colors';

const CustomWrapItemWithRemoveAction = ({ wrapTitle, onRemoved }) => (
  <WrapItem>
    <Flex
      justifyContent="center"
      alignItems="center"
      bg={useColorModeValue(COLORS.WRAP_BG_LIGHT, COLORS.WRAP_BG_LIGHT)}
      p="10px 15px"
      borderRadius="10px"
      fontWeight={500}
      minH="28px"
      maxW="600px"
      maxH="70px"
      color={COLORS.WRAP_COLOR_LIGHT}
      data-testid="RightAndLeftWithAddButtonWithTazBoxFlex"
    >
      <Box data-testid="RightAndLeftWithAddButtonWithTazQBox">{wrapTitle}</Box>
      <IconButton
        background="transparent"
        icon={<SmallCloseIcon color={COLORS.WRAP_COLOR_LIGHT} />}
        onClick={onRemoved}
        data-testid="RightAndLeftWithAddButtonWithTazIconBtn"
        w="20px"
        h="20px"
        pl="20px"
      />
    </Flex>
  </WrapItem>
);

CustomWrapItemWithRemoveAction.propTypes = {
  wrapTitle: PropTypes.string.isRequired,
  onRemoved: PropTypes.func.isRequired,
};

export default CustomWrapItemWithRemoveAction;
