/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Mention, MentionsInput } from 'react-mentions';
import { Box, useColorModeValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { isEmpty } from 'lodash';
import defaultStyle, { darkMentionDefaultStyle } from './mentionDefaultStyle';
import { getWholeOption } from '../../pages/TicketTemplateWorkflow/helper';
import axiosInstance from '../../utils/axios/axios';
import { convertFieldType } from '../../pages/MasterScreen/ConfigJson/helpers';
import { FieldTypes } from '../../utils/enums/types';
import { getMentionOptionLabel } from '../../pages/CommunicationTemplates/utills';
import { themeStyles } from './theme';

const CustomMention = ({
  value,
  label,
  onChange,
  isError,
  errorMessage,
  linkedItemId,
  showQueueAndActorEmailOnly,
  onlyReferredMetric,
  isShowReferredMetric,
  isMulti,
  placeholder,
  showMentionWithQuotes,
  ...props
}) => {
  const [relatedItemActor, setRelatedItemActor] = useState([]);
  const [relatedItemMetric, setRelatedItemMetric] = useState([]);
  const [relatedItemSla, setRelatedItemSla] = useState([]);
  const [relatedItemFields, setRelatedItemFields] = useState([]);

  const fetchRelatedTemplate = async (templateId) => {
    const source = axios.CancelToken.source();
    try {
      const params = {
        $select: 'fields, slas, metric, actors',
        $filter: `id eq '${templateId}'`,
      };
      const response = await axiosInstance.get('templates/list', {
        params,
        cancelToken: source.token,
      });
      if (response?.data?.rows) {
        const template = [...response.data.rows][0];

        const FIELD_LIST = [...(template.fields || [])].map((item) => ({
          id: `parentTicket.data['${item.id}']`,
          display: getMentionOptionLabel({
            name: item?.label,
            type: convertFieldType({ rowData: item }),
            isRelated: true,
          }),
        }));
        const METRIC_DATA = [...(template.metric || [])].map((item) => ({
          id: `parentMetric['${item.id}']`,
          display: getMentionOptionLabel({
            name: item.name,
            type: 'Metric',
            isRelated: true,
          }),
        }));

        const SLA_DATA = Object.values(template?.slas || {})
          .flatMap((data) => data)
          .map((item) => ({
            id: `parentSla['${item.id}']`,
            display: getMentionOptionLabel({
              name: item.name,
              type: 'Sla',
              isRelated: true,
            }),
          }));

        const ACTOR_DATA = [];

        [...(template.actors || [])].forEach((item) => {
          const actorFirstNameOption = {
            id: `parentActor['${item.associated_field_id}']?.userResponse?.first_name`,
            display: getMentionOptionLabel({
              name: item.name,
              type: 'Actor',
              subType: 'First Name',
              isRelated: true,
            }),
          };

          const actorLastNameOption = {
            id: `parentActor['${item.associated_field_id}']?.userResponse?.last_name`,
            display: getMentionOptionLabel({
              name: item.name,
              type: 'Actor',
              subType: 'Last Name',
              isRelated: true,
            }),
          };
          ACTOR_DATA.push(actorFirstNameOption);
          ACTOR_DATA.push(actorLastNameOption);
        });

        setRelatedItemActor([...ACTOR_DATA]);
        setRelatedItemFields([...FIELD_LIST]);
        setRelatedItemMetric([...METRIC_DATA]);
        setRelatedItemSla([...SLA_DATA]);
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const FIELD_SLA_METRIC_DATA = useSelector((state) => {
    const SLA_DATA = Object.values(
      state?.viewState?.selectedTemplate?.slas || {}
    )
      .flatMap((data) => data)
      .map((item) => ({
        id: `sla['${item.id}']`,
        display: getMentionOptionLabel({
          name: item.name,
          type: 'Sla',
        }),
      }));

    const METRIC_DATA = [
      ...(state?.viewState?.selectedTemplate?.metric || []),
    ].map((item) => ({
      id: `metric['${item.id}']`,
      display: getMentionOptionLabel({
        name: item.name,
        type: 'Metric',
      }),
    }));

    const FIELD_DATA = [...(state?.viewState?.selectedTemplate?.fields || [])]
      .filter((data) =>
        showQueueAndActorEmailOnly
          ? data.type === FieldTypes.DROPDOWN &&
            data.type_based_attributes?.collection.collection_type ===
              'SPECIAL_FIELD' &&
            data.type_based_attributes?.collection.specialField === 'queue'
          : true
      )
      .map((item) => ({
        id: showQueueAndActorEmailOnly
          ? `queueAssociation['${item.id}']`
          : `ticket.data['${item.id}']`,
        display: getMentionOptionLabel({
          name: item?.label,
          type: convertFieldType({ rowData: item }),
        }),
      }));

    if (showQueueAndActorEmailOnly) {
      const queueDropdownFieldList = [...(state?.viewState?.selectedTemplate?.fields || [])].filter((data) => data.type === FieldTypes.DROPDOWN && data.type_based_attributes?.collection.collection_type === 'SPECIAL_FIELD' && data.type_based_attributes?.collection.specialField === 'queue');
      const QUEUE_LIST = queueDropdownFieldList.map((item) => {
        const queueOptions = ['Supervisors', 'members', 'watchers'];
        return queueOptions.map((q) => ({
          id: `queueData['${item.id}']?.${q}`,
          display: `${item.label}.${q}`,
        }));
      });

      return QUEUE_LIST.flat();
    }

    return [...FIELD_DATA, ...SLA_DATA, ...METRIC_DATA];
  });

  const ACTOR_LIST = useSelector((state) => {
    const currentTemplateActorsOptions = [];
    [...(state?.viewState?.selectedTemplate?.actors || [])].forEach((item) => {
      if (showQueueAndActorEmailOnly) {
        const actorUserNameOption = {
          id: `actor['${item.id}']?.userResponse?.email`,
          display: getMentionOptionLabel({
            name: item.name,
            type: 'Actor',
            subType: 'Email',
          }),
        };

        currentTemplateActorsOptions.push(actorUserNameOption);
      } else {
        const actorFirstNameOption = {
          id: `actor['${item.id}']?.userResponse?.first_name`,
          display: getMentionOptionLabel({
            name: item.name,
            type: 'Actor',
            subType: 'First Name',
          }),
        };

        const actorLastNameOption = {
          id: `actor['${item.id}']?.userResponse?.last_name`,
          display: getMentionOptionLabel({
            name: item.name,
            type: 'Actor',
            subType: 'Last Name',
          }),
        };
        currentTemplateActorsOptions.push(actorFirstNameOption);
        currentTemplateActorsOptions.push(actorLastNameOption);
      }
    });
    return currentTemplateActorsOptions;
  });

  const HARDCODED_FIELDS_LIST = [
    { id: 'ticket.object_id', display: 'Ticket ID' },
    { id: 'created_at', display: 'Created At' },
  ];

  const HARDCODED_RELATED_FIELDS_LIST = [
    { id: 'parentTicket.object_id', display: 'Ticket ID - Related' },
  ];

  const HARDCODED_ACTORS_LIST = [
    { id: 'cc_watchers', display: 'CC Watcher' },
    { id: 'to_watchers', display: 'To Watcher' },
    { id: 'submitted_by', display: 'Submitted By' },
    { id: 'actioned_by', display: 'Actioned By' },
  ];

  useEffect(() => {
    if (linkedItemId && !showQueueAndActorEmailOnly) {
      fetchRelatedTemplate(linkedItemId);
    }
  }, [linkedItemId]);

  const FIELD_OPTIONS = useMemo(() => {
    if (showQueueAndActorEmailOnly) {
      return [...FIELD_SLA_METRIC_DATA];
    }
    const allFields = [...HARDCODED_FIELDS_LIST];

    if (linkedItemId) {
      allFields.push(...HARDCODED_RELATED_FIELDS_LIST);
    }

    allFields.push(...FIELD_SLA_METRIC_DATA);

    if (linkedItemId) {
      allFields.push(...relatedItemFields);
      allFields.push(...relatedItemSla);
      allFields.push(...relatedItemMetric);
    }

    return allFields;
  }, [relatedItemMetric, relatedItemSla, relatedItemFields]);
  const ACTOR_OPTIONS = useMemo(() => {
    if (showQueueAndActorEmailOnly) {
      return [...ACTOR_LIST, ...HARDCODED_ACTORS_LIST];
    }
    return [...HARDCODED_ACTORS_LIST, ...ACTOR_LIST, ...relatedItemActor];
  }, [relatedItemActor]);

  // const conditionalStyles = props.multiLine ? {
  //   border: '1px solid #d1d5db',
  //   maxHeight: '400px',
  //   overflowY: 'auto',
  //   borderRadius: '8px',
  // } : {
  //   border: 'none',
  //   maxHeight: 'none',
  //   overflowY: 'none',
  //   borderRadius: '0px',
  // };

  return (
    <div>
      {label && <div style={label ? { marginBottom: '.5rem' } : {}}>
        <label className="SCLabel">{label}</label>
        {props?.isMandatory && (
          <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
        )}
      </div>}
      {isShowReferredMetric ? (
        <MentionsInput
          value={value}
          singleLine={!isMulti}
          onChange={(e) => {
            const updatedValue = e.target.value;
            onChange(updatedValue);
          }}
          placeholder={placeholder}
          a11ySuggestionsListLabel="Suggested mentions"
          style={useColorModeValue(defaultStyle, darkMentionDefaultStyle)}
        >
          <Mention
            trigger="@"
            data={onlyReferredMetric || []}
            markup="@(__id__)"
            displayTransform={(id, display) =>
              getWholeOption(id, onlyReferredMetric, 'id')?.display || display
            }
          />
        </MentionsInput>
      ) : (
        // <Box style={conditionalStyles}>
        <MentionsInput
          value={value}
          onChange={(e) => {
            const updatedValue = e.target.value;
            onChange(updatedValue);
          }}
          placeholder={placeholder || 'Mention using \'@\''}
          a11ySuggestionsListLabel="Suggested mentions"
          // style={useColorModeValue(defaultStyle, darkMentionDefaultStyle)}
          style={useColorModeValue(props?.multiLine ? themeStyles.light : defaultStyle, props?.multiLine ? themeStyles.dark : darkMentionDefaultStyle)}
        >
          <Mention
            trigger="$"
            data={FIELD_OPTIONS || []}
            markup="@(__id__)"
            displayTransform={(id, display) =>
              getWholeOption(id, [...FIELD_OPTIONS, ...ACTOR_OPTIONS], 'id')
                ?.display || display
            }
          />
          <Mention
            trigger="@"
            data={ACTOR_OPTIONS || []}
            markup="@(__id__)"
            displayTransform={(id, display) =>
              getWholeOption(id, [...FIELD_OPTIONS, ...ACTOR_OPTIONS], 'id')
                ?.display || display
            }
          />
          {showMentionWithQuotes && (
            <Mention
              trigger='"$'
              data={FIELD_OPTIONS || []}
              markup='"@(__id__)"'
              displayTransform={(id, display) =>
                getWholeOption(id, [...FIELD_OPTIONS, ...ACTOR_OPTIONS], 'id')
                  ?.display || display
              }
            />
          )}
          {showMentionWithQuotes && (
            <Mention
              trigger='"@'
              data={ACTOR_OPTIONS || []}
              markup={`"@(__id__)"`}
              displayTransform={(id, display) =>
                getWholeOption(id, [...FIELD_OPTIONS, ...ACTOR_OPTIONS], 'id')
                  ?.display || display
              }
            />
          )}
          {/* {showMentionWithQuotes && <></>} */}
        </MentionsInput>
        // </Box>
      )}

      {isError && errorMessage && (
        <span className="field-error">{errorMessage}</span>
      )}
    </div>
  );
};
CustomMention.propTypes = {
  id: PropTypes.string,
  isMulti: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  isMandatory: PropTypes.bool,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  linkedItemId: PropTypes.string,
  showQueueAndActorEmailOnly: PropTypes.bool,
  onlyReferredMetric: PropTypes.array,
  isShowReferredMetric: PropTypes.bool,
  placeholder: PropTypes.string,
  showMentionWithQuotes: PropTypes.bool,
};

CustomMention.defaultProps = {
  isMulti: false,
  id: '',
  label: '',
  value: undefined,
  isMandatory: false,
  isError: false,
  errorMessage: '',
  linkedItemId: '',
  showQueueAndActorEmailOnly: false,
  onlyReferredMetric: [],
  isShowReferredMetric: false,
  placeholder: 'Mention using \'@\'',
  showMentionWithQuotes: false,
};

export default CustomMention;
