/* eslint-disable import/no-cycle */
import React from 'react';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Box, Flex } from '@chakra-ui/layout';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { v4 as uuidv4 } from 'uuid';
import CustomButton from '../../../components/Button/SimpleButton';
import CustomModal from '../../../components/Modal/Modal';
import { COLORS } from '../../../utils/enums/colors';
import DeleteIcon from '../../../assets/icons/DeleteIcon.svg';
import { LABELS } from '../../../utils/enums/labels';
import styles from './customTemplate.module.scss';
import axiosInstance from '../../../utils/axios/axios';
import CustomToast from '../../../components/Notification/Notification';
import { updateViewState } from '../../../redux/ViewSlice/ViewReducer';
import { checkIfUserCanEdit } from '../../TicketTemplateBuilder/utils/templateAPIUtils';

const CustomTemplateDeleteModal = ({
  isDeleteModal,
  setIsDeleteModal,
  templateName,
  templateId,
}) => {
  const colorType = useColorModeValue('light', 'dark');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addToast } = CustomToast();

  const onConfirmation = async () => {
    const res = await checkIfUserCanEdit({
      displayToastMessage: true,
      id: templateId,
    });

    if (res?.error) {
      setIsDeleteModal(false);
      return;
    }

    try {
      await axiosInstance.delete(
        `templates/delete?$filter=id eq ${templateId}`
      );
      addToast({
        message: 'Template Deleted successfully',
        type: 'success',
        duration: 2000,
        isClosable: true,
      });
      dispatch(
        updateViewState({
          stateKey: 'ticket_template_list_short_view',
          value: {
            refresh: uuidv4(),
          },
        })
      );
      navigate(`/template-cardlist/`);
      setIsDeleteModal(false);
    } catch (err) {
      addToast({
        title: 'Something went wrong',
        message: err?.response?.data?.message || 'Failed to delete template',
        type: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  };
  return (
    <CustomModal
      className={styles.footer}
      id="delete-modal"
      modalContent={
        <Flex
          flex="1"
          align="center"
          p="2"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          justifyContent="center"
          height="100%"
          data-testid="CustomTemplateDeleteModalOuterContainerFex"
        >
          <Box
            // bg={useColorModeValue('white', 'gray.900')}
            align="center"
            justifyContent="center"
            data-testid="CustomTemplateDeleteModalOuterContainerBox"
          >
            <Box
              width="50"
              height="50"
              mt="5"
              data-testid="CustomTemplateDeleteModalReactSVGContainerBox"
            >
              <ReactSVG
                src={DeleteIcon}
                className={`${styles.templateDelete} p-2 ${
                  colorType === 'dark' ? styles.colorWhite : ''
                }`}
                data-testid="CustomTemplateDeleteModalDeleteTemplateReactSVG"
              />
            </Box>
            <Box
              className={styles.createHeadText}
              color={useColorModeValue('', 'white')}
              data-testid="CustomTemplateDeleteModalLabelContainerBox"
            >
              {LABELS.TITLE.DELETE_TEMPLATE_WARNING}
            </Box>
            <div
              className={styles.paraTex}
              data-testid="CustomTemplateDeleteModalParaTexDiv"
            >
              {templateName}
            </div>
            <div
              className="mt-10"
              data-testid="CustomTemplateDeleteModalButtonContainerDiv"
            >
              <CustomButton
                id="CustomTemplateDeleteModalButton"
                onClick={() => {
                  setIsDeleteModal(false);
                }}
                buttonText={LABELS.ACTIONS.CANCEL}
                variant="outline"
                className="mr-4"
                width="40%"
                size="md"
                color={useColorModeValue(COLORS.BLACK, COLORS.DARK)}
                _hover={{
                  background: 'white',
                  color: 'black',
                  border: '1px solid black',
                }}
              />
              <CustomButton
                id="CustomTemplateDeleteModalButtonconfirm-delete"
                onClick={() => onConfirmation()}
                buttonText={LABELS.ACTIONS.DELETE}
                variant="solid"
                size="md"
                width="40%"
                bg={useColorModeValue(COLORS.RED, COLORS.DARK)}
                _hover={{
                  background: '#DC3636',
                  color: 'white',
                }}
              />
            </div>
          </Box>
        </Flex>
      }
      open={isDeleteModal}
      setOpen={setIsDeleteModal}
      size="sm"
    />
  );
};

CustomTemplateDeleteModal.propTypes = {
  templateName: PropTypes.string.isRequired,
  isDeleteModal: PropTypes.bool.isRequired,
  setIsDeleteModal: PropTypes.func.isRequired,
  templateId: PropTypes.string.isRequired,
};
export default CustomTemplateDeleteModal;
