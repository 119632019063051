import { AddIcon } from '@chakra-ui/icons';
import { Box, Flex, Tooltip, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../../components/Button/SimpleButton';
import { COLORS } from '../../../utils/enums/colors';
import styles from '../styles/CategoryList.module.scss';
import StatusList from './StatusLIst';
import { ACTION_LABELS } from '../../../utils/enums/labels';
import { updateStateOfWorkFlowWithKey } from '../../../redux/WorkFlowMainSlice/WorkFlowMainReducer';
import { isTemplateApproved } from '../helper';

const CategoryList = ({ setAddingStatus, setEditStatus }) => {
  const { selectedWorkFlow, templateLifeCycle } = useSelector(
    (state) => state.workFlowMain
  );
  const dispatch = useDispatch();

  return (
    <Flex direction="column" data-testid="CategoryListFlex">
      <Box data-testid="CategoryListStatusListBox">
        {selectedWorkFlow.workflow_category?.map((item, index) => (
          <StatusList
            key={`status_${index + 1}`}
            status={item}
            setAddingStatus={setAddingStatus}
            setEditStatus={setEditStatus}
          />
        ))}
      </Box>
      <Box mt="28px" data-testid="CategoryListButonBox">
        <Tooltip
          placement="top"
          hasArrow
          label={
            isTemplateApproved(templateLifeCycle)
              ? `Category can't be added as it was approved.`
              : ''
          }
          data-testid="CategoryListApprovedTooltip"
        >
          <Box position="relative" data-testid="CategoryListCustomBox">
            <CustomButton
              leftIcon={<AddIcon />}
              id="CategoryListCustom-new-template-btn"
              buttonText={ACTION_LABELS.ADD_NEW_CATEGORY}
              variant="outline"
              className={styles.addStatusBtn}
              color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
              onClick={() => {
                dispatch(
                  updateStateOfWorkFlowWithKey({
                    keyValue: 'selectedWorkFlowStatusCategory',
                    value: {},
                  })
                );
                setAddingStatus(true);
              }}
              isDisabled={isTemplateApproved(templateLifeCycle)}
            />
          </Box>
        </Tooltip>
      </Box>
    </Flex>
  );
};
CategoryList.propTypes = {
  setAddingStatus: PropTypes.func.isRequired,
  setEditStatus: PropTypes.func.isRequired,
};
export default CategoryList;
