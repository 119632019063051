import React from 'react';
import { Box, FormLabel } from '@chakra-ui/react';
import SelectFieldFromTemplateWithDelete from '../../../components/selectFieldFromTemplateWithDelete';

const renderUpdateRecordsForm = (
  actionToPerform,
  template,
  teamQueueMap,
  hierarchies,
  fieldHierarchyMaps,
  handleChange,
  actionToPerformSelectedIndex,
  actionState,
  relatedTemplates,
) =>{
  const targetTemplateName = () => {
    if (actionState.linked_item_type) {
      const template = relatedTemplates.find(
        (template_) => template_.id === actionState.linked_item_type
      );
      return template?.name;
    }
    return '';
  };
  return (
  <Box marginTop="1rem" data-testid="DynamicRendererUpdateIssueTypeBox">
    <FormLabel fontWeight={600} data-testid="DynamicRendererUpdateIssueLabel">
      Update Record
      <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
    </FormLabel>
    <SelectFieldFromTemplateWithDelete
      key={actionToPerform?.issue_type?.value}
      fieldList={template?.fields || []}
      teamQueueMap={teamQueueMap}
      value={actionToPerform?.update_record || []}
      workFlowStatusList={
        template?.workflow?.workflow_status
          ?.filter((item) => item.id !== 'start_1')
          .map((ele) => ({ value: ele.id, label: ele.data.label })) || []
      }
      onChange={(value) => {
        handleChange(
          value,
          `action_to_perform[${actionToPerformSelectedIndex}].update_record`
        );
      }}
      isRequired
      preventFieldRepeat
      hierarchies={hierarchies}
      fieldHierarchyMap={fieldHierarchyMaps}
      actorAssociationMap={template?.actors || []}
      parentFields={template?.fields || []}
      targetTemplateName={targetTemplateName()}
      sourceTemplateName={template.name}
    />
    {/* {actionErrorData?.update_record ? (
                          <span className="field-error">
                            Field name & value can&apos;t be empty
                          </span>
                        ) : null} */}
  </Box>
)};

export default renderUpdateRecordsForm;
