import React, { useCallback, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import CustomInput from '../../components/InputBox/Input';
import CustomDynamicSelect from './CustomDynamicSelect/CustomDynamicSelect';
import CustomDrawer from './CustomDrawer';

import { FORM_MAP } from './filters.constants';
import { FILTER_VALIDATIONS } from '../../utils/validationSchemas/Filters';

const FilterEditDrawer = ({
  data,
  onSave,
  fields,
  open,
  setOpen,
  fromTemplate,
}) => {
  const [filterData, setFilterData] = useState({ ...data });
  const [isError, setIsError] = useState(false);

  const handleChange = useCallback((key, value) => {
    setFilterData((prev) => ({
      ...prev,
      [key]: value,
    }));

    setIsError(false);
  }, []);

  const handleSave = useCallback(() => {
    const { error } = FILTER_VALIDATIONS.validate(filterData, {
      abortEarly: false,
    });

    if (error) {
      setIsError(true);
      return;
    }

    onSave(filterData);
  }, [filterData, onSave]);

  const closeDrawer = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const drawerBody = useMemo(
    () => (
      <>
        <div className="mb-5">
          <CustomInput
            id="FilterName"
            type="text"
            label="Name"
            placeholder="Name"
            value={filterData?.[FORM_MAP.NAME] || ''}
            isMandatory
            onChange={(e) => handleChange(FORM_MAP.NAME, e.target.value)}
          />
        </div>
        <CustomDynamicSelect
          label="Conditions"
          onChange={(e) => handleChange(FORM_MAP.CONDITIONS, e)}
          fields={fields}
          values={filterData?.[FORM_MAP.CONDITIONS] || []}
          isMandatory
          fromTemplate={fromTemplate}
        />
      </>
    ),
    [filterData, handleChange, fields]
  );

  return (
    <CustomDrawer
      title="Filters"
      closeDrawer={closeDrawer}
      handleSave={handleSave}
      open={open}
      errorMessage={isError ? 'Please fill all mandatory fields' : ''}
      drawerBody={drawerBody}
    />
  );
};

FilterEditDrawer.propTypes = {
  data: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  fields: PropTypes.array,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  fromTemplate: PropTypes.bool,
};

FilterEditDrawer.defaultProps = {
  data: {},
  fields: [],
  fromTemplate: false,
};

export default FilterEditDrawer;
