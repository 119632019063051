// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.O1AGGOflGP7RiyzzylM1{font-family:"Inter",sans-serif !important;height:90vh;font-weight:500;display:flex;position:relative;width:240px;transition:width .3s}.U2TfKy5FI4OBjFgkGdga{width:59px;transition:width .3s}.m11s7Vxgs4ba_WFEs_wf{display:flex;max-height:100vh;border-color:#f3f5f9;padding:.75rem;flex:1 1 0%;width:calc(100% - 226px)}.VujwhKhf1gUTm8Um0CgL{border-radius:15px;display:flex;flex-direction:column;padding:22px 12px;width:100%;height:100%}`, "",{"version":3,"sources":["webpack://./src/layouts/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,yCAAA,CACA,WAAA,CACA,eAAA,CACA,YAAA,CACA,iBAAA,CACA,WAAA,CACA,oBAAA,CAGF,sBACE,UAAA,CACA,oBAAA,CAGF,sBACE,YAAA,CACA,gBAAA,CACA,oBAAA,CACA,cAAA,CACA,WAAA,CACA,wBAAA,CAGF,sBACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,iBAAA,CACA,UAAA,CACA,WAAA","sourcesContent":[".side_bar_content_parent {\n  font-family: 'Inter', sans-serif !important;\n  height: 90vh;\n  font-weight: 500;\n  display: flex;\n  position: relative;\n  width: 240px;\n  transition: width 0.3s;\n}\n\n.side_bar_content_collapsed {\n  width: 59px;\n  transition: width 0.3s;\n}\n\n.mainContainer {\n  display: flex;\n  max-height: 100vh;\n  border-color: rgb(243, 245, 249);\n  padding: 0.75rem;\n  flex: 1 1 0%;\n  width: calc(100% - 226px);\n}\n\n.mainLayoutContainer {\n  border-radius: 15px;\n  display: flex;\n  flex-direction: column;\n  padding: 22px 12px;\n  width: 100%;\n  height: 100%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"side_bar_content_parent": `O1AGGOflGP7RiyzzylM1`,
	"side_bar_content_collapsed": `U2TfKy5FI4OBjFgkGdga`,
	"mainContainer": `m11s7Vxgs4ba_WFEs_wf`,
	"mainLayoutContainer": `VujwhKhf1gUTm8Um0CgL`
};
export default ___CSS_LOADER_EXPORT___;
