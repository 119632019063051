export const BASE_SETTINGS_KEYS = {
  LABEL: 'label',
  SLUG: 'slug',
  DESCRIPTION: 'description',
  DEFAULT_VALUE: 'default_value',
  FORMULA: 'formula',
  IS_EDIT_SLUG: 'isEditSlug',
  NAME: 'name',
  VALUES: 'values',
  MEDIA_SIZE: 'media_size',
};

export const ADVANCED_SETTINGS_KEYS = {
  PLACEHOLDER: 'placeholder',
  HINT_TEXT: 'hint_text',
  REGEX_PATTERN: 'regex_pattern',
  NUMBER_FORMAT: 'number_format',
  MIN_LENGTH: 'min_length',
  MAX_LENGTH: 'max_length',
  REQUIRED: 'required',
  UNIQUE: 'unique_field',
  READ_ONLY: 'read_only',
  HIDDEN: 'hidden',
  SEARCHABLE: 'is_searchable',
  REPORTABLE: 'is_reportable',
  METRICS: 'metrics',
  MEDIA_TYPE: 'media_type',
  DATE_FORMAT: 'date_format',
  LOCALE: 'locale',
  SELECT_LOCALE: 'select_locale',
};

export const cardinality = [
  { id: 'ONE_MANY', label: '1-N' },
  { id: 'ONE_ONE', label: '1-1' },
  { id: 'MANY_ONE', label: 'N-1' },
  { id: 'MANY_MANY', label: 'M-N' },
];

export const optionLabel = { mandatory: 'mandatory', optional: 'optional' };

export const mandatoryOption = [
  { id: optionLabel.mandatory, label: 'mandatory' },
  { id: optionLabel.optional, label: 'optional ' },
];
