import _isArray from 'lodash/isArray';
import _cloneDeep from 'lodash/cloneDeep';
import axiosInstance, { axiosInstanceWithoutBaseUrl } from '../../utils/axios/axios';
import { getTenantData, getTenantInformation } from '../../helpers/tenantUrl';

export const getActorsByTemplateId = async (id) => {
  if (!id) {
    return {
      error: 'templateId is not defined',
      response: null,
    };
  }

  try {
    const option = `id eq ${id}`;
    const response = await axiosInstance.get('/templates/list', {
      params: {
        $top: 1,
        $skip: 0,
        $select: 'actors',
        $filter: option,
      },
    });
    return response?.data?.rows[0];
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return {
      error: message,
      response: null,
    };
  }
};

export const getAppusers = async () => {
  try {
    const response = await axiosInstanceWithoutBaseUrl.get('botmgmt-service/appusers');
    return response?.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return {
      error: message,
      response: null,
    };
  }
};

export const getTenantRolesAndTeams = async () => {
  const tenantData = getTenantData();

  if (tenantData) {
    return tenantData;
  }

  await getTenantInformation();
  return getTenantData();
};

export const parseComponentValuesToAPIData = (componentValues) => {
  // example input:
  // {
  //     "org": [ { "label": "testdrop", "value": "testdrop" } ],
  //     "teams": [
  //       { "label": "james", "value": "james" },
  //       { "label": "finance", "value": "finance" }
  //     ]
  // }

  // example output:
  // {
  //     "org": [ "testdrop" ],
  //     "teams": [ "james", "finance" ]
  // }
  const attributeKeys = Object.keys(componentValues);
  const roleAttributesObject = _cloneDeep(componentValues);
  attributeKeys.forEach((attribute) => {
    const attributeItems = _cloneDeep(roleAttributesObject[attribute]);
    roleAttributesObject[attribute] = (attributeItems || [])
      .filter((x) => !!x)
      .map((attributeItem) => (attributeItem.value ? attributeItem.value : attributeItem));
  });

  return roleAttributesObject;
};

export const parseAPIDataToComponentValues = (roleAttributesObject, allAttributeOptions) => {
  // example input:
  // {
  //     "org": [ "testdrop" ],
  //     "teams": [ "james", "finance" ]
  // }

  // example output:
  // {
  //     "org": [ { "label": "testdrop", "value": "testdrop" } ],
  //     "teams": [
  //       { "label": "james", "value": "james" },
  //       { "label": "finance", "value": "finance" }
  //     ]
  // }

  const componentValues = _cloneDeep(roleAttributesObject);

  Object.keys(roleAttributesObject).forEach((attributeKey) => {
    const attributeArray = _cloneDeep(componentValues[attributeKey]);

    const attributeItems = [...(attributeArray || [])].map((attributeItem) => {
      if (_isArray(allAttributeOptions[attributeKey])) {
        return allAttributeOptions[attributeKey]?.find(
          (option) => option.value === attributeItem
        );
      }
      return attributeItem;
    });
    componentValues[attributeKey] = attributeItems;
  });

  return componentValues;
};
export const ROLE_SETTING_TYPE_VS_INPUT_TYPE = {
  ORG: 'input-disabled',
  TEAMS: 'select',
  QUEUES: 'select',
  GROUPS: 'input',
  USERS: 'select',
  ROLES: 'select',
  ACTORS: 'select',
};

export const ROLE_SETTING_TYPE_VS_OPTIONS = {
  ORG: null,
  TEAMS: 'team',
  QUEUES: 'queues',
  GROUPS: null,
  USERS: 'users',
  ROLES: 'roles',
  ACTORS: 'actors',
};
export const getUserAttributes = async () => {
  try {
    // dashboardV2/getUserAttributes
    const response = await axiosInstanceWithoutBaseUrl.get('UserAttribute/findAndCountAll');
    return {
      response: response?.data,
      error: null,
    };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return {
      error: message,
      response: null,
    };
  }
};
export const getGroup = async () => {
  try {
    const response = await axiosInstanceWithoutBaseUrl.get('group/findAndCountAll');
    return {
      response: response?.data,
      error: null,
    };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return {
      error: message,
      response: null,
    };
  }
};
