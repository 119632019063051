/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import _get from 'lodash/get';
import styles from './WorkFlowLinkButton.module.scss';
import WorkflowLogo from '../../assets/icons/TicketWorkflow.svg';
import WarningWorkflow from '../../assets/icons/WarningWorkflow.svg';
import IndicateWorkflow from '../../assets/icons/IndicateWorkflow.svg';
import CustomButton from '../../components/Button/SimpleButton';
import { COLORS } from '../../utils/enums/colors';
import { setActiveModal } from '../../redux/ModalReducer/ModalSlice';
import LogoutIcon from '../../assets/icons/LogoutIcon.svg';
import ChevronDownIcon from '../../assets/icons/ChevronDown.svg';
import { saveState } from '../TicketTemplateBuilder/utils/helper';

const WorkflowLinkButton = ({
  buttonText,
  name,
  size,
  style,
  targetModalId,
}) => {
  const [workflowName, setWorkflowName] = useState('');
  const { ticket_template_fields_list_view } = useSelector(
    (state) => state.viewState
  );
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedTemplate = useSelector(
    (state) => state?.viewState?.selectedTemplate
  );

  const fetchWorkFlowFromTemplate = async () => {
    if (selectedTemplate?.workflow) {
      setWorkflowName(selectedTemplate?.workflow?.name);
    } else {
      setWorkflowName('');
    }
  };

  useEffect(() => {
    fetchWorkFlowFromTemplate();
  }, [id, selectedTemplate]);

  let label;
  if (selectedTemplate?.lifecycle_status === 'APPROVED') {
    label = (
      <>
        This template is associated with a workflow.
        <br /> This template is in Approved State. Make it Draft to make changes
      </>
    );
  } else if (workflowName) {
    label = `This template is associated with a workflow.`;
  } else if (ticket_template_fields_list_view.data.length === 0) {
    label = 'This template does not contain any fields.';
  } else {
    label = 'This template is not associated with a workflow.';
  }

  return (
    <Flex
      justify="center"
      align="center"
      data-testid="WorkflowLinkButtonContainerFlex"
    >
      <Tooltip hasArrow label={label} data-testid="WorkflowLinkButtonTooltip">
        <Box position="relative" data-testid="WorkflowLinkButtonCSBox">
          <CustomButton
            leftIcon={<ReactSVG src={WorkflowLogo} />}
            id="WorkflowLinkLogoBtn"
            name={name}
            width="100%"
            onClick={async () => {
              if (workflowName) {
                const params = {
                  sourceRoute: 'template',
                  id,
                  ticketTypeName: selectedTemplate?.name,
                };
                const queryString = new URLSearchParams(params).toString();
                const url = `/${'work-flow'}?${queryString}`;
                await saveState('url', location.pathname + location.search);
                navigate(url);
                dispatch(setActiveModal({ targetModalId: undefined }));
              } else {
                dispatch(setActiveModal({ targetModalId }));
              }
            }}
            isDisabled={
              ticket_template_fields_list_view?.data?.length === 0 // ||
              // selectedTemplate?.lifecycle_status === 'APPROVED'
            }
            buttonText={buttonText}
            variant="outline"
            color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
            size={size}
            style={
              workflowName
                ? {
                  ...style,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  borderRight: 0,
                }
                : { ...style }
            }
            rightIcon={
              <ReactSVG
                src={workflowName ? IndicateWorkflow : WarningWorkflow}
                style={{
                  marginLeft: '5px',
                }}
              />
            }
            className={styles.workflow_button}
          />
        </Box>
      </Tooltip>
      {workflowName && (
        <Flex data-testid="WorkflowLinkButtonNameFlex">
          <Popover placement="bottom" data-testid="WorkflowLinkPopover">
            <PopoverTrigger data-testid="WorkflowLinkPopoverTrigger">
              <Button
                className={styles.profileButton}
                style={{
                  height: '32px',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                isDisabled={
                  selectedTemplate?.lifecycle_status === 'APPROVED' ||
                  _get(selectedTemplate, 'properties.wasApproved')
                }
                data-testid="WorkflowLinkButton"
              >
                <ReactSVG
                  role="button"
                  className={styles.profileIcon}
                  src={useColorModeValue(ChevronDownIcon, ChevronDownIcon)}
                  data-testid="WorkflowLinkButtonReactSvg"
                />
              </Button>
            </PopoverTrigger>
            <PopoverContent
              className={styles.popOverBox}
              data-testid="WorkflowLinkButtonPopoverContent"
            >
              <PopoverBody data-testid="WorkflowLinkButtonPopoverBody">
                <Flex
                  // TODO: Remove this style
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    dispatch(setActiveModal({ targetModalId }));
                  }}
                  data-testid="WorkflowLinkButtonFlex"
                >
                  <Flex pt="2" alignItems="center">
                    {/* <ReactSVG src={LogoutIcon} /> */}
                    <Text
                      ml="2"
                      style={{ fontWeight: '500' }}
                      fontSize="md"
                      data-testid="WorkflowLinkButtonText"
                    >
                      Assign another Workflow
                    </Text>
                  </Flex>
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>
      )}
    </Flex>
  );
};
WorkflowLinkButton.propTypes = {
  buttonText: PropTypes.string,
  size: PropTypes.string,
  name: PropTypes.string,
  style: PropTypes.object,
  targetModalId: PropTypes.string.isRequired,
};
WorkflowLinkButton.defaultProps = {
  buttonText: 'Click',
  size: 'sm',
  style: {},
  name: '',
};
export default WorkflowLinkButton;
