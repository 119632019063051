import { FormLabel, GridItem, Grid, Alert, AlertIcon } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import CustomSelectBox from '../../../components/SelectBox/Select';
import customStyleFromRuleForSelect from '../../../context/CustomTheme/CustomStyleFromRuleForSelect';
import { getWholeOption } from '../../TicketTemplateWorkflow/helper';
import { LABELS } from '../../../utils/enums/labels';
import { checkForV2 } from '../../../utils/helper';

const WorkFlowConfigContain = ({
  workflowMasterListOptions,
  linkFieldOptions,
  dispatchWorkflow,
  workflowState,
}) => {
  const handleWorkFlowSelect = (e) => {
    const newObj = {
      id: e.value,
      name: e.label,
      workflow_status: e.workflow_status,
      workflow_category: e.workflow_category,
      workflow_transitions: e.workflow_transitions,
    };
    dispatchWorkflow({
      type: 'SET_STATE',
      value: { ...newObj },
    });
  };

  return (
    <Grid mt="38px">
      <GridItem w="100%">
        <FormLabel className="SCLabel">Select Workflow</FormLabel>
        <CustomSelectBox
          styles={customStyleFromRuleForSelect}
          options={workflowMasterListOptions ?? []}
          value={getWholeOption(workflowState?.id, workflowMasterListOptions)}
          onChange={(e) => {
            handleWorkFlowSelect(e);
          }}
          placeholder="Select a workflow to associate template"
          id="WorkFlowConfigSelectBox"
        />
      </GridItem>
    </Grid>
  );
};
WorkFlowConfigContain.propTypes = {
  workflowMasterListOptions: PropTypes.array.isRequired,
  linkFieldOptions: PropTypes.array.isRequired,
  dispatchWorkflow: PropTypes.func.isRequired,
  workflowState: PropTypes.object.isRequired,
};
export default WorkFlowConfigContain;
