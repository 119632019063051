import React, { useState } from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
// import CustomModal from '../../../components/Modal/Modal';
import styles from '../AddNewRoleModal/rolecontent.module.scss';
import {
  LABELS,
  ROLE_DESCRIPTIONS,
  ROLE_TITLE,
} from '../../../utils/enums/labels';
import CustomModalFooter from '../CreateEditFlow/CustomModalFooter';
import RoleTwo from '../../../assets/icons/Role_2.svg';
import RestrictSpecificStatusContainer from './RestrictSpecificStatusContainer';
import { updateRuleModalId } from '../../../redux/WorkFlowRuleSlice/WorkFlowRuleReducer';
import {
  addRuleToSelectedWorkFlowSate,
  changeSelectedRuleWithKey,
  initSelectedRule,
  removeRuleFromSelectedWorkFlowState,
  updateRuleToSelectedWorkFlowSate,
  updateStateOfWorkFlowWithKey,
} from '../../../redux/WorkFlowMainSlice/WorkFlowMainReducer';
import CustomModalFooterWithDelete from '../../../components/CustomModalFooterWithDelete';
import RuleDeleteModal from '../ShowEditEdge/RuleDeleteModal';
import { CustomDrawer } from '../../TicketTemplateBuilder/ConfigureTemplateView/AddEditTemplateLayoutModal';

const RestrictSpecificStatusModal = ({
  isRestricMoveRole,
  setIsRestricMoveRole,
  edit,
}) => {
  const dispatch = useDispatch();
  const { selectedWorkFlowRule } = useSelector((state) => state.workFlowMain);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  return (
    <>
      <CustomDrawer
        id="selected-field-modal"
        modalTitle={edit ? LABELS.ACTIONS.EDIT_RULE : LABELS.ACTIONS.ADD_RULE}
        drawerBody={
          <>
            <Flex data-testid="RestrictSpecificStatusModalBoxFlex">
              <Box
                className={styles.boxSvg}
                bgColor="#FFF7EA"
                data-testid="RestrictSpecificStatusModalSvgBox"
              >
                <ReactSVG
                  src={`${RoleTwo}`}
                  alt="image"
                  data-testid="RestrictSpecificStatusModalReactSvg"
                />
              </Box>

              <Box
                className={styles.cardbody}
                data-testid="RestrictSpecificStatusModalStatusBox"
              >
                <div
                  style={{ marginLeft: '20px' }}
                  data-testid="RestrictSpecificStatusModalStstusDiv"
                >
                  <Heading
                    className={styles.cardheading}
                    data-testid="RestrictSpecificStatusModalHeading"
                  >
                    {ROLE_TITLE.SPECIFIC_STATUS}
                  </Heading>
                  <Text
                    className={styles.carddesc}
                    data-testid="RestrictSpecificStatusModalDescText"
                  >
                    {ROLE_DESCRIPTIONS.SPECIFIC_STATUS}
                  </Text>
                </div>
              </Box>
            </Flex>
            <RestrictSpecificStatusContainer />
          </>
        }
        drawerFooter={
          edit ? (
            <CustomModalFooterWithDelete
              btnLabel="Update Rule"
              pt="10px"
              isDisabled={!selectedWorkFlowRule.transitionId}
              onCancelClick={() => {
                dispatch(
                  updateStateOfWorkFlowWithKey({
                    keyValue: 'selectedWorkFlowRule',
                    value: initSelectedRule,
                  })
                );
                setIsRestricMoveRole(false);
              }}
              onAddClick={() => {
                dispatch(updateRuleToSelectedWorkFlowSate({ dispatch }));
                setIsRestricMoveRole(false);
              }}
              onDeleteCllick={() => {
                setIsRestricMoveRole(false);
                setIsDeleteModal(true);
              }}
            />
          ) : (
            <CustomModalFooter
              btnLabel="Add Rule"
              pt="10px"
              isDisabled={!selectedWorkFlowRule.transitionId}
              onCancelClick={() => {
                dispatch(updateRuleModalId(''));
                dispatch(
                  updateStateOfWorkFlowWithKey({
                    keyValue: 'selectedWorkFlowRule',
                    value: initSelectedRule,
                  })
                );
                setIsRestricMoveRole(false);
              }}
              onAddClick={() => {
                dispatch(updateRuleModalId(''));
                dispatch(
                  changeSelectedRuleWithKey({
                    keyValue: 'id',
                    value: uuidv4(),
                  })
                );
                dispatch(
                  changeSelectedRuleWithKey({
                    keyValue: 'ruleType',
                    value: 'specificStatus',
                  })
                );
                dispatch(addRuleToSelectedWorkFlowSate({ dispatch }));
                setIsRestricMoveRole(false);
              }}
            />
          )
        }
        openDrawer={isRestricMoveRole}
        setOpenDrawer={() => {
          setIsRestricMoveRole(!isRestricMoveRole);
          dispatch(updateRuleModalId(''));
          dispatch(
            updateStateOfWorkFlowWithKey({
              keyValue: 'selectedWorkFlowRule',
              value: initSelectedRule,
            })
          );
        }}
      />
      <RuleDeleteModal
        isDeleteModal={isDeleteModal}
        setIsDeleteModal={setIsDeleteModal}
        onConfirmation={() => {
          dispatch(removeRuleFromSelectedWorkFlowState({ dispatch }));
          setIsDeleteModal(false);
        }}
        onDeleteCancelClick={() => {
          setIsDeleteModal(false);
          setIsRestricMoveRole(true);
        }}
        title={LABELS.TITLE.DELETE_RULE_WARNING}
        subTitle={ROLE_TITLE.SPECIFIC_STATUS}
      />
    </>
  );
};
RestrictSpecificStatusModal.propTypes = {
  setIsRestricMoveRole: PropTypes.func.isRequired,
  isRestricMoveRole: PropTypes.bool.isRequired,
  edit: PropTypes.bool,
};
RestrictSpecificStatusModal.defaultProps = {
  edit: false,
};
export default RestrictSpecificStatusModal;
