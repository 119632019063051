import _find from 'lodash/find';
import { patchDataForTemplate } from '../../TicketTemplateBuilder/utils/templateAPIUtils';

export const updateMapping = async ({
  data,
  id,
  defaultErrorMessage,
  successMessage,
}) => {
  const res = await patchDataForTemplate({
    id,
    data,
    key: 'mapping',
    defaultErrorMessage,
    successMessage,
  });

  return res;
};
export const getFieldNameById = (selectedTemplate, id) => {
  console.log({ id });
  if (selectedTemplate) {
    const field = _find(selectedTemplate?.fields, (f) => f.id === id);
    return field?.label;
  }
  return null;
};
