import React from 'react';
import { Popconfirm } from 'antd';
import { EditIcon, DeleteIcon, CopyIcon } from '@chakra-ui/icons';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { isDarkThemeApplied } from '../../../TicketTemplateBuilder/utils/helper';
import { checkForV2 } from '../../../../utils/helper';

const DATE_FORMAT = 'MMM DD, YYYY, hh:mm a';
const getColorTheme = (isDark) => (isDark ? '#BB86FC' : '#2563EB');
const getBackgroundColor = (isDark) => (isDark ? '#4F5765' : '#fff');

export const customStyles = (parentWidth = '250px', parentHeight = '32px') => ({
  control: (provided, state) => {
    const isSelected = state?.isSelected || state?.isFocused;
    const isDark = isDarkThemeApplied();
    const borderColor = isSelected ? getColorTheme(isDark) : '#e2e8f0';

    return {
      ...provided,
      height: parentHeight,
      width: parentWidth,
      marginRight: '0px',
      marginTop: '8px',
      color: isDark ? 'white' : 'hsl(0, 0%, 20%)',
      background: getBackgroundColor(isDark),
      borderColor,
    };
  },
  menu: (...props) => {
    const position = {};

    const minTop = 2;

    try {
      const {
        width,
        bottom,
        top: boundingTop,
      } = props[1].innerRef.current.previousSibling.getBoundingClientRect();
      const { height } = props[1].innerRef.current.getBoundingClientRect();

      position.width = `${width}px`;
      const top = bottom + minTop;
      if (height + top >= window.innerHeight) {
        position.top = `${boundingTop - height - 10}px`;
      } else {
        position.top = `${top}px`;
      }
    } catch (err) {
      position.width = '100%';
      position.top = `${minTop}px`;
    }

    return {
      zIndex: 99999,
      position: 'fixed',
      background: isDarkThemeApplied() ? '#4F5765' : '#fff',
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
      ...position,
    };
  },
  valueContainer: (provided) => ({
    ...provided,
    minHeight: '30px',
    padding: '0 6px',
    color: isDarkThemeApplied() ? 'white' : 'hsl(0, 0%, 20%)',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: isDarkThemeApplied() ? 'white' : 'hsl(0, 0%, 20%)',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: isDarkThemeApplied()
      ? 'hsl(0, 0%, 20%)'
      : 'hsl(0, 0%, 90%)',
    color: isDarkThemeApplied() ? 'white' : 'hsl(0, 0%, 20%)',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: isDarkThemeApplied() ? 'white' : 'hsl(0, 0%, 20%)',
  }),
  input: (provided) => ({
    ...provided,
    color: isDarkThemeApplied() ? 'white' : 'hsl(0, 0%, 20%)',
    margin: '0px',
    '::before': {
      position: 'relative',
    },
    '::after': {
      position: 'relative',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    minHeight: '30px',
  }),
  option: (provided, { isDisabled, isFocused, isSelected }) => {
    const isDark = isDarkThemeApplied();
    const selectedBackgroundColor = isDark ? '#BB86FC' : '#2684FF';
    const focusedBackgroundColor = isDark ? '#394353' : '#deebff';
    const backgroundColor = (() => {
      if (isDisabled) return 'blue';
      if (isSelected) return selectedBackgroundColor;
      if (isFocused) return focusedBackgroundColor;
      return undefined;
    })();

    const activeBackgroundColor =
      !isDisabled && (isSelected || isFocused) ? backgroundColor : undefined;

    return {
      ...provided,
      backgroundColor,
      ':active': {
        ...provided[':active'],
        backgroundColor: activeBackgroundColor,
      },
    };
  },
  placeholder: (provided) => ({
    ...provided,
    color: isDarkThemeApplied() ? 'white' : 'hsl(0, 0%, 20%)',
  }),
});

export const IS_SLA = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
];

export const METRIC_TYPE_VALUE = {
  STATE_TIME: 'state_time',
  ACTOR_TIME: 'actor_time',
  QUEUE_TIME: 'queue_time',
  LOG_TIME: 'log_time',
  FIRST_RESPONSE_TIME: 'first_response_time',
  CALCULATED_METRICS: 'calculated_metrics',
};

export const METRIC_TYPE = [
  {
    label: 'State Time',
    value: METRIC_TYPE_VALUE.STATE_TIME,
  },
  checkForV2() && {
    label: 'Actor Time',
    value: METRIC_TYPE_VALUE.ACTOR_TIME,
  },
  {
    label: 'Queue Time',
    value: METRIC_TYPE_VALUE.QUEUE_TIME,
  },
  {
    label: 'Log Time',
    value: METRIC_TYPE_VALUE.LOG_TIME,
  },
  {
    label: 'First Response Time',
    value: METRIC_TYPE_VALUE.FIRST_RESPONSE_TIME,
  },
  {
    label: 'Calculated Metrics',
    value: METRIC_TYPE_VALUE.CALCULATED_METRICS,
  },
].filter((i) => i);

export const UNIT = [{ label: 'Minutes', value: 'minutes' }];

export const createMap = (optionsArray) => {
  const map = {};
  if (!isEmpty(optionsArray)) {
    optionsArray.forEach((op) => {
      map[op?.value] = op?.label;
    });
  }

  return map;
};

export const METRIC_TYPE_MAP = createMap(METRIC_TYPE);
export const SLA_MAP = createMap(IS_SLA);
export const UNIT_MAP = createMap(UNIT);

export const FORM_KEY_MAP = {
  NAME: 'name',
  SLA: 'sla',
  METRIC_TYPE: 'type',
  METRIC_UNIT: 'unit',
  QUEUE: 'queue',
  SELECTED_STATE: 'selected_state',
  INCLUDED_STATES: 'included_states',
  SELECTED_MOVE_OUT: 'move_out',
  SELECTED_MOVE_IN: 'move_in',
  EXIT_CRETERIA_MOVE_OUT: 'moved_out_states',
  EXIT_CRETERIA_MOVE_IN: 'moved_in_states',
  ACTOR: 'actor',
  FIELDS: 'fields',
  NOTES: 'notes',
  CALCULATED_METRICS_EXPRESSION: 'expression',
  SELECTED_REFERRED_FIELD: 'selected_referred_field',
  SELECTED_FIELD: 'selected_field',
  REFERRED_FIELDS: 'referred_fields',
  UPDATED_AT: 'updated_at',
  LOG_TIME_FIELD: 'log_time_field',
  UPDATED_BY: 'updated_by',
};

export const renderUpdatedInfo = (rowData) => (
  <div className="text-center">
    <p className="text-[14px]">{rowData[FORM_KEY_MAP.UPDATED_BY]}</p>
    <p className="text-[12px] text-[#868E96]">
      {dayjs(rowData[FORM_KEY_MAP.UPDATED_AT]).format(DATE_FORMAT)}
    </p>
  </div>
);

export const deleteRenderer = ({
  onConfirm,
  title = 'Delete this',
  description = 'Are you sure you want to delete?',
  dataTestId = 'assigned dataTest id',
  isDisabled = false,
  buttonStyle,
}) => (
  <Popconfirm
    zIndex={9999}
    title={title}
    description={description}
    onConfirm={onConfirm}
    okText="Yes"
    cancelText="No"
    arrow={{
      pointAtCenter: true,
    }}
    placement="top"
    okButtonProps={{
      style: { backgroundColor: '#1677ff' },
    }}
    disabled={isDisabled}
  >
    <DeleteIcon
      className={
        isDisabled ? 'cursor-not-allowed opacity-60' : 'cursor-pointer'
      }
      data-testid={dataTestId}
      style={buttonStyle}
    />
  </Popconfirm>
);

export const columns = (showModal, onClone, onDelete) => [
  {
    name: FORM_KEY_MAP.NAME,
    col_name: FORM_KEY_MAP.NAME,
    type: 'string',
  },
  {
    name: FORM_KEY_MAP.METRIC_TYPE,
    col_name: FORM_KEY_MAP.METRIC_TYPE,
    type: 'string',
    cellRenderer: ({ rowData }) =>
      METRIC_TYPE_MAP[rowData?.[FORM_KEY_MAP.METRIC_TYPE]],
  },
  {
    name: FORM_KEY_MAP.SLA,
    col_name: FORM_KEY_MAP.SLA,
    type: 'string',
  },
  {
    name: 'Updated Info',
    col_name: 'Updated Info',
    headerStyle: {
      textAlign: 'center',
    },
    cellRenderer: ({ rowData }) => renderUpdatedInfo(rowData),
  },
  {
    name: 'actions',
    label: 'actions',
    col_name: 'Actions',
    cellRenderer: ({ rowData }) => (
      <div className="flex gap-[10px]">
        <EditIcon
          className="cursor-pointer"
          data-testid={`template-metrics-sla-${rowData.name}-edit`}
          onClick={() => showModal({ isEdit: true, rowData })}
        />
        {deleteRenderer({
          title: 'Delete this metric',
          description: 'Are you sure you want to delete this metric?',
          dataTestId: `template-metrics-sla-${rowData.name}-delete`,
          onConfirm: () => onDelete(rowData),
        })}
        <CopyIcon data-testid={`template-metrics-sla-${rowData.name}-clone`} className="cursor-pointer" onClick={() => onClone(rowData)} />
      </div>
    ),
  },
];
export const parseExpressionMetricForApi = (input) => {
  // Regular expression to match @(<UUID>)
  const regex = /@\(([^)]+)\)/g;

  // Replace the matched patterns with just the UUIDs
  const output = input.replace(regex, (match, p1) => p1);

  return output;
};
export const parseExpressionMetricForState = (input) => {
  // Regular expression to match UUIDs
  const uuidRegex = /\b[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}\b/g;

  // Replace each matched UUID with @(UUID)
  const output = input.replace(uuidRegex, (match) => `@(${match})`);

  return output;
};

export const removeUUIDFromExpression = (expression, uuidToRemove) => {
  // Define the regex pattern to match the exact UUID with @(UUID)
  const uuidPattern = new RegExp(uuidToRemove.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g');

  // Remove the UUID from the expression
  let modifiedExpression = expression.replace(uuidPattern, '').trim();

  // Clean up any leftover operators and spaces
  modifiedExpression = modifiedExpression.replace(/[\+\-\*/]\s*[\+\-\*/]/g, '');

  // Remove any leading or trailing operators
  modifiedExpression = modifiedExpression.replace(/^[\+\-\*/]\s*|\s*[\+\-\*/]$/g, '').trim();
  return modifiedExpression;
};
