import React from 'react';
import _get from 'lodash/get';
import { useColorModeValue, Box } from '@chakra-ui/react';
import { PropTypes } from 'prop-types';
import { isEmpty } from 'lodash';
import CustomSelect from '../../../components/SelectBox/Select';
import { LIGHT, DARK } from '../../../utils/enums/colors';
import DynamicMutliSelectWithTags from '../../../components/dynamicSelectWithInputTags/DynamicSelectWithInputTags';
import CustomInput from '../../../components/InputBox/Input';
import {
  roleOptions,
  approvalOptions,
} from './customEventAndTriggers.constant';
import { getWholeOption } from '../../TicketTemplateWorkflow/helper';

const ApprovalFlowEvent = ({
  eventSettings = {},
  handleChange,
  actionToPerformSelectedIndex,
  actionErrorData,
  handleActionErrorData,
}) => {
  const typeOfApproval = _get(
    eventSettings,
    `action_to_perform[${actionToPerformSelectedIndex}].type_of_approval`
  );

  const numberOfApprovalsRequired = _get(
    eventSettings,
    `action_to_perform[${actionToPerformSelectedIndex}].number_of_approvals_required`
  );
  const levels = _get(
    eventSettings,
    `action_to_perform[${actionToPerformSelectedIndex}].levels`
  );
  const flatPermittedRoles = _get(
    eventSettings,
    `action_to_perform[${actionToPerformSelectedIndex}].flat_permitted_roles`
  );
  const hierarchicalPermittedRoles = _get(
    eventSettings,
    `action_to_perform[${actionToPerformSelectedIndex}].hierarchical_permitted_roles`
  );

  const handleFieldChange = (value, field) => {
    handleChange(
      value,
      `action_to_perform[${actionToPerformSelectedIndex}].${field}`
    );
    handleActionErrorData(field);
  };
  const handleInputFieldChange = (event, field) => {
    handleChange(
      event.target.value,
      `action_to_perform[${actionToPerformSelectedIndex}].${field}`
    );
    handleActionErrorData(field);
  };
  const isError = !!actionErrorData?.status;
  const errorMessage = actionErrorData?.status || '';

  return (
    <Box marginTop="20px" data-testid="actorAssignmentBox">
      <CustomSelect
        label="Type of Approval"
        options={approvalOptions}
        placeholder="select type of approval"
        value={getWholeOption(typeOfApproval || '', approvalOptions)}
        onChange={(value) => handleFieldChange(value.value, 'type_of_approval')}
        focusBorderColor={useColorModeValue(LIGHT, DARK)}
        isError={isError}
        errorMessage={errorMessage}
        isMandatory
      />

      <Box mt={5}>
        {(typeOfApproval === 'FLAT' || isEmpty(typeOfApproval)) && (
          <CustomInput
            label="Number of approvals required"
            placeholder="1"
            value={numberOfApprovalsRequired}
            onChange={(value) =>
              handleInputFieldChange(value, 'number_of_approvals_required')
            }
            focusBorderColor={useColorModeValue(LIGHT, DARK)}
            isError={isError}
            errorMessage={errorMessage}
            isMandatory
          />
        )}
        {typeOfApproval === 'HEIRARCHICAL' && (
          <CustomInput
            label="Levels"
            placeholder="1"
            value={levels}
            onChange={(value) => handleInputFieldChange(value, 'levels')}
            focusBorderColor={useColorModeValue(LIGHT, DARK)}
            isError={isError}
            errorMessage={errorMessage}
            isMandatory
          />
        )}
      </Box>

      <Box mt={5}>
        {(typeOfApproval === 'FLAT' || isEmpty(typeOfApproval)) && (
          <DynamicMutliSelectWithTags
            label="Permitted Roles"
            selectOptions={roleOptions}
            values={flatPermittedRoles}
            onChange={(value) =>
              handleFieldChange(value, 'flat_permitted_roles')
            }
          />
        )}
        {typeOfApproval === 'HEIRARCHICAL' && (
          <DynamicMutliSelectWithTags
            label="Permitted Roles"
            selectOptions={roleOptions}
            values={hierarchicalPermittedRoles}
            onChange={(value) =>
              handleFieldChange(value, 'hierarchical_permitted_roles')
            }
          />
        )}
      </Box>
    </Box>
  );
};

ApprovalFlowEvent.propTypes = {
  eventSettings: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  actionToPerformSelectedIndex: PropTypes.number.isRequired,
  actionErrorData: PropTypes.object,
  handleActionErrorData: PropTypes.func.isRequired,
};

ApprovalFlowEvent.defaultProps = {
  actionErrorData: {},
};

export default ApprovalFlowEvent;
