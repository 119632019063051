import { RULE_SPECIFIC_STATUS_KEY } from '../../utils/enums/labels';

const addToListRestrictSpecialPermission = (transitionId, value, state) => {
  const updatedState = [...state];
  const index = updatedState.findIndex(
    (item) => item.transition_id === transitionId
  );
  if (index !== -1) {
    const item = updatedState[index];
    const newItem = {
      ...item,
      permissionValue: value,
    };
    updatedState.splice(index, 1, newItem);
  } else {
    const newItem = {
      [RULE_SPECIFIC_STATUS_KEY.TRANSITION_ID]: transitionId,
      permissionValue: value,
    };
    updatedState.push(newItem);
  }
  return [...updatedState];
};
const addOrUpdateRuleSpecificStatus = (transitionId, value, keyType, state) => {
  const updatedState = [...state];
  const index = updatedState.findIndex(
    (item) => item.transition_id === transitionId
  );
  if (index !== -1) {
    const item = updatedState[index];
    const newItem = {
      ...item,
      [keyType]: value,
    };
    updatedState.splice(index, 1, newItem);
  } else {
    const newItem = {
      [RULE_SPECIFIC_STATUS_KEY.TRANSITION_ID]: transitionId,
      [keyType]: value,
    };
    updatedState.push(newItem);
  }
  return [...updatedState];
};

const addToListRestrictMoveIssue = (transitionId, value, keyType, state) => {
  let issueFound = false;
  const updatedRestrictIssue = state.map((issue) => {
    if (issue.transition_id === transitionId) {
      issueFound = true;
      const existingValue = issue.list_restrict_type[keyType] || [];
      const updatedValue = Array.isArray(value)
        ? [...existingValue, ...value]
        : [...existingValue, value];
      const updatedListRestrictType = {
        ...issue.list_restrict_type,
        [keyType]: updatedValue,
      };
      return {
        ...issue,
        list_restrict_type: updatedListRestrictType,
      };
    }
    return issue;
  });
  if (!issueFound) {
    const newListRestrictType = {
      [keyType]: Array.isArray(value) ? value : [value],
    };
    updatedRestrictIssue.push({
      transition_id: transitionId,
      list_restrict_type: newListRestrictType,
      permissionExpression: {
        condition: '',
        list: [],
      },
    });
  }
  return updatedRestrictIssue;
};
const removeFromListRestrictMoveIssue = (
  transitionId,
  value,
  keyValue,
  state
) => {
  const updatedRestrictIssue = state.map((issue) => {
    if (issue.transition_id === transitionId) {
      const existingValue = issue.list_restrict_type[keyValue] || [];
      const updatedValue = existingValue.filter((v) => v !== value);
      if (existingValue.length === updatedValue.length) {
        // Value not found, no need to update state
        return issue;
      }
      const updatedListRestrictType = {
        ...issue.list_restrict_type,
        [keyValue]: updatedValue,
      };
      return {
        ...issue,
        list_restrict_type: updatedListRestrictType,
      };
    }
    return issue;
  });
  return updatedRestrictIssue;
};
const addToPermissionExpressionToRestrictMoveIssue = (
  transitionId,
  lhs,
  op,
  rhs,
  state
) => {
  const updatedRestrictIssue = state.map((issue) => {
    if (issue.transition_id === transitionId) {
      const updatedList = [
        ...issue.permissionExpression.list,
        {
          lhs,
          op,
          rhs,
        },
      ];
      const updatedPermissionExpression = {
        ...issue.permissionExpression,
        list: updatedList,
      };
      return {
        ...issue,
        permissionExpression: updatedPermissionExpression,
      };
    }
    return issue;
  });
  const isTransitionIdExists = updatedRestrictIssue.some(
    (issue) => issue.transition_id === transitionId
  );
  if (!isTransitionIdExists) {
    const newIssue = {
      transition_id: transitionId,
      list_restrict_type: {},
      permissionExpression: {
        condition: '',
        list: [
          {
            lhs,
            op,
            rhs,
          },
        ],
      },
    };
    updatedRestrictIssue.push(newIssue);
  }
  return updatedRestrictIssue;
};
const removeFromToPermissionExpressionToRestrictMoveIssue = (
  transitionId,
  lhs,
  op,
  rhs,
  state
) => {
  const updatedRestrictIssue = state.map((issue) => {
    if (issue.transition_id === transitionId) {
      const updatedList = issue.permissionExpression.list.filter(
        (item) => !(item.lhs === lhs && item.rhs === rhs && item.op === op)
      );
      const updatedPermissionExpression = {
        ...issue.permissionExpression,
        list: updatedList,
      };
      return {
        ...issue,
        permissionExpression: updatedPermissionExpression,
      };
    }
    return issue;
  });
  return updatedRestrictIssue;
};
const updatePermissionExpressionCondition = (transitionId, value, state) => {
  const updatedRestrictIssue = state.map((issue) => {
    if (issue.transition_id === transitionId) {
      const updatedPermissionExpression = {
        ...issue.permissionExpression,
        condition: value,
      };
      return {
        ...issue,
        permissionExpression: updatedPermissionExpression,
      };
    }
    return issue;
  });
  return updatedRestrictIssue;
};
const updateSpecificValue = (
  transitionId,
  fieldKey,
  fieldValue,
  keyValue,
  value,
  state
) => {
  const updatedState = [...state];
  const index = updatedState.findIndex(
    (item) =>
      item.transition_id === transitionId && item[fieldKey] === fieldValue
  );
  if (index !== -1) {
    const item = updatedState[index];
    const newItem = {
      ...item,
      [keyValue]: value,
    };
    updatedState.splice(index, 1, newItem);
  } else {
    const newItem = {
      [RULE_SPECIFIC_STATUS_KEY.TRANSITION_ID]: transitionId,
      [keyValue]: value,
    };
    updatedState.push(newItem);
  }
  return [...updatedState];
};

const workFlowRuleService = {
  addOrUpdateRuleSpecificStatus,
  addToListRestrictMoveIssue,
  removeFromListRestrictMoveIssue,
  addToPermissionExpressionToRestrictMoveIssue,
  removeFromToPermissionExpressionToRestrictMoveIssue,
  updatePermissionExpressionCondition,
  addToListRestrictSpecialPermission,
  updateSpecificValue,
};
export default workFlowRuleService;
