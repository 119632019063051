import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { Flex, useColorModeValue } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import CustomButton from '../../../components/Button/SimpleButton';
import CustomModal from '../../../components/Modal/Modal';
import { LABELS } from '../../../utils/enums/labels';
import { COLORS } from '../../../utils/enums/colors';
import { createNewActorInMasterList, reducerForState } from './helper';
import { setActiveModal } from '../../../redux/ModalReducer/ModalSlice';
import CreateActotContainer from './CreateActotContainer';
import { getTenantData } from '../../../helpers/tenantUrl';
import { showToastMessage } from '../utils/templateAPIUtils';

const CreateActorInMasterList = ({
  openCreateActorModal,
  setOpenCreateActorModal,
  actorDispatch,
  setActorOption,
}) => {
  const [createActorState, createDispatch] = useReducer(reducerForState, {});
  const handleActorDispatch = (name, value) => {
    actorDispatch({
      type: 'UPDATE_STATE',
      name,
      value,
    });
  };
  const dispatch = useDispatch();
  const addNewActorInMaster = async () => {
    const response = await createNewActorInMasterList({
      ...createActorState,
      tenant_id: getTenantData()?.id,
    });
    const errorMessage = response.error || 'Failed to create a new actor';
    if (!response.error) {
      const newOption = {
        value: response?.response.id,
        label: response?.response.name,
      };
      setActorOption((pre) => [newOption, ...pre]);
      handleActorDispatch('name', response?.response.name);
      handleActorDispatch('actor_id', response?.response.id);
      showToastMessage({
        title: 'Success',
        description: 'Actor is created at master list',
        status: 'success',
      });
    } else {
      setActorOption((pre) => [...pre]);
      showToastMessage({
        title: 'Something went wrong',
        description: errorMessage,
        status: 'error',
      });
    }
    setOpenCreateActorModal(false);
  };
  const handleNextButtonClick = () => {
    addNewActorInMaster();
  };
  return (
    <CustomModal
      id="selected-actor_add_edit-modal"
      modalTitle={
        <>
          <h1>Create New Actor</h1>
          {/* <Box className={styles.modalSubTitle}>{templateName}</Box> */}
        </>
      }
      modalContent={
        <CreateActotContainer
          createActorState={createActorState}
          createDispatch={createDispatch}
        />
      }
      modalFooter={
        <Flex gap="8px" data-testid="CreateActorInMasterListGrid">
          <CustomButton
            variant="outline"
            pl="25px"
            pr="25px"
            buttonColor="#868E96"
            buttonText={LABELS.ACTIONS.CANCEL}
            style={{ border: 'none' }}
            _hover={{ color: useColorModeValue(COLORS.BLACK, 'white') }}
            onClick={() => {
              setOpenCreateActorModal(false);
              // RESET_STATE
              createDispatch({ type: 'RESET_STATE' });
            }}
            size="lg"
            id="CreateActorInMasterListCancelBtn"
          />
          <CustomButton
            variant="solid"
            pl="25px"
            pr="25px"
            buttonColor="customBlue"
            isDisabled={!createActorState?.name}
            buttonText="Next"
            onClick={handleNextButtonClick}
            size="lg"
            id="CreateActorInMasterListNextBtn"
          />
        </Flex>
      }
      open={openCreateActorModal}
      setOpen={() => {
        setOpenCreateActorModal(!openCreateActorModal);
        dispatch(setActiveModal({ targetModalId: undefined }));
      }}
    />
  );
};
CreateActorInMasterList.propTypes = {
  openCreateActorModal: PropTypes.bool.isRequired,
  setOpenCreateActorModal: PropTypes.func.isRequired,
  actorDispatch: PropTypes.func.isRequired,
  setActorOption: PropTypes.func.isRequired,
};
export default CreateActorInMasterList;
