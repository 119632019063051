/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { isNaN } from 'lodash';
import { FieldTypes } from '../../../../utils/enums/types';
import CustomInput from '../../../../components/InputBox/Input';
import CustomDatepicker from '../../../../components/Datepicker/DatePicker';
import CustomSwitch from '../../../../components/Switch/Switch';
import CustomSelectBox from '../../../../components/SelectBox/Select';
import { PrimaryCustomButton } from '../../../../components/Button/PrimarySecondaryButton';

/* eslint-disable import/prefer-default-export */
export const renderFieldsBasedonType = (item) => {
  switch (item?.type) {
    case FieldTypes.RICH_TEXT:
      return <ReactQuill style={{ height: '70%', paddingBottom: '30px' }} />;
    case FieldTypes.TEXT:
      return <CustomInput type="text" />;
    case FieldTypes.EMAIL:
      return <CustomInput type="email" />;
    case FieldTypes.NUMBER:
      return <CustomInput />;
    case FieldTypes.DATE:
      return <CustomDatepicker />;
    case FieldTypes.BOOLEAN:
      return <CustomSwitch />;
    case FieldTypes.METRICS:
      return <CustomInput />;
    case FieldTypes.MEDIA:
      return <CustomInput />;
    case FieldTypes.TAGS:
      return <CustomInput />;
    case FieldTypes.DROPDOWN:
      return <CustomSelectBox />;
    case FieldTypes.RELATION:
      return <CustomInput />;
    case FieldTypes.COMPOSITE:
      return <CustomInput />;
    case FieldTypes.FIELDSETS:
      return <CustomInput />;
    case 'SF_NOTES':
      return <NoteField />;
    case 'SF_LINKED_TICKETS':
      return <LinkedTicketsField />;
    case 'SF_HISTORY':
      return <HistoryField />;
    case 'SF_WORKFLOW_TRANSITION_BUTTON':
      return <WorflowTransitionButtonField />;
    default:
      return <CustomInput />;
  }
};

export const NoteField = () => {
  const [comment, setComment] = useState('');
  const [commentList, setCommentList] = useState([
    { comment: 'Rohit do this', date: Date.now() },
  ]);

  const convertDate = (val) => {
    let value = val;
    const dateObj = new Date(value);
    if (dateObj instanceof Date && !isNaN(dateObj)) {
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      value = dateObj.toLocaleString('en-US', options).replace('at', ',');
    }
    return value;
  };

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        data-testid="RenderFieldsBasedOnTypesContainerBox"
      >
        <CustomInput
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
          }}
        />
        <PrimaryCustomButton
          buttonText="Add"
          onClick={() => {
            if (comment) {
              setCommentList([...commentList, { comment, date: Date.now() }]);
            }
            setComment('');
          }}
          id="RenderFieldsBasedOnTypesAddBtn"
        />
      </Box>
      {commentList?.map((i) => (
        <Box
          key={i.date}
          data-testid={`RenderFieldsBasedOnTypesDateBox${i}div`}
        >
          <div>{i.comment}</div>
          <div>{convertDate(i.date)}</div>
        </Box>
      ))}
    </div>
  );
};

export const LinkedTicketsField = () => {
  const [ticket, setticket] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [linkedTicketList, setLinkedTicketList] = useState([
    { name: 'ASC-123', subject: 'Test', status: 'In Progress' },
  ]);

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        data-testid="RenderFieldsBasedOnTypesCSelectBox"
      >
        <CustomSelectBox
          value={ticket}
          onChange={(e) => {
            setticket(e);
          }}
          options={[]}
          id="RenderFieldsBasedOnTypesCSelect"
        />
        <PrimaryCustomButton
          buttonText="Link"
          onClick={() => {}}
          id="RenderFieldsBasedOnTypesLinkBtn"
        />
      </Box>
      <div data-testid="RenderFieldsBasedOnTypesTicketTextDiv">
        Linked Tickets
      </div>
      {linkedTicketList?.map((i) => (
        <Box
          key={i.name}
          data-testid={`RenderFieldsBasedOnTypesContainerBox${i}Div`}
        >
          <div data-testid={`RenderFieldsBasedOnTypes${i}nameDiv`}>
            {i.name}
          </div>
          <div
            data-testid={`RenderFieldsBasedOnTypes${i}StatusDiv`}
          >{`Subject: ${i.subject} || Status ${i.status}`}</div>
        </Box>
      ))}
    </div>
  );
};

export const HistoryField = () => {
  // eslint-disable-next-line no-unused-vars
  const [historyItems, setHistoryItems] = useState([
    {
      title: 'Ticket Linked',
      time: '6 mins ago',
      description: 'Ticket Moved to In pRogress',
    },
  ]);

  return (
    <div data-testid="RenderFieldsBasedOnTypesHistoryDiv">
      <div data-testid="RenderFieldsBasedOnTypesHistoryTextDiv">History </div>
      {historyItems?.map((i) => (
        <Box
          key={i.title}
          borderBottom="1px"
          data-testid={`RenderFieldsBasedOnTypesHistory${i}Box`}
        >
          <div data-testid={`RenderFieldsBasedOnTypesHistory${i}TitleBox`}>
            {i.title} ({i.time})
          </div>
          <div data-testid={`RenderFieldsBasedOnTypesHistory${i}DescBox`}>
            {i.description}
          </div>
        </Box>
      ))}
    </div>
  );
};

export const WorflowTransitionButtonField = () => {
  const [selectedWorkflow, setSelectedWorkflow] = useState('In Progress');
  return (
    <Menu data-testid="RenderFieldsBasedOnTypesWorkflowMenu">
      <MenuButton
        as={Button}
        colorScheme="blue"
        data-testid="RenderFieldsBasedOnTypesWorkflowMenuBtn"
      >
        {selectedWorkflow}
      </MenuButton>
      <MenuList data-testid="RenderFieldsBasedOnTypesWorkflowMenuList">
        <MenuItem
          onClick={() => setSelectedWorkflow('In Progress')}
          data-testid="RenderFieldsBasedOnTypesWorkflowMenuItem"
        >
          In Progress
        </MenuItem>
        <MenuItem
          onClick={() => setSelectedWorkflow('To Do')}
          data-testid="RenderFieldsBasedOnTypesWorkflowToDoMenuItem"
        >
          {' '}
          To Do{' '}
        </MenuItem>
        <MenuItem
          onClick={() => setSelectedWorkflow('Done')}
          data-testid="RenderFieldsBasedOnTypesWorkflowDoneMenuItem"
        >
          Done
        </MenuItem>
        <MenuItem
          onClick={() => setSelectedWorkflow('UX Review')}
          data-testid="RenderFieldsBasedOnTypesWorkflowReviewMenuItem"
        >
          UX -Review
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
