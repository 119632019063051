import { useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import { BLACK } from '../../../utils/enums/colors'
import CustomModal from '../../../components/Modal/Modal'
import { Flex } from 'antd'
import CustomButton from '../../../components/Button/SimpleButton'

export const ConfirmModel = ({ openAlertModal, onCancelOverWriteWorkflow, onConfirmOverWrite }) => {
    return <CustomModal
        id="selected-feedback-modal"
        data-testid="feedbackModal"
        modalTitle={
            <>
                <h1>Alert</h1>
            </>
        }
        modalContent={<p>This Work flow Already linked to this template do you want to Overwrite it</p>}
        modalFooter={
            <Flex gap="8px" data-testid="FeedBackModalFlex">
                <CustomButton
                    variant="outline"
                    pl="25px"
                    pr="25px"
                    buttonColor="#868E96"
                    buttonText={'Cancel'}
                    style={{ border: 'none' }}
                    _hover={{ color: useColorModeValue(BLACK, 'white') }}
                    onClick={() => {
                        onCancelOverWriteWorkflow(false);
                    }}
                    size="lg"
                    id="feedbackModal_cancel_button"
                />
                <CustomButton
                    variant="solid"
                    pl="25px"
                    pr="25px"
                    buttonColor="customBlue"
                    buttonText="Submit"
                    onClick={() => {
                        onConfirmOverWrite(false)
                    }}
                    size="lg"
                    id="feedbackModal_submit_btn"
                />
            </Flex>
        }
        open={openAlertModal}
        setOpen={() => {
            onCancelOverWriteWorkflow(!openAlertModal);
        }}
    />
}
