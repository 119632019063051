/* eslint-disable import/no-cycle */
import React, { useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import AudienceEditModal from './AudienceEditModal/AudienceEditModal';
import NoRecords from '../../../components/Table/NoRecords';
import TableViewWithSearch from '../../../components/Table/TableWithSearch';

import { updateViewState } from '../../../redux/ViewSlice/ViewReducer';
import { AUDIENCE_KEY_MAP, columns } from './AudienceEditModal/audience.utls';
import { patchDataForTemplate } from '../../TicketTemplateBuilder/utils/templateAPIUtils';

const Audience = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const AUDIENCE = useSelector(
    (state) => state?.viewState?.selectedTemplate?.audience || []
  );

  const [showEditModal, setShowEditModal] = useState(false);

  const selectedAudience = useRef({});

  const onSave = async (data) => {
    const tempData = Array.isArray(AUDIENCE) ? [...AUDIENCE] : [];
    const index = typeof selectedAudience.current?.rowIndex === 'number';

    if (index) {
      const rowIndex = selectedAudience.current?.rowIndex;
      tempData[rowIndex] = data;
    } else {
      tempData.push(data);
    }

    const res = await patchDataForTemplate({
      id,
      data: tempData,
      key: 'audience',
      defaultErrorMessage: index
        ? 'Failed to edit audience'
        : 'Failed to create audience',
      successMessage: index
        ? 'Successfully edited audience'
        : 'Successfully created audience',
    });

    if (!res.error) {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: res?.response?.data,
        })
      );
      setShowEditModal(false);
    }
  };

  const onClone = async (rowData) => {
    const tempId = uuidv4();
    const newData = {
      ...rowData,
      id: tempId,
      [AUDIENCE_KEY_MAP.NAME]: `${rowData?.name || ''} (Copy)`,
    };

    const tempData = Array.isArray(AUDIENCE) ? [...AUDIENCE] : [];
    const rowIndex = tempData.findIndex((a) => a.id === rowData.id);
    tempData.splice(rowIndex + 1, 0, newData);

    const res = await patchDataForTemplate({
      id,
      data: tempData,
      key: 'audience',
      defaultErrorMessage: 'Failed to clone audience',
      successMessage: 'Successfully cloned audience',
    });

    if (!res.error) {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: res?.response?.data,
        })
      );
    }
  };

  const onDelete = async (rowId) => {
    const tempData = Array.isArray(AUDIENCE) ? [...AUDIENCE] : [];
    const rowIndex = tempData.findIndex((a) => a.id === rowId);
    tempData.splice(rowIndex, 1);

    const res = await patchDataForTemplate({
      id,
      data: tempData,
      key: 'audience',
      defaultErrorMessage: 'Failed to delete audience',
      successMessage: 'Successfully deleted audience',
    });
    if (!res.error) {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: res?.response?.data,
        })
      );
    }
  };

  const onEditClick = (rowData) => {
    selectedAudience.current = {
      ...rowData,
      rowIndex: AUDIENCE?.findIndex((a) => a.id === rowData.id),
    };
    setShowEditModal(true);
  };

  if (showEditModal) {
    return (
      <div data-testid="AudienceEditModalDiv">
        <AudienceEditModal
          navigateBack={() => {
            setShowEditModal(false);
          }}
          onSave={onSave}
          selectedData={selectedAudience.current}
        />
      </div>
    );
  }

  return (
    <div className="px-[32px]" data-testid="AudienceContainerDiv">
      <p className="text-[29px] font-semibold" data-testid="AudienceTextP">
        {' '}
        Audience{' '}
      </p>

      {!AUDIENCE || !AUDIENCE.length ? (
        <div className="pt-[50px]" data-testid="AudienceNoRecordsDiv">
          <NoRecords
            title="You have not added audience"
            subTitle="Click 'Create New' to get started"
            buttonText="Create New"
            onAdd={() => {
              selectedAudience.current = {};
              setShowEditModal(true);
            }}
          />
        </div>
      ) : (
        <div className="py-[16px]" data-testid="AudienceTableViewDIv">
          <TableViewWithSearch
            placeholder="Search"
            buttonText="Create New"
            onCreateNew={() => {
              selectedAudience.current = {};
              setShowEditModal(true);
            }}
            columns={columns({ onClone, onEditClick, onDelete })}
            tableData={AUDIENCE || []}
          />
        </div>
      )}
    </div>
  );
};

export default Audience;
