/* eslint-disable no-plusplus */
import { Box, Grid, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BORDER_DARK } from '../../utils/enums/colors';
import DynamicModalRenderer from './DynamicModalRenderer';
import { checkVisibility } from './utils';

const PreviewBuilder = React.memo(({ items, viewLayout }) => {
  const [itemsState, setItemsState] = useState([]);
  const { viewState } = useSelector((state) => state);

  const initialSetUp = (arr, viewLay) => {
    if (JSON.stringify(viewLay) === JSON.stringify({})) {
      setItemsState(arr);
    } else {
      const merged = [];
      for (let i = 0; i < viewLay?.rows?.length; i++) {
        merged.push({
          ...viewLay?.rows[i],
          ...arr.find(
            (itmInner) => itmInner?.props?.name === viewLay?.rows[i].i
          ),
        });
      }
      setItemsState(merged);
    }
  };

  useEffect(() => {
    if (items?.length) initialSetUp(items, viewLayout);
  }, [items]);

  return (
    <>
      <Grid
        border="0px"
        borderStyle="solid"
        pl="2"
        pr="2"
        width={viewLayout?.width || '100%'}
        borderRadius="10px"
        borderColor={useColorModeValue('rgba(0,0,0,0.35)', BORDER_DARK)}
        templateColumns={`repeat(${viewLayout?.no_of_cols}, 1fr)`}
        gap={4}
        data-testid="PreviewBuilderGrid"
      >
        {itemsState?.map((item) =>
          checkVisibility(item, viewState) ? (
            <Box
              key={item?.id}
              position="relative"
              gridColumnEnd={`span ${item?.w || 12}`}
              borderRadius="0px"
              display="block"
              alignItems="center"
              justifyContent="space-between"
              borderColor={useColorModeValue('#F5F5F5', BORDER_DARK)}
              data-testid="PreviewBuilderBox"
            >
              {item?.props?.children ? item : <Outlet />}
            </Box>
          ) : (
            ''
          )
        )}
      </Grid>
      <DynamicModalRenderer />
    </>
  );
});

PreviewBuilder.propTypes = {
  items: PropTypes.array.isRequired,
  viewLayout: PropTypes.object.isRequired,
};

export default PreviewBuilder;
