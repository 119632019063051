import React, { useState, useEffect } from "react";
import ConnectWorkflowCard from './ConnectWorkflowCard'
import FlowWithProvider from "../../TicketTemplateWorkflow/FlowWithProvider";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import _get from 'lodash/get';
import { Box } from "@chakra-ui/react";


export const WorkflowMain = () => {
    const [workflowName, setWorkflowName] = useState('');
    const { id } = useParams();
    const selectedTemplate = useSelector(
        (state) => state?.viewState?.selectedTemplate
    );
    // const selectedWorkFlow = useSelector(
    //     (state) => state?.WorkflowMain?.selectedWorkFlow
    // );

    const fetchWorkFlowFromTemplate = async () => {
        if (selectedTemplate?.workflow) {
            setWorkflowName(selectedTemplate?.workflow?.name);
        } else {
            setWorkflowName('');
        }
    };

    useEffect(() => {
        fetchWorkFlowFromTemplate();
    }, [id, selectedTemplate]);
    let renderUI
    if (workflowName) {
        renderUI = <FlowWithProvider id={id}/>
    } else {
        renderUI = <>
            <Box fontSize="16px" marginBottom='3' fontWeight="600">Workflow</Box>
            <Box height= 'calc(100vh - 19rem)' overflow='auto'>
                <ConnectWorkflowCard />
            </Box>
        </>
    }
    return <Box paddingTop="19px">
        {renderUI}
    </Box>
}