import React, { useEffect, useState } from 'react';
import {
  // FormControl,
  // FormLabel,
  // Input,
  // Button,
  Box,
  // Text,
} from '@chakra-ui/react';
import Joi from 'joi';
import { validationErrorMessage } from '../../utils/validationSchemas/validationSchemas';
import SelectFieldFromTemplateWithDelete from '../../components/selectFieldFromTemplateWithDelete';
import {
  DEFAULT_VALUE_SELECT,
  DEFAULT_WORKFLOW_STATUS,
  DUMMY_FIELD_LIST,
  getTeamQueueMap,
} from '../../components/selectFieldFromTemplateWithDelete/utils';

const validationSchema = Joi.object({
  name: Joi.string().required().messages({
    'string.empty': 'Name is not required.',
  }),
  age: Joi.number().integer().min(18).required(),
}).unknown();

const MyForm = () => {
  // eslint-disable-next-line no-unused-vars
  const [formData, setFormData] = useState({
    name: '',
    age: '',
    email: '',
  });
  // eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = useState({});
  const [teamQueueMap, setTeamQueueMap] = useState([]);
  useEffect(() => {
    getTeamQueueMap().then(setTeamQueueMap).catch(() => setTeamQueueMap([]));
  }, []);

  // const handleInputChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { error } = validationSchema.validate(formData, {
      abortEarly: false,
    });

    if (error) {
      setErrors(validationErrorMessage(error));
      return;
    }

    setErrors({});
    // Submit the form data if it's valid
    // ...
  };

  return (
    <Box as="form" onSubmit={handleSubmit} data-testid="MyFormContainerBox">
      {/* <FormControl data-testid="MyFormNameFormControl">
        <FormLabel htmlFor="name" data-testid="MyFormNameFormLabel">
          Name
        </FormLabel>
        <Input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          data-testid="MyFormNameInput"
        />
        {errors.name && <Text color="red">{errors.name}</Text>}
      </FormControl>

      <FormControl data-testid="MyFormAgeFormControl">
        <FormLabel htmlFor="age" data-testid="MyFormAgeFormLabel">
          Age
        </FormLabel>
        <Input
          type="number"
          id="age"
          name="age"
          value={formData.age}
          onChange={handleInputChange}
          data-testid="MyFormAgeInput"
        />
        {errors.age && <Text color="red">{errors.age}</Text>}
      </FormControl>

      <FormControl data-testid="MyFormEmailFormControl">
        <FormLabel htmlFor="email" data-testid="MyFormEmailFormLabel">
          Email
        </FormLabel>
        <Input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          data-testid="MyFormEmailInput"
        />
        {errors.email && <Text color="red">{errors.email}</Text>}
      </FormControl>

      <Button type="submit">Submit</Button> */}
      <SelectFieldFromTemplateWithDelete
        teamQueueMap={teamQueueMap}
        fieldList={DUMMY_FIELD_LIST}
        value={DEFAULT_VALUE_SELECT}
        workFlowStatusList={DEFAULT_WORKFLOW_STATUS.filter(
          (item) => item.id !== 'start_1'
        ).map((ele) => ({ value: ele.id, label: ele.data.label }))}
        label="Update Record"
        // eslint-disable-next-line no-console
        onChange={(value) => console.log(value, 'LIST')}
        isRequired
      />
    </Box>
  );
};

export default MyForm;
