import React from 'react';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Box, Flex } from '@chakra-ui/layout';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import CustomButton from '../../../components/Button/SimpleButton';
import CustomModal from '../../../components/Modal/Modal';
import { COLORS } from '../../../utils/enums/colors';
import DeleteIcon from '../../../assets/icons/DeleteIcon.svg';
import { LABELS } from '../../../utils/enums/labels';
import styles from '../styles/CategoryList.module.scss';
import { DangerCustomButton, SecondaryCustomButton } from '../../../components/Button/PrimarySecondaryButton';

const RuleDeleteModal = ({
  isDeleteModal,
  setIsDeleteModal,
  title,
  subTitle,
  onConfirmation,
  onDeleteCancelClick,
}) => {
  const colorType = useColorModeValue('light', 'dark');
  return (
    <CustomModal
      className={styles.footer}
      id="delete-modal"
      modalContent={
        <Flex
          flex="1"
          align="center"
          p="2"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          justifyContent="center"
          height="100%"
          data-testid="RuleDeleteModalFlex"
        >
          <Box
            // bg={useColorModeValue('white', 'gray.900')}
            align="center"
            justifyContent="center"
            data-testid="RuleDeleteModalBox"
          >
            <Box
              width="50"
              height="50"
              mt="5"
              data-testid="RuleDeleteModalSvgBox"
            >
              <ReactSVG
                src={DeleteIcon}
                className={`${styles.statusDelete} p-2 ${
                  colorType === 'dark' ? styles.colorWhite : ''
                }`}
                data-testid="RuleDeleteModalReactSvg"
              />
            </Box>
            <Box
              className={styles.createHeadText}
              color={useColorModeValue('', 'white')}
              data-testid="RuleDeleteModalTitleBox"
            >
              {title}
            </Box>
            <div
              className={styles.paraTex}
              data-testid="RuleDeleteModalParaTexDiv"
            >
              {subTitle}
            </div>
            <div className="mt-10 flex gap-4 items-center justify-center" data-testid="RuleDeleteModalCancelDiv">
              <SecondaryCustomButton
                id="RuleDeleteModalPaCanceltextBtn"
                onClick={onDeleteCancelClick}
                buttonText={LABELS.ACTIONS.CANCEL}
              />
              <DangerCustomButton
                id="RuleDeleteModalPaCancelconfirm-delete"
                onClick={() => onConfirmation()}
                buttonText={LABELS.ACTIONS.DELETE}
              />
            </div>
          </Box>
        </Flex>
      }
      open={isDeleteModal}
      setOpen={setIsDeleteModal}
      size="sm"
    />
  );
};

RuleDeleteModal.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  isDeleteModal: PropTypes.bool.isRequired,
  setIsDeleteModal: PropTypes.func.isRequired,
  onConfirmation: PropTypes.func.isRequired,
  onDeleteCancelClick: PropTypes.func.isRequired,
};
export default RuleDeleteModal;
