/* eslint-disable no-lone-blocks */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable-next-line no-lone-blocks */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@chakra-ui/layout';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { map, first, get, filter, includes } from 'lodash';
import { Grid, GridItem, Text } from '@chakra-ui/react';
import CustomSelectBox from '../../components/SelectBox/Select';
import { DARK, LIGHT } from '../../utils/enums/colors';
// eslint-disable-next-line import/no-cycle
import DynamicMutliSelectWithButtonTags from '../../components/dynamicSelectWithTags/DynamicSelectWithButonTags';
import SearchListWithButtonTags from '../../components/search/searchListWithButtonTags';
import customStylesSelectWithHeight40 from '../../context/CustomTheme/CustomStylesSelectWithHeight40';
import { DEPENDENT_FIELD_KEY_MAP } from './utils';
import { DROPDOWN_TYPES } from '../TenantFilters/filters.constants';

const DependentFieldDynamicDropdown = ({
  onChange,
  rowData,
  onChangeDependentValues,
}) => {
  const { selectedTemplate } = useSelector((state) => state.viewState || {});

  const [firstDropdown, setFirstDropdown] = useState({});
  const [secondDropdown, setSecondDropdown] = useState({});
  const [firstValue, setFirstValue] = useState('');
  const [secondValue, setSecondValue] = useState([]);
  const [staticFieldList, setStaticFieldList] = useState([]);

  useEffect(() => {
    const fieldList = filter(
      selectedTemplate.fields,
      (field) =>
        field?.type_based_attributes?.collection?.collection_type === DROPDOWN_TYPES.STATIC
    );
    setStaticFieldList(fieldList);
  }, [selectedTemplate]);

  const getDropdownValue = (id) => {
    const data = first(
      filter(staticFieldList, (fieldData) => fieldData.id === id)
    );
    return { value: data?.id, label: data?.label };
  };

  const getOptionsForSelectedDropdown = (fieldID) => {
    const field = first(filter(staticFieldList, (item) => item.id === fieldID));
    const staticOptions = get(
      field,
      'type_based_attributes.collection.staticValues',
      []
    );
    return staticOptions;
  };

  useEffect(() => {
    setFirstDropdown(getDropdownValue(rowData?.dependencies?.id));
    setSecondDropdown(getDropdownValue(rowData?.dependent_field_id));
  }, [rowData]);

  const getFieldDataOptions = useMemo(
    () => () =>
      map(
        filter(
          selectedTemplate.fields,
          (field) =>
            field?.type_based_attributes?.collection?.collection_type ===
            DROPDOWN_TYPES.STATIC
        ),
        (fieldData) => ({
          value: fieldData.id,
          label: fieldData.label,
        })
      ),
    [selectedTemplate]
  );

  const onSelectFirstValue = (val) => {
    setFirstValue(val.label);
    const tempData = rowData?.dependencies?.values;
    const options =
      filter(
        getOptionsForSelectedDropdown(rowData?.dependent_field_id),
        (field) => includes(tempData[val.label], field.label)
      ) || [];
    setSecondValue(options);
  };

  return (
    <Box data-testid="DynamicMutliSelectWithButtonTagsContainerBox">
      <Grid
        templateColumns="6fr 5fr 1fr"
        gap="30px"
        pt="15px"
        data-testid="TicktFieeldContentMainGrid"
      >
        <GridItem w="100%" data-testid="TicktFieeldContentGridIttem">
          <Flex alignItems="center">
            <Text mr="2">If</Text>
            <Box flex="1">
              <CustomSelectBox
                placeholder="Select Value"
                options={getFieldDataOptions() || []}
                value={firstDropdown}
                styles={customStylesSelectWithHeight40}
                onChange={(e) => {
                  setFirstDropdown(e);
                  setSecondDropdown({});
                  setFirstValue('');
                  setSecondValue([]);
                  onChange(
                    e.value,
                    `${DEPENDENT_FIELD_KEY_MAP.DEPENDENCIES}.${DEPENDENT_FIELD_KEY_MAP.ID}`
                  );
                }}
              />
            </Box>
            <Text ml="3" mr="2">
              is selected,
            </Text>
          </Flex>
        </GridItem>
        <GridItem w="100%" data-testid="TicktFieeldContentGridIttem">
          {firstDropdown?.value ? (
            <Flex alignItems="center">
              <Text mr="1">then apply</Text>
              <Box flex="1">
                <CustomSelectBox
                  placeholder="Select Value"
                  options={
                    filter(
                      getFieldDataOptions(),
                      (item) => item.value !== firstDropdown.value
                    ) || []
                  }
                  value={secondDropdown}
                  onChange={(e) => {
                    setSecondDropdown(e);
                    onChange(
                      e.value,
                      `${DEPENDENT_FIELD_KEY_MAP.DEPENDENT_FIELD_ID}`
                    );
                  }}
                  styles={customStylesSelectWithHeight40}
                />
              </Box>
            </Flex>
          ) : null}
        </GridItem>
      </Grid>
      <Grid templateColumns="repeat(3, 1fr)" mt="30px">
        <GridItem>
          {firstDropdown.value ? (
            <SearchListWithButtonTags
              label={firstDropdown.label}
              data={getOptionsForSelectedDropdown(firstDropdown.value)}
              onChange={() => {}}
              onSelectFirstValue={(val) => onSelectFirstValue(val)}
              valueData={firstValue || ''}
            />
          ) : null}
        </GridItem>
        <GridItem>
          {secondDropdown.value ? (
            <DynamicMutliSelectWithButtonTags
              label={secondDropdown.label}
              valueData={secondValue}
              onChange={(selectedLabel, tags) =>
                onChangeDependentValues(selectedLabel, tags)
              }
              selectOptions={getOptionsForSelectedDropdown(
                secondDropdown.value
              )}
              firstValue={firstValue}
            />
          ) : null}
        </GridItem>
      </Grid>
    </Box>
  );
};

DependentFieldDynamicDropdown.propTypes = {
  rowData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeDependentValues: PropTypes.func.isRequired,
};

DependentFieldDynamicDropdown.defaultProps = {};

export default DependentFieldDynamicDropdown;
