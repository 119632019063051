import React, { useCallback, useEffect, useRef, useState } from 'react';

import { IconButton } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import styles from './carousel.module.scss';

const Carousel = ({
  children,
  wrapperClassName,
  buttonProps,
  listenForOpenMenuChange,
}) => {
  const getSideMenuLocalStorageValue = useCallback(
    () => localStorage.getItem('openMenu'),
    []
  );

  const sliders = useRef(null);
  const previousSideMenuValue = useRef(getSideMenuLocalStorageValue());

  const [scrollPerClick, setScrollPerClick] = useState(0);
  const [scrollAmount, setScrollAmount] = useState(0);
  const [isLastChild, setIsLastChild] = useState(false);

  const checkIfLastChildIsVisible = () => {
    if (sliders.current) {
      const { lastChild } = sliders.current;
      if (lastChild) {
        const { right: lastChildRightCoords } =
          lastChild.getBoundingClientRect();
        const { right: containerRightCoords } =
          sliders.current.getBoundingClientRect();
        if (lastChildRightCoords <= containerRightCoords) {
          setIsLastChild(true);
        } else {
          setIsLastChild(false);
        }
      }
    }
  };

  const scrollLeft = () => {
    const tempScrollAmount = scrollAmount - scrollPerClick;

    sliders.current?.scrollTo({
      top: 0,
      left: tempScrollAmount,
      behavior: 'smooth',
    });

    if (tempScrollAmount < 0) {
      setScrollAmount(0);
    } else {
      setScrollAmount(tempScrollAmount);
    }
    setIsLastChild(false);
  };

  const scrollRight = () => {
    if (!isLastChild) {
      const tempScrollAmount = scrollAmount + scrollPerClick;
      sliders.current?.scrollTo({
        top: 0,
        left: tempScrollAmount,
        behavior: 'smooth',
      });
      setScrollAmount(tempScrollAmount);
    }
  };

  const onScrollEvent = debounce(() => {
    checkIfLastChildIsVisible();
  }, 25);

  const setScrollPerClickValue = useCallback(() => {
    const firstChild = sliders.current?.firstChild;
    if (firstChild) {
      const containerWidth = sliders.current?.clientWidth;
      const eachChildWidth = firstChild.clientWidth;

      const tempScrollPerClick = Math.floor(containerWidth / eachChildWidth);
      setScrollPerClick((tempScrollPerClick - 1) * eachChildWidth);
    }
  }, []);

  const onSideMenuToggle = debounce(() => {
    const newValue = getSideMenuLocalStorageValue();
    if (newValue !== previousSideMenuValue.current) {
      setScrollPerClickValue();
      onScrollEvent();
      previousSideMenuValue.current = newValue;
    }
  }, 25);

  useEffect(() => {
    checkIfLastChildIsVisible();
  }, [children?.length]);

  useEffect(() => {
    setScrollPerClickValue();
    sliders.current?.addEventListener('scroll', onScrollEvent);

    if (listenForOpenMenuChange) {
      window.addEventListener('local-storage', onSideMenuToggle);
    }

    return () => {
      sliders.current?.removeEventListener('scroll', onScrollEvent);
      window.removeEventListener('local-storage', onSideMenuToggle);
    };
  }, []);

  return (
    <div
      className={`${styles.carousel} ${wrapperClassName}`}
      data-testid="CarouselParentDiv"
    >
      <div
        ref={sliders}
        className={`${styles.carouselbox} carousel__container`}
        style={{
          marginLeft: scrollAmount > 0 ? 45 : 0,
          marginRight: isLastChild ? 0 : 45,
        }}
        data-testid="CarouselBoxDiv"
      >
        {children}
      </div>

      {scrollAmount > 0 ? (
        <IconButton
          aria-label="Previous button"
          icon={<ChevronLeftIcon />}
          onClick={scrollLeft}
          className={`${styles.switchLeft} ${styles.sliderButton} prev__button`}
          {...buttonProps}
          data-testid="CarouselPrevIconButton"
        />
      ) : null}

      {!isLastChild ? (
        <IconButton
          aria-label="Next button"
          icon={<ChevronRightIcon />}
          onClick={scrollRight}
          className={`${styles.switchRight} ${styles.sliderButton} next__button`}
          {...buttonProps}
          data-testid="CarouselNextIconButton"
        />
      ) : null}
    </div>
  );
};

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  wrapperClassName: PropTypes.string,
  buttonProps: PropTypes.object,
  listenForOpenMenuChange: PropTypes.bool,
};

Carousel.defaultProps = {
  wrapperClassName: '',
  buttonProps: {},
  listenForOpenMenuChange: false,
};

export default Carousel;
