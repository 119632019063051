/* eslint-disable no-restricted-syntax */
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep, capitalize, isEmpty } from 'lodash';

import { saveState } from '../../pages/TicketTemplateBuilder/utils/helper';
import axiosInstance from '../../utils/axios/axios';
import { checkForV2 } from '../../utils/helper';
import {
  checkPermissionForTemplatesWithData,
  patchDataForTemplate,
} from '../../pages/TicketTemplateBuilder/utils/templateAPIUtils';
import { APPROVAL_TYPES } from '../../utils/enums/types';
import { updateViewState } from '../ViewSlice/ViewReducer';

const workFlowListUrl = 'workflows/list';
const templateListUrl = 'templates/list';
const workFlowUpdateUrl = 'workflows/update';

const SUCCESS_STATUS = 'success';
const ERROR_STATUS = 'error';

const updateTooltipObj = ({ res, toolTipObj, successMessage }) => {
  if (res?.error) {
    toolTipObj.title = 'Something went wrong';
    toolTipObj.description = res.error;
    toolTipObj.info = ERROR_STATUS;
  } else if (res?.response) {
    toolTipObj.title = 'Success';
    toolTipObj.description = successMessage;
    toolTipObj.info = SUCCESS_STATUS;
  }
};

const sanitizeRule = (rule) => {
  if (rule.ruleType === 'moveIssue') {
    return rule;
  }

  const newRuleObject = {
    ...rule
  };
  delete newRuleObject.approvals;
  delete newRuleObject.approvalsType;

  return newRuleObject;
};

const sanitizeData = (data) => ({
  ...data,
  workflow_rules: {
    ...data.workflow_rules,
    list: data.workflow_rules.list.map(sanitizeRule),
  }
});

const updateWorkFlowForTemplate = async ({
  templateId,
  data,
  defaultErrorMessage,
  successMessage,
  toolTipObj,
  templateProperties,
}) => {
  const hasAccess = checkPermissionForTemplatesWithData({
    data: templateProperties,
    key: 'editors',
    displayToastMessage: false,
  });

  if (hasAccess?.error) {
    toolTipObj.title = '';
    toolTipObj.description = hasAccess.error;
    toolTipObj.info = ERROR_STATUS;

    return false;
  }

  const res = await patchDataForTemplate({
    id: templateId,
    data: sanitizeData(data),
    key: 'workflow',
    displayToastMessage: false,
    defaultErrorMessage,
    bypassEditCheck: true,
  });
  updateTooltipObj({ res, toolTipObj, successMessage });

  // If we got the error then return false
  return { isSuccessful: !res?.error,
    updatedWorkflowState: res?.response?.data?.workflow,
    data: res?.response?.data
  };
};

const updateWorkflow = async ({
  templateId,
  data,
  defaultErrorMessage,
  successMessage,
  toolTipObj,
}) => {
  const query = `id eq '${templateId}'`;
  const options = {
    $filter: query,
  };

  try {
    await axiosInstance.patch(workFlowUpdateUrl, { data }, { params: options });
    updateTooltipObj({ res: { response: true }, successMessage, toolTipObj });
    return true;
  } catch (err) {
    updateTooltipObj({
      res: { error: err?.response?.data?.message || defaultErrorMessage },
      successMessage,
      toolTipObj,
    });
    return false;
  }
};

const getTitle = (keyValue) => {
  if (typeof keyValue !== 'string' || !keyValue?.trim()) {
    return '';
  }
  return `${capitalize(keyValue?.split('_')?.[1] || '')}`;
};

const updateListWithUpdatedElements = (list, updated) => {
  const updatedLookup = {};
  for (const updatedItem of updated) {
    updatedLookup[updatedItem.id] = updatedItem;
  }

  return list.map((item) => updatedLookup[item.id] || item);
};
const getTransitionListToBeUpdated = (newObj, value) => {
  let transitionListToBeUpdated;
  const transtionIndex = newObj?.workflow_transitions?.findIndex(
    (item) => item.id === value.transitionId
  );
  if (transtionIndex !== -1) {
    const transitionIdList = newObj?.workflow_transitions
      .filter(
        (item) =>
          item.source === newObj?.workflow_transitions[transtionIndex].source
      )
      .map((item) => item.id);

    transitionListToBeUpdated = newObj?.workflow_rules?.list
      .filter((item) => transitionIdList.includes(item.transitionId))
      .map((item) => ({
        ...item,
        listRestricType: value.listRestricType,
      }));
  }
  return transitionListToBeUpdated;
};

const getWorkFlowById = async (id) => {
  const query = `id eq '${id}'`;
  const options = {
    $top: 5,
    $skip: 0,
    $select: '*',
  };
  if (id) {
    options.$filter = query;
  }
  const response = await axiosInstance.get(workFlowListUrl, {
    params: options,
  });

  return response.data.rows[0];
};
const getWorkFlowStateByTemeplateId = async (id, entityRoute) => {
  const query = `id eq '${id}'`;
  const options = {
    $top: 5,
    $skip: 0,
    $select: '*',
  };
  if (id) {
    options.$filter = query;
  }
  if (entityRoute === 'workflow-list') {
    const response = await axiosInstance.get(workFlowListUrl, {
      params: options,
    });
    return {
      workFlow: response.data.rows[0],
      fieldList: [],
      templateId: response.data.rows[0]?.id,
      entityRoute,
      templateLifeCycle: '',
    };
  }
  // entityRoute === 'template'

  const response = await axiosInstance.get(templateListUrl, {
    params: options,
  });
  return {
    workFlow: response.data.rows[0]?.workflow,
    fieldList: response.data.rows[0]?.fields,
    templateId: response.data.rows[0]?.id,
    entityRoute,
    templateLifeCycle: response.data.rows[0]?.lifecycle_status,
    properties: response?.data?.rows?.[0]?.properties || {},
  };

  return {
    workFlow: {},
    fieldList: [],
    templateId: id,
    entityRoute,
  };
};

const addNewStateWorkFlowWithKeyValue = async ({
  keyValue,
  value,
  state,
  templateId,
  entityRoute,
  editTranstion,
  defaultRole,
  templateProperties,
  dispatch,
}) => {
  let localState = cloneDeep(state);
  const toolTipObj = {};
  let listRole;
  let updatedRuleList;
  if (defaultRole) {
    listRole = value?.map((item) => ({
      id: uuidv4(),
      ruleType: 'moveIssue',
      statusId: '',
      transitionId: item?.id,
      valueListObj: [],
      includedStatus: false,
      listRestricType: {
        roles: ['ticket_agent'],
      },
      checkValueStatus: '',
      reverseRuleStatus: false,
      reviewValueStatus: '',
      approvalsType: APPROVAL_TYPES.MIN_APPROVAL_COUNT,
      approvals: '1',
    }));
    const localTransitionIds = value
      ?.map((item) =>
        localState?.workflow_transitions?.filter(
          (transition) => item.source === transition.source
        )
      )
      .flat()
      .map((item) => item.id);

    updatedRuleList = localState?.workflow_rules?.list
      .filter((item) => localTransitionIds.includes(item.transitionId))
      .map((item) => ({
        ...item,
        listRestricType: {
          roles: ['ticket_agent'],
        },
      }));
  }

  if (Array.isArray(value)) {
    localState = { ...state, [keyValue]: [...state[keyValue], ...value] };
    if (defaultRole) {
      const localUpdatedRule = updateListWithUpdatedElements(
        localState?.workflow_rules?.list,
        updatedRuleList
      );
      localState = {
        ...localState,
        workflow_rules: {
          list: [...localUpdatedRule, ...listRole],
        },
      };
    }
  } else {
    localState = {
      ...state,
      [keyValue]: [...state[keyValue], value],
      updated_on: new Date().toISOString(),
    };
    if (defaultRole) {
      const localUpdatedRule = updateListWithUpdatedElements(
        localState?.workflow_rules?.list,
        updatedRuleList
      );
      localState = {
        ...localState,
        workflow_rules: {
          list: [...localUpdatedRule, ...listRole],
        },
      };
    }
  }

  const title = getTitle(keyValue);
  let isSuccessful = false;

  if (entityRoute === 'template') {
    const { isSuccessful, updatedWorkflowState, data } = await updateWorkFlowForTemplate({
      templateId,
      data: localState,
      defaultErrorMessage: editTranstion
        ? `Failed to update ${title}`
        : `Failed to add ${title}`,
      successMessage: editTranstion
        ? `${title} updated successfully`
        : `${title} added successfully`,
      toolTipObj,
      templateProperties,
    });
    if (!isEmpty(data) && typeof dispatch === 'function') {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: {
            ...data,
            lifecycle_status: data.lifecycle_status === 'REJECTED' ? data.lifecycle_status : 'DRAFT',
          },
        })
      );
    }
    return { localState: isSuccessful ? updatedWorkflowState : state, toolTipObj };
  }

  if (entityRoute === 'workflow-list') {
    isSuccessful = await updateWorkflow({
      templateId,
      data: { [keyValue]: localState[keyValue] },
      defaultErrorMessage: editTranstion
        ? `Failed to update ${title}`
        : `Failed to add ${title}`,
      successMessage: editTranstion
        ? `${title} updated successfully`
        : `${title} added successfully`,
      toolTipObj,
    });
  }

  return { localState: isSuccessful ? localState : state, toolTipObj };
};

const updateSelectedWorkFlowWithKeyValue = async ({
  keyValue,
  value,
  state,
  templateId,
  entityRoute,
  templateProperties,
  dispatch,
}) => {
  const toolTipObj = {};
  const tempState = cloneDeep(state);
  const updatedState = {
    ...tempState,
    [keyValue]: state[keyValue].map((item) => {
      if (item.id === value.id) {
        return {
          ...value,
        };
      }
      return item;
    }),
    updated_on: new Date().toISOString(),
  };

  const title = getTitle(keyValue);
  let isSuccessful = false;

  if (entityRoute === 'template') {
    const { isSuccessful, updatedWorkflowState, data } = await updateWorkFlowForTemplate({
      templateId,
      data: updatedState,
      defaultErrorMessage: `Failed to update ${title}`,
      successMessage: `${title} updated successfully`,
      toolTipObj,
      templateProperties,
    });
    if (!isEmpty(data) && typeof dispatch === 'function') {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: {
            ...data,
            lifecycle_status: data.lifecycle_status === 'REJECTED' ? data.lifecycle_status : 'DRAFT',
          },
        })
      );
    }
    return { updatedState: isSuccessful ? updatedWorkflowState : state, toolTipObj };
  }
  if (entityRoute === 'workflow-list') {
    isSuccessful = await updateWorkflow({
      templateId,
      data: { [keyValue]: updatedState[keyValue] },
      successMessage: `${title} updated successfully`,
      defaultErrorMessage: `Failed to update ${title}`,
      toolTipObj,
    });
  }

  return { updatedState: isSuccessful ? updatedState : state, toolTipObj };
};

const saveCategoryInWorkFlow = async ({
  value,
  state,
  templateId,
  entityRoute,
  templateProperties,
  dispatch
}) => {
  const toolTipObj = {};
  const newObj = cloneDeep(state);
  const newCategory = newObj.workflow_category.map((item) => {
    if (item.id === value.id) {
      return { ...value };
    }
    return item;
  });
  const newNode = newObj.workflow_status.map((item) => {
    if (item.data.categoryId === value.id) {
      return {
        ...item,
        type: value.color.value,
        data: { ...item.data, name: value.name },
      };
    }
    return item;
  });

  let isSuccessful = false;
  if (entityRoute === 'template') {
    const { isSuccessful, updatedWorkflowState, data } = await updateWorkFlowForTemplate({
      templateId,
      data: {
        ...newObj,
        workflow_category: newCategory,
        workflow_status: newNode,
        updated_on: new Date().toISOString(),
      },
      defaultErrorMessage: 'Failed to update category',
      successMessage: 'Category Updated Successfully',
      toolTipObj,
      templateProperties,
    });
    if (!isEmpty(data) && typeof dispatch === 'function') {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: {
            ...data,
            lifecycle_status: data.lifecycle_status === 'REJECTED' ? data.lifecycle_status : 'DRAFT',
          },
        })
      );
    }
    return { updatedObj: isSuccessful ? updatedWorkflowState : state, toolTipObj };
  }
  if (entityRoute === 'workflow-list') {
    isSuccessful = await updateWorkflow({
      templateId,
      data: { workflow_category: newCategory, workflow_status: newNode },
      defaultErrorMessage: 'Failed to update category',
      successMessage: 'Category updated successfully',
      toolTipObj,
    });
  }
  return {
    updatedObj: isSuccessful
      ? {
        ...newObj,
        workflow_category: newCategory,
        workflow_status: newNode,
        updated_on: new Date().toISOString(),
      }
      : state,
    toolTipObj,
  };
};

const deleteCategoryFromWorkFlow = async ({
  value,
  state,
  templateId,
  entityRoute,
  templateProperties,
  dispatch
}) => {
  const toolTipObj = {};
  const newObj = cloneDeep(state);
  const newNode = newObj?.workflow_status?.filter(
    (item) => item.data.categoryId === value.id
  );
  const nodeDonotHaveEdge = newNode.filter((nodeItem) => {
    const result = newObj.workflow_transitions.some(
      (edgeItem) =>
        nodeItem.id === edgeItem.source || nodeItem.id === edgeItem.target
    );
    return !result;
  });
  const updatedWorkflowStatus = newObj?.workflow_status?.filter(
    (item) => !nodeDonotHaveEdge.some((nodeItem) => nodeItem.id === item.id)
  );
  const updatedWorkflowCategory = newObj?.workflow_category?.filter(
    (categoryItem) => categoryItem?.id !== value?.id
  );

  let isSuccessful = false;
  if (entityRoute === 'template') {
    const { isSuccessful, updatedWorkflowState, data } = await updateWorkFlowForTemplate({
      templateId,
      data: {
        ...newObj,
        workflow_status: updatedWorkflowStatus,
        workflow_category: updatedWorkflowCategory,
        updated_on: new Date().toISOString(),
      },
      defaultErrorMessage: 'Failed to delete category',
      successMessage: 'Category Deleted Successfully',
      toolTipObj,
      templateProperties,
    });
    if (!isEmpty(data) && typeof dispatch === 'function') {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: {
            ...data,
            lifecycle_status: data.lifecycle_status === 'REJECTED' ? data.lifecycle_status : 'DRAFT',
          },
        })
      );
    }
    return {
      updatedObj: isSuccessful
        ? {
          ...updatedWorkflowState,
          updated_on: new Date().toISOString(),
        }
        : state,
      toolTipObj,
    };
  }
  if (entityRoute === 'workflow-list') {
    isSuccessful = await updateWorkflow({
      templateId,
      data: {
        workflow_category: updatedWorkflowCategory,
        workflow_status: updatedWorkflowStatus,
      },
      defaultErrorMessage: 'Failed to delete category',
      successMessage: 'Category deleted successfully',
      toolTipObj,
    });
  }
  return {
    updatedObj: isSuccessful
      ? {
        ...newObj,
        workflow_status: updatedWorkflowStatus,
        workflow_category: updatedWorkflowCategory,
        updated_on: new Date().toISOString(),
      }
      : state,
    toolTipObj,
  };
};

const deleteEdgeFromWorkFlow = async ({
  value,
  state,
  templateId,
  entityRoute,
  templateProperties,
}) => {
  const toolTipObj = {};
  const newObj = cloneDeep(state);
  const afterRemovedEdge = newObj.workflow_transitions.filter(
    (item) => item.id !== value.id
  );
  const updateRuleList = newObj?.workflow_rules?.list.filter(
    (item) => item.transitionId !== value.id
  );
  const newUpdateRule = {
    ...newObj.workflow_rules,
    list: updateRuleList,
  };

  let isSuccessful = false;
  if (entityRoute === 'template') {
    const { isSuccessful, updatedWorkflowState } = await updateWorkFlowForTemplate({
      templateId,
      data: {
        ...newObj,
        workflow_rules: newUpdateRule,
        workflow_transitions: afterRemovedEdge,
        updated_on: new Date().toISOString(),
      },
      defaultErrorMessage: 'Failed to delete transition',
      successMessage: 'Transition Deleted Successfully',
      toolTipObj,
      templateProperties,
    });
    return {
      updatedObj: isSuccessful
        ? {
          ...updatedWorkflowState,
          updated_on: new Date().toISOString(),
        }
        : state,
      toolTipObj,
    };
  }
  if (entityRoute === 'workflow-list') {
    isSuccessful = await updateWorkflow({
      templateId,
      data: { workflow_transitions: afterRemovedEdge },
      defaultErrorMessage: 'Failed to delete transition',
      successMessage: 'Transition deleted successfully',
      toolTipObj,
    });

    return {
      updatedObj: isSuccessful
        ? {
          ...newObj,
          workflow_transitions: afterRemovedEdge,
          updated_on: new Date().toISOString(),
        }
        : state,
      toolTipObj,
    };
  }

  return {
    updatedObj: isSuccessful
      ? {
        ...newObj,
        workflow_transitions: afterRemovedEdge,
        workflow_rules: newUpdateRule,
      }
      : state,
    toolTipObj,
  };
};

const deleteNodeFromWorkFlow = async ({
  nodeId,
  state,
  templateId,
  entityRoute,
  templateProperties,
  dispatch
}) => {
  const toolTipObj = {};
  const newObj = cloneDeep(state);

  const postDeleteNodes = newObj.workflow_status.filter(
    (node) => node.id !== nodeId
  );

  let isSuccessful = false;
  if (entityRoute === 'template') {
    const { isSuccessful, updatedWorkflowState, data } = await updateWorkFlowForTemplate({
      templateId,
      data: {
        ...newObj,
        workflow_status: postDeleteNodes,
        updated_on: new Date().toISOString(),
      },
      defaultErrorMessage: 'Failed to delete state',
      successMessage: 'State deleted successfully',
      toolTipObj,
      templateProperties,
    });
    if (!isEmpty(data) && typeof dispatch === 'function') {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: {
            ...data,
            lifecycle_status: data.lifecycle_status === 'REJECTED' ? data.lifecycle_status : 'DRAFT'
          },
        })
      );
    }
    return {
      updatedObj: isSuccessful
        ? {
          ...updatedWorkflowState,
          updated_on: new Date().toISOString(),
        }
        : state,
      toolTipObj,
    };
  }
  if (entityRoute === 'workflow-list') {
    isSuccessful = await updateWorkflow({
      templateId,
      data: { workflow_status: postDeleteNodes },
      defaultErrorMessage: 'Failed to delete state',
      successMessage: 'State deleted successfully',
      toolTipObj,
    });
  }
  return {
    updatedObj: isSuccessful
      ? {
        ...newObj,
        workflow_status: postDeleteNodes,
        updated_on: new Date().toISOString(),
      }
      : state,
    toolTipObj,
  };
};

const addRuleToSelectedWorkFlowSate = async ({
  value,
  state,
  templateId,
  templateProperties,
  dispatch
}) => {
  const toolTipObj = {};
  const newObj = cloneDeep(state);
  let newUpdate;
  if (!checkForV2() && value.ruleType === 'moveIssue') {
    const transitionListToBeUpdated = getTransitionListToBeUpdated(
      newObj,
      value
    );
    const localUpdatedRule = updateListWithUpdatedElements(
      newObj?.workflow_rules?.list,
      transitionListToBeUpdated
    );
    newUpdate = {
      ...newObj.workflow_rules,
      list: [...localUpdatedRule, value],
    };
  } else {
    newUpdate = {
      ...newObj.workflow_rules,
      list: [...newObj.workflow_rules.list, value],
    };
  }

  const { isSuccessful, updatedWorkflowState, data } = await updateWorkFlowForTemplate({
    templateId,
    data: { ...newObj, workflow_rules: newUpdate },
    defaultErrorMessage: 'Failed to add rule',
    successMessage: 'Rule added successfully',
    toolTipObj,
    templateProperties,
  });
  if (!isEmpty(data) && typeof dispatch === 'function') {
    dispatch(
      updateViewState({
        stateKey: 'selectedTemplate',
        value: {
          ...data,
          lifecycle_status: data.lifecycle_status === 'REJECTED' ? data.lifecycle_status : 'DRAFT'
        },
      })
    );
  }
  return {
    updatedObj: isSuccessful ? updatedWorkflowState : state,
    toolTipObj,
  };
};

const updateRuleToSelectedWorkFlowSate = async ({
  value,
  state,
  templateId,
  templateProperties,
  dispatch,
}) => {
  const toolTipObj = {};
  const newObj = cloneDeep(state);
  let newUpdate;

  if (!checkForV2() && value.ruleType === 'moveIssue') {
    const transitionListToBeUpdated = getTransitionListToBeUpdated(
      newObj,
      value
    );
    const localUpdatedRule = updateListWithUpdatedElements(
      newObj?.workflow_rules?.list,
      transitionListToBeUpdated
    );
    newUpdate = {
      ...newObj.workflow_rules,
      list: [...localUpdatedRule],
    };
  } else {
    const updatedList = newObj.workflow_rules.list.map((item) => {
      if (item.id === value.id) {
        return value; // Update the item with matching id
      }
      return item; // Keep the original item if no match
    });
    newUpdate = {
      ...newObj.workflow_rules,
      list: updatedList,
    };
  }

  const { isSuccessful, updatedWorkflowState, data } = await updateWorkFlowForTemplate({
    templateId,
    data: { ...newObj, workflow_rules: newUpdate },
    defaultErrorMessage: 'Failed to update rule',
    successMessage: 'Rule updated successfully',
    toolTipObj,
    templateProperties,
  });
  if (!isEmpty(data) && typeof dispatch === 'function') {
    dispatch(
      updateViewState({
        stateKey: 'selectedTemplate',
        value: {
          ...data,
          lifecycle_status: data.lifecycle_status === 'REJECTED' ? data.lifecycle_status : 'DRAFT'
        },
      })
    );
  }
  return {
    updatedObj: isSuccessful ? updatedWorkflowState : state,
    toolTipObj,
  };
};

const removeRuleFromSelectedWorkFlowState = async ({
  value,
  state,
  templateId,
  templateProperties,
  dispatch,
}) => {
  const toolTipObj = {};
  const newObj = cloneDeep(state);
  const updatedList = newObj.workflow_rules.list.filter(
    (item) => item.id !== value.id
  );
  const newUpdate = {
    ...newObj.workflow_rules,
    list: updatedList,
  };

  const { isSuccessful, updatedWorkflowState, data } = await updateWorkFlowForTemplate({
    templateId,
    data: { ...newObj, workflow_rules: newUpdate },
    defaultErrorMessage: 'Failed to delete rule',
    successMessage: 'Rule deleted successfully',
    toolTipObj,
    templateProperties,
  });
  if (!isEmpty(data) && typeof dispatch === 'function') {
    dispatch(
      updateViewState({
        stateKey: 'selectedTemplate',
        value: {
          ...data,
          lifecycle_status: data.lifecycle_status === 'REJECTED' ? data.lifecycle_status : 'DRAFT'
        },
      })
    );
  }
  return {
    updatedObj: isSuccessful ? updatedWorkflowState : state,
    toolTipObj,
  };
};
const updateNodePostionWhenDrag = async ({
  nodeList,
  state,
  templateId,
  entityRoute,
  templateProperties,
}) => {
  const toolTipObj = {};
  const newObj = cloneDeep(state);
  const newUpdateNodeList = nodeList.map((item) => ({
    ...item,
    dragging: false,
    selected: false,
  }));

  let isSuccessful = false;
  if (entityRoute === 'template') {
    const { isSuccessful, updatedWorkflowState } = await updateWorkFlowForTemplate({
      templateId,
      data: {
        ...newObj,
        workflow_status: newUpdateNodeList,
        updated_on: new Date().toISOString(),
      },
      defaultErrorMessage: 'Failed to update workflow',
      successMessage: 'Workflow updated successfully',
      toolTipObj,
      templateProperties,
    });
    return {
      updatedObj: isSuccessful
        ? {
          ...updatedWorkflowState,
          updated_on: new Date().toISOString(),
        }
        : state,
      toolTipObj,
    };
  }
  if (entityRoute === 'workflow-list') {
    isSuccessful = await updateWorkflow({
      templateId,
      data: { workflow_status: newUpdateNodeList },
      defaultErrorMessage: 'Failed to update workflow',
      successMessage: 'Workflow updated successfully',
      toolTipObj,
    });
  }
  return {
    updatedObj: isSuccessful
      ? {
        ...newObj,
        workflow_status: newUpdateNodeList,
        updated_on: new Date().toISOString(),
      }
      : state,
    toolTipObj,
  };
};

const autoSavePostionWhenDrag = async ({
  nodeList,
  state,
  templateId,
  entityRoute,
  templateProperties,
  dispatch
}) => {
  const newObj = cloneDeep(state);
  const newUpdateNodeList = nodeList.map((item) => ({
    ...item,
    dragging: false,
    selected: false,
  }));

  let isSuccessful = false;
  if (entityRoute === 'template') {
    const { isSuccessful, updatedWorkflowState, data } = await updateWorkFlowForTemplate({
      templateId,
      data: {
        ...newObj,
        workflow_status: newUpdateNodeList,
        updated_on: new Date().toISOString(),
      },
      defaultErrorMessage: 'Failed to auto save',
      successMessage: '',
      toolTipObj: {},
      templateProperties,
    });
    await saveState('isAutoSaveWorkflow', false);
    // update selected template state on success of api
    if (isSuccessful && !isEmpty(data) && typeof dispatch === 'function'){
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: data
        })
      );
    }
    return isSuccessful
      ? {
        ...updatedWorkflowState,
        updated_on: new Date().toISOString(),
      }
      : state;
  }
  if (entityRoute === 'workflow-list') {
    isSuccessful = await updateWorkflow({
      templateId,
      data: { workflow_status: newUpdateNodeList },
      defaultErrorMessage: 'Failed to auto save',
      successMessage: '',
      toolTipObj: {},
    });
  }
  await saveState('isAutoSaveWorkflow', false);
  return isSuccessful
    ? {
      ...newObj,
      workflow_status: newUpdateNodeList,
      updated_on: new Date().toISOString(),
    }
    : state;
};
const updateWorkflowOnUndoRedo = async ({
  updatedWorkFlow,
  templateId,
  entityRoute,
  templateProperties
}) => {
  const newObj = cloneDeep(updatedWorkFlow);

  let isSuccessful = false;
  if (entityRoute === 'template') {
    const { isSuccessful, updatedWorkflowState } = await updateWorkFlowForTemplate({
      templateId,
      data: {
        ...newObj,
        updated_on: new Date().toISOString(),
      },
      defaultErrorMessage: 'Failed to auto save',
      successMessage: '',
      toolTipObj: {},
      templateProperties,
    });
    return isSuccessful
      ? {
        ...updatedWorkflowState,
        updated_on: new Date().toISOString(),
      }
      : state;
  }
  if (entityRoute === 'workflow-list') {
    isSuccessful = await updateWorkflow({
      templateId,
      data: {
        workflow_status: newObj.workflow_status,
        workflow_transitions: newObj.workflow_transitions,
        workflow_category: newObj.workflow_category,
      },
      defaultErrorMessage: 'Failed to auto save',
      successMessage: '',
      toolTipObj: {},
    });
  }
  return isSuccessful
    ? {
      ...newObj,
      updated_on: new Date().toISOString(),
    }
    : state;
};
const updateSelectedWorkFlowName = async ({
  keyValue,
  value,
  state,
  templateId,
  entityRoute,
}) => {
  const toolTipObj = {};
  const tempState = cloneDeep(state);
  const updatedState = {
    ...tempState,
    [keyValue]: value,
    updated_on: new Date().toISOString(),
  };

  const title = getTitle(keyValue);
  let isSuccessful = false;

  if (entityRoute === 'workflow-list') {
    isSuccessful = await updateWorkflow({
      templateId,
      data: { [keyValue]: updatedState[keyValue] },
      successMessage: `${title} updated successfully`,
      defaultErrorMessage: `Failed to update ${title}`,
      toolTipObj,
    });
  }

  return { updatedState: isSuccessful ? updatedState : state, toolTipObj };
};

const WorkFlowMainService = {
  getWorkFlowById,
  getWorkFlowStateByTemeplateId,
  addNewStateWorkFlowWithKeyValue,
  updateSelectedWorkFlowWithKeyValue,
  saveCategoryInWorkFlow,
  deleteCategoryFromWorkFlow,
  deleteNodeFromWorkFlow,
  updateWorkflowOnUndoRedo,
  addRuleToSelectedWorkFlowSate,
  updateRuleToSelectedWorkFlowSate,
  removeRuleFromSelectedWorkFlowState,
  deleteEdgeFromWorkFlow,
  updateNodePostionWhenDrag,
  autoSavePostionWhenDrag,
  updateSelectedWorkFlowName,
};
export default WorkFlowMainService;
