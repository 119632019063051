export const MESSAGES = {
  defaultErrorMessage: 'Failed to update mapping. Please try again',
  successMessage: 'Mapping updated successfully',
  noChannelEnabled:
    'The email and bot channel are not enabled for this template.',
};

export const FIELD_LABELS = {
  subject: 'Subject',
  body: 'Body',
  attachment: 'Attachment',
};

export const FIELD_VALUES = {
  subject: 'subject',
  body: 'body',
  attachment: 'attachment',
};
