/* eslint-disable import/no-extraneous-dependencies */
import { Box, Flex, Text } from '@chakra-ui/layout';
import React, { useEffect, useMemo, useState } from 'react';
import { Avatar, Divider, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import styles from './navigation.module.scss';
import CustomButton from '../../components/Button/SimpleButton';
import AcceptRejectDialog from './AcceptRejectDialog';
import axiosInstance from '../../utils/axios/axios';
import { canViewTemplate } from '../../pages/TicketTemplateBuilder/utils/templateAPIUtils';

const NotificationView = ({ onClose }) => {
  const navigate = useNavigate();

  const [data, setData] = useState(null);

  const getTemplateIds = async (notificationData) => {
    const ids = new Set();
    (Array.isArray(notificationData) ? notificationData : [])?.forEach((d) => {
      ids.add(d?.entity_id);
    });
    const filterString = Array.from(ids).reduce((prev, curr) => {
      const str = `id eq ${curr}`;
      if (!prev) {
        return str;
      }
      return `${prev} or ${str}`;
    }, '');

    if (!filterString) {
      return {};
    }

    const url = `/templates/list?$top=${ids.size}&$skip=0&$select=id, properties&$filter=${filterString}`;
    try {
      const res = await axiosInstance.get(url);
      const idMap = (res?.data?.rows || [])?.reduce((prev, curr) => {
        prev[curr.id] = curr;
        return prev;
      }, {});
      return idMap;
    } catch (err) {
      return {};
    }
  };

  const fetchData = async () => {
    try {
      const notificationsEndpoint =
        'notifications/list?$top=100&$skip=0&$select=*';
      const notificationsResponse = await axiosInstance.get(
        notificationsEndpoint
      );
      const newData = notificationsResponse?.data;
      const idMap = await getTemplateIds(newData);

      const filteredData = Array.isArray(newData)
        ? newData.filter((t) => canViewTemplate({ data: idMap[t?.entity_id] }))
        : [];

      // Store the templateIdMap
      sessionStorage.setItem('notificationTemplateMap', JSON.stringify(idMap));

      setData(filteredData);

      // Store the fetched data in local storage
      localStorage.setItem('pollingData', JSON.stringify(filteredData));
    } catch (error) {
      /* empty */
    }
  };

  useEffect(() => {
    const storedData = localStorage.getItem('pollingData');
    if (storedData) {
      setData(JSON.parse(storedData));
    }

    fetchData();

    const interval = setInterval(fetchData, 1000 * 15 * 60); // Poll every 15 minutes

    return () => clearInterval(interval);
  }, []);

  const navigateToTemplate = (listItem) => {
    navigate(
      `/template-makers/template/${listItem?.entity_id}?name=${listItem?.body?.notificationData?.templateName}`
    );
    onClose();
  };

  const templateDataFromLocalStorage = useMemo(() => {
    try {
      const val = JSON.parse(sessionStorage.getItem('notificationTemplateMap'));
      return val || {};
    } catch (err) {
      return {};
    }
  }, [data]);

  return (
    <Box data-testid="notificationBox">
      {data?.map((listItem) => {
        const { notificationData } = listItem?.body || undefined;
        return (
          <>
            <Flex gap="4" alignItems="top" data-testid="notificationView">
              <Avatar name={notificationData.user} size="sm" />
              <Flex direction="column" data-testid="notificationItem">
                <Text
                  className={styles.headingStyle}
                  color={useColorModeValue('#000000', 'white')}
                  data-testid="notificationViewHeadingStyleText"
                >
                  {`${notificationData?.user} changed ${notificationData?.templateName} status from ${notificationData?.fromStatus} to ${notificationData?.toStatus}`}
                </Text>
                <Flex data-testid="notificationViewFlex">
                  <Divider
                    orientation="vertical"
                    className={styles.dividerStyle}
                    colorScheme="gray"
                  />
                  <Text
                    className={styles.noteStyle}
                    data-testid="notificationViewNoteStyleText"
                  >
                    {notificationData?.note}
                  </Text>
                </Flex>
                <Flex
                  justify="space-between"
                  mt={2}
                  data-testid="notificationViewFlexButton"
                >
                  <CustomButton
                    buttonText="View Template"
                    variant="link"
                    size="sm"
                    colorScheme="customBlue"
                    onClick={() => navigateToTemplate(listItem)}
                    _hover={{}}
                    id="notificationViewTemplateBtn"
                  />
                  <Flex data-testid="notificationViewFlexComponentWrap">
                    {listItem?.status === 'CREATED' &&
                      notificationData?.toStatus === 'PENDING_REVIEW' && (
                        <>
                          <AcceptRejectDialog
                            templateId={listItem?.entity_id}
                            id={listItem.id}
                            buttonText="Reject"
                            colorScheme="red"
                            _hover="red"
                            fetchData={fetchData}
                            notificationData={notificationData}
                            templateData={
                              templateDataFromLocalStorage?.[
                                listItem?.entity_id
                              ]
                            }
                          />
                          <AcceptRejectDialog
                            templateId={listItem?.entity_id}
                            id={listItem.id}
                            buttonText="Approve"
                            colorScheme="customBlue"
                            fetchData={fetchData}
                            notificationData={notificationData}
                            templateData={
                              templateDataFromLocalStorage?.[
                                listItem?.entity_id
                              ]
                            }
                          />
                        </>
                      )}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Divider
              className={styles.dividerStyle}
              colorScheme="red"
              mt="12px"
              mb="25px"
            />
          </>
        );
      })}
    </Box>
  );
};

NotificationView.defaultProps = {
  onClose: () => {},
};

NotificationView.propTypes = {
  onClose: PropTypes.func,
};

export default NotificationView;
