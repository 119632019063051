/* eslint-disable consistent-return */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import _ from 'lodash';
import axios from 'axios';
import CustomSelectBox from '../../components/SelectBox/Select';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import ReactSelectViewTheme from '../../context/CustomTheme/ReactSelectViewTheme';
import axiosInstance from '../../utils/axios/axios';

const DynamicDropDownView = ({
  name,
  value,
  optionsUrl,
  labelKey,
  valueKey,
  parentName,
  label,
  ...rest
}) => {
  const [options, setOptions] = useState();

  const getOptions = async () => {
    // currenty we will be using harcoded api values for loading the options
    // once real apis come change it with this one
    // const response = await axiosInstance.get(optionsUrl);
    const response = await axios.get(optionsUrl);
    setOptions(
      response?.data?.map((i) => ({
        ...i,
        label: i[labelKey],
        value: i[valueKey],
      }))
    );
  };
  useEffect(() => {
    getOptions();
  }, []);

  const { viewState } = useSelector((state) => state);
  const dispatch = useDispatch();
  const getValue = () => {
    const stateValue = get(viewState, `${parentName}.${value}`);
    if (typeof stateValue === 'string') {
      const option = _.find(options, { value: stateValue });
      return option ?? '';
    }
    return stateValue ?? '';
  };
  return (
    <CustomSelectBox
      {...rest}
      value={getValue()}
      options={options}
      label={label}
      onChange={(val) => {
        dispatch(
          updateViewState({
            stateKey: parentName,
            value: { ...viewState[parentName], [value]: val.value },
          })
        );
      }}
      styles={ReactSelectViewTheme}
      id="DynamicDropdownViewSelectBox"
    />
  );
};

export default DynamicDropDownView;
