import React from 'react';
import { useSelector } from 'react-redux';
import TemplateEditor from '../TemplateEditor';
import NoTemplateScreen from './NoTemplateScreen';

const TicketTemplateDashboard = () => {
  const allTemplates = useSelector((state) => state.allTemplates);
  const { allTemplatesList = [] } = allTemplates || {};

  return !allTemplatesList?.length ? (
    <NoTemplateScreen />
  ) : (
    <TemplateEditor />
  );
};

export default TicketTemplateDashboard;
