/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import {
  Box,
  DrawerBody,
  DrawerFooter,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { AddIcon } from '@chakra-ui/icons';
import { updateViewState } from '../../../../redux/ViewSlice/ViewReducer';
import {
  patchDataForTemplate,
  showToastMessage,
} from '../../../TicketTemplateBuilder/utils/templateAPIUtils';
import NewSLA from '../NewSla';
import { SLA_FORM_KEY_MAP, emptySla } from './utils';
import CustomButton from '../../../../components/Button/SimpleButton';
import { COLORS, DARK, LIGHT } from '../../../../utils/enums/colors';
import {
  LIST_SLA_VALIDATIONS,
  LIST_SLA_VALIDATIONS_MULTI_SLA,
} from '../../../../utils/validationSchemas/templates/metricsAndSla';
import NoRecords from '../../../../components/Table/NoRecords';
import { PrimaryCustomButton, SecondaryCustomButton } from '../../../../components/Button/PrimarySecondaryButton';
import CustomAccordion from '../../../../components/Accordion/Accordion';
import { validateSlaMetricFieldCannotHaveSameName } from '../../duplicateMetricSlaFieldsVerificationHelper';

const SLA = ({ navigateBack, selectedMetricId }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const viewState = useSelector((state) => state.viewState || {});
  const SLA_DATA = viewState?.selectedTemplate?.slas || {};
  const [isLoading, setIsLoading] = useState(false);
  const [slaListData, setSlaListData] = useState([
    ...(SLA_DATA[selectedMetricId] || []),
  ]);
  const [indexWiseErrorWithIdAsKey, setIndexWiseErrorWithIdAsKey] = useState(
    {}
  );
  const onFinishSave = async () => {
      // update slas with current state (overwrite redux state)  
      const slas = {...viewState?.selectedTemplate.slas}
      slas[selectedMetricId]=slaListData

      // validate SLA name name can not be same as field, sla or any other metric name
      for(let i=0; i<slaListData.length; i++){
      const validationMessage = validateSlaMetricFieldCannotHaveSameName(slaListData[i]?.name?.trim().toLowerCase(), { ...viewState?.selectedTemplate, slas  }, slaListData[i].id)
      if(validationMessage){
        showToastMessage({
          description: validationMessage,
          status: 'info',
        });
        return
      }
      slaListData[i].name = slaListData[i]?.name?.replace(/\s+/g, ' ').trim()
    }

    setIsLoading(true);
    if (!selectedMetricId) {
      showToastMessage({
        description: 'Please create a metric before creating a SLA',
        status: 'info',
      });
      setIsLoading(false);
      return;
    }

    let validator = LIST_SLA_VALIDATIONS_MULTI_SLA;
    if (slaListData?.length && slaListData.length === 1) {
      validator = LIST_SLA_VALIDATIONS;
    }
    const { error } = validator.validate(slaListData, {
      abortEarly: false,
    });
    if (error) {
      const keyPairWithIdObj = slaListData.reduce((acc, item) => {
        acc[item.id] = {};
        return acc;
      }, {});

      error.details.forEach((err) => {
        const keyId = slaListData[err.path[0]].id;
        const keyLoc = err.path[1];
        keyPairWithIdObj[keyId] = {
          ...keyPairWithIdObj[keyId],
          [keyLoc]: err.message,
        };
      });
      setIndexWiseErrorWithIdAsKey({ ...keyPairWithIdObj });
      setIsLoading(false);
      return;
    }
    let requestPayload = {
      ...SLA_DATA,
      [selectedMetricId]: slaListData,
    };

    const res = await patchDataForTemplate({
      id,
      data: requestPayload,
      key: 'slas',
      defaultErrorMessage: 'Failed to save SLA',
      successMessage: 'Successfully saved SLA',
    });

    if (!res.error) {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: {
            ...viewState?.selectedTemplate,
            ...res?.response?.data || {},
            slas: requestPayload,
          },
        })
      );
      setIsLoading(false);
      navigateBack();
    }
    setIsLoading(false);
  };

  const onDeleteSla = (itemId) => {
    const newSlaListData = slaListData.filter((item) => item.id !== itemId);
    setSlaListData(newSlaListData);
  };
  const onAddSLAInSLAList = () => {
    const IS_VALID_FIELD_LIST = slaListData.every(
      (item) => item[SLA_FORM_KEY_MAP.NAME] && item[[SLA_FORM_KEY_MAP.GOAL]]
    );
    if (!IS_VALID_FIELD_LIST) {
      return;
    }

    const tempData = cloneDeep([...slaListData, { ...emptySla, id: uuidv4() }]);
    setSlaListData(tempData);
  };

  

  return (
    <>
      {slaListData.length === 0 && (
        <Box height="calc(100vh - 7rem)" overflow='auto' className="pt-[50px]" data-testid="AudienceNoRecordsDiv">
          <NoRecords
            title="You have not added sla"
            subTitle="Click 'Create New' to get started"
            buttonText="Create New"
            onAdd={() => {
              setSlaListData([{ ...emptySla, id: uuidv4() }]);
            }}
          />
        </Box>
      )}
      {slaListData.length >= 1 ? (
        <Box
          p="16px"
          data-testid="SLAContainerBox"
          overflowY="auto"
          overflowX="hidden"
          height="80vh"
        >
          <DrawerBody padding="10px">
            <CustomAccordion
              accordionData={slaListData?.map((item, itemIndex) => {
                return{
                  title:item.name ? item.name : 'create',
                  content:(
                  <NewSLA
                    rowData={item}
                    setWholeSlaList={setSlaListData}
                    itemIndex={itemIndex}
                    onDeleteSla={onDeleteSla}
                    key={item.id}
                    errorDataItem={indexWiseErrorWithIdAsKey[item.id]}
                  />
                  )}
              })}
              initialExpandedIndexList={0}
            />
          </DrawerBody>
          <DrawerFooter>
            <Flex p="16px 10px">
              <SecondaryCustomButton
                buttonText="Add New SLA"
                onClick={onAddSLAInSLAList}
                leftIcon={<AddIcon />}
              />
            </Flex>
            <Flex p="16px 10px" justify="flex-end">
              <PrimaryCustomButton
                onClick={() => {
                  onFinishSave();
                }}
                buttonText="Save"
                isLoading={isLoading}
              />
            </Flex>
          </DrawerFooter>
        </Box>
      )
      :null}
    </>
  );
};

SLA.propTypes = {
  navigateBack: PropTypes.func.isRequired,
  selectedMetricId: PropTypes.string,
};

SLA.defaultProps = {
  selectedMetricId: '',
};

export default SLA;
