import { axiosInstanceWithoutBaseUrl } from '../../../utils/axios/axios';

// eslint-disable-next-line import/prefer-default-export
export const getAITenantByBotconfig = async () => {
  try {
    const response = await axiosInstanceWithoutBaseUrl.get('BotConfig?$filter=fullName%20eq%20%27rezolveml%27%20and%20configType%20eq%20%27defaultSetting%27');
    if (!response.error && response.data.length) {
      return response.data.some((itm) => itm.fullName === 'rezolveml' && itm.properties);
    }
    return false;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return {
      error: message,
      response: null,
    };
  }
};
