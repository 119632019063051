import { useCallback, useEffect, useState } from 'react';

import useEventCallback from './useEventCallback';

const parseJSON = (value) => {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value || '');
  } catch (error) {
    return undefined;
  }
};
function useLocalStorage(key, initialValue) {
  const readValue = useCallback(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? parseJSON(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  }, [initialValue, key]);

  const [storedValue, setStoredValue] = useState(readValue);

  const setValue = useEventCallback((value) => {
    try {
      const newValue = value instanceof Function ? value(storedValue) : value;
      window.localStorage.setItem(key, JSON.stringify(newValue));
      setStoredValue(newValue);

      window.dispatchEvent(new Event('local-storage'));
      // eslint-disable-next-line no-empty
    } catch (error) {}
  });

  useEffect(() => {
    setStoredValue(readValue());
  }, [readValue]);

  return [storedValue, setValue];
}

export default useLocalStorage;
