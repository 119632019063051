/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allModalsList: [],
  isLoading: false,
  isError: false,
  activeModal: undefined,
};

const allModals = createSlice({
  name: 'allModals',
  initialState,
  reducers: {
    reset: (state) => {
      state.allModalsList = [];
      state.isError = false;
      state.error = '';
    },
    resetError: (state) => {
      state.isError = false;
      state.error = '';
    },
    updateModalList: (state, action) => {
      const unique = [
        ...new Map(
          action?.payload.map((item) => [item.props.modalId, item])
        ).values(),
      ];

      state.allModalsList = unique;
    },
    setActiveModal: (state, action) => {
      state.activeModal = action?.payload?.targetModalId;
    },
  },
});

export const { reset, resetError, updateModalList, setActiveModal } =
  allModals.actions;

export default allModals.reducer;
