/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@chakra-ui/react';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { debounce } from 'lodash';
import CustomTabs from '../../../components/Tabs/Tabs';
import {
  tabsData,
  columns,
  COMMUNICATION_TEMPLATES,
  updateTemplateSuccessMsg,
  updateTemplateFailedMsg,
  editTitleForTemplate,
  addTitleForTemplate,
  createTemplateSuccessMsg,
  createTemplateFailedMsg,
} from '../../CommunicationTemplates/utills';
import AddOrEditEmailTemplateDrawer from '../../CommunicationTemplates/AddOrEditEmailTemplateDrawer';
import { setActiveModal } from '../../../redux/ModalReducer/ModalSlice';
import { updateViewState } from '../../../redux/ViewSlice/ViewReducer';
import AddOrEditGenericCommunicationTemplateDrawer from '../../CommunicationTemplates/AddOrEditGenericCommunicationTemplateDrawer';
import CustomToast from '../../../components/Notification/Notification';
import { COMMUNICATION_TEMPLATE_LABEL } from '../../../utils/enums/labels';
import { ActionHeader } from '../../CommunicationTemplates/HeaderAction';

const CommunicationTemplates = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const { addToast } = CustomToast();
  const { selectedTemplate } = useSelector((state) => state.viewState || {});
  const [openEmailTemplateDrawer, setOpenEmailTemplateDrawer] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [openEmailTemplateEditDrawer, setOpenEmailTemplateEditDrawer] =
    useState(false);
  const [stateForEditEmailTemplate, setStateForEditEmailTemplate] =
    useState(null);
  let selectedTabType = '';
  if (tabIndex === 0) {
    selectedTabType = 'EMAIL';
  } else {
    selectedTabType = 'CANNED_NOTES';
  }

  const getDataAccordingToTab = useMemo(
    () => (tab) =>
      [...(selectedTemplate?.[COMMUNICATION_TEMPLATES] || [])]?.filter(
        (ins) => ins.type === tab
      ) || [],
    [selectedTemplate]
  );

  useEffect(() => {
    setFilteredData([...getDataAccordingToTab(selectedTabType)]);
  }, [selectedTabType, selectedTemplate]);

  const [genericDrawerState, setGenericDrawerState] = useState({
    CANNED_NOTES: {
      openDrawer: false,
      type: 'CANNED_NOTES',
      defaultErrorMessage:
        'Failed to create a canned note template in communication template',
      successMessage:
        'Successfully created canned note in communication template',
      drawerTitle: 'Add New Canned Note Template',
      isHiddenNotificationLabel: true,
      val: {},
      edit: false,
    },
    IN_APP_NOTIFICATION: {
      openDrawer: false,
      type: 'IN_APP_NOTIFICATION',
      defaultErrorMessage:
        'Failed to create a app notification in communication template',
      successMessage:
        'Successfully created app notification in communication template',
      drawerTitle: 'Add New  App Notification Template',
      isHiddenNotificationLabel: false,
      val: {},
      edit: false,
    },
    SLACK_TEAMS_NOTIFICATION: {
      openDrawer: false,
      type: 'SLACK_TEAMS_NOTIFICATION',
      defaultErrorMessage:
        'Failed to create a slack/team notification in communication template',
      successMessage:
        'Successfully created slack/team notification in communication template',
      drawerTitle: 'Add New  Slack/Team Notification Template',
      isHiddenNotificationLabel: false,
      val: {},
      edit: false,
    },
    CHAT_RESPONSE: {
      openDrawer: false,
      type: 'CHAT_RESPONSE',
      defaultErrorMessage:
        'Failed to create a chat response in communication template',
      successMessage:
        'Successfully created chat response in communication template',
      drawerTitle: 'Add New  Chat Response Template',
      isHiddenNotificationLabel: false,
      val: {},
      edit: false,
    },
  });
  const dispatch = useDispatch();
  const { id: templateId } = useParams();

  const genericCopy = async (value, tabType) => {
    const communicationList = _cloneDeep(
      selectedTemplate?.[COMMUNICATION_TEMPLATES] || []
    );
    const rowIndex = communicationList.findIndex((e) => e.id === value.id);
    const newName = _get(communicationList, `[${rowIndex}].name`, '');
    const toastFailedMsg = `Failed to clone the ${tabType.toLowerCase()} communication template`;
    const toastSuccessMsg = `Successfully cloned the ${tabType.toLowerCase()} communication template`;
    dispatch(setActiveModal({ targetModalId: 'crudTemplateCloneModal' }));
    dispatch(
      updateViewState({
        stateKey: 'crudTemplateCloneModal',
        value: {
          id: templateId,
          patchData: {
            data: value,
            toastSuccessMsg,
            toastFailedMsg,
          },
          title: newName,
          masterType: `${tabType.toLowerCase()} communication`,
          listKey: 'selectedTemplate',
          targetUrl: `/templates/update?$filter=id eq ${templateId}`,
          method: 'patch',
        },
      })
    );
  };

  const onEdit = (val, tabType) => {
    if (tabType === 'EMAIL') {
      setStateForEditEmailTemplate(val);
      setOpenEmailTemplateEditDrawer(true);
    } else {
      setGenericDrawerState((state) => ({
        ...state,
        [tabType]: {
          ...state[tabType],
          openDrawer: true,
          val,
          edit: true,
          isHiddenNotificationLabel: tabType === 'CANNED_NOTES',
          successMessage: updateTemplateSuccessMsg(tabType),
          defaultErrorMessage: updateTemplateFailedMsg(tabType),
          drawerTitle: editTitleForTemplate(tabType),
        },
      }));
    }
  };
  const onClone = (val, tabType) => {
    genericCopy(val, tabType);
  };
  // eslint-disable-next-line no-unused-vars
  const onDelete = (val, tabType) => {
    const isCommunicationUseInTemplateLevel = Object.values(
      selectedTemplate.slas || {}
    )
      .flatMap((item) => item)
      .map((item) => item.emailTemplate);
    if (isCommunicationUseInTemplateLevel.includes(val.id)) {
      addToast({
        title: COMMUNICATION_TEMPLATE_LABEL.ERROR_TITLE,
        message: COMMUNICATION_TEMPLATE_LABEL.MSG,
        type: COMMUNICATION_TEMPLATE_LABEL.TOAST_TYPE,
      });
      return;
    }
    dispatch(setActiveModal({ targetModalId: 'deleteModal' }));
    dispatch(
      updateViewState({
        stateKey: 'deleteModal',
        value: {
          ...val,
          labelKey: 'name',
          masterType: 'communication_templates',
          listKey: 'selectedTemplate',
          targetUrl: '/templates/updateWithPath?$filter=id eq ${id}',
          method: 'patch',
        },
      })
    );
  };

  useEffect(() => {}, []);

  const openDrawerForTabType = (tabType) => {
    if (tabType === 'EMAIL') {
      setOpenEmailTemplateDrawer(true);
    } else {
      setGenericDrawerState((state) => ({
        ...state,
        [tabType]: {
          ...state[tabType],
          openDrawer: true,
          val: {},
          edit: false,
          successMessage: createTemplateSuccessMsg(tabType),
          defaultErrorMessage: createTemplateFailedMsg(tabType),
          drawerTitle: addTitleForTemplate(tabType),
        },
      }));
    }
  };
  const filterData = debounce((e) => {
    const filteredFields = getDataAccordingToTab(selectedTabType).filter(
      (field) =>
        field?.name?.toLowerCase()?.includes(e.target.value.toLowerCase())
    );
    setFilteredData([...filteredFields]);
  }, 500);

  const handleClearInput = () => {
    setSearchInputValue('');
    setFilteredData([...getDataAccordingToTab(selectedTabType)]);
  };

  return (
    <div data-testid="CommunicationTemplatesContainerDiv">
      <Box paddingTop="15px">
        <CustomTabs
          smallTabs="true"
          tabsData={tabsData.map((tab) => ({
            ...tab,
            content: React.cloneElement(tab.content, {
              columns: columns({
                onEdit,
                onDelete,
                onClone,
                tabType: tab.tabType,
              }),
              searchKey: 'name',
              tableData: getDataAccordingToTab(tab.tabType),
              filteredData,
              addButtonClick: openDrawerForTabType,
              tabType: tab.tabType,
            }),
          }))}
          tabRadius="100px"
          onChange={(index) => {
            setTabIndex(index);
          }}
          actionHeaderComponent={
            <ActionHeader
              searchInputValue={searchInputValue}
              setSearchInputValue={setSearchInputValue}
              filterData={filterData}
              handleClearInput={handleClearInput}
              selectedTabType={selectedTabType}
              addButtonClick={openDrawerForTabType}
              addButtonText={`Add New ${
                selectedTabType === 'EMAIL' ? 'Email' : 'Canned Note'
              }`}
            />
          }
          specialTab
          variant="unstyled"
          index={tabIndex}
        />
      </Box>
      {openEmailTemplateDrawer && (
        <AddOrEditEmailTemplateDrawer
          openDrawer={openEmailTemplateDrawer}
          setOpenDrawer={setOpenEmailTemplateDrawer}
          type="EMAIL"
        />
      )}
      {openEmailTemplateEditDrawer && stateForEditEmailTemplate && (
        <AddOrEditEmailTemplateDrawer
          openDrawer={openEmailTemplateEditDrawer}
          setOpenDrawer={setOpenEmailTemplateEditDrawer}
          type="EMAIL"
          edit
          selectedEmailTemplateValue={stateForEditEmailTemplate}
        />
      )}

      {Object.entries(genericDrawerState).map(
        ([drawerType, drawer]) =>
          drawer.openDrawer && (
            <AddOrEditGenericCommunicationTemplateDrawer
              key={drawerType}
              openDrawer={drawer.openDrawer}
              setOpenDrawer={(value) =>
                setGenericDrawerState((state) => ({
                  ...state,
                  [drawerType]: {
                    ...state[drawerType],
                    openDrawer: value,
                  },
                }))
              }
              type={drawerType}
              defaultErrorMessage={drawer.defaultErrorMessage}
              successMessage={drawer.successMessage}
              drawerTitle={drawer.drawerTitle}
              isHiddenNotificationLabel={drawer.isHiddenNotificationLabel}
              edit={drawer.edit}
              selectedEmailTemplateValue={drawer.val}
              testId={`CommunicationTemplatesDrawer${drawerType}`}
            />
          )
      )}
    </div>
  );
};

export default CommunicationTemplates;
