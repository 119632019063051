import { LABELS } from '../../../utils/enums/labels';
import { BASE_SETTINGS_KEYS } from './constants';
import { renderFieldLabels } from './renderFields';

const baseLabelSetting = ({
  data,
  setFields,
  fieldSlug,
  setIsError,
  existingFields,
}) => ({
  row1: [
    renderFieldLabels({
      data,
      setFields,
      fieldSlug,
      setIsError,
      existingFields,
    }),
    {
      label: LABELS.FIELD_PROPERTIES.LABEL_NAME,
      value: `${data?.[BASE_SETTINGS_KEYS.NAME] || ''}`,
      placeholder: '',
      valueKey: BASE_SETTINGS_KEYS.NAME,
      onChange: (value) => {
        setFields((prevState) => ({
          ...prevState,
          [BASE_SETTINGS_KEYS.NAME]: value,
        }));
      },
    },
  ],
  row2: [
    {
      label: LABELS.FIELD_PROPERTIES.DESCRIPTION,
      value: data?.[BASE_SETTINGS_KEYS.DESCRIPTION],
      placeholder: '',
      valueKey: BASE_SETTINGS_KEYS.DESCRIPTION,
      onChange: (value) =>
        setFields((ps) => ({ ...ps, [BASE_SETTINGS_KEYS.DESCRIPTION]: value })),
    },
    {
      label: LABELS.FIELD_PROPERTIES.API_URL,
      value: data?.[BASE_SETTINGS_KEYS.API_URL],
      valueKey: BASE_SETTINGS_KEYS.API_URL,
      placeholder: 'URL of the API',
      onChange: (value) =>
        setFields((ps) => ({ ...ps, [BASE_SETTINGS_KEYS.API_URL]: value })),
    },
  ],
  row3: [
    {
      label: LABELS.FIELD_PROPERTIES.KEY,
      value: `${data?.[BASE_SETTINGS_KEYS.KEY] || ''}`,
      valueKey: BASE_SETTINGS_KEYS.KEY,
      placeholder: '',
      onChange: (value) =>
        setFields((ps) => ({ ...ps, [BASE_SETTINGS_KEYS.KEY]: value })),
    },
    {
      label: LABELS.FIELD_PROPERTIES.VALUE,
      value: `${data?.[BASE_SETTINGS_KEYS.VALUE] || ''}`,
      placeholder: '',
      valueKey: BASE_SETTINGS_KEYS.VALUE,
      onChange: (value) =>
        setFields((prevState) => ({
          ...prevState,
          [BASE_SETTINGS_KEYS.VALUE]: value,
        })),
    },
    {
      label: LABELS.FIELD_PROPERTIES.MODULE_NAME,
      value: `${data?.[BASE_SETTINGS_KEYS.MODULE_NAME] || ''}`,
      valueKey: BASE_SETTINGS_KEYS.MODULE_NAME,
      placeholder: '',
      onChange: (value) =>
        setFields((prevState) => ({
          ...prevState,
          [BASE_SETTINGS_KEYS.MODULE_NAME]: value,
        })),
    },
  ],
});

export default baseLabelSetting;
