/* eslint-disable import/no-cycle */
// import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
// import store from '../../store/Store';
import _get from 'lodash/get';
import axiosInstance from '../../utils/axios/axios';

export const debounce = (callback, delay = 1000) => {
  let time;
  return (...args) => {
    clearTimeout(time);
    time = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

// For partial search use query=  and for complete search use this
// filter-> &$filter=${filterKey}
// eslint-disable-next-line no-unused-vars
export const searchApi = async (entity, value, filterKey = '') => {
  try {
    const response = await axiosInstance.get(
      `/search?entity=${entity}&$query=${value}`
    );
    return response?.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return message;
  }
};

export const childTemplateApi = async (nodeItem, idsList, setNodeItem) => {
  const ids = idsList; // Array of IDs

  // Construct the filter string
  const filter = ids.map((id) => `id eq '${id}'`).join(' or ');
  try {
    const response = await axiosInstance.get(
      `/templates/list?$filter=${filter}&$top=30&$skip=0&$select=id,name,slug,version,properties,permissions,metric,tenant_id&$orderby=created_on asc`
    );

    setNodeItem({ ...nodeItem, children: response?.data?.rows || [] });
    // setNodeItem({ ...nodeItem, children: ['s', 'o'] });

    // const { viewState } = store.getState();
    // const updatedList = viewState?.[listkey]?.data?.map((i) => {
    //   if (i?.id === itemId) {
    //     return { ...i, children: response?.data?.rows };
    //   }
    //   return i;
    // });

    // store.dispatch(
    //   updateViewState({
    //     stateKey: listkey,
    //     value: { filter: '', data: updatedList },
    //   })
    // );
    return response?.data?.rows;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return message;
  }
};

// const ke = 'properties.data';

// const ob = {
//   id: '121',
//   name: 'Template test3',
//   slug: 'Template test3',
//   version: '1',
//   properties: {
//     description: 'test description',
//     data: ['123', '345'],
//   },
//   metric: null,
//   tenant_id: '87f31231-304d-4d40-bd4e-c459caa294b9',
// };

export const getChildList = (obj, key) => {
  let result = obj;
  const keys = key?.split('.');

  for (let i = 0; i < keys?.length && typeof result === 'object'; i++) {
    result = result[keys[i]] || [];
  }
  return result;
};

export const evaluateElements = (value1, value2, operator) => {
  switch (operator) {
    case '>':
      return value1 > value2;
    case '<':
      return value1 < value2;
    case '=':
      return value1 === value2;
    case '!=':
      return value1 !== value2;
    default:
      return true;
  }
};

// have harcoded property of data?.length in checkVisibility func
export const checkVisibility = (val, viewState) => {
  if (val?.props?.condition) {
    const valueOfStateKey = _get(
      viewState,
      `${val?.props?.condition?.stateName}.${val?.props?.condition?.stateKey}`
    );
    if (val?.props?.condition) {
      return evaluateElements(
        valueOfStateKey,
        val?.props?.condition?.stateValue,
        val?.props?.condition?.operator
      );
    }
  }
  return true;
};

export const checkDisabled = (disabledCondition, viewState) => {
  if (disabledCondition) {
    const valueOfStateKey = _get(
      viewState,
      `${disabledCondition?.stateName}.${disabledCondition?.stateKey}`
    );

    if (disabledCondition) {
      return !evaluateElements(
        valueOfStateKey,
        disabledCondition?.stateValue,
        disabledCondition?.operator
      );
    }
  }
  return false;
};
export const stringFormatForBoundTo = (inputArray) => {
  if (inputArray.length > 3) {
    const user = inputArray[0];
    const team = inputArray[1];
    const actors = inputArray[3];
    return `${user}, ${team}, ${actors} + ${inputArray.length - 3}`;
  }
  if (inputArray.length === 3) {
    const user = inputArray[0];
    const team = inputArray[1];
    const actors = inputArray[3];

    return `${user}, ${team}, ${actors}`;
  } if (inputArray.length === 2) {
    return `${inputArray[0]}, ${inputArray[1]}`;
  } if (inputArray.length === 1) {
    return inputArray[0];
  }
  return '';
};
