import Joi from 'joi';
import { checkForV2 } from '../../helper';

export const stringRequiredValidation = Joi.string().required().messages({
  'string.empty': 'This field is required',
  'any.required': 'This field is required.',
});

export const DYNAMIC_MULTI_SELECT_WITH_INPUT_TAGS_SCHEMA = Joi.object()
  .keys({
    org: Joi.array().items(Joi.string()),
    roles: Joi.array().items(Joi.string()),
    teams: Joi.array().items(Joi.string()),
    users: Joi.array().items(Joi.string()),
    actors: Joi.array().items(Joi.string()),
    groups: Joi.array().items(Joi.string()),
    queues: Joi.array().items(Joi.string()),
  })
  .custom((value, helpers) => {
    // Check if at least one key in the object has a value
    const hasValue = Object.values(value).some((val) => val.length > 0);
    if (!hasValue) {
      return helpers.error('any.required', {
        message: 'At least one key must have a value',
      });
    }
    return value;
  })
  .unknown();

export const DYNAMIC_EXPRESSION_WITH_TAGS_SCHEMA = Joi.array()
  .min(1)
  .items(
    Joi.object({
      id: Joi.string().guid({ version: 'uuidv4' }).required(),
      operator: Joi.string(),
      leftOperand: Joi.string().required(),
      rightOperand: Joi.alternatives().conditional('operator', {
        is: Joi.valid('IS TRUE', 'IS FALSE'),
        then: Joi.string().allow(''),
        otherwise: Joi.array().min(1).items(Joi.string()).required(),
      }),
    }).unknown()
  )
  .messages({
    'array.min': 'At least one expression must be provided',
    'any.required': 'Each expression must contain the required fields',
    'string.guid': 'The ID field must be a valid UUIDv4 string',
  });

const V1_WORKFLOW_SCHEMA = Joi.array()
  .min(1)
  .items(
    Joi.object({
      id: Joi.string().guid({ version: 'uuidv4' }).required(),
      operator: Joi.string(),
      leftOperand: Joi.string().required(),
    }).unknown()
  )
  .messages({
    'array.min': 'At least one expression must be provided',
    'any.required': 'Each expression must contain the required fields',
    'string.guid': 'The ID field must be a valid UUIDv4 string',
  });

export const LIST_RESTRIC_TYPE_SCHEMA = Joi.object({
  listRestricType: DYNAMIC_MULTI_SELECT_WITH_INPUT_TAGS_SCHEMA,
}).unknown();

export const VALUE_LIST_SCHEMA = Joi.object({
  valueListObj: DYNAMIC_EXPRESSION_WITH_TAGS_SCHEMA,
}).unknown();

const listRestricTypeErrorMessage = 'At least one user, role, or team must be selected.';

export const RULE_BASIC_VALIDATIONS = Joi.object()
  .keys({
    transitionId: stringRequiredValidation,
    id: Joi.string().allow(null, ''),
    ruleType: Joi.string().valid('moveIssue', 'specificValue', 'restrictLinkedTicket').required(),
    reviewValueStatus: Joi.string().allow(''),
    checkValueStatus: Joi.string().allow(''),
    statusId: Joi.string().allow(''),
    includedStatus: Joi.bool().default(false),
    reverseRuleStatus: Joi.bool().default(false),
  })
  .unknown()
  .when(Joi.object({ ruleType: 'specificValue' }).unknown(), {
    then: VALUE_LIST_SCHEMA.keys({
      // valueListObj: DYNAMIC_EXPRESSION_WITH_TAGS_SCHEMA.required().messages({
      //   'any.required': listRestricTypeErrorMessage,
      // }),
      valueListObj: checkForV2()
        ? Joi.object({
          and: Joi.array().messages({
            'array.min': listRestricTypeErrorMessage,
            'any.required': listRestricTypeErrorMessage,
          })
        })
        : V1_WORKFLOW_SCHEMA.required().messages({
          'any.required': listRestricTypeErrorMessage,
        }),
    }),
  })
  .when(Joi.object({ ruleType: 'restrictLinkedTicket' }).unknown(), {
    then: {
      relationType: stringRequiredValidation,
      relationItemId: stringRequiredValidation,
      countCondition: Joi.object({
        and: Joi.array().min(1).messages({
          'array.min': 'At least one field must be selected.',
          'any.required': 'At least one field must be selected.',
        })
      }),
      conditions: Joi.object({
        and: Joi.array().messages({
          'array.min': 'At least one field must be selected.',
          'any.required': 'At least one field must be selected.',
        })
      }),
    }
  })
  .when(Joi.object({ ruleType: 'moveIssue' }).unknown(), {
    then: LIST_RESTRIC_TYPE_SCHEMA.keys({
      listRestricType:
        DYNAMIC_MULTI_SELECT_WITH_INPUT_TAGS_SCHEMA.required().messages({
          'any.required': listRestricTypeErrorMessage,
        }), // Adding the custom error message
      approvals: Joi.number().min(1).label('Approval Count').required()
        .messages({
          'number.base': 'Enter minimum approval count',
          'number.min': 'Minimum approval count should be 1',
        }),
    }),
  });
