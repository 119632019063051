import { Box } from '@chakra-ui/react';
import React, { useState } from 'react';
import CategoryList from './CategoryList';
import CreateEditCategory from './CreateEditCategory';

const StatusCategory = () => {
  const [addingStatus, setAddingStatus] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  return (
    <Box data-testid="StatusCategory">
      {!addingStatus && (
        <CategoryList
          setAddingStatus={setAddingStatus}
          setEditStatus={setEditStatus}
        />
      )}
      {addingStatus && (
        <CreateEditCategory
          setAddingStatus={setAddingStatus}
          edit={editStatus}
          setEditStatus={setEditStatus}
        />
      )}
    </Box>
  );
};

export default StatusCategory;
