/* eslint-disable react/prop-types */
import { Card, CardBody, Grid, GridItem } from '@chakra-ui/react';
import React from 'react';

const PreviewCard = ({ data }) => (
  <Card>
    <CardBody>
      <Grid templateColumns="repeat(12, 1fr)" rowGap={2} columnGap={2}>
        {data?.map((ins, index) => (
          <GridItem colSpan={ins?.width} colEnd={index % 2 !== 0 && 16}>
            {ins?.label}
          </GridItem>
        ))}
      </Grid>
    </CardBody>
  </Card>
);

export default PreviewCard;
