/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import axiosInstance from '../../utils/axios/axios';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import Tree from './TreeView';

const NestedList = ({ actions, columns, name, style, childKey, apiReq }) => {
  const { viewState } = useSelector((state) => state);
  const { endPoint = '' } = apiReq;
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loadNestedListData = async () => {
    const response = await axiosInstance.get(endPoint);
    dispatch(
      updateViewState({
        stateKey: name,
        value: { filter: '', data: response?.data },
      })
    );
  };

  useEffect(() => {
    loadNestedListData();
  }, [viewState[name]?.refresh]);

  const loadParticularTemplateData = async () => {
    if (id) {
      const response = await axiosInstance.get(
        `/templates/list?$top=1&$skip=0&$select=*&$filter=id eq ${id}&history=true`
      );
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: response?.data?.rows[0],
        })
      );
    }
  };

  useEffect(() => {
    loadParticularTemplateData();
  }, [id]);

  const onClickListItem = async (value) => {
    const action = actions[0];
    switch (action.target_type) {
      case 'navigate':
        navigate(
          `${action.target}${value[action.target_params[0]]}?name=${
            value[action.target_params[1]]
          }`
        );
        break;
      case 'popup':
        // code for pop-up
        break;
      default:
    }
  };

  return (
    <Box style={style} data-testid="NestedListBox">
      <Tree
        data={viewState[name]?.data}
        columns={columns}
        childKey={childKey}
        onClickListItem={onClickListItem}
      />
    </Box>
  );
};

export default NestedList;
