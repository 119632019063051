import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import CustomTabs from '../../../components/Tabs/Tabs';
import { renderFieldsBasedonType } from './utils/RenderFieldsBasedOnType';

const TabSectionLayout = ({ item, previewRenderer }) => {
  const tabItems = item?.subItems?.map((i) => ({
    ...i,
    title: i?.label,
    content: <div className="pt-5">{renderFieldsBasedonType(i)}</div>,
  }));

  return (
    <Grid
      templateColumns="repeat(12, 1fr)"
      flex="1 1 0px"
      gap={4}
      py="4"
      pl="2"
      pr="2"
      data-testid="TabSectionLayoutGrid"
    >
      {item?.type === 'component' ? (
        previewRenderer(item?.subItems)
      ) : (
        <>
          <GridItem colSpan={12} data-testid="TabSectionLayoutGridItem">
            <Box display="flex" width="100%" justifyContent="space-between" data-testid="TabSectionLayoutBox">
              <Box>{!item?.hideSectionLabel && item?.sectionName}</Box>
            </Box>
          </GridItem>
          <GridItem colSpan={12} data-testid="TabSectionLayoutCustomGridItem">
            <CustomTabs tabsData={tabItems} onChange={() => {}} />
          </GridItem>
        </>
      )}
    </Grid>
  );
};

TabSectionLayout.propTypes = {
  item: PropTypes.array.isRequired,
  previewRenderer: PropTypes.func.isRequired,
};

export default TabSectionLayout;
