import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import _filter from 'lodash/filter';
import TableViewWithSearch from './TableViewWithSearch';
import EditQueueFieldDrawer from './EditQueueFieldDrawer';
import { COLUMNS, mapQueueFieldData, ROUTING_QUEUE_KEY_MAP, ticketRoutingValidation } from './utils';
import { patchDataForTemplate } from '../TicketTemplateBuilder/utils/templateAPIUtils';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';

const TicketRouting = () => {
  const [errorWithPathAsKey, setErrorWithPathAsKey] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();

  const selectedTemplate = useSelector(
    (state) => state?.viewState?.selectedTemplate
  );

  const FIELDSDATA = _filter(
    selectedTemplate.fields,
    (field) =>
      field?.type_based_attributes?.collection?.specialField === 'queue'
  );

  const TICKET_ROUTING_DATA = selectedTemplate?.ticket_routing;

  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});

  const onEdit = useCallback((rowData = {}) => {
    setSelectedRowData({ ...rowData });
    setOpenEditDrawer(true);
  }, []);

  const onSave = async (dataObj) => {
    setIsLoading(true);
    const tempData = TICKET_ROUTING_DATA ? [...TICKET_ROUTING_DATA] : [];
    const index = tempData.findIndex((data) => data.queue_field_id === dataObj.queue_field_id);
    delete dataObj[ROUTING_QUEUE_KEY_MAP.NAME];

    const { error } = ticketRoutingValidation.validate(dataObj, {
      abortEarly: false,
    });
    if (error) {
      setIsLoading(false);
      error.details.forEach((err) => {
        const keyName = `${err.path[0]}.${err.path[1]}`;
        setErrorWithPathAsKey((pre) => ({ ...pre, [keyName]: err.message }));
      });
      return;
    }
    if (index === -1) {
      tempData.push(dataObj);
    } else {
      tempData[index] = dataObj;
    }

    const res = await patchDataForTemplate({
      id,
      data: tempData,
      key: 'ticket_routing',
      defaultErrorMessage:
      'Failed to add a new route',
      successMessage:
      'Successfully added a new route',
    });

    if (!res.error) {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: {
            ...selectedTemplate,
            ...res?.response?.data || {},
            ticket_routing: tempData,
          },
        })
      );
      setOpenEditDrawer(false);
    }
    setIsLoading(false);
  };

  return (
    <>
      <TableViewWithSearch
        placeholder="Search"
        columns={COLUMNS({ onEdit })}
        tableData={mapQueueFieldData(FIELDSDATA, TICKET_ROUTING_DATA) || []}
        searchKey="name"
      />
      {openEditDrawer && (
        <EditQueueFieldDrawer
          openDrawer={openEditDrawer}
          setOpenDrawer={setOpenEditDrawer}
          selectedRowData={selectedRowData}
          onSaveData={onSave}
          errorWithPathAsKey={errorWithPathAsKey}
          setErrorWithPathAsKey={setErrorWithPathAsKey}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default TicketRouting;
