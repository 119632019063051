/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable import/no-cycle */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable-next-line no-lone-blocks */
import React, { useState, useEffect } from 'react';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/layout';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import _map from 'lodash/map';
// eslint-disable-next-line no-unused-vars
import _set from 'lodash/set';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import _filter from 'lodash/filter';
import { ArrowLeftCircleIcon } from '@heroicons/react/24/outline';

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  // IconButton,
  DrawerBody,
  DrawerFooter,
  Tooltip,
  // RadioGroup,
  // Radio,
  HStack,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import {
  // ArrowBackIcon,
  InfoOutlineIcon
} from '@chakra-ui/icons';
import CustomButton from '../../../components/Button/SimpleButton';
import CustomToast from '../../../components/Notification/Notification';
// import CustomModal from '../../../components/Modal/Modal';
// import CustomInput from '../../../components/InputBox/Input';
// import CustomSelect from '../../../components/SelectBox/Select';
// import TextArea from '../TextArea';
// import CustomCheckBox from '../../../components/CheckBox/CheckBox';

import styles from './customTemplate.module.scss';
import {
  LayoutFields,
  roleSettingSelectOpt,
  CreateFlowRoleSettingOptions,
  roleMapping,
  ACCESS_KEY_NAME,
  selectOptionsChannels,
  selectOptionsNoteTypes,
  renderElement,
} from './CustomTemplateModalLayout';
import {
  DARK,
  LIGHT,
  BLACK,
  WHITE,
  GREY_BACKGROUND,
  DRAFT_BG,
  ALERT_COLOR,
  RULE_CARD_BORDER,
} from '../../../utils/enums/colors';
import { TemplateTypes } from '../../../utils/enums/types';
import {
  getChildTemplatesWithoutParent,
  createPayload,
  createTemplate,
  transformAPIData,
  getAllApprovedTickets,
  getFieldDataFromAPI,
} from './customTemplateModal.helper';
import { updateViewState } from '../../../redux/ViewSlice/ViewReducer';
import DynamicSelectWithInputTags from '../../../components/dynamicSelectWithInputTags/DynamicSelectWithInputTags';
// import DynamicExpressionWithTags
// from '../../../components/dynamicExpressionWithTags/DynamicExpressionWithTags';

import {
  createTemplateSchema,
  validationErrorMessageForTemplate,
} from '../../../utils/validationSchemas/validationSchemas';
import { checkForV2 } from '../../../utils/helper';
import { LABELS } from '../../../utils/enums/labels';
import {
  // canViewTemplate,
  // checkPermissionForTemplatesWithData,
  patchMultipleFieldsForTemplate,
} from '../../TicketTemplateBuilder/utils/templateAPIUtils';
import { isCurrentUserTemplateOwner } from '../../../utils/helper/roleBasedAccess';
// import CustomToolTip from '../../../components/Tooltip/Tooltip';

const SETTINGS_TABS = ['Basic Settings', 'Role Settings'];
const getNotesOrChannelsValue = (layoutState, item) => {
  if (item?.key === 'note_types' || item?.key === 'channels') {
    if (layoutState !== undefined && layoutState !== null) {
      // eslint-disable-next-line no-prototype-builtins
      if (layoutState.hasOwnProperty(item?.key)) {
        return layoutState[item?.key] || [];
      }
    }

    return item.value;
  }

  return layoutState[item?.key] || item.value;
};

const getErrorMessage = (el, layoutState, errorState) => {
  const message =
    typeof el?.errorMessage === 'function'
      ? el?.errorMessage(layoutState?.[el?.key])
      : errorState[el?.key];

  if (
    typeof message === 'string' &&
    message.toLowerCase().includes('running number')
  ) {
    return message
      .replace('running number', 'Ticket Digits')
      .replace('Running number', 'Ticket Digits');
  }

  return message;
};

const CustomTemplateModal = ({
  isOpenLayoutModal,
  setIsOpenLayoutModal,
  isEdit,
  layoutData,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [layoutState, setLayoutState] = useState(layoutData);
  const [errorState, setErrorState] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [childTemplates, setChildTemplates] = useState([]);
  const dispatch = useDispatch();
  const viewState = useSelector((state) => state.viewState);
  const { addToast } = CustomToast();
  const [searchParams, setSearchParams] = useSearchParams();
  // eslint-disable-next-line no-unused-vars
  const [selectOptions, setSelectOptions] = useState({});
  const updateSingle = (key, value, isRequired) => {
    const newdata = { ...layoutState };
    newdata[key] = value;
    const errorData = { ...errorState, [key]: isRequired ? !value : false };
    setErrorState(errorData);
    setLayoutState(newdata);
  };
  useEffect(() => {
    // Getting the approved templates only in v2 mode as we aren't showing the child templates in v1
    if (checkForV2()) {
      setLoading(true);
      getAllApprovedTickets().then((allTemplates) => {
        const childTemplatesWithoutParent = getChildTemplatesWithoutParent(
          allTemplates
        ).filter((t) => t.value !== id);

        setChildTemplates(childTemplatesWithoutParent);
        setLoading(false);
      });
    }

    setLayoutState((prevState) => ({
      ...prevState,
      template_type: TemplateTypes.SINGLE,
    }));
  }, []);

  useEffect(() => {
    if (isEdit) {
      transformAPIData(viewState?.selectedTemplate, (newLayoutState) => {
        setLayoutState(newLayoutState);
      });
    }
  }, [viewState?.selectedTemplate, childTemplates]);

  const onFinish = async () => {
    const upd = _cloneDeep(layoutState);
    upd.name = upd.name?.replace(/\s+/g, ' ').trim()
    if (layoutState?.[ACCESS_KEY_NAME?.NOTE_TYPES] === undefined) {
      upd.note_types = selectOptionsNoteTypes;
    }
    if (layoutState?.[ACCESS_KEY_NAME?.CHANNELS] === undefined) {
      upd.channels = selectOptionsChannels;
    }
    if (
      layoutState?.[ACCESS_KEY_NAME?.COLLABORATORS] &&
      // eslint-disable-next-line no-prototype-builtins
      upd?.collaborators?.hasOwnProperty('value')
    ) {
      upd.collaborators = upd.collaborators.value;
    }

    const payload = createPayload(upd);
    const { error } = createTemplateSchema.validate(payload, {
      abortEarly: false,
    });
    if (error) {
      let tempError = validationErrorMessageForTemplate(error);
      if (Object.keys(tempError).includes('properties')) {
        if (isEmpty(payload?.properties?.prefix)) {
          tempError = { ...tempError, prefix: 'prefix not found' };
        }
        if (isEmpty(payload?.properties?.running_number)) {
          tempError = {
            ...tempError,
            running_number: 'Ticket Digits not found',
          };
        }

        delete tempError.properties;
      }

      // if (checkIfNameIsDefault(payload?.name || '')) {
      //   tempError = {
      //     ...tempError,
      //     [ACCESS_KEY_NAME.NAME]: true,
      //   };
      // }

      setErrorState(tempError);
      setIsLoading(false);
      return;
    }

    // if (checkIfNameIsDefault(payload?.name || '')) {
    //   setErrorState((prev) => ({
    //     ...prev,
    //     [ACCESS_KEY_NAME.NAME]: true,
    //   }));
    //   setIsLoading(false);
    //   return;
    // }

    if (!payload?.properties?.note_types?.length) {
      setErrorState((prevState) => ({
        ...prevState,
        note_types: 'Note types must contain at least 1 item',
      }));
      setIsLoading(false);
      return;
    }

    if (!payload?.properties?.channels?.length) {
      setErrorState((prevState) => ({
        ...prevState,
        channels: 'Channels must contain at least 1 items',
      }));
      setIsLoading(false);
      return;
    }

    if (isEdit) {
      delete payload.version;
      delete payload.tenant_id;
      delete payload.fields;

      const res = await patchMultipleFieldsForTemplate({
        id: viewState?.selectedTemplate?.id,
        payload,
        defaultErrorMessage: 'Failed to update template',
        successMessage: 'Ticket type updated successfully',
      });

      if (!res.error) {
        dispatch(
          updateViewState({
            stateKey: 'selectedTemplate',
            value: res?.response?.data,
          })
        );

        dispatch(
          updateViewState({
            stateKey: 'ticket_template_list_short_view',
            value: {
              refresh: uuidv4(),
            },
          })
        );

        setSearchParams(
          Object.fromEntries([
            ...searchParams,
            ['name', res?.response?.data?.name],
          ])
        );

        setIsOpenLayoutModal(false);
      }
      setIsLoading(false);
    } else {
      const res = await createTemplate({ data: payload });
      if (res?.error) {
        addToast({
          message: res.error,
          type: 'error',
          duration: 2000,
          isClosable: true,
        });
      } else if (res?.response) {
        addToast({
          message: 'Ticket type created successfully',
          type: 'success',
          duration: 2000,
          isClosable: true,
        });
        dispatch(
          updateViewState({
            stateKey: 'ticket_template_list_short_view',
            value: {
              refresh: uuidv4(),
            },
          })
        );
        setIsOpenLayoutModal(false);
        navigate(
          `/template-makers/template/${res.response?.id}?name=${payload.name}`
        );
      }
      setIsLoading(false);
    }
  };

  const modalFooter = (
    <Box data-testid="CustomTemplateModalFooterBox">
      <CustomButton
        color={useColorModeValue(BLACK, WHITE)}
        onClick={() => {
          if (!isEdit) {
            navigate('/template-cardlist');
          }
          setIsOpenLayoutModal(false);
        }}
        buttonText="Cancel"
        variant="outline"
        className="mr-4"
        id="CustomTemplateModalOpenBtn"
        h="36px"
      />
      <CustomButton
        color="white"
        onClick={() => {
          setIsLoading(true);
          onFinish();
        }}
        buttonText="Continue"
        variant="solid"
        className={`${styles.createTemplateBtn} `}
        bg={useColorModeValue(LIGHT, DARK)}
        isLoading={isLoading}
        id="CustomTemplateModalContinueBtn"
        h="36px"
      />
      {!_isEmpty(errorState) && _filter(errorState, (es) => es).length > 0 && (
        <FormLabel color="red">Please fill all mandatory fields </FormLabel>
      )}
    </Box>
  );

  const disableIfTemplateWasApproved = (item) => {
    if (isEmpty(viewState?.selectedTemplate)) {
      return false;
    }
    if (
      ACCESS_KEY_NAME.OWNERS === item.key ||
      ACCESS_KEY_NAME.COLLABORATORS === item.key
    ) {
      if (id === undefined || id === null) {
        return false;
      }

      return !isCurrentUserTemplateOwner(viewState?.selectedTemplate);
    }
    if (
      ACCESS_KEY_NAME.NAME === item?.key ||
      ACCESS_KEY_NAME.PREFIX === item?.key ||
      ACCESS_KEY_NAME.SUFFIX === item?.key
    ) {
      return false;
    }
    return (
      (viewState?.selectedTemplate?.lifecycle_status !== 'DRAFT' ||
        isEmpty(viewState?.selectedTemplate?.lifecycle_status)) &&
      (ACCESS_KEY_NAME.RUNNING_NUMBER || ACCESS_KEY_NAME.START_AT === item?.key)
    );
  };

  useEffect(() => {
    const fetchDataForItem = async (item) => {
      if (item?.api?.path && item?.api?.method) {
        const res = await getFieldDataFromAPI(item.api.path);
        if (res && !res.error) {
          const options = res.map((entry) => ({
            label: entry[item.api.response.labelKey],
            value: entry[item.api.response.valueKey],
          }));
          setSelectOptions((prevOptions) => ({
            ...prevOptions,
            [item.key]: options,
          }));
          item.options = options;
        }
      } else if (item?.value) {
        setSelectOptions((prevOptions) => ({
          ...prevOptions,
          [item.key]: item.value.map((val) => ({
            label: val.label,
            value: val.value,
          })),
        }));
      }
    };

    LayoutFields.forEach((layout) => {
      layout.forEach((column) => {
        column.row?.forEach((item) => {
          fetchDataForItem(item);
        });
      });
    });
  }, []);

  return (
    <Drawer
      size="xl"
      placement="right"
      onClose={() => {
        if (!isEdit) {
          navigate('/template-cardlist');
        }
        setIsOpenLayoutModal(false);
      }}
      isOpen={isOpenLayoutModal}
      data-testid="AddOrEditEmailTemplateDrawerEmail"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Flex
            alignItems="center"
            gap="10px"
            borderBottom={`1px solid ${RULE_CARD_BORDER}`}
            pb="10px"
          >
            <ArrowLeftCircleIcon
              aria-label="Close Drawer"
              width="24px"
              height="24px"
              color="blue"
              cursor="pointer"
              onClick={() => {
                if (!isEdit) {
                  navigate('/template-cardlist');
                }
                setIsOpenLayoutModal(false);
              }}
            />
            {/* <IconButton
              colorScheme="blue"
              aria-label="Close Drawer"
              size="sm"
              borderRadius="50%"
              minW="1.5rem"
              height="1.5rem"
              variant="outline"
              icon={<ArrowBackIcon />}
              onClick={() => {
                if (!isEdit) {
                  navigate('/template-cardlist');
                }
                setIsOpenLayoutModal(false);
              }}
            /> */}
            <Text fontSize="16px">
              {`${isEdit ? 'Edit' : 'Create New'} ${LABELS.TITLE.TICKET_TYPE}`}
            </Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody px="24px">
          <>
            <Box
              className={styles.tab__parent}
              background={useColorModeValue('white', 'var(--drawer-bg)')}
            >
              <HStack
                spacing="32px"
                bg={useColorModeValue(GREY_BACKGROUND, '#4F5765')}
                className={styles.tab__container}
                data-testid="CustomTemplateModalBoxContainerFlex"
              >
                {SETTINGS_TABS?.map((tab, index) => (
                  <Box
                    key={tab}
                    role="tab"
                    className={styles.tab}
                    onClick={() => setActiveTab(index)}
                    data-testid="CustomTemplateModalTabsContainerBox"
                  >
                    <Text
                      px="12px"
                      py="8px"
                      className={`${styles.tab__text} ${
                        activeTab === index
                          ? useColorModeValue(
                              styles.tab__selected__light,
                              styles.tab__selected__dark
                            )
                          : ''
                      }`.trim()}
                    >
                      {SETTINGS_TABS[index]}
                    </Text>
                  </Box>
                ))}
              </HStack>
            </Box>

            {_map(LayoutFields, (tab, index) => {
              if (index === 0 && index === activeTab) {
                return _map(tab, (item) => (
                  <Grid
                    templateColumns={`repeat(${item.column}, 1fr)`}
                    gap={4}
                    className={styles.gridRow}
                    data-testid="CustomTemplateModalGridRowGrid"
                    px="2px"
                  >
                    {_map(item?.row, (el) => {
                      if (
                        (layoutState?.template_type === 'Single' ||
                          !layoutState?.template_type) &&
                        (el.key === 'child_templates_list' || el.forNested)
                      ) {
                        return null;
                      }
                      return (
                        <GridItem
                          colSpan={el.colSpan}
                          width={el?.width ? `${el.width}%` : '100%'}
                          data-testid="CustomTemplateModalGridItem"
                        >
                          <FormControl
                            isRequired={el?.isRequired}
                            key={el?.key}
                            isInvalid={errorState[el?.key]}
                            data-testid="CustomTemplateModalFormControl"
                          >
                            {el?.hideFormLabel ? null : (
                              <FormLabel
                                className={classnames(styles.label, 'ellipsis')}
                                data-testid="CustomTemplateModalFormLabel"
                              >
                                {el?.label}
                                {el?.isToolTip && (
                                  <Tooltip
                                    maxWidth="441px"
                                    borderRadius="6px"
                                    label={
                                      <Flex
                                        justifyItems="center"
                                        justifyContent="center"
                                        alignItems="start"
                                        p="10px"
                                      >
                                        <InfoOutlineIcon
                                          width="24px"
                                          height="24px"
                                          mr="10px"
                                          cursor="pointer"
                                          color={DRAFT_BG}
                                        />
                                        <Text className={styles.label}>
                                          {el?.toolTipInfo}
                                        </Text>
                                      </Flex>
                                    }
                                    className="shadow-lg"
                                    bg={useColorModeValue('white', '#374151')}
                                    color={useColorModeValue(
                                      ALERT_COLOR,
                                      'white'
                                    )}
                                  >
                                    <InfoOutlineIcon ml="5px" />
                                  </Tooltip>
                                )}
                              </FormLabel>
                            )}
                            {renderElement(
                              el,
                              childTemplates,
                              layoutState,
                              updateSingle,
                              errorState,
                              disableIfTemplateWasApproved,
                              getNotesOrChannelsValue,
                              styles
                            )}
                            {errorState[el?.key] &&
                              (el?.isRequired ||
                                el.key === ACCESS_KEY_NAME.START_AT) && (
                                <FormErrorMessage data-testid="CustomTemplateModalFormErrorMessage">
                                  {getErrorMessage(el, layoutState, errorState)}
                                </FormErrorMessage>
                              )}
                          </FormControl>
                        </GridItem>
                      );
                    })}
                  </Grid>
                ));
              }
              if (index === 1 && index === activeTab) {
                return (
                  <Grid
                    className={styles.gridRow}
                    data-testid="CustomTemplateModalDynamicSelectGrid"
                    px="2px"
                  >
                    {/* ***************{Viewers -section} **************** */}
                    <DynamicSelectWithInputTags
                      values={_get(layoutState, `viewers`)}
                      label="Viewers"
                      onChange={(value) =>
                        setLayoutState((prevState) => ({
                          ...prevState,
                          viewers: value,
                        }))
                      }
                      selectOptions={
                        isEdit
                          ? roleSettingSelectOpt
                          : CreateFlowRoleSettingOptions
                      }
                      mapping={roleMapping}
                      isReadOnly={
                        !id
                          ? false
                          : !isCurrentUserTemplateOwner(
                              viewState?.selectedTemplate
                            )
                      }
                    />
                    {/* ***************{Editors -section} **************** */}
                    <DynamicSelectWithInputTags
                      values={_get(layoutState, `editors`)}
                      label="Editors"
                      onChange={(value) =>
                        setLayoutState((prevState) => ({
                          ...prevState,
                          editors: value,
                        }))
                      }
                      selectOptions={
                        isEdit
                          ? roleSettingSelectOpt
                          : CreateFlowRoleSettingOptions
                      }
                      mapping={roleMapping}
                      isReadOnly={
                        !id
                          ? false
                          : !isCurrentUserTemplateOwner(
                              viewState?.selectedTemplate
                            )
                      }
                    />
                    {/* ***************{Expression -section} **************** */}
                    {/* {isEdit && (
                    <DynamicExpressionWithTags
                      templateId={layoutState?.id}
                      values={_get(layoutState, `expressions`)}
                      onChange={(value) =>
                        setLayoutState((prevState) => ({
                          ...prevState,
                          expressions: value,
                        }))
                      }
                      label="Expressions"
                    />
                  )} */}
                  </Grid>
                );
              }
              return null;
            })}
          </>
        </DrawerBody>
        <DrawerFooter>{modalFooter}</DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

CustomTemplateModal.propTypes = {
  isOpenLayoutModal: PropTypes.bool.isRequired,
  setIsOpenLayoutModal: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  layoutData: PropTypes.object,
};

CustomTemplateModal.defaultProps = {
  isEdit: false,
  layoutData: {},
};

export default CustomTemplateModal;
