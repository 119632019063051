import Joi from 'joi';

import { stringRequiredValidation } from '../validationSchemas';
import { FORM_MAP } from '../../../pages/TenantFilters/filters.constants';

export const FILTER_VALIDATIONS = Joi.object({
  [FORM_MAP.NAME]: stringRequiredValidation,
  [FORM_MAP.CONDITIONS]: Joi.array().required().min(1),
}).unknown();

export const FILTER_ROLE_MAPPING_VALIDATIONS = Joi.object({
  [FORM_MAP.ROLES]: stringRequiredValidation,
  [FORM_MAP.FILTERS]: Joi.array().required().min(1),
  [FORM_MAP.DEFAULT_FILTER]: stringRequiredValidation,
}).unknown();
