export const themeStyles = {
  light: {
    control: {
      backgroundColor: '#fff',
      fontSize: 14,
      fontWeight: 'normal',
      minHeight: 100,
      maxHeight: 400,
      height: 'auto',
      border: '1px solid silver',
    },
  
    '&multiLine': {
      control: {
        fontFamily: 'Inter',
        minHeight: 100,
        maxHeight: 400,
        height: 'auto'
      },
      highlighter: {
        padding: 9,
        border: '1px solid ',
        minHeight: 100,
        maxHeight: 400,
        height: 'auto'
      },
      input: {
        padding: 9,
        border: '1px solid silver',
        minHeight: 100,
        maxHeight: 400,
        height:'auto',
        overflow: 'auto',
        ':focus': {
          border: '1px solid silver',
        }
      },
    },
  
    '&singleLine': {
      display: 'inline-block',
      width: 180,
  
      highlighter: {
        padding: 1,
        border: '2px inset transparent',
      },
      input: {
        padding: 9,
        maxHeight: 38,
        border: '1px solid silver',
      },
    },
  
    suggestions: {
      list: {
        backgroundColor: 'white',
        border: '1px solid rgba(0,0,0,0.15)',
        fontSize: 14,
        height: 130,
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        scrollbarColor: 'grey white',
      },
      item: {
        padding: '5px 15px',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        '&focused': {
          backgroundColor: '#deebff',
        },
      },
    },
  },
  dark: {
    control: {
      backgroundColor: '#4F5765',
      fontSize: 14,
      fontWeight: 'normal',
      minHeight: 100,
      maxHeight: 400,
      height: 'auto',
    },
  
    '&multiLine': {
      control: {
        fontFamily: 'Inter',
        minHeight: 100,
        maxHeight: 400,
        height: 'auto',
      },
      highlighter: {
        padding: 9,
        minHeight: 100,
        maxHeight: 400,
        height: 'auto',
        border: '1px solid',
        '&focused': {
          border: '1px solid #3F444E',
        }
      },
      input: {
        padding: 9,
        border: '1px solid #3F444E',
        minHeight: 100,
        maxHeight: 400,
        height: 'auto',
        overflow: 'auto',
        '&focused': {
          border: '1px solid #3F444E',
        }
      },
    },
  
    '&singleLine': {
      display: 'inline-block',
      width: 180,
  
      highlighter: {
        padding: 1,
        border: '2px inset transparent',
      },
      input: {
        padding: 1,
        border: '2px inset',
      },
    },
  
    suggestions: {
      list: {
        backgroundColor: '#4F5765',
        border: '1px solid rgba(0,0,0,0.15)',
        fontSize: 14,
        height: 130,
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        scrollbarColor: 'grey white',
      },
      item: {
        padding: '5px 15px',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        '&focused': {
          backgroundColor: '#394353',
        },
      },
    },
  }
}






// export const themeStyles = {
//     light: {
//       control: {
//         backgroundColor: 'transparent',
//         fontSize: '0.9rem',
//         color: 'hsl(0, 0%, 20%)', // Regular text color
//         borderColor: '#3a546f',
//         overflow: 'hidden',
//         display: 'flex',
//         flexDirection: 'column',
//         position: 'relative',
//         minHeight: '100px',
//         borderRadius: '8px',
//         border: 'none',
//         height: '100%',
//       },
//       highlighter: {
//         padding: '0.7rem 1rem',
//         minHeight: '100%',
//         height: '100%',
//         overflow: 'auto',
//         flexGrow: 1,
//       },
//       input: {
//         padding: '0.7rem 1rem',
//         outline: 'none',
//         border: '0px',
//         fontSize: '0.9rem',
//         color: 'hsl(0, 0%, 20%)',
//         overflow: 'auto !important',
//         height: '100%',
//         flexGrow: 1,
//         '::placeholder': {
//           color: '#7288a3',
//         },
//       },
//       suggestions: {
//         backgroundColor: 'white',
//         boxShadow: '0px 0px 11px 0px rgba(0, 0, 0, 0.75)',
//         borderRadius: '8px',
//         maxWidth: '300px',
//         width: '100%',
//         maxHeight: '200px',
//         height: '100%',
//         overflowY: 'auto',
//         color: 'hsl(0, 0%, 20%)',
//         list: {
//           backgroundColor: 'white',
//           border: '1px solid rgba(0,0,0,0.15)',
//           fontSize: 14,
//           // height: '200px',
//           overflowY: 'auto',
//           scrollbarWidth: 'thin',
//           scrollbarColor: 'grey white',
//         },
//         item: {
//           padding: '6px 8px' ,
//           borderBottom: '1px solid #d1d5db',
//           '&focused': {
//             backgroundColor: '#deebff',
//             fontWeight: 600,
//           },
//         },
//       },
//     },
//     dark: {
//       control: {
//         backgroundColor: '#4F5765',
//         fontSize: '0.9rem',
//         color: 'white',
//         borderColor: '#3F444E',
//         overflow: 'hidden',
//         display: 'flex',
//         flexDirection: 'column',
//         position: 'relative',
//         minHeight: '100px',
//         borderRadius: '8px',
//         border: 'none',
//         height: '100%',
//       },
//       highlighter: {
//         padding: '0.7rem 1rem',
//         minHeight: '100%',
//         height: '100%',
//         overflow: 'auto',
//         flexGrow: 1,
//       },
//       input: {
//         padding: '0.7rem 1rem',
//         outline: 'none',
//         border: '0px',
//         fontSize: '0.9rem',
//         color: 'white',
//         backgroundColor: '#4F5765',
//         overflow: 'auto !important',
//         height: '100%',
//         flexGrow: 1,
//         '::placeholder': {
//           color: '#8899A6',
//         },
//       },
//       suggestions: {
//         backgroundColor: '#333',
//         boxShadow: '0px 0px 11px 0px rgba(0, 0, 0, 0.75)',
//         borderRadius: '8px',
//         maxWidth: '300px',
//         width: '100%',
//         maxHeight: '200px',
//         height: '100%',
//         overflowY: 'auto',
//         color: 'white',
//         list: {
//           backgroundColor: '#333',
//           border: '1px solid rgba(255, 255, 255, 0.15)',
//           fontSize: 14,
//           // height: 130,
//           overflowY: 'auto',
//           scrollbarWidth: 'thin',
//           scrollbarColor: 'grey #333',
//         },
//         item: {
//           padding: '6px 8px',
//           borderBottom: '1px solid rgba(255, 255, 255, 0.15)',
//           '&focused': {
//             backgroundColor: '#50597b',
//             fontWeight: 600,
//             color: 'white',
//           },
//         },
//       },
//     }
//   };