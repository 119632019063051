import React from 'react';
import { Box } from '@chakra-ui/react';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { getWholeOption } from '../../TicketTemplateWorkflow/helper';
import { getStatusFields, makeOptions } from '../../../utils/helper/fieldHelper';

const makeStatusOptions = (template) => template?.workflow?.workflow_status.filter((status) => status?.id !== 'start_1').map((status) => ({
  label: status?.data?.label,
  value: status?.id
}));

const renderStatusTransitionForm = (
  handleChange,
  actionToPerformSelectedIndex,
  handleActionErrorData,
  selectedTemplate,
  actionToPerform,
  handleChanges
) => (
  <>
    <Box data-testid="DynamicRendererTransitionFormBox1">
      <CustomSelectBox
        options={makeOptions(getStatusFields(selectedTemplate))}
        label="Status Field"
        placeholder="Select Field"
        value={getWholeOption(
          actionToPerform.status_field,
          makeOptions(getStatusFields(selectedTemplate))
        )}
        onChange={(value) => {
          if (value.value === actionToPerform.status_field) {
            handleChange(
              value.value,
              `action_to_perform[${actionToPerformSelectedIndex}].status_field`
            );
          } else {
            const events = [
              {
                value: value.value,
                path: `action_to_perform[${actionToPerformSelectedIndex}].status_field`
              },
              {
                value: '',
                path: `action_to_perform[${actionToPerformSelectedIndex}].to`
              }
            ];
            handleChanges(events);
          }

          handleActionErrorData('status_field');
        }}
        isMandatory
        id="DynamicRendererManualEventCustomSelectStatusField"
      />
    </Box>
    {
      actionToPerform.status_field && (
        <Box data-testid="DynamicRendererTransitionFormBox2">
          <CustomSelectBox
            options={makeStatusOptions(selectedTemplate)}
            label="To Status"
            placeholder="Select status"
            value={getWholeOption(
              actionToPerform.to,
              makeStatusOptions(selectedTemplate)
            )}
            onChange={(value) => {
              handleChange(
                value.value,
                `action_to_perform[${actionToPerformSelectedIndex}].to`
              );
              handleActionErrorData('to');
            }}
            isMandatory
            id="DynamicRendererManualEventCustomSelectTo"
          />
        </Box>
      )
    }
  </>
);

export default renderStatusTransitionForm;
