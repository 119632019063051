import { createData, updateData, getData } from '../../utils/crud';

export const getTenantPermission = async () => {
  const res = await getData({
    endpoint: '/actionPermissions/list?$select=*',
  });

  return res?.[0] || null;
};
export const createTenantPermission = async ({ payload }) => {
  const res = await createData({
    endpoint: '/actionPermissions/create',
    payload,
    successMessage: 'Tenant permission created successfully',
    errorMessage: 'Failed to create the tenant permission',
  });

  return res || null;
};

export const editTenantPermission = async ({ id, payload }) => {
  const res = await updateData({
    payload,
    endpoint: `/actionPermissions/update?$filter=id eq ${id}`,
    successMessage: 'Tenant permission updated successfully',
    errorMessage: 'Failed to update the tenant permission',
  });

  if (!res) {
    return null;
  }

  return res;
};
