/* eslint-disable import/no-cycle */
import React, { useLayoutEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Flex,
  Textarea,
  useColorModeValue,
  Tooltip,
  Box,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import CustomButton from '../../components/Button/SimpleButton';
import styles from './navigation.module.scss';
import {
  updateLifecycleStatus,
  interactNotificationAPI,
} from '../../pages/DynamicRenderer/customTemplateModal/customTemplateModal.helper';
import CustomToast from '../../components/Notification/Notification';
import { COLORS } from '../../utils/enums/colors';
import { enableApproveOnlyForOwnersInTemplate } from '../../utils/helper/roleBasedAccess';
import { TEMPLATE_TOOLTIPS } from '../../utils/enums/labels';

const STATUS = {
  Reject: 'REJECTED',
  Approve: 'APPROVED',
};

const AcceptRejectDialog = ({
  templateId,
  id,
  buttonText,
  colorScheme,
  _hover,
  fetchData,
  notificationData,
  templateData,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [comment, setComment] = useState('');
  const [error, setError] = useState(null);
  const [isLoadingForApiCall, setIsLoadingForApiCall] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);

  const { addToast } = CustomToast();

  const handleOnClick = async () => {
    setError('');
    const errorMessage = 'Comment cannot be empty';
    if (isEmpty(comment)) {
      setError(errorMessage);
    }
    if (!isEmpty(comment)) {
      const payload = {
        data: {
          lifecycle_status:
            STATUS[buttonText] !== 'REJECTED' ? STATUS[buttonText] : 'DRAFT',
          note: comment,
        },
      };
      const response = await updateLifecycleStatus(payload, templateId);
      if (response.error) {
        setError(response.error);
        setIsLoadingForApiCall(false);
        fetchData();
        return;
      }
      await interactNotificationAPI(id);
      addToast({
        message: `Template ${buttonText}ed`,
        type: buttonText === 'Approve' ? 'success' : 'error',
        duration: 2000,
        isClosable: true,
      });

      setIsLoadingForApiCall(false);
      onClose();
      window.location.reload();
      fetchData();
    }
    setIsLoadingForApiCall(false);
  };

  useLayoutEffect(() => {
    if (buttonText === 'Approve' || buttonText === 'Reject') {
      setIsBtnDisabled(
        enableApproveOnlyForOwnersInTemplate(templateData, buttonText)
      );
    }
  }, [templateData]);

  return (
    <>
      <Tooltip
        placement="top"
        hasArrow
        label={isBtnDisabled ? TEMPLATE_TOOLTIPS.ONLY_OWNERS : ''}
        data-testid="AcceptRejectDialogTooltip"
      >
        <Box data-testid="AcceptRejectDialogBox">
          <CustomButton
            buttonText={buttonText}
            // variant="outline"
            size="sm"
            mr={2}
            colorScheme={colorScheme}
            onClick={onOpen}
            _hover={_hover}
            isDisabled={isBtnDisabled}
            id="AcceptRejectToolTipButton"
          />
        </Box>
      </Tooltip>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        data-testid="AcceptRejectDialogModal"
      >
        <ModalOverlay />
        <ModalContent data-testid="AcceptRejectDialogModalContent">
          <ModalHeader data-testid="AcceptRejectDialogModalHeader">
            <Flex
              direction="column"
              justifyContent="center"
              data-testid="AcceptRejectDialogFlex"
            >
              <Text
                className={styles.dialogHeadingStyle}
                color={useColorModeValue('', COLORS.WHITE)}
                data-testid="AcceptRejectDialogHeadingStyleText"
              >
                {`${buttonText} Template`}
              </Text>
              <Text
                className={styles.dialogSubHeadingStyle}
                color={useColorModeValue('', COLORS.WHITE)}
                data-testid="AcceptRejectDialogSubHeadingStyleText"
              >
                {notificationData.templateName}
              </Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody data-testid="AcceptRejectDialogModalBody">
            <Text
              className={styles.commentStyle}
              color={useColorModeValue('', COLORS.WHITE)}
              data-testid="AcceptRejectDialogCommentStyleText"
            >
              Comment
            </Text>
            <Textarea
              placeholder="Your comment here..."
              onChange={(e) => setComment(e.target.value)}
              data-testid="AcceptRejectDialogTextArea"
            />
          </ModalBody>

          <ModalFooter data-testid="AcceptRejectDialogModalFooter">
            {error && (
              <span className="field-error" style={{ marginRight: '10px' }}>
                {error}
              </span>
            )}
            <CustomButton
              buttonText="Cancel"
              data-testid="cancelId"
              variant="unstyled"
              size="sm"
              color="rgba(134, 142, 150, 1)"
              mr={3}
              onClick={onClose}
              id="AcceptRejectModalCancelButton"
            />
            <CustomButton
              buttonText={buttonText}
              variant="solid"
              size="sm"
              colorScheme={colorScheme}
              onClick={() => {
                setIsLoadingForApiCall(true);
                handleOnClick();
              }}
              isLoading={isLoadingForApiCall}
              id="AcceptRejectMOdalSaveButton"
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

AcceptRejectDialog.propTypes = {
  templateId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  colorScheme: PropTypes.string.isRequired,
  _hover: PropTypes.string.isRequired,
  fetchData: PropTypes.func.isRequired,
  notificationData: PropTypes.object.isRequired,
  templateData: PropTypes.object,
};

AcceptRejectDialog.defaultProps = {
  templateData: {},
};

export default AcceptRejectDialog;
