export const LIGHT = '#2563EB';
export const DARK = '#427af5';
export const DEFAULT = '#868E96';
export const SELECT_LIGHT = '#EDEDFF';
export const DRAG_LIGHT = '#2563EB10';
export const BG_LIGHT = '#F0F0FE';
export const BG_DARK = '#F0F0FE';
export const SELECT_DARK = '#FFFFFF06';
export const DRAGGING = '#FFCCCB';
export const TEMPLATE_TITLE = '#1b1b1b';
export const ALERT_COLOR = '#2D3748';
export const BLACK = '#000000';
export const WHITE = '#FFFFFF';
export const LIGHT_BORDER = '#E2E3E4';
export const RED = '#E53E3E';
export const BORDER_LIGHT = 'gray.200';
export const BORDER_DARK = '#3f444e';
export const TEXT_LIGHT = '#374151';
export const TEXT_DARK = '#EDF2F7';
export const TO_DO_STATUS = '#333333';
export const IN_PROGRESS_STATUS = '#5C5CDE';
export const DONE_STATUS = '#2EC28D';
export const FIXING_STATUS = '#ED5703';
export const CAUTION_STATUS = '#cf431f';
export const DELETE_STATUS = '#ED7474';
export const BLACK_OPACITY = '#000000D9';
export const RULE_CARD_BORDER = '#E2E8F0';
export const TOGGLE_TABLE = 'hsl(0, 0%, 20%)';
export const GRAY_800 = '#1A202C';
export const RADIO_SELECTED_BG = '#F0F0FE';
export const WRAP_OPERATOR_BG = '#EBEBEB';
export const WRAP_OPERATOR_BG_DARK = '#333333';
export const BG_HOVER_SELECTED_LIGHT = '#E6F0FF';
export const BG_HOVER_SELECTED_DARK = '#E6F0FF';
export const WORKFLOW_DARK_MODE_BG = '#171923';
export const GREY_BACKGROUND = '#f2f3f4';
export const SIGNATURE_DRAWER_COLOR = '#A9A9A9';
export const SIGNATURE_DRAWER_BG = '#E2E2E2';
export const SIDEBAR_DRAWER_BG = 'rgba(255, 255, 255, 0.16)';
export const WRAP_BG_LIGHT = '#E0E7FF';
export const WRAP_COLOR_LIGHT = '#4338CA';
export const COUNT_BG = '#DEE8FC';
export const DRAFT_BG = '#E79B04';
export const TH_LIGHT = '#111827';
export const BG_BLUE = '#2563EB';
export const WHITE_COLOR = '#EEF2FF';
export const COLORS = {
  LIGHT,
  DARK,
  DEFAULT,
  SELECT_DARK,
  SELECT_LIGHT,
  DRAGGING,
  DRAG_LIGHT,
  TEMPLATE_TITLE,
  ALERT_COLOR,
  BLACK,
  WHITE,
  BG_LIGHT,
  LIGHT_BORDER,
  RED,
  TEXT_DARK,
  TOGGLE_TABLE,
  GRAY_800,
  RADIO_SELECTED_BG,
  WRAP_OPERATOR_BG,
  WRAP_OPERATOR_BG_DARK,
  BG_HOVER_SELECTED_LIGHT,
  BG_HOVER_SELECTED_DARK,
  WORKFLOW_DARK_MODE_BG,
  GREY_BACKGROUND,
  DELETE_STATUS,
  SIGNATURE_DRAWER_COLOR,
  SIGNATURE_DRAWER_BG,
  SIDEBAR_DRAWER_BG,
  WRAP_BG_LIGHT,
  WRAP_COLOR_LIGHT,
  COUNT_BG
};
