/* eslint-disable import/prefer-default-export */

import { getTenantData } from '../../../helpers/tenantUrl';

export const fieldsetsJson = {
  name: 'fieldsets',
  label: 'Fieldsets',
  table_name: 'fieldsets_table',
  route: '/fieldsets',
  permissions: [
    {
      name: 'edit',
      type: 'edit',
      roles: ['ticket_analyst', 'ticket_admin'],
      conditions: [
        {
          AND: [
            {
              OR: [
                {
                  condition: 'user_id = $user.id',
                },
                {
                  condition: 'collaborator_id = $user.id',
                },
              ],
            },
            {
              condition: 'status != published',
            },
          ],
        },
      ],
    },
    {
      name: 'view',
      type: 'view',
      roles: ['ticket_analyst', 'ticket_admin'],
    },
    {
      name: 'create',
      type: 'create',
      roles: ['ticket_analyst', 'ticket_admin'],
    },
    {
      name: 'approve',
      type: 'edit',
      roles: ['ticket_admin'],
    },
    {
      name: 'reject',
      type: 'edit',
      roles: ['ticket_admin'],
    },
  ],
  columns: [
    {
      name: 'name',
      label: 'Name',
      column_name: 'name',
      data_type: 'string',
    },
    {
      name: 'status',
      label: 'Status',
      column_name: 'status',
      data_type: 'string',
    },
    {
      name: 'parent',
      label: 'Parent',
      column: 'name',
      related_entity: 'parent_ticket_template',
    },
    {
      name: 'updated_on',
      label: 'Update On',
      column_name: 'updated_on',
      data_type: 'timestamp',
    },
  ],
  related_entities: [
    {
      name: 'parent_ticket_template',
      join_column: 'parent_id',
      related_table: 'ticket_template',
      related_join_column: 'id',
    },
    {
      name: 'ticket_template_fields',
      join_column: 'id',
      related_table: 'ticket_template_fields',
      related_join_column: 'ticket_template_id',
    },
  ],
  views: [
    {
      name: 'fieldsets_container',
      label: 'Fieldsets Container',
      show_label: false,
      type: 'container',
      style: { padding: '0px 32px', boxSizing: 'borderBox' },
      elements: [
        {
          name: 'fieldsets_title',
          type: 'text',
          value: 'Fieldsets',
          style: { fontSize: '29px', fontWeight: '600' },
        },
      ],
      views: [
        {
          name: 'fieldsets_expanded_list',
          label: 'Fieldsets List',
          type: 'list_exp',
          calculatedHight: 250,
          apiReq: {
            reqCols: ['*'],
            orderBy: 'created_on asc',
            endPoint: 'fieldsets/list',
          },
          related_entity: 'ticket_template_fields',
          columns: [
            {
              col_name: 'name',
              type: 'string',
            },
            {
              col_name: 'created_on',
              type: 'date',
            },
            {
              col_name: 'actions',
              type: 'actions',
            },
          ],
          filter: [
            {
              condition: 'is_active = true',
            },
          ],
          actions: [
            {
              name: 'view_workFlow',
              icon: 'viewIcon',
              type: 'button',
              target: '/fieldsets/fieldsets-makers/fieldset',
              target_type: 'navigate',
            },
            {
              name: 'edit_fieldsets',
              icon: 'editIcon',
              type: 'button',
              target_type: 'popup',
              label_key: 'label',
              target_modal_id: 'edit_fieldset_modal', // TODO confirm about modal
              master_type: 'FieldSet',
            },
            {
              name: 'delete_fieldsets',
              icon: 'deleteIcon',
              type: 'button',
              target_type: 'popup',
              label_key: 'label', // Key name to be displayed in modal as label
              target_modal_id: 'deleteModal',
              master_type: 'FieldSet',
              listKey: 'fieldsets_expanded_list', // identify the redux status for update it
              // eslint-disable-next-line no-template-curly-in-string
              target_url: '/fieldsets/delete?$filter=id eq ${id}',
            },
            {
              name: 'copy_fieldsets',
              icon: 'copyIcon',
              type: 'button',
              target_url: '/fieldsets/clone', // *for api call
              method: 'post',
              listKey: 'fieldsets_expanded_list', // identify the redux status for update it
              target_type: 'popup',
              target_modal_id: 'crudTemplateCloneModal',
              master_type: 'fieldset',
            },
          ],
          filterKey: 'name',
          listKey: 'fieldsets_expanded_list',
        },
        {
          name: 'action_container',
          label: 'Action Container',
          show_label: false,
          type: 'container',
          elements: [
            {
              name: 'search_fieldset_item',
              type: 'search',
              label: 'Search fields...',
              placeholder: 'Search fieldsets...',
              entity: 'Fieldsets',
              listKey: 'fieldsets_expanded_list',
              filterKey: '',
              style: { height: '40px' },
            },
          ],
          actions: [
            {
              name: 'create_new_action',
              label: 'Create New Fieldset',
              show_label: true,
              is_button: true,
              icon: '',
              action_type: 'get',
              permission: 'edit',
              view: 'edit_view',
              navigation: 'popup',
              target_type: 'popup',
              target_modal_id: 'create_fieldset_modal', // TODO confirm about modal
              type: 'button',
              classname: 'editbutton',
              style: {
                color: '#FFFFFF',
                fontWeight: '600',
                backgroundColor: '#2563EB',
              },
            },
          ],
          view_layout: {
            width: '100%',
            no_of_cols: 12,
            rows: [
              {
                i: 'search_fieldset_item',
                x: 0,
                y: 1,
                w: 3,
                // maxH: 'Infinity',
              },
              {
                i: 'create_new_action',
                x: 0,
                y: 1,
                w: 3,
                colEnd: -1,
                maxH: 'Infinity',
              },
            ],
          },
        },
      ],

      view_layout: {
        width: '100%',
        no_of_cols: 12,
        rows: [
          {
            i: 'fieldsets_title',
            x: 0,
            y: 1,
            w: 12,
            maxH: 'Infinity',
          },
          {
            i: 'action_container',
            x: 0,
            y: 0,
            w: 12,
            h: 1,
          },
          {
            i: 'fieldsets_expanded_list',
            x: 0,
            y: 0,
            w: 12,
            h: 1,
          },
        ],
      },
    },
    {
      name: 'create_fieldset_modal',
      type: 'modal',
      modal_id: 'create_fieldset_modal',
      modal_title: 'Create New Fieldset',
      isOpen: false,
      mandatoryFields: ['name', 'slug'],
      elements: [
        {
          name: 'fieldset_name',
          type: 'input',
          value: 'name',
          inputType: 'string',
          label: 'Name',
          isMandatory: true,
          linked_slug_field: 'slug',
          slug_prefix: 'Actors',
        },
        {
          name: 'fieldset_slug',
          type: 'input',
          value: 'slug',
          inputType: 'string',
          label: 'Slug',
          isMandatory: true,
        },
        {
          name: 'fieldset_description',
          type: 'input',
          value: 'description',
          inputType: 'string',
          label: 'Description',
          isMandatory: false,
        },
      ],
      actions: [
        {
          name: 'continue',
          label: 'Finish',
          show_label: true,
          icon: 'edit.ico',
          is_button: true,
          action_type: 'get',
          permission: 'edit',
          view: 'details_view',
          target_type: 'api_call',
          type: 'button',
          is_modal_footer: true,
          listKey: 'fieldsets_expanded_list',
          target_url: '/fieldsets/create',
          method: 'post',
          payloadKeysMaps: {
            slug: '',
            name: '',
            description: '',
            tenant_id: getTenantData()?.id,
            // status: 'DRAFT',
          },
          style: {
            color: '#FFFFFF',
            fontWeight: '600',
            backgroundColor: '#2563EB',
            padding: '20px',
          },
        },
        {
          name: 'cancel',
          label: 'Cancel',
          show_label: true,
          icon: 'edit.ico',
          is_button: true,
          action_type: 'get',
          permission: 'edit',
          view: 'details_view',
          target_type: 'state_change',
          type: 'button',
          is_modal_footer: true,
          style: {
            color: '#868E96',
            fontWeight: '600',
            padding: '20px',
          },
        },
      ],
      view_layout: {
        width: '100%',
        no_of_cols: 12,
        rows: [
          {
            i: 'fieldset_name',
            x: 0,
            y: 1,
            w: 6,
            // maxH: 'Infinity',
          },
          {
            i: 'fieldset_slug',
            x: 0,
            y: 1,
            w: 6,
            // maxH: 'Infinity',
          },
          {
            i: 'fieldset_description',
            x: 0,
            y: 1,
            w: 12,
            // maxH: 'Infinity',
          },
          {
            i: 'cancel',
            w: 6,
            h: 1,
          },
          {
            i: 'continue',
            w: 6,
            h: 1,
          },
        ],
      },
    },
    {
      name: 'edit_fieldset_modal',
      type: 'modal',
      modal_id: 'edit_fieldset_modal',
      modal_title: 'Edit Fieldset',
      isEdit: true,
      isOpen: false,
      elements: [
        {
          name: 'fieldset_name',
          type: 'input',
          value: 'name',
          inputType: 'string',
          label: 'Name',
          isMandatory: true,
          linked_slug_field: 'slug',
          slug_prefix: 'Actors',
        },
        {
          name: 'fieldset_slug',
          type: 'input',
          value: 'slug',
          inputType: 'string',
          label: 'Slug',
          isMandatory: true,
        },
        {
          name: 'fieldset_description',
          type: 'input',
          value: 'description',
          inputType: 'string',
          label: 'Description',
          isMandatory: false,
        },
      ],
      actions: [
        {
          name: 'continue',
          label: 'Save',
          show_label: true,
          icon: 'edit.ico',
          is_button: true,
          action_type: 'get',
          permission: 'edit',
          view: 'details_view',
          target_type: 'api_call',
          type: 'button',
          is_modal_footer: true,
          listKey: 'fieldsets_expanded_list',
          listIdentifier: 'id',
          // eslint-disable-next-line no-template-curly-in-string
          target_url: '/fieldsets/update?$filter=id eq ${id}',
          method: 'patch',
          payloadKeysMaps: {
            slug: '',
            name: '',
            description: '',
            // status: 'DRAFT',
          },
          style: {
            color: '#FFFFFF',
            fontWeight: '600',
            backgroundColor: '#2563EB',
            padding: '20px',
          },
        },
        {
          name: 'cancel',
          label: 'Cancel',
          show_label: true,
          icon: 'edit.ico',
          is_button: true,
          action_type: 'get',
          permission: 'edit',
          view: 'details_view',
          target_type: 'state_change',
          type: 'button',
          is_modal_footer: true,
          style: {
            color: '#868E96',
            fontWeight: '600',
            padding: '20px',
          },
        },
      ],
      view_layout: {
        width: '100%',
        no_of_cols: 12,
        rows: [
          {
            i: 'fieldset_name',
            x: 0,
            y: 1,
            w: 12,
            // maxH: 'Infinity',
          },
          {
            i: 'fieldset_slug',
            x: 0,
            y: 1,
            w: 12,
            // maxH: 'Infinity',
          },
          {
            i: 'fieldset_description',
            x: 0,
            y: 1,
            w: 12,
            // maxH: 'Infinity',
          },
          {
            i: 'cancel',
            w: 6,
            h: 1,
          },
          {
            i: 'continue',
            w: 6,
            h: 1,
          },
        ],
      },
    },
  ],

  view_layout: {
    width: '100%',
    no_of_cols: 12,
    rows: [
      {
        i: 'fieldsets_container',
        x: 0,
        y: 0,
        w: 12,
        h: 1,
      },
    ],
  },
};
