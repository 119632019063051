/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

const TextView = ({ name, value, style }) => (
  <p style={style} data-testid="TextViewTextP">
    {value}
  </p>
);

TextView.propTypes = {
  style: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default TextView;
