const removeAccentMarks = (str) =>
  str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const getNormalizedName = (name, removeDashes = true) => {
  if (name === undefined || typeof name !== 'string' || name === null) {
    return '';
  }

  const normalizedName = removeAccentMarks(name)
    .toLowerCase()
    .replace(/[^\w ]+/g, '') // Removes non word characters
    .replace(/_+/g, '') // Removes underscores
    .replace(/ +/g, '-'); // Replaces spaces with hyphens

  if (removeDashes) {
    return normalizedName.replace(/-/g, ''); // Removes dashes
  }

  return normalizedName;
};

// eslint-disable-next-line import/prefer-default-export
export const getFieldLabels = (fields) => {
  const uniqueLabels = new Set();
  fields?.forEach((field) => {
    if (field?.label) {
      const normalizedLabel = getNormalizedName(field?.label);
      if (!uniqueLabels.has(normalizedLabel)) {
        uniqueLabels.add(normalizedLabel);
      }
    }
  });
  return uniqueLabels;
};
