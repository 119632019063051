import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const keys = Object.freeze({
  AUDIENCE: 'audience',
  INTENT_GROUPS: 'intentGroups',
  FIELD_DEFAULTS: 'fieldDefaults',
});

const fieldDefaults = createSlice({
  name: 'fieldDefaults',
  initialState,
  reducers: {
    setData: (state, action) => {
      const { key, data } = action.payload;
      state[key] = data;
      return state;
    },
    updateData: (state, action) => {
      const { key, data, id } = action.payload;
      const temp = state[key];
      if (Array.isArray(temp)) {
        state[key] = temp.map((d) => (d.id === id ? data : d));
      } else {
        state[key] = data;
      }
      return state;
    },
    addData: (state, action) => {
      const { key, data } = action.payload;
      const temp = state[key];
      if (Array.isArray(temp)) {
        state[key] = temp.concat(data);
      }
      return state;
    },
    deleteData: (state, action) => {
      const { key, id } = action.payload;
      const temp = state[key];
      if (Array.isArray(temp)) {
        state[key] = temp.filter((d) => d.id !== id);
      }
      return state;
    }
  },
});

export const { setData, updateData, addData, deleteData } = fieldDefaults.actions;

export const getAudience = (state) => state?.fieldDefaults?.[keys.AUDIENCE];
export const getIntentGroups = (state) => state?.fieldDefaults?.[keys.INTENT_GROUPS];
export const getFieldDefaults = (state) => state?.fieldDefaults?.[keys.FIELD_DEFAULTS];

export default fieldDefaults.reducer;
