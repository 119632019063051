// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CIyBKG1RNV77hCmQ90aJ span{margin:0px !important;margin-inline-end:0}.zWCzWZmVyQHVMtaufa8b{font-size:15px !important;border-color:#2563eb !important;width:100%}.WW1xaR7swOF9F2EWgvxS{font-size:15px !important;border-color:#ed7474 !important;margin-right:80px;color:#ed7474}.XeKagwjxaj1ZK7AS36Ww svg{width:50px;height:50px}.Qg2zHOTvY56nGYVwtbH5 svg rect{fill:rgba(0,0,0,0)}.Qg2zHOTvY56nGYVwtbH5 svg path{fill:none;stroke:#ed7474}.xaHCfJClBdfSQoWKQgLb{font-size:30px;font-weight:600;color:rgba(0,0,0,.8509803922);margin-top:22px;margin-bottom:15px;line-height:35px}.pfclEn5CDkkbe59wPLFf{font-size:16px;color:#968e96;margin-top:15px;line-height:22px}`, "",{"version":3,"sources":["webpack://./src/pages/TicketTemplateWorkflow/styles/CategoryList.module.scss"],"names":[],"mappings":"AAAA,2BACQ,qBAAA,CACA,mBAAA,CAER,sBACI,yBAAA,CACA,+BAAA,CACA,UAAA,CAEJ,sBACI,yBAAA,CACA,+BAAA,CACA,iBAAA,CACA,aAAA,CAEJ,0BACQ,UAAA,CACA,WAAA,CAER,+BACQ,kBAAA,CAER,+BACQ,SAAA,CACA,cAAA,CAER,sBACI,cAAA,CACA,eAAA,CACA,6BAAA,CACA,eAAA,CACA,kBAAA,CACA,gBAAA,CAEJ,sBACI,cAAA,CACA,aAAA,CACA,eAAA,CACA,gBAAA","sourcesContent":[".emptyBtnWithIcon span {\n        margin: 0px !important; \n        margin-inline-end: 0;\n    }\n.addStatusBtn {\n    font-size: 15px !important;\n    border-color: #2563EB !important;\n    width: 100%;\n  }\n.delStatusBtn {\n    font-size: 15px !important;\n    border-color: #ED7474 !important;\n    margin-right: 80px;\n    color: #ED7474;\n}\n.statusDelete svg {\n        width: 50px;\n        height: 50px;\n      }\n.colorWhite svg rect {\n        fill: transparent;\n      }\n.colorWhite svg path {\n        fill: none;\n        stroke: #ed7474;\n      }\n.createHeadText {\n    font-size: 30px;\n    font-weight: 600;\n    color: #000000d9;\n    margin-top: 22px;\n    margin-bottom: 15px;\n    line-height: 35px;\n  }\n.paraText {\n    font-size: 16px;\n    color: #968e96;\n    margin-top: 15px;\n    line-height: 22px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyBtnWithIcon": `CIyBKG1RNV77hCmQ90aJ`,
	"addStatusBtn": `zWCzWZmVyQHVMtaufa8b`,
	"delStatusBtn": `WW1xaR7swOF9F2EWgvxS`,
	"statusDelete": `XeKagwjxaj1ZK7AS36Ww`,
	"colorWhite": `Qg2zHOTvY56nGYVwtbH5`,
	"createHeadText": `xaHCfJClBdfSQoWKQgLb`,
	"paraText": `pfclEn5CDkkbe59wPLFf`
};
export default ___CSS_LOADER_EXPORT___;
