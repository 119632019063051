import React from 'react';

import { Box, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import CustomTable from '../../../components/Table/Table';

const TemplateLocalesTable = ({
  // placeholder,
  columns,
  tableData,
  onRowClick,
  // searchKey,
  title,
}) =>
// const [filteredData, setFilteredData] = useState([...tableData]);
// const [searchInputValue, setSearchInputValue] = useState('');

// const filterData = debounce((e) => {
//   const filteredFields = tableData.filter((field) =>
//     field[searchKey].toLowerCase().includes(e.target.value.toLowerCase())
//   );
//   setFilteredData([...filteredFields]);
// }, 500);

  // useEffect(() => {
  //   setFilteredData([...tableData]);
  // }, [tableData]);
  // const handleClearInput = () => {
  //   setSearchInputValue('');
  //   setFilteredData([...tableData]);
  // };
  (
    <Box py="16px" data-testid="TemplateLocalesTableViewContainerBox">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        data-testid="TemplateLocalesTableViewContainerBoxFlex"
      >
        <Text style={{ fontSize: '16px', fontWeight: '600' }}>{title}</Text>
      </Box>

      <Box my="2px" data-testid="TemplateLocalesTableViewTableContainerBox">
        <CustomTable
          columns={columns}
          tableData={tableData}
          onRowClick={onRowClick}
          title={title}
        />
      </Box>
    </Box>
  );
TemplateLocalesTable.propTypes = {
  // placeholder: PropTypes.string,
  columns: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  // searchKey: PropTypes.string,
  title: PropTypes.string,
};

TemplateLocalesTable.defaultProps = {
  // placeholder: 'Search',
  onRowClick: undefined,
  // searchKey: 'label',
  title: 'Locales',
};

export default TemplateLocalesTable;
