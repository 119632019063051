import React from 'react';

import { Text, useColorModeValue } from '@chakra-ui/react';
import { CopyIcon, EditIcon } from '@chakra-ui/icons';

import TableColumnText from '../../../../components/Text/TableColumnText';

import { deleteRenderer, createMap } from '../../MetricsAndSLA/Metrics/utils';
import {
  WRAP_OPERATOR_BG,
  WRAP_OPERATOR_BG_DARK,
} from '../../../../utils/enums/colors';

export const AUDIENCE_KEY_MAP = {
  NAME: 'name',
  SELECTED_INTENT: 'selected_intent',
  INTENT: 'intent',
  ROLES: 'roles',
  FILTERS: 'filters',
  DESCRIPTION: 'description',
};

export const INTENT_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: 'create', label: 'Create' },
  { value: 'update', label: 'Update' },
  { value: 'delete', label: 'Delete' },
  { value: 'close', label: 'Close' },
  { value: 'reassign', label: 'Reassign' },
  { value: 'state_transition', label: 'State Transition' },
  { value: 'public_notes', label: 'Public Notes' },
  { value: 'private_notes', label: 'Private Notes' },
];

export const INTENT_OPTIONS_MAP = createMap(INTENT_OPTIONS);

export const convertRolesToArray = (roles) => {
  const convertedArray = Object.entries(roles).flatMap(([key, values]) =>
    Array.isArray(values)
      ? values.map((value) => ({
        leftOperand: key,
        operator: 'IS',
        rightOperand: value,
      }))
      : [
        {
          leftOperand: key,
          operator: 'IS',
          rightOperand: values,
        },
      ]
  );

  return convertedArray;
};

export const renderCriteria = (data) => {
  if (data.length > 0) {
    const { leftOperand, operator, rightOperand } = data[0];

    const classList = 'p-[5px] rounded-[5px] font-medium';

    return (
      <div className="flex gap-[5px] items-center">
        <p>{leftOperand}</p>
        {operator ? (
          <Text
            className={classList}
            backgroundColor={useColorModeValue(
              WRAP_OPERATOR_BG,
              WRAP_OPERATOR_BG_DARK
            )}
          >
            {operator}
          </Text>
        ) : null}

        {rightOperand ? <p>{rightOperand}</p> : null}
        <p>
          {data.length - 1 ? (
            <p className={classList}>{`+${data.length - 1}`}</p>
          ) : (
            ''
          )}
        </p>
      </div>
    );
  }

  return null;
};

export const convertFiltersToArray = (filters) => {
  const convertedArray = filters.flatMap((filter) =>
    filter.rightOperand.map((value) => ({
      leftOperand: filter.leftOperand,
      operator: filter.operator,
      rightOperand: value,
    }))
  );

  return convertedArray;
};

export const columns = ({ onClone, onDelete, onEditClick }) => [
  {
    col_name: AUDIENCE_KEY_MAP.NAME,
  },
  {
    col_name: AUDIENCE_KEY_MAP.INTENT,
    cellRenderer: ({ rowData }) => {
      let data = rowData?.[AUDIENCE_KEY_MAP.INTENT];
      data = Array.isArray(data) ? data : [];
      const columnText = data
        .reduce((prev, curr) => `${prev} ${INTENT_OPTIONS_MAP[curr]}, `, '')
        .trim()
        .slice(0, -1);

      return <TableColumnText text={columnText} />;
    },
  },
  {
    col_name: 'Criteria',
    cellRenderer: ({ rowData }) => {
      const roles = rowData?.[AUDIENCE_KEY_MAP.ROLES] || {};
      const filters = rowData?.[AUDIENCE_KEY_MAP.FILTERS] || [];

      const data = [];

      if (Object.keys(roles).length > 0) {
        data.push(...convertRolesToArray(roles));
      }
      if (filters.length > 0) {
        data.push(...convertFiltersToArray(filters));
      }

      return renderCriteria(data);
    },
  },
  {
    col_name: AUDIENCE_KEY_MAP.DESCRIPTION,
    cellRenderer: ({ rowData }) => {
      const columnText = rowData?.[AUDIENCE_KEY_MAP.DESCRIPTION] || '';
      return <TableColumnText text={columnText} />;
    },
  },
  {
    col_name: 'Actions',
    cellRenderer: ({ rowData }) => (
      <div className="flex gap-[10px]">
        <EditIcon
          className="cursor-pointer"
          onClick={() => {
            onEditClick(rowData);
          }}
        />
        {deleteRenderer({
          title: 'Delete this audience',
          description: 'Are you sure you want to delete this audience?',
          onConfirm: () => {
            onDelete(rowData.id);
          },
        })}
        <CopyIcon
          className="cursor-pointer"
          onClick={() => {
            onClone(rowData);
          }}
        />
      </div>
    ),
  },
];
