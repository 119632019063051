import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { BORDER_DARK, BORDER_LIGHT } from '../../utils/enums/colors';
import CustomTabs from '../../components/Tabs/Tabs';
import EmailSettings from './EmailSettings';

const OtherSettings = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const tabs = useMemo(() => {
    const temp = [
      {
        id: 'emailSettings',
        title: 'Email Settings',
        content: <EmailSettings />,
      },
    ];

    return temp;
  }, []);

  return (
    <Box p="15px" height="calc(100vh - 18rem)" overflow="auto">
      <Flex
        align="center"
        justify="space-between"
        borderBottom="1px solid"
        borderColor={useColorModeValue(BORDER_LIGHT, BORDER_DARK)}
        pb="3"
      >
        <Text fontWeight="500">Other Settings</Text>
      </Flex>
      {/* Add back when there are more tabs */}
      {/* <CustomTabs
        tabsData={tabs}
        onChange={(tabIndex) => {
          setSelectedIndex(tabIndex);
        }}
        variant="unstyled"
        specialTab
        index={selectedIndex}
        tabRadius="100px"
      /> */}
      <Flex align="center" justify="space-between" mt="30px">
        <Text fontWeight="500">Email Settings</Text>
      </Flex>
      <EmailSettings />
    </Box>
  );
};

export default OtherSettings;
