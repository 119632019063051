import { isEmpty } from 'lodash';
import axiosInstance from '../../../utils/axios/axios';
import { CustomToast } from '../../../components/Notification/Notification';
import { fieldValueOption } from './relation.helper';

const { addToast } = CustomToast();

// To create a new relationship definition
export const saveRelationDefinition = async (payload) => {
  try {
    const res = await axiosInstance.post('/relationDefinitions/create', {
      data: payload,
    });
    if (res?.status === 201) {
      addToast({
        message: 'Relation definition successfully created',
        type: 'success',
        duration: 2000,
        isClosable: true,
      });
      return res.data;
    }

    throw new Error('Failed to create relation definition');
  } catch (err) {
    addToast({
      message: 'Failed to create relation definition',
      type: 'error',
      duration: 2000,
      isClosable: true,
    });

    return null;
  }
};

// To get all the relationship definition
export const getRelationDefinition = async () => {
  try {
    const response = await axiosInstance.get(
      `/relationDefinitions/list?$select=*`
    );
    if (Array.isArray(response?.data?.rows)) {
      return response.data.rows;
    }
    return [];
  } catch (err) {
    return [];
  }
};

// To create a new relationship
export const saveRelation = async (payload) => {
  try {
    const res = await axiosInstance.post('/relations/create', {
      data: payload,
    });

    if (!res?.data?.id) {
      throw new Error('Something went wrong');
    }

    addToast({
      message: 'Relation successfully created',
      type: 'success',
      duration: 2000,
      isClosable: true,
    });

    return res?.data;
  } catch (err) {
    addToast({
      message: 'Relation creation failed',
      type: 'error',
      duration: 2000,
      isClosable: true,
    });

    return null;
  }
};

// To update a relationship
export const updateRelation = async (id, payload) => {
  try {
    const res = await axiosInstance.patch(
      `/relations/update?$filter=id eq ${id}`,
      {
        data: payload,
      }
    );

    if (res?.data?.rows_updated !== 1) {
      throw new Error('Something went wrong');
    }
    addToast({
      message: 'Relation successfully updated',
      type: 'success',
      duration: 2000,
      isClosable: true,
    });

    return res?.data?.data;
  } catch (err) {
    addToast({
      message: 'Failed to update relation',
      type: 'error',
      duration: 2000,
      isClosable: true,
    });

    return null;
  }
};

// To get all the relationships for the template
export const getRelations = async (templateId) => {
  try {
    const response = await axiosInstance.get(
      `/relations/templateIds?templateIds=${templateId}`
    );
    if (!Array.isArray(response.data)) {
      throw new Error('Something went wrong');
    }

    return response.data;
  } catch (err) {
    return [];
  }
};

// To delete a relationship
export const deleteRelations = async (id) => {
  try {
    await axiosInstance.delete(`/relations/delete?$filter=id eq ${id}`);

    addToast({
      message: 'Relation deleted successfully',
      type: 'success',
      duration: 2000,
      isClosable: true,
    });

    return true;
  } catch (err) {
    addToast({
      message: 'Failed to delete relation',
      type: 'error',
      duration: 2000,
      isClosable: true,
    });
    return false;
  }
};
export const removeMatchingOption = (optionList, stateForComparison, key = 'left') => {
  const templateIds = stateForComparison.map((item) => item[key].value);
  const filteredDataArray = optionList.filter(
    (item) => !templateIds.includes(item?.value?.toString())
  );

  return filteredDataArray;
};
export const getTypeOfFieldForRelation = (leftField, data, key) => {
  const type =
  [...(leftField?.fields || [])]?.find((f) => f?.id === data[key].value)
    ?.type || '';
  return type || '';
};
export async function isEachValueMapWithField(details, currentTemplate, hierarchies) {
  const similarities = details?.similarities || [];
  const results = await Promise.all(similarities.map(async (sim) => {
    const workFlowStates = (currentTemplate?.workflow?.workflow_status || [])
      .filter((state) => state.id !== 'start_1')
      .map((state) => ({ label: state.data.label, value: state.id }));

    const workflowData = {
      workFlowStates,
    };

    const options = await fieldValueOption(
      currentTemplate, workflowData, sim.fieldA.value, hierarchies
    );
    if (isEmpty(options)) return true;
    return options.length !== sim?.mappings.length;
  }));

  return results.some((result) => result === true);
}
