/* eslint-disable arrow-body-style */
import { redirect } from 'react-router-dom';
import { USER_NOT_AUTHENTICATED } from '../enums/axiosConstant';
import { getAgentDomain, getTenantData } from '../../helpers/tenantUrl';
// import { getTenantData } from '../../helpers/tenantUrl';

const axios = require('axios');

const getBaseUrl = () => {
  if (process.env.USE_LOCAL_API) {
    return process.env.LOCAL_API_URL;
  }

  const basePath = '/catalog';
  const baseDomainURL = getAgentDomain();
  return baseDomainURL + basePath;
};

// it is declare in global level may be first time it is null/ undefined for testing
// const token = localStorage.getItem('kc_token');

const tenantInformation = getTenantData();
const tenantId = tenantInformation?.id;

const axiosInstance = axios.create({
  baseURL: getBaseUrl(),
  // baseURL: 'https://catalog-service-api.91social.net',
  // baseURL: 'http://localhost:3000',
  headers: {
    'Content-Type': 'application/json',
    'ngrok-skip-browser-warning': true,
    authorization: `Bearer ${localStorage.getItem('kc_token')}`,
    'x-tenantid': tenantId,
    'x-runtime-version': 2, // This is needed to get the new templates
  },
});
const axiosInstanceWithoutBaseUrl = axios.create({
  baseURL: getAgentDomain(),
  // baseURL: 'https://catalog-service-api.91social.net',
  // baseURL: 'http://localhost:3000',
  headers: {
    'Content-Type': 'application/json',
    'ngrok-skip-browser-warning': true,
    authorization: `Bearer ${localStorage.getItem('kc_token')}`,
    'x-tenantid': tenantId,
    'x-runtime-version': 2, // This is needed to get the new templates
  },
});
export const APIGatewayService = axios.create({
  // TODO: Need to move base url constant to env file
  baseURL: 'https://apigateway-service-test.apps.actionable-science.com/',
  headers: {
    'Content-Type': 'application/json',
    'ngrok-skip-browser-warning': true,
    // authorization: `Bearer ${localStorage.getItem('kc_token')}`,
    'x-tenantid': tenantId,
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    // Wait for the token to be available
    const token = await localStorage.getItem('kc_token');
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use((res) => {
  if (res?.data?.errorId === USER_NOT_AUTHENTICATED) {
    redirect('/login');
    return;
  }
  // eslint-disable-next-line consistent-return
  return res;
});

axiosInstanceWithoutBaseUrl.interceptors.request.use(
  async (config) => {
    // Wait for the token to be available
    const token = await localStorage.getItem('kc_token');
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstanceWithoutBaseUrl.interceptors.response.use((res) => {
  if (res?.data?.errorId === USER_NOT_AUTHENTICATED) {
    redirect('/login');
    return;
  }
  // eslint-disable-next-line consistent-return
  return res;
});

export default axiosInstance;
export { axiosInstanceWithoutBaseUrl };
