const FieldTypes = {
  DEFAULT: '',
  TEXT: 'TEXT',
  EMAIL: 'EMAIL',
  NUMBER: 'NUMBER',
  DATE: 'DATE',
  RICH_TEXT: 'RICH_TEXT',
  BOOLEAN: 'BOOLEAN',
  METRICS: 'METRICS',
  MEDIA: 'MEDIA',
  TAGS: 'TAGS',
  LOOKUP: 'LOOKUP',
  DROPDOWN: 'DROPDOWN',
  RELATION: 'RELATION',
  COMPOSITE: 'COMPOSITE',
  FIELDSETS: 'FIELDSETS',
  LABEL: 'LABEL',
  TEXT_AREA: 'TEXT_AREA',
  SLA: 'SLA',
};

const TemplateTypes = {
  NESTED: 'Nested',
  SINGLE: 'Single',
};

const SettingsTabTypes = {
  BASIC: 'SettingsTabType::Basic',
  ADVANCED: 'SettingsTabType::Advanced',
  FIELD: 'SettingsTabType::Field',
  ROLE: 'SettingsTabType::Role',
};
const InputBoxTypes = {
  NUMBER: 'number',
};
const RenderTypes = {
  TEXT_AREA: 'textarea',
};

const FieldTabCollectionType = {
  STATIC: 'CollectionType::Static_Tag',
  DYNAMIC: 'CollectionType::Dynamic_Tag',
};
const STRING = 'string';
const STATIC = 'STATIC';
const RoleSettingsTabTypes = {
  ALL_ROLE: 'RoleSettingsTabTypes::AllRole',
  RESTRICT_TRANSITION_ROLE: 'RoleSettingsTabTypes::RestrictTransition',
  VALIDATE_DETAILS_ROLE: 'RoleSettingsTabTypes::ValidateDetails',
  PERFORM_ACTION_ROLE: 'RoleSettingsTabTypes::PerformActions',
};
/**
 *  key of typeNodes  should match with workflowNodeTypes value
 */
const WorkFlowNodeTypes = {
  toDoNode: 'toDoNode',
  selectorCreateNode: 'selectorCreateNode',
  toProgressNode: 'toProgressNode',
  toDoneNode: 'toDoneNode',
  toFixingNode: 'toFixingNode',
  toCautionNode: 'toCautionNode',
};

const APPROVAL_TYPES = {
  ALL_APPROVALS: 'all_approvals',
  MIN_APPROVAL_COUNT: 'min_approvals_count',
};

export {
  FieldTypes,
  TemplateTypes,
  SettingsTabTypes,
  InputBoxTypes,
  WorkFlowNodeTypes,
  RenderTypes,
  FieldTabCollectionType,
  STRING,
  STATIC,
  RoleSettingsTabTypes,
  APPROVAL_TYPES,
};
