import { isBoolean, isEmpty, isNumber } from 'lodash';
import {
  CAUTION_STATUS,
  DONE_STATUS,
  FIXING_STATUS,
  IN_PROGRESS_STATUS,
  TO_DO_STATUS,
  WHITE,
} from '../../../utils/enums/colors';
import {
  ruleReviewTextOption,
  rulereviewBothOption,
} from '../../../utils/enums/selectOption';
import { WorkFlowNodeTypes } from '../../../utils/enums/types';
import { checkForV2 } from '../../../utils/helper';
import { LifecycleStatusTypes } from '../../../utils/enums/lifecycleStatus';

export const getMaxXPosition = (nodes) =>
  nodes.reduce((acc, node) => Math.max(acc, node.position.x), 0);

export const findColorPalette = (value) => {
  const colorPaletteMap = {
    [WorkFlowNodeTypes.toDoNode]: TO_DO_STATUS,
    [WorkFlowNodeTypes.toProgressNode]: IN_PROGRESS_STATUS,
    [WorkFlowNodeTypes.toDoneNode]: DONE_STATUS,
    [WorkFlowNodeTypes.toFixingNode]: FIXING_STATUS,
    [WorkFlowNodeTypes.toCautionNode]: CAUTION_STATUS,
  };

  return colorPaletteMap[value] || WHITE;
};
export const addedPreFixText = (name) => `Add ${name}`;
export const mapToJsonArray = (inputMap) => [...inputMap.values()];
export const getLabelByValue = (value, state) => {
  const items = state.find((item) => item.value === value);
  return items ? items.label : '';
};
export const isIdFoundInArrays = (id, ...arrays) =>
  arrays.flat().some((obj) => obj.transitionId === id);
export const findOptionForRuleReview = (field) => {
  if (field === 'description' || field === 'summary') {
    return rulereviewBothOption;
  }
  return ruleReviewTextOption;
};
export const getWholeOption = (value, state, key = 'value') => {
  if ((!isEmpty(value) || isNumber(value) || isBoolean(value)) && Array.isArray(state)) {
    const items = state.find((item) => item[key] === value);
    return items || {};
  }
  return '';
};

export const getWholeMultiSelectOptions = (value, state, key = 'value') => {
  if (!isEmpty(value) && Array.isArray(value) && Array.isArray(state)) {
    // return state objects whose value is in value array
    const items = state.filter((item) => value.includes(item[key]));
    return items || [];
  }
  return '';
};
export const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1);
export const formatDate = (dateStr) => {
  const currentDate = new Date();
  const inputDate = new Date(dateStr);

  const timeDiff = currentDate.getTime() - inputDate.getTime();
  const secondsDiff = Math.floor(timeDiff / 1000);
  const minutesDiff = Math.floor(secondsDiff / 60);
  const hoursDiff = Math.floor(minutesDiff / 60);
  const daysDiff = Math.floor(hoursDiff / 24);
  const weeksDiff = Math.floor(daysDiff / 7);
  const monthsDiff = Math.floor(daysDiff / 30);

  if (daysDiff === 0) {
    return `Last updated Today (${inputDate.toLocaleTimeString()})`;
  }
  if (daysDiff === 1) {
    return `Last updated Last updated Yesterday (${inputDate.toLocaleTimeString()})`;
  }
  if (weeksDiff === 1) {
    return `Last updated One week ago (${inputDate.toLocaleDateString()})`;
  }
  if (monthsDiff === 1) {
    return `Last updated One month ago (${inputDate.toLocaleDateString()})`;
  }
  return inputDate.toDateString();
};

export const getOptionFormBool = (value, state) =>
  state.find((item) => item.value === value) || '';
export const getEdgeFromId = (id, state) => state.find((item) => item.id === id) || {};

export const isTemplateApproved = (templateLifeCycle) => {
  if (checkForV2()) {
    return false;
  }

  return templateLifeCycle === LifecycleStatusTypes.APPROVED;
};
