import React from 'react';
import _get from 'lodash/get';
import { useColorModeValue, Box } from '@chakra-ui/react';
import { PropTypes } from 'prop-types';
import CustomSelect from '../../../components/SelectBox/Select';
import { makeOptions, getQueueFields } from '../../../utils/helper/fieldHelper';
import { LIGHT, DARK } from '../../../utils/enums/colors';

const QueueAssignmentEvent = ({
  eventSettings = {},
  handleChange,
  actionToPerformSelectedIndex,
  actionErrorData,
  handleActionErrorData,
  template,
}) => {
  const queueField = _get(
    eventSettings,
    `action_to_perform[${actionToPerformSelectedIndex}].queue_field`
  );
  const options = makeOptions(getQueueFields(template));

  const handleQueueFieldChange = (value) => {
    handleChange(
      value,
      `action_to_perform[${actionToPerformSelectedIndex}].queue_field`
    );
    handleActionErrorData('queue_field');
  };
  const isError = !!actionErrorData?.status;
  const errorMessage = actionErrorData?.status || '';

  return (
    <Box marginTop="20px" data-testid="queueAssignmentBox">
      <CustomSelect
        label="Queue Field"
        options={options}
        placeholder="select queue field"
        value={queueField}
        onChange={handleQueueFieldChange}
        focusBorderColor={useColorModeValue(LIGHT, DARK)}
        isError={isError}
        errorMessage={errorMessage}
        isMandatory
      />
    </Box>
  );
};

QueueAssignmentEvent.propTypes = {
  eventSettings: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  actionToPerformSelectedIndex: PropTypes.number.isRequired,
  actionErrorData: PropTypes.object,
  handleActionErrorData: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
};

QueueAssignmentEvent.defaultProps = {
  actionErrorData: {},
};

export default QueueAssignmentEvent;
