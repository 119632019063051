import { useState } from 'react';
import _find from 'lodash/find';
import _lowerCase from 'lodash/lowerCase';
import _get from 'lodash/get';

const useTableSearchLocal = () => {
  const [filteredData, setFilteredData] = useState();

  const searchFilter = ({ data, columns, value }) => {
    const newFilteredData = data.filter((d) =>
      _find(columns, (col) => {
        if (col?.filterable) {
          const colValue = _get(d, `${col.col_name}`);
          if (!colValue) return false;
          return _lowerCase(colValue)?.includes(_lowerCase(value));
        }
        return false;
      })
    );
    setFilteredData([...newFilteredData]);
  };
  return { filteredData, searchFilter };
};

export default useTableSearchLocal;
