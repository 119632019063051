import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, HStack, VStack, Text } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

import PropTypes from 'prop-types';
import { cloneDeep, isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';

import NewRelationModal from './NewRelationModal';
import CustomInput from '../../../components/InputBox/Input';
import CustomSelectBox from '../../../components/SelectBox/Select';
import TableWithAction from '../../../components/Table/TableWithAction';

import {
  generateFieldMap,
  makeRelationDefinitionOptions,
  getPairedString,
  getRelationScopeFields,
  getFieldsForSelect,
  findPairB,
  fieldValueOption,
} from './relation.helper';
import {
  cardinality,
  mandatoryOption,
  optionLabel,
} from '../../../utils/constants';
import RELATION_VALIDATIONS from '../../../utils/validationSchemas/templates/relations';
import styles from './Relation.module.scss';
import {
  RELATION_TYPES
} from '../../../utils/enums/selectOption';
import {
  PrimaryCustomButton,
  SecondaryCustomButton,
} from '../../../components/Button/PrimarySecondaryButton';
import {
  isEachValueMapWithField,
  removeMatchingOption,
} from './relation.service';
import CustomToast from '../../../components/Notification/Notification';
import { getHierarchies } from '../../../components/dynamicExpressionWithTags/dynamicExpressionWithTags.helper';

const EditRelation = ({
  relationData,
  navigateBack,
  templateIdMap,
  offers,
  offerIdMap,
  relationshipOptions,
  onCreateNewRelationship,
  onSaveRelation,
  isRelationLoading
}) => {
  const { addToast } = CustomToast();
  const { id } = useParams();

  const [details, setDetails] = useState(relationData || {});
  const [showCreateRelationModal, setShowCreateRelationModal] = useState(false);
  const [isError, setIsError] = useState(false);
  const [hierarchies, setHierarchies] = useState([]);
  const cardinalityOptions = useMemo(
    () =>
      cardinality.map((c) => ({
        label: c.label,
        value: c.id,
      })),
    []
  );

  const mandatoryOptions = useMemo(
    () =>
      mandatoryOption.map((c) => ({
        label: c.label,
        value: c.id,
      })),
    []
  );

  const offerOptions = useMemo(() => {
    if (!Array.isArray(offers)) {
      return [];
    }

    return offers.map((offer) => ({
      value: offer?.id,
      label: offer?.name,
    }));
  }, [offers]);

  const enrichedRelationOptions = useMemo(
    () => makeRelationDefinitionOptions(relationshipOptions),
    [relationshipOptions]
  );

  const currentTemplate = useMemo(
    () => templateIdMap?.[id],
    [templateIdMap, id]
  );

  const isRight = details?.leftItemId && currentTemplate.id !== details?.leftItemId;

  const selectedTemplate = useMemo(() => {
    if (!details?.itemType) {
      return {};
    }
    return templateIdMap?.[details?.itemType];
  }, [details?.itemType, templateIdMap]);

  const currentTemplateFields = useMemo(
    () =>
      generateFieldMap(
        [...(currentTemplate?.fields || [])].filter(
          (item) => item.type === 'DROPDOWN'
        )
      ),
    [currentTemplate]
  );

  const selectedTemplateFields = useMemo(
    () =>
      generateFieldMap(
        [...(selectedTemplate?.fields || [])].filter(
          (item) => item.type === 'DROPDOWN'
        )
      ),
    [selectedTemplate]
  );

  const enrichedCurrentTemplateFields = useMemo(
    () => getFieldsForSelect(Object.values(currentTemplateFields)),
    [currentTemplateFields]
  );

  const enrichedSelectTemplateFields = useMemo(
    () => getFieldsForSelect(Object.values(selectedTemplateFields)),
    [selectedTemplateFields]
  );
  const combinedRelationalFields = useMemo(() => {
    const fields = getRelationScopeFields(
      Object.values(currentTemplateFields),
      currentTemplate?.id
    );

    fields.push(
      ...getRelationScopeFields(
        Object.values(selectedTemplateFields),
        selectedTemplate?.id
      )
    );

    // Removing the selected fields
    const customAttributes = details?.customAttribute || [];
    const fieldMap = customAttributes?.reduce((prev, curr) => {
      prev[curr?.field?.value || ''] = true;
      return prev;
    }, {});

    return fields.filter((f) => !fieldMap[f?.value || '']);
  }, [
    currentTemplateFields,
    selectedTemplateFields,
    currentTemplate,
    selectedTemplate,
    details?.customAttribute,
  ]);

  const cardinalityMap = useMemo(
    () =>
      cardinality.reduce((prev, curr) => {
        prev[curr.id] = curr.label;
        return prev;
      }, {}),
    []
  );

  const handleChange = useCallback((key, value) => {
    setDetails((prev) => ({
      ...prev,
      [key]: value,
    }));

    setIsError(false);
  }, []);

  const createNewRelation = useCallback(() => {
    setShowCreateRelationModal(true);
  }, []);

  // Add Custom Attributes
  const addAttribute = useCallback(() => {
    setDetails((prev) => {
      if (!prev?.field || !prev?.mandatory) {
        return prev;
      }

      const tempdata = cloneDeep(prev?.customAttribute || []);
      tempdata.push({
        field: prev?.field,
        mandatory: prev?.mandatory,
      });

      return {
        ...prev,
        field: null,
        mandatory: '',
        customAttribute: tempdata,
      };
    });
  }, []);
  // Delete Custom Attributes
  const deleteAttribute = useCallback((rowNo) => {
    setDetails((prev) => {
      const tempData = cloneDeep(prev?.customAttribute);
      tempData.splice(rowNo, 1);
      return {
        ...prev,
        customAttribute: tempData,
      };
    });
  }, []);

  const getDifferenceWithFormat = (arr1, arr2, checkKey = 'label') => {
    const arr2Set = new Set(arr2.map((item) => item[checkKey]));
    const difference = arr1.filter((item) => arr2Set.has(item[checkKey]));
    const formattedDifference = difference.map((item) => ({
      left: {
        value: item.value,
        label: item.label,
      },
      right: {
        value: item.value,
        label: item.label,
      },
    }));
    return formattedDifference;
  };
  const makeSimilaritiesByFieldType = async (fieldLeft, fieldRight) => {
    let leftFieldValueMapOption = [];
    let rightFieldValueMapOption = [];

    const getFieldData = (template, fieldId) => {
      const field =
        (template?.fields || []).find((f) => f?.id === fieldId) || {};
      const workflowStates = (template?.workflow?.workflow_status || [])
        .filter((state) => state.id !== 'start_1')
        .map((state) => ({ label: state.data.label, value: state.id }));
      return { field, workflowStates };
    };

    const leftData = getFieldData(currentTemplate, fieldLeft);
    const rightData = getFieldData(selectedTemplate, fieldRight);

    const leftOptions = await fieldValueOption(
      currentTemplate,
      { workFlowStates: leftData.workflowStates },
      fieldLeft,
      hierarchies
    );
    if (!isEmpty(leftOptions)) {
      leftFieldValueMapOption = [...leftOptions];
    }

    const rightOptions = await fieldValueOption(
      selectedTemplate,
      { workFlowStates: rightData.workflowStates },
      fieldRight,
      hierarchies
    );
    if (!isEmpty(rightOptions)) {
      rightFieldValueMapOption = [...rightOptions];
    }

    const leftTypeAttributes =
      leftData.field.type_based_attributes?.collection || {};
    const rightTypeAttributes =
      rightData.field.type_based_attributes?.collection || {};

    const isStaticCollection =
      leftTypeAttributes.collection_type === 'STATIC' &&
      rightTypeAttributes.collection_type === 'STATIC';

    const isSameDropdownType =
      leftData.field.type === 'DROPDOWN' && rightData.field.type === 'DROPDOWN';

    const isDependentCollection =
      leftTypeAttributes.collection_type === 'DEPENDENT' &&
      rightTypeAttributes.collection_type === 'DEPENDENT';

    const specialFieldsToCheck = ['source', 'queue'];
    const specialFieldsToCheckLabel = ['workflowState', 'actor'];
    if (isSameDropdownType && isStaticCollection) {
      return getDifferenceWithFormat(
        leftFieldValueMapOption,
        rightFieldValueMapOption
      );
    }

    if (isSameDropdownType) {
      if (isDependentCollection) {
        return getDifferenceWithFormat(
          leftFieldValueMapOption,
          rightFieldValueMapOption
        );
      }

      if (
        specialFieldsToCheck.includes(leftTypeAttributes.specialField) &&
        specialFieldsToCheck.includes(rightTypeAttributes.specialField)
      ) {
        return getDifferenceWithFormat(
          leftFieldValueMapOption,
          rightFieldValueMapOption,
          'value'
        );
      }
      if (
        specialFieldsToCheckLabel.includes(leftTypeAttributes.specialField) &&
        specialFieldsToCheckLabel.includes(rightTypeAttributes.specialField)
      ) {
        return getDifferenceWithFormat(
          leftFieldValueMapOption,
          rightFieldValueMapOption
        );
      }
    }

    return [];
  };

  // Add Similar Fields
  const addSimilarities = useCallback(async () => {
    setDetails((prev) => {
      if (!prev?.fieldA || !prev?.fieldB) {
        return prev;
      }
      return prev;
    });

    const prevState = details;
    if (prevState?.fieldA && prevState?.fieldB) {
      const data = await makeSimilaritiesByFieldType(
        prevState.fieldA.value,
        prevState.fieldB.value
      );

      const tempData = cloneDeep(prevState.similarities || []);
      tempData.push({
        fieldA: prevState.fieldA,
        similar: '~',
        fieldB: prevState.fieldB,
        mappings: data || [],
      });
      setDetails({
        ...prevState,
        similarities: tempData,
        fieldA: null,
        fieldB: null,
      });
    }
  }, [details]);

  const addValueMapInSimilarities = useCallback((similarIndex) => {
    setDetails((prev) => {
      if (!prev[`valueA-${similarIndex}`] || !prev[`valueB-${similarIndex}`]) {
        return {
          ...prev,
          [`valueA-${similarIndex}`]: null,
          [`valueB-${similarIndex}`]: null,
        };
      }

      const tempData = cloneDeep(prev?.similarities || []);
      const updatedMappings = [
        ...(tempData[similarIndex]?.mappings || []),
        {
          left: prev[`valueA-${similarIndex}`],
          right: prev[`valueB-${similarIndex}`],
        },
      ];

      tempData[similarIndex] = {
        ...tempData[similarIndex],
        mappings: updatedMappings,
      };
      return {
        ...prev,
        similarities: tempData,
        [`valueA-${similarIndex}`]: null,
        [`valueB-${similarIndex}`]: null,
      };
    });
  }, []);

  // Delete Similar Fields
  const deleteSimilarity = useCallback((rowNo) => {
    setDetails((prev) => {
      const tempData = cloneDeep(prev?.similarities);
      tempData.splice(rowNo, 1);
      return {
        ...prev,
        similarities: tempData,
      };
    });
  }, []);
  // delete similar mapping
  const deleteMapValueFromSimilarity = useCallback(
    (similarityRowNo, mappingRowNo) => {
      setDetails((prev) => {
        const tempData = cloneDeep(prev?.similarities);
        if (tempData && tempData[similarityRowNo]) {
          const updatedMappings = [
            ...(tempData[similarityRowNo]?.mappings || []),
          ];
          updatedMappings.splice(mappingRowNo, 1);
          tempData[similarityRowNo] = {
            ...tempData[similarityRowNo],
            mappings: updatedMappings,
          };
        }
        return {
          ...prev,
          similarities: tempData,
        };
      });
    },
    []
  );

  const onSave = useCallback(async () => {
    try {
      const result = await isEachValueMapWithField(details, currentTemplate, hierarchies);
      if (result) {
        addToast({
          title: 'Something went wrong',
          message: 'All value of fields must be mapped',
          type: 'error',
          duration: 2000,
          isClosable: true,
        });
        return;
      }

      const { error } = RELATION_VALIDATIONS.validate(details, {
        abortEarly: false,
      });
      if (error) {
        setIsError(true);
        return;
      }

      const customAttributes = details.customAttribute?.map((attr) => ({
        template_id: attr?.field?.template_id,
        field_id: attr?.field?.value,
        isRequired: attr?.mandatory === optionLabel.mandatory,
      }));

      const stringPairB = findPairB(
        details?.relation?.label,
        relationshipOptions
      );

      const similarities = // isMapSwap
        [...(details?.similarities || [])].map((sim) => ({
          left_field_id: sim?.fieldA?.value,
          right_field_id: sim?.fieldB?.value,
          mappings: sim?.mappings || [],
        }));

      // These keys can be changed from either side of the relationship.
      const payload = {
        cardinality: details?.cardinality,
        custom_attributes: customAttributes,
        similarity: similarities,
      };

      // Only update the following keys of the relation
      // if the relation id is not present/it is being created
      if (!details?.id) {
        payload.name = details.name?.replace(/\s+/g, ' ').trim();
        payload.template_id_left = id;
        payload.offer_id = details.offer_id;
        payload.template_id_right = details.itemType;
        payload.relation_type = RELATION_TYPES.OFFER;
        payload.relation_def = {
          left_to_right: details?.relation?.label,
          right_to_left:
            details?.relation?.label === stringPairB?.string_pair_a
              ? stringPairB?.string_pair_b
              : stringPairB?.string_pair_a,
      };
      }

      onSaveRelation(payload, details?.id);
    } catch (error) {
      console.error('Error:', error);
    }
  }, [
    id,
    details,
    currentTemplateFields,
    selectedTemplateFields,
    relationshipOptions,
    onSaveRelation,
  ]);

  useEffect(() => {
    getHierarchies(setHierarchies);
  }, []);

  useEffect(() => {
    if (details?.offer_id && !details?.id) {
      handleChange('itemType', offerIdMap[details.offer_id]?.item_type);
    }
  }, [details.offer_id]);

  const shouldShowPreview = () => {
    if (
      details?.relation &&
      details?.offer_id &&
      offerIdMap[details.offer_id]?.name &&
      details?.itemType
    ) {
      return true;
    }

    return false;
  };

  const getEntityNames = () => {
    const currentTemplateId = currentTemplate?.id;
    const isCurrentInLeft = currentTemplateId === details?.leftItemId;
    const otherTemplateId = isCurrentInLeft ? details?.rightItemId : details?.leftItemId;

    // offer id left and right is not avaialbe until the network request is made
    const currentOfferId = isCurrentInLeft
      ? (details?.offer_id_left || details?.offer_id)
      : details?.offer_id_right;
    const otherOfferId = isCurrentInLeft
      ? details?.offer_id_right :
      (details?.offer_id_left || details?.offer_id);

    const currentOfferName = offerIdMap[currentOfferId]?.name
      || `${templateIdMap[currentTemplateId]?.name} Default Offer`;
    const otherOfferName = offerIdMap[otherOfferId]?.name
      || `${templateIdMap[otherTemplateId]?.name} Default Offer`;

    return {
      currentOfferName,
      otherOfferName,
    };
  };

  const getCurrentPreview = () => {
    const { currentOfferName, otherOfferName } = getEntityNames();
    const relationName = details.relation.label;

    return `${currentOfferName} ${relationName} ${otherOfferName}`;
  };

  const getSelectedPreview = () => {
    const { currentOfferName, otherOfferName } = getEntityNames();

    const relationName = getPairedString(
      details.relation.label,
      relationshipOptions
    );

    return `${otherOfferName} ${relationName} ${currentOfferName}`;
  };

  return (
    <Box
      className={styles.relationContainer}
      height="calc(100vh - 15rem)"
      overflow="auto"
    >
      {showCreateRelationModal ? (
        <NewRelationModal
          show={showCreateRelationModal}
          setShow={setShowCreateRelationModal}
          onSuccess={onCreateNewRelationship}
        />
      ) : null}

      <div
        className="flex items-center gap-[5px] mb-[10px]"
        data-testid="relationsEdit"
      >
        <ArrowBackIcon
          className={`
            cursor-pointer
            border
            border-[#2563EB]
            p-1
            rounded-full
            hover:bg-[#2563EB]
            hover:text-white
          `}
          color="#2563EB"
          h="32px"
          w="32px"
          onClick={navigateBack}
          data-testid="RelationsEditModalBackIcon"
        />
        <p className="font-semibold text-[16px]">Relation</p>
      </div>

      <div>
        <CustomInput
          id="input-test-id"
          type="text"
          label="Name"
          value={details?.name || ''}
          isMandatory
          onChange={(e) => handleChange('name', e.target.value)}
          className={`${styles.inputBox}  ${styles.width}`}
        />

        <CustomSelectBox
          size="sm"
          className={styles.width}
          options={offerOptions}
          value={
            details?.offer_id
              ? {
                value: details.offer_id,
                label: offerIdMap[details.offer_id]?.name,
              }
              : null
          }
          onChange={(e) => {
            if (e.value !== details.offer_id) {
              handleChange('offer_id', e.value);
            }
          }}
          label="Offer"
          isDisabled={details?.id}
          isMandatory
        />

        {/* Select Relationship */}
        <div style={{ marginBottom: '.5rem' }}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="SCLabel">Relation</label>
          <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
        </div>
        <Box className="flex gap-3">
          <CustomSelectBox
            size="sm"
            options={enrichedRelationOptions}
            onChange={(e) => handleChange('relation', e)}
            value={details?.relation}
            className={`${styles.width}`}
            isDisabled={details?.id}
          />

          {!details?.id ? (
            <SecondaryCustomButton
              buttonText="Create New Relation"
              onClick={createNewRelation}
            />
          ) : null}
        </Box>

        {shouldShowPreview() ? (
          <Box
            minWidth="30%"
            maxWidth="50%"
            p={5}
            mh={60}
            bg="rgba(37, 99, 235, 0.17)"
            color="black"
          >
            <HStack align="flex-start">
              <VStack>
                <Text fontWeight={500}>{`Preview: `}</Text>
                <Text fontWeight={500}>{` `}</Text>
              </VStack>
              <VStack align="flex-start">
                <Text>{getCurrentPreview()}</Text>
                <Text>{getSelectedPreview()}</Text>
              </VStack>
            </HStack>
          </Box>
        ) : null}

        {/* To Select Cardinality */}
        <CustomSelectBox
          label="Cardinality"
          className={styles.width}
          value={
            details?.cardinality
              ? {
                value: details.cardinality,
                label: cardinalityMap[details.cardinality],
              }
              : null
          }
          options={cardinalityOptions}
          onChange={(e) => {
            handleChange('cardinality', e.value);
          }}
          isMandatory
          isDisabled={isRight || details?.id}
        />

        <div className={styles.title}>Custom Attributes</div>
        <Box className={styles.relationBox}>
          <CustomSelectBox
            options={combinedRelationalFields}
            onChange={(e) => handleChange('field', e)}
            value={details?.field || null}
            placeholder="Select Field"
            isDisabled={isRight || details?.id}
          />
          <CustomSelectBox
            options={mandatoryOptions}
            onChange={(e) => handleChange('mandatory', e.value)}
            value={
              details?.mandatory
                ? {
                  label: optionLabel[details?.mandatory],
                  value: details?.mandatory,
                }
                : null
            }
            placeholder="Select"
            isDisabled={isRight || details?.id}
          />
          <SecondaryCustomButton
            buttonText="Add"
            isDisabled={
              !details?.field ||
              !details?.mandatory ||
              details?.id ||
              isRight
            }
            onClick={addAttribute}
          />
        </Box>

        {details?.customAttribute?.length ? (
          <TableWithAction
            className={`${styles.table} ${styles.tdTable}`}
            columns={[]}
            rowData={details?.customAttribute || []}
            keys={['field.label', 'mandatory']}
            action="delete"
            onClick={deleteAttribute}
            hierarchies={hierarchies}
          />
        ) : null}

        {/* Similarity Section */}
        <div className={styles.title}>Similarity</div>
        <Box className={styles.relationBox}>
          <CustomSelectBox
            options={removeMatchingOption(
              enrichedCurrentTemplateFields,
              [...(details?.similarities || [])],
              'fieldA'
            )}
            onChange={(e) => handleChange('fieldA', e)}
            value={details?.fieldA || null}
            placeholder="Select Field"
            isDisabled={isRight || details?.id}
          />

          <CustomSelectBox
            options={removeMatchingOption(
              enrichedSelectTemplateFields,
              [...(details?.similarities || [])],
              'fieldB'
            )}
            onChange={(e) => handleChange('fieldB', e)}
            value={details?.fieldB || null}
            placeholder="Select Field"
            isDisabled={isRight || details?.id}
          />

          <SecondaryCustomButton
            buttonText="Add"
            isDisabled={!details?.fieldA || !details?.fieldB || details?.id || isRight}
            onClick={addSimilarities}
          />
        </Box>

        {details?.similarities?.length ? (
          <TableWithAction
            className={styles.table}
            columns={[]}
            rowData={details.similarities}
            keys={['fieldA.label', 'similar', 'fieldB.label']}
            action="delete"
            onClick={deleteSimilarity}
            leftTemplate={currentTemplate}
            rightTemplate={selectedTemplate}
            addValueMapInSimilarities={addValueMapInSimilarities}
            handleChangeForSelectBox={handleChange}
            details={details}
            deleteMapValueFromSimilarity={deleteMapValueFromSimilarity}
            showValueMapField
            hierarchies={hierarchies}
            isRight={isRight}
          />
        ) : null}

        <div className={`${styles.buttonsDiv} space-x-2.5`}>
          {isError ? (
            <span className="field-error">
              Please fill all mandatory fields
            </span>
          ) : null}
          <SecondaryCustomButton buttonText="Cancel" onClick={navigateBack} />
          <PrimaryCustomButton
            isLoading={isRelationLoading}
            buttonText="Save"
            onClick={onSave}
            isDisabled={isRight || details?.id}
          />
        </div>
      </div>
    </Box>
  );
};

EditRelation.propTypes = {
  relationData: PropTypes.object,
  navigateBack: PropTypes.func.isRequired,
  templateIdMap: PropTypes.object.isRequired,
  offers: PropTypes.array.isRequired,
  offerIdMap: PropTypes.object.isRequired,
  relationshipOptions: PropTypes.array.isRequired,
  onCreateNewRelationship: PropTypes.func.isRequired,
  onSaveRelation: PropTypes.func.isRequired,
  isRelationLoading: PropTypes.bool.isRequired,
};

EditRelation.defaultProps = {
  relationData: {},
};

export default EditRelation;
