import { createSlice } from '@reduxjs/toolkit';

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState: [],
  reducers: {
    storeFetchedFavorites: (state, action) => action.payload,
    storeCreatedFavorite: (state, action) => {
      state.push(action.payload);
    },
    removeFavoriteFromStore: (state, action) => {
      const removedTemplateId = action.payload;
      return state.filter(
        (favorite) => favorite.template_id !== removedTemplateId
      );
    },
  },
});

export const {
  storeFetchedFavorites,
  storeCreatedFavorite,
  removeFavoriteFromStore,
} = favoritesSlice.actions;

export default favoritesSlice.reducer;
