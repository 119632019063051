import axiosInstance from '../../../utils/axios/axios';

export const addSignatureInMasterSignatures = async (
  value,
  defaultErrorMessage
) => {
  try {
    const requestBody = {
      data: value,
    };

    const response = await axiosInstance.post(
      `/signatures/create`,
      requestBody
    );
    return {
      response: response?.data,
      error: null,
    };
  } catch (error) {
    return {
      error: error?.response?.data?.message || defaultErrorMessage,
      response: null,
    };
  }
};
export const updateSignatureInMasterSignatures = async (
  id,
  value,
  defaultErrorMessage
) => {
  try {
    const requestBody = {
      data: value,
    };

    const response = await axiosInstance.patch(
      `/signatures/update?$filter=id eq ${id}`,
      requestBody
    );
    return {
      response: response?.data,
      error: null,
    };
  } catch (error) {
    return {
      error: error?.response?.data?.message || defaultErrorMessage,
      response: null,
    };
  }
};
