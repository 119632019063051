import React from 'react';
import { Tag, TagCloseButton, TagLabel, Flex, Box } from '@chakra-ui/react';

import styles from '../../../TicketTemplateBuilder/styles/fieldRolePermission.module.scss';

export const renderTagsCategory1 = ({
  key,
  data,
  renderTagLabel,
  deleteTags,
}) => {
  const tagData = data?.[key] || [];

  if (!Array.isArray(tagData) || tagData.length === 0) {
    return null;
  }

  return (
    <>
      {tagData.map((expression, index) => (
        <Tag
          key={`attribute_${index + 1}`}
          size="lg"
          borderRadius="md"
          variant="solid"
          colorScheme="blue"
          mb="1rem"
          className={`${styles.expressionTagStyle} mr-[5px] justify-between`}
        >
          <TagLabel>{renderTagLabel(expression)}</TagLabel>
          {typeof deleteTags === 'function' ? (
            <TagCloseButton
              onClick={() => {
                deleteTags({ key, index, expression });
              }}
            />
          ) : null}
        </Tag>
      ))}
    </>
  );
};

export const renderTagsCategory2 = ({
  key,
  data,
  deleteTags,
  title,
  renderTagLabel,
  parentIndex = 0,
  colorScheme = 'gray',
  borderColor = '#2563EB',
}) => {
  const tagData = data?.[key] || data || [];

  if (!Array.isArray(tagData) || tagData.length === 0) {
    return null;
  }

  return (
    <Flex size="md" variant="solid" mb="1rem" alignItems="center">
      <Box
        className={`${styles.tagBoxStyle} ${styles.wrapTags}`}
        style={{ border: `1px solid ${borderColor}` }}
      >
        {title}
:
{tagData.map((expression, index) => (
          <Tag
            key={`values_${parentIndex}_${index + 1}`}
            size="lg"
            borderRadius="md"
            variant="subtle"
            colorScheme={colorScheme}
            ml="0.5rem"
            className={styles.tagNamestyle}
          >
            <TagLabel>{renderTagLabel(expression)}</TagLabel>
            {typeof deleteTags === 'function' ? (
              <TagCloseButton
                onClick={() => deleteTags({ key, index, expression })}
              />
            ) : null}
          </Tag>
))}
      </Box>
    </Flex>
  );
};
