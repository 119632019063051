import React from 'react';

import PropTypes from 'prop-types';

import CheckboxComponent from './CheckboxComponent';

import { checkboxStatus } from './utils';

import styles from './IndeterminateCheckbox.module.scss';

const CheckboxTree = ({ items, compute }) => {
  if (!Array.isArray(items)) {
    return null;
  }

  return (
  <ul className={styles.checkboxLists}>
    {items?.map((item) => {
      // if childList are there, then recursively call the Checkbox Tree
      let childList = null;
      if (Array.isArray(item?.items)) {
        childList = <CheckboxTree items={item.items} compute={compute} />;
      }

      return (
        <li key={item?.id} className={styles.listItem}>
          <CheckboxComponent
            id={item?.id}
            name={item?.name}
            checked={item?.checkboxStatus === checkboxStatus.checked}
            indeterminate={item?.checkboxStatus === checkboxStatus.indeterminate}
            compute={compute}
            dataTestId={`audience-intentGroups-${item?.name}-checkBox`}
          />
          {/* <label htmlFor={item?.name}>{item?.name || ''}</label> */}
          {childList}
        </li>
      );
    })}
  </ul>
  );
};

CheckboxTree.propTypes = {
  items: PropTypes.array.isRequired,
  compute: PropTypes.func.isRequired,
};

export default CheckboxTree;
