import React, { useState, useEffect } from 'react';
import { Alert, AlertIcon, Box, Flex } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import _get from 'lodash/get';
import CustomTabs from '../../../components/Tabs/Tabs';
import FieldMapping from './FieldMapping';
import CustomButton from '../../../components/Button/SimpleButton';
import { updateMapping, getFieldNameById } from './emailAndBotMapper.helper';
import { updateViewState } from '../../../redux/ViewSlice/ViewReducer';
import { CHANNEL_VALUES } from '../customTemplateModal/CustomTemplateModalLayout';
import { FIELD_VALUES, MESSAGES } from './emailAndBotMapping.constant';

const EmailAndBotMapping = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [botMapping, setBotMapping] = useState({
    subject: '',
    body: '',
  });
  const [mailMapping, setMailMapping] = useState({
    subject: '',
    body: '',
    attachment: '',
  });

  const viewState = useSelector((state) => state.viewState);
  const id = viewState?.selectedTemplate?.id;
  const dispatch = useDispatch();
  const updateState = (res) => {
    if (!res.error) {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: res?.response?.data,
        })
      );
    }
  };
  const handleOnChange = (value, type, identifier) => {
    if (type === CHANNEL_VALUES.bot) {
      setBotMapping((prevState) => ({
        ...prevState,
        [identifier]: value,
      }));
    }
    if (type === CHANNEL_VALUES.email) {
      setMailMapping((prevState) => ({
        ...prevState,
        [identifier]: value,
      }));
    }
  };

  const tabsData = [
    {
      id: CHANNEL_VALUES.email,
      title: 'Mailbox',
      content: (
        <FieldMapping
          type={CHANNEL_VALUES.email}
          mapping={mailMapping}
          onChange={(val, type, identifier) =>
            handleOnChange(val, type, identifier)
          }
        />
      ),
    },
    {
      id: CHANNEL_VALUES.bot,
      title: 'Bot',
      content: (
        <FieldMapping
          mapping={botMapping}
          type={CHANNEL_VALUES.bot}
          onChange={(val, type, identifier) =>
            handleOnChange(val, type, identifier)
          }
        />
      ),
    },
  ];
  const onSave = async () => {
    setIsLoading(true);
    const data = {
      bot_mapping: {
        subject: botMapping?.subject?.value,
        body: botMapping?.body?.value,
      },
      mail_mapping: {
        subject: mailMapping?.subject?.value,
        body: mailMapping?.body?.value,
        attachment: mailMapping?.attachment?.value,
      },
    };
    const res = await updateMapping({
      data,
      id,
      defaultErrorMessage: MESSAGES.defaultErrorMessage,
      successMessage: MESSAGES.successMessage,
    });
    updateState(res);
    setIsLoading(false);
  };

  const getFieldName = (template, mappingKey) =>
    getFieldNameById(template, _get(template?.mapping, mappingKey));

  useEffect(() => {
    if (viewState?.selectedTemplate) {
      const mailMappingKeys = [
        FIELD_VALUES.subject,
        FIELD_VALUES.body,
        FIELD_VALUES.attachment,
      ];
      const botMappingKeys = [FIELD_VALUES.subject, FIELD_VALUES.body];
      const mailMappingValues = mailMappingKeys.reduce((acc, key) => {
        const label = getFieldName(
          viewState?.selectedTemplate,
          `mail_mapping.${key}`
        );
        return {
          ...acc,
          [key]: {
            value:
              viewState?.selectedTemplate?.mapping?.mail_mapping?.[key] || '',
            label: label || '',
          },
        };
      }, {});
      const botMappingValues = botMappingKeys.reduce((acc, key) => {
        const label = getFieldName(
          viewState?.selectedTemplate,
          `bot_mapping.${key}`
        );
        return {
          ...acc,
          [key]: {
            value:
              viewState?.selectedTemplate?.mapping?.bot_mapping?.[key] || '',
            label: label || '',
          },
        };
      }, {});
      setMailMapping(mailMappingValues);
      setBotMapping(botMappingValues);
    }
  }, [viewState?.selectedTemplate]);
  const getActiveTabs = (tabs) =>
    tabs.filter((tab) =>
      viewState?.selectedTemplate?.properties?.channels?.includes(tab.id)
    );

  const activeTabs = getActiveTabs(tabsData);

  return (
    <Box mt={5}>
      {activeTabs.length > 0 && (
        <>
          <div data-testid="mapping_tab">
            <CustomTabs className="ml-16" tabsData={activeTabs} />
          </div>
          <Flex justifyContent="flex-end" m={5}>
            <CustomButton
              variant="solid"
              pl="5px"
              pr="5px"
              buttonColor="customBlue"
              size="lg"
              minWidth="115px"
              classname='calculatedHeight'
              onClick={onSave}
              buttonText="Save"
              isLoading={isLoading}
            />
          </Flex>
        </>
      )}
      {activeTabs.length <= 0 && (
        <Flex>
          <Alert status="info" mt="8" borderRadius="4px" alignItems="center">
            <AlertIcon />
            {MESSAGES.noChannelEnabled}
          </Alert>
        </Flex>
      )}
    </Box>
  );
};

export default EmailAndBotMapping;
