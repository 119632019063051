import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useColorMode,
  useColorModeValue,
  Box,
} from '@chakra-ui/react';
import styles from './tabs.module.scss';
import { COLORS } from '../../utils/enums/colors';

const CustomTabs = ({
  tabsData,
  size,
  colorScheme,
  onChange,
  variant,
  specialTab,
  defaultTabIndex,
  actionHeaderComponent,
  tabRadius,
  index,
  smallTabs,
  customWidth
}) => {
  const { colorMode } = useColorMode();
  const selectedTabStyle = useMemo(
    () =>
      useColorModeValue(
        { bg: COLORS.WHITE, borderRadius: tabRadius ? tabRadius : '5px' },
        {
          bg: COLORS.WRAP_OPERATOR_BG_DARK,
          borderRadius: tabRadius ? tabRadius : '5px',
        }
      ),
    [colorMode]
  );

  return (
    <Box>
    <Tabs
      size={size}
      colorScheme={colorScheme}
      onChange={onChange}
      variant={variant}
      defaultIndex={defaultTabIndex}
      // className={containerClassName}
      index={index}
    >
      <Box display={'flex'} justifyContent={'space-between'}>
      <TabList
        display="inline-flex"
        borderRadius={tabRadius ? tabRadius : specialTab ? '5px' : '0'}
        border="1px solid #D1D5DB"
        className={classNames(
          {
            [styles.tabListContainer]: specialTab,
            [styles.tabListContainerDark]: specialTab && colorMode === 'dark',
          },
          'custom__tablist'
        )}
        marginLeft="35px"
        width={customWidth??""}
      >
        {tabsData?.map((tab) => (
          <Tab
            key={tab.id}
            px="12px"
            py={smallTabs?"1px":"8px"}
            _selected={specialTab ? selectedTabStyle : ''}
            m={specialTab ? '5px' : ''}
            isDisabled={tab?.disabled}
            whiteSpace="nowrap"
          >
            {tab.title}
          </Tab>
        ))}
      </TabList>
      {actionHeaderComponent}
      </Box>
      <TabPanels>
        {tabsData?.map((tab) => (
          <TabPanel key={tab.id}>{tab.content}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
    </Box>
  );
};
CustomTabs.propTypes = {
  tabsData: PropTypes.instanceOf(Array).isRequired,
  size: PropTypes.string,
  colorScheme: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.string,
  specialTab: PropTypes.bool,
  defaultTabIndex: PropTypes.number,
  tabRadius: PropTypes.string,
  containerClassName: PropTypes.string,
  index: PropTypes.number,
  smallTabs: PropTypes.bool,
  customWidth: PropTypes.string
};
CustomTabs.defaultProps = {
  size: 'sm',
  colorScheme: `blue`,
  variant: 'line',
  specialTab: false,
  defaultTabIndex: 0,
  tabRadius: '',
  containerClassName: undefined,
  index: 0,
  smallTabs: false,
  customWidth: ''
};
export default CustomTabs;
