import { Box, Flex, Tooltip } from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';
import TextWithTooltip from '../../../components/Text';

const StatusAction = ({
  onClick,
  icon,
  text,
  bgColor,
  isDisabled,
  toolTipLable,
}) => (
  <Tooltip
    hasArrow
    label={isDisabled && toolTipLable}
    data-testid="StatusActionTooltip"
  >
    <Flex
      direction="column"
      alignItems="center"
      onClick={() => (isDisabled ? () => {} : onClick())}
      // pr="25px"
      data-testid="StatusActionFlex"
      pt="4px"
    >
      <Box
        w="25px"
        bg={bgColor}
        h="25px"
        borderRadius="5px"
        opacity={isDisabled && 0.4}
        justifyContent="center"
        alignItems="center"
        data-testid="StatusActionBox"
        display="flex"
      >
        {icon}
      </Box>
      <Box maxW="48px" noOfLines={1} textAlign="center" data-testid="StatusActionTooltipBox">
        <TextWithTooltip
          text={text}
          style={{
            textAlign: 'center',
            fontSize: '11px',
            fontWeight: 500,
            paddingTop: '8px',
          }}
        />
      </Box>
    </Flex>
  </Tooltip>
);
StatusAction.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  toolTipLable: PropTypes.string,
};
StatusAction.defaultProps = {
  isDisabled: false,
  toolTipLable: '',
};
export default StatusAction;
