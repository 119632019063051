import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../components/Button/SimpleButton';
import ConfigureIcon from '../../../assets/icons/ConfigureViewIcon.svg';
import AddNewFieldModal from '../AddNewFieldModal';
import CreateEditFieldModal from '../CreateEditFieldModal';
import CustomInput from '../../../components/InputBox/Input';
import {
  BORDER_DARK,
  BORDER_LIGHT,
  COLORS,
  DARK,
  LIGHT,
} from '../../../utils/enums/colors';
import styles from '../styles/particularTemplate.module.scss';
import { getFieldLabel, LABELS } from '../../../utils/enums/labels';

const FieldSettingsTab = ({ data }) => {
  const [openFieldsModal, setOpenFieldsModal] = useState(false);
  const [type, setType] = useState('');
  const [openCreateFieldModal, setOpenCreateFieldModal] = useState(false);
  const [openEditFieldModal, setOpenEditFieldModal] = useState(false);
  const [fieldData, setFieldData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (type) {
      setOpenFieldsModal(false);
      setOpenCreateFieldModal(true);
    }
  }, [type]);
  useEffect(() => {
    if (!openCreateFieldModal) {
      setType('');
    }
  }, [openCreateFieldModal]);

  const handleEditField = (fields) => {
    setFieldData(fields);
    setOpenEditFieldModal(true);
  };

  return (
    <Box display="block" width="100%" flex="1" pt="17px" data-testid="TemplateEditorDataContainerBox">
      <Flex
        flex="1"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        justifyContent="space-between"
        flexWrap="wrap"
        data-testid="TemplateEditorDataContainerFlex"
      >
        <Flex flex="1" data-testid="TemplateEditorDataCIFlex">
          <CustomInput
            id="TemplateEditorsearchField"
            placeholder={LABELS.ACTIONS.SEARCH_FOR_A_FIELD}
            onChange={() => {}}
            size="sm"
          />
          <Flex ml="auto">
            <CustomButton
              className={` ml-4`}
              leftIcon={<AddIcon />}
              color={useColorModeValue(LIGHT, DARK)}
              id="TemplateEditorbutton-edit-template"
              onClick={() => setOpenFieldsModal(true)}
              buttonText={LABELS.ACTIONS.ADD_A_FIELD}
              variant="outline"
              size="sm"
            />
            <CustomButton
              className={`${styles.btnRight} ${styles.customHeight} ml-4`}
              leftIcon={
                <ReactSVG
                  src={ConfigureIcon}
                  className={`${styles.configureIcon}  ${useColorModeValue(
                    COLORS.BLACK,
                    styles.configureIconWhite
                  )}`}
                />
              }
              color={useColorModeValue(COLORS.BLACK, COLORS.TEXT_DARK)}
              id="TemplateEditorViewbutton-edit-template"
              onClick={() => {
                navigate(
                  `/template-builder/configure-template-view/${data?.id}`,
                  {
                    state: data,
                  }
                );
              }}
              buttonText={LABELS.ACTIONS.CONFIGURE_VIEW}
              variant="outline"
            />
          </Flex>
        </Flex>
        <AddNewFieldModal
          isAddField={openFieldsModal}
          setIsAddField={setOpenFieldsModal}
          onClick={setType}
        />
        {openCreateFieldModal && (
          <CreateEditFieldModal
            type={type}
            setType={setType}
            isFieldModel={openCreateFieldModal}
            setIsfieldModel={setOpenCreateFieldModal}
            templateName={data?.name}
            modalFrom="Templates"
          />
        )}
      </Flex>
      <Box mt="5" data-testid="TemplateEditorDataTableContaineBox">
        <TableContainer
          borderRadius="12px"
          borderWidth="1.5px"
          fontSize="14px"
          borderColor={useColorModeValue(BORDER_LIGHT, BORDER_DARK)}
          color={useColorModeValue(COLORS.BLACK, COLORS.TEXT_DARK)}
          data-testid="TemplateEditorDataTableContainer"
        >
          <Table variant="simple" data-testid="TemplateEditorDataTable">
            <Thead data-testid="TemplateEditorDataThead">
              <Tr fontWeight="bold" data-testid="TemplateEditorDataThTr">
                <Th paddingLeft="16" data-testid="TemplateEditorDataNameTh">{LABELS.TITLE.FIELD_TABLE_NAME}</Th>
                <Th paddingLeft="32" data-testid="TemplateEditorDataTypeTh">{LABELS.TITLE.FIELD_TABLE_TYPE}</Th>
                <Th textAlign="right" paddingRight="32" data-testid="TemplateEditorDataActionTh">
                  {LABELS.TITLE.FIELD_TABLE_ACTIONS}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.fields?.map((i) => (
                <Tr key={i?.id} data-testid={`TemplateEditorDataTdTr${i}`}>
                  <Td paddingLeft="16" data-testid={`TemplateEditorDataTdTr${i}label`}>{i?.label}</Td>
                  <Td paddingLeft="32" data-testid={`TemplateEditorDataTdTr${i}type`}>{getFieldLabel(i?.type)}</Td>
                  <Td textAlign="right" paddingRight="32" data-testid={`TemplateEditorDataTdTr${i}Icon`}>
                    <EditIcon
                      onClick={() => handleEditField(i)}
                      className={styles.editIconLight}
                    />
                    <DeleteIcon className={styles.deleteIconLight} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      {openEditFieldModal && (
        <CreateEditFieldModal
          type={fieldData.type}
          setType={setType}
          fieldData={fieldData}
          edit
          isFieldModel={openEditFieldModal}
          setIsfieldModel={setOpenEditFieldModal}
          templateName={data?.name}
        />
      )}
    </Box>
  );
};

FieldSettingsTab.propTypes = {
  data: PropTypes.objectOf().isRequired,
};

export default FieldSettingsTab;
