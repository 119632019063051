import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import LinkedMetaFieldDrawer from './LinkedMetaFieldDrawer';
import { META_FIELD_DEFAULT_STATE } from './LinkedMetaFieldDrawer/utils';
import LinkedMetaFieldTableView from './LinkedMetaFieldTableView';
import { columns } from './utils';
import Loader from '../DynamicRenderer/Dashboard/Loader';
import {
  deleteMetaFields,
  getMetaFieldList,
  resetError,
} from '../../redux/MetaFieldsSlice/MetaFieldsReducer';
import CustomToast from '../../components/Notification/Notification';
import MetaFieldLayout from './MetaFieldLayout';
import {
  PRE_FIELDS_NO_ASSOCIATED,
  PRE_FIELDS_WITH_ASSOCIATIONS,
} from '../../redux/MetaFieldsSlice/MetaFieldsService';

const TicketMetaFields = () => {
  const { addToast } = CustomToast();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openLinkOrUnLinkDrawer, setOpenLinkOrUnLinkDrawer] = useState(false);
  const [stateLinkOrUnlinkDrawer, setStateLinkOrUnlinkDrawer] = useState(null);
  const [showTableLayout, setShowTableLayout] = useState(false);
  const { loading, metaFieldList, error } = useSelector(
    (state) => state.metaFields
  );
  const [isEditMetaFieldName, setIsEditMetaFieldName] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMetaFieldList('some thing went wrong'));
  }, []);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(resetError());
      }, 500);
    }
  }, [error]);

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return addToast({
      title: 'Something went wrong',
      message: 'Meta field api fail but predefine meta field will load',
      type: 'error',
    });
  }

  const showModal = (data) => {
    const arr = [...PRE_FIELDS_NO_ASSOCIATED, ...PRE_FIELDS_WITH_ASSOCIATIONS];

    const TEMP_DATA = data?.rowData;
    setStateLinkOrUnlinkDrawer(TEMP_DATA);
    setOpenLinkOrUnLinkDrawer(true);
    const isDeleteButtonVisible = arr.every(
      (field) => field.meta_field_id !== data?.rowData?.meta_field_id
    );
    setIsEditMetaFieldName(isDeleteButtonVisible);
  };

  const onDelete = (data) => {
    const metaFieldIdToDelete = data?.rowData?.id;
    if (!isEmpty(data?.rowData?.field_data)) {
      addToast({
        title: 'Failed to delete Meta Fields',
        message: 'Due to ticket type is linked',
        type: 'error',
      });
      return;
    }
    dispatch(deleteMetaFields(metaFieldIdToDelete))
      .then(() => {
        addToast({
          title: 'Success',
          message: 'Meta Fields successfully deleted',
          type: 'success',
        });
      })
      .catch((err) => {
        addToast({
          title: 'Something went wrong',
          message: 'Failed to delete Meta Fields',
          type: 'error',
        });
      });
  };

  return (
    <>
      {showTableLayout && (
        <MetaFieldLayout
          isOpen={showTableLayout}
          setIsOpen={setShowTableLayout}
          setShowTableLayout={setShowTableLayout}
        />
      )}
      <LinkedMetaFieldTableView
        columns={columns({
          showModal,
          onDelete,
        })}
        tableData={metaFieldList}
        placeholder="Search meta field"
        searchKey="name"
        addNewMetaField={() => {
          setOpenDrawer(true);
        }}
        setShowTableLayout={setShowTableLayout}
      />

      {openDrawer && (
        <LinkedMetaFieldDrawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          selectedMetaFieldValue={META_FIELD_DEFAULT_STATE}
        />
      )}
      {stateLinkOrUnlinkDrawer && openLinkOrUnLinkDrawer && (
        <LinkedMetaFieldDrawer
          openDrawer={openLinkOrUnLinkDrawer}
          setOpenDrawer={setOpenLinkOrUnLinkDrawer}
          selectedMetaFieldValue={stateLinkOrUnlinkDrawer}
          isCreateMetaField={false}
          isEditMetaFieldName={isEditMetaFieldName}
        />
      )}
    </>
  );
};

export default TicketMetaFields;
