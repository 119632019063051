export const getQueueFields = (template) =>
  template?.fields
    ?.map((field) => {
      if (field.type_based_attributes?.collection?.specialField === 'queue' && field.type_based_attributes?.collection?.collection_type !== 'DEPENDENT') {
        return field;
      }
      return null;
    })
    .filter((field) => field);

export const getActorFields = (template) =>
  template?.fields
    ?.map((field) => {
      if (field.type_based_attributes?.collection?.specialField === 'actor') {
        return field;
      }
      return null;
    })
    .filter((field) => field);

export const makeOptions = (fields) =>
  fields?.map((d) => ({
    label: d?.label,
    value: d?.id,
  }));

export const makeOptionValue = (id, fields) => {
  const matchedField = fields?.find((f) => f?.id === id);
  if (matchedField) {
    return {
      label: matchedField?.label,
      value: matchedField?.id,
    };
  }
  return null;
};

export const getQueueFieldsForV1 = (template) =>
  template?.fields
    ?.map((field) => {
      if (field.label.toLowerCase() === 'queue') {
        return field;
      }
      return null;
    })
    .filter((field) => field);
