import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import { Box, useColorModeValue } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import Circle from '../../assets/icons/Circle.svg';
// import TriangleRightIcon from '../../assets/icons/TriangleRightIcon.svg';
import Plus from '../../assets/icons/Plus.svg';
import PlusDark from '../../assets/icons/PlusDark.svg';
import Minus from '../../assets/icons/Minus.svg';
import MinusDark from '../../assets/icons/MinusDark.svg';
import CustomToolTip from '../../components/Tooltip/Tooltip';
import styles from './treeStyles.module.scss';
import {
  BLACK,
  COLORS,
  DARK,
  LIGHT,
  SELECT_DARK,
  SELECT_LIGHT,
  WHITE,
} from '../../utils/enums/colors';

const Tree = ({ data = [], childKey, onClickListItem, columns }) => (
  <div
    className={styles.tree}
    style={{ marginBottom: '1rem' }}
    data-testid="TreeContainerDiv"
  >
    <ul
      className={`${styles['tree-node-container']} ${styles.treeNodeContainer}`}
    >
      {data.map((tree) => (
        <TreeNode
          node={tree}
          key={tree?.id}
          childKey={childKey}
          onClickListItem={onClickListItem}
          columns={columns}
        />
      ))}
    </ul>
  </div>
);

const TreeNode = ({ node, childKey, onClickListItem, columns }) => {
  const [childVisible, setChildVisiblity] = useState(false);
  const { id } = useParams();
  const hasChild = !!node?.isNested;

  return (
    <>
      <Box
        className={`${styles['tree-node']} list-none`}
        key={node?.id}
        height="auto"
        as="span"
        fontSize="15px"
        pl="2"
        borderRadius="6px"
        _hover={{
          bg: COLORS.BG_LIGHT,
          color: 'black',
          // color: useColorModeValue('black', 'white'),
        }}
        bg={node?.id === id ? useColorModeValue(SELECT_LIGHT, SELECT_DARK) : ''}
        color={
          node?.id === id
            ? useColorModeValue(LIGHT, DARK)
            : useColorModeValue(BLACK, WHITE)
        }
        data-testid="TreeNodeContainerBox"
      >
        <div className={styles['tree-head']} data-testid="TreeNodeContainerDiv">
          {hasChild ? (
            <div
              className={`${styles['tree-toggler']} ${''}`}
              data-testid="TreeNodeReactSvgDiv"
            >
              <ReactSVG
                src={useColorModeValue(
                  childVisible ? Minus : Plus,
                  childVisible ? MinusDark : PlusDark
                )}
                color={useColorModeValue(BLACK, WHITE)}
                className={styles.triangleDown}
                onClick={(e) => {
                  e.preventDefault();
                  setChildVisiblity((v) => !v);
                }}
              />
            </div>
          ) : (
            <ReactSVG src={Circle} className={styles.circle} />
          )}
          <div
            className={`${styles['tree-head']} ${styles.treeHead} `}
            data-testid="TreeNodeTreeHeadDiv"
          >
            <i
              className={`mr-1 ${node?.icon}`}
              style={{ marginRight: '0.5rem' }}
            />
            <div
              className={`${styles['tree-node-label']}`}
              onClick={(e) => {
                e.preventDefault();
                onClickListItem(node);
              }}
              data-testid="TreeNodeTreeNodeLabelDiv"
            >
              <CustomToolTip
                id="tooltip-test"
                toolTipText={
                  hasChild
                    ? `${node[columns]} (${node[childKey]?.length})`
                    : node[columns]
                }
              >
                {/* hardcoded width as ellipsis was not working for full texts */}
                <span className="ellipsis pt-2" style={{ width: '270px' }}>
                  {hasChild
                    ? `${node[columns]} (${node[childKey]?.length})`
                    : node[columns]}
                </span>
              </CustomToolTip>
            </div>
          </div>
        </div>
      </Box>

      {hasChild && childVisible && (
        <div className={styles['tree-content']}>
          <ul
            className={`${styles['tree-node-container']} ${styles.treeNodeContainer} ${styles.nested}`}
          >
            {node[childKey].map((child) => (
              <TreeNode
                node={child}
                key={child.id}
                childKey={childKey}
                onClickListItem={onClickListItem}
                columns={columns}
              />
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

Tree.propTypes = {
  data: PropTypes.array.isRequired,
  childKey: PropTypes.string.isRequired,
  onClickListItem: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
};

TreeNode.propTypes = {
  node: PropTypes.object.isRequired,
  childKey: PropTypes.string.isRequired,
  onClickListItem: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
};

export default Tree;
