import React from 'react';

import { Button, Textarea, useColorModeValue } from '@chakra-ui/react';
import { debounce } from 'lodash';

import CustomSelectBox from '../../../components/SelectBox/Select';
import axiosInstance from '../../../utils/axios/axios';
import { deleteRenderer } from '../MetricsAndSLA/Metrics/utils';
import { getPaginatedData } from '../../../utils/helper';
import { renderStatus } from '../../TemplateCardListView/TemplateTableView/utils';
import TextWithTooltip from '../../../components/Text';
import { DARK, LIGHT } from '../../../utils/enums/colors';

export const FORM_MAP = {
  CHILD_TEMPLATE_ID: 'id',
  UPDATE_FIELDS: 'updateFields',
  EDGE_CONDITION: 'edgeConditions',
  PROPERTIES: 'properties',
  WAS_APPROVED: 'wasApproved',
};

export const associateBy = (data, key) =>
  data.reduce((prev, curr) => {
    prev[curr[key]] = curr;
    return prev;
  }, {});

export const enrichResponse = (data) => {
  const fieldIdMap = {};
  const fieldData = (data || []).map((t) => {
    const fields = (t?.fields || []).map((f) => {
      fieldIdMap[f?.id] = f?.label;
      return {
        label: f?.label,
        value: f?.id,
      };
    });

    return {
      fields,
      value: t?.id,
      label: t?.name,
      lifeCycle: t?.lifecycle_status,
    };
  });

  return {
    fieldsData: associateBy(fieldData, 'value'),
    fieldIdMap,
  };
};

export const getFieldData = async (templateIds) => {
  if (!Array.isArray(templateIds) || !templateIds.length) {
    return {};
  }

  const filterString = templateIds.reduce((prev, curr) => {
    const str = `id eq ${curr}`;
    if (!prev) {
      return str;
    }
    return `${prev} or ${str}`;
  }, '');

  const url = `/templates/list?$top=${templateIds.length}&$skip=0&$select=*&$filter=${filterString}`;

  try {
    const res = await axiosInstance.get(url);
    return enrichResponse(res?.data?.rows || []);
  } catch (err) {
    return {};
  }
};

export const getAllTemplates = async () => {
  const data = await getPaginatedData({
    endpoint: '/templates/list',
    select: '*',
  });
  return data;
};

export const columns = ({
  childData,
  onEdit,
  onDelete,
  approvedTemplateMap,
  templateFields = [],
  onNavigate,
}) => {
  const { fieldsData, fieldIdMap } = childData;

  const formatOptionsForSelect = (data) =>
    (data || []).map((f) => ({
      label: fieldIdMap[f],
      value: f,
    }));

  const edgeConditionEdit = debounce((rowId, e) => {
    onEdit({
      column: FORM_MAP.PROPERTIES,
      subColumn: FORM_MAP.EDGE_CONDITION,
      rowId,
      value: e.target.value,
    });
  }, 500);

  return [
    {
      col_name: 'Child Template Name',
      cellRenderer: ({ rowData }) => {
        const templateName =
          approvedTemplateMap[rowData?.[FORM_MAP.CHILD_TEMPLATE_ID]]?.label ||
          fieldsData[rowData?.[FORM_MAP.CHILD_TEMPLATE_ID]]?.label ||
          '';

        return (
          <Button
            variant="link"
            width="200px"
            textAlign="left"
            color={useColorModeValue(LIGHT, DARK)}
            onClick={() => {
              onNavigate(rowData?.[FORM_MAP.CHILD_TEMPLATE_ID], templateName);
            }}
          >
            <TextWithTooltip text={templateName} />
          </Button>
        );
      },
    },
    {
      col_name: 'Status',
      cellRenderer: ({ rowData }) => {
        const id = rowData?.[FORM_MAP.CHILD_TEMPLATE_ID];
        const lifeCycleStatus =
          approvedTemplateMap?.[id]?.lifeCycle || fieldsData[id]?.lifeCycle;

        return renderStatus(lifeCycleStatus);
      },
    },
    {
      col_name: FORM_MAP.UPDATE_FIELDS,
      cellRenderer: ({ rowData }) => {
        const associatedFields = (
          fieldsData[rowData?.[FORM_MAP.CHILD_TEMPLATE_ID]]?.fields || []
        ).filter((f) =>
          templateFields.some(
            (pt) => pt.label.toLowerCase() === f.label.toLowerCase()
          )
        );

        return (
          <CustomSelectBox
            isMulti
            value={formatOptionsForSelect(
              rowData?.[FORM_MAP.PROPERTIES]?.[FORM_MAP.UPDATE_FIELDS]
            )}
            options={associatedFields}
            onChange={(e) => {
              onEdit({
                column: FORM_MAP.PROPERTIES,
                subColumn: FORM_MAP.UPDATE_FIELDS,
                rowId: rowData?.[FORM_MAP.CHILD_TEMPLATE_ID],
                value: e.map((v) => v.value),
              });
            }}
            id="childTemplateCSelectBox"
          />
        );
      },
    },
    {
      col_name: FORM_MAP.EDGE_CONDITION,
      cellRenderer: ({ rowData }) => (
        <Textarea
          defaultValue={
            rowData?.[FORM_MAP.PROPERTIES]?.[FORM_MAP.EDGE_CONDITION] || ''
          }
          onChange={(e) => {
            edgeConditionEdit(rowData?.[FORM_MAP.CHILD_TEMPLATE_ID], e);
          }}
          w="15rem"
        />
      ),
    },
    {
      col_name: 'Action',
      cellRenderer: ({ rowData }) =>
        deleteRenderer({
          title: 'Delete this child template',
          description: 'Are you sure, you want to delete this?',
          onConfirm: () => onDelete(rowData?.[FORM_MAP.CHILD_TEMPLATE_ID]),
          isDisabled: rowData?.[FORM_MAP.WAS_APPROVED],
          buttonStyle: rowData?.[FORM_MAP.WAS_APPROVED]
            ? undefined
            : { color: 'red' },
        }),
    },
  ];
};
