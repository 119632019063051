import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import {
  Box,
  Flex,
  IconButton,
  Radio,
  RadioGroup,
  Spacer,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';

import { useDispatch, useSelector } from 'react-redux';

import CustomButton from '../../../components/Button/SimpleButton';

import {
  deleteQuickFilter,
  removeDefaultFilter,
  setDefaultFilter,
  setSelectedFilterId,
} from '../../../redux/CardReducer/quickFilterSlice';
import { COLORS } from '../../../utils/enums/colors';

import quickFilterStyles from './quickFilterStyles.module.scss';
import { QUICK_FILTERS_LABELS } from '../../../utils/enums/labels';
import DeleteModalV2 from '../../DynamicRenderer/DeleteModalV2';

const QuickFiltersList = ({ quickFilters, onEdit, onApplyFilter }) => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, jsxContent } = DeleteModalV2();

  const { defaultFilterId, selectedFilterId } = useSelector(
    (state) => state.quickFilter
  );

  const onQuickFilterChange = useCallback((value) => {
    dispatch(setSelectedFilterId(value));
  }, []);

  const onDeleteQuickFilter = useCallback((filterId) => {
    onOpen({
      title: QUICK_FILTERS_LABELS.DELETE_FILTER,
      descriptionText: QUICK_FILTERS_LABELS.DELETE_CONFIRM_MESSAGE,
      onDelete: async () => {
        await dispatch(deleteQuickFilter(filterId));
      },
    });
  }, []);

  const onRemoveDefault = useCallback(async () => {
    dispatch(removeDefaultFilter());
  }, []);

  const onMarkAsDefaultClick = useCallback(async () => {
    dispatch(setDefaultFilter(selectedFilterId));
  }, [selectedFilterId]);

  const onEditClicked = useCallback((event, filter) => {
    event.stopPropagation();
    onEdit(filter);
  }, []);

  return (
    <>
      {isOpen && jsxContent}
      {quickFilters.map((filter) => (
        <Flex key={filter?.id} alignItems="center">
          <Flex gap="1rem" fontSize="14px">
            <RadioGroup
              onChange={onQuickFilterChange}
              value={selectedFilterId}
              className={quickFilterStyles.quickfilter__radio__button}
            >
              <Radio
                value={filter?.id}
                isChecked={filter?.id === selectedFilterId}
              >
                {filter?.filterName}
              </Radio>
            </RadioGroup>
            {filter?.id === defaultFilterId && (
              <Text className={quickFilterStyles.default__text__container}>
                DEFAULT
              </Text>
            )}
          </Flex>

          <Spacer />

          <IconButton
            icon={<EditIcon />}
            variant="outline"
            border="none"
            size="sm"
            onClick={(e) => {
              onEditClicked(e, filter);
            }}
          />
          <IconButton
            icon={<DeleteIcon />}
            variant="outline"
            border="none"
            size="sm"
            onClick={() => onDeleteQuickFilter(filter.id)}
          />
        </Flex>
      ))}

      <Box
        background={useColorModeValue('white', 'var(--drawer-bg)')}
        className={quickFilterStyles.saved__filters__button__container}
      >
        <CustomButton
          variant="outline"
          buttonText={
            selectedFilterId === defaultFilterId
              ? QUICK_FILTERS_LABELS.REMOVE_DEFAULT
              : QUICK_FILTERS_LABELS.MARK_AS_DEFAULT
          }
          color={useColorModeValue(COLORS.DEFAULT, COLORS.WHITE)}
          onClick={
            selectedFilterId === defaultFilterId
              ? onRemoveDefault
              : onMarkAsDefaultClick
          }
        />
        <CustomButton
          variant="solid"
          colorScheme="customBlue"
          buttonText={QUICK_FILTERS_LABELS.APPLY_FILTER}
          onClick={onApplyFilter}
          isDisabled={!selectedFilterId}
        />
      </Box>
    </>
  );
};

QuickFiltersList.propTypes = {
  quickFilters: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onApplyFilter: PropTypes.func.isRequired,
};

QuickFiltersList.defaultProps = {};

export default QuickFiltersList;
