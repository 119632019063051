import React from 'react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import PropTypes from 'prop-types';

const ActionBackHeader = ({ label, onClick }) => (
  <div data-testid="DynamicRendererEditContainerDiv">
    <div
      className="flex items-center gap-[5px]"
      data-testid="DynamicRendererEditContainerIcon"
    >
      {/* <ArrowBackIcon
        className="cursor-pointer border border-[#E2E8F0] p-1 rounded-[5px]"
        h="32px"
        w="32px"
        onClick={onClick}
      /> */}
      <p
        className="font-semibold text-[16px]"
        data-testid="DynamicRendererEditEventActionBackHeader"
      >
        { label }
      </p>
    </div>
  </div>
);

ActionBackHeader.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ActionBackHeader;
