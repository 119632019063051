import React, { useCallback, useMemo } from 'react';

import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import { ReactSVG } from 'react-svg';

import PropTypes from 'prop-types';

import CustomSelectBox from '../../../components/SelectBox/Select';

import { createFilterDropdown } from '../utils';
import { CARD_LISTS_TOOLTIP_LABELS } from '../../../utils/enums/labels';

import SortIcon from '../../../assets/icons/sortingIcon.svg';
import SortDarkIcon from '../../../assets/icons/sortDarkIcon.svg';
import SortIcon1 from '../../../assets/icons/sortIcon1.svg';
import SortIcon2 from '../../../assets/icons/sortIcon2.svg';
import SortUpDark from '../../../assets/icons/SortUpDark.svg';
import SortDownDark from '../../../assets/icons/SortDownDark.svg';

import styles from '../cardLayout.module.scss';

const SortPopOver = ({
  appliedFilters,
  setAppliedFilters,
  iconButtonClass,
  selectDarkStyle,
  onRefresh,
  // standardHeight,
}) => {
  const { colorMode } = useColorMode();

  const iconSelectedClass = useMemo(
    () => useColorModeValue(styles.icon__selected, styles.icon__selected__dark),
    [colorMode]
  );

  const handleChangeForDropdown = useCallback((selectedOption) => {
    setAppliedFilters((prev) => ({
      ...prev,
      selectedSortBy: selectedOption,
      selectedSortType: '',
    }));
  }, []);

  const onSortClick = (sortType = 'asc') => {
    setAppliedFilters((prev) => {
      const selectedSortType =
        prev.selectedSortType === sortType ? '' : sortType;

      const tempFilters = {
        ...prev,
        selectedSortType,
      };

      onRefresh(tempFilters);

      return tempFilters;
    });
  };

  return (
    <Popover placement="bottom-start" data-testid="SortPopOver">
      <PopoverTrigger data-testid="SortPopOvertrigger">
        <Box data-testid="SortPopOverButtonBox">
          <Tooltip
            hasArrow
            placement="top"
            label={
              appliedFilters.selectedSortType && appliedFilters.selectedSortBy
                ? `${appliedFilters.selectedSortBy.label} - Sort by ${
                  appliedFilters.selectedSortType === 'asc'
                    ? CARD_LISTS_TOOLTIP_LABELS.ASCENDING
                    : CARD_LISTS_TOOLTIP_LABELS.DESENDING
                }`
                : CARD_LISTS_TOOLTIP_LABELS.SORT
            }
            data-testid="SortPopOverTooltip"
          >
            <Box
              className={`${useColorModeValue(
                styles.sortButtonLight,
                styles.sortButtonDark
              )} ${appliedFilters.selectedSortType ? iconSelectedClass : ''}`}
              data-testid="SortPopOverIconButon"
            >
              <ReactSVG src={useColorModeValue(SortIcon, SortDarkIcon)} />
            </Box>
          </Tooltip>
        </Box>
      </PopoverTrigger>
      <PopoverContent minW="408px" data-testid="SortPopOverContent">
        <PopoverBody
          pt="40px"
          pb="40px"
          pl="20px"
          data-testid="SortPopOverBody"
        >
          <Box data-testid="SortPopOverSelectBox">
            <Flex gap="10px" data-testid="SortPopOverSelectFlex">
              <CustomSelectBox
                value={appliedFilters.selectedSortBy}
                className={styles.selectBoxStyle}
                options={createFilterDropdown}
                onChange={handleChangeForDropdown}
                styles={selectDarkStyle}
                size="md"
                id="SortPopOverSelectBox"
              />
              <Button
                onClick={() => onSortClick()}
                className={`
                ${iconButtonClass}
                ${
                  appliedFilters.selectedSortType === 'asc'
                    ? iconSelectedClass
                    : ''
                }
              `}
                data-testid="SortPopOverAscButton"
              >
                <ReactSVG src={useColorModeValue(SortIcon1, SortUpDark)} />
              </Button>
              <Button
                onClick={() => onSortClick('desc')}
                className={`
                ${iconButtonClass}
                ${
                  appliedFilters.selectedSortType === 'desc'
                    ? iconSelectedClass
                    : ''
                }
              `}
                data-testid="SortPopOverDescButton"
              >
                <ReactSVG src={useColorModeValue(SortIcon2, SortDownDark)} />
              </Button>
            </Flex>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

SortPopOver.propTypes = {
  appliedFilters: PropTypes.object.isRequired,
  setAppliedFilters: PropTypes.func.isRequired,
  selectDarkStyle: PropTypes.object.isRequired,
  onRefresh: PropTypes.func.isRequired,
  iconButtonClass: PropTypes.string,
};

SortPopOver.defaultProps = {
  iconButtonClass: '',
};

export default SortPopOver;
