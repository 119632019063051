/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import { Box, Spinner, useColorModeValue } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import Circle from '../../assets/icons/Circle.svg';
import TriangleRightIcon from '../../assets/icons/TriangleRightIcon.svg';
import CustomToolTip from '../../components/Tooltip/Tooltip';
import styles from './treeNodeStyles.module.scss';
import {
  BLACK,
  COLORS,
  DARK,
  LIGHT,
  SELECT_DARK,
  SELECT_LIGHT,
  WHITE,
} from '../../utils/enums/colors';
import { getChildList } from './utils';

export const TreeNodeList = ({
  node,
  childKey,
  onClickListItem,
  loadChildItems,
  columns,
}) => {
  const [childVisible, setChildVisiblity] = useState(false);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [nodeItem, setNodeItem] = useState(node);
  const childList = getChildList(node, childKey);

  const handleChildLoad = async () => {
    if (childList?.length) {
      setChildVisiblity(true);
      setLoading(true);
      await loadChildItems(nodeItem, childList, setNodeItem);
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        className={`${styles['tree-node']} list-none`}
        key={nodeItem?.id}
        py="0.5"
        height="auto"
        as="span"
        fontSize="15px"
        pl="3"
        borderRadius="6px"
        _hover={{
          bg: COLORS.BG_LIGHT,
          color: useColorModeValue('black', 'white'),
        }}
        bg={
          nodeItem?.id === id
            ? useColorModeValue(SELECT_LIGHT, SELECT_DARK)
            : ''
        }
        color={
          nodeItem?.id === id
            ? useColorModeValue(LIGHT, DARK)
            : useColorModeValue(BLACK, WHITE)
        }
        data-testid="TreeNodeListContainerBox"
      >
        <div
          className={styles['tree-head']}
          data-testid="TreeNodeListContainerDiv"
        >
          {childList?.length ? (
            <div
              className={`${styles['tree-toggler']} ${
                childVisible ? styles.active : ''
              }`}
              data-testid="TreeNodeListTogglerDiv"
            >
              <ReactSVG
                src={TriangleRightIcon}
                className={styles.triangleDown}
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleChildLoad();
                }}
                data-testid="TreeNodeListReactSVG"
              />
            </div>
          ) : (
            <ReactSVG
              src={Circle}
              className={styles.circle}
              data-testid="TreeNodeListCircleReactSvg"
            />
          )}
          <div
            className={`${styles['tree-head']} ${styles.treeHead}`}
            data-testid="TreeNodeListTreeHeadDiv"
          >
            <i
              className={`mr-1 ${nodeItem.icon}`}
              style={{ marginRight: '0.5rem' }}
            />
            <div
              className={`${styles['tree-node-label']} ellipsis`}
              onClick={(e) => {
                e.preventDefault();
                onClickListItem(nodeItem);
              }}
              data-testid="TreeNodeListNodeLabelDiv"
            >
              <CustomToolTip id="tooltip-test" toolTipText={nodeItem[columns]}>
                {nodeItem[columns]}
              </CustomToolTip>
            </div>
          </div>
        </div>
      </Box>

      {childList?.length && childVisible && (
        <div
          className={styles['tree-content']}
          data-testid="TreeNodeLisTreeContentDiv"
        >
          {loading ? (
            <Spinner size="sm" />
          ) : (
            <ul
              className={`${styles['tree-node-container']} ${styles.treeNodeContainer} ${styles.nested}`}
            >
              {nodeItem?.children?.map((child) => (
                <TreeNodeList
                  node={child}
                  key={child.id}
                  childKey={childKey}
                  onClickListItem={onClickListItem}
                  loadChildItems={loadChildItems}
                  columns={columns}
                />
              ))}
            </ul>
          )}
        </div>
      )}
    </>
  );
};

TreeNodeList.propTypes = {
  node: PropTypes.object.isRequired,
  childKey: PropTypes.string.isRequired,
  onClickListItem: PropTypes.func.isRequired,
  loadChildItems: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
};

export default TreeNodeList;
