const LAYOUT_FIELD_COLORS = {
  TICKET_SCOPE: '',
  WORKFLOW_SCOPE: '#E0115F', // Raspberry
  RELATION_SCOPE: '#32F41E', // Green
  SF_METRIC_TYPE: '#ff00ff', // Fuchsia
  SF_SLAS_TYPE: '#9000ff', // Electric violet
  SF_TYPE: '#2563EB', // Royal Blue
};

export default LAYOUT_FIELD_COLORS;
