import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import { LIGHT } from '../../utils/enums/colors';

const ExpressionsDivClearable = ({
  name,
  value,
  parentName,
  targetStatekey,
  lhsKey,
  opKey,
  rhsKey,
}) => {
  const { viewState } = useSelector((state) => state);
  const dispatch = useDispatch();
  const sourceKey = targetStatekey ?? parentName;
  const state = viewState[sourceKey] ? viewState[sourceKey][value] : [];

  const removeItem = (item) => {
    const updated = state?.filter((ins) => ins.id !== item?.id);
    dispatch(
      updateViewState({
        stateKey: sourceKey,
        value: {
          ...viewState[sourceKey],
          [value]: updated,
        },
      })
    );
  };

  return (
    <div data-testid="ExpressionsDivClearableDiv">
      <Box
        className="modalAddedInputContainer"
        marginTop="22px"
        display="flex"
        gap="10px"
        flexWrap="wrap"
        maxW="871px"
        data-testid="ExpressionsDivClearableBox"
      >
        {state?.map((option) => (
          <Flex
            className="modalAddedInputBox"
            alignItems="center"
            justifyContent="flex-start"
            gap="11px"
            height="32px !important"
            border={`1px solid ${LIGHT}`}
            flex={0}
            paddingLeft="5px"
            p={'12px'}
            borderRadius={'4px'}
            data-testid="ExpressionsDivClearableFlex"
          >
            <Box className="modalAddedInputBoxValue">{option[lhsKey]}</Box>
            <Box bgColor="#E5E5FF" borderRadius={'4px'} pl={'6px'} pr={'6px'}>
              {option[opKey]}
            </Box>
            <Box className="modalAddedInputBoxValue">{option[rhsKey]}</Box>
            <IconButton
              height="28px"
              minWidth={'30px'}
              icon={<SmallCloseIcon />}
              background="ffffff"
              onClick={() => {
                removeItem(option);
              }}
            />
          </Flex>
        ))}
      </Box>
    </div>
  );
};

ExpressionsDivClearable.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  parentName: PropTypes.string.isRequired,
};

export default ExpressionsDivClearable;
