import { useColorMode } from '@chakra-ui/react';
import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import styles from './navigation.module.scss';
import MoonIcon from '../../assets/icons/moonIcon.svg';
import SunIcon from '../../assets/icons/sunIcon.svg';

const CustomSwitch = () => {
  const { toggleColorMode, colorMode } = useColorMode();
  let switchIcon = colorMode === 'light' ? SunIcon : MoonIcon;
  const [mode, setMode] = useState(colorMode);

  return (
    <div
      className={styles.switchContainer}
      onClick={() => {
        setMode((prev) => {
          switchIcon = prev === 'light' ? SunIcon : MoonIcon;
          setTimeout(() => {
            toggleColorMode();
          }, 0);
          return prev === 'light' ? 'dark' : 'light';
        });
      }}
      data-testid="CustomSwitchContainerDiv"
    >
      <div
        className={`${styles.sliderContainer} ${styles[mode]}`}
        data-testid="CustomSwitchSliderContainerDiv"
      >
        <div
          className={`${styles.slider} ${styles[mode]}`}
          data-testid="CustomSwitchSliderDiv"
        >
          <ReactSVG
            src={switchIcon}
            data-testid="CustomSwitchReactSVG"
            className={styles.switchIcon}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomSwitch;
