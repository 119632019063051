import { isArray } from 'lodash';
import axios from 'axios';
import { getTenantData, getUserInfoUsingQueue } from '../../helpers/tenantUrl';
import { DROPDOWN_TYPES, SPECIAL_FIELD_TYPE } from '../../pages/TenantFilters/filters.constants';
import axiosInstance from '../../utils/axios/axios';

export const DUMMY_FIELD_LIST = [
  {
    id: '84a05156-eadc-4a41-85dc-6d0f2b4e4d05',
    slug: 'field_text_name-0b8df233',
    type: 'TEXT',
    label: 'Name',
    scope: 'TICKET',
    display: true,
    tenant_id: '9af85e02-8e38-4bea-8fb9-5518c57508bf',
    permissions: {
      editors: {
        org: [
          'testdrop'
        ]
      },
      viewers: {
        org: [
          'testdrop'
        ]
      },
      expression: [],
      restrictRead: {}
    },
    type_based_attributes: {
      dependency: {
        filterField: 'parentName'
      },
      is_reportable: true,
      is_searchable: true
    }
  },
  {
    id: '9da6c3ea-888c-4d30-a846-5fb48fae1d25',
    slug: 'field_dropdown_place-35a146c9',
    type: 'DROPDOWN',
    label: 'Place',
    scope: 'TICKET',
    display: true,
    tenant_id: '9af85e02-8e38-4bea-8fb9-5518c57508bf',
    permissions: {
      editors: {
        org: [
          'testdrop'
        ]
      },
      viewers: {
        org: [
          'testdrop'
        ]
      },
      expression: [],
      restrictRead: {}
    },
    type_based_attributes: {
      collection: {
        isMulti: false,
        actor_type: [],
        staticValues: [
          {
            label: 'Bangalore',
            value: 'Bangalore'
          },
          {
            label: 'Hyderabad',
            value: 'Hyderabad'
          },
          {
            label: 'Chennai',
            value: 'Chennai'
          }
        ],
        collection_type: 'STATIC'
      },
      dependency: {
        filterField: 'parentName'
      },
      is_reportable: true,
      is_searchable: true
    }
  },
  {
    id: '6d458d5d-0dd2-40c8-8eb2-10f24332f49f',
    slug: 'field_dropdown_status-4a7bb14e',
    type: 'DROPDOWN',
    label: 'Status',
    scope: 'TICKET',
    display: true,
    tenant_id: '9af85e02-8e38-4bea-8fb9-5518c57508bf',
    permissions: {
      editors: {
        org: [
          'testdrop'
        ]
      },
      viewers: {
        org: [
          'testdrop'
        ]
      },
      expression: [],
      restrictRead: {}
    },
    type_based_attributes: {
      collection: {
        list: [],
        actor_type: [],
        isWhiteList: false,
        specialField: SPECIAL_FIELD_TYPE.WORKFLOW,
        collection_type: DROPDOWN_TYPES.SPECIAL_FIELD
      },
      dependency: {
        filterField: 'parentName'
      },
      is_reportable: true,
      is_searchable: true
    }
  },
  {
    id: '78d222d9-8476-450c-9221-b9983639cb3e',
    slug: 'field_dropdown_queues-94d69ae3',
    type: 'DROPDOWN',
    label: 'Queues',
    scope: 'TICKET',
    display: true,
    tenant_id: '9af85e02-8e38-4bea-8fb9-5518c57508bf',
    permissions: {
      editors: {
        org: [
          'testdrop'
        ]
      },
      viewers: {
        org: [
          'testdrop'
        ]
      },
      expression: [],
      restrictRead: {}
    },
    type_based_attributes: {
      collection: {
        list: [
          {
            team: 'james',
            queueId: 39216,
            queue_name: 'Hardware Level 1'
          }
        ],
        actor_type: [],
        isWhiteList: true,
        specialField: SPECIAL_FIELD_TYPE.QUEUE,
        collection_type: DROPDOWN_TYPES.SPECIAL_FIELD
      },
      dependency: {
        filterField: 'parentName'
      }
    }
  },
  {
    id: 'e3df5fc0-3e4f-4f7e-bf94-d0b5dcafd620',
    slug: 'field_dropdown_primary-actor-39e00fcc',
    type: 'DROPDOWN',
    label: 'Primary Actor',
    scope: 'TICKET',
    display: true,
    tenant_id: '9af85e02-8e38-4bea-8fb9-5518c57508bf',
    permissions: {
      editors: {
        org: [
          'testdrop'
        ]
      },
      viewers: {
        org: [
          'testdrop'
        ]
      },
      expression: [],
      restrictRead: {}
    },
    type_based_attributes: {
      collection: {
        list: [],
        actor_type: 'PRIMARY',
        isWhiteList: false,
        specialField: SPECIAL_FIELD_TYPE.ACTORS,
        collection_type: DROPDOWN_TYPES.SPECIAL_FIELD
      },
      dependency: {
        filterField: 'parentName'
      }
    }
  },
  {
    id: '00f8ded5-606b-4c18-b080-ba55d10710f1',
    slug: 'field_dropdown_requestor-actor-45efa9d1',
    type: 'DROPDOWN',
    label: 'Requestor Actor',
    scope: 'TICKET',
    display: true,
    tenant_id: '9af85e02-8e38-4bea-8fb9-5518c57508bf',
    permissions: {
      editors: {
        org: [
          'testdrop'
        ]
      },
      viewers: {
        org: [
          'testdrop'
        ]
      },
      expression: [],
      restrictRead: {}
    },
    type_based_attributes: {
      collection: {
        list: [],
        actor_type: 'REQUESTOR',
        isWhiteList: false,
        specialField: SPECIAL_FIELD_TYPE.ACTORS,
        collection_type: DROPDOWN_TYPES.SPECIAL_FIELD
      },
      dependency: {
        filterField: 'parentName'
      }
    }
  },
  {
    id: '83ad0f7d-7da8-426a-a24d-e055a663d9e6',
    slug: 'field_rich_text_description-7b76e62b',
    type: 'RICH_TEXT',
    label: 'Description',
    scope: 'TICKET',
    display: true,
    tenant_id: '9af85e02-8e38-4bea-8fb9-5518c57508bf',
    permissions: {
      editors: {
        org: [
          'testdrop'
        ]
      },
      viewers: {
        org: [
          'testdrop'
        ]
      },
      expression: [],
      restrictRead: {}
    },
    type_based_attributes: {
      dependency: {
        filterField: 'parentName'
      },
      is_reportable: true,
      is_searchable: true
    }
  },
  {
    id: '607c104d-ad22-41b2-877f-8879634a6f78',
    slug: 'field_media_attachments-10a3679f',
    type: 'MEDIA',
    label: 'Attachments',
    scope: 'TICKET',
    display: true,
    tenant_id: '9af85e02-8e38-4bea-8fb9-5518c57508bf',
    permissions: {
      editors: {
        org: [
          'testdrop'
        ]
      },
      viewers: {
        org: [
          'testdrop'
        ]
      },
      expression: [],
      restrictRead: {}
    },
    type_based_attributes: {
      dependency: {
        filterField: 'parentName'
      },
      media_size: 0,
      media_type: [
        'images',
        'videos',
        'documents',
        'compressed_files'
      ],
      is_reportable: true,
      is_searchable: true,
      maximum_number_attachment: 0
    }
  },
  {
    id: 'ce7c0a57-9131-4297-8090-8182e0ac96b5',
    slug: 'field_date_start-date-779c0205',
    type: 'DATE',
    label: 'Start Date',
    scope: 'TICKET',
    display: true,
    tenant_id: '9af85e02-8e38-4bea-8fb9-5518c57508bf',
    permissions: {
      editors: {
        org: [
          'testdrop'
        ]
      },
      viewers: {
        org: [
          'testdrop'
        ]
      },
      expression: [],
      restrictRead: {}
    },
    type_based_attributes: {
      dependency: {
        filterField: 'parentName'
      },
      is_reportable: true,
      is_searchable: true
    }
  },
  {
    id: '2760fd3b-8062-4b42-afaa-69da2383e4e5',
    slug: 'field_number_experience-1c2e4ec6',
    type: 'NUMBER',
    label: 'Experience',
    scope: 'TICKET',
    display: true,
    tenant_id: '9af85e02-8e38-4bea-8fb9-5518c57508bf',
    permissions: {
      editors: {
        org: [
          'testdrop'
        ]
      },
      viewers: {
        org: [
          'testdrop'
        ]
      },
      expression: [],
      restrictRead: {}
    },
    type_based_attributes: {
      dependency: {
        filterField: 'parentName'
      },
      is_reportable: true,
      is_searchable: true
    }
  },
  {
    id: 'a0c96738-8ff8-4df9-9a58-4bbbd1132673',
    slug: 'field_text_area_address-54faf49e',
    type: 'TEXT_AREA',
    label: 'Address',
    scope: 'TICKET',
    display: true,
    tenant_id: '9af85e02-8e38-4bea-8fb9-5518c57508bf',
    permissions: {
      editors: {
        org: [
          'testdrop'
        ]
      },
      viewers: {
        org: [
          'testdrop'
        ]
      },
      expression: [],
      restrictRead: {}
    },
    type_based_attributes: {
      dependency: {
        filterField: 'parentName'
      },
      is_reportable: true,
      is_searchable: true
    }
  },
  {
    id: 'b5c15807-c912-42c7-9ca8-0eb8e456a812',
    slug: 'field_tag_skills-31ee68af',
    type: 'TAGS',
    label: 'Skills',
    scope: 'TICKET',
    display: true,
    tenant_id: '9af85e02-8e38-4bea-8fb9-5518c57508bf',
    permissions: {
      editors: {
        org: [
          'testdrop'
        ]
      },
      viewers: {
        org: [
          'testdrop'
        ]
      },
      expression: [],
      restrictRead: {}
    },
    type_based_attributes: {
      collection: {
        isMulti: false,
        actor_type: [],
        staticValues: [
          {
            label: 'FE',
            value: 'FE'
          },
          {
            label: 'BE',
            value: 'BE'
          }
        ],
        collection_type: 'STATIC'
      },
      dependency: {
        filterField: 'parentName'
      },
      is_reportable: true,
      is_searchable: true
    }
  },
  {
    id: 'cc2a5038-e6e4-455d-9c04-7d8b051998ee',
    slug: 'field_boolean_agreement-8da661fc',
    type: 'BOOLEAN',
    label: 'Agreement',
    scope: 'TICKET',
    display: true,
    tenant_id: '9af85e02-8e38-4bea-8fb9-5518c57508bf',
    permissions: {
      editors: {
        org: [
          'testdrop'
        ]
      },
      viewers: {
        org: [
          'testdrop'
        ]
      },
      expression: [],
      restrictRead: {}
    },
    type_based_attributes: {
      dependency: {
        filterField: 'parentName'
      },
      is_reportable: true,
      is_searchable: true
    }
  },
  {
    id: 'c1536935-0a19-4288-9c78-327800ab525e',
    slug: 'field_mail_email-6143fe13',
    type: 'EMAIL',
    label: 'Email',
    scope: 'TICKET',
    display: true,
    tenant_id: '9af85e02-8e38-4bea-8fb9-5518c57508bf',
    permissions: {
      editors: {
        org: [
          'testdrop'
        ]
      },
      viewers: {
        org: [
          'testdrop'
        ]
      },
      expression: [],
      restrictRead: {}
    },
    type_based_attributes: {
      dependency: {
        filterField: 'parentName'
      },
      is_reportable: true,
      is_searchable: true
    }
  },
  {
    id: 'c1536935-0a19-4288-9c78-327800ab5267f',
    slug: 'field_mail_email-6143fe13',
    type: 'BOOLEAN',
    label: 'Bool',
    scope: 'TICKET',
    display: true,
    tenant_id: '9af85e02-8e38-4bea-8fb9-5518c57508bf',
    permissions: {
      editors: {
        org: [
          'testdrop'
        ]
      },
      viewers: {
        org: [
          'testdrop'
        ]
      },
      expression: [],
      restrictRead: {}
    },
    type_based_attributes: {
      dependency: {
        filterField: 'parentName'
      },
      is_reportable: true,
      is_searchable: true
    }
  }
];
export const DEFAULT_VALUE_SELECT = [
  {
    field: {
      value: '78d222d9-8476-450c-9221-b9983639cb3e',
      label: 'Queues'
    },
    value: 'value'
  },
  {
    field: {
      value: '6d458d5d-0dd2-40c8-8eb2-10f24332f49f',
      label: 'Status'
    },
    value: 'lave'
  },
  {
    field: {
      value: 'c1536935-0a19-4288-9c78-327800ab525e',
      label: 'Email'
    },
    value: 'value@gmail.com'
  },
  {
    field: {
      value: '00f8ded5-606b-4c18-b080-ba55d10710f1',
      label: 'Requestor Actor'
    },
    value: 'value'
  },
  {
    field: {
      value: 'b5c15807-c912-42c7-9ca8-0eb8e456a812',
      label: 'Skills'
    },
    value: 'BE'
  },
  {
    field: {
      value: 'ce7c0a57-9131-4297-8090-8182e0ac96b5',
      label: 'Start Date'
    },
    value: '2024-02-28T17:03:37.000Z'
  }
];

export const DEFAULT_WORKFLOW_STATUS = [
  {
    id: 'start_1',
    data: {
      label: 'START'
    },
    type: 'selectorCreateNode',
    dragging: false,
    position: {
      x: 150,
      y: 100
    },
    selected: false
  },
  {
    id: 'start_2',
    data: {
      name: 'To-do Category',
      label: 'TO DO',
      categoryId: 'categores_1'
    },
    type: 'toDoNode',
    dragging: false,
    position: {
      x: 250,
      y: 300
    },
    selected: false,
    positionAbsolute: {
      x: 250,
      y: 300
    }
  },
  {
    id: 'start_3',
    data: {
      name: 'In-progress Category',
      label: 'IN PROGRESS',
      categoryId: 'categores_3'
    },
    type: 'toProgressNode',
    dragging: false,
    position: {
      x: 500,
      y: 540
    },
    selected: false,
    positionAbsolute: {
      x: 500,
      y: 540
    }
  },
  {
    id: 'start_4',
    data: {
      name: 'Done Category',
      label: 'DONE',
      categoryId: 'categores_4'
    },
    type: 'toDoneNode',
    dragging: false,
    position: {
      x: 1060,
      y: 680
    },
    selected: false,
    positionAbsolute: {
      x: 1060,
      y: 680
    }
  },
  {
    id: '1967c8f5-d85b-4813-9b6d-3971f4f53deb',
    data: {
      name: 'In-progress Category',
      label: 'PROCESS',
      categoryId: 'categores_3'
    },
    type: 'toProgressNode',
    dragging: false,
    position: {
      x: 1230,
      y: 200
    },
    selected: false
  },
  {
    id: '318d728a-4e49-48e9-b58d-2022e48e63bd',
    data: {
      name: 'To-do Category',
      label: 'TEST J',
      categoryId: '1706521424671'
    },
    type: 'toDoNode',
    width: 150,
    height: 42,
    dragging: false,
    position: {
      x: 1400,
      y: 440
    },
    selected: false,
    positionAbsolute: {
      x: 1400,
      y: 440
    }
  }
];

export const getTeams = async () => {
  const tenantInformation = getTenantData();
  const tenantId = tenantInformation?.id;
  const params = {
    tenant_id: tenantId,
  };
  const response = await axiosInstance.get('teams', { params });
  if (isArray(response?.data)) {
    const [teams] = response.data;
    if (isArray(teams)) {
      const teamObject = teams[0];
      return teamObject?.team || [];
    }
    return [];
  }

  return [];
};

export const getQueue = async (team) => {
  const tenantInformation = getTenantData();
  const tenantId = tenantInformation?.id;
  const params = {
    tenant_id: tenantId,
    team_name: team,
  };
  const response = await axiosInstance.get('queueTypes/teams', { params });
  if (!isArray(response?.data)) {
    return {
      team,
      queues: [],
    };
  }

  return {
    team,
    queues: response?.data[0].map((queue) => ({
      id: queue.id,
      name: queue?.properties?.queueLabel || queue?.queue_name
    })) || [],
  };
};

export const getTeamQueueMap = async () => {
  const teams = await getTeams();
  const promises = teams.map(getQueue);

  return Promise.all(promises);
};

export const getUserList = async (queueId) => {
  const url = getUserInfoUsingQueue(queueId);
  const token = localStorage.getItem('kc_token');

  try {
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = res?.data || {};
    return data;
  } catch (err) {
    console.warn(err, 'user info not found');
    return {};
  }
};

export const getAllUniqueUsers = (data) => {
  const userSet = new Set();

  data.forEach((item) => {
    if (item.users && item.users.length > 0) {
      item.users.forEach((subItem) => {
        if (subItem.users && subItem.users.length > 0) {
          subItem.users.forEach((user) => {
            userSet.add(user.email); // Add email to the set for uniqueness
          });
        }
      });
    }
  });

  return Array.from(userSet).map((email) => ({ value: email, label: email }));
};

export const getSelectedHierarchy = (hierarchiesArray, hierarchyId) => hierarchiesArray.find(
  (item) => item.id === hierarchyId
) || { hierarchy: [] };

export const getSelectedFieldHierarchyMap = (
  fieldHierarchyMap, fieldId
) => fieldHierarchyMap.find((mapping) => {
  const index = (mapping.map || []).indexOf(fieldId);
  if (index >= 0) {
    return true;
  }
  return false;
});
