import { createData, deleteData, getData, updateData } from '../../utils/crud';

export const getDataForIndeterminateCheckbox = (hierarchy) => {
  const data = [];

  const generatePayload = (h, d) => {
    if (!Array.isArray(h) || h.length === 0) {
      return;
    }

    for (let i = 0; i < h.length; i++) {
      const title = h?.[i]?.title;
      const key = h?.[i]?.key;
      let index;
      if (title && key) {
        index = d.push({
          id: key,
          name: title,
          items: [],
        });
      }
      generatePayload(h?.[i]?.children, d?.[index - 1]?.items);
    }
  };

  generatePayload(hierarchy, data);
  return data;
};

export const getIntent = async () => {
  const res = await getData({
    endpoint: '/intentGroups/list?$select=*',
  });
  const intentGroups = res?.[0]?.intent_groups;
  const id = res?.[0]?.id;

  if (!Array.isArray(intentGroups) || !id) {
    return null;
  }

  return {
    intent_groups: intentGroups,
    id,
  };
};

export const createIntent = async ({ payload }) => {
  const res = await createData({
    endpoint: 'intentGroups/create',
    payload,
    successMessage: 'Intent Group created successfully',
    errorMessage: 'Failed to create the Intent Group',
  });

  return res ? { intent_groups: res?.intent_groups, id: res?.id } : null;
};

export const editIntentGroup = async ({ id, payload }) => {
  const res = await updateData({
    payload,
    endpoint: `intentGroups/update?$filter=id eq ${id}`,
    successMessage: 'Intent Group updated successfully',
    errorMessage: 'Failed to update the intent group',
  });

  if (!res) {
    return null;
  }

  const intentGroups = res?.intent_groups;

  return {
    intent_groups: intentGroups,
    id,
  };
};

export const deleteIntentGroup = async (id) => {
  const res = await deleteData({
    endpoint: `intentGroups/delete?$filter=id eq ${id}`,
    successMessage: 'Intent Group deleted successfully',
    errorMessage: 'Failed to delete the intent group',
  });

  return res;
};
