import React from 'react';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Box, Flex } from '@chakra-ui/layout';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { isEmpty } from 'lodash';
import CustomButton from '../../../components/Button/SimpleButton';
import CustomModal from '../../../components/Modal/Modal';
import { COLORS } from '../../../utils/enums/colors';
import DeleteIcon from '../../../assets/icons/DeleteIcon.svg';
import { LABELS } from '../../../utils/enums/labels';
import styles from '../styles/templatebuilder.module.scss';

const TemplateDeleteModal = ({
  isDeleteModal,
  setIsDeleteModal,
  templateName,
  onConfirmation,
  titelText,
}) => {
  const colorType = useColorModeValue('light', 'dark');
  return (
    <CustomModal
      className={styles.footer}
      id="delete-modal"
      modalContent={
        <Flex
          flex="1"
          align="center"
          p="2"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          justifyContent="center"
          height="100%"
        >
          <Box
            // bg={useColorModeValue('white', 'gray.900')}
            align="center"
            justifyContent="center"
            data-testid="TemplateDeletteModalContaineBox"
          >
            <Box width="50" height="50" mt="5" data-testid="TemplateDeletteModalReactSvgBox">
              <ReactSVG
                src={DeleteIcon}
                className={`${styles.templateDelete} p-2 ${
                  colorType === 'dark' ? styles.colorWhite : ''
                }`}
              />
            </Box>
            <Box
              className={styles.createHeadText}
              color={useColorModeValue('', 'white')}
              data-testid="TemplateDeletteModalHeaderTextBox"
            >
              {isEmpty(titelText) ? LABELS.TITLE.DELETE_TEMPLATE_WARNING : titelText}
            </Box>
            <div className={styles.paraTex} data-testid="TemplateDeletteModalTempNameDiv">{templateName}</div>
            <div className="mt-10" data-testid="TemplateDeletteModalCBtnDiv">
              <CustomButton
                id="TemplateDeteleModalcancel-delete-modal"
                onClick={() => setIsDeleteModal(false)}
                buttonText={LABELS.ACTIONS.CANCEL}
                variant="outline"
                className="mr-4"
                width="40%"
                size="md"
                color={useColorModeValue(COLORS.BLACK, COLORS.DARK)}
                _hover={{
                  background: 'white',
                  color: 'black',
                  border: '1px solid black',
                }}
              />
              <CustomButton
                id="TemplateDeleteModalconfirm-delete"
                onClick={() => onConfirmation()}
                buttonText={LABELS.ACTIONS.DELETE}
                variant="solid"
                size="md"
                width="40%"
                bg={useColorModeValue(COLORS.RED, COLORS.DARK)}
                _hover={{
                  background: '#DC3636',
                  color: 'white',
                }}
              />
            </div>
          </Box>
        </Flex>
      }
      open={isDeleteModal}
      setOpen={setIsDeleteModal}
      size="sm"
    />
  );
};

TemplateDeleteModal.propTypes = {
  templateName: PropTypes.string.isRequired,
  isDeleteModal: PropTypes.bool.isRequired,
  setIsDeleteModal: PropTypes.func.isRequired,
  onConfirmation: PropTypes.func.isRequired,
  titelText: PropTypes.string,
};
TemplateDeleteModal.defaultProps = {
  titelText: '',
};
export default TemplateDeleteModal;
