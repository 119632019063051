/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useMemo, useState } from 'react';
import { Flex, useColorModeValue, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import classnames from 'classnames';
import NavItem from './NavItem';
import { ROUTES_DEFINED_MAP, routeList } from '../../utils/enums/routes';
import DrawerIcon from '../../assets/icons/DrawerIcon.svg';
import DrawerIconDark from '../../assets/icons/DrawerIconDark.svg';
import styles from './nav-layout.module.scss';
import { getDomain } from '../../helpers/tenantUrl';

const SidebarContentHelper = ({ children, path = '' }) => {
  let selectedItem;

  try {
    selectedItem = `/${path.split('/')[1].split('?')[0]}`;
  } catch (err) {
    selectedItem = path;
  }

  const getPath = () => {
    if (path === '#') {
      return path;
    }

    return `${getDomain()}${path}`;
  };

  return (
    <div style={{ width: '220px' }}>
      {ROUTES_DEFINED_MAP[selectedItem] ? (
        <Link to={path}>{children}</Link>
      ) : (
        <a href={getPath()} className={styles.getPath}>
          {children}
        </a>
      )}
    </div>
  );
};

function getActiveItems(data) {
	const activeItems = {};
	const { pathname } = window.location;

	function searchActiveItems(items, parentName, topLevelParent) {
		items.forEach((item) => {
			if (pathname.includes(item.path)) {
				activeItems[parentName] = true;
				activeItems[item.name] = true;
				if (topLevelParent) {
					activeItems[topLevelParent] = true;
				}
			} else if (item.children) {
				// Recursively search through children with current item's name as parent
				searchActiveItems(item.children, item.name, topLevelParent || parentName);
			}
		});
	}

	// Call the recursive function with the top-level data
	searchActiveItems(data);
	return activeItems;
}

const SidebarContents = ({
  selectedMenu,
  openMenu,
  setOpenMenu,
  globalBg,
  keycloak,
  uiFeatureData,
}) => {
  const [displayChildren, setDisplayChildren] = useState({});
  const [modifiedRoutes, setModifiedRoutes] = useState([]);

  useEffect(() => {
		if (!openMenu) {
			setDisplayChildren({});
		} else {
			setDisplayChildren(getActiveItems(modifiedRoutes));
		}
	}, [openMenu]);

  const checkAllowedRoutes = (route, productSettings) =>
    route.children &&
    route.children.map((r) => {
      if (productSettings[r.name] && productSettings[r.name]?.status === 1) {
        const updatedRoute = { ...r, isNav: true };
        if (r.children) {
          updatedRoute.children = checkAllowedRoutes(
            r,
            productSettings[r.name]
          );
        }
        return updatedRoute;
      }
      if (productSettings[r.name] && productSettings[r.name]?.status === 0) {
        return { ...r, isNav: false };
      }
      return r;
    });

  useEffect(() => {
    const updatedRoutes = routeList.map((route) => {
      if (
        uiFeatureData[route.name] &&
        uiFeatureData[route.name]?.status === 1
      ) {
        const updatedRoute = { ...route, isNav: true };
        if (route.children) {
          updatedRoute.children = checkAllowedRoutes(
            route,
            uiFeatureData[route.name]
          );
        }
        if (
          !uiFeatureData?.tickets?.showRequestsManagement &&
          route.path === '/approvals'
        ) {
          updatedRoute.isNav = false;
        }
        return updatedRoute;
      }
      if (
        uiFeatureData[route.name] &&
        uiFeatureData[route.name]?.status === 0
      ) {
        return { ...route, isNav: false };
      }
      return route;
    });

    setModifiedRoutes([...updatedRoutes]);
    if(openMenu){
		  setDisplayChildren(getActiveItems(updatedRoutes));
    }
  }, [uiFeatureData]);

  const filterNav = (items) => {
    let filterNavItems = [];

    if (keycloak && keycloak?.realmAccess?.roles) {
      filterNavItems = items?.filter(
        (item) =>
          item.isNav &&
          keycloak.realmAccess.roles.some(
            (role) => item.allowedRole && item.allowedRole.includes(role)
          )
      );
    }

    return filterNavItems;
  };

  const renderNavItem = ({
    menuItem,
    level,
    handleClick,
    filteredChildren = null,
  }) => {
    return (
      <NavItem
        icon={menuItem?.iconName}
        iconElement={menuItem?.iconElement}
        childIcon={menuItem?.iconsName}
        key={menuItem?.name}
        path={menuItem.path}
        highlightRouts={menuItem.highlightRouts}
        selectedMenu={selectedMenu}
        openMenu={openMenu}
        item={menuItem}
        handleItemClick={handleClick}
        level={level}
        globalBg={globalBg}
        iconType={menuItem?.iconType}
        iconWidth={menuItem?.iconWidth}
        iconHeight={menuItem?.iconHeight}
      >
        <Text
          className={
            openMenu ? styles.openMenuTransition : styles.closeMenuTransition
          }
          fontSize="13px"
          pl={
            [
              'Admin App',
              'Learning Mgmt',
              'Live Chat',
              'Virtual Agent',
            ].includes(menuItem?.label)
              ? '6px'
              : '.25rem'
          }
          color="rgb(108, 114, 128)"
          fontWeight="medium"
        >
          {openMenu ? menuItem?.label : ''}
        </Text>

        {filteredChildren && filteredChildren?.length > 0 && openMenu ? (
          <span
            style={{ marginLeft: 'auto', marginRight: '11px' }}
            className="arrow"
          >
            <i
              aria-hidden="true"
              style={{
                height: 16,
                width: 16,
                paddingLeft: 10,
                color: 'rgb(196, 196, 196)',
                fontSize: '12px',
                fontWeight: '600',
              }}
              className={`${
                displayChildren[menuItem.name]
                  ? 'fa fa-angle-down'
                  : 'fa fa-angle-right'
              } ${displayChildren[menuItem.name] ? styles.arrowClicked : ''}`}
            />
          </span>
        ) : null}
      </NavItem>
    );
  };

  const getPathForParentMenuItems = (path = '', childrenLength = 0) => {
    if (childrenLength > 0) {
      return '#';
    }

    return path;
  };

  const renderMenuItems = (menuItemsList, level) => {
    const onMenuItemClick = (item) => {
      if (openMenu) {
        setDisplayChildren((prev) => ({
          ...prev,
          [item.name]: !displayChildren[item.name],
        }));
      }
    };

    return (
      <>
        {menuItemsList?.length > 0 &&
          menuItemsList.map((menuItem) => {
            const filteredChildren = filterNav(menuItem.children);
            if (menuItem && menuItem.children) {
              return (
                <SidebarContentHelper
                  path={getPathForParentMenuItems(
                    menuItem?.path,
                    filteredChildren?.length
                  )}
                >
                  {renderNavItem({
                    menuItem,
                    level,
                    filteredChildren,
                    handleClick: () => onMenuItemClick(menuItem),
                  })}
                  <div
                    className={`${styles.displayChildren} ${
                      displayChildren[menuItem?.name] ? '' : styles.hideChildren
                    }`}
                  >
                    <span className={styles.submenuContainer}>
                      {renderMenuItems(filteredChildren, level + 2)}
                    </span>
                  </div>
                </SidebarContentHelper>
              );
            }

            return (
              <SidebarContentHelper path={menuItem?.path}>
                {renderNavItem({
                  menuItem,
                  level,
                  handleClick: () => {},
                })}
              </SidebarContentHelper>
            );
          })}
      </>
    );
  };

  const renderMenus = useMemo(
    () =>
      modifiedRoutes?.length > 0 && filterNav(modifiedRoutes)?.length > 0
        ? renderMenuItems(filterNav(modifiedRoutes), 2)
        : null,
    [modifiedRoutes, selectedMenu, openMenu, displayChildren]
  );

  return (
    <>
      <div
        className={
          openMenu
            ? styles.sideBarExpandedContent
            : styles.sideBarCollapsedContent
        }
      >
        <Flex
          direction="column"
          gap="0.25rem"
          mt="10px"
          overflowX="hidden"
          className={
            openMenu
              ? styles.sidebarExpandedDrawer
              : styles.sidebarCollapsedDrawer
          }
        >
          {renderMenus}
        </Flex>
      </div>
      <div
        className={
          openMenu ? styles.stickyExpandedArrow : styles.stickyCollapsedArrow
        }
      >
        <ReactSVG
          onClick={() => setOpenMenu(!openMenu)}
          className={classnames(
            styles.commonIconStyle,
            styles.drawerIcon,
            styles.openMenuIcon,
            {
              [styles.iconRotate]: openMenu,
            }
          )}
          src={useColorModeValue(DrawerIcon, DrawerIconDark)}
        />
      </div>
    </>
  );
};

SidebarContentHelper.propTypes = {
  path: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

SidebarContents.propTypes = {
  selectedMenu: PropTypes.string,
  openMenu: PropTypes.bool,
  setOpenMenu: PropTypes.func,
  globalBg: PropTypes.string.isRequired,
  keycloak: PropTypes.object.isRequired,
  uiFeatureData: PropTypes.object.isRequired,
};

SidebarContents.defaultProps = {
  selectedMenu: '',
  openMenu: true,
  setOpenMenu: () => {},
};

export default SidebarContents;
