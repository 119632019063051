/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { MdBlockFlipped } from 'react-icons/md';

import { COLORS } from '../../utils/enums/colors';
import CustomSelectBox from '../../components/SelectBox/Select';
import CustomTable from '../DynamicRenderer/customTable';
import {
  ACTION_TYPE,
  ACTION_TYPE_VALUE,
  MANUAL_TRIGGER_TYPES,
} from '../DynamicRenderer/customEventAndTriggers/customEventAndTriggers.constant';
import { getWholeOption } from '../TicketTemplateWorkflow/helper';
import renderCreateIssuesForm from './actionForms/renderCreateIssuesForm';
import renderUpdateRecordsForm from './actionForms/renderUpdateRecordsForm';
import renderApprovalFlowForm from './actionForms/renderApprovalFlowForm';
import renderActorAssignmentForm from './actionForms/renderActorAssignmentForm';
import renderNotifyTeamSlackForm from './actionForms/renderNotifyTeamSlackForm';
import renderRunScript from './actionForms/renderRunScript';
import renderWebhookForm from './actionForms/renderWebhookForm';
import renderEmailForm from './actionForms/renderEmailForm';
import renderQueueAssignmentForm from './actionForms/renderQueueAssignmentForm';
import renderStatusTransitionForm from './actionForms/renderStatusTransitionForm';
import renderAddNoteForm from './actionForms/renderAddNoteForm';
import renderAddWatcherForm from './actionForms/renderAddWatcherForm';
import {
  convertAssoicatedIdToActorId,
  parseAPIToCustomMention,
  parseAPIToRichTextMention,
  useMentionObj,
} from '../CommunicationTemplates/utills';
import renderAddAttachmentForm from './actionForms/renderAddAttachmentForm';
import { CustomDrawer } from '../TicketTemplateBuilder/ConfigureTemplateView/AddEditTemplateLayoutModal';
import {
  PrimaryCustomButton,
  SecondaryCustomButton,
} from '../../components/Button/PrimarySecondaryButton';
import renderTicketAutomationForm from './actionForms/renderTicketAutomationForm';
import { getTenantData } from '../../helpers/tenantUrl';
import { axiosInstanceWithoutBaseUrl } from '../../utils/axios/axios';
import renderKnowledgeArticleForm from './actionForms/renderKnowledgeArticleForm';
import { getHierarchies } from '../../components/dynamicExpressionWithTags/dynamicExpressionWithTags.helper';

const isOneEventOfSameTemplate = (eventConfig, currentTemplateId) => eventConfig.some(
  (config) => config.linked_item_type === currentTemplateId || config.linked_item_relation === 'self'
);

const ActionToPerform = ({
  isActionToPerformEditing,
  addNewActionToPerform,
  actionToPerformTabColumns,
  filteredActions,
  actionState,
  onActionToPerformEditClick,
  actionToPerformSelectedIndex,
  handleChange,
  handleChanges,
  handleActionErrorData,
  actionErrorData,
  getDataForEmail,
  openPreviewModal,
  isModalOpen,
  closePreviewModal,
  selectedTemplate,
  onFinishSave,
  relatedTemplates,
  teamQueueMap,
  hierarchies,
  fieldHierarchyMaps,
  fieldDefaultDataForCreateIssue,
  isLoading,
  relationsData,
  relationItemTypeOption,
  fieldMapByTemplateId,
  oncancelActionType,
  templateIdMap,
}) => {
  const [linkedRelationItemId, setLinkedRelationItemId] = useState(
    actionState?.linked_item_type || ''
  );
  const [silentFlows, setSilentFlows] = useState([]);
  const [createIssueHierarchies, setCreateIssueHierarchies] = useState(null);
  const [createIssueFieldHierarchyMaps, setCreateIssueFieldHierarchyMaps] = useState(null);

  let actionToPerform = {};
  let isNoteForm = false;
  let isStatusTransitionForm = false;

  const selectedEvent = selectedTemplate.events?.events?.filter(
    (event) => event.id === actionState?.listened_event
  );

  if (selectedEvent.length) {
    isNoteForm =
      selectedEvent[0].trigger === MANUAL_TRIGGER_TYPES.ISSUE_NOTE_ADDED;
    isStatusTransitionForm =
      selectedEvent[0].trigger === MANUAL_TRIGGER_TYPES.ISSUE_TRANSITIONED;
  }

  const { mentionObj: emailMentionObj } = useMentionObj({
    linkedItemId: linkedRelationItemId,
    isEmailForm: true,
    isNoteForm,
    isStatusTransitionForm,
  });
  const { mentionObj: noteMentionObj } = useMentionObj({
    linkedItemId: linkedRelationItemId,
    isNoteForm,
    isStatusTransitionForm,
  });

  const actionTypes = ACTION_TYPE.filter(
    (actionType) => {
      if (
        actionType.value === ACTION_TYPE_VALUE.CREATE_ISSUES &&
        !isOneEventOfSameTemplate(actionState?.eventConfig || [], selectedTemplate.id)
      ) {
        return false;
      }
      return true;
    });

  if (actionState?.action_to_perform) {
    let data =
      actionState?.action_to_perform[actionToPerformSelectedIndex] || {};

    if (data.action_type === ACTION_TYPE_VALUE.SEND_EMAIL) {
      const signature = data.signature
        ? parseAPIToRichTextMention(data.signature)
        : '';
      const template = data.template
        ? parseAPIToRichTextMention(data.template)
        : '';
      const linkedItemType = relatedTemplates.filter(
        (temp) => temp.id === linkedRelationItemId
      )[0];
      const ccValue = data.cc ? convertAssoicatedIdToActorId(
        data.cc,
        selectedTemplate,
        linkedItemType
      ) : '';
      const toValue = data.to ? convertAssoicatedIdToActorId(
        data.to,
        selectedTemplate,
        linkedItemType
      ) : '';
      const bccValue = data.bcc ? convertAssoicatedIdToActorId(
        data.bcc,
        selectedTemplate,
        linkedItemType
      ) : '';
      const cc = ccValue ? parseAPIToCustomMention(ccValue) : '';
      const bcc = bccValue ? parseAPIToCustomMention(bccValue) : '';
      const subject = data.subject ? parseAPIToCustomMention(data.subject) : '';
      const to = toValue ? parseAPIToCustomMention(toValue) : '';
      const sendAs = data.send_as ?? 'text';
      data = {
        ...data,
        signature,
        template,
        cc,
        bcc,
        subject,
        to,
        send_as: sendAs,
      };
    }

    if (data.action_type === ACTION_TYPE_VALUE.NOTIFY_ON_TEAM_OR_SLACK) {
      if (data.audience) {
        data = {
          ...data,
          audience: parseAPIToCustomMention(data?.audience || ''),
        };
      }
      if (data.teams_Payload) {
        data = {
          ...data,
          teams_Payload: parseAPIToCustomMention(data?.teams_Payload || ''),
        };
      }
      if (data.slack_Payload) {
        data = {
          ...data,
          slack_Payload: parseAPIToCustomMention(data?.slack_Payload || ''),
        };
      }
    }

    if (data.action_type === ACTION_TYPE_VALUE.ADD_NOTE) {
      data = {
        ...data,
        message: parseAPIToRichTextMention(data?.message || ''),
      };
    }

    if (data.action_type === ACTION_TYPE_VALUE.TICKET_AUTOMATION) {
      if (data.payload) {
        data = {
          ...data,
          payload: parseAPIToCustomMention(data?.payload || ''),
        };
      }

      if (silentFlows) {
        data = {
          ...data,
          flowName: silentFlows.filter(
            (flow) => flow.value === data?.flowName
          )[0],
        };
      }
    }

    actionToPerform = { ...data };
  }
  useEffect(() => {
    setLinkedRelationItemId(actionState?.linked_item_type);
  }, [actionState?.linked_item_type || '']);

  useEffect(() => {
    const template = templateIdMap[actionToPerform?.issue_type];
    if (!template) {
      setCreateIssueHierarchies([]);
      setCreateIssueFieldHierarchyMaps([]);
    }

    if (template?.field_hierarchy_map) {
      getHierarchies(
        (hierarchy) => {
          setCreateIssueHierarchies(hierarchy || []);
          setCreateIssueFieldHierarchyMaps(template?.field_hierarchy_map || []);
        }
      );
    }
  }, [actionToPerform?.issue_type]);
  const getSilentFlows = async () => {
    const tenantInformation = getTenantData();
    const tenantId = tenantInformation?.id;
    const response = await axiosInstanceWithoutBaseUrl.get(
      `botConfig/findAndCountAll?$filter=tenantId eq '${tenantId}' and status eq 'Published' and properties_isSilentFlow eq 'true'`
    );

    if (response.status === 200) {
      if (
        response.data &&
        response.data?.rows &&
        Array.isArray(response.data?.rows)
      ) {
        const flows = response.data?.rows;
        setSilentFlows(
          flows.map((flow) => ({
            label: flow?.properties?.label,
            value: flow?.fullName.replace(`${flow.properties.namespace}.`, ''),
          }))
        );
      }
    }
    return [];
  };

  useEffect(() => {
    getSilentFlows();
  }, []);
  return (
    <Box
      border="1px solid #E2E3E4"
      borderRadius="10px"
      padding="4"
      marginTop="4"
    >
      {!isActionToPerformEditing && (
        <Box data-testid="DynamicRendererManualisActionEditingBox">
          <Flex
            gap="15px"
            flexWrap="wrap"
            marginBottom="1rem"
            data-testid="DynamicRendererManualisActionEditingFlex"
            className="pb-1 border-b-2"
          >
            <Box
              flex="1"
              justifyContent="space-between"
              fontSize="16px"
              fontWeight="600"
              data-testid="DynamicRendererManualisActionEditingTypeCIBox"
            >
              <Text> Actions to Perform</Text>
            </Box>
            <PrimaryCustomButton
              buttonText="Add New Action To Perform"
              onClick={() => {
                addNewActionToPerform();
              }}
              id="DynamicRendererManualisActionEditingPerformCBtn"
            />
          </Flex>
          <Box className="flex items-center justify-center">
            {(filteredActions || actionState.action_to_perform || []).length ? (
              <Box className="h-full w-full">
                <CustomTable
                  columns={actionToPerformTabColumns}
                  tableData={
                    filteredActions || actionState.action_to_perform || []
                  }
                  onRowClick={({ rowData, column }) =>
                    onActionToPerformEditClick(rowData?.id, column)
                  }
                />
              </Box>
            ) : (
              <Box
                height="144px"
                width="401px"
                border="1px solid #E2E3E4"
                borderRadius="10px"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap="15px"
              >
                <Box className="h-[41px] w-[41px] border border-solid custom-border rounded-lg flex items-center justify-center">
                  <MdBlockFlipped
                    className="h-[24px] w-[24px]"
                    color="#D1D5DB"
                  />
                </Box>
                <Text fontSize="16px" color="#6B7280" fontWeight="600">
                  You have not added action. please add action
                </Text>
              </Box>
            )}
          </Box>
        </Box>
      )}
      <CustomDrawer
        size="xl"
        drawerBody={
          <Grid data-testid="DynamicRendererManualTpActionEditingGrid">
            <GridItem data-testid="DynamicRendererManualTpActionEditingGridItm">
              <FormLabel
                className="ellipsis SCLabel"
                data-testid="DynamicRendererManualTpActionEditingActionlabel"
              >
                Action Type
              </FormLabel>
              <CustomSelectBox
                options={actionTypes}
                placeholder="Select Action Type"
                value={getWholeOption(actionToPerform.action_type, ACTION_TYPE)}
                onChange={(value) => {
                  if (value.value !== actionToPerform.action_type) {
                    handleChange(
                      {
                        action_type: value.value,
                      },
                      `action_to_perform[${actionToPerformSelectedIndex}]`
                    );
                  }
                }}
                focusBorderColor={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
                id="DynamicRendererManualTpActionEditingCSelect"
              />
              {actionToPerform.action_type === ACTION_TYPE_VALUE.SEND_EMAIL &&
                renderEmailForm(
                  actionToPerform,
                  handleChange,
                  handleChanges,
                  actionToPerformSelectedIndex,
                  handleActionErrorData,
                  actionErrorData,
                  getDataForEmail,
                  openPreviewModal,
                  isModalOpen,
                  closePreviewModal,
                  emailMentionObj,
                  linkedRelationItemId
                )}
              {actionToPerform.action_type ===
                ACTION_TYPE_VALUE.TRIGGER_WEBHOOK &&
                renderWebhookForm(
                  actionToPerform,
                  handleChange,
                  actionToPerformSelectedIndex,
                  handleActionErrorData,
                  actionErrorData
                )}
              {actionToPerform.action_type === ACTION_TYPE_VALUE.RUN_SCRIPT &&
                renderRunScript(
                  actionToPerform,
                  handleChange,
                  actionToPerformSelectedIndex,
                  handleActionErrorData,
                  actionErrorData
                )}
              {actionToPerform.action_type ===
                ACTION_TYPE_VALUE.NOTIFY_ON_TEAM_OR_SLACK &&
                renderNotifyTeamSlackForm(
                  actionToPerform,
                  handleChange,
                  actionToPerformSelectedIndex,
                  handleActionErrorData,
                  actionErrorData
                )}
              {actionToPerform.action_type ===
                ACTION_TYPE_VALUE.QUEUE_ASSIGNMENT &&
                renderQueueAssignmentForm(
                  actionState,
                  handleChange,
                  actionToPerformSelectedIndex,
                  actionErrorData,
                  handleActionErrorData,
                  selectedTemplate
                )}
              {actionToPerform.action_type ===
                ACTION_TYPE_VALUE.ACTOR_ASSIGNMENT &&
                renderActorAssignmentForm(
                  actionState,
                  handleChange,
                  actionToPerformSelectedIndex,
                  actionErrorData,
                  handleActionErrorData,
                  selectedTemplate,
                  actionToPerform
                )}
              {actionToPerform.action_type ===
                ACTION_TYPE_VALUE.INITIATE_APPROVAL_FLOW &&
                renderApprovalFlowForm(
                  actionState,
                  handleChange,
                  actionToPerformSelectedIndex,
                  actionErrorData,
                  handleActionErrorData,
                  selectedTemplate
                )}
              {actionToPerform.action_type ===
                ACTION_TYPE_VALUE.UPDATE_RECORDS &&
                renderUpdateRecordsForm(
                  actionToPerform,
                  selectedTemplate,
                  teamQueueMap,
                  hierarchies,
                  fieldHierarchyMaps,
                  handleChange,
                  actionToPerformSelectedIndex,
                  actionState,
                  relatedTemplates
                )}
              {actionToPerform.action_type ===
                ACTION_TYPE_VALUE.CREATE_ISSUES &&
                renderCreateIssuesForm(
                  relatedTemplates,
                  actionToPerform,
                  handleChange,
                  handleChanges,
                  actionToPerformSelectedIndex,
                  actionErrorData,
                  teamQueueMap,
                  fieldDefaultDataForCreateIssue,
                  relationsData,
                  selectedTemplate,
                  actionState,
                  createIssueHierarchies,
                  createIssueFieldHierarchyMaps,
                )}
              {actionToPerform.action_type ===
                ACTION_TYPE_VALUE.STATE_TRANSITION &&
                renderStatusTransitionForm(
                  handleChange,
                  actionToPerformSelectedIndex,
                  handleActionErrorData,
                  selectedTemplate,
                  actionToPerform,
                  handleChanges
                )}
              {actionToPerform.action_type === ACTION_TYPE_VALUE.ADD_NOTE &&
                renderAddNoteForm(
                  handleChange,
                  actionToPerformSelectedIndex,
                  handleActionErrorData,
                  selectedTemplate,
                  actionToPerform,
                  noteMentionObj
                )}
              {actionToPerform.action_type === ACTION_TYPE_VALUE.ADD_WATCHERS &&
                renderAddWatcherForm(
                  handleChange,
                  actionToPerformSelectedIndex,
                  handleActionErrorData,
                  actionToPerform,
                  actionErrorData
                )}
              {actionToPerform.action_type ===
                ACTION_TYPE_VALUE.REMOVE_WATCHERS &&
                renderAddWatcherForm(
                  handleChange,
                  actionToPerformSelectedIndex,
                  handleActionErrorData,
                  actionToPerform,
                  actionErrorData
                )}
              {actionToPerform.action_type ===
                ACTION_TYPE_VALUE.ADD_ATTACHMENT &&
                renderAddAttachmentForm(
                  handleChange,
                  actionToPerformSelectedIndex,
                  handleActionErrorData,
                  actionToPerform,
                  actionErrorData,
                  relationsData,
                  relationItemTypeOption,
                  fieldMapByTemplateId,
                  selectedTemplate
                )}
              {actionToPerform.action_type ===
                ACTION_TYPE_VALUE.REMOVE_ATTACHMENT &&
                renderAddAttachmentForm(
                  handleChange,
                  actionToPerformSelectedIndex,
                  handleActionErrorData,
                  actionToPerform,
                  actionErrorData,
                  relationsData,
                  relationItemTypeOption,
                  fieldMapByTemplateId,
                  selectedTemplate
                )}
              {actionToPerform.action_type ===
                ACTION_TYPE_VALUE.TICKET_AUTOMATION &&
                renderTicketAutomationForm(
                  actionToPerform,
                  handleChange,
                  actionToPerformSelectedIndex,
                  handleActionErrorData,
                  actionErrorData,
                  silentFlows
                )}
              {actionToPerform.action_type ===
                ACTION_TYPE_VALUE.ADD_TO_KNOWLEDGE &&
                renderKnowledgeArticleForm(
                  actionToPerform,
                  handleChange,
                  actionToPerformSelectedIndex,
                  handleActionErrorData,
                  actionErrorData,
                  silentFlows
                )}
            </GridItem>
          </Grid>
        }
        drawerFooter={
          <Flex p="16px 10px" justify="flex-end" gap={3}>
            <SecondaryCustomButton
              onClick={() => {
                oncancelActionType();
              }}
              buttonText="Cancel"
              id="AddEditTemplateLayoutModalNameTextCancelBtn"
            />
            <PrimaryCustomButton
              onClick={() => {
                onFinishSave();
              }}
              buttonText="Save"
              isLoading={isLoading}
            />
          </Flex>
        }
        modalTitle="Action To Perform"
        openDrawer={isActionToPerformEditing}
        setOpenDrawer={oncancelActionType}
      />
    </Box>
  );
};

export default ActionToPerform;
