import React from 'react';
import { Box, Checkbox } from '@chakra-ui/react';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { getWholeOption } from '../../TicketTemplateWorkflow/helper';
import { selectOptionsNoteTypes } from '../../DynamicRenderer/customTemplateModal/CustomTemplateModalLayout';
import QuillEditor from '../../../components/QuillEditor';
import { EMAIL_QUILL_TOOLBAR } from '../../CommunicationTemplates/utills';
import { roleSettingSelectOpt } from '../../../utils/enums/selectOption';
import DynamicMutliSelectWithInputTags from '../../../components/dynamicSelectWithInputTags/DynamicSelectWithInputTags';

const makeNoteTypeOptions = (template) =>
  selectOptionsNoteTypes.filter((option) =>
    template.properties?.note_types?.includes(option.value)
  );

const renderAddNoteForm = (
  handleChange,
  actionToPerformSelectedIndex,
  handleActionErrorData,
  selectedTemplate,
  actionToPerform,
  mentionObj
) => (
  <>
    <Box data-testid="DynamicRendererNoteFormBox1" marginTop="8px">
      <CustomSelectBox
        options={makeNoteTypeOptions(selectedTemplate)}
        label="Note Type"
        placeholder="Select Note Type"
        value={getWholeOption(
          actionToPerform.note_type,
          makeNoteTypeOptions(selectedTemplate)
        )}
        onChange={(value) => {
          handleChange(
            value.value,
            `action_to_perform[${actionToPerformSelectedIndex}].note_type`
          );

          handleActionErrorData('note_type');
        }}
        id="DynamicRendererManualEventCustomSelectNoteType"
      />
    </Box>
    <Box data-testid="DynamicRendererNoteFormBox2" marginTop="8px">
      <QuillEditor
        editorvalue={actionToPerform.message}
        toolBarModules={EMAIL_QUILL_TOOLBAR}
        format=""
        customEditorContainerStyles={{ height: '196px' }}
        handleOnChange={(value) => {
          handleChange(
            value,
            `action_to_perform[${actionToPerformSelectedIndex}].message`
          );
          handleActionErrorData('message');
        }}
        label="Note"
        mentionObj={mentionObj}
      />
    </Box>
    <Box data-testid="DynamicRendererNoteFormBox3" marginTop="32px">
      <Checkbox
        isChecked={actionToPerform.notify_users}
        onChange={() => {
          handleChange(
            !actionToPerform.notify_users,
            `action_to_perform[${actionToPerformSelectedIndex}].notify_users`
          );
        }}
        mb="15px"
        data-testid="AddFromMasterModalSelectAllCheckbox"
      >
        Notify Users?
      </Checkbox>
    </Box>
    <Box data-testid="DynamicRendererNoteFormBox4" marginTop="8px">
      <Checkbox
        isChecked={actionToPerform.send_note_with_history}
        onChange={() => {
          handleChange(
            !actionToPerform.send_note_with_history,
            `action_to_perform[${actionToPerformSelectedIndex}].send_note_with_history`
          );
        }}
        mb="15px"
        data-testid="AddFromMasterModalSelectAllCheckbox"
      >
        Send Note with History?
      </Checkbox>
    </Box>
    <Box data-testid="DynamicRendererNoteFormBox5" marginTop="8px">
      <DynamicMutliSelectWithInputTags
        values={actionToPerform.permissions || {}}
        label="Permissions"
        onChange={(value) => {
          handleChange(
            value,
            `action_to_perform[${actionToPerformSelectedIndex}].permissions`
          );
        }}
        templateId={selectedTemplate?.id}
        selectOptions={roleSettingSelectOpt}
      />
    </Box>
  </>
);

export default renderAddNoteForm;
