import React, { useCallback, useEffect, useState } from 'react';

import { Drawer, DrawerContent, DrawerHeader, DrawerOverlay, IconButton, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useColorModeValue } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import SLA from '../SLA';
import EditMetricModal from './EditMetricModal';

import { FORM_KEY_MAP, IS_SLA, UNIT_MAP } from './utils';
import { updateViewState } from '../../../../redux/ViewSlice/ViewReducer';
import styles from './metrics.module.scss';
import { GREY_BACKGROUND } from '../../../../utils/enums/colors';
import { isObjectEqual } from '../../../../utils/helper/isObjectEqual';
import { showToastMessage } from '../../../TicketTemplateBuilder/utils/templateAPIUtils';

const TABS = {
  METRICS: 'Metrics Parameters',
  SLA: 'SLA',
};

const EditMetrics = ({
  data,
  isEdit,
  closeModal,
  openDrawer,
  onSaveMetric,
  isLoading,
  setIsLoading,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const viewState = useSelector((state) => state.viewState || {});

  const [activeTab, setActiveTab] = useState(TABS.METRICS);
  const [slaModalState, setSlaModalState] = useState({
    show: false,
    isEdit: false,
    rowData: null,
  });
  const [isMetricUpdated,setIsMetricUpdated] = useState(false)

  const showSLAModal = useCallback(({ rowData = {}, isSlaEdit = false }) => {
    setSlaModalState({
      show: true,
      isEdit: isSlaEdit,
      rowData,
    });
  }, []);

  const closeSLAModal = useCallback(() => {
    setSlaModalState({
      show: false,
      isEdit: false,
      rowData: null,
    });
  }, []);

  const navigateBack = useCallback(() => {
    if (activeTab === TABS.SLA && slaModalState.show) {
      closeSLAModal();
    } else {
      closeModal();
    }
    setActiveTab(TABS.METRICS)
  }, [activeTab, slaModalState]);

  const onSave = (dataObj) => {
    onSaveMetric(dataObj);
  };

  const getqueueData = useCallback(async () => {
    dispatch(
      updateViewState({
        stateKey: 'queue',
        value: {
          id,
          data: [],
        },
      })
    );
  }, [id]);

  useEffect(() => {
    if (viewState?.queue?.id !== id) {
      getqueueData();
    }
  }, []);

  const tabConfigurations = [
    {
      label: TABS.METRICS,
      testId: 'EditMetricsTab',
      onClick: () => setActiveTab(TABS.METRICS),
      isActive: activeTab === TABS.METRICS,
    },
    {
      label: TABS.SLA,
      testId: 'EditMetricsSLATab',
      onClick: () => {
        if(isMetricUpdated){
          showToastMessage({
            description: 'Save Change before moving to SLA',
            status: 'info',
          });
          return
        }
          setActiveTab(TABS.SLA)
      },
      isActive: activeTab === TABS.SLA,
      isVisible: data?.id && data?.[FORM_KEY_MAP.SLA] === IS_SLA[0].value,
    },
  ];
  const isMetricUpdatedFun=(initialMetricData,metricData)=>{
    setIsMetricUpdated(!isObjectEqual(initialMetricData,metricData))
  }

  return (
    <Drawer
      size="lg"
      placement="right"
      onClose={closeModal}
      isOpen={openDrawer}
      data-testid="AddOrEditEmailTemplateDrawerEmail"
    >
      <DrawerOverlay />
      <DrawerContent>
      <DrawerHeader>
      <div data-testid="EditMetricsContainerDiv">
        <div
          className="flex items-center gap-[10px]"
          data-testid="EditMetricsArrowIconDiv"
        >
          <IconButton
            colorScheme="blue"
            aria-label="Close Drawer"
            size="sm"
            borderRadius="50%"
            minW="1.5rem"
            height="1.5rem"
            variant="outline"
            icon={<ArrowBackIcon />}
            onClick={navigateBack}
          />
          {activeTab === TABS.METRICS ? (
            <div data-testid="EditMetricsTextPDiv">
              <p
                className="font-semibold text-[16px]"
                data-testid="EditMetricsMetricP"
              >
                {`${isEdit ? 'Update Metric' : 'Create New Metric'}`}
              </p>
            </div>
          ) : (
            <div data-testid="EditMetricsTextSlaDiv">
              <p
                className="font-semibold text-[16px]"
                data-testid="EditMetricsSLAP"
              >
                {slaModalState.isEdit ? 'Update SLA' : 'Create New SLA'}
              </p>
            </div>
          )}
        </div>
      </div>
      </DrawerHeader>
      {/* TABS container */}
      <div className="mt-[20px]" data-testid="EditMetricsContainerTabs">
        <Tabs index={tabConfigurations.findIndex(({isActive})=>isActive)} data-testid="EditMetricsTabs">
              <TabList
                padding="0px"
                data-testid="EditMetricsTabList"
                bg={useColorModeValue(GREY_BACKGROUND, '#4F5765')}
                className={styles.tab__container}
              >
                {tabConfigurations.map(({ label, testId, onClick, isActive, isVisible = true }) => (
                  isVisible && (
                    <Tab
                      key={label}
                      onClick={onClick}
                      border="none"
                      borderBottom="0px"
                      padding="5px"
                      className={`${styles.tab}`}
                      data-testid={testId}
                    >
                      <Text
                        px="12px"
                        py="8px"
                        className={`${styles.tab__text} ${isActive ? useColorModeValue(styles.tab__selected__light, styles.tab__selected__dark) : ''}`.trim()}
                      >
                        {label}
                      </Text>
                    </Tab>
                  )
                ))}
              </TabList>
          <TabPanels data-testid="EditMetricsTabPanels">
                <TabPanel data-testid="EditMetricsTabPanelMetric">
                  <EditMetricModal
                    rowData={data}
                    navigateBack={navigateBack}
                    isMetricUpdated={isMetricUpdatedFun}
                    onSaveMetric={onSave}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                  />
                </TabPanel>
                <TabPanel data-testid="EditMetricsTabPanelSla">
                  <SLA
                    modalState={slaModalState}
                    showModal={showSLAModal}
                    navigateBack={navigateBack}
                    selectedMetricId={data?.id}
                    selectedMetricUnit={
                      UNIT_MAP[data?.[FORM_KEY_MAP.METRIC_UNIT]] || ''
                    }
                  />
                </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
      </DrawerContent>
    </Drawer>
  );
};

EditMetrics.propTypes = {
  data: PropTypes.object,
  isEdit: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  onSaveMetric: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  openDrawer: PropTypes.bool.isRequired,
};

EditMetrics.defaultProps = {
  data: {},
  isEdit: false,
  isLoading: false,
  setIsLoading: () => {},
};

export default EditMetrics;
