import axiosInstance, { axiosInstanceWithoutBaseUrl } from '../../../utils/axios/axios';
import { getTenantData } from '../../../helpers/tenantUrl';

const allCompositeUrl = 'composite-fields';
const getAllCompositeApi = async (options) => {
  const list = await axiosInstance.get(allCompositeUrl, { params: options });
  return list.data;
};
const getRoleListApi = async () => {
  const list = await axiosInstanceWithoutBaseUrl.get(`role/tenant-roles/null?tenantId=${getTenantData().tenantId}`);
  return list.data;
};

const allCompositeService = {
  getAllCompositeApi,
  getRoleListApi
};

export default allCompositeService;
