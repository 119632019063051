/* eslint-disable indent */
import React, { useState } from 'react';
import {
  Table,
  TableContainer,
  Th,
  Tr,
  Td,
  Thead,
  Tbody,
  useColorModeValue,
  Box,
} from '@chakra-ui/react';
import { capitalize, cloneDeep, startCase } from 'lodash';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import TableColumnText from '../Text/TableColumnText';
import {
  BLACK,
  BORDER_DARK,
  BORDER_LIGHT,
  COLORS,
  WHITE,
} from '../../utils/enums/colors';
import styles from './table.module.scss';
import TriangleRightIcon from '../../assets/icons/TriangleRightIcon.svg';
import TriangleDown from '../../assets/icons/TriangleDown.svg';
import Circle from '../../assets/icons/tableCircle.svg';
import { useLocation } from 'react-router';import Loader from '../../pages/DynamicRenderer/Dashboard/Loader';

const CustomTable = (props) => {
  const {
    columns,
    tableData,
    isTableLoading,
    onRowClick,
    rowIdKey,
    hideTableHead,
    nestedTable,
    height,
    title
  } = props;
  const [expandedRows, setExpandedRows] = useState({});
  const {pathname} = useLocation();

  const toggleRow = (e, rowId) => {
    e.stopPropagation();
    if (expandedRows[rowId]) {
      setExpandedRows((prev) => {
        const temp = { ...prev };
        delete temp[rowId];
        return temp;
      });
    } else {
      setExpandedRows((prev) => {
        const temp = { ...prev };
        temp[rowId] = true;
        return temp;
      });
    }
  };

  const getColumnContent = ({ column, td, index, subIndex }) => {
    const columnCellContent =
      column?.cellRenderer && typeof column.cellRenderer === 'function' ? (
        column.cellRenderer({
          rowData: td,
          column,
          rowIndex: index,
        })
      ) : (
        <TableColumnText text={td[column.col_name]} />
      );

    if (nestedTable) {
      if (subIndex === 0) {
        return (
          <div className="flex gap-[10px] max-w-[300px] items-center">
            <div
              style={{
                paddingLeft: (td?.nestedLevel || 0) * 30,
              }}
            >
              {td?.children?.length > 0 ? (
                <div onClick={(e) => toggleRow(e, td?.id)}>
                  {expandedRows[td?.id] ? (
                    <ReactSVG
                      src={TriangleDown}
                      className={useColorModeValue(styles.light, styles.dark)}
                    />
                  ) : (
                    <ReactSVG
                      src={TriangleRightIcon}
                      className={useColorModeValue(styles.light, styles.dark)}
                    />
                  )}
                </div>
              ) : (
                <div className="">
                  <ReactSVG src={Circle} />
                </div>
              )}
            </div>
            <div className="flex-1">{columnCellContent}</div>
          </div>
        );
      }
    }
    return columnCellContent;
  };

  const getTableData = () => {
    if (nestedTable) {
      const tempData = [];
      const nestData = (data, nestedLevel) => {
        (data || [])?.forEach((d) => {
          const temp = cloneDeep(d);
          Object.assign(temp, { nestedLevel });

          tempData.push(temp);
          if (expandedRows[temp?.id]) {
            nestData(temp?.children, (temp?.nestedLevel || 0) + 1);
          }
        });
      };

      nestData(tableData, 0);

      return tempData;
    }
    return tableData;
  };

  return (
    <TableContainer
      borderRadius="10px 10px 0px 0px"
      // borderWidth="1.5px"
      fontSize="14px"
      borderTop="1.5"
      borderBottom="0px"
      borderColor={useColorModeValue(BORDER_LIGHT, BORDER_DARK)}
      color={useColorModeValue(COLORS.BLACK, COLORS.TEXT_DARK)}
      data-testid="CusTableTbleContainer"
      height={height}
      overflow="auto"
      style={{ overflowY: 'auto' }}
    >
      <Table variant="simple" data-testid="CusTable">
        {!hideTableHead && (
          <Thead data-testid="CusTableThead" className="border-b-2">
            <Tr data-testid="CusTableTr" padding="10px">
              {columns.map((column) => (
                <Th
                  paddingLeft="0px"
                  paddingRight="0px"
                  paddingTop="20px"
                  paddingBottom="20px"
                  color={useColorModeValue(BLACK, WHITE)}
                  key={column?.col_name}
                  width={`${100 / (columns.length - 1)}%`}
                  textAlign={
                    column.col_name === 'actions'
                      ? 'center'
                      : column.align || 'start'
                  }
                  className={`${useColorModeValue(
                    styles.tableRowDataLight,
                    styles.tableRowDataDark
                  )}`}
                  fontWeight="500"
                  fontSize="14px"
                  lineHeight="normal"
                  style={{ ...column?.headerStyle, textTransform: 'none' }}
                >
                  {column?.headerRenderer &&
                  typeof column.headerRenderer === 'function'
                    ? column.headerRenderer({ column })
                    : column?.textAsItIs ? column?.col_name : capitalize(startCase(column?.col_name).toLowerCase())}

                </Th>
              ))}
            </Tr>
          </Thead>
        )}

        <Tbody>
          {!getTableData().length? (
            <Td colSpan={columns.length} textAlign="center">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="calc(100vh - 18rem - 60px)"
                fontSize="16px"
                color={useColorModeValue(COLORS.BLACK, COLORS.TEXT_DARK)}
                data-testid="NoRecordsMessage"
              >
                {isTableLoading ? <Loader />:`No Record Found For ${title || 'data'}`}
              </Box>
            </Td>
          ) : (getTableData()?.map((td, index) => (
              <React.Fragment key={td.id}>
                <Tr
                  {...(rowIdKey && typeof rowIdKey === 'function'
                    ? { id: rowIdKey(td) }
                    : {})}
                  {...(typeof onRowClick === 'function'
                    ? { className: 'cursor-pointer' }
                    : {})}
                  className="border-b-1"
                >
                  {columns.map((column, subIndex) => (
                    <Td
                      padding="16px 0px"
                      className={`${
                        useColorModeValue(styles.tableRowDataLight, styles.tableRowDataDark)
                      }`}
                      key={`table_data_${index + 1}_${`${subIndex + 1}`}`}
                      {...(onRowClick &&
                        typeof onRowClick === 'function' && {
                          onClick: (e) => {
                            onRowClick({
                              event: e,
                              rowData: td,
                              column,
                              rowIndex: index,
                            });
                          },
                        })}
                    >
                      {getColumnContent({
                        column,
                        td,
                        index,
                        subIndex,
                      })}
                    </Td>
                  ))}
                </Tr>
              </React.Fragment>
            ))
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

CustomTable.propTypes = {
  columns: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  isTableLoading: PropTypes.bool,
  rowIdKey: PropTypes.func,
  hideTableHead: PropTypes.bool,
  nestedTable: PropTypes.bool,
  level: PropTypes.number,
  height: PropTypes.string,
  title: PropTypes.string,
};

CustomTable.defaultProps = {
  onRowClick: null,
  rowIdKey: undefined,
  isTableLoading: false,
  hideTableHead: false,
  nestedTable: false,
  level: 1,
  height: 'calc(100vh - 18rem)',
  title: 'data',
};

export default CustomTable;
