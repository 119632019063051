/* eslint-disable indent */
import { FIELD_VALIDATE_KEY, LABELS } from '../../../utils/enums/labels';
import { validateNumber } from './helper';
import { BASE_SETTINGS_KEYS, ADVANCED_SETTINGS_KEYS } from './constants';
import { renderFieldLabels } from './renderFields';

const baseNumberSetting = ({
  data,
  setFields,
  fieldSlug,
  setIsError,
  existingFields,
}) => ({
  row1: [
    renderFieldLabels({
      data,
      setFields,
      fieldSlug,
      setIsError,
      existingFields,
    }),
    {
      label: LABELS.FIELD_PROPERTIES.NAME,
      key: FIELD_VALIDATE_KEY.SLUG,
      value: `${data?.[BASE_SETTINGS_KEYS.SLUG] || ''}`,
      placeholder: '',
      required: true,
      errorString: LABELS.ERROR.NAME,
      isReadOnly: true,
      valueKey: BASE_SETTINGS_KEYS.SLUG,
      onChange: (value) => {
        setFields((pre) => ({
          ...pre,
          [BASE_SETTINGS_KEYS.SLUG]: value,
          [BASE_SETTINGS_KEYS.IS_EDIT_SLUG]: true,
          isNameError: false,
        }));
        setIsError((pre) => ({ ...pre, [BASE_SETTINGS_KEYS.SLUG]: !value }));
      },
    },
  ],
  row2: [
    {
      label: LABELS.FIELD_PROPERTIES.DESCRIPTION,
      value: data?.[BASE_SETTINGS_KEYS.DESCRIPTION],
      placeholder: '',
      valueKey: BASE_SETTINGS_KEYS.DESCRIPTION,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [BASE_SETTINGS_KEYS.DESCRIPTION]: value,
        })),
    },
  ],
  row3: [
    {
      label: LABELS.FIELD_PROPERTIES.DEFAULT_VALUE,
      value: `${data?.[BASE_SETTINGS_KEYS.DEFAULT_VALUE] || ''}`,
      placeholder: '',
      type:'number',
      key: FIELD_VALIDATE_KEY.DEFAULT_VALUE,
      errorString: LABELS.ERROR.NUMBER_FORMAT,
      valueKey: BASE_SETTINGS_KEYS.DEFAULT_VALUE,
      onChange: (value) => {
        const defaultValue = value;
        setFields((pre) => ({
          ...pre,
          [BASE_SETTINGS_KEYS.DEFAULT_VALUE]: value,
        }));
        setIsError((pre) => {
          delete pre[`${BASE_SETTINGS_KEYS.DEFAULT_VALUE}_message`]
          return {
          ...pre,
          [BASE_SETTINGS_KEYS.DEFAULT_VALUE]: false,
        }});
      },
    },
  ],
});

export default baseNumberSetting;
