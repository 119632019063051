import axios from 'axios';
import { isEmpty, isArray } from 'lodash';
import { getUserInfoUrl } from '../../helpers/tenantUrl';
import { showToastMessage } from '../../pages/TicketTemplateBuilder/utils/templateAPIUtils';

const checkIfAvailable = (arr1, arr2) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return [];
  }

  return arr1.filter((element) => arr2.includes(element));
};

class UserInfoInformation {
  #userInfoDetails = {};

  /**
   * @param {{}} userDetails
   */
  set setUserInfoDetails(userDetails) {
    this.#userInfoDetails = userDetails;
  }

  get userDetails() {
    return this.#userInfoDetails;
  }
}

const userInfo = new UserInfoInformation();

export const getUserInfo = async (keycloack) => {
  const url = getUserInfoUrl();
  const token = localStorage.getItem('kc_token');

  try {
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const r = await keycloack.loadUserProfile();

    const data = res?.data?.data?.metaData || {};

    userInfo.setUserInfoDetails = {
      ...data,
      availableUsers: data?.users,
      roles: keycloack?.realmAccess?.roles || data?.roles,
      teams: r?.attributes?.team || data?.teams,
      users: r?.username,
    };
  } catch (err) {
    console.warn(err, 'user info not found');
    userInfo.setUserInfoDetails = {};
    showToastMessage({
      description: 'User info not found. Switching to view mode',
      status: 'info',
    });
  }
};

export const checkForCollaborators = (selectedTemplate) => {
  const user = userInfo.userDetails;
  const collaborators = selectedTemplate?.properties?.collaborators;
  if (typeof collaborators === 'string') {
    /*
     * Enabling the access only if the collaborator is the current user
     * Currently, we can add only one collaborator
     */
    return (
      collaborators?.trim()?.toLowerCase() === user?.users?.trim()?.toLowerCase()
    );
  }
  return false;
};

export const enableViewerIfUserNotFound = () => {
  /**
   * Enabling the view access if user info not found
   */
  const user = userInfo.userDetails;
  return isEmpty(user);
};

export const isCurrentUserTemplateOwner = (template, isCheckCreatedBy = true) => {
  const user = userInfo.userDetails;
  const owners = template?.properties?.owners;
  let ownerString = owners;
  const userRoles = user?.roles?.map((role) => role.toLowerCase()) || [];
  const hasAdminRole = userRoles.includes('ticket_admin') || userRoles.includes('tenant_admin') ||
  userRoles.includes('ticket admin') || userRoles.includes('tenant admin');

  if (isArray(owners)) {
    ownerString = owners.join(',');
  }

  if (template?.created_by?.toLowerCase() === 'system' || hasAdminRole) {
    return true;
  }
  if (isCheckCreatedBy) {
    return ownerString
      ?.split(',')
      ?.map((name) => name.trim())
      ?.includes(user?.users) ||
    ownerString
      ?.split(' ')
      ?.map((name) => name.trim())
      ?.includes(user?.users) ||
    template?.created_by === user?.users;
  }
  return ownerString
    ?.split(',')
    ?.map((name) => name.trim())
    ?.includes(user?.users) ||
  ownerString
    ?.split(' ')
    ?.map((name) => name.trim())
    ?.includes(user?.users);
};

export const enableApproveOnlyForOwnersInTemplate = (
  selectedTemplate,
  label,
) => {
  if (label === 'Approve' || label === 'Reject') {
    return !isCurrentUserTemplateOwner(selectedTemplate);
    // Disabling Approve button, if no owners are available for the template
  }
  return false;
};

export const checkPermissions = (permissions) => {
  const user = userInfo.userDetails;

  // Checking for the username
  const checkUsers =
    checkIfAvailable(permissions?.users, [user?.users]).length > 0;

  // Checking for the roles
  const checkRoles =
    checkIfAvailable(permissions?.roles, user?.roles).length > 0;

  // Checking for the queues
  const checkQueues =
    checkIfAvailable(permissions?.queues, user?.queues).length > 0;

  // Checking for teams
  const checkTeams =
    checkIfAvailable(permissions?.teams, user?.teams).length > 0;

  // Checking for groups
  const checkGroups =
    checkIfAvailable(permissions?.groups, user?.groups).length > 0;
  return (
    permissions?.org?.length ||
    checkUsers ||
    checkRoles ||
    checkQueues ||
    checkTeams ||
    checkGroups
  );
};

export const getUserInformation = () => {
  const user = userInfo.userDetails;
  return user;
};

export const removeDuplicateItem = (emails) => {
  const seenValues = new Set();
  return emails.filter((email) => {
    if (seenValues.has(email.value)) {
      return false; // Duplicate found, filter out
    }
    seenValues.add(email.value);
    return true; // Unique, keep it
  });
};

export const availableUsersListInTenantLevel = () => {
  const userInfoData = userInfo.userDetails;
  return removeDuplicateItem([...(userInfoData?.availableUsers || [])].map((item) => ({
    value: item.email || item.user,
    label: item.user || item.email
  })));
};
