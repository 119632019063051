import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import { useColorModeValue } from '@chakra-ui/react';
import './datePicker.scss';
import { WHITE } from '../../utils/enums/colors';

const { RangePicker } = DatePicker;

const CustomDatePicker = ({
  showTime,
  value,
  onChange,
  dateFormat,
  allowClear,
  customHeight,
  isRangeSelector,
  ...props
}) => {
  const color = useColorModeValue(WHITE, '#4f5765');

  const commonProps = {
    showTime: showTime ? { format: 'HH:mm' } : false,
    format: dateFormat,
    allowClear,
    changeOnBlur: true,
    ...props,
    style: {
      // eslint-disable-next-line react/prop-types
      ...(props?.style || {}),
      width: '100%',
      height: customHeight,
      backgroundColor: color, // Set the background color based on the theme
    },
  };

  if (isRangeSelector) {
    return (
      <RangePicker
        value={value?.length === 2 ? [dayjs(value[0]), dayjs(value[1])] : []}
        onChange={(val) => {
          if (!val || val.length !== 2) {
            onChange([]);
          } else {
            onChange([
              new Date(dayjs(val[0]).format()),
              new Date(dayjs(val[1]).format()),
            ]);
          }
        }}
        dropdownClassName={useColorModeValue(
          '',
          'darkModeDateRangeMenu textColor'
        )}
        className={useColorModeValue('', 'inputClass')}
        {...commonProps}
      />
    );
  }

  return (
    <DatePicker
      value={value ? dayjs(value) : undefined}
      onChange={(val) => {
        if (val) {
          onChange(new Date(dayjs(val).format()));
        } else {
          onChange('');
        }
      }}
      dropdownClassName={useColorModeValue(
        '',
        'darkModeDateRangeMenu textColor'
      )}
      className={useColorModeValue('', 'inputClass')}
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      {...commonProps}
    />
  );
};

CustomDatePicker.propTypes = {
  showTime: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  dateFormat: PropTypes.string,
  allowClear: PropTypes.bool,
  isRangeSelector: PropTypes.bool,
  customHeight: PropTypes.string,
};

CustomDatePicker.defaultProps = {
  showTime: false,
  value: undefined,
  dateFormat: 'YYYY-MM-DD HH:mm',
  allowClear: true,
  isRangeSelector: false,
  customHeight: '32px',
};

export default CustomDatePicker;
