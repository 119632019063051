import React from 'react';
import {
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { DEFAULT } from '../../../utils/enums/colors';
import { DROPDOWN_TYPES, SPECIAL_FIELD_TYPE } from '../../TenantFilters/filters.constants';

const convertFieldType = ({ rowData }) => {
  if (rowData?.type === 'DROPDOWN') {
    const collection = rowData?.type_based_attributes?.collection;

    if (collection) {
      if (collection.collection_type === DROPDOWN_TYPES.SPECIAL_FIELD) {
        // eslint-disable-next-line default-case
        switch (collection.specialField) {
          case SPECIAL_FIELD_TYPE.SOURCE:
            return 'Source Dropdown';
          case SPECIAL_FIELD_TYPE.QUEUE:
            return 'Queue Dropdown';
          case SPECIAL_FIELD_TYPE.WORKFLOW:
            return 'Workflow State Dropdown';
          case SPECIAL_FIELD_TYPE.ACTORS:
            return 'Actor Dropdown';
          default:
            return 'Special Dropdown';
        }
      }

      switch (collection.collection_type) {
        case DROPDOWN_TYPES.STATIC:
          return 'Static Dropdown';
        case DROPDOWN_TYPES.DYNAMIC:
          return 'Dynamic Dropdown';
        case DROPDOWN_TYPES.DEPENDENT:
          return 'Dependent Dropdown';
        default:
          return 'Dropdown';
      }
    }
  }

  switch (rowData?.type) {
    case 'TEXT':
      return 'Text';
    case 'NUMBER':
      return 'Number';
    case 'DATE':
      return 'Date';
    case 'BOOLEAN':
      return 'Boolean';
    case 'TEXT_AREA':
      return 'Text Area';
    case 'RICH_TEXT':
      return 'Rich Text';
    case 'EMAIL':
      return 'Email';
    case 'MEDIA':
      return 'Media';
    case 'LABEL':
      return 'Label';
    case 'TAGS':
      return 'Tags';
    default:
      return rowData?.type;
  }
};

const convertScope = ({ rowData }) => {
  switch (rowData?.scope) {
    case 'TICKET':
      return 'Ticket';
    case 'RELATION':
      return 'Relation';
    case 'WORKFLOW':
      return 'Workflow';
    default:
      return (
        <Text
          as="i"
          color={useColorModeValue(DEFAULT, DEFAULT)}
        >
Not Set
        </Text>
      );
  }
};
const convertMetaField = ({ rowData }) => {
  const metaFieldList = useSelector(
    (state) => state?.viewState?.selectedTemplate?.associated_meta_fields || {}
  );
  const isFieldIdPresent = Object.keys(metaFieldList).includes(rowData.id);
  if (isFieldIdPresent) {
    return metaFieldList[rowData.id].name;
  }
  return (
    <Text
      textAlign="center"
      as="i"
      color={useColorModeValue(DEFAULT, DEFAULT)}
    >
Not Set
    </Text>
  );
};

export {
  convertFieldType,
  convertScope,
  convertMetaField
};
