/* eslint-disable camelcase */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Box } from '@chakra-ui/react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { isEmpty } from 'lodash';
import TableViewWithSearch from '../../../components/Table/TableWithSearch';

import {
  fetchAllCards,
  selectAllCards,
} from '../../../redux/CardReducer/AllCardSlice';
import {
  enrichDataForEdit,
  generateOfferIdMap,
  generateTemplateIdMap,
} from './relation.helper';
import { columns } from './relation.constants';
import {
  deleteRelations,
  getRelationDefinition,
  getRelations,
  saveRelation,
  updateRelation,
} from './relation.service';
import EditRelation from './EditRelation';
import axiosInstance from '../../../utils/axios/axios';

const Relations = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line camelcase
  const { ticket_template_fields_list_view } = useSelector(
    (state) => state.viewState
  );

  const items = useSelector(selectAllCards);

  const [showEdit, setShowEdit] = useState(false);
  const [relations, setRelations] = useState([]);
  const [relationshipOptions, setRelationShipOptions] = useState([]);
  const [offers, setOffers] = useState([]);
  const [offerIdMap, setOfferIdMap] = useState({});
  const [isRelationLoading, setIsRelationLoading] = useState(false);

  // const isTemplateApiTriggered = useRef(false);
  const selectedRelation = useRef(null);

  const templateIdMap = useMemo(() => generateTemplateIdMap(items), [items]);

  useEffect(() => {
    axiosInstance
      .get(`fieldDefaults/list?$select=*&$filter=offer_type ne 'email'`)
      .then((response) => {
        const allOffers = response?.data?.rows || [];
        setOffers(allOffers);
      });
  }, []);

  useEffect(() => {
    setOfferIdMap(generateOfferIdMap(offers));
  }, [offers]);

  const onCreateOrEdit = useCallback(
    (rowData) => {
      selectedRelation.current = rowData
        ? enrichDataForEdit({
          templateIdMap,
          offerIdMap,
          data: rowData,
          id,
        })
        : null;
      setShowEdit(true);
    },
    [templateIdMap, offerIdMap, id]
  );

  const onDelete = useCallback((rowData) => {
    if (!rowData?.id) {
      return;
    }

    deleteRelations(rowData?.id).then((res) => {
      if (res) {
        setRelations((prev) => prev.filter((r) => r.id !== rowData?.id));
      }
    });
  }, []);

  const onSaveRelation = useCallback((r) => {
    setRelationShipOptions((prev) => [...prev, r]);
  }, []);

  const onSave = useCallback((payload, relationId) => {
    setIsRelationLoading(true);
    if (relationId) {
      updateRelation(relationId, payload).then((res) => {
        if (res) {
          setIsRelationLoading(false);
          setRelations((prev) =>
            prev.map((r) => {
              if (r?.id === relationId) {
                return res;
              }

              return r;
            })
          );
          setShowEdit(false);
        }
      });
    } else {
      saveRelation(payload).then((res) => {
        if (res) {
          setIsRelationLoading(false);
          setRelations((prev) => [...prev, res]);
          setShowEdit(false);
        }
      });
    }
  }, []);

  const navigateBack = useCallback(() => {
    setShowEdit(false);
  }, []);

  const getUpdateFields = (templateId) => {
    if (
      ticket_template_fields_list_view?.data?.length !==
      templateIdMap?.[templateId]?.fields?.length
    ) {
      // eslint-disable-next-line max-len, no-unsafe-optional-chaining
      const updatedObject = {
        ...templateIdMap[templateId],
        fields: [...(ticket_template_fields_list_view?.data || [])],
      };
      templateIdMap[templateId] = updatedObject;
    }
  };
  // Fetching all templates, if the list is empty
  useEffect(() => {
    if (isEmpty(items)) {
      dispatch(fetchAllCards());
    }
    // isTemplateApiTriggered.current = true;
  }, []);

  // Fetching all relations
  useEffect(() => {
    getRelations(id).then((res) => {
      setRelations(res);
    });

    getRelationDefinition().then((res) => {
      setRelationShipOptions(res);
    });
  }, [id]);

  useEffect(() => {
    if (ticket_template_fields_list_view?.data && templateIdMap?.[id]?.fields) {
      getUpdateFields(id);
    }
  }, [ticket_template_fields_list_view?.data, templateIdMap?.[id]?.fields, id]);

  return (
    <>
      {showEdit ? (
        <EditRelation
          relationData={selectedRelation.current}
          navigateBack={navigateBack}
          templateIdMap={templateIdMap}
          offers={offers}
          offerIdMap={offerIdMap}
          relationshipOptions={relationshipOptions}
          onSaveRelation={onSave}
          onCreateNewRelationship={onSaveRelation}
          isRelationLoading={isRelationLoading}
        />
      ) : null}

      {!showEdit ? (
        <Box py="15px" data-testid="RelationContainer">
          <TableViewWithSearch
            placeholder="Search"
            buttonText="Create New Relation"
            buttonVariant="solid"
            onCreateNew={() => {
              onCreateOrEdit(null);
            }}
            title="Relations"
            columns={columns({
              onEdit: onCreateOrEdit,
              onDelete,
              templateIdMap,
              offerIdMap,
              currentTemplateId: id,
              navigate,
            })}
            tableData={relations}
          />
        </Box>
      ) : null}
    </>
  );
};

export default Relations;
