/* eslint-disable no-restricted-syntax */
/* eslint-disable indent */
import {
  Alert,
  AlertIcon,
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Text,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { ArrowBackIcon } from '@chakra-ui/icons';
import LayoutTableView from './LayoutTableView';
import { columns } from './utils';
import { LAYOUT_VIEW_LIST, filterArrayByViewId, updateArray } from './helper';
// import CustomModal from '../../../components/Modal/Modal';
import CustomModalFooter from '../../TicketTemplateWorkflow/CreateEditFlow/CustomModalFooter';
import { COLORS, RULE_CARD_BORDER } from '../../../utils/enums/colors';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { getWholeOption } from '../../TicketTemplateWorkflow/helper';
import customStyleFromRuleForSelect from '../../../context/CustomTheme/CustomStyleFromRuleForSelect';
import { checkForV2 } from '../../../utils/helper';
import { updateViewState } from '../../../redux/ViewSlice/ViewReducer';
import {
  patchDataForTemplate,
  showToastMessage,
} from '../../TicketTemplateBuilder/utils/templateAPIUtils';
import { SCOPE_MAP, layoutViewTypes } from '../../../utils/enums/selectOption';
import { TITLE_LABELS } from '../../../utils/enums/labels';

const LayoutViewAndTransition = () => {
  const [tableDataState, setTableDataState] = useState([]);
  const [ViewLayoutMap, setViewLayoutMap] = useState([]);
  const [workflowTranstion, setWorkflowTranstion] = useState([]);
  const [layoutsState, setLayoutsState] = useState([]);
  const [layoutOption, setLayoutOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingNew, setLoadingNew] = useState(true);
  const dispatch = useDispatch();
  const [editLayoutModal, setEditLayoutModal] = useState({
    isOpen: false,
    viewName: '',
    layoutId: '',
    rowData: {},
  });
  const [isLoadingForApiCall, setIsLoadingForApiCall] = useState(false);

  const viewState = useSelector((state) => state.viewState || {});
  const selectedTemplate = viewState?.selectedTemplate;
  /* Changing state to set the show modal */
  const showModal = useCallback(
    ({ viewName = '', layoutId = '', rowData = {} }) => {
      setEditLayoutModal({
        isOpen: true,
        viewName,
        layoutId,
        rowData,
      });
    },
    []
  );

  /* Changing state to close modal */
  const closeModal = useCallback(() => {
    setEditLayoutModal({
      isOpen: false,
      viewName: '',
      layoutId: '',
      rowData: {},
    });
  }, []);
  const { id } = useParams();

  useEffect(() => {
    if (selectedTemplate) {
      if (!isEmpty(selectedTemplate?.view_layout_map)) {
        setViewLayoutMap(selectedTemplate?.view_layout_map);
        setLoadingNew(false)
      }
      if (!isEmpty(selectedTemplate?.workflow?.workflow_transitions)) {
        if (checkForV2()) {
          const newArray = selectedTemplate?.workflow?.workflow_transitions
            ?.filter((item, index) => index > 0)
            .map((item) => ({
              id: item.id,
              type: 'TRANSITION',
              label: item.data.label,
              linked_layout: null,
            }));
          setWorkflowTranstion(newArray);
        }
        setLoadingNew(false)
      }
      if (!isEmpty(selectedTemplate?.layouts)) {
        const newOption = selectedTemplate?.layouts?.map((item) => ({
          value: item.id,
          label: item.name,
          viewType: item.viewType,
        }));
        setLayoutsState(selectedTemplate?.layouts);
        setLayoutOption(newOption);
        setLoadingNew(false)
      }
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (isEmpty(workflowTranstion) && isEmpty(ViewLayoutMap)) {
      setTableDataState([...LAYOUT_VIEW_LIST]);
    } else if (isEmpty(ViewLayoutMap)) {
      const updatedTableData = [...LAYOUT_VIEW_LIST, ...workflowTranstion];
      setTableDataState(updatedTableData);
    } else {
      const updatedTableData = updateArray(
        [
          // eslint-disable-next-line no-unsafe-optional-chaining
          ...LAYOUT_VIEW_LIST?.map((i) => ({ ...i, linked_layout: null })),
          ...workflowTranstion,
        ],
        ViewLayoutMap
      );
      const newMap = filterArrayByViewId(ViewLayoutMap, updatedTableData);
      setViewLayoutMap(newMap);
      setTableDataState(updatedTableData);
    }
  }, [workflowTranstion, layoutsState]);

  const validateLayoutLinking = (sourceLayout, targetLayout) => {
    for (const sourceItem of sourceLayout.schema) {
      if (sourceItem.type === 'component') {
        const sourceFields = new Set(sourceItem.subItems.map((i) => i.id));

        for (const targetItem of targetLayout.schema) {
          if (
            targetItem.type === 'component' &&
            sourceItem.id !== targetItem.id
          ) {
            const targetFields = new Set(targetItem.subItems.map((i) => i.id));

            for (const field of sourceFields) {
              if (targetFields.has(field)) {
                return false; // Found a common value, return false
              }
            }
          }
        }
      }
    }
    return true; // No common value found, return true
  };

  const handleLinkLayoutToView = async () => {
    setLoading(true);
    const tempData = [...ViewLayoutMap];
    const tempTableData = [...tableDataState].map((item) => {
      if (item.id === editLayoutModal.rowData.id) {
        return editLayoutModal.rowData;
      }
      return item;
    });
    const index = tempData.findIndex(
      (data) => data.view_id === editLayoutModal.rowData.id
    );
    const newObj = {
      id: uuidv4(),
      type: editLayoutModal.rowData.type,
      view_id: editLayoutModal.rowData.id,
      layout_id: editLayoutModal.rowData.linked_layout,
    };
    if (index === -1) {
      tempData.push(newObj);
    } else {
      tempData[index] = newObj;
    }

    const createLayout = tempData?.filter((i) => i?.view_id === 'create')?.[0];

    if (createLayout && checkForV2()) {
      const checkValidationForCreate = (layouts, fields, layoutId) => {
        // Helper function to flatten subItems recursively
        const flattenSubItems = (item) => {
          if (item.type === 'component' && item.subItems) {
            return [item, ...item.subItems.flatMap(flattenSubItems)];
          }
          return [item];
        };

        const currentlayFields = layouts
          ?.filter((i) => i?.id === layoutId)[0]
          ?.schema?.flatMap((i) => flattenSubItems(i))
          ?.map((i) => i)
          ?.sort();

        const reqFields = fields
          ?.filter(
            (i) =>
              i?.type_based_attributes?.required &&
              i?.scope !== SCOPE_MAP.WORKFLOW &&
              i?.scope !== SCOPE_MAP.RELATION
          )
          ?.map((i) => i)
          ?.sort();

        const areAllRequiredFieldsPresent = (normalFields, requiredFields) => {
          // Check if all required fields are present in the normal array and no special fields
          const hasSpecialField = normalFields.some((field) =>
            field?.type?.startsWith('SF_')
          );
          const rf = requiredFields?.map((i) => i?.id);
          const nf = normalFields?.map((i) => i?.id);

          return !hasSpecialField && rf.every((field) => nf.includes(field));
        };
        return areAllRequiredFieldsPresent(currentlayFields, reqFields);
      };

      const allFieldsPresent = checkValidationForCreate(
        selectedTemplate?.layouts,
        selectedTemplate?.fields,
        createLayout?.layout_id
      );

      if (!allFieldsPresent) {
        showToastMessage({
          title: 'Validation Error',
          description:
            'Layout linked to Create view should have all required fields and cannot have any Special Fields.',
          status: 'error',
        });
        setLoading(false);
        setIsLoadingForApiCall(false);
        return;
      }
    }

    // Code For Versioning
    const isValidationRequired = !!ViewLayoutMap?.length;

    if (isValidationRequired && !checkForV2()) {
      const targetLayout = layoutsState?.filter(
        (ins) => ins?.id === editLayoutModal?.layoutId
      )[0];

      for (const viewLayout of ViewLayoutMap) {
        const sourceLayout = layoutsState?.filter(
          (ins) => ins.id === viewLayout.layout_id
        )[0];

        const validation = validateLayoutLinking(sourceLayout, targetLayout);

        if (!validation) {
          // Validation failed for a pair, show error message and return from function
          showToastMessage({
            title: 'Validation Error',
            description:
              'Fields from the one section cannot be placed in other sections of the linked layout.',
            status: 'error',
          });
          setLoading(false);
          setIsLoadingForApiCall(false);
          return; // Stop further processing
        }
      }
    }

    const res = await patchDataForTemplate({
      id,
      data: tempData,
      key: 'view_layout_map',
      defaultErrorMessage:
        index === -1
          ? 'Failed to link layout to View'
          : 'Failed to edit layout',
      successMessage:
        index === -1
          ? 'Successfully linked the layout to view'
          : 'Successfully edited the layout',
    });
    setLoading(false);
    setIsLoadingForApiCall(false);

    if (res.error) {
      closeModal();
    } else {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: res?.response?.data,
        })
      );
      setTableDataState([...tempTableData]);
      setViewLayoutMap([...tempData]);
      closeModal();
    }
  };
  const onDelete = async (rowData) => {
    const tempData = [...ViewLayoutMap];
    const selectedRow = [...tableDataState].map((item) => {
      if (item.id === rowData.id) {
        return { ...item, linked_layout: null };
      }
      return item;
    });
    const indexViewLayout = tempData.findIndex(
      (data) => data.view_id === rowData.id
    );
    if (indexViewLayout !== -1) {
      tempData.splice(indexViewLayout, 1);
    }
    const res = await patchDataForTemplate({
      id,
      data: tempData,
      key: 'view_layout_map',
      defaultErrorMessage: 'Failed to unlink layout',
      successMessage: 'Successfully unlinked the layout',
    });
    if (!res.error) {
      setViewLayoutMap(tempData);
      setTableDataState(selectedRow);
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: res?.response?.data,
        })
      );
    }
  };

  const filterArrayForLayout = (layoutsList, selectedView) => {
    if (selectedView?.type === 'TRANSITION') {
      return layoutsList.filter(
        (item) => item.viewType === layoutViewTypes.TRANSITION
      );
    }

    if (selectedView?.type === 'VIEW') {
      if (selectedView?.id === 'card') {
        return layoutsList.filter(
          (item) => item.viewType === layoutViewTypes.INBOX
        );
      }
      return layoutsList.filter(
        (item) => item.viewType === layoutViewTypes.GRID || !item.viewType
      );
    }

    return [];
  };

  if (!selectedTemplate?.layouts || !selectedTemplate?.layouts.length) {
    return (
      <Flex>
        <Alert status="info" mt="8" borderRadius="4px" alignItems="center">
          <AlertIcon />
          {TITLE_LABELS.NO_LAYOUTS}
        </Alert>
      </Flex>
    );
  }
  return (
    <Box
      data-testid="LayoutViewAndTransitionContainerBox"
      height="calc(100vh - 15rem)"
    >
      <LayoutTableView
        placeholder="Search for a view"
        isLoading={!tableDataState?.length}
        columns={columns(showModal, onDelete, layoutsState)}
        tableData={tableDataState}
        title="Linked layout"
      />

      <Drawer
        size="lg"
        placement="right"
        onClose={() => {
          if (editLayoutModal.isOpen) {
            closeModal();
          }
        }}
        isOpen={editLayoutModal.isOpen}
        data-testid="AddOrEditEmailTemplateDrawerEmail"
      >
        <DrawerContent>
          <DrawerHeader>
            <Flex
              alignItems="center"
              gap="10px"
              pb="10px"
              borderBottom={`1px solid ${RULE_CARD_BORDER}`}
            >
              <IconButton
                colorScheme="blue"
                aria-label="Close Drawer"
                size="sm"
                borderRadius="50%"
                minW="1.5rem"
                height="1.5rem"
                variant="outline"
                icon={<ArrowBackIcon />}
                onClick={() => {
                  if (editLayoutModal.isOpen) {
                    closeModal();
                  }
                }}
              />
              <Box style={{ fontSize: '16px', color: COLORS.DEFAULT }}>
                Link Layout
              </Box>
              <Text fontSize="16px">
                <Box style={{ fontSize: '16px', color: COLORS.DEFAULT }}>
                  {editLayoutModal?.viewName}
                </Box>
              </Text>
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <Grid
              templateColumns="repeat(12, 1fr)"
              data-testid="LayoutViewAndTransitionGrid"
            >
              <GridItem
                colSpan={12}
                data-testid="LayoutViewAndTransitionGridItem"
              >
                <CustomSelectBox
                  width="100%"
                  styles={customStyleFromRuleForSelect}
                  name=""
                  value={getWholeOption(editLayoutModal.layoutId, layoutOption)}
                  options={filterArrayForLayout(
                    layoutOption,
                    editLayoutModal?.rowData
                  )}
                  onChange={(e) => {
                    setEditLayoutModal((pre) => ({
                      ...pre,
                      layoutId: e.value,
                      rowData: { ...pre.rowData, linked_layout: e.value },
                    }));
                  }}
                  id="LayoutViewAndTransitionSelectViewBox"
                  label="Layout"
                  isMandatory
                />
              </GridItem>
            </Grid>
          </DrawerBody>
          <DrawerFooter>
            <CustomModalFooter
              onCancelClick={() => {
                closeModal();
              }}
              onAddClick={() => {
                setIsLoadingForApiCall(true);
                handleLinkLayoutToView();
              }}
              btnLabel="Link"
              isDisabled={!editLayoutModal.layoutId || loading}
              isLoading={isLoadingForApiCall}
            />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default LayoutViewAndTransition;
