import React, { useCallback, useState } from 'react';
import { Flex, Grid, GridItem, useColorModeValue } from '@chakra-ui/react';

import { useDispatch } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';

import CustomModal from '../../components/Modal/Modal';
import CustomTextArea from '../../components/Textarea/CustomTextArea';
import CustomToast from '../../components/Notification/Notification';

import { LABELS } from '../../utils/enums/labels';
import { setActiveModal } from '../../redux/ModalReducer/ModalSlice';
import { getAgentDomain, getTenantData } from '../../helpers/tenantUrl';
import { PrimaryCustomButton, SecondaryCustomButton } from '../../components/Button/PrimarySecondaryButton';

const FeedBackModal = ({ openFeedBackModal, setOpenFeedBackModal }) => {
  const dispatch = useDispatch();
  const { addToast } = CustomToast();

  const [feedBackText, setFeedBackText] = useState('');
  const [isLoadingForApiCall, setIsLoadingForApiCall] = useState(false);

  const onFeedbackSubmit = useCallback(async () => {
    const { tenantId, domain, id } = getTenantData();
    const name = localStorage.getItem('userName');
    const preferredUserName = localStorage.getItem('preferredUserName');
    const token = localStorage.getItem('kc_token');

    const payload = {
      subject: `Service Catalog - Feedback - ${tenantId} by ${
        preferredUserName || name
      }`,
      feedback: feedBackText,
      tenantName: tenantId || null,
      domain,
      user: preferredUserName || name,
    };

    const endpoint = `${getAgentDomain()}/feedback`;

    try {
      await axios.post(endpoint, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          'x-tenantid': id,
        },
      });
      setIsLoadingForApiCall(false);
      addToast({
        title: 'Success',
        message: 'Successfully submitted the feedback',
        type: 'success',
      });
    } catch (err) {
      setIsLoadingForApiCall(false);
      addToast({
        title: 'Something went wrong',
        message: 'Something went wrong while submitting the feedback',
        type: 'error',
      });
    }
    setIsLoadingForApiCall(false);
    // Closing the feedback modal
    setOpenFeedBackModal(false);
  }, [feedBackText, setOpenFeedBackModal]);

  return (
    <CustomModal
      id="selected-feedback-modal"
      data-testid="feedbackModal"
      modalTitle={
        <>
          <h1>Add Feedback</h1>
          {/* <Box className={styles.modalSubTitle}>{templateName}</Box> */}
        </>
      }
      modalContent={
        <Grid mt="38px" data-testid="FeedBackModalGrid">
          <GridItem w="100%" data-testid="FeedBackModalGridItem">
            <CustomTextArea
              id="feedBackModal"
              label="Please share your feedback"
              type="text"
              onChange={(e) => {
                setFeedBackText(e.target.value);
              }}
              errorMessage="name is already exist"
              value={feedBackText}
            />
          </GridItem>
        </Grid>
      }
      modalFooter={
        <Flex gap="8px" data-testid="FeedBackModalFlex">
          <SecondaryCustomButton
            buttonText={LABELS.ACTIONS.CANCEL}
            onClick={() => {
              setOpenFeedBackModal(false);
              dispatch(setActiveModal({ targetModalId: undefined }));
            }}
            id="feedbackModal_cancel_button"
          />
          <PrimaryCustomButton
            isDisabled={!feedBackText}
            buttonText="Submit"
            onClick={() => {
              setIsLoadingForApiCall(true);
              onFeedbackSubmit();
            }}
            id="feedbackModal_submit_btn"
            isLoading={isLoadingForApiCall}
          />
        </Flex>
      }
      open={openFeedBackModal}
      setOpen={() => {
        setOpenFeedBackModal(!openFeedBackModal);
        dispatch(setActiveModal({ targetModalId: undefined }));
      }}
    />
  );
};
FeedBackModal.propTypes = {
  openFeedBackModal: PropTypes.bool.isRequired,
  setOpenFeedBackModal: PropTypes.func.isRequired,
};
export default FeedBackModal;
