import axiosInstance from '../../../utils/axios/axios';

const allFieldseteUrl = 'fieldsets';

const getAllFieldsetsApi = async (options) => {
  const list = await axiosInstance.get(allFieldseteUrl, { params: options });
  return list.data;
};

const allFieldsetService = {
  getAllFieldsetsApi,
};

export default allFieldsetService;
