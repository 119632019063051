import React from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';
import CustomInput from '../../../components/InputBox/Input';
import TextAreaBox from '../../DynamicRenderer/TextArea';
import { DARK, LIGHT } from '../../../utils/enums/colors';
import CustomSelectBox from '../../../components/SelectBox/Select';
import CustomMention from '../../../components/Mention';
import {
  NOTIFY_ON_TYPES,
  NOTIFY_ON_TYPES_VALUE
} from '../../DynamicRenderer/customEventAndTriggers/customEventAndTriggers.constant';

const renderNotifyTeamSlackForm = (
  actionToPerform,
  handleChange,
  actionToPerformSelectedIndex,
  handleActionErrorData,
  linkedRelationItemId,
  actionErrorData
) => {
  const selectedValue = actionToPerform.notify_on && actionToPerform.notify_on.length > 0 && actionToPerform.notify_on?.map((item) => {return {label: item, value: item}})
  return (
<>
    <Box
      marginTop="20px"
      data-testid="DynamicRendererSlackBox"
    >
      <CustomSelectBox
        label="Notify On"
        options={NOTIFY_ON_TYPES}
        placeholder="select notify on"
        value={selectedValue}
        onChange={(value) => {
          const values = value && value.length > 0 ? value.map((item) => item.value) : []
          handleChange(
            values,
            `action_to_perform[${actionToPerformSelectedIndex}].notify_on`
          );
          handleActionErrorData('notify_on');
        }}
        focusBorderColor={useColorModeValue(LIGHT, DARK)}
        isError={!!actionErrorData?.notify_on}
        errorMessage={actionErrorData?.notify_on || ''}
        isMandatory
        isMulti={true}
        id="DynamicRendererSlackCSelect"
      />
    </Box>
    <Box
          marginTop="10px"
          data-testid="DynamicRendererAudienceBox"
        >
          <CustomMention
            label="Audience"
            value={actionToPerform.audience || ''}
            onChange={(e) => {
              handleChange(
                e,
                `action_to_perform[${actionToPerformSelectedIndex}].audience`
              );
              handleActionErrorData('audience');
            }}
            isError={!!actionErrorData?.to}
            errorMessage={actionErrorData?.to || ''}
            isMandatory={true}
            id="DynamicRenderer6DivCInput"
            linkedItemId={linkedRelationItemId}
            showQueueAndActorEmailOnly
          />
         </Box>
        {(actionToPerform.notify_on && actionToPerform.notify_on.length>0 && actionToPerform.notify_on.includes(NOTIFY_ON_TYPES_VALUE.TEAMS)) && (
        <Box marginTop="10px" data-testid="DynamicRendererTeamsNotifBox">
          <CustomMention
            placeholder="Notification Message for Teams"
            value={actionToPerform.teams_Payload || ''}
            label="Teams Notification"
            onChange={(value) => {
              handleChange(
                value,
                `action_to_perform[${actionToPerformSelectedIndex}].teams_Payload`
              );
              handleActionErrorData('teams_payload');
            }}
            isError={!!actionErrorData?.template}
            errorMessage={actionErrorData?.template || ''}
            isMulti
            isMandatory
            multiLine={true}
          />
        </Box>
        )}
        {(actionToPerform.notify_on && actionToPerform.notify_on.length>0 && actionToPerform.notify_on.includes(NOTIFY_ON_TYPES_VALUE.SLACK)) && (
        <Box marginTop="10px" data-testid="DynamicRendererSlackNotifBox">
          <CustomMention
            placeholder="Notification Message for Slack"
            value={actionToPerform.slack_Payload || ''}
            label="Slack Notification"
            onChange={(value) => {
              handleChange(
                value,
                `action_to_perform[${actionToPerformSelectedIndex}].slack_Payload`
              );
              handleActionErrorData('slack_Payload');
            }}
            isError={!!actionErrorData?.template}
            errorMessage={actionErrorData?.template || ''}
            isMulti
            isMandatory
            multiLine={true}
          />
        </Box>
        )}
</>
);
}

export default renderNotifyTeamSlackForm;
