import { isEmpty } from "lodash";
export const lastTenSelectedFlowSessionKey = 'lastTenSelectedFlow'

export const isWorkFlowUpdated = (initialWorkflow, updatedWorkFlow) => {
    delete initialWorkflow.updated_on
    delete updatedWorkFlow.updated_on
    if (JSON.stringify(initialWorkflow) === JSON.stringify(updatedWorkFlow)) return false;
    return true;
}

export const handleLastTenWorkFLowState =(updatedWorkFlow, id)=>{
    let lastTenFlowState = JSON.parse(sessionStorage.getItem(lastTenSelectedFlowSessionKey))
    //incase change work flow
    if(lastTenFlowState?.selectedWorkflowID!=id){ 
      sessionStorage.removeItem(lastTenSelectedFlowSessionKey)
      lastTenFlowState = null;
    };
    
    if (!updatedWorkFlow || isEmpty(updatedWorkFlow)) return;
    
    if (!lastTenFlowState || isEmpty(lastTenFlowState)) {
      sessionStorage.setItem(lastTenSelectedFlowSessionKey, JSON.stringify({selectedWorkflowID:id, undoIndex:0 ,lastTenWorkflowState:[updatedWorkFlow]}));
      return
    }
    const { undoIndex } = lastTenFlowState
    
    // if work flow is not updated then return
    if (!isWorkFlowUpdated({ ...lastTenFlowState?.lastTenWorkflowState[lastTenFlowState.undoIndex] }, { ...updatedWorkFlow })) return;

    // if change in work flow then push it to array
    function pushWithLimit(array, data, limit) {
      // Push new data to the beginning of the array
      array[ undoIndex + 1 ] = data;
      array.length = undoIndex + 2

      // If array length exceeds the limit, remove the last element
      if (array.length > limit) {
        array.shift();
      }

      return array;
    }
    sessionStorage.setItem(lastTenSelectedFlowSessionKey, JSON.stringify({ ...lastTenFlowState, undoIndex: undoIndex + 1 ,lastTenWorkflowState: pushWithLimit(lastTenFlowState?.lastTenWorkflowState, updatedWorkFlow, 10)}))
}


