import { LABELS } from '../../../utils/enums/labels';

const createBaseSettings = (data, setFields) => ({
  row1: [
    {
      label: LABELS.TEMPLATE_PROPERTIES.NAME,
      value: `${data?.name || ''}`,
      placeholder: '',
      onChange: (value) => setFields({ ...data, name: value }),
    },
  ],
  row2: [
    {
      id: 3,
      label: LABELS.TEMPLATE_PROPERTIES.DESCRIPTION,
      value: data?.description,
      placeholder: '',
      onChange: (value) => setFields({ ...data, description: value }),
    },
  ],
});

export default createBaseSettings;
