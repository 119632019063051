/* eslint-disable import/no-cycle */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-unstable-nested-components */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList as List } from 'react-window';
import { useParams } from 'react-router-dom';
import InfiniteLoader from 'react-window-infinite-loader';
import { ReactSVG } from 'react-svg';
import { Box, useColorModeValue } from '@chakra-ui/react';
import CustomToolTip from '../../components/Tooltip/Tooltip';
import TreeNodeList from './TreeNodeView';
import { childTemplateApi } from './utils';
import Circle from '../../assets/icons/Circle.svg';
import {
  BLACK,
  COLORS,
  DARK,
  LIGHT,
  SELECT_DARK,
  SELECT_LIGHT,
  WHITE,
} from '../../utils/enums/colors';
import styles from './crudStyle.module.scss';

const ReactWindowComponent = ({
  hasNextPage,
  isNextPageLoading,
  items,
  loadNextPage,
  height,
  columns,
  onClickListItem,
}) => {
  const listRef = useRef(null);
  const itemCount = hasNextPage ? items?.length + 1 : items?.length;
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;
  const isItemLoaded = (index) => !hasNextPage || index < items.length;
  const { id } = useParams();
  const isNested = false;

  // eslint-disable-next-line react/prop-types
  const Item = ({ index, style }) => {
    let content;
    let particularItem;
    if (!isItemLoaded(index)) {
      content = 'Loading...';
      particularItem = {};
    } else {
      content = items[index][columns] || items[index].name;
      particularItem = items[index];
    }

    content = (
      <CustomToolTip id="tooltip-list-short" toolTipText={content}>
        <span
          className="ellipsis ml-2"
          onClick={() => {
            if (items[index].id) onClickListItem(items[index]);
          }}
        >
          {content}
        </span>
      </CustomToolTip>
    );

    // return <div style={style}>{content}</div>;

    return (
      <div style={style}>
        {!isNested ? (
          <Box
            display="flex"
            _hover={{
              bg: COLORS.BG_LIGHT,
              color: useColorModeValue('black', 'white'),
            }}
            bg={
              particularItem?.id === id
                ? useColorModeValue(SELECT_DARK, SELECT_LIGHT)
                : ''
            }
            color={
              particularItem?.id === id
                ? useColorModeValue(LIGHT, DARK)
                : useColorModeValue(BLACK, WHITE)
            }
          >
            <ReactSVG src={Circle} className={styles.circle} />
            {content}
          </Box>
        ) : (
          isItemLoaded(index) && (
            <TreeNodeList
              node={items[index] || {}}
              childKey="properties.child_templates_list"
              onClickListItem={onClickListItem}
              loadChildItems={childTemplateApi}
              columns={columns}
            />
            // eslint-disable-next-line indent
          )
        )}
      </div>
    );
  };

  return (
    <InfiniteLoader
      ref={listRef}
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
      threshold={1}
    >
      {({ onItemsRendered, ref }) => (
        <List
          className="List"
          height={height || 800}
          itemCount={itemCount}
          itemSize={30}
          onItemsRendered={onItemsRendered}
          ref={ref}
          width="100%"
        >
          {Item}
        </List>
      )}
    </InfiniteLoader>
  );
};

ReactWindowComponent.propTypes = {
  hasNextPage: PropTypes.bool.isRequired,
  isNextPageLoading: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  loadNextPage: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
  columns: PropTypes.string.isRequired,
  onClickListItem: PropTypes.func.isRequired,
};

export default ReactWindowComponent;
