/* eslint-disable react/prop-types */
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React from 'react';

const PreviewTable = ({ data }) => {
  const columns = data;
  return (
    <TableContainer
      borderRadius="10px 10px 0px 0px"
      borderWidth="1.5px"
      fontSize="14px"
      width="100%"
      margin="2%"
      //   borderColor={useColorModeValue(BORDER_LIGHT, BORDER_DARK)}
      //   color={useColorModeValue(COLORS.BLACK, COLORS.TEXT_DARK)}
    >
      <Table variant="simple">
        <Thead>
          <Tr fontWeight="bold">
            {columns.map((column) => (
              <Th
                key={column?.col_name}
                width={`${100 / columns.length}%`}
                textAlign={column.align ? column.align : 'start'}
                p="18px"
                fontWeight="700"
              >
                {column?.label}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            {columns.map((column, index) => (
              <Td>{index}</Td>
            ))}
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default PreviewTable;
