/* eslint-disable indent */
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _omitBy from 'lodash/omitBy';
import _cloneDeep from 'lodash/cloneDeep';
import _flattenDeep from 'lodash/flattenDeep';
import _find from 'lodash/find';
import _get from 'lodash/get';
import { v4 as uuidv4 } from 'uuid';

import axiosInstance from '../../../utils/axios/axios';
import {
  TRIGGER_TYPES,
  MANUAL_TRIGGERS,
  TIME_TRIGGERS,
  ACTION_TYPE,
  SCRIPT_TYPE,
  NOTIFY_ON_TYPES,
  STATUS_TYPE,
  ACTION_TYPE_VALUE,
  MANUAL_TRIGGERS_V1,
  approvalOptionsValues,
  approvalOptions,
} from './customEventAndTriggers.constant';
import { checkForV2 } from '../../../utils/helper';
import { emailSignatureOption } from '../../../utils/enums/selectOption';
import { patchDataForTemplate } from '../../TicketTemplateBuilder/utils/templateAPIUtils';
import { makeOptionValue } from '../../../utils/helper/fieldHelper';

export const updateTemplate = async ({
  data,
  id,
  key = 'events',
  defaultErrorMessage,
  successMessage,
}) => {
  const res = await patchDataForTemplate({
    id,
    data,
    key,
    defaultErrorMessage,
    successMessage,
  });

  return res;
};

export const getTemplateById = async (id) => {
  const response = await axiosInstance.get(
    `/templates/list?$top=1&$skip=0&$select=name,id,fields&$filter=id eq ${id}`
  );
  if (response.status === 200) {
    return response.data;
  }
  return [];
};

export const getTriggers = (eventSetting) => {
  if (!checkForV2()) {
    return MANUAL_TRIGGERS_V1;
  }
  if (eventSetting?.trigger_type?.value === TRIGGER_TYPES[0].value) {
    return MANUAL_TRIGGERS;
  }
  return TIME_TRIGGERS;
};

export const getTransitions = (selectedTemplate) => {
  const workflowStatus = selectedTemplate?.workflow?.workflow_status;
  const workflowTransitions = selectedTemplate?.workflow?.workflow_transitions;
  const transitions = _map(workflowTransitions, (transition) => {
    const transitionState = _get(transition, 'data.label');
    const fromState = _find(workflowStatus, { id: transition.source });
    const toState = _find(workflowStatus, { id: transition.target });
    return {
      label: `${transitionState}  (${fromState?.data?.label} -> ${toState?.data?.label})`,
      value: transition.id,
    };
  });
  if (!_isEmpty(transitions)) transitions.shift();
  return transitions;
};

export const getSignatures = (selectedTemplate) =>
  _map(selectedTemplate?.actors, (actor) => ({
    value: actor.id,
    label: actor.name,
  }));

export const createPayload = (template, eventSettings) => {
  const events = _map(eventSettings, (es) => {
    const event = _cloneDeep(es);
    if (event.temp) delete event.temp;
    event.id = event?.id || uuidv4();
    event.trigger_type = !_isEmpty(event?.trigger_type)
      ? event.trigger_type?.value
      : '';
    event.trigger = !_isEmpty(event?.trigger) ? event.trigger?.value : '';
    event.transition_name = event.transition_name
      ? event?.transition_name?.value
      : '';
    event.action_to_perform = _map(event?.action_to_perform, (atp) => {
      if (atp.temp) delete atp.temp;
      atp.id = atp?.id || uuidv4();
      atp.action_type = atp && atp.action_type ? atp?.action_type?.value : '';

      // Encoding the email template
      if (atp.action_type === ACTION_TYPE_VALUE.SEND_EMAIL) {
        atp.template = atp.template ? atp.template : '';
        atp.cc = atp.cc ? atp.cc : '';
        atp.bcc = atp.bcc ? atp.bcc : '';
      }
      if (atp.action_type === ACTION_TYPE_VALUE.INITIATE_APPROVAL_FLOW) {
        atp.type_of_approval = atp?.type_of_approval?.value || '';
        if (atp?.type_of_approval === approvalOptionsValues.flat) {
          delete atp?.levels;
          delete atp?.hierarchical_permitted_roles;
        }
        if (atp?.type_of_approval === approvalOptionsValues.heirarchical) {
          delete atp?.number_of_approvals_required;
          delete atp?.flat_permitted_roles;
        }
      }
      if (atp.action_type === ACTION_TYPE_VALUE.QUEUE_ASSIGNMENT) {
        atp.queue_field = atp?.queue_field?.value || '';
      }
      if (atp.action_type === ACTION_TYPE_VALUE.ACTOR_ASSIGNMENT) {
        atp.actor_field = atp?.actor_field?.value || '';
      }

      // atp.signature = atp && atp.signature ? atp?.signature?.value : '';
      atp.notify_on = atp && atp.notify_on ? atp?.notify_on?.value : '';
      atp.status = atp && atp.status ? atp?.status?.value : '';
      atp.issue_type = atp && atp.issue_type ? atp?.issue_type?.value : '';
      atp.script_type = atp && atp.script_type ? atp?.script_type?.value : '';
      atp.update_record =
        atp && atp.update_record
          ? _map(atp.update_record, (ur) => {
              ur.field = ur?.field?.value;
              return ur;
            })
          : null;
      return atp;
    });
    return event;
  });
  let payload = {
    events,
  };
  payload = _omitBy(payload, (v) => !v);
  return payload;
};

export const findAndCreateOptionObject = (
  options,
  identifier,
  valueKey,
  labelKey
) => {
  const option = options?.find((f) => f?.id === identifier);
  if (option) {
    return { value: option[valueKey], label: option[labelKey] };
  }
  return undefined;
};

export const transformAPIData = (APIResponse, templates) => {
  const newEvents = _cloneDeep(APIResponse?.events?.events);
  const events = _map(newEvents, (es) => {
    const tiggerType = es.trigger_type;
    es.id = es?.id || uuidv4();
    es.trigger_type = !_isEmpty(es?.trigger_type)
      ? TRIGGER_TYPES.find((t) => t.value === es.trigger_type)
      : undefined;
    es.transition_name = !_isEmpty(es?.transition_name)
      ? getTransitions(APIResponse).find((t) => t.value === es?.transition_name)
      : undefined;
    if (!_isEmpty(es?.trigger)) {
      let option;
      if (tiggerType === TRIGGER_TYPES[0].value) {
        option = MANUAL_TRIGGERS.find((t) => t.value === es?.trigger);
      } else {
        option = TIME_TRIGGERS.find((t) => t.value === es.trigger);
      }

      es.trigger = option;
    }
    es.action_to_perform = _map(es?.action_to_perform, (atp) => {
      if (atp && atp.action_type) {
        atp.action_type = ACTION_TYPE.find((t) => t.value === atp?.action_type);
        // atp.signature = findAndCreateOptionObject(
        //   APIResponse?.actors,
        //   atp?.signature,
        //   'id',
        //   'name'
        // );
        atp.id = atp?.id || uuidv4();
        atp.signature = emailSignatureOption.find(
          (t) => t.value === atp?.signature
        );
        // Decoding the Email Template
        atp.template = atp.template ? atp.template : '';
        atp.cc = atp.cc ? atp.cc : '';
        atp.bcc = atp.bcc ? atp.bcc : '';
        atp.queue_field = makeOptionValue(
          atp?.queue_field,
          APIResponse?.fields
        );
        atp.actor_field = makeOptionValue(
          atp?.actor_field,
          APIResponse?.fields
        );
        atp.notify_on = NOTIFY_ON_TYPES.find((t) => t.value === atp?.notify_on);
        atp.type_of_approval = approvalOptions.find(
          (t) => t.value === atp?.type_of_approval
        );
        atp.status = STATUS_TYPE.find((t) => t.value === atp?.status);
        atp.issue_type = templates?.find((t) => t.value === atp?.issue_type);
        atp.script_type = SCRIPT_TYPE?.find(
          (t) => t.value === atp?.script_type
        );
        atp.update_record =
          atp?.update_record &&
          atp.update_record?.map((record) => {
            const newField = APIResponse?.fields?.find(
              (f) => f?.id === record.field
            );
            record.field = { value: newField?.id, label: newField.label };
            return record;
          });
      }
      return atp || {};
    });
    return es;
  });
  return events;
};

export const getTemplates = (viewState) => {
  const templates = viewState?.ticket_template_list_short_view?.data?.map(
    (template) => ({
      value: template.id,
      label: template.name,
    })
  );

  return templates?.filter((x) => x);
};

export const getTransformedChildTemplatesForUI = (id, callback) => {
  getTemplateById(id).then((templates) => {
    const childTemplates = templates?.rows.map((t) => {
      if (_isEmpty(t)) return null;
      return { value: t.id, label: t.name };
    });
    if (typeof callback === 'function') {
      callback(childTemplates?.filter((x) => x));
    }
  });
};

export const getTransformedChildTemplatesFieldsForUI = (
  currentTemplate,
  actionToPerform,
  callback
) => {
  let fields;
  const createFieldsOptions = (id) => {
    getTemplateById(id).then((templates) => {
      if (templates?.count === 1) {
        fields = templates?.rows[0].fields?.map((t) => {
          if (_isEmpty(t)) return null;
          return { value: t.id, label: t.label };
        });
        fields = _flattenDeep(fields);
        if (typeof callback === 'function') {
          callback(fields?.filter((x) => x));
        }
      }
    });
  };
  if (actionToPerform?.action_type?.value === ACTION_TYPE_VALUE.CREATE_ISSUES) {
    currentTemplate?.properties?.child_templates_list?.forEach((id) => {
      if (actionToPerform.issue_type?.value === id) {
        createFieldsOptions(id);
      }
      callback([]);
    });
  } else {
    createFieldsOptions(currentTemplate?.id);
  }
};

export const getTemplate = (viewState) => {
  const splitedPathName = window.location.pathname.split('/');
  const templateId = splitedPathName[splitedPathName.length - 1];
  const templateData = viewState?.ticket_template_list_short_view?.data?.filter(
    (d) => d.id === templateId
  );
  if (templateData && templateData.length > 0) {
    return transformAPIData(
      templateData[0],
      getTransformedChildTemplatesForUI(templateData[0])
    );
  }
  return {};
};

export const getFields = (viewState) => {
  const fieldsList = viewState?.ticket_template_fields_list_view?.data?.map(
    (field) => {
      if (field?.id) {
        return {
          value: field.id,
          label: field.label,
        };
      }
      return null;
    }
  );

  return fieldsList?.filter((field) => field);
};
