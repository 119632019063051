/* eslint-disable react/no-children-prop */
import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { InputGroup, InputRightElement, Input } from '@chakra-ui/input';
import { CalendarIcon } from '@chakra-ui/icons';
import { useColorModeValue } from '@chakra-ui/react';
import { DARK, LIGHT } from '../../utils/enums/colors';

const customDateInput = ({ value, onClick, onChange, inputHeight }, ref) => (
  <InputGroup>
    <Input
      autoComplete="off"
      value={value}
      ref={ref}
      onClick={onClick}
      onChange={onChange}
      fontSize="sm"
      height={inputHeight}
      focusBorderColor={useColorModeValue(LIGHT, DARK)}
    />
    <InputRightElement >
    <CalendarIcon fontSize="sm" />
        </InputRightElement>
  </InputGroup>
);
customDateInput.displayName = 'DateInput';

const CustomInput = forwardRef(customDateInput);

const CustomDatepicker = ({
  onChange,
  dateFormat,
  id,
  selectedDate,
  inputHeight,
  styleForRightInput,
  ...props
}) => (
    <DatePicker
      wrapperClassName="w-full"
      data-testid={id}
      selected={selectedDate}
      onChange={onChange}
      dateFormat={dateFormat}
      customInput={<CustomInput inputHeight={inputHeight} />}
      {...props}
    />
);

CustomDatepicker.propTypes = {
  id: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired,
  selectedDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  inputHeight: PropTypes.string,
  styleForRightInput: PropTypes.object,
};
CustomDatepicker.defaultProps = {
  selectedDate: '',
  inputHeight: '40px',
  styleForRightInput: {},
};

export default CustomDatepicker;
