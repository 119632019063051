// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vLleE8n01WcKjZyIBnvb .qd4ey55iR6MSFxADitCw{color:#374151 !important;font-size:14px !important;font-weight:500 !important;line-height:20px !important}`, "",{"version":3,"sources":["webpack://./src/components/InputBox/input.module.scss"],"names":[],"mappings":"AAGA,4CACQ,wBAAA,CACA,yBAAA,CACA,0BAAA,CACA,2BAAA","sourcesContent":["\n$gray-700: #374151;\n$blue: #2563EB;\n.inputBox .label{\n        color: $gray-700 !important;\n        font-size: 14px !important;\n        font-weight: 500 !important;\n        line-height: 20px !important;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputBox": `vLleE8n01WcKjZyIBnvb`,
	"label": `qd4ey55iR6MSFxADitCw`
};
export default ___CSS_LOADER_EXPORT___;
