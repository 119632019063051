/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useLocation, useParams } from 'react-router-dom';
import AddNewFieldModal from '../TicketTemplateBuilder/AddNewFieldModal';
import CreateEditFieldModal from '../TicketTemplateBuilder/CreateEditFieldModal';
import { setActiveModal } from '../../redux/ModalReducer/ModalSlice';
import DeleteModal from './DeleteModal';
import ModalView from './ModalView';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import ModalMutliTabView from './multiTabView';
import AddFieldsetContent from '../TicketTemplateBuilder/AddFieldsetsModal/AddFieldsetContent';
import AddEditActorModal from '../TicketTemplateBuilder/AddEditActorModal';
import CustomTemplateModal from './customTemplateModal/CustomTemplateModal';
import { FieldTypes } from '../../utils/enums/types';
import { LABELS } from '../../utils/enums/labels';
import CustomTemplateDeleteModal from './customTemplateModal/CustomTemplateDeleteModal';
import CustomTemplateJSONViewer from './customTemplateModal/CustomTemplateJsonViewModal';
import WorkflowConfigModal from '../TicketTemplateBuilder/WorkflowConfigModal';
import AddFieldFromMasterList from './customTemplateModal/AddFieldFromMasterListModal';
import AddEditWorkflowList from '../TicketTemplateWorkflow/AddEditWorkflowList';
import CloneTemplate from './customTemplateModal/CloneTemplate';
import CrudCloneTemplate from '../../components/CrudCloneTemplateModal';
import AddEditSignatureDrawer from './AddEditSignatureDrawer';

// eslint-disable-next-line no-unused-vars
const DynamicModalRenderer = () => {
  const [openModal, setOpenModal] = useState(true);
  const [openFieldsModal, setOpenFieldsModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [openFieldsetModal, setOpenFieldsetModal] = useState(false);
  const [type, setType] = useState('');
  const [openCreateFieldModal, setOpenCreateFieldModal] = useState(false);
  const [openEditFieldModal, setOpenEditFieldModal] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [openTemplateDeleteModal, setOpenTemplateDeleteModal] = useState(false);
  const [openTemplateJsonModal, setOpenTemplateJsonModal] = useState(false);
  const [openFieldsetsModal, setFieldsetsModal] = useState(false);
  const [openTemplateCloneModal, setOpenTemplateCloneModal] = useState(false);
  const [openAddActorInTemplateModal, setOpenAddActorInTemplateModal] =
    useState(false);
  const [openEditActorInTemplateModal, setOpenEditActorInTemplateModal] =
    useState(false);
  const [openEditCustomTemplateModel, setEditCustomTemplateModel] =
    useState(false);
  const [fieldMasterModal, setFieldMasterModal] = useState(false);

  // workflow config
  const [isConfigWorkflowModalOpen, setIsConfigWorkflowModalOpen] =
    useState(false);
  // add workflow master list modal
  const [openAddEditWorkflowModal, setOpenAddEditWorkflowModal] =
    useState(false);
  // edit workflow master list modal
  const [openEditWorkFlowModal, setOpenEditWorkFlowModal] = useState(false);
  const [openCrudTemplateCloneModal, setCrudTemplateCloneModal] =
    useState(false);
  const [openAddSignatureDrawer, setOpenAddSignatureDrawer] = useState(false);
  const [openEditSignatureDrawer, setOpenEditSignatureDrawer] = useState(false);
  const dispatch = useDispatch();
  const { allModalsList = [], activeModal = false } = useSelector(
    (state) => state.allModals
  );
  const viewState = useSelector((state) => state.viewState);
  const { editFieldModal } = useSelector((state) => state.viewState);
  const { editActorInTemplateModal, editWorkFlowInListModal } = useSelector(
    (state) => state.viewState
  );

  const fieldData = editFieldModal;

  const location = useLocation();
  const { id } = useParams();
  const { name } = queryString.parse(location.search);

  const onModalClose = () => {
    if (activeModal) {
      dispatch(setActiveModal({ activeModal: undefined }));
      dispatch(
        updateViewState({
          stateKey: activeModal,
          value: undefined,
        })
      );
    }
  };

  useEffect(() => {
    switch (activeModal) {
      case 'addFieldsModal':
        setOpenFieldsModal(true);
        break;
      case 'editFieldModal':
        setOpenEditFieldModal(true);
        break;
      case 'deleteModal':
        setDeleteModal(true);
        break;
      case 'edit_custom_template_modal':
        setEditCustomTemplateModel(true);
        break;
      case 'addActorInTemplateModal':
        setOpenAddActorInTemplateModal(true);
        break;
      case 'editActorInTemplateModal':
        setOpenEditActorInTemplateModal(true);
        break;
      case 'deleteTemplateModal':
        setOpenTemplateDeleteModal(true);
        break;
      case 'templateJsonModal':
        setOpenTemplateJsonModal(true);
        break;
      case 'workflowConfigModal':
        setIsConfigWorkflowModalOpen(true);
        break;
      case 'addFieldFromMaster':
        setFieldMasterModal(true);
        break;
      case 'addWorkFlowInListModal':
        setOpenAddEditWorkflowModal(true);
        break;
      case 'editWorkFlowInListModal':
        setOpenEditWorkFlowModal(true);
        break;
      case 'templateCloneModal':
        setOpenTemplateCloneModal(true);
        break;
      case 'crudTemplateCloneModal':
        setCrudTemplateCloneModal(true);
        break;
      case 'addSignatureDrawer':
        setOpenAddSignatureDrawer(true);
        break;
      case 'editSignatureDrawer':
        setOpenEditSignatureDrawer(true);
        break;
      default:
        setOpenModal(true);
    }
  }, [activeModal]);

  useEffect(() => {
    if (!openModal) {
      onModalClose();
    }
  }, [openModal]);

  useEffect(() => {
    if (!openEditFieldModal) {
      onModalClose();
    }
    if (!openModal) onModalClose();
  }, [openModal]);

  useEffect(() => {
    if (!openEditFieldModal) onModalClose();
  }, [openEditFieldModal]);

  useEffect(() => {
    if (!openFieldsModal) {
      onModalClose();
    }
  }, [openFieldsModal]);
  useEffect(() => {
    if (!openFieldsetModal) {
      onModalClose();
    }
  }, [openFieldsetModal]);

  useEffect(() => {
    if (
      !openTemplateDeleteModal ||
      !openTemplateJsonModal ||
      !fieldMasterModal ||
      !openTemplateCloneModal ||
      !openAddSignatureDrawer ||
      !openEditSignatureDrawer
    ) {
      onModalClose();
    }
  }, [
    openTemplateDeleteModal,
    openTemplateJsonModal,
    fieldMasterModal,
    openTemplateCloneModal,
    openAddSignatureDrawer,
    openEditSignatureDrawer,
  ]);

  useEffect(() => {
    if (!openEditCustomTemplateModel) {
      onModalClose();
    }
  }, [openEditCustomTemplateModel]);

  useEffect(() => {
    if (type) {
      setOpenFieldsModal(false);
      setOpenCreateFieldModal(true);
    }
    if (type === 'FIELDSETS') {
      setFieldsetsModal(true);
      setOpenCreateFieldModal(false);
    }
  }, [type]);

  useEffect(() => {
    if (!openCreateFieldModal) {
      setType('');
    }
  }, [openCreateFieldModal]);

  useEffect(() => {
    if (!openEditFieldModal) {
      onModalClose();
    }
    if (!openAddActorInTemplateModal) {
      onModalClose();
    }
    if (!openEditActorInTemplateModal) {
      onModalClose();
    }
    if (!isConfigWorkflowModalOpen) {
      onModalClose();
    }
    if (!openAddEditWorkflowModal) {
      onModalClose();
    }
    if (!openEditWorkFlowModal) {
      onModalClose();
    }
    if (!openModal) onModalClose();
  }, [openModal]);

  useEffect(() => {
    if (!openModal) {
      onModalClose();
    }
  }, [openModal]);

  return (
    <div data-testid="DynamicModalRendererContainerDiv">
      {allModalsList?.map((item) => {
        if (item?.props?.modalId === activeModal) {
          if (item?.props?.type === 'modal_multi_tab') {
            return (
              <ModalMutliTabView
                name={item?.props.name}
                isOpen={item?.props?.modalId === activeModal && openModal}
                setIsOpen={setOpenModal}
                viewLayout={item?.props.viewLayout}
                modalId={item?.props?.modalId}
                onModalClose={onModalClose}
                modalTitle={item?.props?.modalTitle}
                key={item?.props?.modalId}
                isEdit={item?.props?.isEdit}
                tabIds={item?.props?.tabIds}
                mandatoryFields={item?.props?.mandatoryFields}
                refreshListsAfterSave={item?.props?.refreshListsAfterSave}
              >
                {item?.props?.children}
              </ModalMutliTabView>
            );
          }
          return (
            <ModalView
              name={item?.props.name}
              isOpen={item?.props?.modalId === activeModal && openModal}
              setIsOpen={setOpenModal}
              viewLayout={item?.props.viewLayout}
              modalId={item?.props?.modalId}
              onModalClose={onModalClose}
              modalTitle={item?.props?.modalTitle}
              key={item?.props?.modalId}
              isEdit={item?.props?.isEdit}
              mandatoryFields={item?.props?.mandatoryFields}
            >
              {item?.props?.children}
            </ModalView>
          );
        }
      })}

      {openFieldsetsModal && (
        <AddFieldsetContent
          isOpen={openFieldsetsModal}
          setIsOpen={setFieldsetsModal}
          onModalClose={onModalClose}
          title="Delete Modal"
        />
      )}

      <AddNewFieldModal
        isAddField={openFieldsModal}
        setIsAddField={setOpenFieldsModal}
        onClick={setType}
      />
      {openCreateFieldModal && (
        <CreateEditFieldModal
          type={type}
          setType={setType}
          isFieldModel={openCreateFieldModal}
          setIsfieldModel={setOpenCreateFieldModal}
          templateName={name}
          modalFrom="Templates"
          setIsAddField={setOpenFieldsModal}
        />
      )}
      {openEditFieldModal && (
        <CreateEditFieldModal
          type={fieldData.type}
          setType={setType}
          fieldData={viewState[activeModal]}
          edit
          isFieldModel={openEditFieldModal}
          setIsfieldModel={setOpenEditFieldModal}
          templateName={name}
        />
      )}
      {openEditCustomTemplateModel && (
        <CustomTemplateModal
          type={FieldTypes.TEXT}
          templateName={LABELS.ACTIONS.CREATE_NEW_TEMPLATE}
          isEdit
          isOpenLayoutModal={openEditCustomTemplateModel}
          setIsOpenLayoutModal={setEditCustomTemplateModel}
        />
      )}
      {openDeleteModal && (
        <DeleteModal
          isOpen={openDeleteModal}
          setIsOpen={setDeleteModal}
          onModalClose={onModalClose}
          title="Delete Modal"
          fieldData={viewState[activeModal]}
          labelKey={
            viewState[activeModal] ? viewState[activeModal].labelKey : ''
          }
          masterType={
            viewState[activeModal] ? viewState[activeModal].masterType : ''
          }
          listKey={viewState[activeModal] ? viewState[activeModal].listKey : ''}
          targetUrl={
            viewState[activeModal] ? viewState[activeModal].targetUrl : ''
          }
          id={viewState[activeModal] ? viewState[activeModal].id : ''}
          method={viewState[activeModal] ? viewState[activeModal].method : ''}
          patchFilterId={viewState[activeModal]?.method ? id : ''}
          linkedType={
            viewState[activeModal]?.linkedType
              ? viewState[activeModal]?.linkedType
              : ''
          }
        />
      )}
      {openAddActorInTemplateModal && (
        <AddEditActorModal
          isAddEditActorModal={openAddActorInTemplateModal}
          setIsAddEditActorModal={setOpenAddActorInTemplateModal}
        />
      )}
      {openEditActorInTemplateModal && (
        <AddEditActorModal
          edit
          isAddEditActorModal={openEditActorInTemplateModal}
          setIsAddEditActorModal={setOpenEditActorInTemplateModal}
          initStateForActor={editActorInTemplateModal}
        />
      )}
      {openTemplateDeleteModal && (
        <CustomTemplateDeleteModal
          isDeleteModal={openTemplateDeleteModal}
          setIsDeleteModal={setOpenTemplateDeleteModal}
          templateName={name}
          templateId={id}
        />
      )}
      {openTemplateJsonModal && (
        <CustomTemplateJSONViewer
          isJsonModal={openTemplateJsonModal}
          setIsJsonModal={setOpenTemplateJsonModal}
          templateName={name}
          templateId={id}
        />
      )}
      {isConfigWorkflowModalOpen && (
        <WorkflowConfigModal
          isConfigModalOpen={isConfigWorkflowModalOpen}
          setIsConfigModalOpen={setIsConfigWorkflowModalOpen}
          templateName={name}
        />
      )}
      {fieldMasterModal && (
        <AddFieldFromMasterList
          isOpen={fieldMasterModal}
          setIsOpen={setFieldMasterModal}
        />
      )}
      {openAddEditWorkflowModal && (
        <AddEditWorkflowList
          isAddEditWorkflowModal={openAddEditWorkflowModal}
          setIsAddEditWorkflowModal={setOpenAddEditWorkflowModal}
        />
      )}
      {openEditWorkFlowModal && (
        <AddEditWorkflowList
          edit
          isAddEditWorkflowModal={openEditWorkFlowModal}
          setIsAddEditWorkflowModal={setOpenEditWorkFlowModal}
          initStateForWorkflowList={editWorkFlowInListModal}
        />
      )}
      <CloneTemplate
        id={id}
        templateName={name}
        isOpen={openTemplateCloneModal}
        setIsOpen={setOpenTemplateCloneModal}
        pathOfClone="templateMaker"
      />
      {openCrudTemplateCloneModal && (
        <CrudCloneTemplate
          isOpen={openCrudTemplateCloneModal}
          setIsOpen={setCrudTemplateCloneModal}
          onModalClose={onModalClose}
          title={viewState[activeModal] ? viewState[activeModal].title : ''}
          labelKey={
            viewState[activeModal] ? viewState[activeModal].labelKey : ''
          }
          masterType={
            viewState[activeModal] ? viewState[activeModal].masterType : ''
          }
          listKey={viewState[activeModal] ? viewState[activeModal].listKey : ''}
          targetUrl={
            viewState[activeModal] ? viewState[activeModal].targetUrl : ''
          }
          id={viewState[activeModal] ? viewState[activeModal].id : ''}
          method={viewState[activeModal] ? viewState[activeModal].method : ''}
          patchFilterId={viewState[activeModal]?.method ? id : ''}
          patchData={
            viewState[activeModal]?.patchData
              ? viewState[activeModal]?.patchData
              : {}
          }
        />
      )}
      {openAddSignatureDrawer && (
        <AddEditSignatureDrawer
          openDrawer={openAddSignatureDrawer}
          setOpenDrawer={setOpenAddSignatureDrawer}
        />
      )}
      {openEditSignatureDrawer && (
        <AddEditSignatureDrawer
          openDrawer={openEditSignatureDrawer}
          setOpenDrawer={setOpenEditSignatureDrawer}
          edit
          selectedSignatureValue={viewState[activeModal]}
        />
      )}
    </div>
  );
};

export default DynamicModalRenderer;
