export const dummyNode = [
  {
    id: 'start_1',
    data: {
      label: 'START',
    },
    type: 'selectorCreateNode',
    dragging: false,
    position: {
      x: 150,
      y: 100,
    },
    selected: false,
  },
  {
    id: 'start_2',
    data: {
      name: 'To-do Category',
      label: 'TO DO',
      categoryId: 'categores_1',
    },
    type: 'toDoNode',
    dragging: false,
    position: {
      x: 250,
      y: 300,
    },
    selected: false,
    positionAbsolute: {
      x: 250,
      y: 300,
    },
  },
  {
    id: 'start_3',
    data: {
      name: 'In-progress Category',
      label: 'IN PROGRESS',
      categoryId: 'categores_3',
    },
    type: 'toProgressNode',
    dragging: false,
    position: {
      x: 500,
      y: 540,
    },
    selected: false,
    positionAbsolute: {
      x: 500,
      y: 540,
    },
  },
  {
    id: 'start_4',
    data: {
      name: 'Done Category',
      label: 'DONE',
      categoryId: 'categores_4',
    },
    type: 'toDoneNode',
    dragging: false,
    position: {
      x: 1060,
      y: 680,
    },
    selected: false,
    positionAbsolute: {
      x: 1060,
      y: 680,
    },
  },
];
export const dummyEdge = [
  {
    id: 'edges_1',
    type: 'smoothstep',
    data: {
      label: 'Task To Do',
    },
    source: 'start_1',
    target: 'start_2',
    markerEnd: {
      type: 'arrowclosed',
    },
    sourceHandle: '1686315510876',
    targetHandle: 'target_1',
  },
  {
    id: 'reactflow__edge-start_2source_2-start_3target_1',
    data: {
      label: 'Work Assigned',
    },
    type: 'customEdges',
    source: 'start_2',
    target: 'start_3',
    markerEnd: {
      type: 'arrowclosed',
    },
    sourceHandle: 'source_2',
    targetHandle: 'target_1',
  },
  {
    id: 'e21464e5-b816-4fff-a83e-ef75533feb0b',
    data: {
      label: 'Work Done',
    },
    type: 'customEdges',
    source: 'start_3',
    target: 'start_4',
    markerEnd: {
      type: 'arrowclosed',
    },
    sourceHandle: 'source_2',
    targetHandle: 'target_1',
  },
];

export const handlesToSourceCheck = [
  'source_1',
  'source_2',
  'source_3',
  'source_4',
];
export const handlesToTargetCheck = [
  'target_1',
  'target_2',
  'target_3',
  'target_4',
];

export const dummyStatusCategories = [
  {
    id: 'categores_1',
    name: 'To-do Category',
    color: {
      value: 'toDoNode',
      label: 'Black',
    },
    description:
      'Statuses capture the stages of your working process. Issues in a to-do status represent work that has not started yet.',
  },
  {
    id: 'categores_3',
    name: 'In-progress Category',
    color: {
      value: 'toProgressNode',
      label: 'Blue',
    },
    description:
      'Statuses capture the stages of your working process. Issues in an in-progress status represent ongoing work.',
  },
  {
    id: 'categores_4',
    name: 'Done Category',
    color: {
      value: 'toDoneNode',
      label: 'Green',
    },
    description:
      'Statuses capture the stages of your working process. Issues in a done status represent completed work.',
  },
];
export const dummyDefaultRole = [
  {
    id: '6f1a5555-733d-4259-8aae-b7e91d02lmn',
    ruleType: 'moveIssue',
    statusId: '',
    transitionId: 'reactflow__edge-start_2source_2-start_3target_1',
    valueListObj: [],
    includedStatus: false,
    listRestricType: {
      roles: [
        'ticket_agent'
      ]
    },
    checkValueStatus: '',
    reverseRuleStatus: false,
    reviewValueStatus: ''
  },
  {
    id: '6f1a5555-733d-4259-8aae-b7e91d02qst',
    ruleType: 'moveIssue',
    statusId: '',
    transitionId: 'e21464e5-b816-4fff-a83e-ef75533feb0b',
    valueListObj: [],
    includedStatus: false,
    listRestricType: {
      roles: [
        'ticket_agent'
      ]
    },
    checkValueStatus: '',
    reverseRuleStatus: false,
    reviewValueStatus: ''
  },
];
