import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import { useSelector, useDispatch } from 'react-redux';
import { parseHumanReadable } from 'cron-js-parser';
import CustomInput from '../../components/InputBox/Input';
import TextAreaBox from '../DynamicRenderer/TextArea';
import CustomSelectBox from '../../components/SelectBox/Select';
import { checkForV2, isEmptyCondition } from '../../utils/helper';
import {
  ASSIGNMENT_STRATEGY,
  MANUAL_TRIGGER_TYPES,
  TIME_TRIGGER_TYPES,
  TRIGGER_TYPES,
  TRIGGER_TYPES_V1,
} from '../DynamicRenderer/customEventAndTriggers/customEventAndTriggers.constant';
import { DARK, LIGHT } from '../../utils/enums/colors';
import {
  TemplateEventValidations,
  addEventInEventList,
  createPayloadForEvent,
  getTriggerEvent,
  isCronValid,
  processErrorWithMessage,
} from './helper';
import customStyleFromRuleForSelect from '../../context/CustomTheme/CustomStyleFromRuleForSelect';
import { getTransitions } from '../DynamicRenderer/customEventAndTriggers/customEventAndTriggers.helper';
import { getWholeOption } from '../TicketTemplateWorkflow/helper';
// import DynamicExpressionWithTags from
// '../../components/dynamicExpressionWithTags/DynamicExpressionWithTags';
import DynamicMutliSelectWithInputTags from '../../components/dynamicSelectWithInputTags/DynamicSelectWithInputTags';
import { roleSettingSelectOpt } from '../../utils/enums/selectOption';
import CustomButton from '../../components/Button/SimpleButton';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import ConditionsExpressions from '../../components/ConditionsExpressions/ConditionsExpressions';
import { selectOptionsNoteTypes } from '../DynamicRenderer/customTemplateModal/CustomTemplateModalLayout';
import { CustomDrawer } from '../TicketTemplateBuilder/ConfigureTemplateView/AddEditTemplateLayoutModal';
import { patchDataForTemplateWithPath } from '../TicketTemplateBuilder/utils/templateAPIUtils';

const CreateAndEditEvent = ({ eventState, setEventState }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorKeyListWithMessage, setErrorKeyListWithMessage] = useState({});
  const dispatch = useDispatch();
  const { selectedTemplate } = useSelector(
    (reduxState) => reduxState.viewState
  );

  const getActorField = useMemo(
    () => () =>
      _map(
        _filter(
          selectedTemplate.fields,
          (field) =>
            field?.type_based_attributes?.collection?.specialField === 'actor'
        ),
        (actorField) => ({ value: actorField.id, label: actorField.label })
      ),
    [selectedTemplate]
  );
  const getFieldOption = useMemo(() => {
    if (eventState.trigger === 'field_change') {
      return [...(selectedTemplate?.fields || [])]
        .filter(
          (ele) =>
            ele.label !== 'Status' &&
            ele.label !== 'Attachment' &&
            ele.label !== 'Source' &&
            ele.label !== 'relation'
        )
        .map((item) => ({
          value: item.id,
          label: item.label,
        }));
    }
    return [...(selectedTemplate?.fields || [])].map((item) => ({
      value: item.id,
      label: item.label,
    }));
  }, [selectedTemplate, eventState]);

  const getSLAOptions = useMemo(() => {
    const metrics = selectedTemplate?.metric || [];
    const slas = selectedTemplate?.slas || {};

    const slasOptions = [];

    if (metrics.length > 0) {
      metrics.forEach((metric) => {
        const slasOfMetric = slas[metric.id] || [];
        slasOfMetric.forEach((sla) => {
          slasOptions.push({
            value: sla.id,
            label: `${metric.name} - ${sla.name}`,
          });
        });
      });
    }

    return slasOptions;
  }, [selectedTemplate]);

  const handleChange = (value, path) => {
    setErrorKeyListWithMessage((pre) => ({ ...pre, [path]: '' }));
    if (path === 'trigger_type') {
      setEventState((pre) => ({
        ...pre,
        data: {
          [path]: value,
          event_name: pre?.data?.event_name || '',
          description: pre?.data?.description || '',
          ticket_conditions: pre?.data?.ticket_conditions || [],
          user_conditions: pre?.data?.user_conditions || {},
          id: pre?.data?.id || '',
        },
      }));
    } else if (path === 'trigger') {
      setEventState((pre) => ({
        ...pre,
        data: {
          trigger_type: pre.data.trigger_type,
          [path]: value,
          event_name: pre?.data?.event_name || '',
          description: pre?.data?.description || '',
          ticket_conditions: pre?.data?.ticket_conditions || [],
          user_conditions: pre?.data?.user_conditions || {},
          id: pre?.data?.id || '',
        },
      }));
    } else {
      setEventState((pre) => ({
        ...pre,
        data: { ...pre.data, [path]: value },
      }));
    }
  };

  const dynamicRenderWithRTTriggerEvent = () => {
    const triggerValue = eventState?.trigger;
    switch (triggerValue) {
      case MANUAL_TRIGGER_TYPES.ACTOR_ASSIGNED:
        return (
          <Flex gap="20px">
            <Box data-testid="DynamicRendererManual2Box">
              <CustomSelectBox
                options={getActorField() || []}
                label="Field"
                placeholder="select field"
                value={getWholeOption(
                  eventState.actor_field || '',
                  getActorField()
                )}
                onChange={(value) => {
                  handleChange(value.value, `actor_field`);
                  // handleBasicErrorData('field');
                }}
                focusBorderColor={useColorModeValue(LIGHT, DARK)}
                isError={!!errorKeyListWithMessage?.actor_field}
                errorMessage={errorKeyListWithMessage?.actor_field || ''}
                id="DynamicRendererManualEventCustomSelect"
              />
            </Box>
            <Box data-testid="DynamicRendererManual2Box">
              <CustomSelectBox
                options={ASSIGNMENT_STRATEGY}
                label="Assignment Strategy"
                placeholder="select field"
                value={getWholeOption(
                  eventState.assignment_strategy || '',
                  ASSIGNMENT_STRATEGY
                )}
                onChange={(value) => {
                  handleChange(value.value, `assignment_strategy`);
                }}
                focusBorderColor={useColorModeValue(LIGHT, DARK)}
                isError={!!errorKeyListWithMessage?.assignment_strategy}
                errorMessage={
                  errorKeyListWithMessage?.assignment_strategy || ''
                }
                id="DynamicRendererManualEventCustomSelect"
              />
            </Box>
          </Flex>
        );
      case MANUAL_TRIGGER_TYPES.FIELD_CHANGE:
        return (
          <Box data-testid="DynamicRendererManualContainerBox">
            <CustomSelectBox
              options={getFieldOption}
              label="Field"
              placeholder="Trigger Field"
              value={eventState.field || {}}
              onChange={(value) => {
                handleChange(value, `field`);
              }}
              focusBorderColor={useColorModeValue(LIGHT, DARK)}
              isError={!!errorKeyListWithMessage?.field}
              errorMessage={errorKeyListWithMessage?.field || ''}
              isMulti
              id="DynamicRendererManualEventCustomSelect"
            />
          </Box>
        );
      case MANUAL_TRIGGER_TYPES.ISSUE_TRANSITIONED:
        return (
          <Box data-testid="DynamicRendererManual2Box">
            <CustomSelectBox
              options={getTransitions(selectedTemplate)}
              label="Transition Name"
              placeholder="select transition name"
              value={getWholeOption(
                eventState.transition_name || '',
                getTransitions(selectedTemplate)
              )}
              onChange={(value) => {
                handleChange(value.value, `transition_name`);
              }}
              focusBorderColor={useColorModeValue(LIGHT, DARK)}
              isError={!!errorKeyListWithMessage?.transition_name}
              errorMessage={errorKeyListWithMessage?.transition_name || ''}
              id="DynamicRendererManual2CustomSelect"
            />
          </Box>
        );
      case MANUAL_TRIGGER_TYPES.ISSUE_NOTE_ADDED:
        return (
          <Box data-testid="DynamicRendererManual2Box">
            <CustomSelectBox
              options={selectOptionsNoteTypes}
              label="Note Type"
              placeholder="Select Note Type"
              value={getWholeOption(
                eventState.note_type || '',
                selectOptionsNoteTypes
              )}
              onChange={(value) => {
                handleChange(value.value, `note_type`);
              }}
              focusBorderColor={useColorModeValue(LIGHT, DARK)}
              isError={!!errorKeyListWithMessage?.note_type}
              errorMessage={errorKeyListWithMessage?.note_type || ''}
              id="DynamicRendererManual2CustomSelect"
            />
          </Box>
        );
      case TIME_TRIGGER_TYPES.RELATIVE_ITEM:
        return (
          <Flex gap="10px">
            {/* <Box width="49%" data-testid="DynamicRendererManualIndexBox">
              <CustomInput
                label="Event"
                placeholder="select event"
                value={eventState.event || ''}
                onChange={(e) => {
                  handleChange(e.target.value, `event`);
                }}
                focusBorderColor={useColorModeValue(LIGHT, DARK)}
                isError={!!errorKeyListWithMessage?.event}
                errorMessage={errorKeyListWithMessage?.event || ''}
                id="DynamicRenderer2DivCInput"
                height="38px"
              />
            </Box> */}
            <Box
              width="100%"
              data-testid="DynamicRendererManualIndexAmntOfTimeBox"
              mt="5px"
            >
              <CustomInput
                label="Amount Of Time (in minutes)"
                value={eventState.amount_of_time || ''}
                onChange={(e) => {
                  handleChange(e.target.value, `amount_of_time`);
                }}
                focusBorderColor={useColorModeValue(LIGHT, DARK)}
                isError={!!errorKeyListWithMessage?.amount_of_time}
                errorMessage={errorKeyListWithMessage?.amount_of_time || ''}
                id="DynamicRenderer3DivCInput"
                height="38px"
              />
            </Box>
          </Flex>
        );
      case TIME_TRIGGER_TYPES.SPECIFIC_HOURS_OF_DAY:
        return (
          <CustomInput
            label="Start Time"
            value={eventState.start_time || ''}
            onChange={(e) => {
              handleChange(e.target.value, `start_time`);
            }}
            focusBorderColor={useColorModeValue(LIGHT, DARK)}
            isError={!!errorKeyListWithMessage?.start_time}
            errorMessage={errorKeyListWithMessage?.start_time || ''}
            id="DynamicRenderer4DivCInput"
            height="38px"
            type="time"
          />
        );
      case TIME_TRIGGER_TYPES.CRON:
        return (
          <>
            <CustomInput
              label="Cron Expression"
              value={eventState.cron || ''}
              onChange={(e) => {
                handleChange(e.target.value, `cron`);
              }}
              focusBorderColor={useColorModeValue(LIGHT, DARK)}
              isError={!!errorKeyListWithMessage?.cron}
              errorMessage={errorKeyListWithMessage?.cron || ''}
              id="DynamicRenderer4DivCInput"
              height="38px"
            />
            {isCronValid(eventState.cron || '') && (
              <Flex mt="10px" gap="10px" data-testid="CronExpressionPreview">
                <Text>Preview:</Text>
                <Text>
                  {parseHumanReadable(eventState.cron || '', {}, 'en')}
                </Text>
              </Flex>
            )}
          </>
        );
      case TIME_TRIGGER_TYPES.SLA:
      case TIME_TRIGGER_TYPES.SLA_RELATIVE_TIME:
        return (
          <>
            <Box data-testid="DynamicRendererManualContainerBox">
              <CustomSelectBox
                options={getSLAOptions}
                label="SLA"
                placeholder="Select sla"
                value={eventState.sla || {}}
                onChange={(value) => {
                  handleChange(value, `sla`);
                }}
                focusBorderColor={useColorModeValue(LIGHT, DARK)}
                isError={!!errorKeyListWithMessage?.sla}
                errorMessage={errorKeyListWithMessage?.sla || ''}
                id="DynamicRendererManualEventCustomSelect"
              />
            </Box>
            {eventState.trigger === TIME_TRIGGER_TYPES.SLA_RELATIVE_TIME && (
              <Flex mt="10px" gap="10px" data-testid="CronExpressionPreview">
                <CustomInput
                  label="Percentage"
                  type="number"
                  value={eventState.metricPercentage || ''}
                  onChange={(e) => {
                    handleChange(e.target.value, `metricPercentage`);
                  }}
                  focusBorderColor={useColorModeValue(LIGHT, DARK)}
                  isError={!!errorKeyListWithMessage?.metricPercentage}
                  errorMessage={errorKeyListWithMessage?.metricPercentage || ''}
                  id="DynamicRenderer4DivCInput"
                  height="38px"
                />
              </Flex>
            )}
          </>
        );
      default:
        return null;
    }
  };

  const completeCheckForPayload = (payload) => {
    const { error } = TemplateEventValidations.validate(payload, {
      abortEarly: false,
    });
    if (error) {
      const errorList = processErrorWithMessage(error.details);
      setErrorKeyListWithMessage(errorList);
      return true;
    }
    return false;
  };
  const updateState = (res) => {
    if (!res.error) {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: res?.response?.data,
        })
      );
    }
  };
  const onFinishSave = async () => {
    setIsLoading(true);
    let payload = { ...createPayloadForEvent(eventState) };
    payload.event_name = payload.event_name?.replace(/\s+/g, ' ').trim()
    if (completeCheckForPayload(payload)) {
      setIsLoading(false);
      return;
    }
    const newUpdatedEventList = addEventInEventList(
      payload,
      selectedTemplate?.events?.events
    );

    const isCreate =
      newUpdatedEventList?.length >
      (selectedTemplate?.events?.events?.length || 0);

    const res = await patchDataForTemplateWithPath({
      id: selectedTemplate?.id,
      data: {
        path: 'events.events',
        operation: isCreate ? 'create' : 'update',
        data: payload,
        id: !isCreate ? payload.id : undefined,
      },
      displayToastMessage: true,
      defaultErrorMessage: isCreate
        ? 'Failed to save the event'
        : 'Failed to update the event',
      successMessage: isCreate
        ? 'Successfully saved the event'
        : 'Successfully updated the event',
    });

    updateState(res);
    setIsLoading(false);
    setEventState(() => ({ data: {}, isShow: false }));
  };

  return (
    <>
      <CustomDrawer
        size="xl"
        modalTitle={
          <div data-testid="DynamicRendererEditContainerDiv">
            <div
              className="flex items-center gap-[5px]"
              data-testid="DynamicRendererEditContainerIcon"
            >
              {/* <ArrowBackIcon
          className={`
        cursor-pointer
        border
        border-[#E2E8F0]
        p-1
        rounded-[5px]
      `}
          h="32px"
          w="32px"
          onClick={() => {
            setEventState(() => ({ data: {}, isShow: false }));
          }}
        /> */}
              <p
                className="font-semibold text-[16px]"
                data-testid="DynamicRendererEditEventSettingsTextP"
              >
                Event Settings
              </p>
            </div>
          </div>
        }
        drawerBody={
          <>
            <Grid
              // templateColumns="repeat(12, 1fr)"
              columnGap={30}
              data-testid="DynamicRendererEditContainenrGrid"
            >
              <GridItem colSpan={4} data-testid="DynamicRendererEditGridItem">
                <CustomInput
                  label="Name"
                  isMandatory
                  value={eventState.event_name || ''}
                  onChange={(e) => {
                    handleChange(e.target.value, `event_name`);
                  }}
                  isError={errorKeyListWithMessage?.event_name || false}
                  errorMessage={errorKeyListWithMessage?.event_name || ''}
                  id="DynamicRenderer1DivCInput"
                  mb="10px"
                />
                <Box mt="10px">
                  <TextAreaBox
                    rows={2}
                    value={eventState.description || ''}
                    label="Description"
                    isMandatory
                    onChange={(e) =>
                      handleChange(e?.target?.value || '', `description`)
                    }
                    isError={errorKeyListWithMessage?.description || false}
                    errorMessage={errorKeyListWithMessage?.description || ''}
                    mt="10px"
                    mb="10px"
                  />
                </Box>
                <Box mt="10px">
                  <CustomSelectBox
                    options={checkForV2() ? TRIGGER_TYPES : TRIGGER_TYPES_V1}
                    placeholder="select trigger type"
                    value={getWholeOption(
                      eventState.trigger_type,
                      checkForV2() ? TRIGGER_TYPES : TRIGGER_TYPES_V1
                    )}
                    onChange={(value) => {
                      handleChange(value.value, `trigger_type`);
                    }}
                    focusBorderColor={useColorModeValue(LIGHT, DARK)}
                    isError={errorKeyListWithMessage?.trigger_type || false}
                    errorMessage={errorKeyListWithMessage?.trigger_type || ''}
                    id="DynamicRendererEditCustomSelectBox"
                    label="Trigger Type"
                  />
                </Box>
              </GridItem>
            </Grid>
            <Grid
              // templateColumns="repeat(12, 1fr)"
              // columnGap={30}
              mt="10px"
            >
              <GridItem colSpan={4}>
                <Box>
                  <CustomSelectBox
                    options={getTriggerEvent(
                      eventState.trigger_type || 'manual'
                    )}
                    placeholder="select trigger"
                    value={getWholeOption(
                      eventState.trigger,
                      getTriggerEvent(eventState.trigger_type || 'manual')
                    )}
                    onChange={(value) => {
                      handleChange(value.value, `trigger`);
                      //   handleBasicErrorData('trigger');
                    }}
                    focusBorderColor={useColorModeValue(LIGHT, DARK)}
                    styles={customStyleFromRuleForSelect}
                    isError={errorKeyListWithMessage?.trigger || false}
                    errorMessage={errorKeyListWithMessage?.trigger || ''}
                    id="DynamicRendererEditTriggerSelectCustomBox"
                    label="Trigger Event"
                  />
                </Box>
              </GridItem>
            </Grid>
            <Grid
              // templateColumns="repeat(12, 1fr)"
              // columnGap={30}
              mt="10px"
            >
              <GridItem colSpan={4}>
                {dynamicRenderWithRTTriggerEvent()}
              </GridItem>
            </Grid>
            <Grid
              //  templateColumns="repeat(12, 1fr)" columnGap={30}
              mt="10px"
            >
              <GridItem colSpan={8} mt="10px" className="100%">
                {/* <DynamicExpressionWithTags
            templateId={selectedTemplate?.id}
            values={eventState.ticket_conditions || []}
            onChange={(value) => handleChange(value, `ticket_conditions`)}
            label="Ticket Conditions"
          /> */}
                <ConditionsExpressions
                  templateId={selectedTemplate?.id}
                  value={eventState.ticket_conditions || {}}
                  onChange={(value) => {
                    const { and } = value || {};
                    const updatedValue = isEmptyCondition(and)
                      ? { and: [] }
                      : value;
                    handleChange(updatedValue, `ticket_conditions`);
                  }}
                  label="Ticket Conditions"
                  maxWidth="100%"
                />
                {errorKeyListWithMessage?.ticket_conditions && (
                  <span className="field-error">
                    {errorKeyListWithMessage?.ticket_conditions}
                  </span>
                )}
              </GridItem>
            </Grid>
            <Grid
              // templateColumns="repeat(12, 1fr)" columnGap={30}
              mt="10px"
            >
              <GridItem colSpan={8}>
                <DynamicMutliSelectWithInputTags
                  values={eventState.user_conditions || {}}
                  label="Event Permissions"
                  onChange={(value) => {
                    handleChange(value, 'user_conditions');
                  }}
                  templateId={selectedTemplate?.id}
                  selectOptions={roleSettingSelectOpt}
                  includeUserAttributes
                />
                {errorKeyListWithMessage?.user_conditions && (
                  <span className="field-error">
                    Select at least one event permission
                  </span>
                )}
              </GridItem>
            </Grid>
          </>
        }
        drawerFooter={
          <Flex p="16px 10px" justify="flex-end">
            <CustomButton
              id="SaveEvent"
              classname="calculatedHeight"
              color="white"
              onClick={() => {
                onFinishSave();
              }}
              buttonText="Save"
              variant="solid"
              bg={useColorModeValue(LIGHT, DARK)}
              isLoading={isLoading}
            />
          </Flex>
        }
        openDrawer
        setOpenDrawer={() => {
          setEventState(() => ({ data: {}, isShow: false }));
        }}
      />
      {/* <Box
      p="15px"
      border="1px solid #E2E3E4"
      borderRadius="6px"
      data-testid="DynamicRendererEditContainerBox"
      overflowX="scroll"
      overflow="auto"
      height="calc(100vh - 15rem)"
    >

    </Box> */}
    </>
  );
};

CreateAndEditEvent.propTypes = {
  eventState: PropTypes.object.isRequired,
  setEventState: PropTypes.func.isRequired,
};

export default CreateAndEditEvent;
