import {
  BiNotepad,
  BiLinkExternal,
  BiLayer,
  BiTrendingUp,
  BiTrendingDown,
  BiChevronUp,
  BiChevronsUp,
  BiTransferAlt,
  BiChevronDown,
  BiChevronsDown,
  BiPlus,
  BiRotateRight,
  BiX,
  BiDotsVerticalRounded,
  BiCalendar,
  BiEdit,
  BiPlusMedical,
  BiAddToQueue,
  BiArrowFromRight,
  BiPrinter,
  BiSolidImage,
} from 'react-icons/bi';
import {
  BsChevronLeft,
  BsChevronRight,
  BsFillExclamationCircleFill,
  BsFilePdf,
  BsFileWord,
  BsFileExcel,
  BsFilePpt,
  BsFileZip,
  BsTrash,
  BsFiletypeHtml
} from 'react-icons/bs';
import { TiAttachment } from 'react-icons/ti';
import { MdOutlineHistory, MdOutlineOndemandVideo } from 'react-icons/md';
import CustomToast from '../Notification/Notification';
import axiosInstance from '../../utils/axios/axios';

const { addToast } = CustomToast();

const showToast = ({ title, message, type }) => {
  addToast({
    title,
    message,
    type,
  });
};

export const MIME_TYPES = {
  // images
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  svg: 'image/svg+xml',
  gif: 'image/gif',
  webp: 'image/webp',
  bmp: 'image/bmp',
  // videos
  mpg: 'video/mpeg',
  mpeg: 'video/mpeg',
  mov: 'video/quicktime',
  mp4: 'video/mp4',
  wmv: 'video/x-ms-wmv',
  avi: 'video/x-msvideo',
  // documents
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  txt: 'text/plain',
  text: 'text/plain',
  csv: 'text/csv',
  tsv: 'text/tab-separated-values',
  html: 'text/html',
  htm: 'text/html',
  // compressed_files
  zip: 'application/zip',
  rar: 'application/vnd.rar',
  tar: 'application/x-tar',
};

export const MEDIA_FILE_EXTENSIONS = {
  images: ['jpg', 'jpeg', 'png', 'svg', 'gif', 'webp'],
};

export const getMimeType = (filename) => {
  const extension = filename.split('.').pop()?.toLowerCase();
  if (!extension) return '';

  return extension;
};

export const isImageMimeType = (mimeType) => {
  switch (mimeType) {
    case 'image/jpeg':
    case 'image/png':
    case 'image/svg+xml':
    case 'image/gif':
    case 'image/bmp':
      return true;
    default:
      return false;
  }
};

export const getImage = async (blobName) => {
  try {
    const res = await axiosInstance.get(`/files/download?fileName=${blobName}`);

    const base64String = res?.data?.data;
    const mimeType = res?.data?.mimeType;
    if (typeof (base64String) !== 'string') {
      throw new Error('Something went wrong');
    }

    return {
      fileSrc: `data:${mimeType};base64,${base64String}`,
      fileMimeType: mimeType,
    };
  } catch (err) {
    return '';
  }
};

export const postImage = async (file) => {
  const fileName = file?.name;
  // const mimeType = getMimeType(fileName);
  // const imageMimeType = isImageMimeType(mimeType);

  // if (!imageMimeType) {
  //   showToast({
  //     type: 'error',
  //     message: `You can only upload ${MEDIA_FILE_EXTENSIONS.images.join(', ')} files.`,
  //     title: 'File type not allowed',
  //   });

  //   return null;
  // }

  try {
    const formData = new FormData();
    formData.append('file', file);

    const res = await axiosInstance.post(`/files/upload?name=${fileName}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    if (!res?.data?.fileUrl) {
      throw new Error('Something went wrong');
    }

    return {
      imageUrl: res?.data?.fileUrl,
      blobName: res?.data?.blobName,
    };
  } catch (err) {
    showToast({
      type: 'error',
      message: 'Failed to upload the image',
      title: 'Error',
    });

    return null;
  }
};

export const getImageUrl = async (blobName) => {
  try {
    const res = await axiosInstance.get(`/files/sasUrl?fileName=${blobName}`);

    const imgUrl = res?.data?.sasUrl;

    if (typeof (imgUrl) !== 'string') {
      throw new Error('Something went wrong');
    }

    return imgUrl;
  } catch (err) {
    return '';
  }
};


export const deleteAttachment = async (blobName) => {
  try {
    const res = await axiosInstance.delete(`/files/delete?fileName=${blobName}`);
    if (res?.data?.status !== 'success') {
      throw new Error('Something went wrong');
    }

    return true;
  } catch (err) {
    return false;
  }
};
export const getIcon = (IconName) => {
  // eslint-disable-next-line default-case
  switch (IconName) {
    case 'BiTrendingUp':
      return BiTrendingUp;
    case 'BiTrendingDown':
      return BiTrendingDown;
    case 'BiChevronUp':
      return BiChevronUp;
    case 'BiChevronsUp':
      return BiChevronsUp;
    case 'BsChevronLeft':
      return BsChevronLeft;
    case 'BsChevronRight':
      return BsChevronRight;
    case 'BiTransferAlt':
      return BiTransferAlt;
    case 'BiChevronDown':
      return BiChevronDown;
    case 'BiChevronsDown':
      return BiChevronsDown;
    case 'BiLayer':
      return BiLayer;
    case 'BiLinkExternal':
      return BiLinkExternal;
    case 'BiNotepad':
      return BiNotepad;
    case 'MdOutlineHistory':
      return MdOutlineHistory;
    case 'BsFillExclamationCircleFill':
      return BsFillExclamationCircleFill;
    case 'BiPlus':
      return BiPlus;
    case 'BiRotateRight':
      return BiRotateRight;
    case 'BiX':
      return BiX;
    case 'BiDotsVerticalRounded':
      return BiDotsVerticalRounded;
    case 'BiCalendar':
      return BiCalendar;
    case 'BiEdit':
      return BiEdit;
    case 'BiPlusMedical':
      return BiPlusMedical;
    case 'BiAddToQueue':
      return BiAddToQueue;
    case 'BiArrowFromRight':
      return BiArrowFromRight;
    case 'BiPrinter':
      return BiPrinter;
    case 'BiSolidImage':
      return BiSolidImage;
    case 'MdOutlineOndemandVideo':
      return MdOutlineOndemandVideo;
    case 'BsFilePdf':
      return BsFilePdf;
    case 'BsFileWord':
      return BsFileWord;
    case 'BsFileExcel':
      return BsFileExcel;
    case 'BsFilePpt':
      return BsFilePpt;
    case 'BsFileZip':
      return BsFileZip;
    case 'BsTrash':
      return BsTrash;
    case 'TiAttachment':
      return TiAttachment;
    case 'BsFiletypeHtml':
      return BsFiletypeHtml;
  }
};
export const getIconForMimeType = (mimeType) => {
  switch (mimeType) {
    case 'image/jpeg':
    case 'image/png':
    case 'image/svg+xml':
    case 'image/gif':
      return 'BiSolidImage';
    case 'video/mpeg':
    case 'video/quicktime':
    case 'video/mp4':
    case 'video/x-ms-wmv':
    case 'video/x-msvideo':
      return 'MdOutlineOndemandVideo';
    case 'application/pdf':
      return 'BsFilePdf';
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'BsFileWord';
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return 'BsFileExcel';
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return 'BsFilePpt';
    case 'application/zip':
    case 'application/vnd.rar':
    case 'application/x-tar':
      return 'BsFileZip';
    case 'text/html':
      return 'BsFiletypeHtml';
    default:
      return 'BiFile';
  }
};
export const downloadFile = (data) => {
  window.open(data, '_blank', 'noopener noreferrer');
};
