import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import CustomSelectBox from '../../../components/SelectBox/Select';
import {
  getWholeMultiSelectOptions,
  getWholeOption,
} from '../../TicketTemplateWorkflow/helper';
import CustomField from '../../../components/dynamicExpressionWithTags/CustomField';
import { availableUsersListInTenantLevel } from '../../../utils/helper/roleBasedAccess';
import { FieldTypes } from '../../../utils/enums/types';

const renderAddAttachmentForm = (
  handleChange,
  actionToPerformSelectedIndex,
  handleActionErrorData,
  actionToPerform,
  errorData,
  relationsData,
  relationItemTypeOption,
  fieldMapByTemplateId,
  selectedTemplate
) => (
  <>
    <Box data-testid="DynamicRendererNoteFormBox1" marginTop="8px">
      <CustomSelectBox
        options={relationItemTypeOption}
        label="Item Type"
        placeholder="Select Item Type"
        value={actionToPerform.issue_type}
        onChange={(value) => {
          handleChange(
            value,
            `action_to_perform[${actionToPerformSelectedIndex}].issue_type`
          );

          handleActionErrorData('issue_type');
        }}
        id="DynamicRendererManualEventCustomSelectNoteType"
      />
    </Box>
    {!isEmpty(actionToPerform?.issue_type?.value) && (
      <Box data-testid="DynamicRendererNoteFormBox2" marginTop="8px">
        <CustomSelectBox
          options={fieldMapByTemplateId[actionToPerform.issue_type.value].MEDIA}
          placeholder="Select Item Type"
          value={getWholeOption(
            actionToPerform.associated_issue_field_id,
            fieldMapByTemplateId[actionToPerform.issue_type.value].MEDIA
          )}
          onChange={(value) => {
            handleChange(
              value.value,
              `action_to_perform[${actionToPerformSelectedIndex}].associated_issue_field_id`
            );

            handleActionErrorData('associated_issue_field_id');
          }}
          id="DynamicRendererManualEventCustomSelectNoteType"
          label="Associated Media Field"
        />
      </Box>
    )}

    <Box data-testid="DynamicRendererNoteFormBox1" marginTop="8px">
      <CustomSelectBox
        options={(selectedTemplate?.fields || [])
          .filter((item) => item.type === FieldTypes.MEDIA)
          .map((data) => ({ value: data.id, label: data.label }))}
        placeholder="Select Item Type"
        value={getWholeOption(
          actionToPerform.current_field_id,
          (selectedTemplate?.fields || [])
            .filter((item) => item.type === FieldTypes.MEDIA)
            .map((data) => ({ value: data.id, label: data.label }))
        )}
        onChange={(value) => {
          handleChange(
            value.value,
            `action_to_perform[${actionToPerformSelectedIndex}].current_field_id`
          );

          handleActionErrorData('current_field_id');
        }}
        id="DynamicRendererManualEventCustomSelectNoteType"
        label="Current Media Field"
      />
    </Box>
  </>
);

export default renderAddAttachmentForm;
