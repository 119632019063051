import React, { useEffect } from 'react';
import { Flex, Icon, useColorModeValue } from '@chakra-ui/react';
import { ReactSVG } from 'react-svg';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { COLORS } from '../../utils/enums/colors';
import styles from './nav-layout.module.scss';

const NavItem = ({
  icon,
  iconElement,
  children,
  highlightRouts,
  path,
  selectedMenu,
  openMenu,
  item,
  handleItemClick,
  level,
  iconType,
  globalBg,
  iconWidth,
  iconHeight,
  childIcon,
}) => {
  const location = useLocation();
  let iconName = <Icon as={icon} width={iconWidth} height={iconHeight} />;

  useEffect(() => {
    if ((location.pathname === path || (openMenu && location.pathname.includes(path))) || item?.name === 'catalog') {
      handleItemClick(item);
    }
  }, []);

  if (iconElement) {
    iconName = iconElement;
  } else if (icon) {
    iconName = <Icon as={icon} width={iconWidth} height={iconHeight} />;
  } else {
    iconName = (<ReactSVG
      className={classnames(styles.svgSideBarIcons, {
        [styles.svgIconSelected]: selectedMenu === path && iconType !== 'outline',
        [styles.svgOutlineIconSelected]: selectedMenu === path && iconType === 'outline',
        [styles.svgIconDark]: selectedMenu === path && globalBg === 'dark',
        [styles.svgMargin]: !(selectedMenu === path && globalBg === 'dark'),
      })}
      src={childIcon}
    />);
  }

  return (
    <Flex
      onClick={() => handleItemClick(item)}
      alignItems="center"
      pt="0.25rem"
      pb="0.25rem"
      pl="0.5rem"
      mb="0.5rem"
      borderRadius="md"
      role="group"
      cursor="pointer"
      height="30px"
      max-height="30px"
      _hover={{
        bg: useColorModeValue('', COLORS.SIDEBAR_DRAWER_BG),
      }}
      className={`
        ${iconType !== 'outline' ? styles.svgHover : styles.svgHoverBox}
        ${styles.side__menu__icon} ${openMenu ? styles.openBox : styles.closeBox}
         ${location.pathname.includes(path) || highlightRouts?.some(path => location.pathname.includes(path)) ? styles.activeNavLink : ''}
      `}
    >
      {iconName}
      {children}
    </Flex>
  );
};

NavItem.propTypes = {
  icon: PropTypes.node.isRequired,
  iconElement: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
  highlightRouts: PropTypes.array,
  selectedMenu: PropTypes.string.isRequired,
  globalBg: PropTypes.string.isRequired,
  openMenu: PropTypes.bool.isRequired,
  iconType: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  handleItemClick: PropTypes.func.isRequired,
  level: PropTypes.number.isRequired,
  iconWidth: PropTypes.string.isRequired,
  iconHeight: PropTypes.string.isRequired,
  childIcon: PropTypes.node.isRequired,
};

export default NavItem;
