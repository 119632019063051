/* eslint-disable indent */
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _flattenDeep from 'lodash/flattenDeep';
import { v4 as uuidv4 } from 'uuid';

import axiosInstance from '../../../utils/axios/axios';
import { patchDataForTemplate } from '../../TicketTemplateBuilder/utils/templateAPIUtils';
import { isEmpty } from 'lodash';

export const updateTemplate = async ({
  data,
  id,
  defaultErrorMessage,
  successMessage,
}) => {
  const res = await patchDataForTemplate({
    id,
    data,
    key: 'permissions',
    defaultErrorMessage,
    successMessage,
  });

  return res;
};

export const getTemplateById = async (id) => {
  const response = await axiosInstance.get(
    `/templates/list?$top=1&$skip=0&$select=name,id,fields&$filter=id eq ${id}`
  );
  if (response.status === 200) {
    return response.data;
  }
  return [];
};

export const getTemplates = (viewState) => {
  const templates = viewState?.ticket_template_list_short_view?.data?.map(
    (template) => ({
      value: template.id,
      label: template.name,
    })
  );

  return templates?.filter((x) => x);
};

export const getTransformedChildTemplatesForUI = (currentTemplate) => {
  const templates = currentTemplate?.children?.map((template) => ({
    value: template.id,
    label: template.name,
  }));

  return templates?.filter((x) => x);
};

export const getTransformedChildTemplatesFieldsForUI = (
  currentTemplate,
  actionToPerform,
  callback
) => {
  let fields;
  const createFieldsOptions = (id) => {
    getTemplateById(id).then((templates) => {
      if (templates?.count === 1) {
        fields = templates?.rows[0].fields?.map((t) => {
          if (_isEmpty(t)) return null;
          return { value: t.id, label: t.label };
        });
        fields = _flattenDeep(fields);
        if (typeof callback === 'function') {
          callback(fields?.filter((x) => x));
        }
      }
    });
  };
  createFieldsOptions(currentTemplate?.id);
};

export const transformAPIData = (permissions, IntentOptions) => {
  if (_isEmpty(permissions)) return [];
  return _map(permissions, (permission) => ({
    ...permission,
    id: permission?.id || uuidv4(),
    intent: _map(permission?.intent, (intent) =>
      IntentOptions.find((t) => t.value === intent)
    ),
  }));
};

export const createPayload = (permissions) => {
  permissions = _map(permissions, (permission) => {
    delete permission?.temp;
    return {
      ...permission,
      id: permission?.id || uuidv4(),
      intent: _map(permission?.intent, (intent) => intent?.value),
    };
  });
  const payload = {
    permissions,
  };
  return payload;
};

export const getTemplate = (viewState) => {
  const splitedPathName = window.location.pathname.split('/');
  const templateId = splitedPathName[splitedPathName.length - 1];
  const templateData = viewState?.ticket_template_list_short_view?.data?.filter(
    (d) => d.id === templateId
  );
  if (templateData && templateData.length > 0) {
    return templateData[0];
  }
  return {};
};

export const getFields = (viewState, omitField, fieldType = 'TEXT_AREA') => {
  const fieldsList = viewState?.ticket_template_fields_list_view
  ?.data?.filter((item) => item.type === fieldType)?.map(
    (field) => {
      if (field?.id) {
        return {
          value: field.id,
          label: field.label,
        };
      }
      return null;
    }
  );

  return !isEmpty(omitField) ?
  fieldsList?.filter((field) => field).filter((item) => omitField?.value !== item.value) :
  fieldsList?.filter((field) => field);
};
