import { Grid, GridItem } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import CustomInput from '../../../components/InputBox/Input';
import { stringToSlug } from '../utils/helper';

const CreateActotContainer = ({ createDispatch, createActorState }) => {
  const handleNameInput = (e) => {
    createDispatch({
      type: 'UPDATE_STATE',
      name: 'name',
      value: e.target.value,
    });
    if (e.target.value) {
      createDispatch({
        type: 'UPDATE_STATE',
        name: 'slug',
        value: stringToSlug(`actors_${e.target.value}`),
      });
    } else {
      createDispatch({
        type: 'UPDATE_STATE',
        name: 'slug',
        value: '',
      });
    }
  };
  const handleDescInput = (e) => {
    createDispatch({
      type: 'UPDATE_STATE',
      name: 'description',
      value: e.target.value,
    });
  };
  return (
    <>
      <Grid mt="45px" data-testid="CreateActotContainerNameGrid">
        <GridItem w="100%" data-testid="CreateActotContainerNameGridItem">
          <CustomInput
            label="Name"
            isMandatory
            value={createActorState?.name ?? ''}
            onChange={handleNameInput}
            id="CreateActorCIDivCInput"
          />
        </GridItem>
      </Grid>
      <Grid mt="45px">
        <GridItem w="100%">
          {/* <CustomInput
            label="Slug"
            isMandatory
            value={createActorState?.slug ?? ''}
            onChange={() => {}}
            isReadOnly
          /> */}
        </GridItem>
      </Grid>
      <Grid mt="42px" data-testid="CreateActotContainerDescGrid">
        <GridItem w="100%" data-testid="CreateActotContainerDescGridItem">
          <CustomInput
            label="Description"
            value={createActorState?.description ?? ''}
            onChange={handleDescInput}
            readonly
          />
        </GridItem>
      </Grid>
    </>
  );
};
CreateActotContainer.propTypes = {
  createActorState: PropTypes.object.isRequired,
  createDispatch: PropTypes.func.isRequired,
};
export default CreateActotContainer;
