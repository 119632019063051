/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable brace-style */
import { v4 as uuidv4 } from 'uuid';
import axiosInstance from '../../../utils/axios/axios';
import { tenantId } from '../../../utils/enums/axiosConstant';
import { COLORS } from '../../../utils/enums/colors';
import { patchDataForTemplate, patchDataForTemplateWithPath } from './templateAPIUtils';
import Store from '../../../store/Store';
import { FieldTypes } from '../../../utils/enums/types';
import { SPECIAL_FIELDS_DROPDOWN } from '../../../utils/enums/labels';
import { DROPDOWN_TYPES, SPECIAL_FIELD_TYPE } from '../../TenantFilters/filters.constants';

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: '0px',
  margin: `0 0 0 0`,
  // change background colour if dragging
  background: isDragging ? COLORS.DRAGGING : 'transparent',
  // styles we need to apply on draggables
  ...draggableStyle,
});

export const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? COLORS.DRAG_LIGHT : 'transparent',
  padding: '4px',
  width: 260,
});

export const checkNested = (arr) =>
  arr?.some((item) => item?.subItems === undefined);

const flattenSubItems = (subItems) => {
  const stack = [...subItems];
  const output = [];

  while (stack.length) {
    const currentItem = stack.pop();
    output.push({
      id: currentItem.id,
      content: currentItem.content,
    });

    if (currentItem.subItems) {
      stack.push(...currentItem.subItems);
    }
  }

  return output;
};

export const getAllTemplates = (arr) =>
  flattenSubItems(arr).map((item) => ({
    id: item.id,
    content: item.content,
  }));
export const addUnderScoreInStr = (str) => str.replace(/ /g, '_').toLowerCase();

export const getTemplatesInNestedList = (arr) => {
  if (arr?.length === 0) return;
  const tree = [];
  const mappedArr = {};

  // First map the nodes of the array to an object -> create a hash table.
  for (let i = 0, len = arr.length; i < len; i++) {
    const arrElem = arr[i];
    mappedArr[arrElem.id] = { ...arrElem, children: [] };
  }

  for (const id in mappedArr) {
    if (mappedArr.hasOwnProperty(id)) {
      const mappedElem = mappedArr[id];
      // If the element is not at the root level, add it to its parent array of children.
      if (mappedElem.parent_id) {
        mappedArr[mappedElem.parent_id]?.children.push(mappedElem);
      }
      // If the element is at the root level, add it to first level elements array.
      else {
        tree.push(mappedElem);
      }
    }
  }
  return tree;
};

export const getFieldsApi = async (id) => {
  const response = await axiosInstance.get(
    `fields/tenant/${tenantId}/template/${id}`
  );
  const updatedData = Promise.all(
    [...response.data].map(async (item) => {
      if (item.collection_id !== null) {
        const result = await axiosInstance.get(
          `collections/${item.collection_id}`
        );
        return { ...item, values: result.data[0].values.join('\n') };
      }
      return item;
    })
  );
  const newUpdateResult = await updatedData;
  return newUpdateResult;
};
export const getFieldsetApi = async (id) => {
  const response = await axiosInstance.get(
    `fields/tenant/${tenantId}/fieldset/${id}`
  );
  return response?.data;
};
export const getCompositeApi = async (id) => {
  const response = await axiosInstance.get(
    `fields/tenant/${tenantId}/compositeField/${id}`
  );
  return response?.data;
};
export const addTemplateApi = async (data) => {
  try {
    const response = await axiosInstance.post(
      `templates/tenant/${tenantId}`,
      data
    );
    return response?.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return message;
  }
};

export const editTemplateApi = async (data, id) => {
  try {
    const response = await axiosInstance.patch(`templates/${id}`, data);
    return response?.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return message;
  }
};

export const addCollectionInField = async (data) => {
  try {
    const response = await axiosInstance.post('collections', data);
    return response?.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return message;
  }
};
export const editCollectionInField = async (data, id) => {
  try {
    const response = await axiosInstance.patch(`collections/${id}`, data);
    return response?.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return message;
  }
};
export const getCollectionInField = async (id) => {
  try {
    const response = await axiosInstance.get(`collections/${id}`);
    return response?.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return message;
  }
};

export const addFieldInFieldsetsUsingCrud = async (
  value,
  defaultErrorMessage
) => {
  try {
    const requestBody = {
      data: {
        fields: value,
      },
    };
    const response = await axiosInstance.patch(
      `/fieldsets/update?$filter=id eq ${
        window.location.pathname.split('/').reverse()[0]
      }`,
      requestBody
    );
    return {
      response: response?.data,
      error: null,
    };
  } catch (error) {
    return {
      error: error?.response?.data?.message || defaultErrorMessage,
      response: null,
    };
  }
};
export const addFieldInMasterFields = async (value, defaultErrorMessage) => {
  try {
    const requestBody = {
      data: value,
    };

    const response = await axiosInstance.post(`/fields/create`, requestBody);
    return {
      response: response?.data,
      error: null,
    };
  } catch (error) {
    return {
      error: error?.response?.data?.message || defaultErrorMessage,
      response: null,
    };
  }
};
export const addFieldInMasterFieldsets = async (value) => {
  try {
    const requestBody = {
      data: value,
    };

    const response = await axiosInstance.post(`/fieldsets/create`, requestBody);
    return {
      response: response?.data,
      error: null,
    };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return {
      error: message,
      response: null,
    };
  }
};

export const updateFieldInMasterFields = async (
  id,
  value,
  defaultErrorMessage
) => {
  try {
    // eslint-disable-next-line camelcase
    const { tenant_id, slug, ...valueWithoutTenant } = value;

    const requestBody = {
      data: valueWithoutTenant,
    };

    const response = await axiosInstance.patch(
      `/fields/update?$filter=id eq ${id}`,
      requestBody
    );
    return {
      response: response?.data,
      error: null,
    };
  } catch (error) {
    return {
      error: error?.response?.data?.message || defaultErrorMessage,
      response: null,
    };
  }
};
export const updateFieldInMasterFieldsets = async (id, value) => {
  try {
    // eslint-disable-next-line camelcase
    const { tenant_id, ...valueWithoutTenant } = value;

    const requestBody = {
      data: valueWithoutTenant,
    };
    // const requestBody = {
    //   data: value,
    // };

    const response = await axiosInstance.patch(
      `/fieldsets/update?$filter=id eq ${id}`,
      requestBody
    );
    return {
      response: response?.data,
      error: null,
    };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return {
      error: message,
      response: null,
    };
  }
};

export const addFieldsInFieldsetApi = async (data, id) => {
  const response = await axiosInstance.post(
    `fields/tenant/${tenantId}/fieldset/${id}`,
    data
  );
  return response?.data;
};
export const addFieldsInCompositeApi = async (data, id) => {
  const response = await axiosInstance.post(
    `fields/tenant/${tenantId}/compositeField/${id}`,
    data
  );
  return response?.data;
};

export const fetchAllFieldset = async ({ query } = {}) => {
  try {
    const params = {
      $top: 50,
      $skip: 0,
      $select: '*',
      ...query, // Include additional query parameters if provided
    };

    const response = await axiosInstance.get('fieldsets/list', {
      params,
    });
    return response.data.rows;
  } catch (error) {
    throw new Error('Failed to fetch templates.');
  }
};

export const editFieldInTemplateApi = async (data, id) => {
  const response = await axiosInstance.patch(`fields/${id}`, data);
  return response?.data;
};
export const addFieldsetApi = async (data) => {
  const response = await axiosInstance.post(`fieldsets`, data);
  return response?.data;
};
export const addCompositeApi = async (data) => {
  const response = await axiosInstance.post(`composite-fields`, data);
  return response?.data;
};
export const editFieldsetApi = async (data, id) => {
  const response = await axiosInstance.patch(`fieldsets/${id}`, data);
  return response?.data;
};
export const editCompositeApi = async (data, id) => {
  const response = await axiosInstance.patch(`composite-fields/${id}`, data);
  return response?.data;
};
export const deleteTemplateApi = async (id) => {
  const response = await axiosInstance.delete(`templates/${id}`);
  return response;
};
export const deleteFieldsetApi = async (id) => {
  const response = await axiosInstance.delete(`fieldsets/${id}`);
  return response;
};

export const removedInvalidChar = (str) => {
  str = str?.replace(/[^\w\s-()@/.]/g, ''); // remove invalid chars
  str?.replace(/ /g, ' ');

  return str;
};

export const removedInvalidCharAllowQuestion = (str) => {
  str = str?.replace(/[^\w\s-()@/.?]/g, ''); // remove invalid chars
  str?.replace(/ /g, ' ');

  return str;
};
export const validateEmail = (email) => {
  const re = /^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
  return !re.test(email);
};

export const validateNumber = (num, minLength = 0, maxLength = 0) => {
  const re = /^-?\d*\.?\d+$/;
  if (minLength && maxLength) {
    const checkNum = !(
      parseInt(num, 10) < parseInt(minLength, 10) ||
      parseInt(num, 10) > parseInt(maxLength, 10)
    );
    return !re.test(num) || !checkNum;
  }
  return !re.test(num);
};
export const validateStrRange = (value, minLength = 0, maxLength = 0) => {
  if (minLength && maxLength) {
    const checkRange = !(
      value.length < parseInt(minLength, 10) ||
      value.length > parseInt(maxLength, 10)
    );
    return !checkRange;
  }
  return false;
};

export const getAllLayoutApi = async (id) => {
  try {
    const response = await axiosInstance.get(
      `/templates/list?$top=100&$skip=0&$select=layouts,fields,view_layout_map&$filter=id eq '${id}'`
    );
    return {
      layouts: response?.data?.rows[0]?.layouts,
      fields: response?.data?.rows[0]?.fields,
      viewLayoutMap: response?.data?.rows[0]?.view_layout_map,
    };
  } catch (err) {
    return err;
  }
};

export const addTemplateLayoutApi = async (data) => {
  try {
    const response = await axiosInstance.post(`layouts`, data);
    return response?.data;
  } catch (err) {
    return err;
  }
};

export const addOrEditTemplateLayoutApi = async ({
  id,
  data,
  defaultErrorMessage,
  successMessage,
}) => {
  const res = await patchDataForTemplateWithPath({
    id,
    data,
    defaultErrorMessage,
    successMessage,
  });

  return res;
};

export const flattenList = (items) => {
  let arr = [];
  items.forEach((i) => {
    if (i.subItems) {
      arr = [...arr, ...i.subItems];
    } else {
      arr.push(i);
    }
  });
  return arr;
};

// Load value from localStorage
// export const loadState = (key) => {
//   try {
//     const serializedState = localStorage.getItem(key);
//     if (!serializedState) return undefined;
//     return JSON.parse(serializedState);
//   } catch (e) {
//     return undefined;
//   }
// };
export const loadState = (key) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null || serializedState === undefined) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

// Save value in localStorage
export const saveState = async (key, state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (e) {
    // Ignore
  }
};
export const removeState = async (key, state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.removeItem(key, serializedState);
  } catch (e) {
    // Ignore
  }
};

export const isEmtpty = (value) =>
  value === null || // check for null
  value === undefined || // check for undefined
  value === '' || // check for empty string
  value === false ||
  (Array.isArray(value) && value.length === 0) || // check for empty array
  (typeof value === 'object' && Object.keys(value).length === 0); // check

const removeAccentMarks = (str) =>
  str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

function generateShortUuid() {
  const uuid = uuidv4().replace(/-/g, '');
  const shortUuid = uuid.substring(0, 8); // Extract the first 8 characters
  return shortUuid;
}

export const getSlug = (name) => {
  if (!name) return undefined;
  const slug = removeAccentMarks(
    name
      .toLowerCase()
      .replace(/[^\w ]+/g, '')
      .replace(/ +/g, '-')
  );

  return `${slug}-${generateShortUuid()}`;
};

export const stringToSlug = (str) => getSlug(str);

export const isDarkThemeApplied = () =>
  localStorage.getItem('chakra-ui-color-mode') === 'dark';

export const getDefaultValue = (defaultVal) => {
  if (typeof defaultVal === 'boolean') {
    return defaultVal;
  }

  return defaultVal || undefined;
};

export const checkDisableForActorType = (val) => {
  const primaryActorFields = [];
  const requestorActorFields = [];

  const { viewState } = Store.getState();
  // eslint-disable-next-line camelcase
  const fields = viewState?.ticket_template_fields_list_view?.data;

  fields?.forEach((field) => {
    if (
      field.type === FieldTypes.DROPDOWN &&
      field.type_based_attributes?.collection?.collection_type ===
      DROPDOWN_TYPES.SPECIAL_FIELD
    ) {
      if (field.type_based_attributes.collection.specialField === SPECIAL_FIELD_TYPE.ACTORS) {
        if (field.type_based_attributes.collection.actor_type === 'PRIMARY') {
          primaryActorFields.push(field);
        } else if (
          field.type_based_attributes.collection.actor_type === 'REQUESTOR'
        ) {
          requestorActorFields.push(field);
        }
      }
    }
  });

  if (val === 'PRIMARY') {
    return Boolean(primaryActorFields?.length);
  }
  if (val === 'REQUESTOR') {
    return Boolean(requestorActorFields?.length);
  }

  return false;
};

export const checkSourceFieldInTemplate = (fields) => {
  const sourceField = fields?.filter(
    (field) =>
      field?.type_based_attributes?.collection?.collection_type ===
      DROPDOWN_TYPES.SPECIAL_FIELD &&
      field?.type_based_attributes?.collection?.specialField === SPECIAL_FIELD_TYPE.SOURCE
  );
  return Boolean(sourceField?.length);
};

export const getOptionsForSpecialField = (id) => {
  const { viewState } = Store.getState();
  // eslint-disable-next-line camelcase
  const fields = viewState?.ticket_template_fields_list_view?.data;

  let filteredVals = SPECIAL_FIELDS_DROPDOWN;

  if (id) {
    filteredVals = filteredVals?.filter((i) => i.value !== SPECIAL_FIELD_TYPE.WORKFLOW);
  }

  if (checkSourceFieldInTemplate(fields)) {
    filteredVals = filteredVals?.filter((i) => i.value !== SPECIAL_FIELD_TYPE.SOURCE);
  }
  return filteredVals;
};
