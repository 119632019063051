export const cardJson = [
  {
    id: 1,
    title: 'Onboarding Request',
    status: 'todo',
    desc: 'this is description',
    created_by: 'sam@gmail.com',
    created_on: 'Feb 20 2023, 11:09 pm',
    last_edited: 'Feb 20 2023, 11:09 pm',
    Edited_by: 'john@gmail.com',
  },
  {
    id: 2,
    title: 'Offboarding Request',
    status: 'inProgress',
    desc: 'this is description',
    created_by: 'sam@gmail.com',
    created_on: 'Feb 20 2023, 11:09 pm',
    last_edited: 'Feb 20 2023, 11:09 pm',
    Edited_by: 'john@gmail.com',
  },
  {
    id: 3,
    title: 'Software Request',
    status: 'done',
    desc: 'this is description',
    created_by: 'sam@gmail.com',
    created_on: 'Feb 20 2023, 11:09 pm',
    last_edited: 'Feb 20 2023, 11:09 pm',
    Edited_by: 'john@gmail.com',
  },
  {
    id: 4,
    title: 'Hardware Request',
    status: 'draft',
    desc: 'this is description',
    created_by: 'sam@gmail.com',
    created_on: 'Feb 20 2023, 11:09 pm',
    last_edited: 'Feb 20 2023, 11:09 pm',
    Edited_by: 'john@gmail.com',
  },
  {
    id: 5,
    title: 'Access Request',
    status: 'draft',
    desc: 'this is description',
    created_by: 'sam@gmail.com',
    created_on: 'Feb 20 2023, 11:09 pm',
    last_edited: 'Feb 20 2023, 11:09 pm',
    Edited_by: 'john@gmail.com',
  },
  {
    id: 6,
    title: 'Equipment Request',
    status: 'draft',
    desc: 'Training Request',
    created_by: 'sam@gmail.com',
    created_on: 'Feb 20 2023, 11:09 pm',
    last_edited: 'Feb 20 2023, 11:09 pm',
    Edited_by: 'john@gmail.com',
  },
  {
    id: 7,
    title: 'Travel Request',
    status: 'draft',
    desc: 'Training Request',
    created_by: 'sam@gmail.com',
    created_on: 'Feb 20 2023, 11:09 pm',
    last_edited: 'Feb 20 2023, 11:09 pm',
    Edited_by: 'john@gmail.com',
  },
];

export const categoryData = [
  {
    categories: [
      {
        name: 'Category 1',
        subcategories: [
          { name: 'Sub Category 1-1' },
          { name: 'Sub Category 1-2' },
          { name: 'Sub Category 1-3' },
        ],
      },
      {
        name: 'Category 2',
        subcategories: [
          { name: 'Sub Category 2-1' },
          { name: 'Sub Category 2-2' },
          { name: 'Sub Category 2-3' },
        ],
      },
      {
        name: 'Category 3',
        subcategories: [
          { name: 'Sub Category 3-1' },
          { name: 'Sub Category 3-2' },
          { name: 'Sub Category 3-3' },
        ],
      },
    ],
  },
];
