/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Text,
  useColorModeValue,
  Checkbox,
  Box,
  DrawerOverlay,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';
import CustomButton from '../../../components/Button/SimpleButton';
import { LABELS } from '../../../utils/enums/labels';
import { COLORS } from '../../../utils/enums/colors';

const LanguageDrawer = ({ openDrawer, setCloseDrawer, title, setLanguage }) => {
  const [stateValue, setStateValue] = useState([]);
  const [checkList, setCheckList] = useState([]);
  const { languages } = useSelector((state) => state.locales);
  const { selectedLanguages } = useSelector((state) => state.locales);

  useEffect(() => {
    const updatedLanguages = languages.map((language) => {
      const selectedLanguage = selectedLanguages?.find(
        (selected) => selected.locale_code === language.locale_code
      );
      if (selectedLanguage && selectedLanguage.is_active) {
        return selectedLanguage;
      }
      return { ...language, is_active: false };
    });
    setCheckList(updatedLanguages);
  }, [languages, selectedLanguages]);

  const handleOnChangeOfCheckBox = (value, index, item) => {
    setCheckList((prevState) => {
      const selected = { ...prevState[index] };
      selected.is_active = value;
      const newState = [...prevState];
      newState[index] = selected;
      return newState;
    });
  };
  const handleOnChangeForAll = (value) => {
    setCheckList((prevState) => {
      const newState = prevState.map((item) => {
        const newItem = { ...item };
        newItem.is_active = value;
        return newItem;
      });
      return newState;
    });
  };

  return (
    <Drawer
      size="lg"
      placement="right"
      onClose={setCloseDrawer}
      isOpen={openDrawer}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Flex alignItems="center" gap="10px">
            <IconButton
              colorScheme="blue"
              aria-label="Close Drawer"
              size="sm"
              borderRadius="50%"
              minW="1.5rem"
              height="1.5rem"
              variant="outline"
              icon={<ArrowBackIcon />}
              onClick={setCloseDrawer}
            />
            <Text fontSize="16px">{title}</Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <Box mb="20px">
            <Checkbox
              isChecked={checkList.every((item) => item.is_active)}
              onChange={(e) => {
                handleOnChangeForAll(e.target.checked);
              }}
              data-testid="LanguageContentDependentCheckbox"
            >
              All
            </Checkbox>
          </Box>
          <Grid templateColumns="repeat(2, 1fr)" gap="30px">
            {languages.map((item, index) => (
              <GridItem>
                <Checkbox
                  isChecked={checkList[index]?.is_active || false}
                  onChange={(e) => {
                    handleOnChangeOfCheckBox(e.target.checked, index, item);
                  }}
                  data-testid="LanguageContentDependentCheckbox"
                >
                  {item.name}
                </Checkbox>
              </GridItem>
            ))}
          </Grid>
        </DrawerBody>
        <DrawerFooter>
          <CustomButton
            variant="outline"
            pl="25px"
            pr="25px"
            buttonColor={LABELS.SIGNATURE_DRAWER_COLOR}
            buttonText={LABELS.ACTIONS.DISCARD}
            style={{
              border: '1px solid #d1d5db',
              // background: COLORS.SIGNATURE_DRAWER_BG,
              // height: '38px',
              marginRight: '10px',
              color: COLORS.SIGNATURE_DRAWER_COLOR,
            }}
            _hover={{ color: useColorModeValue(COLORS.BLACK, 'white') }}
            onClick={setCloseDrawer}
            // size="lg"
            classname="calculatedHeight"
          />
          <CustomButton
            variant="solid"
            pl="25px"
            pr="25px"
            // style={{ height: '38px' }}
            buttonColor="customBlue"
            buttonText={LABELS.ACTIONS.ADD}
            onClick={() => {
              setLanguage(checkList);
              setCloseDrawer();
            }}
            // size="lg"
            isLoading={false}
            data-testid="LanguageButtonAdd"
            classname="calculatedHeight"
          />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

LanguageDrawer.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  setCloseDrawer: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  setLanguage: PropTypes.func.isRequired,
};

export default LanguageDrawer;
