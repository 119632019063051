import { get, isEmpty } from 'lodash';
import { getHierarchyById, getQueueListForWholeTenant, getTitlesHierarchy } from '../../components/dynamicExpressionWithTags/dynamicExpressionWithTags.helper';
import { SCOPE_MAP } from '../../utils/enums/selectOption';
import { FieldTypes } from '../../utils/enums/types';
import { DROPDOWN_TYPES, SUPPORTED_FIELD_TYPES, getOperators } from './filters.constants';
import { availableUsersListInTenantLevel } from '../../utils/helper/roleBasedAccess';
import { selectOptionsChannels } from '../DynamicRenderer/customTemplateModal/CustomTemplateModalLayout';
import { getSelectedFieldHierarchyMap, getSelectedHierarchy } from '../../components/selectFieldFromTemplateWithDelete/utils';

const TEMPLATE_FILTERS_MAP = {
  [FieldTypes.TEXT]: SUPPORTED_FIELD_TYPES.TEXT,
  [FieldTypes.EMAIL]: SUPPORTED_FIELD_TYPES.TEXT,
  [FieldTypes.RICH_TEXT]: SUPPORTED_FIELD_TYPES.TEXT,

  [FieldTypes.NUMBER]: SUPPORTED_FIELD_TYPES.NUMBER,

  [FieldTypes.DATE]: SUPPORTED_FIELD_TYPES.DATE,

  [FieldTypes.DROPDOWN]: SUPPORTED_FIELD_TYPES.DROPDOWN,
  [FieldTypes.TAGS]: SUPPORTED_FIELD_TYPES.DROPDOWN,

  [FieldTypes.BOOLEAN]: SUPPORTED_FIELD_TYPES.BOOLEAN,

  [FieldTypes.METRICS]: SUPPORTED_FIELD_TYPES.METRICS,
};

// eslint-disable-next-line import/prefer-default-export
export const getTransformedTemplateFields = async ({ selectedTemplate, hierarchies }) => {
  const fields = selectedTemplate?.fields;

  if (!Array.isArray(fields)) {
    return [];
  }

  const tempData = await Promise.all(fields.map(async (field) => {
    if (!field || (field.scope && field.scope !== SCOPE_MAP.TICKET)) {
      return null;
    }

    const type = TEMPLATE_FILTERS_MAP?.[field?.type || ''];
    if (!type) {
      return null;
    }

    const payload = {
      id: field?.id || '',
      label: field?.label || '',
      type,
      operators: getOperators(type),
    };

    if (payload.type === FieldTypes.METRICS || payload.type === FieldTypes.SLA) {
      payload.type = FieldTypes.METRICS;
    }

    if (payload.type === FieldTypes.DROPDOWN) {
      let options = [];
      const collectionType = field?.type_based_attributes?.collection?.collection_type;
      const specialField = field?.type_based_attributes?.collection?.specialField;

      if (collectionType === 'STATIC') {
        const values = field?.type_based_attributes?.collection?.staticValues;
        options = Array.isArray(values) ? values : [];
      } else if (collectionType === 'DEPENDENT') {
        if (selectedTemplate?.field_hierarchy_map?.length && hierarchies?.length) {
          const selectedMapping = getSelectedFieldHierarchyMap(
            selectedTemplate?.field_hierarchy_map, field.id
          );
          if (selectedMapping) {
            const selectedHierarchy = getSelectedHierarchy(
              hierarchies, selectedMapping.heirachyListId
            );
            if (selectedHierarchy) {
              const titles = getTitlesHierarchy(
                selectedHierarchy?.hierarchy || [],
                selectedMapping);
              if (titles[field.id]) {
                options = titles[field.id].map((item) => ({ value: item, label: item }));
              }
            }
          }
        }
      } else if (specialField) {
        if (specialField === 'workflowState') {
          const values = selectedTemplate?.workflow?.workflow_status || [];
          options = Array.isArray(values) ? values.map((ins) => ({
            label: ins?.data?.label,
            value: ins?.data?.label,
          })) : [];
        } else if (specialField === 'queue') {
          const listFilter = get(field, 'type_based_attributes.collection.list');
          const isWhiteList = get(field, 'type_based_attributes.collection.isWhiteList');
          options = await getQueueListForWholeTenant(listFilter, isWhiteList);
        } else if (specialField === 'actor') {
          const users = await availableUsersListInTenantLevel();
          options = users || [];
        } else if (specialField === 'source') {
          options = selectOptionsChannels;
        }
      }

      payload.defaultOptions = options;
    }

    return payload;
  }));

  // Filter out any null values resulting from invalid fields
  return tempData.filter((item) => item !== null);
};
