import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardBody,
  Grid,
  GridItem,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
// import { RoleSettingsTabTypes } from '../../../utils/enums/types';
// import { LABELS } from '../../../utils/enums/labels';
import { APPLYED_ALL_ROLE } from './roleCollection';
import styles from './rolecontent.module.scss';
import { updateRuleModalId } from '../../../redux/WorkFlowRuleSlice/WorkFlowRuleReducer';
import { checkForV2 } from '../../../utils/helper';

// const SETTINGS_TABS = [
//   RoleSettingsTabTypes.ALL_ROLE,
//   RoleSettingsTabTypes.RESTRICT_TRANSITION_ROLE,
//   RoleSettingsTabTypes.VALIDATE_DETAILS_ROLE,
// ];
// const SETTINGS_TABS_LABEL_MAP = {
//   [RoleSettingsTabTypes.ALL_ROLE]: LABELS.TITLE.ALL_RULES,
//   [RoleSettingsTabTypes.RESTRICT_TRANSITION_ROLE]:
//     LABELS.TITLE.RESTRICT_TRANSTION,
//   [RoleSettingsTabTypes.VALIDATE_DETAILS_ROLE]: LABELS.TITLE.VALIDATE_DETAILS,
//   [RoleSettingsTabTypes.PERFORM_ACTION_ROLE]: LABELS.TITLE.PERFORM_ACTIONS,
// };
const RoleContent = ({ handleRuleModalOpen, addedRules }) => {
  const dispatch = useDispatch();
  const { ruleModalId } = useSelector((state) => state.workFlowRules);
  // const [activeRoleSetting, setActiveRoleSetting] = useState(
  //   RoleSettingsTabTypes.ALL_ROLE
  // );

  const filteredRules = useMemo(() => {
    if (checkForV2()) {
      return APPLYED_ALL_ROLE;
    }
    return APPLYED_ALL_ROLE.filter((r) => {
      if (!r) {
        return false;
      }
      if (addedRules) {
        return addedRules && !addedRules[r.modalId];
      }
      return true;
    });
  }, [addedRules]);

  return (
    <>
      {/* <CustomInput
        onChange={(e) => {}}
        value=""
        placeholder="Search Rule"
        isError={false}
        helperText=" "
        errorMessage=""
        mt="20px"
      />
      <Flex as="b" gap="18px" pb="30px" pt="30px">
        {SETTINGS_TABS?.map((settingsTabType) => (
          <Box
            key={settingsTabType}
            id={settingsTabType}
            px="5px"
            borderBottomColor={
              activeRoleSetting === settingsTabType &&
              useColorModeValue(LIGHT, DARK)
            }
            color={
              activeRoleSetting === settingsTabType
                ? useColorModeValue('black', DARK)
                : DEFAULT
            }
            borderBottomWidth={activeRoleSetting === settingsTabType && '4px'}
            onClick={() => {
              setActiveRoleSetting(settingsTabType);
              dispatch(updateRuleModalId(''));
            }}
            cursor="pointer"
          >
            {SETTINGS_TABS_LABEL_MAP[settingsTabType]}
          </Box>
        ))}
      </Flex> */}
      {true && (
        <Grid
          templateColumns="repeat(1, 1fr)"
          gap="24px"
          data-testid="RoleContentGrid"
        >
          {filteredRules.map((field, index) => {
            if (!field) {
              return null;
            }
            return (
              <GridItem
                key={`rule_${index + 1}`}
                w="100%"
                data-testid="RoleContentCardGridItem"
              >
                <Card
                  direction={{ base: 'column', sm: 'row' }}
                  overflow="hidden"
                  variant="outline"
                  className={`${styles.cardStyle} ${
                    field?.modalId === ruleModalId && styles.selectCard
                  }`}
                  onClick={async () => {
                    await dispatch(updateRuleModalId(field?.modalId));
                    handleRuleModalOpen(field?.modalId);
                  }}
                  data-testid="RoleContentCard"
                >
                  <Box
                    className={styles.boxSvg}
                    data-testid="RoleContentReacrSvgBox"
                  >
                    <ReactSVG src={`${field.image}`} alt="image" />
                  </Box>
                  <Stack data-testid="RoleContentStack">
                    <CardBody
                      className={styles.cardbody}
                      data-testid="RoleContentCardBody"
                    >
                      <div
                        style={{ marginLeft: '20px' }}
                        data-testid="RoleContentDiv"
                      >
                        <Heading
                          className={styles.cardheading}
                          data-testid="RoleContentHeading"
                        >
                          {field.label}
                        </Heading>
                        <Text
                          className={styles.carddesc}
                          data-testid="RoleContentText"
                        >
                          {field.description}
                        </Text>
                      </div>
                    </CardBody>
                  </Stack>
                </Card>
              </GridItem>
            );
          })}
        </Grid>
      )}
      {/* {activeRoleSetting === RoleSettingsTabTypes.RESTRICT_TRANSITION_ROLE && (
        <Grid templateColumns="repeat(1, 1fr)" gap="24px">
          {APPLYED_ALL_ROLE.slice(0, 3).map((field) => (
            <GridItem w="100%">
              <Card
                direction={{ base: 'column', sm: 'row' }}
                overflow="hidden"
                variant="outline"
                className={`${styles.cardStyle} ${
                  field?.modalId === ruleModalId && styles.selectCard
                }`}
                onClick={() => {
                  dispatch(updateRuleModalId(field?.modalId));
                }}
              >
                <Box className={styles.boxSvg}>
                  <ReactSVG src={`${field.image}`} alt="image" />
                </Box>
                <Stack>
                  <CardBody className={styles.cardbody}>
                    <div style={{ marginLeft: '20px' }}>
                      <Heading className={styles.cardheading}>
                        {field.label}
                      </Heading>
                      <Text className={styles.carddesc}>
                        {field.description}
                      </Text>
                    </div>
                  </CardBody>
                </Stack>
              </Card>
            </GridItem>
          ))}
        </Grid>
      )}
      {activeRoleSetting === RoleSettingsTabTypes.VALIDATE_DETAILS_ROLE && (
        <Grid templateColumns="repeat(1, 1fr)" gap="24px">
          {APPLYED_ALL_ROLE.slice(3).map((field) => (
            <GridItem w="100%">
              <Card
                direction={{ base: 'column', sm: 'row' }}
                overflow="hidden"
                variant="outline"
                className={`${styles.cardStyle} ${
                  field?.modalId === ruleModalId && styles.selectCard
                }`}
                onClick={() => {
                  dispatch(updateRuleModalId(field?.modalId));
                }}
              >
                <Box className={styles.boxSvg}>
                  <ReactSVG src={`${field.image}`} alt="image" />
                </Box>
                <Stack>
                  <CardBody className={styles.cardbody}>
                    <div style={{ marginLeft: '20px' }}>
                      <Heading className={styles.cardheading}>
                        {field.label}
                      </Heading>
                      <Text className={styles.carddesc}>
                        {field.description}
                      </Text>
                    </div>
                  </CardBody>
                </Stack>
              </Card>
            </GridItem>
          ))}
        </Grid>
      )} */}
    </>
  );
};

RoleContent.propTypes = {
  handleRuleModalOpen: PropTypes.func.isRequired,
  addedRules: PropTypes.object,
};

RoleContent.defaultProps = {
  addedRules: null,
};

export default RoleContent;
