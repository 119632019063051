/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Box, Grid, useColorModeValue } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { BORDER_DARK } from '../../../utils/enums/colors';
import TabSectionLayout from './TabSectionLayout';
import PlainSectionLayout from './PlainSectionLayout';
import CollapsibleSectionLayout from './CollapsibleSectionLayout';
import { renderFieldsBasedonType } from './utils/RenderFieldsBasedOnType';
import PreviewTable from './PreviewTable';
import PreviewCard from './PreviewCard';

const PreviewLayout = ({ items, layoutType, viewType }) => {
  const [itemsState, setItemsState] = useState([]);

  useEffect(() => {
    setItemsState(items);
  }, [items]);

  const previewRenderer = (itemsArr) =>
    itemsArr.map((item) =>
      item.type !== 'component' ? (
        <Box
          key={item.id}
          position="relative"
          gridColumnEnd={`span ${item?.width}`}
          gridRowEnd={`span ${item?.height || 1}`}
          height="100%"
          borderRadius="6px"
          display="block"
          alignItems="center"
          justifyContent="space-between"
          borderColor={useColorModeValue('rgba(0,0,0,0.25)', BORDER_DARK)}
          data-testid="PreviewLayoutBox"
        >
          <Box p="2">{item?.label}</Box>
          {renderFieldsBasedonType(item)}
        </Box>
      ) : (
        <Box
          key={item.id}
          // gridColumnEnd="span 12"
          gridColumnEnd={`span ${item?.width}`}
          gridRowEnd="auto"
          border="1px"
          borderStyle="solid"
          borderRadius="10px"
          borderColor={useColorModeValue('rgba(0,0,0,0.25)', BORDER_DARK)}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          data-testid="PreviewLayoutTabBox"
        >
          {item?.sectionType === 'TAB' ? (
            <TabSectionLayout item={item} previewRenderer={previewRenderer} />
          ) : item?.sectionType === 'PLAIN' ? (
            <PlainSectionLayout item={item} previewRenderer={previewRenderer} />
          ) : (
            <CollapsibleSectionLayout
              item={item}
              previewRenderer={previewRenderer}
              isExpand={item.sectionType === 'COLLAPSIBLE' ? [0] : []}
            />
          )}
        </Box>
      )
    );

  return viewType === 'TABLE' ? (
    <PreviewTable data={items} />
  ) : viewType === 'LIST' || viewType === 'SEARCH' ? (
    <PreviewCard data={items} />
  ) : (
    <Grid
      border="1px"
      borderStyle="solid"
      mx="8"
      py="4"
      pl="4"
      pr="4"
      width={layoutType === 'MOBILE' ? '400px' : '100%'}
      borderRadius="10px"
      borderColor={useColorModeValue('rgba(0,0,0,0.35)', BORDER_DARK)}
      templateColumns="repeat(12, 1fr)"
      gap={4}
      data-testid="PreviewLayoutGrid"
    >
      {previewRenderer(itemsState)}
    </Grid>
  );
};

PreviewLayout.propTypes = {
  items: PropTypes.array.isRequired,
  layoutType: PropTypes.string.isRequired,
  viewType: PropTypes.string.isRequired,
};

export default PreviewLayout;
