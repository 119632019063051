import axiosInstance from '../../../utils/axios/axios';
import { dummyDefaultRole } from '../DummyData';

export const createNewWorkflowInMasterList = async (
  value,
  defaultErrorMessage
) => {
  try {
    const requestBody = {
      data: {
        ...value,
      },
    };
    const response = await axiosInstance.post(`/workflows/create`, requestBody);
    return {
      response: response?.data,
      error: null,
    };
  } catch (error) {
    return {
      error: error?.response?.data?.message || defaultErrorMessage,
      response: null,
    };
  }
};

export const updateWorkflow = async (value, id, defaultErrorMessage) => {
  try {
    const requestBody = {
      data: {
        ...value,
      },
    };
    const response = await axiosInstance.patch(
      `/workflows/update?$filter=id eq ${id}`,
      requestBody
    );
    return {
      response: response?.data,
      error: null,
    };
  } catch (error) {
    return {
      error: error?.response?.data?.message || defaultErrorMessage,
      response: null,
    };
  }
};

export const payloadCrater=(responseWorkflow)=>{
  delete responseWorkflow.created_on
  responseWorkflow.updated_on = new Date().toString()
  delete responseWorkflow.created_by
  delete responseWorkflow.updated_by
  delete responseWorkflow.lifecycle_status
  delete responseWorkflow.status
  delete responseWorkflow.status
  delete responseWorkflow.deleted_seq
  delete responseWorkflow.tenant_id
  delete responseWorkflow.version
  delete responseWorkflow.slug
  delete responseWorkflow.cloned_from
  responseWorkflow.workflow_rules= { list: [...dummyDefaultRole] }
  return responseWorkflow
}