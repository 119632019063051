import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { Box, Flex } from '@chakra-ui/react';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { renderTagsCategory2 } from '../MetricsAndSLA/Tags/TagBuilder';
import addBtn from '../../../assets/icons/addBtn.svg';
import { formatLabel } from './utils';

const CustomSelectWithTag = ({
  label,
  selectBoxOption,
  addDataForTags,
  tagsData,
  tagsMapping,
  deleteTags,
}) => {
  const [selectedBoxValue, setSelectedBoxValue] = useState('');
  return (
    <Flex
      mt="10px"
      gap="30px"
      flexWrap="wrap"
      data-testid="CustomSelectWithTabContainerFlex"
    >
      <Box flex="1" data-testid="CustomSelectWithTabContainerBox">
        <div
          className="inline-flex items-end gap-[10px] w-full"
          data-testid="CustomSelectWithTabContainerDiv"
        >
          <Box flex="1" data-testid="CustomSelectWithTabContinerInnerBox">
            <CustomSelectBox
              label={formatLabel(label)}
              options={selectBoxOption}
              value={selectedBoxValue}
              onChange={(e) => setSelectedBoxValue(e)}
              isMulti
            />
          </Box>
          <ReactSVG
            src={addBtn}
            width="32px"
            height="38px"
            onClick={() => {
              addDataForTags(label, selectedBoxValue);
              setSelectedBoxValue('');
            }}
            style={{ cursor: 'pointer' }}
          />
        </div>

        <div
          className="mt-[10px]"
          data-testid="CustomSelectWithTabDataContainerDiv"
        >
          {renderTagsCategory2({
            key: label,
            data: tagsData,
            title: formatLabel(label),
            renderTagLabel: (data) => tagsMapping[data],
            deleteTags,
          })}
        </div>
      </Box>
    </Flex>
  );
};

CustomSelectWithTag.propTypes = {
  label: PropTypes.string.isRequired,
  selectBoxOption: PropTypes.array.isRequired,
  addDataForTags: PropTypes.func.isRequired,
  tagsData: PropTypes.object.isRequired,
  tagsMapping: PropTypes.object.isRequired,
  deleteTags: PropTypes.func.isRequired,
};

export default CustomSelectWithTag;
