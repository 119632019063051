/* eslint-disable import/no-extraneous-dependencies */
import dayjs from 'dayjs';

import { LifecycleStatusTypes } from '../../utils/enums/lifecycleStatus';

import styles from './cardLayout.module.scss';

export const STATUSES = [
  { value: '*', label: 'All' },
  { value: LifecycleStatusTypes.DRAFT, label: 'Draft' },
  { value: LifecycleStatusTypes.PENDING_REVIEW, label: 'Pending Review' },
  // { value: LifecycleStatusTypes.REJECTED, label: 'Rejected' },
  { value: LifecycleStatusTypes.APPROVED, label: 'Approved' },
];

export const createFilterDropdown = [
  { label: 'Updated On', value: 'updated_on' },
  { label: 'Created On', value: 'created_on' },
];

export const createFilterDropdownMap = createFilterDropdown.reduce(
  (prev, curr) => {
    prev[curr.value] = curr.label;
    return prev;
  },
  {}
);

export const statusesMap = STATUSES.reduce((prev, curr) => {
  prev[curr.value] = curr.label;
  return prev;
}, {});

const getLifeCycleFilter = (lifeCycle) => `lifecycle_status eq '${lifeCycle}'`;

const getOrderByFilter = (sortBy, sortType) => `${sortBy} ${sortType}`;

export const getDateRange = (dateRange) => {
  if (!Array.isArray(dateRange) || dateRange.length !== 1) {
    return undefined;
  }

  let [start, end] = dateRange[0].split('-');
  start = start.trim().split('/').reverse().join('/');
  end = end.trim().split('/').reverse().join('/');

  start = dayjs(start).startOf('day').utc().format();
  end = dayjs(end).endOf('day').utc().format();

  return [start, end];
};

export const setDateRange = (dateRange) => {
  if (dateRange.length === 2) {
    const [startDate, endDate] = dateRange;
    return [
      `${dayjs(startDate).format('DD/MM/YYYY')} - ${dayjs(endDate).format(
        'DD/MM/YYYY'
      )}`,
    ];
  }
  return [];
};

const getDateRangeFilter = (dateRangeColumn, dateRange) => {
  const [start, end] = getDateRange(dateRange);
  return `${dateRangeColumn} ge '${start}' and ${dateRangeColumn} le '${end}'`;
};

const checkIfLifeCycleIsApplied = (appliedFilters) => {
  if (
    appliedFilters?.selectedLifeCycle?.value &&
    appliedFilters.selectedLifeCycle.value !== '*'
  ) {
    return true;
  }
  return false;
};

const checkIfSortIsApplied = (appliedFilters) => {
  if (appliedFilters.selectedSortBy?.value && appliedFilters.selectedSortType) {
    return true;
  }
  return false;
};

export const checkIfDateRangeIsApplied = (appliedFilters) => {
  if (
    appliedFilters?.dateRangeColumn?.value &&
    appliedFilters?.dateRanges?.length
  ) {
    return true;
  }
  return false;
};

const getDateFilter = (queryStr, appliedFilters) => {
  let str = queryStr;

  if (queryStr) {
    str += ' and ';
  }

  str += getDateRangeFilter(
    appliedFilters.dateRangeColumn.value,
    appliedFilters.dateRanges
  );

  return str;
};

export const getFilterQuery = (appliedFilters) => {
  let queryStr = '';
  let orderBy = '';

  // If lifeCycle was selected the apply it
  if (checkIfLifeCycleIsApplied(appliedFilters)) {
    queryStr += getLifeCycleFilter(appliedFilters.selectedLifeCycle.value);
  }

  // If sortBy was selected then apply it
  if (checkIfSortIsApplied(appliedFilters)) {
    orderBy += getOrderByFilter(
      appliedFilters.selectedSortBy.value,
      appliedFilters.selectedSortType
    );
  }

  // If dateRange is selected
  if (checkIfDateRangeIsApplied(appliedFilters)) {
    queryStr = getDateFilter(queryStr, appliedFilters);
  }

  return {
    query: queryStr || null,
    orderBy: orderBy || null,
  };
};

export const getColorForStatus = (lifecycleStatus) => {
  switch (lifecycleStatus) {
    case LifecycleStatusTypes.DRAFT:
      return styles.draftStatusColor;
    case LifecycleStatusTypes.PUBLISHED:
      return styles.publishedStatusColor;
    case LifecycleStatusTypes.APPROVED:
      return styles.approvedStatusColor;
    case LifecycleStatusTypes.ARCHIVED:
      return styles.archivedStatusColor;
    case LifecycleStatusTypes.REJECTED:
      return styles.rejectedStatusColor;
    case LifecycleStatusTypes.PENDING_REVIEW:
      return styles.pendingReviewStatusColor;
    default:
      return '';
  }
};
export const WIDTH_SIZE = {
  SM: '32px',
  LG: '36px',
  STANDARD_SIZE: 1440
};
