/* eslint-disable no-lone-blocks */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable-next-line no-lone-blocks */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@chakra-ui/layout';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { ReactSVG } from 'react-svg';
import { v4 as uuidv4 } from 'uuid';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';

import {
  FormLabel,
  Wrap,
  WrapItem,
  IconButton,
  useColorModeValue,
} from '@chakra-ui/react';
import CustomButton from '../Button/SimpleButton';
import CustomSelect from '../SelectBox/Select';
import { DARK, LIGHT } from '../../utils/enums/colors';
import { LABELS } from '../../utils/enums/labels';
// eslint-disable-next-line import/no-cycle
import {
  createAPIReadyData,
  createRenderableDataFromValues,
  stripEmptyArraysFromObject,
} from './dynamicSelectWithTags.helper';

const DynamicMutliSelectWithTags = ({
  label,
  selectOptions,
  onChange,
  values,
  labelWeight,
  runHandlerOnEmptyTags,
}) => {
  const [selectedTag, setSelectedTags] = useState('');
  const [tags, setTags] = useState([]);

  const addTag = ({ value }) => {
    const newTags = _cloneDeep(tags);
    if (newTags.findIndex((tag) => tag.value === value.value) > -1) return;
    newTags.push(value);
    setTags(newTags);
  };

  const removeTag = ({ index }) => {
    const newTags = _cloneDeep(tags);
    newTags.splice(index, 1);
    setTags(newTags);
  };

  useEffect(() => {
    if (!_isEmpty(tags) || runHandlerOnEmptyTags) {
      const apiReadyData = createAPIReadyData(tags);
      onChange(stripEmptyArraysFromObject(apiReadyData));
    }
  }, [tags]);

  useEffect(() => {
    if (!_isEmpty(values)) {
      const renderableDataFromValues = createRenderableDataFromValues(
        values,
        selectOptions
      );
      setTags(renderableDataFromValues);
    }
  }, []);

  return (
    <Box data-testid="DynamicMutliSelectWithTagsContainerBox">
      <FormLabel
        fontWeight={labelWeight}
        marginTop="20px"
        marginBottom="10px"
        data-testid="DynamicMutliSelectWithTagsFormLabel"
      >
        {label}
      </FormLabel>
      <Flex
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        gap="20px"
        mb="30px"
        data-testid="DynamicMutliSelectWithTagsFlex"
      >
        <CustomSelect
          options={selectOptions}
          value={selectedTag}
          onChange={(value) => {
            setSelectedTags(value);
          }}
          placeholder="select option"
          // eslint-disable-next-line react/no-unstable-nested-components
          getOptionLabel={(e) => (
            <Flex align="center" className="text-[14px]">
              {e.icon && (
                <ReactSVG src={e.icon} pr="7px" width="15px" height="13px" />
              )}
              <span className="ml-1">{e.label}</span>
            </Flex>
          )}
          id="DynamicMutliSelectWithTagsSelect"
        />
        <CustomButton
          className={` ml-4 border-[#2563EB] `}
          color={useColorModeValue(LIGHT, DARK)}
          id="button-addDynamicSelectWithTag-role"
          buttonText={LABELS.ACTIONS.ADD}
          variant="outline"
          height="32px"
          mt="0px"
          width="66px"
          padding="0px 30px"
          onClick={() => {
            if (selectedTag) {
              addTag({
                value: selectedTag,
              });
            }
            setSelectedTags('');
          }}
        />
      </Flex>
      {tags.length > 0 && (
        <Wrap
          spacing="15px"
          mt="22px"
          data-testid="DynamicMutliSelectWithTagsContainerWrap"
        >
          <Wrap
            spacing={2}
            pl="10px"
            pt="3px"
            pb="3px"
            maxW="380px"
            border={`1px solid ${LIGHT}`}
            borderRadius="4px"
            key={uuidv4()}
            data-testid="DynamicMutliSelectWithTagsWrap"
          >
            <WrapItem data-testid="DynamicMutliSelectWithTagsWrapItem">
              <Wrap data-testid="DynamicMutliSelectWithTagsInnerWrap">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  data-testid="DynamicMutliSelectWithTagsInnerBox"
                >
                  {`${label} : `}
                </Box>
                {tags.map((value, index) => (
                  <Flex
                    key={value}
                    justifyContent="center"
                    alignItems="center"
                    bg="#EBEBEB"
                    borderRadius="4px"
                    fontWeight={500}
                    height="28px"
                    maxW="350px"
                    paddingLeft="1rem"
                    data-testid="DynamicMutliSelectWithTagsInnerMapFlex"
                  >
                    <Box data-testid="DynamicMutliSelectWithTagsLabelBox">
                      {value?.label}
                    </Box>
                    <IconButton
                      background="ffffff"
                      icon={<SmallCloseIcon />}
                      onClick={() =>
                        removeTag({
                          index,
                          value,
                        })
                      }
                      data-testid="DynamicMutliSelectWithTagsIcomButton"
                    />
                  </Flex>
                ))}
              </Wrap>
            </WrapItem>
          </Wrap>
        </Wrap>
      )}
    </Box>
  );
};

DynamicMutliSelectWithTags.propTypes = {
  label: PropTypes.string.isRequired,
  selectOptions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
  labelWeight: PropTypes.string,
  runHandlerOnEmptyTags: PropTypes.bool,
};

DynamicMutliSelectWithTags.defaultProps = {
  labelWeight: 600,
  runHandlerOnEmptyTags: false,
};

export default DynamicMutliSelectWithTags;
