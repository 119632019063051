import axiosInstance from '../../../utils/axios/axios';

export const reducerForState = (state, action) => {
  switch (action.type) {
    case 'UPDATE_STATE':
      return { ...state, [action.name]: action.value };
    case 'RESET_STATE':
      return {};
    case 'SET_STATE':
      return { ...state, ...action.value };
    default:
      return state;
  }
};
export const makeApiCallForActor = async () => {
  try {
    const response = await axiosInstance.get('/actors/list', {
      params: {
        $top: 1000,
        $skip: 0,
        $select: 'id,name',
      },
    });
    return response?.data;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return {
      error: message,
      response: null,
    };
  }
};
export const makeApiCallForField = async (id) => {
  try {
    const option = `id eq ${id}`;
    const response = await axiosInstance.get('/templates/list', {
      params: {
        $top: 1,
        $skip: 0,
        $select: 'fields',
        $filter: option,
      },
    });
    return response?.data?.rows[0];
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return {
      error: message,
      response: null,
    };
  }
};

export const createNewActorInMasterList = async (value) => {
  try {
    const requestBody = {
      data: {
        ...value,
      },
    };
    const response = await axiosInstance.post(`/actors/create`, requestBody);
    return {
      response: response?.data,
      error: null,
    };
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return {
      error: message,
      response: null,
    };
  }
};
export const makeApiCallForWorkflowFromTemplate = async (id) => {
  try {
    const option = `id eq ${id}`;
    const response = await axiosInstance.get('/templates/list', {
      params: {
        $top: 1,
        $skip: 0,
        $select: 'workflow',
        $filter: option,
      },
    });
    return response?.data?.rows[0];
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return {
      error: message,
      response: null,
    };
  }
};
export const makeApiCallForWorkflowList = async (temp_id) => {
  try {
    const response = await axiosInstance.get('/workflows/list', {
      params: {
        $top: 1000,
        $skip: 0,
        $select:
          'id,name,workflow_status,workflow_transitions,workflow_category,template_id,cloned_from',
      },
    });
    return response?.data?.rows.filter(({ template_id }) => temp_id ? (!template_id || template_id == temp_id):!template_id);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return {
      error: message,
      response: null,
    };
  }
};

export const makeApiCallForActorsFromTemplate = async (id) => {
  try {
    const option = `id eq ${id}`;
    const response = await axiosInstance.get('/templates/list', {
      params: {
        $top: 1,
        $skip: 0,
        $select: 'actors',
        $filter: option,
      },
    });
    return response?.data?.rows[0];
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return {
      error: message,
      response: null,
    };
  }
};
