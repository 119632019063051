import _capitalize from 'lodash/capitalize';
import Joi from 'joi';
import { getTenantData } from '../../../helpers/tenantUrl';

export const SIGNATURE_MAP_KEY = {
  NAME: 'name',
  DESCRIPTION: 'description',
  SIGNATURE: 'signature',
  ASSOCIATIONS: 'associations',
  ACTORS: 'actors',
  TEAMS: 'teams',
  QUEUES: 'queues',
  TENANT_ID: 'tenant_id',
  STATUS: 'status',
};

export const STATUS_OPTION = [
  { label: 'Draft', value: 'DRAFT' },
  { label: 'Published', value: 'PUBLISHED' },
];

export const getStatusObject = (value, status) => {
  if (value === '') {
    return '';
  }
  const result = status?.find((obj) => obj.value === value);
  return result || '';
};

export const formatLabel = (label) => {
  const formattedLabel = _capitalize(label);

  if (formattedLabel.endsWith('s')) {
    return formattedLabel.slice(0, -1);
  }

  return formattedLabel;
};

const LIFECYCLE_STATUS = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  DELETED: 'DELETED',
};

export const createSignatureAtMasterList = Joi.object({
  name: Joi.string().required(),
  description: Joi.string(),
  signature: Joi.string().required(),
  status: Joi.string()
    .valid(...Object.values(LIFECYCLE_STATUS))
    .required(),
  associations: Joi.object({
    actors: Joi.array().min(1),
    teams: Joi.array().min(1),
    queues: Joi.array().min(1),
  })
    .or('actors', 'teams', 'queues')
    .required(),
  tenant_id: Joi.string().guid({ version: 'uuidv4' }).required(),
});

export const createSignaturePayload = (data) => {
  const payload = {
    [SIGNATURE_MAP_KEY.NAME]: data?.[SIGNATURE_MAP_KEY.NAME],
    [SIGNATURE_MAP_KEY.DESCRIPTION]: data?.[SIGNATURE_MAP_KEY.DESCRIPTION],
    [SIGNATURE_MAP_KEY.SIGNATURE]: data?.[SIGNATURE_MAP_KEY.SIGNATURE],
    [SIGNATURE_MAP_KEY.STATUS]: data?.[SIGNATURE_MAP_KEY.STATUS],
    [SIGNATURE_MAP_KEY.ASSOCIATIONS]: data?.[SIGNATURE_MAP_KEY.ASSOCIATIONS],
    [SIGNATURE_MAP_KEY.TENANT_ID]: getTenantData()?.id,
  };
  return payload;
};

export const processErrors = (errors, index = 0) => {
  const errorKeys = {};
  errors?.forEach((item) => {
    const key = item.path[index];
    errorKeys[key] = true;
  });
  return errorKeys;
};
export const getActors = (inputArray) => {
  if (inputArray.length === 0) {
    return [];
  }
  const actorsArray = [].concat(
    ...inputArray.map((item) => item?.associations?.actors)
  );
  return Array.from(new Set(actorsArray));
};
export const getNonMatchingElements = (excludeArray, targetArray) => {
  if (excludeArray.length === 0) {
    return targetArray;
  }

  return targetArray.filter((item) => !excludeArray.includes(item.value));
};
