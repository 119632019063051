import React from 'react';
import Joi from 'joi';
import { v4 as uuidv4 } from 'uuid';
import { CopyIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { isEmpty } from 'lodash';
import TableColumnText from '../../components/Text/TableColumnText';

export const QUICK_ACTION_TYPE = {
  ADD_NOTE: 'add_note',
  ADD_ATTACHMENT: 'add_attachment',
  ADD_TO_WATCHERS: 'add_toWatchers',
  ADD_CC_WATCHERS: 'add_ccWatchers',
  ADD_ACTOR: 'add_actor',
  REMOVE_CC_WATCHERS: 'remove_ccWatchers',
  REMOVE_TO_WATCHERS: 'remove_toWatchers',
  REMOVE_ACTOR: 'remove_actor',
  FIELD_CHANGE: 'field_change'
};

export const COLUMNS_QUICK_ACTION = ({ onEdit, onDelete, onClone }) => [
  {
    name: 'NAME',
    col_name: 'name',
    type: 'string',
    cellRenderer: ({ rowData }) => <TableColumnText text={rowData?.name} />,
  },
  {
    name: 'DESCRIPTION',
    col_name: 'description',
    type: 'string',
    cellRenderer: ({ rowData }) => {
      const desc = rowData?.description || '';
      return <div>{desc?.length ? desc : 'N/A'}</div>;
    },
  },
  {
    name: 'ACTIONS',
    label: 'actions',
    col_name: 'Actions',
    cellRenderer: ({ rowData }) => (
      <div className="flex gap-[10px] justify-center">
        <EditIcon className="cursor-pointer" onClick={() => onEdit(rowData)} />
        <DeleteIcon className="cursor-pointer" onClick={() => onDelete(rowData)} />
          <CopyIcon className="cursor-pointer" onClick={() => onClone(rowData)} />
      </div>
    ),
  },
];
export const QUICK_ACTION_DATA_KEYS = {
  NAME: 'name',
  DESCRIPTION: 'description',
  ADD_NOTE: 'add_note',
  CANNED_NOTE_ID: 'canned_note_id',
  FIELD_LIST_DATA: 'field_list_data',
  QUICK_ACTIONS: 'quick_actions',
  ID: 'id',
  ACTION_LIST: 'action_list',
};

export const QUICK_ACTION_LABEL = {
  ADD_EDIT_TITLE: `Add/Edit New Quick Action`,
  ADD_NOTE: 'Add Note',
  SELECT_NOTE: 'Select Note',
  FIELD: 'Field',
  VALUE: 'Value',
  ADD_MORE_FIELD: 'Add More Fields',
  NAME: 'Name',
  DESCRIPTION: 'Description',
  NOTE_TYPE: 'Select Note Type'
};
export const NOTE_TYPE_OPTION = [
  { value: 'private', label: 'Private' },
  { value: 'public', label: 'Public' },
  { value: 'chase', label: 'Chase' },
  { value: 'resolution', label: 'Resolution' }
];

export const createPayloadForQuickAction = (data) => {
  const payload = {
    [QUICK_ACTION_DATA_KEYS.ID]: data?.[QUICK_ACTION_DATA_KEYS.ID] || uuidv4(),
    [QUICK_ACTION_DATA_KEYS.NAME]: data?.[QUICK_ACTION_DATA_KEYS.NAME]?.replace(/\s+/g, ' ').trim() || '',
    // [QUICK_ACTION_DATA_KEYS.ADD_NOTE]: data?.[QUICK_ACTION_DATA_KEYS.ADD_NOTE] || false,
    // [QUICK_ACTION_DATA_KEYS.CANNED_NOTE_ID]: data?.[QUICK_ACTION_DATA_KEYS.ADD_NOTE]
    //   ? data?.[QUICK_ACTION_DATA_KEYS.CANNED_NOTE_ID]
    //   : undefined,
    [QUICK_ACTION_DATA_KEYS.DESCRIPTION]: data?.[QUICK_ACTION_DATA_KEYS.DESCRIPTION] || '',
    [QUICK_ACTION_DATA_KEYS.ACTION_LIST]: data?.[QUICK_ACTION_DATA_KEYS.ACTION_LIST] || [],
  };
  return payload;
};
export const createQuickActionValidation = (nameCheckArray) => Joi.object({
  [QUICK_ACTION_DATA_KEYS.ID]: Joi.string().guid({ version: 'uuidv4' }).required(),
  [QUICK_ACTION_DATA_KEYS.NAME]: Joi.string()
    .required()
    .custom((value, helpers) => {
      if (nameCheckArray.includes(value)) {
        return helpers.error('any.invalid', { message: 'The name must be unique.' });
      }
      return value;
    }, 'uniqueName').messages({
      'any.required': 'Name is required',
      'any.invalid': `Name can't be duplicate`,
      'string.empty': 'Name is required'
    }),
  [QUICK_ACTION_DATA_KEYS.DESCRIPTION]: Joi.string().allow('', null),
  [QUICK_ACTION_DATA_KEYS.ACTION_LIST]: Joi.array().items(Joi.object({
    canned_noted_id: Joi.string().when('action_type', { is: 'add_note',
      then: Joi.required().messages({
        'string.empty': 'Note is required',
        'any.invalid': `Note  is required`,
      }) }),
    canned_noted_type: Joi.string().when('action_type', { is: 'add_note',
      then: Joi.required().messages({
        'string.empty': 'Note type is required',
        'any.invalid': `Note type is required`,
      }) })
  }).unknown(true)).min(1).messages({
    'array.empty': 'Please add at least one action type',
    'array.min': 'Please add at least one action type',
  }),
  // [QUICK_ACTION_DATA_KEYS.ADD_NOTE]: Joi.bool(),
  // [QUICK_ACTION_DATA_KEYS.CANNED_NOTE_ID]: Joi.string().when(QUICK_ACTION_DATA_KEYS.ADD_NOTE, {
  //   is: true,
  //   then: Joi.required(),
  //   otherwise: Joi.forbidden()
  // }).messages({
  //   'any.required': 'Select a canned note',
  //   'string.empty': 'Select a canned note'
  // }),
});
export const QUICK_ACTION_TYPE_OPTIONS = [
  {
    value: QUICK_ACTION_TYPE.ADD_NOTE,
    label: 'Add Notes'
  },
  {
    value: QUICK_ACTION_TYPE.FIELD_CHANGE,
    label: 'Field Change',
  },
  {
    value: QUICK_ACTION_TYPE.ADD_ATTACHMENT,
    label: 'Add Attachment'
  },
  {
    value: QUICK_ACTION_TYPE.ADD_TO_WATCHERS,
    label: 'Add To Watchers'
  },
  {
    value: QUICK_ACTION_TYPE.REMOVE_TO_WATCHERS,
    label: 'Remove To Watchers'
  },
  {
    value: QUICK_ACTION_TYPE.ADD_CC_WATCHERS,
    label: 'Add CC Watchers'
  },
  {
    value: QUICK_ACTION_TYPE.REMOVE_CC_WATCHERS,
    label: 'Remove CC Watchers'
  },
  {
    value: QUICK_ACTION_TYPE.ADD_ACTOR,
    label: 'Add Actor',
  },
  {
    value: QUICK_ACTION_TYPE.REMOVE_ACTOR,
    label: 'Remove Actor',
  },
];
const EXTENSION_MIME_TYPE_MAP = {
  // images
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  svg: 'image/svg+xml',
  gif: 'image/gif',
  webp: 'image/webp',
  bmp: 'image/bmp',
  // videos
  mpg: 'video/mpeg',
  mpeg: 'video/mpeg',
  mov: 'video/quicktime',
  mp4: 'video/mp4',
  wmv: 'video/x-ms-wmv',
  avi: 'video/x-msvideo',
  // documents
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  txt: 'text/plain',
  text: 'text/plain',
  csv: 'text/csv',
  tsv: 'text/tab-separated-values',
  // compressed_files
  zip: 'application/zip',
  rar: 'application/vnd.rar',
  tar: 'application/x-tar',
};
export const parserForUploadAttachment = (data) => {
  if (!Array.isArray(data) || isEmpty(data)) {
    return [];
  }

  return data.map((item) => {
    const lastDotIndex = item.lastIndexOf('.');
    const fileExtension = (lastDotIndex === -1) ? '' : item.substring(lastDotIndex + 1);

    return {
      file_name: item,
      file_url: `https://nonprodcdn.blob.core.windows.net/sc-images/${item}`,
      file_type: EXTENSION_MIME_TYPE_MAP[fileExtension] || 'unknown'
    };
  });
};
