import React, { useState } from 'react';
import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import TicketTemplateSidebar from './TicketTemplateSidebar';

const TicketTemplateBuilder = () => {
  const [openNewTemplateModal, setOpenNewTemplateModal] = useState(false);

  // console.warn(
  //   'REACT_ENV',
  //   process.env.REACT_ENV,
  //   'REACT_BACKEND_URL',
  //   process.env.REACT_BACKEND_URL,
  //   'REACT_PORT',
  //   process.env.REACT_PORT
  // );

  return (
    <Flex bg={useColorModeValue('gray.100', 'gray.900')}>
      <TicketTemplateSidebar
        setOpenNewTemplateModal={setOpenNewTemplateModal}
        openNewTemplateModal={openNewTemplateModal}
      />
      <Box ml="310px" width="100%" bg={useColorModeValue('white', '')}>
        <Outlet />
      </Box>
    </Flex>
  );
};

export default TicketTemplateBuilder;
