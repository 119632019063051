/* eslint-disable react/prop-types */
import { DeleteIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import SelectBoxWithAddButton from './SelectBoxWithAddButton';

const WrapperForSelectBoxWithAddButton = ({
  label,
  optionList,
  onDelete,
  values,
  onUpdatedValue,
  restrictLengthToBeOne = false,
}) => (
  <Box
    border="1px solid #D1D5DB"
    pl="16px"
    pr="16px"
    borderRadius="4px"
    mb="15px"
  >
    <Flex justifyContent="space-between" pt="16px">
      <Text className="SCLabel">{label}</Text>
      <DeleteIcon color="red" className="cursor-pointer" onClick={onDelete} />
    </Flex>
    <SelectBoxWithAddButton
      option={optionList}
      buttonLabel="Add"
      onChange={(value) => onUpdatedValue(value)}
      values={values}
      restrictLengthToBeOne={restrictLengthToBeOne}
    />
  </Box>
);

export default WrapperForSelectBoxWithAddButton;
