/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { EditIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { useNavigate, useParams } from 'react-router-dom';
import ServiceCommandUnit from './ServiceCommandUnit';
import StrictModeDroppable from './Droppable';
import TriangleDown from '../../assets/icons/TriangleDown.svg';
import CustomButton from '../../components/Button/SimpleButton';
import {
  checkNested,
  getItemStyle,
  getListStyle,
  getTemplatesInNestedList,
  reorder,
} from './utils/helper';
import Circle from '../../assets/icons/Circle.svg';
import styles from './styles/templatebuilder.module.scss';
import CreateTemplateModal from './CreateEditTemplate/CreateTemplateModal';
import {
  LIGHT,
  DARK,
  SELECT_LIGHT,
  SELECT_DARK,
  COLORS,
  BLACK,
  WHITE,
} from '../../utils/enums/colors';
import { LABELS } from '../../utils/enums/labels';
import { FieldTypes } from '../../utils/enums/types';
import { getTemplateList } from '../../redux/TicketTemplate/AllTemplatesReducer/AllTemplateSlice';
import CustomToolTip from '../../components/Tooltip/Tooltip';
import CreateFieldsets from './CreateNewFieldset';
import CreateComposite from './CreateNewComposite';

export const TicketTemplateSidebar = ({
  setOpenNewTemplateModal,
  openNewTemplateModal,
}) => {
  const { allTemplatesList } = useSelector((state) => state.allTemplates);
  const [openFieldsetModal, setOpenFieldsetModal] = useState(false);
  const [openCompositeModal, setOpenCompositeModal] = useState(false);
  const [allTemplates, setAllTemplates] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!allTemplatesList?.length) dispatch(getTemplateList());
  }, []);

  useEffect(() => {
    const nestedList = getTemplatesInNestedList(allTemplatesList);
    setAllTemplates(nestedList);
  }, [allTemplatesList]);

  const navigate = useNavigate();
  const { id } = useParams();
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;
    if (result.type === 'droppableItem') {
      setAllTemplates(reorder(allTemplates, sourceIndex, destIndex));
    } else if (result.type === 'droppableSubItem') {
      const itemSubItemMap = allTemplates.reduce((acc, item) => {
        acc[item.id] = item.children;
        return acc;
      }, {});

      const sourceParentId = result.source.droppableId;
      const destParentId = result.destination.droppableId;
      const sourceSubItems = itemSubItemMap[sourceParentId];
      const destSubItems = itemSubItemMap[destParentId];
      let newItems = [...allTemplates];
      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const reorderedSubItems = reorder(
          sourceSubItems,
          sourceIndex,
          destIndex
        );
        newItems = newItems.map((item) => {
          if (item.id === sourceParentId) {
            item = { ...item, children: reorderedSubItems };
          }
          return item;
        });
        setAllTemplates(newItems);
      } else {
        const newSourceSubItems = [...sourceSubItems];
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);
        const newDestSubItems = [...destSubItems];
        newDestSubItems.splice(destIndex, 0, draggedItem);
        newItems = newItems.map((item) => {
          if (item.id === sourceParentId) {
            item = { ...item, children: newSourceSubItems };
          } else if (item.id === destParentId) {
            item = { ...item, children: newDestSubItems };
          }
          return item;
        });
        setAllTemplates(newItems);
      }
    }
  };
  return (
    <>
      <Box
        bg={useColorModeValue('white', 'gray.900')}
        borderRight="1px"
        borderRightColor={useColorModeValue('gray.200', 'gray.700')}
        width="310px"
        height="100vh"
        position="fixed"
        overflowX="scroll"
        className={styles.sidebarHeight}
      >
        <Text
          color={useColorModeValue(COLORS.BLACK, COLORS.WHITE)}
          className={styles.title}
          level={5}
        >
          {LABELS.TITLE.TEMPLATE_SERVICE_CATALOGUE}
        </Text>
        {/* Template */}
        <Accordion
          allowMultiple={false}
          allowToggle
          defaultIndex={0}
          className={styles.accordionContainer}
        >
          <AccordionItem key="all-templates-accordion" py="2">
            <AccordionButton>
              <Box
                display="flex"
                textAlign="left"
                justifyContent="space-between"
                width="100%"
              >
                <Text
                  className={styles.accordionHeading}
                  color={useColorModeValue('', 'white')}
                >
                  {LABELS.TITLE.ALL_TEMPLATES}
                  <span className={styles.ticketCounterField}>
                    {allTemplatesList?.length || 0}
                  </span>
                </Text>
                <span>
                  <AccordionIcon marginRight="4" />
                  <Box
                    bg={useColorModeValue('white', 'gray.900')}
                    border="1px"
                    borderColor={useColorModeValue('gray.200', 'gray.700')}
                    borderRadius="6px"
                    alignItems="center"
                    justifyContent="center"
                    p="2"
                    display="inline"
                  >
                    <SearchIcon
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      width="14px"
                      height="14px"
                      alignSelf="flex-end"
                    />
                  </Box>
                </span>
              </Box>
            </AccordionButton>
            <AccordionPanel pb={4} className={`${styles.allTemplateText}`}>
              <CustomButton
                leftIcon={<EditIcon />}
                id="create-template"
                width="100%"
                onClick={() => {
                  setOpenNewTemplateModal(true);
                }}
                buttonText={LABELS.ACTIONS.CREATE_NEW_TEMPLATE}
                variant="outline"
                className={styles.createTemplateBtnAccordion}
                color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
                mb="4"
                size="md"
              />
              {/* Drag Strat */}
              <DragDropContext onDragEnd={onDragEnd}>
                <StrictModeDroppable
                  droppableId="droppable"
                  type="droppableItem"
                >
                  {(provided, snapshot) => (
                    <div
                      className={styles.overflowSection}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {allTemplates?.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <>
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <Box
                                  {...provided.dragHandleProps}
                                  className={`${styles.templateSelect} `}
                                  m="0"
                                  as="span"
                                  fontSize="15px"
                                  pl="3"
                                  borderRadius="6px"
                                  _hover={{
                                    bg: COLORS.BG_LIGHT,
                                    color: useColorModeValue('black', 'white'),
                                  }}
                                  bg={
                                    item?.id === id
                                      ? useColorModeValue(
                                          SELECT_LIGHT,
                                          SELECT_DARK
                                        )
                                      : ''
                                  }
                                  color={
                                    item?.id === id
                                      ? useColorModeValue(LIGHT, DARK)
                                      : useColorModeValue(BLACK, WHITE)
                                  }
                                  onClick={() =>
                                    navigate(`/template-builder/${item?.id}`)
                                  }
                                >
                                  <ReactSVG
                                    src={
                                      checkNested(item?.children)
                                        ? TriangleDown
                                        : Circle
                                    }
                                    className={
                                      checkNested(item?.children)
                                        ? styles.triangleDown
                                        : styles.circle
                                    }
                                  />
                                  <CustomToolTip
                                    id="tooltip-test"
                                    toolTipText={item?.name}
                                  >
                                    <span className="ellipsis ml-2">
                                      {item.name}
                                    </span>
                                  </CustomToolTip>
                                </Box>
                                <ServiceCommandUnit
                                  subItems={item.children}
                                  type={item.id}
                                />
                              </div>
                              {provided.placeholder}
                            </>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </StrictModeDroppable>
              </DragDropContext>
              {/* Drag End */}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        {/* FieldSets */}
        <CreateFieldsets
          setOpenFieldsetModal={setOpenFieldsetModal}
          openFieldsetModal={openFieldsetModal}
        />
        <CreateComposite
          setOpenCompositeModal={setOpenCompositeModal}
          openCompositeModal={openCompositeModal}
        />
      </Box>
      {openNewTemplateModal && (
        <CreateTemplateModal
          type={FieldTypes.TEXT}
          templateName={LABELS.ACTIONS.CREATE_NEW_TEMPLATE}
          isTemplatedModel={openNewTemplateModal}
          setIsTemplatedModel={setOpenNewTemplateModal}
        />
      )}
    </>
  );
};

TicketTemplateSidebar.propTypes = {
  setOpenNewTemplateModal: PropTypes.func.isRequired,
  openNewTemplateModal: PropTypes.bool.isRequired,
};

export default TicketTemplateSidebar;
