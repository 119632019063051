/* eslint-disable no-unused-vars */
import {
  DEFAULT_VALUE,
  FIELD_VALIDATE_KEY,
  LABELS,
} from '../../../utils/enums/labels';
import { FieldTypes } from '../../../utils/enums/types';
import { ADVANCED_SETTINGS_KEYS, BASE_SETTINGS_KEYS } from './constants';
import { removedInvalidChar, removedInvalidCharAllowQuestion, stringToSlug } from './helper';

export const renderMinLengthField = ({
  data,
  setFields,
  setIsError,
  label = LABELS.FIELD_PROPERTIES.MIN_LENGTH,
}) => ({
  label,
  value: data?.[ADVANCED_SETTINGS_KEYS.MIN_LENGTH] || '',
  errorString: LABELS.ERROR.MIN_LENGTH,
  key: FIELD_VALIDATE_KEY.MIN_LENGTH,
  placeholder: '',
  valueKey: ADVANCED_SETTINGS_KEYS.MIN_LENGTH,
  onChange: (val) => {
    // min length should be 0
    const value = val > 0 ? val : 0;

    // Setting the field value
    setFields((prev) => ({
      ...prev,
      [ADVANCED_SETTINGS_KEYS.MIN_LENGTH]: value,
    }));

    // Setting the errors
    setIsError((pre) => ({
      ...pre,
      [ADVANCED_SETTINGS_KEYS.MIN_LENGTH]:
        value && data?.[ADVANCED_SETTINGS_KEYS.MAX_LENGTH]
          ? !(
            parseInt(value, 10) <
              parseInt(data?.[ADVANCED_SETTINGS_KEYS.MAX_LENGTH], 10)
          )
          : false,
      [ADVANCED_SETTINGS_KEYS.MAX_LENGTH]:
        value && data?.[ADVANCED_SETTINGS_KEYS.MAX_LENGTH]
          ? !(
            parseInt(value, 10) <
              parseInt(data?.[ADVANCED_SETTINGS_KEYS.MAX_LENGTH], 10)
          )
          : false,
    }));
  },
});

export const renderMaxLengthField = ({
  data,
  setFields,
  setIsError,
  label = LABELS.FIELD_PROPERTIES.MAX_LENGTH,
}) => ({
  label,
  value: data?.[ADVANCED_SETTINGS_KEYS.MAX_LENGTH] || '',
  placeholder: '',
  key: FIELD_VALIDATE_KEY.MAX_LENGTH,
  errorString: LABELS.ERROR.MAX_LENGTH,
  valueKey: ADVANCED_SETTINGS_KEYS.MAX_LENGTH,
  onChange: (val) => {
    // Min length should be 0
    const value = val > 0 ? val : 0;

    // Setting the data
    setFields((prev) => ({
      ...prev,
      [ADVANCED_SETTINGS_KEYS.MAX_LENGTH]: value,
    }));

    // Setting the errors
    setIsError((pre) => ({
      ...pre,
      [ADVANCED_SETTINGS_KEYS.MIN_LENGTH]:
        value && data?.[ADVANCED_SETTINGS_KEYS.MIN_LENGTH]
          ? !(
            parseInt(value, 10) >
              parseInt(data?.[ADVANCED_SETTINGS_KEYS.MIN_LENGTH], 10)
          )
          : false,
      [ADVANCED_SETTINGS_KEYS.MAX_LENGTH]:
        value && data?.[ADVANCED_SETTINGS_KEYS.MIN_LENGTH]
          ? !(
            parseInt(value, 10) >
              parseInt(data?.[ADVANCED_SETTINGS_KEYS.MIN_LENGTH], 10)
          )
          : false,
    }));
  },
});

export const renderMinRowField = ({
  data,
  setFields,
  setIsError,
  label = LABELS.FIELD_PROPERTIES.MIN_ROW,
}) => ({
  label,
  value: data?.[ADVANCED_SETTINGS_KEYS.MIN_ROW] || '',
  errorString: LABELS.ERROR.MIN_ROW,
  key: FIELD_VALIDATE_KEY.MIN_ROW,
  placeholder: '',
  valueKey: ADVANCED_SETTINGS_KEYS.MIN_ROW,
  onChange: (val) => {
    // min length should be 0
    const value = val > 0 ? val : 0;

    // Setting the field value
    setFields((prev) => ({
      ...prev,
      [ADVANCED_SETTINGS_KEYS.MIN_ROW]: value,
    }));

    // Setting the errors
    setIsError((pre) => ({
      ...pre,
      [ADVANCED_SETTINGS_KEYS.MIN_ROW]:
        value && data?.[ADVANCED_SETTINGS_KEYS.MAX_ROW]
          ? !(
            parseInt(value, 10) <
              parseInt(data?.[ADVANCED_SETTINGS_KEYS.MAX_ROW], 10)
          )
          : false,
      [ADVANCED_SETTINGS_KEYS.MAX_ROW]:
        value && data?.[ADVANCED_SETTINGS_KEYS.MAX_ROW]
          ? !(
            parseInt(value, 10) <
              parseInt(data?.[ADVANCED_SETTINGS_KEYS.MAX_ROW], 10)
          )
          : false,
    }));
  },
});

export const renderMaxRowField = ({
  data,
  setFields,
  setIsError,
  label = LABELS.FIELD_PROPERTIES.MAX_ROW,
}) => ({
  label,
  value: data?.[ADVANCED_SETTINGS_KEYS.MAX_ROW] || '',
  placeholder: '',
  key: FIELD_VALIDATE_KEY.MAX_ROW,
  errorString: LABELS.ERROR.MAX_ROW,
  onChange: (val) => {
    // Min length should be 0
    const value = val > 0 ? val : 0;

    // Setting the data
    setFields((prev) => ({
      ...prev,
      [ADVANCED_SETTINGS_KEYS.MAX_ROW]: value,
    }));

    // Setting the errors
    setIsError((pre) => ({
      ...pre,
      [ADVANCED_SETTINGS_KEYS.MIN_ROW]:
        value && data?.[ADVANCED_SETTINGS_KEYS.MIN_ROW]
          ? !(
            parseInt(value, 10) >
              parseInt(data?.[ADVANCED_SETTINGS_KEYS.MIN_ROW], 10)
          )
          : false,
      [ADVANCED_SETTINGS_KEYS.MAX_ROW]:
        value && data?.[ADVANCED_SETTINGS_KEYS.MIN_ROW]
          ? !(
            parseInt(value, 10) >
              parseInt(data?.[ADVANCED_SETTINGS_KEYS.MIN_ROW], 10)
          )
          : false,
    }));
  },
});

export const renderOffsetPositiveField = ({
  data,
  setFields,
  setIsError,
  label = LABELS.FIELD_PROPERTIES.OFFSET_POSITIVE,
}) => ({
  label,
  value: data?.[BASE_SETTINGS_KEYS.OFFSET_VALUE] || '',
  placeholder: '',
  key: FIELD_VALIDATE_KEY.OFFSET_VALUE,
  errorString: LABELS.ERROR.OFFSET_POSITIVE,
  valueKey: BASE_SETTINGS_KEYS.OFFSET_VALUE,
  onChange: (val) => {
    // Min length should be 0
    const value = val > 0 ? val : 0;

    // Setting the data
    setFields((prev) => ({
      ...prev,
      [BASE_SETTINGS_KEYS.OFFSET_VALUE]: value,
    }));

    // Setting the errors
    setIsError((pre) => ({
      ...pre,
      [BASE_SETTINGS_KEYS.OFFSET_VALUE]:
        value && data?.[BASE_SETTINGS_KEYS.OFFSET_VALUE]
          ? !(parseInt(value, 10) > 0)
          : false,
    }));
  },
});

export const renderOffsetNegativeField = ({
  data,
  setFields,
  setIsError,
  label = LABELS.FIELD_PROPERTIES.OFFSET_NEGATIVE,
}) => ({
  label,
  value: data?.[BASE_SETTINGS_KEYS.OFFSET_VALUE] || '',
  placeholder: '',
  key: FIELD_VALIDATE_KEY.OFFSET_VALUE,
  errorString: LABELS.ERROR.OFFSET_NEGATIVE,
  valueKey: BASE_SETTINGS_KEYS.OFFSET_VALUE,
  onChange: (val) => {
    // Min length should be 0
    const value = val > 0 ? val : 0;

    // Setting the data
    setFields((prev) => ({
      ...prev,
      [BASE_SETTINGS_KEYS.OFFSET_VALUE]: value,
    }));

    // Setting the errors
    setIsError((pre) => ({
      ...pre,
      [BASE_SETTINGS_KEYS.OFFSET_VALUE]:
        value && data?.[BASE_SETTINGS_KEYS.OFFSET_VALUE]
          ? !(parseInt(value, 10) > 0)
          : false,
    }));
  },
});

export const renderFieldLabels = ({
  data,
  setFields,
  setIsError,
  fieldSlug,
  existingFields = {},
}) => ({
  label: LABELS.FIELD_PROPERTIES.LABEL,
  key: FIELD_VALIDATE_KEY.LABEL,
  value: `${data?.[BASE_SETTINGS_KEYS.LABEL] || DEFAULT_VALUE.EMPTY_STRING}`,
  placeholder: '',
  required: true,
  errorString: ({ value: val = '' }) => {
    if (!val.trim()) {
      return LABELS.ERROR.LABEL;
    }
    // if (checkForDuplicateNames(val)) {
    //   return LABELS.ERROR.DUPLICATE_FIELD_NAMES;
    // }
    return '';
  },
  valueKey: BASE_SETTINGS_KEYS.LABEL,
  onChange: (value, edit) => {
    let slugError = !data?.[BASE_SETTINGS_KEYS.IS_EDIT_SLUG];
    let slug = edit
      ? data?.[BASE_SETTINGS_KEYS.SLUG]
      : stringToSlug(`${fieldSlug}_${value}`);
    if (!value && !edit) {
      slug = DEFAULT_VALUE.EMPTY_STRING;
    }
    setFields((preData) => ({
      ...preData,
      [BASE_SETTINGS_KEYS.LABEL]: data?.type === FieldTypes.BOOLEAN
        ? removedInvalidCharAllowQuestion(value)
        : removedInvalidChar(value),
      [BASE_SETTINGS_KEYS.SLUG]: slug,
    }));
    if (slug) {
      slugError = false;
    }
    if (!value && !edit) {
      slugError = true;
    }
    setIsError((pre) => ({
      ...pre,
      [BASE_SETTINGS_KEYS.LABEL]: !value, // || checkForDuplicateNames(value),
      [BASE_SETTINGS_KEYS.SLUG]: slugError,
    }));
  },
});
