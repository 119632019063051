import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../components/Button/SimpleButton';
import ConfigureIcon from '../../../assets/icons/ConfigureViewIcon.svg';
import AddNewFieldModal from '../AddNewFieldModal';
import CreateEditFieldModal from '../CreateEditFieldModal';
import CustomInput from '../../../components/InputBox/Input';
import {
  BORDER_DARK,
  BORDER_LIGHT,
  COLORS,
  DARK,
  LIGHT,
} from '../../../utils/enums/colors';
import styles from '../styles/particularTemplate.module.scss';
import { getFieldLabel, LABELS } from '../../../utils/enums/labels';
// import CreateEditFieldsetFieldModal from '../CreateEditFieldsetFieldModal';

const FieldSettingsTab = ({ data }) => {
  const [openFieldsModal, setOpenFieldsModal] = useState(false);
  const [type, setType] = useState('');
  const [openCreateFieldModal, setOpenCreateFieldModal] = useState(false);
  const [openEditFieldModal, setOpenEditFieldModal] = useState(false);
  const [fieldData, setFieldData] = useState({});
  const navigate = useNavigate();
  const colorType = useColorModeValue('light', 'dark');
  useEffect(() => {
    if (type) {
      setOpenFieldsModal(false);
      setOpenCreateFieldModal(true);
    }
  }, [type]);
  useEffect(() => {
    if (!openCreateFieldModal) {
      setType('');
    }
  }, [openCreateFieldModal]);

  const handleEditField = (fields) => {
    setFieldData(fields);
    setOpenEditFieldModal(true);
  };

  return (
    <Box
      display="block"
      width="100%"
      flex="1"
      pt="17px"
      data-testid="FieldSettingsTabBox"
    >
      <Flex
        flex="1"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        justifyContent="space-between"
        flexWrap="wrap"
        data-testid="FieldSettingsTabGrpFlex"
      >
        <Flex flex="1" data-testid="FieldSettingsTabCIFlex">
          <CustomInput
            id="searchFieldSS"
            placeholder={LABELS.ACTIONS.SEARCH_FOR_A_FIELD}
            onChange={() => {}}
            size="sm"
          />
          <Flex ml="auto" data-testid="FieldSettingsTabButtonFlex">
            <CustomButton
              className={` ml-4`}
              leftIcon={<AddIcon />}
              color={useColorModeValue(LIGHT, DARK)}
              id="FieldSettingsTabButtonFlexBtntemplate"
              onClick={() => setOpenFieldsModal(true)}
              buttonText={LABELS.ACTIONS.ADD_A_FIELD}
              variant="outline"
              size="sm"
            />
            <CustomButton
              className={`${styles.btnRight} ${styles.customHeight} ml-4`}
              leftIcon={
                <ReactSVG
                  src={ConfigureIcon}
                  className={`${styles.configureIcon}  ${useColorModeValue(
                    COLORS.BLACK,
                    styles.configureIconWhite
                  )}`}
                />
              }
              color={useColorModeValue(COLORS.BLACK, COLORS.TEXT_DARK)}
              id="FieldSettingsTabButtonFlexViewtemplate"
              isDisabled="true"
              onClick={() => {
                navigate();
                // navigate(
                //   `/template-builder/configure-template-view/${data?.id}`,
                //   {
                //     state: data,
                //   }
                // );
              }}
              buttonText={LABELS.ACTIONS.CONFIGURE_VIEW}
              variant="outline"
            />
          </Flex>
        </Flex>
        <AddNewFieldModal
          isAddField={openFieldsModal}
          setIsAddField={setOpenFieldsModal}
          onClick={setType}
        />
        {openCreateFieldModal && (
          <CreateEditFieldModal
            type={type}
            setType={setType}
            isFieldModel={openCreateFieldModal}
            setIsfieldModel={setOpenCreateFieldModal}
            templateName={data?.name}
            modalFrom="Composite"
          />
        )}
      </Flex>
      <Box mt="5" data-testid="FieldSettingsTableBox">
        <TableContainer
          borderRadius="12px"
          borderWidth="1.5px"
          fontSize="14px"
          borderColor={useColorModeValue(BORDER_LIGHT, BORDER_DARK)}
          color={useColorModeValue(COLORS.BLACK, COLORS.TEXT_DARK)}
          data-testid="FieldSettingsTableContainer"
        >
          <Table variant="simple" data-testid="FieldSettingsTable">
            <Thead data-testid="FieldSettingsTabTEThead">
              <Tr fontWeight="bold" data-testid="FieldSettingsTabThTr">
                <Th paddingLeft="16" data-testid="FieldSettingsTabNameTh">
                  {LABELS.TITLE.FIELD_TABLE_NAME}
                </Th>
                <Th paddingLeft="32" data-testid="FieldSettingsTabTypeTh">
                  {LABELS.TITLE.FIELD_TABLE_TYPE}
                </Th>
                <Th
                  textAlign="right"
                  paddingRight="32"
                  data-testid="FieldSettingsTabActionTh"
                >
                  {LABELS.TITLE.FIELD_TABLE_ACTIONS}
                </Th>
              </Tr>
            </Thead>
            <Tbody data-testid="FieldSettingsTabTbody">
              {data?.fields?.map((i) => (
                <Tr key={i?.id} data-testid="FieldSettingsTdTr">
                  <Td paddingLeft="16" data-testid="FieldSettingsTabLabelTd">
                    {i?.label}
                  </Td>
                  <Td paddingLeft="32" data-testid="FieldSettingsTabTypeTd">
                    {getFieldLabel(i?.type)}
                  </Td>
                  <Td
                    textAlign="right"
                    paddingRight="32"
                    data-testid="FieldSettingsTabIconTd"
                  >
                    <EditIcon
                      onClick={() => handleEditField(i)}
                      className={styles.editIconLight}
                    />
                    <DeleteIcon className={styles.deleteIconLight} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      {openEditFieldModal && (
        <CreateEditFieldModal
          type={fieldData.type}
          setType={setType}
          fieldData={fieldData}
          edit
          isFieldModel={openEditFieldModal}
          setIsfieldModel={setOpenEditFieldModal}
          templateName={data?.name}
        />
      )}
    </Box>
  );
};

FieldSettingsTab.propTypes = {
  data: PropTypes.objectOf().isRequired,
};

export default FieldSettingsTab;
