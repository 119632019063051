/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, GridItem } from '@chakra-ui/react';
import CustomModal from '../../../components/Modal/Modal';
import CustomButton from '../../../components/Button/SimpleButton';
import { setActiveModal } from '../../../redux/ModalReducer/ModalSlice';
import { updateViewState } from '../../../redux/ViewSlice/ViewReducer';
import axiosInstance from '../../../utils/axios/axios';
import { getTenantData } from '../../../helpers/tenantUrl';

const ModalMutliTabView = ({
  // name,
  isOpen,
  setIsOpen,
  // isEdit,
  viewLayout,
  modalTitle,
  children,
  onModalClose,
  tabIds,
  mandatoryFields = [],
  refreshListsAfterSave,
}) => {
  const [modalItems, setModalItems] = useState([]);
  const [modalFooter, setModalFooter] = useState('');
  const [footerItems, setFooterItems] = useState([]);
  const viewState = useSelector((state) => state.viewState);
  const { activeModal = false } = useSelector((state) => state.allModals);
  const dispatch = useDispatch();
  // eslint-disable-next-line no-shadow
  const sortByViewLayout = (items, viewLayout) => {
    if (JSON.stringify(viewLayout) === JSON.stringify({})) return items;
    let merged = [];
    const foot = [{ type: 'error' }];
    for (let i = 0; i < viewLayout?.rows?.length; i++) {
      merged.push({
        ...viewLayout?.rows[i],
        ...items.find(
          (itmInner) => itmInner?.props?.name === viewLayout?.rows[i].i
        ),
      });
    }

    merged = merged.filter((i) => {
      if (
        JSON.stringify(i) !== JSON.stringify({}) &&
        !i?.props?.isModalFooter
      ) {
        return i;
      }
      foot.push(i);
    });

    setFooterItems(foot);
    return merged;
  };

  const makeApiRequest = async (method, targetUrl, data) => {
    if (method === 'get') {
      await axiosInstance.get(targetUrl, { data });
    } else if (method === 'post') {
      const response = await axiosInstance.post(targetUrl, { data });
      if (response.data) {
        return response.data;
      }
    } else if (method === 'patch') {
      const response = await axiosInstance.patch(targetUrl, { data });
      if (response.data) {
        return response.data;
      }
    }
  };
  const createPayload = (rowData) => {
    const payload = {
      slug: `${rowData.template_name}_template`,
      name: rowData.template_name,
      tenant_id: getTenantData()?.id,
      version: '1',
      properties: {
        description: rowData.description,
        child_templates_list: !_.isEmpty(rowData.child_templates_list)
          ? _.map(rowData.child_templates_list, (c) => c.value)
          : '',
        template_type: rowData.template_type,
        owners: !_.isEmpty(rowData.owners)
          ? _.map(rowData.owners, (c) => c.value)
          : '',
        collborators: !_.isEmpty(rowData.collborators)
          ? _.map(rowData.collborators, (c) => c.value)
          : '',
        note_types: !_.isEmpty(rowData.note_types)
          ? _.map(rowData.note_types, (c) => c.value)
          : [],
        channels: !_.isEmpty(rowData.channels)
          ? _.map(rowData.channels, (c) => c.value)
          : [],
      },
    };
    payload.properties = _.omitBy(payload.properties, (v) => !v);
    return payload;
  };
  const getMandatoryFieldsOfTabById = (payloadKeysMaps, tabId) => {
    const allElementsOfTab = [];
    _.forEach(payloadKeysMaps, (val, key) => {
      if (val.includes(tabId)) {
        allElementsOfTab.push(key);
      }
    });
    const mandatoryFieldsOfTab = _.filter(
      mandatoryFields,
      (field) => allElementsOfTab.indexOf(field) >= 0
    );
    return mandatoryFieldsOfTab || [];
  };
  const getIsFormValid = (payloadKeysMaps, modalData = {}) => {
    let isFormValid = true;
    _.forEach(tabIds, (tabId) => {
      const tabData = modalData[tabId];
      dispatch(
        updateViewState({
          stateKey: tabId,
          value: { ...tabData, errorObj: undefined },
        })
      );
      const mandatoryFieldsOfTabById = getMandatoryFieldsOfTabById(
        payloadKeysMaps,
        tabId
      );
      _.forEach(mandatoryFieldsOfTabById, (mandatorykey) => {
        if (_.isEmpty(_.get(modalData, `${tabId}.${mandatorykey}`))) {
          isFormValid = false;
          const errorObj = {};
          errorObj[`${mandatorykey}`] = `${mandatorykey} is required field`;
          dispatch(
            updateViewState({
              stateKey: tabId,
              value: { ...tabData, errorObj },
            })
          );
        }
      });
    });
    return isFormValid;
  };
  const modalFooterActions = async (actionButtonProps) => {
    const {
      name: btnName,
      payloadKeysMaps,
      method,
      targetUrl,
      // listKey,
      // listIdentifier,
    } = actionButtonProps;
    if (btnName === 'cancel') {
      if (payloadKeysMaps) {
        Object.keys(payloadKeysMaps).map((i) =>
          dispatch(
            updateViewState({
              stateKey: i,
              value: undefined,
            })
          )
        );
      }
      dispatch(setActiveModal({ targetModalId: undefined }));
      dispatch(
        updateViewState({
          stateKey: activeModal,
          value: undefined,
        })
      );
    }
    if (btnName === 'continue') {
      const isFormValid = getIsFormValid(payloadKeysMaps, viewState);
      if (!isFormValid) {
        const errorObj = {
          error: 'Please fill mandatory fields in all the tabs',
        };
        dispatch(
          updateViewState({
            stateKey: activeModal,
            value: errorObj,
          })
        );
        return false;
      }
      dispatch(
        updateViewState({
          stateKey: activeModal,
          value: undefined,
        })
      );
      const apiData = _.reduce(
        payloadKeysMaps,
        (acc, value, key) => ({
          ...acc,
          [key]: _.get(viewState, value) ?? '',
        }),
        {}
      );
      const payload = createPayload(apiData);
      // eslint-disable-next-line no-unused-vars
      const rowData = await makeApiRequest(method, targetUrl, payload);
      dispatch(setActiveModal({ targetModalId: undefined }));
      _.forEach(refreshListsAfterSave, (listName) => {
        dispatch(
          updateViewState({
            stateKey: listName,
            value: {
              refresh: uuid(),
            },
          })
        );
      });

      dispatch(
        updateViewState({
          stateKey: activeModal,
          value: undefined,
        })
      );
    }
  };

  useEffect(() => {
    setModalItems(sortByViewLayout(children, viewLayout));
  }, [children]);

  useEffect(() => {
    if (footerItems?.length > 0) {
      setModalFooter(
        footerItems.map((i) => {
          if (i.type === 'error') {
            if (viewState[activeModal] && viewState[activeModal].error) {
              return (
                <p style={{ color: 'red' }}>{viewState[activeModal].error}</p>
              );
            }
            return null;
          }
          return (
            <CustomButton
              onClick={() => modalFooterActions(i.props)}
              buttonText={i.props.buttonText}
              variant={i.props.name === 'cancel' ? '' : 'solid'}
              buttonColor={i.props.name === 'cancel' ? '' : 'customBlue'}
              _hover={
                i.props.name === 'cancel'
                  ? { bg: 'red', color: '#ffffff !important' }
                  : undefined
              }
              ml="2"
              style={i.props.style}
              id="MultiTsbViewFooterBtn"
            />
          );
        })
      );
    }
  }, [footerItems, viewState]);

  const checkVisibility = (element) => {
    const val = _.get(viewState, element?.props?.condition?.stateName);
    if (element?.props?.condition) {
      return element?.props?.condition?.stateValue === val;
    }
    return true;
  };

  const modalContent = (
    <Grid
      templateColumns={`repeat(${viewLayout?.no_of_cols}, 1fr)`}
      gap={3}
      data-testid="MultiTabViewGridContainer"
    >
      {modalItems?.map((i) => (
        <GridItem key={i.id} colSpan={i.w}>
          {checkVisibility(i) ? i : ''}
        </GridItem>
      ))}
    </Grid>
  );
  return (
    <CustomModal
      modalTitle={modalTitle}
      modalContent={modalContent}
      open={isOpen}
      setOpen={setIsOpen}
      modalFooter={modalFooter}
      onModalClose={onModalClose}
      id="MultiTavViewModalZmodal"
    />
  );
};

ModalMutliTabView.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  // isEdit: PropTypes.bool,
  children: PropTypes.node.isRequired,
  modalTitle: PropTypes.string,
  viewLayout: PropTypes.object.isRequired,
  // name: PropTypes.string,
  onModalClose: PropTypes.func,
  mandatoryFields: PropTypes.array,
  tabIds: PropTypes.array.isRequired,
  refreshListsAfterSave: PropTypes.array.isRequired,
};

ModalMutliTabView.defaultProps = {
  // isEdit: false,
  modalTitle: '',
  onModalClose: () => {},
  mandatoryFields: [],
  // name: '',
};

export default ModalMutliTabView;
