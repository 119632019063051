// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mHCoLffK9hz10lUGfZ7I{padding:20px 20px 20px 20px;cursor:pointer;min-height:69px;align-items:flex-start}.morUqGz3um6UAeZ_dQDA{padding:0px !important;align-items:center !important}.GyedqJzaQDASoBmdj5cX{font-size:17px !important;font-weight:600 !important}.VYKhpynmEjrHHQkQPuAi{font-size:13px !important;font-weight:400 !important;color:#868e96 !important}.i4nSTlx4OEVNOnCyi4lo{border:.5px solid #e2e3e4;padding:4px 3px 0px 3px;border-radius:8px}.i4nSTlx4OEVNOnCyi4lo svg{width:32px;height:32px}.Vdos7rBYEK8I1ZuOoTx6{border:1px solid #2563eb !important}`, "",{"version":3,"sources":["webpack://./src/pages/TicketTemplateWorkflow/AddNewRoleModal/rolecontent.module.scss"],"names":[],"mappings":"AAAA,sBACI,2BAAA,CACA,cAAA,CACA,eAAA,CACA,sBAAA,CAGJ,sBACI,sBAAA,CACA,6BAAA,CAGJ,sBACI,yBAAA,CACA,0BAAA,CAGJ,sBACI,yBAAA,CACA,0BAAA,CACA,wBAAA,CAGJ,sBACI,yBAAA,CACA,uBAAA,CACA,iBAAA,CAGJ,0BACQ,UAAA,CACA,WAAA,CAGR,sBACI,mCAAA","sourcesContent":[".cardStyle {\n    padding: 20px 20px 20px 20px;\n    cursor: pointer;\n    min-height: 69px;\n    align-items: flex-start;\n}\n\n.cardbody {\n    padding: 0px !important;\n    align-items: center !important;\n}\n\n.cardheading {\n    font-size: 17px !important;\n    font-weight: 600 !important;\n}\n\n.carddesc {\n    font-size: 13px !important;\n    font-weight: 400 !important;\n    color: #868E96 !important;\n}\n\n.boxSvg {\n    border: 0.5px solid #E2E3E4;\n    padding: 4px 3px 0px 3px;\n    border-radius: 8px;\n}\n\n.boxSvg svg {\n        width: 32px;\n        height: 32px;\n    }\n\n.selectCard {\n    border: 1px solid #2563EB !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardStyle": `mHCoLffK9hz10lUGfZ7I`,
	"cardbody": `morUqGz3um6UAeZ_dQDA`,
	"cardheading": `GyedqJzaQDASoBmdj5cX`,
	"carddesc": `VYKhpynmEjrHHQkQPuAi`,
	"boxSvg": `i4nSTlx4OEVNOnCyi4lo`,
	"selectCard": `Vdos7rBYEK8I1ZuOoTx6`
};
export default ___CSS_LOADER_EXPORT___;
