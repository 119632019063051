import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {
    name: '',
    protocol: '',
    host: '',
    port: '',
    username: '',
    authType: '',
    password: '',
    redirectUrl: '',
    clientId: '',
    clientServer: '',
    refreshToken: '',
    maxTry: '',
    coolOffTime: '',
    skipTime: '',
    timeout: '',
    domain: '',
    email: [],
    itemTypes: null,
    ticketStatus: '',
  },
};

const mailServer = createSlice({
  name: 'mailServer',
  initialState,
  reducers: {
    addMailServer: (state, action) => {
      const responseData = action.payload;
      if (responseData) {
        state.data = { ...responseData };
      }
    },
    updateMailServer: (state, action) => {
      const newData = action.payload;
      if (newData) {
        state.data = { ...newData };
      }
    },
  },
});

export const { updateMailServer, addMailServer } = mailServer.actions;
export default mailServer.reducer;
