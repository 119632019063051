import Joi from 'joi';

import { stringRequiredValidation } from '../validationSchemas';
import { RELATION_TYPES } from '../../enums/selectOption';

const RELATION_VALIDATIONS = Joi.object({
  name: stringRequiredValidation,
  itemType: stringRequiredValidation,
  offer_id: Joi.when('relation_type', {
    is: RELATION_TYPES.OFFER,
    then: Joi.string().required().messages({
      'string.empty': 'Offer is required',
      'any.required': 'Offer is required',
    }),
    otherwise: Joi.string().allow('', null),
  }),
  relation: Joi.object({
    label: stringRequiredValidation,
    value: stringRequiredValidation,
  }).unknown(),
  cardinality: stringRequiredValidation,
}).unknown();

export default RELATION_VALIDATIONS;
