import React from 'react';
import PropTypes from 'prop-types';
// import CreatableSelect from 'react-select/creatable';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '@chakra-ui/react';
// import customStylesReactSelect from '../../../context/CustomTheme/ReactSelectTheme';

import {
  updateDropDownState,
  // updateInputValueForCreateDropDown,
} from '../../../redux/WorkFlowMainSlice/WorkFlowMainReducer';
import CustomInput from '../../../components/InputBox/Input';

const AddStatus = ({ errorName, setErrorName }) => {
  const dispatch = useDispatch();
  const { dropDownState } = useSelector((state) => state.workFlowMain);
  // const handleChangeOfCreateSelect = (e) => {
  //   setErrorName(false);
  //   dispatch(updateDropDownState(e));
  // };
  // const handleCreateOfCreateSelect = (value) => {
  //   setErrorName(false);
  //   const newOption = {
  //     value: value.toLowerCase(),
  //     label: value,
  //   };
  //   dispatch(updateDropDownState(newOption));
  // };
  // const handleInputValueChange = (value) => {
  //   setErrorName(false);
  //   dispatch(updateInputValueForCreateDropDown(value));
  // };
  return (
    <>
      {/* <CreatableSelect
        isClearable
        onChange={handleChangeOfCreateSelect}
        onCreateOption={handleCreateOfCreateSelect}
        options={[]}
        value={dropDownState}
        styles={customStylesReactSelect}
        inputValue={inputValueForCreateDropDown}
        onInputChange={handleInputValueChange}
        {...props}
        placeholder="Type to search or create"
        className={errorName && 'border-2 rounded border-rose-600'}
      />
      {errorName && (
        <p className="text-red-600 text-sm pt-2">
          Status name is already exited in the canvas
        </p>
      )} */}
      <Text className="text-lg font-semibold" data-testid="AddStatusText">
        Name
        <span className="text-red-600 text-sm pt-[10px] pl-[3px]">*</span>
      </Text>
      <CustomInput
        onChange={(e) => {
          setErrorName(false);
          dispatch(updateDropDownState(e.target.value));
        }}
        value={dropDownState}
        placeholder=""
        helperText=" "
        isError={errorName}
        errorMessage="The status name is already in use on the canvas."
        id="Addstatus_name"
      />
    </>
  );
};
AddStatus.propTypes = {
  errorName: PropTypes.bool.isRequired,
  setErrorName: PropTypes.func.isRequired,
};
export default AddStatus;
