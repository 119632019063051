import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex, IconButton, Wrap, WrapItem } from '@chakra-ui/react';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import { LIGHT } from '../../utils/enums/colors';

const ClearableDiv = ({ name, value, parentName, targetStatekey }) => {
  const { viewState } = useSelector((state) => state);
  const dispatch = useDispatch();
  const sourceKey = targetStatekey ?? parentName;
  const state = viewState[sourceKey] ? viewState[sourceKey][value] : {};

  const removeItem = (key, item) => {
    const updated = state[key]?.filter((ins) => ins !== item);

    dispatch(
      updateViewState({
        stateKey: sourceKey,
        value: {
          ...viewState[sourceKey],
          [value]: {
            ...viewState[sourceKey][value],
            [key]: updated,
          },
        },
      })
    );
  };

  return (
    <div data-testid="ClearableDivContainerDiv">
      <Wrap spacing="15px" mt="22px" data-testid="ClearableDivContainerWrap">
        {Object.entries(state || {})?.map(([keyItem, valueArray]) => (
          <Wrap
            spacing={2}
            p={'6px'}
            maxW="380px"
            display={state?.[keyItem]?.length > 0 ? '' : 'none'}
            border={`1px solid ${LIGHT}`}
            borderRadius={'4px'}
            data-testid="ClearableDivInnerWrap"
          >
            <WrapItem data-testid="ClearableDivWrapItem">
              <Wrap data-testid="ClearableDivButtonWrap">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  data-testid="ClearableDivItemBox"
                >
                  {`${keyItem}:`}
                </Box>
                {valueArray?.length > 0 &&
                  valueArray?.map((ins) => (
                    <Flex
                      key={value}
                      justifyContent="center"
                      alignItems="center"
                      bg="#E5E5FF"
                      p={'4px'}
                      pl={'10px'}
                      pr={'0px'}
                      borderRadius="4px"
                      fontWeight={500}
                      height="28px"
                      maxW="350px"
                      data-testid="ClearableDivButtonFlex"
                    >
                      <Box>{ins}</Box>
                      <IconButton
                        background="ffffff"
                        minWidth={'30px'}
                        icon={<SmallCloseIcon />}
                        onClick={async () => removeItem(keyItem, ins)}
                        data-testid="ClearableDivIconButton"
                      />
                    </Flex>
                  ))}
              </Wrap>
            </WrapItem>
          </Wrap>
        ))}
      </Wrap>
    </div>
  );
};

ClearableDiv.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  parentName: PropTypes.string.isRequired,
};

export default ClearableDiv;
