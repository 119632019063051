import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { cloneDeep, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import TableLayout from '../TenantFilters/TableLayout';
import AddNewAudience from './AddNewAudience';

import { getDataForIndeterminateCheckbox, getIntent } from '../CreateEditIntentBuilder/intent.service';
import { columns, enrichDataForEdit } from './utils';
import {
  createAudience,
  deleteAudience,
  getAudiences,
  updateAudience,
} from './audience.services';
import {
  getAudience,
  getIntentGroups,
  keys,
  addData,
  setData,
  deleteData,
  updateData,
} from '../../redux/FieldDefaults';
import { getIdNameMap } from '../../components/IndeterminateCheckbox/utils';

const MasterAudience = () => {
  const dispatch = useDispatch();

  const audiences = useSelector(getAudience);
  const intentGroups = useSelector(getIntentGroups);

  const [audienceEditDrawer, setAudienceEditDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const selectedAudience = useRef({});
  const isuseEffectTriggered = useRef(false);

  const onEditClick = useCallback((data = {}) => {
    selectedAudience.current = enrichDataForEdit(data);
    setAudienceEditDrawer(true);
  }, []);

  const onCreateClick = useCallback(() => {
    onEditClick();
  }, [onEditClick]);

  const onCreateOrEditAudience = useCallback((data) => {
    const temp = cloneDeep(data);
    const id = temp?.id;
    delete temp.id;

    setIsLoading(true);
    const promise = id ? updateAudience(temp, id) : createAudience(temp);
    promise
      .then((res) => {
        if (res) {
          if (!id) {
            dispatch(addData({ key: keys.AUDIENCE, data: res }));
          } else {
            dispatch(updateData({ key: keys.AUDIENCE, data: res, id }));
          }
          setAudienceEditDrawer(false);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onDeleteAudience = useCallback((data) => {
    deleteAudience(data?.id).then((res) => {
      if (res) {
        dispatch(deleteData({ key: keys.AUDIENCE, id: data?.id }));
      }
    });
  }, []);

  const intentOptions = useMemo(() => {
    const ig = intentGroups?.intent_groups;
    if (!Array.isArray(ig)) {
      return [];
    }

    const ed = getDataForIndeterminateCheckbox(ig);
    return ed;
  }, [intentGroups]);

  const intentOptionsMap = useMemo(
    () => getIdNameMap(intentOptions),
    [intentOptions]
  );

  const cols = useMemo(() => {
    const c = columns({
      onEditClick,
      onDelete: onDeleteAudience,
      optionsMap: intentOptionsMap,
    });

    return c;
  }, [onEditClick, onDeleteAudience, intentOptionsMap]);

  useEffect(() => {
    if (isuseEffectTriggered.current) {
      return;
    }

    isuseEffectTriggered.current = true;

    if (isEmpty(intentGroups)) {
      getIntent().then((res) => {
        if (res) {
          dispatch(setData({ key: keys.INTENT_GROUPS, data: res }));
        }
      });
    }

    if (isEmpty(audiences)) {
      getAudiences().then((res) => {
        dispatch(setData({ key: keys.AUDIENCE, data: res }));
      });
    }
  }, [intentGroups, audiences]);

  return (
    <>
      <TableLayout
        tableData={audiences || []}
        title="Audience"
        addBtnText="Create Audience"
        onCreateButtonClick={onCreateClick}
        columns={cols}
      />

      <AddNewAudience
        selectedAudience={selectedAudience.current}
        onCreateOrEditAudience={onCreateOrEditAudience}
        intentOptions={intentOptions}
        open={audienceEditDrawer}
        setOpen={setAudienceEditDrawer}
        isLoading={isLoading}
        key={audienceEditDrawer.toString()}
      />
    </>
  );
};

export default MasterAudience;
