import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { Box, Flex, } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { isEmpty } from 'lodash';
import CustomInput from '../../../../components/InputBox/Input';
import CustomSelectBox from '../../../../components/SelectBox/Select';
import { SLA_MET_COLORS, SLA_FORM_KEY_MAP, SLA_OUTPUT_COLORS } from '../SLA/utils';
import SlaOutcomeAndReminder from './SlaOutcomeAndReminder';
import ConditionsExpressions from '../../../../components/ConditionsExpressions/ConditionsExpressions';
import { isEmptyCondition } from '../../../../utils/helper';
import { getWholeOption } from '../../../TicketTemplateWorkflow/helper';

const NewSLA = ({
  rowData,
  setWholeSlaList,
  itemIndex,
  onDeleteSla,
  errorDataItem,
}) => {
  const { id } = useParams();
  const [slaData, setSlaData] = useState(rowData);
  const [errorData, setErrorData] = useState({});

  const handleErrorData = useCallback((key, value) => {
    setErrorData((prev) => {
      if (prev[key] && value) {
        return {
          ...prev,
          [key]: '',
        };
      }
      return prev;
    });
  }, []);

  const handleChange = (key, value) => {
    setSlaData((prev) => {
      setWholeSlaList((prevFromSla) => {
        const updatedSlaList = [...prevFromSla];
        updatedSlaList[itemIndex] = { ...prev, [key]: value };
        return updatedSlaList;
      });
      return { ...prev, [key]: value };
    });
    handleErrorData(key, value);
  };

  const deleteTags = ({ key, index }) => {
    const initialData = [...slaData[key]] || [];
    if (initialData.length > 0) {
      initialData.splice(index, 1);
      setSlaData((prev) => {
        setWholeSlaList((prevFromSlaList) => {
          const updatedSlaList = [...prevFromSlaList];
          updatedSlaList[itemIndex] = { ...prev, [key]: [...initialData] };
          return updatedSlaList;
        });
        return { ...prev, [key]: [...initialData] };
      });

      handleErrorData(key, initialData);
    }
  };
  const isColorNameExist = (colorArray, colorToCheck) =>
    colorArray.some((item) => item.color === colorToCheck);

  const addOutCome = () => {
    const initialData = slaData?.[SLA_FORM_KEY_MAP.OUTCOME] || [];

    if (
      slaData?.[SLA_FORM_KEY_MAP.STAGES] &&
      slaData?.[SLA_FORM_KEY_MAP.COLOR]?.value &&
      slaData?.[SLA_FORM_KEY_MAP.LABEL]
    ) {
      const tempObj = {
        [SLA_FORM_KEY_MAP.STAGES]: slaData?.[SLA_FORM_KEY_MAP.STAGES],
        [SLA_FORM_KEY_MAP.COLOR]: slaData?.[SLA_FORM_KEY_MAP.COLOR].value,
        [SLA_FORM_KEY_MAP.LABEL]: slaData?.[SLA_FORM_KEY_MAP.LABEL],
      };
      if (
        isColorNameExist(initialData, slaData?.[SLA_FORM_KEY_MAP.COLOR]?.value)
      ) {
        setErrorData((prev) => ({
          ...prev,
          [SLA_FORM_KEY_MAP.OUTCOME]: 'Color already exists in list',
        }));
        return;
      }
      const updatedData = [...initialData, tempObj];
      delete slaData?.[SLA_FORM_KEY_MAP.STAGES];
      delete slaData?.[SLA_FORM_KEY_MAP.COLOR];
      delete slaData?.[SLA_FORM_KEY_MAP.LABEL];

      setSlaData((prev) => {
        setWholeSlaList((prevFromSlaList) => {
          const updatedSlaList = [...prevFromSlaList];
          updatedSlaList[itemIndex] = {
            ...prev,
            [SLA_FORM_KEY_MAP.OUTCOME]: updatedData,
          };
          return updatedSlaList;
        });

        return {
          ...prev,
          [SLA_FORM_KEY_MAP.OUTCOME]: updatedData,
        };
      });

      handleErrorData(SLA_FORM_KEY_MAP.OUTCOME, updatedData);
    }
  };

  const addRemainder = () => {
    const initialData = slaData?.[SLA_FORM_KEY_MAP.REMAINDER] || [];

    if (slaData?.[SLA_FORM_KEY_MAP.REMAINDER_STAGES]) {
      const value = slaData[SLA_FORM_KEY_MAP.REMAINDER_STAGES];
      const updatedData = new Set([...initialData, value]);
      setSlaData((prev) => {
        setWholeSlaList((prevSlaList) => {
          const updatedSlaList = [...prevSlaList];
          updatedSlaList[itemIndex] = {
            ...prev,
            [SLA_FORM_KEY_MAP.REMAINDER]: Array.from(updatedData),
          };
          return updatedSlaList;
        });

        return {
          ...prev,
          [SLA_FORM_KEY_MAP.REMAINDER]: Array.from(updatedData),
          [SLA_FORM_KEY_MAP.REMAINDER_STAGES]: '',
        };
      });
      handleErrorData(SLA_FORM_KEY_MAP.REMAINDER, updatedData);
    }
  };

  useEffect(() => {
    setErrorData(errorDataItem);
  }, [errorDataItem]);
  const getBackGroundColor = (colorName) => {
    if (isEmpty(colorName)) {
      return '#fff';
    }
    if (colorName === 'orange') {
      return '#E74C3C'; // '#F04A00';
    }
    if (colorName === 'brown') {
      return '#964B00';
    }
    if (colorName === 'red') {
      return 'red';
    }
    return '#fff';
  };

  return (
    <Box
      p="10px 10px 10px 20px"
      // width="70%"
    >
      <Flex justifyContent="space-between">
        <CustomInput
          label="Name"
          value={slaData?.[SLA_FORM_KEY_MAP.NAME] || ''}
          onChange={(e) => {
            handleChange(SLA_FORM_KEY_MAP.NAME, e.target.value);
          }}
          isError={!!errorData?.[SLA_FORM_KEY_MAP.NAME]}
          errorMessage={errorData?.[SLA_FORM_KEY_MAP.NAME] || ''}
          id="EditSlaModalDivCInput"
        />

        <DeleteIcon
          mx="4"
          color="red"
          onClick={() => {
            onDeleteSla(slaData.id);
          }}
          cursor="pointer"
        />
      </Flex>

      <Box pt="20px">
        {/* first item */}
        <Box templateColumns="2fr 8fr" gap="20px">
          <Box>
            <CustomInput
              label="Goal"
              value={slaData?.[SLA_FORM_KEY_MAP.GOAL] || ''}
              onChange={(e) => {
                handleChange(SLA_FORM_KEY_MAP.GOAL, e.target.value);
              }}
              isError={!!errorData?.[SLA_FORM_KEY_MAP.GOAL]}
              errorMessage={errorData?.[SLA_FORM_KEY_MAP.GOAL] || ''}
              id="EditSlaModalGoalDivCInput"
            />
          </Box>
          <Box>
            <ConditionsExpressions
              label="Attributes"
              templateId={id}
              value={slaData?.[SLA_FORM_KEY_MAP.ATTRIBUTES_CONTAINER] || []}
              onChange={(value) => {
                const { and } = value || {};
                const updatedValue = isEmptyCondition(and)
                  ? { and: [] }
                  : value;
                handleChange(
                  SLA_FORM_KEY_MAP.ATTRIBUTES_CONTAINER,
                  updatedValue
                );
              }}
            />
            {errorData?.[SLA_FORM_KEY_MAP.ATTRIBUTES_CONTAINER] ? (
              <span className="field-error">
                {errorData?.[SLA_FORM_KEY_MAP.ATTRIBUTES_CONTAINER]}
              </span>
            ) : null}
          </Box>
        </Box>
        <Flex>
          <CustomSelectBox
            label="Default Outcome Color"
            options={SLA_OUTPUT_COLORS}
            value={getWholeOption(
              slaData?.[SLA_FORM_KEY_MAP.SLA_DEFAULT_OUTCOME_COLOR] ||
                '#008000',
              SLA_OUTPUT_COLORS
            )}
            onChange={(e) => {
              handleChange(SLA_FORM_KEY_MAP.SLA_DEFAULT_OUTCOME_COLOR, e.value);
            }}
            id="EditSlaModalSLAMetColorInput"
          />
          <Box
            w="20px"
            style={{
              backgroundColor:
                slaData?.[SLA_FORM_KEY_MAP.SLA_DEFAULT_OUTCOME_COLOR] ||
                '#008000',
              alignSelf: 'flex-end',
              height: '38px',
              width: '38px',
              marginLeft: '11px',
              border: '1px solid rgb(226, 232, 240)',
              borderRadius: '5px',
            }}
          />
        </Flex>
        <SlaOutcomeAndReminder
          slaData={slaData}
          setErrorData={setErrorData}
          handleErrorData={handleErrorData}
          handleChange={handleChange}
          getBackGroundColor={getBackGroundColor}
          addOutCome={addOutCome}
          errorData={errorData}
          deleteTags={deleteTags}
          addRemainder={addRemainder}
        />
        <Flex>
          <CustomSelectBox
            label="SLA Met Color"
            options={SLA_MET_COLORS}
            value={
              getWholeOption(
                slaData?.[SLA_FORM_KEY_MAP.SLA_MET_COLOR] || '#008000',
                SLA_MET_COLORS
              )
            }
            onChange={(e) => {
              handleChange(SLA_FORM_KEY_MAP.SLA_MET_COLOR, e.value);
            }}
            id="EditSlaModalSLAMetColorInput"
          />
          <Box
            w="20px"
            style={{
              backgroundColor: slaData?.[SLA_FORM_KEY_MAP.SLA_MET_COLOR] || '#008000',
              alignSelf: 'flex-end',
              height: '38px',
              width: '38px',
              marginLeft: '11px',
              border: '1px solid rgb(226, 232, 240)',
              borderRadius: '5px',
            }}
          />
        </Flex>
      </Box>
    </Box>
  );
};

NewSLA.propTypes = {
  rowData: PropTypes.object.isRequired,
  setWholeSlaList: PropTypes.func.isRequired,
  itemIndex: PropTypes.number.isRequired,
  onDeleteSla: PropTypes.func.isRequired,
  errorDataItem: PropTypes.object,
};
NewSLA.defaultProps = {
  errorDataItem: {},
};

export default NewSLA;
