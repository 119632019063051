import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import _cloneDeep from 'lodash/cloneDeep';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import CustomButton from '../../../components/Button/SimpleButton';
import { COLORS } from '../../../utils/enums/colors';
import DragAndResizeLayout from '../../TicketTemplateBuilder/ConfigureTemplateView/DragAndResizeLayout';
import { saveMetaTableLayout } from '../LinkedMetaFieldDrawer/utils';
import CustomToast from '../../../components/Notification/Notification';
import { updateMultipleStatesOfMetaFieldList } from '../../../redux/MetaFieldsSlice/MetaFieldsReducer';
import SpecialFieldDrawer from '../SpecialFieldDrawer';
import { ensureObjectIdFirst } from '../../../redux/MetaFieldsSlice/MetaFieldsService';
import { CustomDrawer } from '../../TicketTemplateBuilder/ConfigureTemplateView/AddEditTemplateLayoutModal';

const MetaFieldLayout = ({ setShowTableLayout, isOpen, setIsOpen }) => {
  const { addToast } = CustomToast();
  const { metaColumnAttributes, metaTableAttribute } = useSelector(
    (state) => state.metaFields
  );
  const [tableAttribute, setTableAttribute] = useState({
    ...metaTableAttribute,
  });
  const [colAttributes, setColAttributes] = useState(
    ensureObjectIdFirst([...metaColumnAttributes])
  );

  const [loader, setLoader] = useState(false);
  const [openSpecialDrawer, setOpenSpecialDrawer] = useState(false);
  const dispatch = useDispatch();
  const onSave = async () => {
    setLoader(true);
    const REMOVE_ID_FROM_COL_ATTRIBUTES = _cloneDeep(colAttributes).map(
      (item) => {
        delete item.label;
        delete item.id;
        delete item.isStatic;
        return item;
      }
    );
    const payload = {
      table_attributes: tableAttribute,
      column_attributes: REMOVE_ID_FROM_COL_ATTRIBUTES,
    };
    const res = await saveMetaTableLayout(
      payload,
      'Failed to save the meta field layout'
    );
    const ADD_LABEL_ID_IN_COLUMN = res.response.column_attributes.map(
      (item) => ({
        ...item,
        id: item.meta_field_id,
        label: item.meta_field_name,
      })
    );
    if (!res.error) {
      dispatch(
        updateMultipleStatesOfMetaFieldList([
          {
            stateKey: 'metaColumnAttributes',
            value: ADD_LABEL_ID_IN_COLUMN,
          },
          {
            stateKey: 'metaTableAttribute',
            value: res.response.table_attributes,
          },
        ])
      );
      addToast({
        title: 'Success',
        message: 'Successfully saved the meta field layout',
        type: 'success',
      });
    } else {
      setTableAttribute({ ...metaTableAttribute });
      setColAttributes([...metaColumnAttributes]);
      addToast({
        title: 'Something went wrong',
        message: res.error,
        type: 'error',
      });
    }
    setLoader(false);
    isOpen && setIsOpen(false);
  };
  const updateSpecialField = (val) => {
    setColAttributes(ensureObjectIdFirst([...val]));
    // setColAttributes(val);
  };
  useEffect(() => {
    setColAttributes(ensureObjectIdFirst([...metaColumnAttributes]));
  }, [metaColumnAttributes, setColAttributes]);

  return (
    <CustomDrawer
      openDrawer={isOpen}
      setOpenDrawer={setIsOpen}
      modalTitle={<Text style={{ fontWeight: 'bold' }}>Meta Table Layout</Text>}
      drawerBody={
        <>
          <Flex
            gap="15px"
            data-testid="LinkedMetaFieldTableViewContainerFlex"
            width="100%"
            justifyContent="end"
          >
            {/* <CustomButton
              buttonText="Add Meta Fields"
              variant="outline"
              style={{ height: '32px' }}
              color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
              onClick={() => {
                setOpenSpecialDrawer(true);
              }}
            /> */}
            <CustomButton
              color="white"
              id="button-edit-meta-layout-saveTextBtn"
              onClick={onSave}
              buttonText="Save"
              variant="solid"
              bg={useColorModeValue('#2563EB', '#BB86FC')}
              isLoading={loader}
            />
          </Flex>
          <Box width="100%">
            <DragAndResizeLayout
              itemsStateList={colAttributes}
              setItemsStateList={setColAttributes}
              layoutType="MOBILE"
              viewType="MOBILE"
              metaFieldTableAttributes={tableAttribute}
              setMetaFieldTableAttributes={setTableAttribute}
              metaFieldLayoutTitle="Table"
              mainwidth="100%"
            />
          </Box>
          {openSpecialDrawer && (
            <SpecialFieldDrawer
              openDrawer={openSpecialDrawer}
              setOpenDrawer={setOpenSpecialDrawer}
              title="Add Meta Fields"
              checkBoxList={colAttributes}
              setCommonAttributesState={updateSpecialField}
            />
          )}
        </>
      }
    />
  );
};

MetaFieldLayout.propTypes = {
  setShowTableLayout: PropTypes.func.isRequired,
};

export default MetaFieldLayout;
