import React from 'react';
import { Box } from '@chakra-ui/react';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { ASSIGNMENT_STRATEGY } from '../../DynamicRenderer/customEventAndTriggers/customEventAndTriggers.constant';
import { getWholeOption } from '../../TicketTemplateWorkflow/helper';
import ActorAssignmentEvent from '../../DynamicRenderer/customEventAndTriggers/ActorAssignmentEvent';

const renderActorAssignmentForm = (
  actionState,
  handleChange,
  actionToPerformSelectedIndex,
  actionErrorData,
  handleActionErrorData,
  selectedTemplate,
  actionToPerform) => (
<>
    <ActorAssignmentEvent
      eventSettings={actionState}
      handleChange={handleChange}
      actionToPerformSelectedIndex={actionToPerformSelectedIndex}
      actionErrorData={actionErrorData}
      handleActionErrorData={handleActionErrorData}
      template={selectedTemplate}
    />
    <Box data-testid="DynamicRendererManual2Box">
      <CustomSelectBox
        options={ASSIGNMENT_STRATEGY}
        label="Assignment Strategy"
        placeholder="select field"
        value={getWholeOption(
          actionToPerform.assignment_strategy || '',
          ASSIGNMENT_STRATEGY
        )}
        onChange={(value) => {
          handleChange(
            value.value,
            `action_to_perform[${actionToPerformSelectedIndex}].assignment_strategy`
          );
          handleActionErrorData('assignment_strategy');
        }}
        isMandatory
        id="DynamicRendererManualEventCustomSelect"
      />
    </Box>
</>
);

export default renderActorAssignmentForm;
