/* eslint-disable indent */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomInput from '../../components/InputBox/Input';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import { stringToSlug } from '../TicketTemplateBuilder/utils/helper';

const InputView = ({
  name,
  value,
  inputType,
  parentName,
  placeholder,
  label,
  isMandatory,
  linkedSlugField,
  slugPrefix,
  size = 'sm',
}) => {
  const { viewState } = useSelector((state) => state);
  const dispatch = useDispatch();

  return (
    <div data-testid="InputViewDiv">
      <CustomInput
        name={name}
        type={inputType}
        placeholder={placeholder}
        size={size}
        value={
          viewState[parentName] ? viewState[parentName][value] ?? '' : '' || ''
        }
        onChange={(e) => {
          dispatch(
            updateViewState({
              stateKey: parentName,
              value: {
                ...viewState[parentName],
                [value]: e.target.value,
                ...(linkedSlugField
                  ? {
                      [linkedSlugField]: stringToSlug(
                        `${slugPrefix}_${e.target.value}`
                      ),
                    }
                  : {}),
              },
            })
          );
        }}
        isError={
          viewState[parentName]?.errorObj
            ? viewState[parentName].errorObj[value]
            : undefined
        }
        label={label}
        isMandatory={isMandatory}
        id="InputViewDivCInput"
      />
    </div>
  );
};

export default InputView;
