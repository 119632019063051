import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import CustomMention from '../../components/Mention';

const DummyConditionCheck = () => {
  const { selectedTemplate } = useSelector((state) => state.viewState || {});
  const [normalCheck, setnormalCheck] = useState('');
  console.log(normalCheck, 'CHECK');
  return (
    <Box>
      <CustomMention
        value={normalCheck}
        onChange={(e) => {
          setnormalCheck(e);
        }}
        options={[
          { value: '0a5b17f2-7fb4-4cc2-8c8e-7d9e02ecb73d', label: 'Test1' },
          { value: 'e312f7fd-15cf-4dd0-ba31-4904171ab9c1', label: 'Test2' },
        ]}
        label="Test"
        linkedItemId="152"
      />
    </Box>
  );
};

DummyConditionCheck.propTypes = {};

export default DummyConditionCheck;
