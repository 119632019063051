/* eslint-disable react/prop-types */

import React from 'react';
import { Table, Td, Th, Thead, Tr, Tbody } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { DeleteIcon } from '@chakra-ui/icons';
import _get from 'lodash/get';
import ShowValueMapForDropdownField from '../../pages/DynamicRenderer/Relation/showValueMapForDropdownField';
import { getTypeOfFieldForRelation } from '../../pages/DynamicRenderer/Relation/relation.service';
import { FieldTypes } from '../../utils/enums/types';

const TableWithAction = ({
  columns,
  rowData,
  keys,
  action,
  onClick,
  className,
  leftTemplate,
  rightTemplate,
  addValueMapInSimilarities,
  handleChangeForSelectBox,
  details,
  deleteMapValueFromSimilarity,
  showValueMapField,
  hierarchies,
  isRight,
}) => (
  <Table className={className}>
    <Thead>
      {columns?.map((head) => (
        <Th>{head}</Th>
      ))}
    </Thead>
    <Tbody>
      {rowData?.map((data, index) => (
        <>
          {getTypeOfFieldForRelation(leftTemplate, data, 'fieldA') ===
            FieldTypes.DROPDOWN &&
            getTypeOfFieldForRelation(rightTemplate, data, 'fieldB') ===
              FieldTypes.DROPDOWN &&
            index === 0 && (
              <Tr>
                {' '}
                <Td fontWeight="600">{`${leftTemplate.name}`}</Td>
                <Td> </Td>
                <Td fontWeight="600">{`${rightTemplate.name}`}</Td>
                <Td> </Td>
              </Tr>
          )}
          <Tr>
            {keys?.map((k) => (
              <Td fontWeight="600">{_get(data, `${k}`)}</Td>
            ))}
            <Td style={{ width: '60px' }}>
              {action === 'delete' && !isRight && !details.id ? (
                <DeleteIcon onClick={() => onClick(index)} color="red" />
              ) : null}
            </Td>
          </Tr>
          {showValueMapField && (
            <ShowValueMapForDropdownField
              data={data}
              leftTemplate={leftTemplate}
              rightTemplate={rightTemplate}
              addValueMapInSimilarities={addValueMapInSimilarities}
              similarityIndex={index}
              handleChangeForSelectBox={handleChangeForSelectBox}
              details={details}
              deleteMapValueFromSimilarity={deleteMapValueFromSimilarity}
              hierarchies={hierarchies}
              isRight={isRight}
            />
          )}
        </>
      ))}
    </Tbody>
  </Table>
);

TableWithAction.propTypes = {
  columns: PropTypes.array.isRequired,
  rowData: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  keys: PropTypes.array.isRequired,
  action: PropTypes.element,
  className: PropTypes.any,
  leftTemplate: PropTypes.object,
  rightTemplate: PropTypes.object,
  addValueMapInSimilarities: PropTypes.func,
  handleChangeForSelectBox: PropTypes.func,
  details: PropTypes.object,
  deleteMapValueFromSimilarity: PropTypes.func,
  showValueMapField: PropTypes.bool,
  hierarchies: PropTypes.array,
  isRight: PropTypes.bool,
};
TableWithAction.defaultProps = {
  onClick: undefined,
  action: undefined,
  className: undefined,
  leftTemplate: {},
  rightTemplate: {},
  addValueMapInSimilarities: () => {},
  handleChangeForSelectBox: () => {},
  details: {},
  deleteMapValueFromSimilarity: () => {},
  showValueMapField: false,
  hierarchies: [],
  isRight: false,
};

export default TableWithAction;
