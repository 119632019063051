import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import allCompositeService from './AllCompositeService';

export const getCompositeList = createAsyncThunk(
  'allCompositeList/getAllCompositeApi',
  async (options, thunkAPI) => {
    try {
      const listData = await allCompositeService.getAllCompositeApi(options);
      return listData;
    } catch (error) {
      const { message } = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  allCompositeList: [],
  isLoading: false,
  isError: false,
};

const allComposite = createSlice({
  name: 'allComposite',
  initialState,
  reducers: {
    reset: (state) => {
      state.allCompositeList = [];
      state.isError = false;
      state.error = '';
    },
    resetError: (state) => {
      state.isError = false;
      state.error = '';
    },
    updateAllCompositeList: (state, action) => {
      state.allCompositeList = action.payload.allCompositeList;
    },
    addFieldstoComposite: (state, action) => {
      state.allCompositeList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompositeList.pending, (state) => {
        state.isLoading = true;
        state.allCompositeList = [];
        state.isError = false;
        state.error = '';
      })
      .addCase(getCompositeList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allCompositeList = action.payload;
      })
      .addCase(getCompositeList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload;
      });
  },
});

export const {
  reset,
  resetError,
  updateAllCompositeList,
  addFieldstoComposite,
} = allComposite.actions;

export default allComposite.reducer;
