import React, { useCallback, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import CustomSelectBox from '../../../components/SelectBox/Select';

import { FORM_MAP } from '../filters.constants';
import { FILTER_ROLE_MAPPING_VALIDATIONS } from '../../../utils/validationSchemas/Filters';
import CustomDrawer from '../CustomDrawer';

const FilterRoleMappingsDrawer = ({
  data,
  onSave,
  roles,
  filters,
  open,
  setOpen,
}) => {
  const [filterData, setFilterData] = useState({ ...data });
  const [errorMessage, setErrorMessage] = useState(false);

  const closeDrawer = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleChange = useCallback((key, value) => {
    setFilterData((prev) => ({
      ...prev,
      [key]: value,
    }));

    setErrorMessage('');
  }, []);

  const handleSave = useCallback(() => {
    const payload = {
      id: filterData?.id,
      [FORM_MAP.ROLES]: filterData?.[FORM_MAP.ROLES]?.value,
      [FORM_MAP.DEFAULT_FILTER]: filterData?.[FORM_MAP.DEFAULT_FILTER]?.value,
      [FORM_MAP.FILTERS]: filterData?.[FORM_MAP.FILTERS]?.map((e) => e.value),
    };

    const { error } = FILTER_ROLE_MAPPING_VALIDATIONS.validate(payload, {
      abortEarly: false,
    });

    if (error) {
      setErrorMessage('Please fill all mandatory fields');
      return;
    }

    if (
      !payload?.[FORM_MAP.FILTERS]?.includes(payload?.[FORM_MAP.DEFAULT_FILTER])
    ) {
      setErrorMessage(
        'Please select a default filter among the selected filters'
      );
      return;
    }

    onSave(payload);
  }, [filterData, onSave]);

  const drawerBody = useMemo(() => {
    const uniqueRoles = roles.filter((obj, index, self) =>
      index === self.findIndex((t) => t.label === obj.label)
    );

    const content = (
      <>
        <div className="mb-5">
          <CustomSelectBox
            label="Role"
            isMandatory
            options={uniqueRoles}
            value={filterData?.[FORM_MAP.ROLES] || null}
            onChange={(e) => handleChange(FORM_MAP.ROLES, e)}
          />
        </div>

        <div className="mb-5">
          <CustomSelectBox
            label="Filters"
            isMandatory
            options={filters}
            value={filterData?.[FORM_MAP.FILTERS] || []}
            onChange={(e) => handleChange(FORM_MAP.FILTERS, e)}
            isMulti
          />
        </div>

        <div className="mb-5">
          <CustomSelectBox
            label="Default Filter"
            isMandatory
            options={filterData?.[FORM_MAP.FILTERS] || []}
            value={filterData?.[FORM_MAP.DEFAULT_FILTER] || null}
            onChange={(e) => handleChange(FORM_MAP.DEFAULT_FILTER, e)}
          />
        </div>
      </>
    );

    return content;
  }, [filterData, handleChange]);

  return (
    <CustomDrawer
      title="Filter Role Mapping"
      closeDrawer={closeDrawer}
      handleSave={handleSave}
      open={open}
      errorMessage={errorMessage || ''}
      drawerBody={drawerBody}
    />
  );
};

FilterRoleMappingsDrawer.propTypes = {
  data: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
};

FilterRoleMappingsDrawer.defaultProps = {
  data: {},
};

export default FilterRoleMappingsDrawer;
