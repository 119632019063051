/* eslint-disable react/prop-types */
import { Box, Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateAllTemplateList } from '../../../redux/TicketTemplate/AllTemplatesReducer/AllTemplateSlice';
import CustomButton from '../../../components/Button/SimpleButton';
import CustomModal from '../../../components/Modal/Modal';
import CreateFieldContent from './FieldContent';
import { FieldTypes, TemplateTypes } from '../../../utils/enums/types';
import { LABELS, TEMPLATE_VALIDATE_KEY } from '../../../utils/enums/labels';
import { addTemplateApi, editTemplateApi } from '../utils/helper';
import { workflowId } from '../../../utils/enums/axiosConstant';
import createBaseSettings from './BaseSetting';

const CreateTemplateModal = ({
  type,
  templateName,
  // data = {},
  edit = false,
  isTemplatedModel,
  setIsTemplatedModel,
}) => {
  const { id = '' } = useParams();
  const { allTemplatesList } = useSelector((state) => state.allTemplates);
  const [selectedTicket, setSelectedTicket] = useState({});
  const [fields, setFields] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isError, setIsError] = useState({});
  const [baseData, setBaseData] = useState([]);
  const [fieldData, setFieldData] = useState([]);
  const [isActive, setIsActive] = useState(false);

  const handleData = () => {
    switch (type) {
      case FieldTypes.TEXT:
      case FieldTypes.EMAIL:
        setBaseData(createBaseSettings(fields, setFields, setIsError));
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    handleData();
  }, [fields]);

  // validation
  const checkIsAllRequireTrue = () => {
    const result = {};
    const errorRowOne = baseData?.row1?.map((each) => {
      if (each.required) {
        if (fields[each.key]) {
          Object.assign(result, { [each.key]: false });
          return true;
        }
        Object.assign(result, { [each.key]: true });
        return false;
      }
      Object.assign(result, { [each.key]: false });
      return true;
    });
    setIsError(result);
    const cur = [...(errorRowOne || [])].every((item) => item);
    setIsActive(cur);
    return cur;
  };

  const finishCheck = () => {
    const row1 = baseData?.row1?.map((each) => {
      if (each.required) {
        if (fields[each.key]) {
          return true;
        }
        return false;
      }
      return true;
    });
    setIsActive([...(row1 || [])].every((item) => item));
  };

  useEffect(finishCheck, [fields]);
  // validation end

  function updateObjectById(ids, arr) {
    return arr.map((obj) => {
      if (obj.id === ids) {
        // updates selected object
        setSelectedTicket(obj);
        return obj;
      }
      if (obj.subItems && obj.subItems.length) {
        // recursively checks subItems arrays
        return {
          ...obj,
          subItems: updateObjectById(ids, obj.subItems),
        };
      }
      // if no template found
      // setSelectedTicket(null);
      return obj;
    });
  }

  useEffect(() => {
    if (id && edit) {
      updateObjectById(id, allTemplatesList);
    }
  }, [id, allTemplatesList]);

  useEffect(() => {
    if (edit) {
      setFields(selectedTicket);
    }
  }, [selectedTicket]);

  const addTemplate = async () => {
    let subItemsArr = [];
    if (fields?.templateType === TemplateTypes.NESTED) {
      subItemsArr = fields?.selectedTemplates?.map((i) => ({
        id: Math.random(),
        name: `${i?.value?.name} - ${fields?.name}`,
      }));
    }

    const datas = {
      name: fields?.name,
      children: subItemsArr,
      templateType: fields?.templateType,
      description: fields?.description,
      version: 'v1',
      workflow_id: workflowId,
      status: 'DRAFT',
      parent_id: null,
      previous_version_id: null,
      cloned_from: null,
      is_default: false,
      group_id: null,
      collabrators: null,
      is_marked_favourite: false,
    };

    let response = {};

    if (edit) {
      response = await editTemplateApi(datas, id);
    } else {
      response = await addTemplateApi(datas);
      if (typeof response === 'string') {
        setBaseData((prevState) => ({
          ...prevState,
          row1: prevState.row1.map((item) => {
            if (item.key === TEMPLATE_VALIDATE_KEY.NAME) {
              return {
                ...item,
                errorString: LABELS.TEMPLATE_ERROR.VALUE_USED,
              };
            }
            return item;
          }),
        }));
        setIsError({ ...isError, [TEMPLATE_VALIDATE_KEY.NAME]: true });
      }
    }

    if (typeof response !== 'string' && Object.keys(response).length > 0) {
      const editedList = allTemplatesList.map((i) => {
        if (i.id === id) {
          return { ...i, ...datas };
        }
        return i;
      });

      dispatch(
        updateAllTemplateList({
          allTemplatesList: edit ? editedList : [response, ...allTemplatesList],
        })
      );
      navigate(`/template-builder/${edit ? selectedTicket?.id : response.id}`);

      setIsTemplatedModel(false);
    }
  };
  const handleAddTemplate = () => {
    const bool = checkIsAllRequireTrue();
    if (bool) {
      addTemplate();
    }
  };
  return (
    <CustomModal
      id="selected-field-modal"
      modalTitle={<h1>{templateName}</h1>}
      modalContent={
        <Box mt="7" data-testid="CreateTemplateModalFieldContentBox">
          <CreateFieldContent
            type={type}
            fields={fields}
            setFields={setFields}
            baseData={baseData}
            fieldData={fieldData}
            isError={isError}
          />
        </Box>
      }
      modalFooter={
        <Flex gap="8px" data-testid="CreateTemplateModalButtonBox">
          <CustomButton
            variant="outline"
            buttonColor="customBlue"
            size="lg"
            buttonText={LABELS.ACTIONS.CANCEL}
            onClick={() => setIsTemplatedModel(false)}
            id="CreateTemplateModalButtonCanclBtn"
          />
          <CustomButton
            variant="solid"
            buttonColor="customBlue"
            size="lg"
            buttonText={LABELS.ACTIONS.CONTINUE}
            onClick={() => {
              handleAddTemplate();
            }}
            id="CreateTemplateModalButtonContinueBtn"
          />
        </Flex>
      }
      open={isTemplatedModel}
      setOpen={setIsTemplatedModel}
    />
  );
};

export default CreateTemplateModal;
