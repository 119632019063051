import _ from 'lodash';
import { getDynamicValuesFromCurl, getQueueListForWholeTenant, getTitlesHierarchy } from '../../../components/dynamicExpressionWithTags/dynamicExpressionWithTags.helper';
import { optionLabel } from '../../../utils/constants';
import { SCOPE_MAP } from '../../../utils/enums/selectOption';
import { FieldTypes } from '../../../utils/enums/types';
import { selectOptionsChannels } from '../customTemplateModal/CustomTemplateModalLayout';
import { availableUsersListInTenantLevel } from '../../../utils/helper/roleBasedAccess';
import { getSelectedFieldHierarchyMap, getSelectedHierarchy } from '../../../components/selectFieldFromTemplateWithDelete/utils';

export const makeRelationDefinitionOptions = (relationDefinitions) => {
  const options = [];

  if (Array.isArray(relationDefinitions)) {
    relationDefinitions?.forEach((defs) => {
      options.push({
        label: defs?.string_pair_a,
        value: `${defs?.id}__A`,
      });
      options.push({
        label: defs?.string_pair_b,
        value: `${defs?.id}__B`,
      });
    });
  }

  return options;
};

export const makeRelationDefinitionOptionValue = (id, relationDefinitions) => {
  const matchedField = relationDefinitions?.find((f) => f?.id === id);
  if (matchedField) {
    return {
      label: matchedField?.string_pair_a,
      value: matchedField?.id,
    };
  }
  return null;
};

export const findPairB = (pairA, relationDefinitions) =>
  relationDefinitions?.find(
    (r) => r?.string_pair_a === pairA || r?.string_pair_b === pairA
  );

export const getPairedString = (pairA, relationDefinitions) => {
  const pair = findPairB(pairA, relationDefinitions);

  if (pair?.string_pair_a === pairA) {
    return pair.string_pair_b;
  }
  return pair?.string_pair_a;
};

export const generateTemplateIdMap = (allTemplates) => {
  if (!Array.isArray(allTemplates)) {
    return {};
  }

  return allTemplates.reduce((acc, template) => {
    acc[template.id] = template;
    return acc;
  }, {});
};

export const generateOfferIdMap = (allOffers) => {
  if (!Array.isArray(allOffers)) {
    return {};
  }

  return allOffers.reduce((acc, offer) => {
    acc[offer.id] = offer;
    return acc;
  }, {});
};

export const generateFieldMap = (fields) => {
  if (!Array.isArray(fields)) {
    return {};
  }

  return fields.reduce((prev, curr) => {
    prev[curr?.id || curr?.value || ''] = curr;
    return prev;
  }, {});
};

export const getFieldsForSelect = (fields) => {
  if (!Array.isArray(fields)) {
    return [];
  }

  return fields.map((f) => ({
    label: f?.label,
    value: f?.id,
  }));
};

export const getRelationScopeFields = (fields, templateId) => {
  if (!Array.isArray(fields)) {
    return [];
  }

  return fields
    .filter((f) => f?.id && f?.scope === SCOPE_MAP.RELATION)
    .map((val) => ({
      template_id: templateId,
      label: val?.label,
      value: val?.id,
    }));
};

export const enrichDataForEdit = ({
  templateIdMap,
  data,
  id,
}) => {
  const leftTemplate = templateIdMap[data?.template_id_left];
  const rightTemplate = templateIdMap[data?.template_id_right];

  const fieldsAIdMap = generateFieldMap(leftTemplate?.fields);
  const fieldsBIdMap = generateFieldMap(rightTemplate?.fields);

  const relationFieldOfA = getRelationScopeFields(
    leftTemplate?.fields,
    data?.template_id_left,
  );

  const relationFieldOfB = getRelationScopeFields(
    rightTemplate?.fields,
    data?.template_id_right
  );

  const relationFieldOfAIdMap = generateFieldMap(relationFieldOfA);
  const relationFieldOfBIdMap = generateFieldMap(relationFieldOfB);

  const relation = {
    name: data?.name?.replace(/\s+/g, ' ').trim(),
    id: data?.id,
    offer_id: data?.offer_id,
    relation_type: data?.relation_type,
    cardinality: data?.cardinality,
    leftItemId: data?.template_id_left,
    rightItemId: data?.template_id_right,
    offer_id_left: data?.offer_id_left,
    offer_id_right: data?.offer_id_right,
  };

  relation.customAttribute = data?.custom_attributes?.map((attr) => {
    let matchedField = relationFieldOfAIdMap[attr?.field_id];
    if (!matchedField) {
      matchedField = relationFieldOfBIdMap[attr?.field_id];
    }
    if (!matchedField) {
      return null;
    }
    return {
      field: matchedField,
      mandatory: attr?.isRequired ? optionLabel.mandatory : optionLabel.optional,
    };
  }).filter((x) => x);

  // If selected template is the left template
  if (id === data?.template_id_left) {
    relation.itemType = data?.template_id_right;
    relation.relation = {
      value: data?.relation_def?.left_to_right,
      label: data?.relation_def?.left_to_right,
    };
    relation.similarities = data?.similarity?.map((sim) => {
      const fieldOfA = fieldsAIdMap[sim?.left_field_id];
      const fieldOfB = fieldsBIdMap[sim?.right_field_id];
      return {
        fieldA: {
          value: fieldOfA?.id,
          label: fieldOfA?.label,
        },
        similar: '~',
        fieldB: {
          value: fieldOfB?.id,
          label: fieldOfB?.label,
        },
        mappings: sim?.mappings || []
      };
    });
  } else {
    relation.itemType = data?.template_id_left;
    relation.relation = {
      value: data?.relation_def?.right_to_left,
      label: data?.relation_def?.right_to_left,
    };
    relation.similarities = data?.similarity?.map((sim) => {
      const fieldOfA = fieldsAIdMap[sim?.left_field_id];
      const fieldOfB = fieldsBIdMap[sim?.right_field_id];
      const swapLeftWithRight = [...(sim?.mappings || [])]
        .map((item) => ({ left: item.right, right: item.left }));
      return {
        fieldA: {
          value: fieldOfB?.id,
          label: fieldOfB?.label,
        },
        similar: '~',
        fieldB: {
          value: fieldOfA?.id,
          label: fieldOfA?.label,
        },
        mappings: swapLeftWithRight
      };
    });
  }

  return relation;
};
export const fieldValueOption = async (template, workflowData, fieldId, hierarchies = []) => {
  const field = [...(template?.fields || [])]?.find((f) => f?.id === fieldId);
  let values;
  if (field?.type === FieldTypes.DROPDOWN || field?.type === FieldTypes.TAGS) {
    const collectionType = _.get(
      field,
      'type_based_attributes.collection.collection_type'
    );
    const specialFieldType = _.get(
      field,
      'type_based_attributes.collection.specialField'
    );
    if (collectionType === 'DYNAMIC') {
      values = _.get(field, 'type_based_attributes.collection.dynamicCurl'); // curlKey name is change
      return getDynamicValuesFromCurl(values) || [];
    }
    if (collectionType === 'STATIC') {
      values = _.get(field, 'type_based_attributes.collection.staticValues');
      if (typeof values === 'string') {
        values = values?.split(/\r?\n|\r|\n/g);
        const options = values?.map((v) => {
          if (!v) return null;
          return { value: v, label: v };
        });
        return options?.filter((x) => x);
      }
      return values?.filter((x) => x.value && x.label) || [];
    }
    if (collectionType === 'DEPENDENT') {
      if (template?.field_hierarchy_map) {
        const selectedMapping = getSelectedFieldHierarchyMap(
          template?.field_hierarchy_map, field.id
        );
        if (selectedMapping) {
          const selectedHierarchy = getSelectedHierarchy(
            hierarchies, selectedMapping.heirachyListId
          );

          const titles = getTitlesHierarchy(
            selectedHierarchy.hierarchy || [],
            selectedMapping
          );
          if (titles[field.id]) {
            return titles[field.id].map((item) => ({ value: item, label: item }));
          }
        } else {
          return [];
        }
      } else {
        return [];
      }
    }
    if (collectionType === 'SPECIAL_FIELD' && specialFieldType === 'queue') {
      const listFilter = _.get(field, 'type_based_attributes.collection.list');
      const isWhiteList = _.get(
        field,
        'type_based_attributes.collection.isWhiteList'
      );
      return getQueueListForWholeTenant(listFilter, isWhiteList);
    }
    if (
      collectionType === 'SPECIAL_FIELD' &&
      specialFieldType === 'workflowState'
    ) {
      return workflowData?.workFlowStates || [];
    }
    if (collectionType === 'SPECIAL_FIELD' && specialFieldType === 'source') {
      return selectOptionsChannels;
    }

    if (collectionType === 'SPECIAL_FIELD' && specialFieldType === 'actor') {
      const users = availableUsersListInTenantLevel();
      return users;
    }
  }
  return [];
};
