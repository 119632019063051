import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Box, useColorModeValue } from '@chakra-ui/react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

import Header from './Header';
import AllCardList from './AllCardList';
import Loader from '../DynamicRenderer/Dashboard/Loader';
import CustomTable from '../../components/Table/Table';

import {
  fetchFavorites,
  isEmptyCardList,
  selectAllCards,
  selectAllCardsError,
  selectAllCardsLoading,
  storeFetchedCards,
  updateIsEmptyCardList,
} from '../../redux/CardReducer/AllCardSlice';

import { setActiveModal } from '../../redux/ModalReducer/ModalSlice';
import TemplateDeleteModal from '../TicketTemplateBuilder/TemplateEditor/TemplateDeleteModal';
import { deleteTemplate, getTree, toggleFavoriteForTemplate } from './helpers';
import {
  removeFavoriteFromStore,
  storeCreatedFavorite,
} from '../../redux/CardReducer/favoritesSlice';
import { columns, scrollToTemplate } from './TemplateTableView/utils';

import styles from './cardLayout.module.scss';
import sumStyles from '../TicketTemplateBuilder/styles/templatebuilder.module.scss';
import CloneTemplate from '../DynamicRenderer/customTemplateModal/CloneTemplate';
import axiosInstance from '../../utils/axios/axios';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import CustomToast from '../../components/Notification/Notification';
import { LABELS } from '../../utils/enums/labels';
import CustomTemplateModal from '../DynamicRenderer/customTemplateModal/CustomTemplateModal';
import { FieldTypes } from '../../utils/enums/types';

const TemplateCardList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const data = useSelector(selectAllCards);
  const favorites = useSelector((state) => state.favorites);
  const loading = useSelector(selectAllCardsLoading);
  const error = useSelector(selectAllCardsError);
  const isEmptyTicketCards = useSelector(isEmptyCardList);

  const [searchText, setSearchText] = useState('');
  const [currentCount, setCurrentCount] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isCardView, setIsCardView] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [templateProp, setTemplateProp] = useState({});
  const pathname = useLocation().pathname  
  const [openCustomTemplateModel, setCustomTemplateModel] = useState(false)

  const cardId = useRef(-1);
  const appliedFiltersRef = useRef({});
  const searchRef = useRef('');
  const templateListRef = useRef(null);
  const isScrolled = useRef(false);
  const { addToast } = CustomToast();

  // clear selectedTemplate state on template-cardList page load
  useEffect(() => {
    dispatch(
      updateViewState({
        stateKey: 'selectedTemplate',
        value: {},
      })
    );
  }, []);

  const cardCount = useMemo(
    () => (
      <Box
        backgroundColor={useColorModeValue(
          sumStyles.ticketCountField,
          sumStyles.ticketCountFieldDark
        )}
        className={`
        ${useColorModeValue(
          sumStyles.ticketCountField,
          sumStyles.ticketCountFieldDark
        )}`}
        style={{ margin: 0 }}
      >
        <span>{`${currentCount}`}</span>
      </Box>
    ),
    [currentCount]
  );

  const onCreateNewTemplate = useCallback(() => {
    setCustomTemplateModel(true)
  }, [navigate]);

  const onEditTemplate = useCallback(
    (id, name) => {
      if(pathname==='/template-cardlist'){
        axiosInstance
        .get(
          `/templates/list?$top=1&$skip=0&$select=*&$filter=id eq ${id}&history=true`
        )
        .then((responseData) => {
          const selectedTemplate = responseData?.data?.rows[0] || {};
          dispatch(
            updateViewState({
              stateKey: 'selectedTemplate',
              value: {
                ...selectedTemplate,
              },
            })
          );
          navigate(`/template-makers/template/${id}?name=${name}`);
        })
        .catch((e) => {
          addToast({
            title: 'Something went wrong',
            message: e?.response?.data?.message || '',
            type: 'error',
          });
        });
      }
      else{
        axiosInstance
        .get(
          `/fieldDefaults/list?$select=*`
        )
        .then((responseData) => {
          const selectedTemplate = responseData?.data?.rows[0] || {};
          dispatch(
            updateViewState({
              stateKey: 'selectedTemplate',
              value: {
                ...selectedTemplate,
              },
            })
          );
          navigate(`/bot-integration/template/${id}?name=${name}`);
        })
        .catch((e) => {
          addToast({
            title: 'Something went wrong',
            message: e?.response?.data?.message || '',
            type: 'error',
          });
        });
      }
    },
    [navigate]
  );

  const favoriteMap = useMemo(
    () =>
      favorites?.reduce((prev, curr) => {
        prev[curr?.template_id] = true;
        return prev;
      }, {}),
    [favorites]
  );

  const onDeleteConfirmation = useCallback(async () => {
    if (cardId.current) {
      const res = await deleteTemplate(cardId?.current);
      if (!res?.error) {
        const updatedCards = data?.filter((t) => t?.id !== cardId?.current);
        dispatch(storeFetchedCards(updatedCards));
      }
    }
    setOpenDeleteModal(false);
  }, [data]);

  const onClone = (id, name) => {
    setTemplateProp({ id, name });
    setIsOpen(true);
  };

  const toggleFavorite = useCallback(
    async (id) => {
      const isFavorite = favoriteMap[id];
      const res = await toggleFavoriteForTemplate(id, isFavorite);
      if (!res?.error) {
        if (isFavorite) {
          dispatch(removeFavoriteFromStore(id));
        } else {
          dispatch(storeCreatedFavorite(res?.response));
        }
      }
    },
    [data, favorites, favoriteMap]
  );

  const onDelete = useCallback((id) => {
    cardId.current = id;
    setOpenDeleteModal(true);
  }, []);

  const filterData = useCallback(
    (inputValue = '') =>
      (data || [])?.filter((item) =>
        item?.name?.toLowerCase()?.includes(inputValue?.toLowerCase())
      ),
    [data]
  );

  const filterResults = debounce((inputValue) => {
    setFilteredData(filterData(inputValue));
  }, 500);

  useEffect(() => {
    searchRef.current = searchText;
    filterResults(searchText);
    if (filterData.length === 0) {
      dispatch(updateIsEmptyCardList(true));
    }
  }, [searchText]);

  useEffect(() => {
    setFilteredData(filterData(searchRef?.current));

    if (!isScrolled?.current) {
      const templateId = location?.hash?.slice(1);
      scrollToTemplate(templateListRef, templateId, isScrolled);
    }
  }, [data]);

  useEffect(() => {
    const getFavorites = async () => {
      await dispatch(fetchFavorites());
    };

    getFavorites();
  }, []);

  const filteredItems = useMemo(() => {
    let filtereddata = filteredData;
    if (!appliedFiltersRef?.current?.selectedSortType) {
      filtereddata = filteredData?.sort((a, b) => {
        const isAFavorite = favoriteMap[a?.id];
        const isBFavorite = favoriteMap[b?.id];

        if (isAFavorite && !isBFavorite) {
          return -1;
        }
        if (!isAFavorite && isBFavorite) {
          return 1;
        }
        return 0;
      });
    }

    setCurrentCount(filtereddata?.length);

    return filtereddata;
  }, [data, filteredData, favorites, favoriteMap]);

  const renderMainContent = () => {
    if (loading) {
      return <Loader />;
    }

    if (error) {
      return (
        <div className={styles.no__cards}>
          Something went wrong while fetching the tickets
        </div>
      );
    }

    if (isEmptyTicketCards && filteredItems?.length === 0) {
      return (
        <Box className={styles.no__cards}>
          <p>No Ticket Types Found</p>
        </Box>
      );
    }

    return (
      <Box ref={templateListRef} data-testid="TemplateCardListBox">
        {isCardView ? (
          <AllCardList
            favoriteMap={favoriteMap}
            onDelete={onDelete}
            onEdit={onEditTemplate}
            onClone={onClone}
            toggleFavorite={toggleFavorite}
            filteredItems={filteredItems}
          />
        ) : (
          <CustomTable
            columns={columns({
              favouriteMap: favoriteMap,
              onEdit: onEditTemplate,
              onDelete,
              onClone,
              toggleFavorite,
            })}
            isTableLoading={loading || (data.length && !filteredData.length) }
            tableData={getTree(filteredItems)}
            nestedTable
            onRowClick={({ rowData }) => {
              onEditTemplate(rowData?.id, rowData?.name);
            }}
            height="calc(100vh - 210px)"
            rowIdKey={(rd) => `template_${rd.id}`}
            title="Templates"
          />
        )}
      </Box>
    );
  };

  return (
    <div data-testid="TemplateCardListDiv" style={{ overflow: 'hidden' }}>
      <Box data-testid="TemplateCardListContainerBox" px="12px">
        <Box
          // flex="1"
          // borderRadius="lg"
          // role="group"
          // cursor="pointer"
          // justifyContent="space-between"
          // flexWrap="wrap"
          // mt="10px"
          // gap="5px"
          data-testid="TemplateCardListContainerFlex"
          pb="18px"
          borderBottom="1px solid #DDDCDC"
        >
          <Header
            searchText={searchText}
            setSearchText={setSearchText}
            cardCount={cardCount}
            appliedFiltersRef={appliedFiltersRef}
            onCreateNewTemplate={onCreateNewTemplate}
            isCardView={isCardView}
            setIsCardView={setIsCardView}
          />
        </Box>
        {renderMainContent()}
        {/* Delete Confirmation Modal */}
        <TemplateDeleteModal
          isDeleteModal={openDeleteModal}
          setIsDeleteModal={setOpenDeleteModal}
          onConfirmation={onDeleteConfirmation}
        />
        <CloneTemplate
          id={templateProp?.id}
          templateName={templateProp?.name}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          pathOfClone="cardList"
        />
      </Box>
      {openCustomTemplateModel && (
        <CustomTemplateModal
          type={FieldTypes.TEXT}
          templateName={LABELS.ACTIONS.CREATE_NEW_TEMPLATE}
          isEdit={false}
          isOpenLayoutModal={openCustomTemplateModel}
          setIsOpenLayoutModal={setCustomTemplateModel}
        />
      )}
    </div>
  );
};

export default TemplateCardList;
