import React from 'react';
import { RepeatIcon, ArrowBackIcon } from '@chakra-ui/icons';
import ErrorLogo from '../../assets/icons/ErrorLogo.svg';
import CustomButton from '../../components/Button/SimpleButton';
import styles from './common-layout.module.scss';
import { LABELS } from '../../utils/enums/labels';

export const ErrorPage = () => {
  const goBack = () => {
    window.location.href = '/login';
  };

  const reload = () => {
    window.location.reload();
  };

  return (
    <div className={styles.container} data-testid="ErrorPageContainerDiv">
      <div className={styles.center} data-testid="ErrorPageCenterDiv">
        <img
          alt="Something went Wrong"
          src={ErrorLogo}
          className={styles.logoImage}
          data-testid="ErrorPageErrorLogoImg"
        />
        <h2 className={styles.firstLine} data-testid="ErrorPageFirstLineH2">
          {LABELS.TITLE.PAGE_ERROR_HERO_TEXT}
        </h2>
        <p
          className={`${styles.marginBottom} ${styles.secondLine}`}
          data-testid="ErrorPageMarginBottomP"
        >
          {LABELS.TITLE.PAGE_ERROR_DESCRIPTION}
        </p>
        <div className={styles.block} data-testid="ErrorPageBlockDiv">
          <CustomButton
            leftIcon={<RepeatIcon />}
            colorScheme="whiteAlpha"
            color="black"
            id="reload-error-pageButton"
            onClick={reload}
            buttonText={LABELS.ACTIONS.RELOAD}
            variant="outline"
            className={styles.btnLeft}
          />
          <CustomButton
            className={styles.btnRight}
            color="white"
            leftIcon={<ArrowBackIcon />}
            id="button-error-pageButton"
            onClick={goBack}
            buttonText={LABELS.ACTIONS.GO_HOME}
            variant="solid"
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
