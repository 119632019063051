/* eslint-disable indent */
import React from 'react';
import _get from 'lodash/get';
import {
  Table,
  TableContainer,
  Th,
  Tr,
  Td,
  Thead,
  Tbody,
  useColorModeValue,
} from '@chakra-ui/react';

import { startCase } from 'lodash';
import PropTypes from 'prop-types';

import { BORDER_DARK, BORDER_LIGHT, COLORS } from '../../utils/enums/colors';
import TableColumnText from '../../components/Text/TableColumnText';
import { useLocation } from 'react-router';
const CustomTable = (props) => {
  const { columns, onRowClick, templateId } = props;
  const tableData = props?.tableData?.filter((data) => !data.temp);
  const {pathname} = useLocation()
  return (
    <TableContainer
      borderRadius="10px 10px 0px 0px"
      borderWidth="1.5px"
      fontSize="14px"
      borderColor={useColorModeValue(BORDER_LIGHT, BORDER_DARK)}
      color={useColorModeValue(COLORS.BLACK, COLORS.TEXT_DARK)}
      data-testid="CustomTableContainer"
    >
      <Table variant="simple" data-testid="CustomTable">
        <Thead data-testid="CustomTableThead">
          <Tr fontWeight="bold" data-testid="CustomTableThContainerTr">
            {columns.map((column) => (
              <Th
                key={column?.col_name}
                width={`${100 / columns.length}%`}
                textAlign={column.align ? column.align : 'start'}
                p="18px"
                fontWeight="700"
                data-testid="CustomTableTh"
              >
                {column.label || startCase(column.col_name)}
              </Th>
            ))}
          </Tr>
        </Thead>

        <Tbody data-testid="CustomTableTbody">
          {tableData?.map((td, index) => (
            <Tr
              key={td?.id || `table_data_row_${index + 1}`}
              cursor="pointer"
              data-testid="CustomTableTdContainerTr"
            >
              {columns.map((column, subIndex) => (
                <Td
                  key={`table_data_${index + 1}_${`${subIndex + 1}`}`}
                  {...(onRowClick &&
                    typeof onRowClick === 'function' && {
                      onClick: (e) => {
                        onRowClick({
                          event: e,
                          rowData: td,
                          column,
                          rowIndex: index,
                        });
                      },
                    })}
                  data-testid="CustomTableTd"
                >
                  {column?.cellRenderer &&
                  typeof column.cellRenderer === 'function' ? (
                    column.cellRenderer({
                      rowData: td,
                      column,
                      rowIndex: index,
                      tableData,
                      templateId,
                      pathname
                    })
                  ) : (
                    <TableColumnText
                      text={_get(td, column.col_name)}
                      data-testid="CustomTableColumnText"
                    />
                  )}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

CustomTable.propTypes = {
  columns: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  templateId: PropTypes.number,
};

CustomTable.defaultProps = {
  onRowClick: null,
  templateId: null,
};

export default CustomTable;
