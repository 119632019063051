import React, { useEffect, useState } from 'react';

import {
  Box,
  Flex,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

import CustomInput from '../../components/InputBox/Input';
import CustomTable from '../../components/Table/Table';
import CustomButton from '../../components/Button/SimpleButton';
import { COLORS } from '../../utils/enums/colors';

const TenantLocales = ({
  placeholder,
  columns,
  tableData,
  onRowClick,
  searchKey,
  addLanguage,
}) => {
  const [filteredData, setFilteredData] = useState([...tableData]);
  const [searchInputValue, setSearchInputValue] = useState('');

  const filterData = debounce((e) => {
    const filteredFields = tableData.filter((field) =>
      field[searchKey].toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredData([...filteredFields]);
  }, 500);

  useEffect(() => {
    setFilteredData([...tableData]);
  }, [tableData]);
  const handleClearInput = () => {
    setSearchInputValue('');
    setFilteredData([...tableData]);
  };
  return (
    <>
      <style>
        {`
      .customHeight{
        padding-top:0px !important;
      }
      
      `}
      </style>
      <Box px="12px" data-testid="TenantLocalesTableViewContainerBox">
        <Box
          display="flex"
          justifyContent="space-between"
          data-testid="TenantLocalesTableViewContainerBoxFlex"
        >
          <Text style={{ fontSize: '20px', fontWeight: '600' }}>Locales</Text>
          <Flex
            gap="15px"
            flexWrap="wrap"
            data-testid="TenantLocalesTableViewContainerFlex"
            width="40%"
            alignItems="center"
          >
            <Box
              flex="1"
              data-testid="TenantLocalesTableViewInnerBox"
              className="calculatedHeight customHeight"
            >
              <InputGroup size="md" className="calculatedHeight customHeight">
                <CustomInput
                  placeholder={placeholder}
                  type="text"
                  size="sm"
                  className="calculatedHeight customHeight"
                  value={searchInputValue}
                  onChange={(e) => {
                    setSearchInputValue(e.target.value);
                    filterData(e);
                  }}
                />
                  <InputRightElement
                    style={{ right: 12, cursor: 'pointer' }}
                    // eslint-disable-next-line react/no-children-prop
                    children={searchInputValue ? <CloseIcon color="gray.300" onClick={handleClearInput} /> : <SearchIcon color="gray.300" />}
                    className="calculatedHeight"
                    size="l"
                    onClick={handleClearInput}
                  />
              </InputGroup>
            </Box>
            <CustomButton
              classname="calculatedHeight"
              style={{
                backgroundColor: useColorModeValue(COLORS.LIGHT, COLORS.DARK),
                color: '#fff',
              }}
              buttonText="Add Language"
              variant="solid"
              color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
              onClick={addLanguage}
            />
          </Flex>
        </Box>

        <Box my="16px" data-testid="TenantLocalesTableViewTableContainerBox">
          <CustomTable
            columns={columns}
            tableData={filteredData}
            onRowClick={onRowClick}
            title="Locales"
          />
        </Box>
      </Box>
    </>
  );
};

TenantLocales.propTypes = {
  placeholder: PropTypes.string,
  columns: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  addLanguage: PropTypes.func.isRequired,
  searchKey: PropTypes.string,
};

TenantLocales.defaultProps = {
  placeholder: 'Search',
  onRowClick: undefined,
  searchKey: 'label',
};

export default TenantLocales;
