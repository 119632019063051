import React from 'react';
import { EdgeText, getStraightPath } from 'reactflow';
import PropTypes from 'prop-types';

const CustomStraightEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  data,
  markerEnd,
}) => {
  const [edgePath, labelX, labelY] = getStraightPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  return (
    <>
      <path
        id={id}
        style={{ ...style }}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <EdgeText
        x={labelX - 5 / 2}
        y={labelY - 5 / 2}
        onClick={(e) => {
          // e.stopPropagation();
        }}
        label={data.label}
        // labelStyle={{ fill: 'black' }}
        labelShowBg
        // labelBgStyle={{ fill: '#c0c0c0' }}
        labelBgPadding={[8, 8]}
        labelBgBorderRadius={12}
      />
      <h1>&nbsp;</h1>
    </>
  );
};
CustomStraightEdge.propTypes = {
  data: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  sourceX: PropTypes.number.isRequired,
  sourceY: PropTypes.number.isRequired,
  targetX: PropTypes.number.isRequired,
  targetY: PropTypes.number.isRequired,
  sourcePosition: PropTypes.object.isRequired,
  targetPosition: PropTypes.object.isRequired,
  style: PropTypes.object,
  markerEnd: PropTypes.string.isRequired,
};
CustomStraightEdge.defaultProps = {
  style: {},
};
export default CustomStraightEdge;
