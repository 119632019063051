import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';

import { cloneDeep, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import HierarchyTree from '../../components/HierarchyTree';
import CustomButton from '../../components/Button/SimpleButton';

import { COLORS } from '../../utils/enums/colors';
import {
  emptyIntentGroup,
  updatedDataForIntentGroup,
  validateCreateIntentGroup,
} from './utils';
import { processErrorWithMessage } from '../TemplateNewEventAction/helper';
import { createIntent, editIntentGroup, getIntent } from './intent.service';
import {
  setData,
  updateData,
  addData,
  keys,
  getIntentGroups,
} from '../../redux/FieldDefaults';

const CreateEditIntentBuilder = () => {
  const dispatch = useDispatch();

  const intentGroups = useSelector(getIntentGroups);

  const [isLoading, setIsLoading] = useState(false);
  const [errorListWithKey, setErrorListWithKey] = useState({});
  const [intentGroupState, setIntentGroupState] = useState(
    updatedDataForIntentGroup(intentGroups)
  );

  const [expandedNodes, setExpandedNodes] = useState([]);

  const onExpand = useCallback((nodes) => {
    setExpandedNodes(nodes);
  }, []);

  const isuseEffectTriggered = useRef(false);
  const key = useRef(0);

  const handleIntentGroupCreation = useCallback(
    (tempIntentGroup) => {
      const data = tempIntentGroup
        ? {
            id: intentGroupState?.id || '',
            intent_groups: tempIntentGroup,
          }
        : intentGroupState;

      const { error } = validateCreateIntentGroup.validate(data, {
        abortEarly: false,
      });
      if (!isEmpty(error)) {
        setErrorListWithKey(processErrorWithMessage(error.details));
        return;
      }

      setIsLoading(true);
      const temp = cloneDeep(data);
      const id = temp?.id;
      delete temp.id;

      const promise = id
        ? editIntentGroup({ id, payload: temp })
        : createIntent({ payload: temp });

      promise
        .then((res) => {
          if (res) {
            if (id) {
              dispatch(updateData({ key: keys.INTENT_GROUPS, data: res, id }));
            } else {
              dispatch(addData({ key: keys.INTENT_GROUPS, data: res }));
            }

            if (tempIntentGroup) {
              key.current += 1;
              setIntentGroupState(res);
            }
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [intentGroupState]
  );

  useEffect(() => {
    if (isuseEffectTriggered.current) {
      return;
    }

    isuseEffectTriggered.current = true;

    if (isEmpty(intentGroups)) {
      getIntent().then((res) => {
        if (res) {
          const updatedData = updatedDataForIntentGroup(res);
          setIntentGroupState(updatedData);
          dispatch(setData({ key: keys.INTENT_GROUPS, data: updatedData }));
        }
      });
    }
  }, [intentGroups]);
  return (
    <Box mx="12px">
      <Box borderBottom="1px solid #D1D5DB" pb="10px">
        <Text fontSize="20px" fontWeight="600">
          Intents
        </Text>
      </Box>

      <Box
        mt="20px"
        border="1px solid #D1D5DB"
        borderRadius="5px"
        pt="10px"
        pl="10px"
        minH="60vh"
      >
        <div style={{ display: 'flex', gap: '0.4rem' }}>
          <HierarchyTree
            label="Add Main Node"
            onChange={(value) => {
              setIntentGroupState((pre) => ({ ...pre, intent_groups: value }));
              setErrorListWithKey((pre) => ({ ...pre, intent_groups: '' }));
            }}
            value={intentGroupState.intent_groups}
            key={(key.current || intentGroupState.id).toString()}
            showImage
            allowEditImage
            onImageChange={handleIntentGroupCreation}
            onExpand={onExpand}
            defaultExpanded={expandedNodes}
          />
        </div>
        {errorListWithKey?.intent_groups && (
          <span className="field-error">{errorListWithKey?.intent_groups}</span>
        )}
      </Box>
      <Flex p="16px 10px" justify="flex-end">
        <CustomButton
          color="white"
          onClick={() => handleIntentGroupCreation()}
          buttonText={intentGroupState?.id ? 'Update' : 'Save'}
          variant="solid"
          bg={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
          isLoading={isLoading}
        />
      </Flex>
    </Box>
  );
};

export default CreateEditIntentBuilder;
