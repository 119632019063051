import React from 'react';
import { Box } from '@chakra-ui/react';
import HierarchyTree from '../../components/HierarchyTree';

const ExampleCopyHierarchy = () => {
  const test = 'example';
  return (
    <Box data-testid="MyFormContainerBox">
      {/* <AttachmentUpload
        onDelete={() => {}}
        onUpload={(base64, fileName, mimeType) => {
          console.log({ base64, fileName, mimeType });
        }}
        onError={() => {}}
        attachmentState={dummyObj}
      /> */}
      <HierarchyTree
        label="Add Main Node"
        onChange={(value) => {
          console.log(value, 'value');
        }}
      />
    </Box>
  );
};

export default ExampleCopyHierarchy;
