import React from 'react';
import { StarIcon } from '@chakra-ui/icons';
import { TemplateTypes } from '../../utils/enums/types';

/* eslint-disable import/prefer-default-export */
export const dummyAllTemplates = [
  {
    id: '1-asd',
    content: 'Incident Template',
    templateType: TemplateTypes.SINGLE,
    fields: [
      {
        id: 'd7460cb6-3f45-4b18-94ce-39e5d37e6eee',
        slug: 'field_text_child_sample_text',
        type: 'TEXT',
        label: 'Child Text Field ',
        description: 'Child Text Field description 1',
        read_only: false,
        hidden: false,
        required: false,
        hint_text: 'A Sample Text Field 2',
        placeholder: 'Enter Text Here For Text Field',
        searchable: true,
        default_value: null,
        regex_pattern: null,
        unique: false,
        min_length: 10,
        max_length: 100,
        min_value: null,
        max_value: null,
        reportable: true,
        number_format: null,
        date_format: null,
        media_type: null,
        media_size: null,
        permission_id: 'ed9a9c87-3a8b-48ca-ae41-28db723eeb05',
        tenant_id: 'ab49bfe2-655c-459e-be0e-ffc55c23342b',
        is_derived: false,
        derived_expression: '{}',
        collection_id: null,
        is_default: false,
        created_by: null,
        updated_by: null,
        created_on: '2023-03-15T22:51:12.951Z',
        updated_on: '2023-03-15T22:51:12.951Z',
      },
      {
        id: 'c8a3977d-04a6-4058-9f06-566509fb4404',
        slug: 'field_text_child_sample_text_2',
        type: 'TEXT',
        label: 'Child Text Field 2 ',
        description: 'Child Text Field description 2',
        read_only: false,
        hidden: false,
        required: false,
        hint_text: 'A Sample Text Field 3',
        placeholder: 'Enter Text Here For Text Field',
        searchable: true,
        default_value: null,
        regex_pattern: null,
        unique: false,
        min_length: 10,
        max_length: 100,
        min_value: null,
        max_value: null,
        reportable: true,
        number_format: null,
        date_format: null,
        media_type: null,
        media_size: null,
        permission_id: 'e2903529-ba5b-4bc6-aed5-cc701e7efa59',
        tenant_id: 'ab49bfe2-655c-459e-be0e-ffc55c23342b',
        is_derived: false,
        derived_expression: '{}',
        collection_id: null,
        is_default: false,
        created_by: null,
        updated_by: null,
        created_on: '2023-03-15T22:53:32.580Z',
        updated_on: '2023-03-15T22:53:32.580Z',
      },
    ],
    subItems: [
      {
        id: '10-asd',
        content: 'Sub Ticket',
        templateType: TemplateTypes.NESTED,
        selectedTemplates: [
          {
            label: 'Incident Template',
            value: { id: '1-asd', content: 'Incident Template' },
          },
        ],
      },
      {
        id: '11-asd',
        content: 'Sub Ticket2',
        templateType: TemplateTypes.NESTED,
        selectedTemplates: [
          {
            label: 'Incident Template',
            value: { id: '1-asd', content: 'Incident Template' },
          },
        ],
      },
    ],
  },
  {
    id: '2-asd',
    content: 'Onboarding Template',
    templateType: TemplateTypes.SINGLE,
    subItems: [
      {
        id: '12-asd',
        content: 'Sub Ticket3',
        templateType: TemplateTypes.NESTED,
        selectedTemplates: [
          {
            label: 'Onboarding Template',
            value: { id: '2-asd', content: 'Onboarding Template' },
          },
        ],
      },
    ],
  },
];

export const dummy = [
  {
    id: 'c283d95d-d8c2-43ab-9678-9d3517f69de7',
    version: 'v1',
    name: 'Incident Template',
    permission_id: '6b66257d-a3d4-4fd2-bc06-f5d6e26cfa3f',
    workflow_id: 'f4a7aaec-c5fb-4e38-b4de-a2832d8396af',
    status: 'DRAFT',
    parent_id: null,
    previous_version_id: null,
    cloned_from: null,
    is_default: false,
    tenant_id: 'dd1d4fb5-856c-43f3-bd2c-8cbba1673ab1',
    group_id: null,
    collabrators: null,
    is_marked_favourite: false,
    created_by: 'ef3f5c3a-5769-4c34-a40e-f06b5fef15c1',
    updated_by: 'ef3f5c3a-5769-4c34-a40e-f06b5fef15c1',
    created_on: '2023-03-13T18:30:00.000Z',
    updated_on: '2023-03-13T18:30:00.000Z',
  },
  {
    id: '61199b06-c48c-4676-b33a-35e7b1dd7d43',
    version: 'v1',
    name: 'Onboarding Template',
    permission_id: '6b66257d-a3d4-4fd2-bc06-f5d6e26cfa3f',
    workflow_id: 'f4a7aaec-c5fb-4e38-b4de-a2832d8396af',
    status: 'DRAFT',
    parent_id: null,
    previous_version_id: null,
    cloned_from: null,
    is_default: false,
    tenant_id: 'dd1d4fb5-856c-43f3-bd2c-8cbba1673ab1',
    group_id: null,
    collabrators: null,
    is_marked_favourite: false,
    created_by: 'ef3f5c3a-5769-4c34-a40e-f06b5fef15c1',
    updated_by: 'ef3f5c3a-5769-4c34-a40e-f06b5fef15c1',
    created_on: '2023-03-13T13:00:00.000Z',
    updated_on: '2023-03-13T13:00:00.000Z',
  },
  {
    id: 'b4715a8b-ba42-407c-876a-28ef693f897e',
    version: 'v1',
    name: 'Request Template',
    permission_id: '6b66257d-a3d4-4fd2-bc06-f5d6e26cfa3f',
    workflow_id: 'f4a7aaec-c5fb-4e38-b4de-a2832d8396af',
    status: 'DRAFT',
    parent_id: '61199b06-c48c-4676-b33a-35e7b1dd7d43',
    previous_version_id: null,
    cloned_from: null,
    is_default: false,
    tenant_id: 'dd1d4fb5-856c-43f3-bd2c-8cbba1673ab1',
    group_id: null,
    collabrators: null,
    is_marked_favourite: false,
    created_by: 'ef3f5c3a-5769-4c34-a40e-f06b5fef15c1',
    updated_by: 'ef3f5c3a-5769-4c34-a40e-f06b5fef15c1',
    created_on: '2023-03-13T13:00:00.000Z',
    updated_on: '2023-03-13T13:00:00.000Z',
  },
  {
    id: 'b4715a8b-ba42-407c-876a-',
    version: 'v1',
    name: 'Onboarding Template',
    permission_id: '6b66257d-a3d4-4fd2-bc06-f5d6e26cfa3f',
    workflow_id: 'f4a7aaec-c5fb-4e38-b4de-a2832d8396af',
    status: 'PUBLISHED',
    parent_id: 'c283d95d-d8c2-43ab-9678-9d3517f69de7',
    previous_version_id: null,
    cloned_from: null,
    is_default: false,
    tenant_id: 'dd1d4fb5-856c-43f3-bd2c-8cbba1673ab1',
    group_id: null,
    collabrators: null,
    is_marked_favourite: false,
    created_by: 'ef3f5c3a-5769-4c34-a40e-f06b5fef15c1',
    updated_by: 'ef3f5c3a-5769-4c34-a40e-f06b5fef15c1',
    created_on: '2023-03-13T13:00:00.000Z',
    updated_on: '2023-03-13T13:00:00.000Z',
  },
];

export const dummy2 = [
  {
    id: 'c283d95d-d8c2-43ab-9678-9d3517f69de7',
    version: 'v1',
    name: ' Template',
    permission_id: '6b66257d-a3d4-4fd2-bc06-f5d6e26cfa3f',
    workflow_id: 'f4a7aaec-c5fb-4e38-b4de-a2832d8396af',
    status: 'DRAFT',
    parent_id: null,
    previous_version_id: null,
    cloned_from: null,
    is_default: false,
    tenant_id: 'dd1d4fb5-856c-43f3-bd2c-8cbba1673ab1',
    group_id: null,
    collabrators: null,
    is_marked_favourite: false,
    created_by: 'ef3f5c3a-5769-4c34-a40e-f06b5fef15c1',
    updated_by: 'ef3f5c3a-5769-4c34-a40e-f06b5fef15c1',
    created_on: '2023-03-13T18:30:00.000Z',
    updated_on: '2023-03-13T18:30:00.000Z',
  },
  {
    id: '61199b06-c48c-4676-b33a-35e7b1dd7d43',
    version: 'v1',
    name: 'Onboarding Template',
    permission_id: '6b66257d-a3d4-4fd2-bc06-f5d6e26cfa3f',
    workflow_id: 'f4a7aaec-c5fb-4e38-b4de-a2832d8396af',
    status: 'DRAFT',
    parent_id: null,
    previous_version_id: null,
    cloned_from: null,
    is_default: false,
    tenant_id: 'dd1d4fb5-856c-43f3-bd2c-8cbba1673ab1',
    group_id: null,
    collabrators: null,
    is_marked_favourite: false,
    created_by: 'ef3f5c3a-5769-4c34-a40e-f06b5fef15c1',
    updated_by: 'ef3f5c3a-5769-4c34-a40e-f06b5fef15c1',
    created_on: '2023-03-13T13:00:00.000Z',
    updated_on: '2023-03-13T13:00:00.000Z',
  },
  {
    id: 'b4715a8b-ba42-407c-876a-28ef693f897e',
    version: 'v1',
    name: 'Request Template',
    permission_id: '6b66257d-a3d4-4fd2-bc06-f5d6e26cfa3f',
    workflow_id: 'f4a7aaec-c5fb-4e38-b4de-a2832d8396af',
    status: 'DRAFT',
    parent_id: '61199b06-c48c-4676-b33a-35e7b1dd7d43',
    previous_version_id: null,
    cloned_from: null,
    is_default: false,
    tenant_id: 'dd1d4fb5-856c-43f3-bd2c-8cbba1673ab1',
    group_id: null,
    collabrators: null,
    is_marked_favourite: false,
    created_by: 'ef3f5c3a-5769-4c34-a40e-f06b5fef15c1',
    updated_by: 'ef3f5c3a-5769-4c34-a40e-f06b5fef15c1',
    created_on: '2023-03-13T13:00:00.000Z',
    updated_on: '2023-03-13T13:00:00.000Z',
  },
];

export const cardData = [
  {
    name: 'star_icon',
    value: <StarIcon />,
  },
  {
    name: 'status',
    value: 'draft',
  },
  {
    name: 'ticket_type',
    value: 'Hardware request',
  },
  {
    name: 'created_by',
    value: 'samsmith@gmail.com',
  },
  {
    name: 'created_on',
    value: 'Feb 28 2023, 15:09 pm',
  },
  {
    name: 'last_edited',
    value: 'Feb 28 2023, 15:09 pm',
  },
  {
    name: 'edited_by',
    value: 'samsmith@gmail.com',
  },
  {
    name: 'description',
    value: 'This description is related to Hardware',
  },
];

export const dummyLayoutData = [
  {
    id: '123e4567-e89b-12d3-a456-426614174201',
    template_id: null,
    component_id: '123e4567-e89b-12d3-a456-426614174023',
    name: '',
    type: 'CUSTOM',
    schema: '',
    is_active: true,
    created_by: '123e4567-e89b-12d3-a456-426614174203',
    updated_by: '123e4567-e89b-12d3-a456-426614174204',
    created_on: '1922-02-01T18:30:00.000Z',
    updated_on: '2023-03-20T04:31:18.094Z',
  },
  {
    id: '123e4567-e89b-12d3-a456-426614174202',
    template_id: '123e4567-e89b-12d3-a456-426604174000',
    component_id: null,
    name: '',
    type: 'CUSTOM',
    schema: '',
    is_active: true,
    created_by: '123e4567-e89b-12d3-a456-426614174203',
    updated_by: '123e4567-e89b-12d3-a456-426614174204',
    created_on: '1922-02-01T18:30:00.000Z',
    updated_on: '2023-03-20T04:31:18.094Z',
  },
  {
    id: '123e4567-e89b-12d3-a456-426614174200',
    template_id: '123e4567-e89b-12d3-a456-426604174000',
    component_id: '123e4567-e89b-12d3-a456-426614174023',
    name: '',
    type: 'CUSTOM',
    schema: '',
    is_active: true,
    created_by: '123e4567-e89b-12d3-a456-426614174203',
    updated_by: '123e4567-e89b-12d3-a456-426614174204',
    created_on: '1922-02-01T18:30:00.000Z',
    updated_on: '2023-03-20T04:31:18.094Z',
  },
  {
    id: '0807b3df-fabc-4bbf-940b-89fbb257b9c5',
    template_id: '123e4567-e89b-12d3-a456-426604174921',
    component_id: null,
    name: null,
    type: 'CUSTOM',
    schema: null,
    is_active: false,
    created_by: null,
    updated_by: null,
    created_on: '2023-03-20T05:55:11.917Z',
    updated_on: '2023-03-20T05:55:11.917Z',
  },
];
export const dummyTable = [
  {
    id: 1,
    name: 'Ticket Name',
    type: 'text',
    action: 'edit.io',
  },
  {
    id: 2,
    name: 'Ticket ID',
    type: 'Number',
    action: 'delete.ico',
  },
  {
    id: 3,
    name: 'Ticket Name',
    type: 'Dropdown',
  },
];

// export const viewJson = {
//   name: 'ticket_template',
//   label: 'Ticket Template',
//   table_name: 'ticket_template',
//   permissions: [
//     {
//       name: 'edit',
//       type: 'edit',
//       roles: ['ticket_analyst', 'ticket_admin'],
//       conditions: [
//         {
//           AND: [
//             {
//               OR: [
//                 {
//                   condition: 'user_id = $user.id',
//                 },
//                 {
//                   condition: 'collaborator_id = $user.id',
//                 },
//               ],
//             },
//             {
//               condition: 'status != published',
//             },
//           ],
//         },
//       ],
//     },
//     {
//       name: 'view',
//       type: 'view',
//       roles: ['ticket_analyst', 'ticket_admin'],
//     },
//     {
//       name: 'create',
//       type: 'create',
//       roles: ['ticket_analyst', 'ticket_admin'],
//     },
//     {
//       name: 'approve',
//       type: 'edit',
//       roles: ['ticket_admin'],
//     },
//     {
//       name: 'reject',
//       type: 'edit',
//       roles: ['ticket_admin'],
//     },
//   ],
//   columns: [
//     {
//       name: 'name',
//       label: 'Name',
//       column_name: 'name',
//       data_type: 'string',
//     },
//     {
//       name: 'status',
//       label: 'Status',
//       column_name: 'status',
//       data_type: 'string',
//     },
//     {
//       name: 'parent',
//       label: 'Parent',
//       column: 'name',
//       related_entity: 'parent_ticket_template',
//     },
//     {
//       name: 'updated_on',
//       label: 'Update On',
//       column_name: 'updated_on',
//       data_type: 'timestamp',
//     },
//   ],
//   related_entities: [
//     {
//       name: 'parent_ticket_template',
//       join_column: 'parent_id',
//       related_table: 'ticket_template',
//       related_join_column: 'id',
//     },
//     {
//       name: 'ticket_template_fields',
//       join_column: 'id',
//       related_table: 'ticket_template_fields',
//       related_join_column: 'ticket_template_id',
//     },
//   ],
//   views: [
//     // {
//     //   name: 'ticket_template_list_view',
//     //   label: 'Ticket Template List',
//     //   show_label: false,
//     //   type: 'list_exp',
//     //   entity: 'ticket_template',
//     //   is_default: true,
//     //   columns: ['name', 'status', 'parent', 'update_on'],
//     //   filter: [
//     //     {
//     //       AND: [
//     //         {
//     //           condition: 'is_active = true',
//     //         },
//     //         {
//     //           condition: 'created_on > $date.now - 30d',
//     //         },
//     //       ],
//     //     },
//     //   ],
//     //   actions: [
//     //     {
//     //       name: 'view_edit_details',
//     //       label: 'View/Edit',
//     //       show_label: false,
//     //       icon: 'edit.ico',
//     //       is_button: true,
//     //       action_type: 'get',
//     //       permission: 'view',
//     //       view: 'ticket_template_details_view',
//     //     },
//     //     {
//     //       name: 'delete_details',
//     //       label: 'Delete',
//     //       show_label: false,
//     //       icon: 'delete.ico',
//     //       is_button: true,
//     //       action_type: 'get',
//     //       permission: 'edit',
//     //       view: 'ticket_template_delete_view',
//     //       navigation: 'popup',
//     //     },
//     //     {
//     //       name: 'submit_details',
//     //       label: 'Submit',
//     //       show_label: true,
//     //       is_button: false,
//     //       action_type: 'submit',
//     //       permission: 'edit',
//     //       view: 'ticket_template_list_view',
//     //       toast_key: 'response.message',
//     //     },
//     //     {
//     //       name: 'approve_details',
//     //       label: 'Approve',
//     //       show_label: true,
//     //       is_button: false,
//     //       action_type: 'approve',
//     //       permission: 'approve',
//     //       view: 'ticket_template_list_view',
//     //       toast_key: 'response.message',
//     //     },
//     //     {
//     //       name: 'clone_details',
//     //       label: 'Clone',
//     //       show_label: true,
//     //       is_button: false,
//     //       action_type: 'get_clone',
//     //       permission: 'view',
//     //       view: 'ticket_template_details_view',
//     //       toast_key: 'response.error_message',
//     //     },
//     //   ],
//     //   bulk_actions: [
//     //     {
//     //       name: 'submit_details_bulk',
//     //       label: 'Submit',
//     //       show_label: true,
//     //       is_button: false,
//     //       action_type: 'submit_bulk',
//     //       permission: 'edit',
//     //       view: 'ticket_template_list_view',
//     //       toast_key: 'response.message',
//     //     },
//     //     {
//     //       name: 'compare_details_bulk',
//     //       label: 'Compare',
//     //       show_label: true,
//     //       icon: 'diff.ico',
//     //       is_button: false,
//     //       min_items: 2,
//     //       max_items: 2,
//     //       action_type: 'compare',
//     //       permission: 'view',
//     //       view: 'ticket_template_compare_view',
//     //     },
//     //   ],
//     // },
//     {
//       name: 'ticket_template_list_short_view',
//       label: 'Ticket Template List Short',
//       show_label: false,
//       type: 'list_short',
//       entity: 'ticket_template',
//       columns: ['name'],
//       nested: {
//         column: 'parent_id',
//         column_type: 'parent',
//       },
//       filter: [
//         {
//           AND: [
//             {
//               condition: 'is_active = true',
//             },
//             {
//               OR: [
//                 {
//                   condition: 'author_id = $user.id',
//                 },
//                 {
//                   condition: 'collaborator_id = $user.id',
//                 },
//               ],
//             },
//             {
//               condition: 'created_on > $date.now - 30d',
//             },
//           ],
//         },
//       ],
//       actions: [
//         {
//           name: 'move_parent_child',
//           label: 'Move',
//           show_label: false,
//           icon: 'move.ico',
//           is_button: false,
//           action_type: 'template_update_parent_child',
//           permission: 'edit',
//           view: 'ticket_template_list_short_view',
//           type: 'button',
//         },
//         {
//           name: 'view_edit_details',
//           label: 'View/Edit',
//           show_label: false,
//           icon: 'edit.ico',
//           is_button: false,
//           action_type: 'get',
//           permission: 'view',
//           view: 'ticket_template_details_view',
//           target_view: 'ticket_template_list_view',
//           type: 'button',
//         },
//         {
//           name: 'create_ticket_template',
//           label: 'Create Ticket Template',
//           show_label: true,
//           is_button: true,
//           icon: 'add.ico',
//           action_type: null,
//           permission: 'edit',
//           view: 'create_ticket_template_view',
//           navigation: 'popup',
//           type: 'button',
//           isViewable: true,
//         },
//       ],
//       view_layout: {
//         width: 300,
//         no_of_cols: 7,
//         rows: [
//           {
//             i: 'delete_message',
//             w: 5,
//             h: 1,
//           },
//           {
//             i: 'create_ticket_template',
//             w: 2,
//             h: 1,
//           },
//           {
//             i: 'cancel_delete_ticket_template',
//             w: 2,
//             h: 1,
//           },
//         ],
//       },
//     },
//     // {
//     //   name: 'ticket_template_delete_view',
//     //   label: 'Ticket Template Delete',
//     //   show_label: true,
//     //   type: 'custom',
//     //   entity: 'ticket_template',
//     //   elements: [
//     //     {
//     //       name: 'delete_message',
//     //       type: 'text',
//     //       value: 'Do you want to delete the ticket template $name ?',
//     //     },
//     //   ],
//     //   actions: [
//     //     {
//     //       name: 'delete_ticket_template',
//     //       label: 'Delete',
//     //       show_label: true,
//     //       is_button: true,
//     //       action_type: 'delete',
//     //       permission: 'edit',
//     //       view: 'ticket_template_list_view',
//     //       toast_key: 'response.message',
//     //     },
//     //     {
//     //       name: 'cancel_delete_ticket_template',
//     //       label: 'Cancel',
//     //       show_label: true,
//     //       is_button: true,
//     //       action_type: 'ui_close_popup',
//     //       view: 'ticket_template_list_view',
//     //     },
//     //   ],
//     //   view_layout: {
//     //     width: 300,
//     //     no_of_cols: 7,
//     //     rows: [
//     //       {
//     //         i: 'delete_message',
//     //         x: 1,
//     //         y: 0,
//     //         w: 5,
//     //         h: 1,
//     //       },
//     //       {
//     //         i: 'delete_ticket_template',
//     //         x: 1,
//     //         y: 1,
//     //         w: 2,
//     //         h: 1,
//     //       },
//     //       {
//     //         i: 'cancel_delete_ticket_template',
//     //         x: 4,
//     //         y: 1,
//     //         w: 2,
//     //         h: 1,
//     //       },
//     //     ],
//     //   },
//     // },
//     // {
//     //   name: 'ticket_template_filter_view',
//     //   label: 'Filters',
//     //   show_label: true,
//     //   type: 'custom',
//     //   entity: 'ticket_template',
//     //   elements: [
//     //     {
//     //       name: 'search_ticket_template',
//     //       type: 'input',
//     //       placeholder: 'Search By Name',
//     //     },
//     //   ],
//     //   views: [
//     //     {
//     //       name: 'status_list_view',
//     //       label: 'Status',
//     //       show_label: true,
//     //       type: 'list_dropdown',
//     //       entity: 'ticket_template',
//     //       columns: ['status'],
//     //     },
//     //   ],
//     //   actions: [
//     //     {
//     //       name: 'filter_ticket_templates',
//     //       label: 'Filter',
//     //       show_label: true,
//     //       icon: 'filter.ico',
//     //       is_button: true,
//     //       action_type: 'list',
//     //       permission: 'view',
//     //       view: 'ticket_template_list_view',
//     //       target_view: 'ticket_template_list_view',
//     //     },
//     //   ],
//     //   view_layout: {
//     //     width: '100%',
//     //     no_of_cols: 9,
//     //     rows: [
//     //       {
//     //         i: 'search_ticket_template',
//     //         x: 1,
//     //         y: 0,
//     //         w: 2,
//     //         h: 1,
//     //       },
//     //       {
//     //         i: 'status_list_view',
//     //         x: 4,
//     //         y: 0,
//     //         w: 3,
//     //         h: 1,
//     //       },
//     //       {
//     //         i: 'filter_ticket_templates',
//     //         x: 7,
//     //         y: 0,
//     //         w: 2,
//     //         h: 1,
//     //       },
//     //     ],
//     //   },
//     // },
//     // {
//     //   name: 'ticket_template_details_view',
//     //   label: 'Ticket Template Details',
//     //   show_label: false,
//     //   type: 'custom',
//     //   entity: 'ticket_template',
//     //   elements: [
//     //     {
//     //       name: 'title',
//     //       type: 'text',
//     //       value: '$name',
//     //     },
//     //   ],
//     //   views: [
//     //     {
//     //       name: 'fields',
//     //       label: 'Fields',
//     //       type: 'custom',
//     //       related_entity: 'ticket_template_fields',
//     //       views: [
//     //         {
//     //           name: 'search_field',
//     //           type: 'lookup',
//     //           placeholder: 'Search Field to Add',
//     //           entity: 'field',
//     //           column: 'name',
//     //           actions: [
//     //             {
//     //               type: 'implicit',
//     //               action_type: 'add',
//     //               view: 'configure_field_view',
//     //               permission: 'view',
//     //               navigation: 'popup',
//     //             },
//     //           ],
//     //         },
//     //         {
//     //           name: 'ticket_template_fields_list_view',
//     //           label: 'Ticket Template Fields',
//     //           show_label: false,
//     //           type: 'list_exp',
//     //           related_entity: 'ticket_template_fields',
//     //           columns: ['name', 'type'],
//     //           filter: [
//     //             {
//     //               condition: 'is_active = true',
//     //             },
//     //           ],
//     //           actions: [
//     //             {
//     //               name: 'edit_ticket_template_field',
//     //               label: 'Edit',
//     //               show_label: false,
//     //               icon: 'edit.ico',
//     //               action_type: 'get',
//     //               permission: 'edit',
//     //               view: 'configure_field_view',
//     //               navigation: 'popup',
//     //             },
//     //             {
//     //               name: 'delete_details',
//     //               label: 'Delete',
//     //               show_label: false,
//     //               icon: 'delete.ico',
//     //               is_button: true,
//     //               action_type: 'get',
//     //               permission: 'edit',
//     //               view: 'ticket_template_field_delete_view',
//     //               navigation: 'popup',
//     //             },
//     //           ],
//     //         },
//     //       ],
//     //       actions: [
//     //         {
//     //           name: 'quick_add_field',
//     //           label: 'Quick Add Field',
//     //           show_label: true,
//     //           is_button: true,
//     //           action_type: null,
//     //           permission: 'edit',
//     //           view: 'configure_field_view',
//     //           navigation: 'popup',
//     //         },
//     //         {
//     //           name: 'configure_field_layout',
//     //           label: 'Configure Layout',
//     //           show_label: true,
//     //           is_button: true,
//     //           icon: 'ham.ico',
//     //           action_type: 'get_field_layout',
//     //           permission: 'edit',
//     //           view: 'configure_field_layout_view',
//     //           navigation: 'popup',
//     //         },
//     //       ],
//     //       view_layout: {
//     //         width: '100%',
//     //         no_of_cols: 9,
//     //         rows: [
//     //           {
//     //             i: 'search_field',
//     //             x: 0,
//     //             y: 0,
//     //             w: 2,
//     //             h: 1,
//     //           },
//     //           {
//     //             i: 'quick_add_field',
//     //             x: 4,
//     //             y: 0,
//     //             w: 2,
//     //             h: 1,
//     //           },
//     //           {
//     //             i: 'configure_field_view',
//     //             x: 7,
//     //             y: 0,
//     //             w: 2,
//     //             h: 1,
//     //           },
//     //           {
//     //             i: 'ticket_template_fields_list_view',
//     //             x: 1,
//     //             y: 1,
//     //             w: 7,
//     //             maxH: 'Infinity',
//     //           },
//     //         ],
//     //       },
//     //     },
//     //     {
//     //       name: 'configure_field_layout_view',
//     //       label: 'Configure Field Layout',
//     //       type: 'custom',
//     //       related_entity: 'ticket_template_layout',
//     //       route: '/ticket_template_layout',
//     //     },
//     //     {
//     //       name: 'configure_field_view',
//     //     },
//     //     {
//     //       name: 'ticket_template_field_delete_view',
//     //       label: 'Ticket Template Field Delete',
//     //       show_label: true,
//     //       type: 'custom',
//     //       entity: 'ticket_template_fields',
//     //       elements: [
//     //         {
//     //           name: 'delete_message',
//     //           type: 'text',
//     //           value: 'Do you want to delete the ticket template field $name ?',
//     //         },
//     //       ],
//     //       actions: [
//     //         {
//     //           name: 'delete_ticket_template_field',
//     //           label: 'Delete',
//     //           show_label: true,
//     //           is_button: true,
//     //           action_type: 'delete',
//     //           permission: 'edit',
//     //           view: 'ticket_template_fields_list_view',
//     //           toast_key: 'response.message',
//     //         },
//     //         {
//     //           name: 'cancel_delete_ticket_template_field',
//     //           label: 'Cancel',
//     //           show_label: true,
//     //           is_button: true,
//     //           action_type: 'ui_close_popup',
//     //           view: 'ticket_template_fields_list_view',
//     //         },
//     //       ],
//     //       view_layout: {
//     //         width: 300,
//     //         no_of_cols: 7,
//     //         rows: [
//     //           {
//     //             i: 'delete_message',
//     //             x: 1,
//     //             y: 0,
//     //             w: 5,
//     //             h: 1,
//     //           },
//     //           {
//     //             i: 'delete_ticket_template',
//     //             x: 1,
//     //             y: 1,
//     //             w: 2,
//     //             h: 1,
//     //           },
//     //           {
//     //             i: 'cancel_delete_ticket_template',
//     //             x: 4,
//     //             y: 1,
//     //             w: 2,
//     //             h: 1,
//     //           },
//     //         ],
//     //       },
//     //     },
//     //   ],
//     //   actions: [
//     //     {
//     //       name: 'view_json',
//     //       label: 'Json',
//     //       show_label: true,
//     //       is_button: true,
//     //       action_type: 'get_json',
//     //       permission: 'view',
//     //       view: 'ticket_template_json_view',
//     //       navigation: 'popup',
//     //     },
//     //     {
//     //       name: 'save_details',
//     //       label: 'Save',
//     //       show_label: true,
//     //       icon: 'save.ico',
//     //       is_button: true,
//     //       action_type: 'save',
//     //       permission: 'edit',
//     //       view: 'ticket_template_details_view',
//     //       toast_key: 'response.message',
//     //     },
//     //   ],
//     //   view_layout: {
//     //     width: '100%',
//     //     no_of_cols: 12,
//     //     rows: [
//     //       {
//     //         i: 'title',
//     //         x: 1,
//     //         y: 0,
//     //         w: 2,
//     //         h: 1,
//     //       },
//     //       {
//     //         i: 'view_json',
//     //         x: 6,
//     //         y: 0,
//     //         w: 2,
//     //         h: 1,
//     //       },
//     //       {
//     //         i: 'save_details',
//     //         x: 9,
//     //         y: 0,
//     //         w: 2,
//     //         h: 1,
//     //       },
//     //       {
//     //         i: 'fields',
//     //         x: 1,
//     //         y: 1,
//     //         w: 10,
//     //         maxH: 'Infinity',
//     //       },
//     //     ],
//     //   },
//     // },
//     // {
//     //   name: 'create_ticket_template_view',
//     //   label: 'Create Ticket Template',
//     //   show_label: true,
//     //   type: 'custom',
//     //   entity: 'ticket_template',
//     //   elements: [
//     //     {
//     //       name: 'name',
//     //       label: 'Template Name',
//     //       type: 'input',
//     //     },
//     //     {
//     //       name: 'description',
//     //       label: 'Description',
//     //       type: 'textarea',
//     //     },
//     //   ],
//     //   actions: [
//     //     {
//     //       name: 'continue_create_ticket_template',
//     //       label: 'Continue',
//     //       show_label: true,
//     //       is_button: true,
//     //       action_type: 'save',
//     //       permission: 'edit',
//     //       view: 'ticket_template_details_view',
//     //       target_view: 'ticket_template_list_view',
//     //     },
//     //     {
//     //       name: 'cancel_create_ticket_template',
//     //       label: 'Cancel',
//     //       show_label: true,
//     //       is_button: true,
//     //       action_type: 'ui_close_popup',
//     //       view: 'ticket_template_list_view',
//     //     },
//     //   ],
//     //   view_layout: {
//     //     width: '40%',
//     //     no_of_cols: 7,
//     //     rows: [
//     //       {
//     //         i: 'name',
//     //         x: 1,
//     //         y: 0,
//     //         w: 5,
//     //         h: 1,
//     //       },
//     //       {
//     //         i: 'description',
//     //         x: 1,
//     //         y: 1,
//     //         w: 5,
//     //         h: 1,
//     //       },
//     //       {
//     //         i: 'continue_create_ticket_template',
//     //         x: 1,
//     //         y: 2,
//     //         w: 2,
//     //         h: 1,
//     //       },
//     //       {
//     //         i: 'cancel_create_ticket_template',
//     //         x: 4,
//     //         y: 2,
//     //         w: 2,
//     //         h: 1,
//     //       },
//     //     ],
//     //   },
//     // },
//   ],
//   actions: [
//     {
//       name: 'create_ticket_template',
//       label: 'Create Ticket Template',
//       show_label: true,
//       is_button: true,
//       icon: 'add.ico',
//       action_type: null,
//       permission: 'edit',
//       view: 'create_ticket_template_view',
//       navigation: 'popup',
//       type: 'button',
//     },
//   ],
//   view_layout: {
//     width: '100%',
//     no_of_cols: 12,
//     rows: [
//       {
//         i: 'create_ticket_template',
//         x: 0,
//         y: 0,
//         w: 12,
//         h: 1,
//       },
//       {
//         i: 'ticket_template_list_short_view',
//         x: 0,
//         y: 1,
//         w: 12,
//         maxH: 'Infinity',
//       },
//       // {
//       //   i: 'delete_ticket_template',
//       //   x: 4,
//       //   y: 0,
//       //   w: 8,
//       //   maxH: 'Infinity',
//       // },
//     ],
//   },
// };

export const viewJson2 = {
  name: 'ticket_template',
  label: 'Ticket Template',
  table_name: 'ticket_template',
  route: '/template-maker',
  // outlet_routes: [
  //   '/template-maker/:component/:id',
  // ],
  permissions: [
    {
      name: 'edit',
      type: 'edit',
      roles: ['ticket_analyst', 'ticket_admin'],
      conditions: [
        {
          AND: [
            {
              OR: [
                {
                  condition: 'user_id = $user.id',
                },
                {
                  condition: 'collaborator_id = $user.id',
                },
              ],
            },
            {
              condition: 'status != published',
            },
          ],
        },
      ],
    },
    {
      name: 'view',
      type: 'view',
      roles: ['ticket_analyst', 'ticket_admin'],
    },
    {
      name: 'create',
      type: 'create',
      roles: ['ticket_analyst', 'ticket_admin'],
    },
    {
      name: 'approve',
      type: 'edit',
      roles: ['ticket_admin'],
    },
    {
      name: 'reject',
      type: 'edit',
      roles: ['ticket_admin'],
    },
  ],
  columns: [
    {
      name: 'name',
      label: 'Name',
      column_name: 'name',
      data_type: 'string',
    },
    {
      name: 'status',
      label: 'Status',
      column_name: 'status',
      data_type: 'string',
    },
    {
      name: 'parent',
      label: 'Parent',
      column: 'name',
      related_entity: 'parent_ticket_template',
    },
    {
      name: 'updated_on',
      label: 'Update On',
      column_name: 'updated_on',
      data_type: 'timestamp',
    },
  ],
  related_entities: [
    {
      name: 'parent_ticket_template',
      join_column: 'parent_id',
      related_table: 'ticket_template',
      related_join_column: 'id',
    },
    {
      name: 'ticket_template_fields',
      join_column: 'id',
      related_table: 'ticket_template_fields',
      related_join_column: 'ticket_template_id',
    },
  ],
  views: [
    {
      name: 'ticket_template_sidebar',
      label: 'Ticket Template Sidebar',
      show_label: false,
      type: 'container',
      views: [
        {
          name: 'ticket_template_list_accordion',
          label: 'All Templates',
          show_label: false,
          type: 'accordion',
          entity: 'Templates',
          filterKey: 'name',
          listKey: 'ticket_template_list_short_view',
          views: [
            {
              name: 'ticket_template_list_short_view',
              label: 'Ticket Template List Short',
              show_label: false,
              type: 'nested_list',
              entity: 'ticket_template',
              columns: ['name'],
              apiReq: {
                reqCols: [
                  'id',
                  'name',
                  'slug',
                  'version',
                  'properties',
                  'metric',
                  'tenant_id',
                ],
                orderBy: 'created_on asc',
                endPoint: 'templates/list',
              },
              nested: {
                column: 'parent_id',
                column_type: 'parent',
              },
              filter: [
                {
                  AND: [
                    {
                      condition: 'is_active = true',
                    },
                    {
                      OR: [
                        {
                          condition: 'author_id = $user.id',
                        },
                        {
                          condition: 'collaborator_id = $user.id',
                        },
                      ],
                    },
                  ],
                },
              ],
              actions: [
                {
                  name: 'view_edit_details',
                  label: 'View/Edit',
                  show_label: false,
                  icon: 'edit.ico',
                  is_button: false,
                  action_type: 'get',
                  permission: 'view',
                  target_params: ['id', 'name'],
                  type: 'record',
                  target: '/template-maker/template/',
                  target_type: 'navigate',
                },
              ],
            },
            {
              name: 'create_modal',
              label: 'create new template',
              type: 'modal_multi_tab',
              show_label: true,
              mandatoryFields: ['template_name', 'template_type', 'viewers'],
              tabIds: ['basic_tab_container', 'role_tab_container'],
              modal_id: 'create_template_modal',
              views: [
                {
                  name: 'create_template_tab_view',
                  label: 'tab Settings',
                  type: 'tab_view',
                  show_label: true,
                  filter: [],
                  views: [
                    {
                      name: 'basic_tab_container',
                      label: 'Basic Settings',
                      type: 'container',
                      show_label: true,
                      style: { paddingTop: '8px' },
                      elements: [
                        {
                          name: '_template_name',
                          type: 'input',
                          value: 'template_name',
                          inputType: 'string',
                          label: 'Name',
                          isMandatory: true,
                        },
                        {
                          name: '_prefix',
                          type: 'input',
                          value: 'prefix',
                          inputType: 'string',
                          label: 'Prefix',
                          isMandatory: false,
                        },
                        {
                          name: '_running_number',
                          type: 'input',
                          value: 'running_number',
                          inputType: 'number',
                          label: 'Ticket Digits',
                          isMandatory: false,
                        },
                        {
                          name: '_suffix',
                          type: 'input',
                          value: 'suffix',
                          inputType: 'string',
                          label: 'Suffix',
                          isMandatory: false,
                        },
                        {
                          name: '_start_at',
                          type: 'input',
                          value: 'start_at',
                          inputType: 'number',
                          label: 'Start at',
                          isMandatory: false,
                        },
                        {
                          name: '_template_type',
                          type: 'select',
                          value: 'template_type',
                          label: 'Template Type',
                          isMandatory: true,
                          options: [
                            { label: 'Single', value: 'single' },
                            { label: 'Nested', value: 'nested' },
                          ],
                        },
                        {
                          name: '_select_child_template_type',
                          type: 'multi_select',
                          value: 'child_templates_list',
                          label: 'Child Template(s)',
                          visibility: false,
                          condition: {
                            stateName: 'template_type',
                            stateValue: 'nested',
                          },
                          options: [
                            {
                              label: 'Incident Template',
                              value: 'incident template',
                            },
                            {
                              label: 'Onboarding Template',
                              value: 'onboarding template',
                            },
                          ],
                        },
                        {
                          name: '_offset_6',
                          type: 'text',
                          value: '',
                        },
                        {
                          name: '_description',
                          type: 'textarea',
                          value: 'description',
                          inputType: 'string',
                          label: 'Description',
                          rows: 1,
                        },
                        {
                          name: '_owners',
                          type: 'multi_select',
                          value: 'owners',
                          label: 'Owners',
                          options: [
                            {
                              label: 'May fox',
                              value: 'may fox',
                            },
                            {
                              label: 'Terry King',
                              value: 'terry king',
                            },
                          ],
                        },
                        {
                          name: '_collaborators',
                          type: 'multi_select',
                          value: 'collaborators',
                          label: 'Collaborators',
                          options: [
                            {
                              label: 'May fox',
                              value: 'may fox',
                            },
                            {
                              label: 'Terry King',
                              value: 'terry king',
                            },
                          ],
                        },
                        {
                          name: '_note_types',
                          type: 'multi_select',
                          value: 'note_types',
                          label: 'Note Types',
                          options: [
                            {
                              label: 'Public',
                              value: 'Public',
                            },
                            {
                              label: 'Private',
                              value: 'private',
                            },
                            {
                              label: 'Chase',
                              value: 'chase',
                            },
                            {
                              label: 'Resolution',
                              value: 'resolution',
                            },
                          ],
                        },
                        {
                          name: '_channels',
                          type: 'multi_select',
                          value: 'channels',
                          label: 'Channels',
                          options: [
                            {
                              label: 'Web',
                              value: 'web',
                            },
                            {
                              label: 'Teams',
                              value: 'teams',
                            },
                          ],
                        },
                      ],
                      view_layout: {
                        width: '100%',
                        no_of_cols: 12,
                        rows: [
                          {
                            i: '_template_name',
                            w: 5,
                            h: 1,
                          },
                          {
                            i: '_prefix',
                            w: 1,
                            h: 1,
                          },
                          {
                            i: '_running_number',
                            w: 3,
                            h: 1,
                          },
                          {
                            i: '_suffix',
                            w: 1,
                            h: 1,
                          },
                          {
                            i: '_start_at',
                            w: 2,
                            h: 1,
                          },
                          {
                            i: '_template_type',
                            w: 6,
                            h: 1,
                          },
                          {
                            i: '_description',
                            w: 6,
                            h: 1,
                          },
                          {
                            i: '_select_child_template_type',
                            w: 6,
                            h: 1,
                          },
                          {
                            i: '_offset_6',
                            w: 6,
                            h: 1,
                          },
                          {
                            i: '_owners',
                            w: 6,
                            h: 1,
                          },
                          {
                            i: '_collaborators',
                            w: 6,
                            h: 1,
                          },
                          {
                            i: '_note_types',
                            w: 6,
                            h: 1,
                          },
                          {
                            i: '_channels',
                            w: 6,
                            h: 1,
                          },
                        ],
                      },
                    },
                    {
                      name: 'role_tab_container',
                      label: 'Role Settings',
                      type: 'container',
                      show_label: true,
                      style: { paddingTop: '8px' },
                      elements: [
                        {
                          name: '_viewers',
                          type: 'select',
                          value: 'viewers',
                          label: 'Viewers',
                          isMandatory: true,
                          options: [
                            {
                              label: 'Organisation',
                              value: 'organisation',
                            },
                            {
                              label: 'Team',
                              value: 'team',
                            },
                            {
                              label: 'Group',
                              value: 'group',
                            },
                            {
                              label: 'Queue',
                              value: 'queue',
                            },
                            {
                              label: 'User',
                              value: 'user',
                            },
                            {
                              label: 'Role',
                              value: 'role',
                            },
                            {
                              label: 'Actors',
                              value: 'actors',
                            },
                          ],
                        },
                      ],
                      view_layout: {
                        width: '100%',
                        no_of_cols: 12,
                        rows: [
                          {
                            i: '_viewers',
                            w: 12,
                            h: 1,
                          },
                        ],
                      },
                    },
                  ],
                  view_layout: {
                    width: '100%',
                    no_of_cols: 12,
                    rows: [
                      {
                        i: 'basic_tab_container',
                        w: 12,
                        h: 1,
                      },
                      {
                        i: 'role_tab_container',
                        w: 12,
                        h: 1,
                      },
                    ],
                  },
                },
              ],
              actions: [
                {
                  name: 'continue',
                  label: 'Continue',
                  show_label: true,
                  icon: 'edit.ico',
                  is_button: true,
                  action_type: 'get',
                  permission: 'edit',
                  view: 'details_view',
                  target_type: 'api_call',
                  type: 'button',
                  is_modal_footer: true,
                  target_url: '/actors/create',
                  method: 'post',
                  payloadKeysMaps: {
                    template_name: 'basic_tab_container.template_name',
                    template_type: 'basic_tab_container.template_type.value',
                    child_templates_list:
                      'basic_tab_container.child_templates_list.value',
                    description: 'basic_tab_container.description',
                    owner: 'basic_tab_container.owner',
                    collaborator: 'basic_tab_container.collaborator',
                    note_types: 'basic_tab_container.note_types',
                    channels: 'basic_tab_container.channels',
                    viewers: 'role_tab_container.viewers',
                  },
                },
                {
                  name: 'cancel',
                  label: 'Cancel',
                  show_label: true,
                  icon: 'edit.ico',
                  is_button: true,
                  action_type: 'get',
                  permission: 'edit',
                  view: 'details_view',
                  target_type: 'state_change',
                  type: 'button',
                  is_modal_footer: true,
                  payloadKeysMaps: {
                    template_name: 'basic_tab_container.template_name',
                    template_type: 'basic_tab_container.template_type.value',
                    child_templates_list:
                      'basic_tab_container.child_templates_list.value',
                    description: 'basic_tab_container.description',
                    owner: 'basic_tab_container.owner',
                    collaborator: 'basic_tab_container.collaborator',
                    note_types: 'basic_tab_container.note_types',
                    channels: 'basic_tab_container.channels',
                    viewers: 'role_tab_container.viewers',
                  },
                },
              ],
              view_layout: {
                width: '100%',
                no_of_cols: 12,
                rows: [
                  {
                    i: 'create_template_tab_view',
                    w: 12,
                    h: 1,
                  },
                  {
                    i: 'cancel',
                    w: 6,
                    h: 1,
                  },
                  {
                    i: 'continue',
                    w: 6,
                    h: 1,
                  },
                ],
              },
            },
          ],
          actions: [
            {
              name: 'create_template',
              label: 'Create Template',
              show_label: true,
              icon: 'edit.ico',
              is_button: true,
              action_type: 'get',
              permission: 'edit',
              view: 'details_view',
              target_type: 'popup',
              target_modal_id: 'create_template_modal',
              type: 'button',
            },
          ],
          view_layout: {
            width: '100%',
            no_of_cols: 12,
            rows: [
              {
                i: 'create_template',
                w: 6,
                h: 1,
              },
              {
                i: 'ticket_template_list_short_view',
                w: 12,
                h: 1,
              },
              {
                i: 'create_modal',
                w: 12,
                h: 1,
              },
            ],
          },
        },

        {
          name: 'fieldsetslist_accordion',
          label: 'Fieldsets',
          show_label: false,
          type: 'accordion',
          entity: 'Fieldsets',
          filterKey: 'name',
          views: [
            {
              name: 'fieldset_list_short_view',
              label: 'Fieldset List Short',
              show_label: false,
              type: 'list_short',
              entity: 'fieldset',
              apiReq: {
                reqCols: [
                  'id',
                  'name',
                  'slug',
                  'version',
                  'properties',
                  'metric',
                  'tenant_id',
                ],
                orderBy: 'created_on asc',
                endPoint: 'templates/list',
              },
              columns: ['name'],
              filter: [
                {
                  AND: [
                    {
                      condition: 'is_active = true',
                    },
                  ],
                },
              ],

              actions: [
                {
                  name: 'fieldset_view_edit_details',
                  label: 'View/Edit',
                  show_label: false,
                  icon: 'edit.ico',
                  is_button: false,
                  action_type: 'get',
                  permission: 'view',
                  target_params: ['id', 'name'],
                  type: 'record',
                  target: '/template-maker/fieldset/',
                  target_type: 'navigate',
                },
              ],
            },
            {
              name: 'create_modal_fieldset',
              type: 'modal',
              modal_id: 'create_template_modal_fieldset',
              elements: [
                {
                  name: 'namelabel',
                  type: 'text',
                  value: 'Name',
                },
                {
                  name: 'typelabel',
                  type: 'text',
                  value: 'Type',
                },
                {
                  name: 'name',
                  type: 'input',
                  value: 'templateName',
                  inputType: 'string',
                },
                {
                  name: 'type',
                  type: 'select',
                  value: 'templateType',
                  options: [{ name: 'dummy', value: 'Dummy' }],
                },
              ],
              actions: [
                {
                  name: 'continue',
                  label: 'Continue',
                  show_label: true,
                  icon: 'edit.ico',
                  is_button: true,
                  action_type: 'get',
                  permission: 'edit',
                  view: 'details_view',
                  target_type: 'api_call',
                  type: 'button',
                },
                {
                  name: 'cancel',
                  label: 'Cancel',
                  show_label: true,
                  icon: 'edit.ico',
                  is_button: true,
                  action_type: 'get',
                  permission: 'edit',
                  view: 'details_view',
                  target_type: 'state_change',
                  type: 'button',
                },
              ],
              view_layout: {
                width: '100%',
                no_of_cols: 12,
                rows: [
                  {
                    i: 'namelabel',
                    w: 6,
                    h: 1,
                  },
                  {
                    i: 'typelabel',
                    w: 6,
                    h: 1,
                  },
                  {
                    i: 'name',
                    w: 6,
                    h: 1,
                  },
                  {
                    i: 'type',
                    w: 6,
                    h: 1,
                  },
                  {
                    i: 'continue',
                    w: 6,
                    h: 1,
                  },
                  {
                    i: 'cancel',
                    w: 6,
                    h: 1,
                  },
                ],
              },
            },
          ],
          actions: [
            {
              name: 'create_fieldset',
              label: 'Create FieldSet',
              show_label: true,
              icon: 'edit.ico',
              is_button: true,
              action_type: 'get',
              permission: 'edit',
              view: 'details_view',
              target_type: 'popup',
              type: 'button',
              target_modal_id: 'create_template_modal_fieldset',
            },
          ],
          view_layout: {
            width: '100%',
            no_of_cols: 12,
            rows: [
              {
                i: 'create_fieldset',
                w: 12,
                h: 1,
              },
              {
                i: 'fieldset_list_short_view',
                w: 12,
                h: 1,
              },
              {
                i: 'create_modal_fieldset',
                w: 12,
                h: 1,
              },
            ],
          },
        },
      ],
      view_layout: {
        width: '100%',
        no_of_cols: 12,
        rows: [
          {
            i: 'ticket_template_list_accordion',
            x: 0,
            y: 0,
            w: 12,
            h: 1,
          },
          {
            i: 'fieldsetslist_accordion',
            x: 0,
            y: 1,
            w: 12,
            maxH: 'Infinity',
          },
        ],
      },
    },

    // {
    //   name: 'template_details_view',
    //   label: 'template_details',
    //   route: '/template-maker/template/:id',
    //   show_label: false,
    //   type: 'container',
    //   views: [
    //     {
    //       name: 'leftTop_view',
    //       label: 'Ticket Template List Short',
    //       show_label: false,
    //       type: 'container',
    //       views: [
    //         {
    //           name: 'left_view',
    //           label: 'Ticket Template List Short',
    //           show_label: false,
    //           type: 'container',
    //           elements: [
    //             {
    //               name: 'title',
    //               type: 'dynamic_text',
    //               value: '$name',
    //               style: { fontSize: '29px', fontWeight: '600' },
    //             },
    //           ],
    //           actions: [
    //             {
    //               name: 'edit',
    //               label: 'Edit',
    //               show_label: true,
    //               is_button: true,
    //               icon: '',
    //               action_type: 'get',
    //               permission: 'edit',
    //               view: 'edit_view',
    //               navigation: 'popup',
    //               target_modal_id: 'create_template_modal',
    //               type: 'button',
    //               classname: 'editbutton',
    //               style: {
    //                 color: 'rgba(0, 0, 0, 0.85)',
    //                 fontWeight: '600',
    //                 height: '32px',
    //               },
    //             },
    //             {
    //               name: 'copy',
    //               label: '',
    //               show_label: false,
    //               icon: 'copy.ico',
    //               is_button: true,
    //               action_type: 'get',
    //               permission: 'edit',
    //               view: 'ticket_template_field_delete_view',
    //               navigation: 'popup',
    //               target_modal_id: 'create_template_modal',
    //               type: 'button',
    //               classname: 'copybutton',
    //               style: {
    //                 color: 'rgba(0, 0, 0, 0.85)',
    //                 fontSize: '19px',
    //                 width: '32px',
    //                 height: '32px',
    //               },
    //             },
    //             {
    //               name: 'delete',
    //               label: '',
    //               show_label: false,
    //               icon: 'delete.ico',
    //               is_button: true,
    //               action_type: 'get',
    //               permission: 'edit',
    //               view: 'ticket_template_field_delete_view',
    //               navigation: 'popup',
    //               target_modal_id: 'create_template_modal',
    //               type: 'button',
    //               classname: 'deletebutton',
    //               style: {
    //                 borderColor: '#ED7474',
    //                 color: '#ED7474',
    //                 fontSize: '19px',
    //                 width: '32px',
    //                 height: '32px',
    //               },
    //             },
    //           ],
    //           view_layout: {
    //             width: '100%',
    //             no_of_cols: 12,
    //             rows: [
    //               {
    //                 i: 'title',
    //                 w: 5,
    //                 h: 1,
    //               },
    //               {
    //                 i: 'edit',
    //                 w: 2,
    //                 h: 1,
    //               },
    //               {
    //                 i: 'copy',
    //                 w: 1,
    //                 h: 1,
    //               },
    //               {
    //                 i: 'delete',
    //                 w: 1,
    //                 h: 1,
    //               },
    //             ],
    //           },
    //         },
    //         {
    //           name: 'right_view',
    //           label: 'Ticket Template List Short',
    //           show_label: false,
    //           type: 'container',
    //           classname: 'rightview',
    //           actions: [
    //             {
    //               name: 'view_json',
    //               label: 'View JSON',
    //               show_label: true,
    //               is_button: true,
    //               icon: '',
    //               action_type: 'get',
    //               permission: 'edit',
    //               view: 'edit_view',
    //               navigation: 'popup',
    //               target_modal_id: 'create_template_modal',
    //               type: 'button',
    //               classname: 'viewjsonbutton',
    //               style: { color: 'rgba(0, 0, 0, 0.85)' },
    //             },
    //             {
    //               name: 'preview',
    //               label: 'Preview',
    //               show_label: true,
    //               is_button: true,
    //               icon: 'view.ico',
    //               action_type: 'get',
    //               permission: 'edit',
    //               view: 'edit_view',
    //               navigation: 'popup',
    //               target_modal_id: 'create_template_modal',
    //               type: 'button',
    //               classname: 'previewbutton',
    //               style: { color: 'rgba(0, 0, 0, 0.85)' },
    //             },
    //             {
    //               name: 'dropdown',
    //               label: 'Ticket Template Fields',
    //               show_label: false,
    //               type: 'dropdown_select',
    //               columns: ['status'],
    //               classname: 'dropdownStyle',
    //               filter: [
    //                 {
    //                   condition: 'is_active = true',
    //                 },
    //               ],
    //             },
    //             {
    //               name: 'save',
    //               label: 'Save',
    //               show_label: true,
    //               icon: '',
    //               is_button: true,
    //               action_type: 'get',
    //               permission: 'edit',
    //               view: 'ticket_template_field_delete_view',
    //               navigation: 'popup',
    //               target_modal_id: 'create_template_modal',
    //               type: 'button',
    //               classname: 'savebutton',
    //               style: { background: '#2563EB', color: '#FFFFFF' },
    //             },
    //           ],
    //           view_layout: {
    //             width: '100%',
    //             no_of_cols: 12,
    //             rows: [
    //               {
    //                 i: 'view_json',
    //                 w: 2,
    //                 h: 1,
    //               },
    //               {
    //                 i: 'preview',
    //                 w: 2,
    //                 h: 1,
    //               },
    //               {
    //                 i: 'dropdown',
    //                 w: 4,
    //                 h: 1,
    //               },
    //               {
    //                 i: 'save',
    //                 w: 2,
    //                 h: 1,
    //               },
    //             ],
    //           },
    //         },
    //       ],
    //       view_layout: {
    //         width: '100%',
    //         no_of_cols: 12,
    //         rows: [
    //           {
    //             i: 'left_view',
    //             w: 6,
    //             h: 1,
    //           },
    //           {
    //             i: 'right_view',
    //             w: 6,
    //             h: 1,
    //           },
    //         ],
    //       },
    //     },
    //     // {
    //     //   name: 'ticket_template_fields_list_view',
    //     //   label: 'Ticket Template Fields',
    //     //   show_label: false,
    //     //   type: 'list_exp',
    //     //   related_entity: 'ticket_template_fields',
    //     //   columns: ['name', 'type', 'actions'],
    //     //   filter: [
    //     //     {
    //     //       condition: 'is_active = true',
    //     //     },
    //     //   ],
    //     // },
    //     {
    //       name: 'ticket_template_tabs_view',
    //       label: 'Ticket Template Fields',
    //       show_label: false,
    //       type: 'tab_view',
    //       related_entity: 'ticket_template_fields',
    //       filter: [],
    //       views: [
    //         {
    //           name: 'fieldsetting_tab',
    //           label: 'Field Settings',
    //           show_label: false,
    //           type: 'container',
    //           filter: [],
    //           views: [
    //             {
    //               name: 'field_search_container',
    //               type: 'container',
    //               views: [
    //                 // {
    //                 //   name: 'search_fieldsetting_tab',
    //                 //   label: 'Search for a field, type...',
    //                 //   type: 'search',
    //                 // },
    //               ],
    //               elements: [
    //                 {
    //                   name: 'search_fieldsetting_tab',
    //                   type: 'input',
    //                   label: 'Search for a field, type...',
    //                 },
    //               ],
    //               actions: [
    //                 {
    //                   name: 'add_field',
    //                   label: 'Add Field',
    //                   show_label: true,
    //                   icon: 'add.ico',
    //                   is_button: true,
    //                   action_type: 'get',
    //                   permission: 'edit',
    //                   view: 'details_view',
    //                   target_type: 'popup',
    //                   type: 'button',
    //                   style: { borderColor: 'rgb(104, 104, 247)' },
    //                 },
    //                 {
    //                   name: 'configure_view',
    //                   label: 'Configure the View',
    //                   show_label: true,
    //                   icon: 'config.ico',
    //                   is_button: true,
    //                   action_type: 'get',
    //                   permission: 'edit',
    //                   view: 'details_view',
    //                   target_type: 'navigate',
    //                   type: 'button',
    //                   is_modal_footer: true,
    //                   style: {
    //                     borderColor: 'rgb(0, 0, 0)',
    //                     color: 'rgb(0, 0, 0)',
    //                   },
    //                 },
    //               ],
    //               view_layout: {
    //                 width: '100%',
    //                 no_of_cols: 12,
    //                 rows: [
    //                   {
    //                     i: 'search_fieldsetting_tab',
    //                     w: 10,
    //                     h: 1,
    //                   },
    //                   {
    //                     i: 'add_field',
    //                     w: 1,
    //                     h: 1,
    //                   },
    //                   {
    //                     i: 'configure_view',
    //                     w: 1,
    //                     h: 1,
    //                   },
    //                 ],
    //               },
    //             },
    //             {
    //               name: 'ticket_template_fields_list_view',
    //               label: 'Ticket Template Fields',
    //               show_label: false,
    //               type: 'list_exp',
    //               related_entity: 'ticket_template_fields',
    //               columns: ['name', 'type', 'actions'],
    //               filter: [
    //                 {
    //                   condition: 'is_active = true',
    //                 },
    //               ],
    //               actions: [
    //                 {
    //                   name: 'edit_fields',
    //                   icon: 'editIcon',
    //                   type: 'button',
    //                   target_type: 'popup',
    //                   target_modal_id: 'edit',
    //                 },
    //                 {
    //                   name: 'delete_fields',
    //                   icon: 'deleteIcon',
    //                   type: 'button',
    //                   target_type: 'popup',
    //                   target_modal_id: 'delete',
    //                 },
    //               ],
    //             },
    //           ],
    //           view_layout: {
    //             width: '100%',
    //             no_of_cols: 12,
    //             rows: [
    //               {
    //                 i: 'fieldsetting_tab',
    //                 w: 12,
    //                 h: 1,
    //               },
    //               {
    //                 i: 'field_search_container',
    //                 w: 12,
    //                 h: 1,
    //               },
    //               {
    //                 i: 'ticket_template_fields_list_view',
    //                 w: 12,
    //                 h: 1,
    //               },
    //             ],
    //           },
    //         },

    //         // role settings
    //         {
    //           name: 'role_settings_tab',
    //           label: 'Role Settings',
    //           show_label: false,
    //           type: 'container',
    //           filter: [],
    //         },

    //         // field defaults
    //         // {
    //         //   name: 'field_defaults_tab',
    //         //   label: 'Ticket Template Fields Default Settings Tab',
    //         //   show_label: false,
    //         //   type: 'container',
    //         //   filter: [],
    //         //   views: [
    //         //     {
    //         //       name: 'field_search_container',
    //         //       type: 'container',
    //         //       views: [
    //         //         {
    //         //           name: 'search_fieldsetting_tab',
    //         //           type: 'search',
    //         //         },
    //         //       ],
    //         //       actions: [
    //         //         {
    //         //           name: 'add_field',
    //         //           label: 'Add Field',
    //         //           show_label: true,
    //         //           icon: 'edit.ico',
    //         //           is_button: true,
    //         //           action_type: 'get',
    //         //           permission: 'edit',
    //         //           view: 'details_view',
    //         //           target_type: 'popup',
    //         //           type: 'button',
    //         //         },
    //         //         {
    //         //           name: 'congigure_view',
    //         //           label: 'Configure the View',
    //         //           show_label: true,
    //         //           icon: 'edit.ico',
    //         //           is_button: true,
    //         //           action_type: 'get',
    //         //           permission: 'edit',
    //         //           view: 'details_view',
    //         //           target_type: 'navigate',
    //         //           type: 'button',
    //         //           is_modal_footer: true,
    //         //         },
    //         //       ],
    //         //       view_layout: {
    //         //         width: '100%',
    //         //         no_of_cols: 12,
    //         //         rows: [
    //         //           {
    //         //             i: 'search_fieldsetting_tab',
    //         //             w: 8,
    //         //             h: 1,
    //         //           },
    //         //           {
    //         //             i: 'add_field',
    //         //             w: 2,
    //         //             h: 1,
    //         //           },
    //         //           {
    //         //             i: 'configure_view',
    //         //             w: 2,
    //         //           },
    //         //         ],
    //         //       },
    //         //     },
    //         //     {
    //         //       name: 'ticket_template_fields_list_view',
    //         //       label: 'Ticket Template Fields',
    //         //       show_label: false,
    //         //       type: 'list_exp',
    //         //       related_entity: 'ticket_template_fields',
    //         //       columns: ['name', 'type', 'actions'],
    //         //       filter: [],
    //         //     },
    //         //   ],
    //         //   view_layout: {
    //         //     width: '100%',
    //         //     no_of_cols: 12,
    //         //     rows: [
    //         //       {
    //         //         i: 'leftTop_view',
    //         //         w: 12,
    //         //         h: 1,
    //         //       },
    //         //       {
    //         //         i: 'ticket_template_fields_list_view',
    //         //         w: 12,
    //         //         h: 1,
    //         //       },
    //         //     ],
    //         //   },
    //         // },
    //       ],
    //     },
    //   ],
    //   view_layout: {
    //     width: '100%',
    //     no_of_cols: 12,
    //     rows: [
    //       {
    //         i: 'leftTop_view',
    //         w: 12,
    //         h: 1,
    //       },
    //       // {
    //       //   i: 'ticket_template_fields_list_view',
    //       //   w: 12,
    //       //   h: 1,
    //       // },
    //       {
    //         i: 'ticket_template_tabs_view',
    //         w: 12,
    //         h: 1,
    //       },
    //     ],
    //   },
    // },

    // {
    //   name: 'fieldset_details_view',
    //   label: 'Details view',
    //   route: '/template-maker/fieldset/:id',
    //   show_label: false,
    //   type: 'container',
    //   views: [
    //     {
    //       name: 'leftTop_view',
    //       label: 'Ticket Template List Short',
    //       show_label: false,
    //       type: 'container',
    //       views: [
    //         {
    //           name: 'left_view',
    //           label: 'Ticket Template List Short',
    //           show_label: false,
    //           type: 'container',
    //           elements: [
    //             {
    //               name: 'title',
    //               type: 'text',
    //               value: '$name',
    //               style: { fontSize: '29px', fontWeight: '600' },
    //             },
    //           ],
    //           actions: [
    //             {
    //               name: 'edit',
    //               label: 'Edit',
    //               show_label: true,
    //               is_button: true,
    //               icon: '',
    //               action_type: 'get',
    //               permission: 'edit',
    //               view: 'edit_view',
    //               navigation: 'popup',
    //               target_modal_id: 'create_template_modal',
    //               type: 'button',
    //               classname: 'editbutton',
    //               style: {
    //                 color: 'rgba(0, 0, 0, 0.85)',
    //                 fontWeight: '600',
    //                 height: '32px',
    //               },
    //             },
    //             {
    //               name: 'copy',
    //               label: '',
    //               show_label: false,
    //               icon: 'copy.ico',
    //               is_button: true,
    //               action_type: 'get',
    //               permission: 'edit',
    //               view: 'ticket_template_field_delete_view',
    //               navigation: 'popup',
    //               target_modal_id: 'create_template_modal',
    //               type: 'button',
    //               classname: 'copybutton',
    //               style: {
    //                 color: 'rgba(0, 0, 0, 0.85)',
    //                 fontSize: '19px',
    //                 width: '32px',
    //                 height: '32px',
    //               },
    //             },
    //             {
    //               name: 'delete',
    //               label: '',
    //               show_label: false,
    //               icon: 'delete.ico',
    //               is_button: true,
    //               action_type: 'get',
    //               permission: 'edit',
    //               view: 'ticket_template_field_delete_view',
    //               navigation: 'popup',
    //               target_modal_id: 'create_template_modal',
    //               type: 'button',
    //               classname: 'deletebutton',
    //               style: {
    //                 borderColor: '#ED7474',
    //                 color: '#ED7474',
    //                 fontSize: '19px',
    //                 width: '32px',
    //                 height: '32px',
    //               },
    //             },
    //           ],
    //           view_layout: {
    //             width: '100%',
    //             no_of_cols: 12,
    //             rows: [
    //               {
    //                 i: 'title',
    //                 w: 5,
    //                 h: 1,
    //               },
    //               {
    //                 i: 'edit',
    //                 w: 2,
    //                 h: 1,
    //               },
    //               {
    //                 i: 'copy',
    //                 w: 1,
    //                 h: 1,
    //               },
    //               {
    //                 i: 'delete',
    //                 w: 1,
    //                 h: 1,
    //               },
    //             ],
    //           },
    //         },
    //         {
    //           name: 'right_view',
    //           label: 'Ticket Template List Short',
    //           show_label: false,
    //           type: 'container',
    //           classname: 'rightview',
    //           actions: [
    //             {
    //               name: 'view_json',
    //               label: 'View JSON',
    //               show_label: true,
    //               is_button: true,
    //               icon: '',
    //               action_type: 'get',
    //               permission: 'edit',
    //               view: 'edit_view',
    //               navigation: 'popup',
    //               target_modal_id: 'create_template_modal',
    //               type: 'button',
    //               classname: 'viewjsonbutton',
    //               style: { color: 'rgba(0, 0, 0, 0.85)' },
    //             },
    //             {
    //               name: 'preview',
    //               label: 'Preview',
    //               show_label: true,
    //               is_button: true,
    //               icon: 'view.ico',
    //               action_type: 'get',
    //               permission: 'edit',
    //               view: 'edit_view',
    //               navigation: 'popup',
    //               target_modal_id: 'create_template_modal',
    //               type: 'button',
    //               classname: 'previewbutton',
    //               style: { color: 'rgba(0, 0, 0, 0.85)' },
    //             },
    //             {
    //               name: 'dropdown',
    //               label: 'Ticket Template Fields',
    //               show_label: false,
    //               type: 'dropdown_select',
    //               related_entity: 'ticket_template_fields',
    //               columns: ['status'],
    //               classname: 'dropdownStyle',
    //               filter: [
    //                 {
    //                   condition: 'is_active = true',
    //                 },
    //               ],
    //             },
    //             {
    //               name: 'save',
    //               label: 'Save',
    //               show_label: true,
    //               icon: '',
    //               is_button: true,
    //               action_type: 'get',
    //               permission: 'edit',
    //               view: 'ticket_template_field_delete_view',
    //               navigation: 'popup',
    //               target_modal_id: 'create_template_modal',
    //               type: 'button',
    //               classname: 'savebutton',
    //               style: { background: '#2563EB', color: '#FFFFFF' },
    //             },
    //           ],
    //           view_layout: {
    //             width: '100%',
    //             no_of_cols: 12,
    //             rows: [
    //               {
    //                 i: 'view_json',
    //                 w: 2,
    //                 h: 1,
    //               },
    //               {
    //                 i: 'preview',
    //                 w: 2,
    //                 h: 1,
    //               },
    //               {
    //                 i: 'dropdown',
    //                 w: 4,
    //                 h: 1,
    //               },
    //               {
    //                 i: 'save',
    //                 w: 2,
    //                 h: 1,
    //               },
    //             ],
    //           },
    //         },
    //       ],
    //       view_layout: {
    //         width: '100%',
    //         no_of_cols: 12,
    //         rows: [
    //           {
    //             i: 'left_view',
    //             w: 6,
    //             h: 1,
    //           },
    //           {
    //             i: 'right_view',
    //             w: 6,
    //             h: 1,
    //           },
    //         ],
    //       },
    //     },
    //     // {
    //     //   name: 'ticket_template_fields_list_view',
    //     //   label: 'Ticket Template Fields',
    //     //   show_label: false,
    //     //   type: 'list_exp',
    //     //   related_entity: 'ticket_template_fields',
    //     //   columns: ['name', 'type', 'actions'],
    //     //   filter: [
    //     //     {
    //     //       condition: 'is_active = true',
    //     //     },
    //     //   ],
    //     // },
    //     {
    //       name: 'ticket_template_tabs_view',
    //       label: 'Ticket Template Fields',
    //       show_label: false,
    //       type: 'tab_view',
    //       related_entity: 'ticket_template_fields',
    //       filter: [],
    //       views: [
    //         {
    //           name: 'fieldsetting_tab',
    //           label: 'Field Settings',
    //           show_label: false,
    //           type: 'container',
    //           filter: [],
    //           views: [
    //             {
    //               name: 'field_search_container',
    //               type: 'container',
    //               views: [
    //                 // {
    //                 //   name: 'search_fieldsetting_tab',
    //                 //   label: 'Search for a field, type...',
    //                 //   type: 'search',
    //                 // },
    //               ],
    //               elements: [
    //                 {
    //                   name: 'search_fieldsetting_tab',
    //                   type: 'input',
    //                   label: 'Search for a field, type...',
    //                 },
    //               ],
    //               actions: [
    //                 {
    //                   name: 'add_field',
    //                   label: 'Add Field',
    //                   show_label: true,
    //                   icon: 'add.ico',
    //                   is_button: true,
    //                   action_type: 'get',
    //                   permission: 'edit',
    //                   view: 'details_view',
    //                   target_type: 'popup',
    //                   type: 'button',
    //                   style: { borderColor: 'rgb(104, 104, 247)' },
    //                 },
    //                 {
    //                   name: 'configure_view',
    //                   label: 'Configure the View',
    //                   show_label: true,
    //                   icon: 'config.ico',
    //                   is_button: true,
    //                   action_type: 'get',
    //                   permission: 'edit',
    //                   view: 'details_view',
    //                   target_type: 'navigate',
    //                   type: 'button',
    //                   is_modal_footer: true,
    //                   style: {
    //                     borderColor: 'rgb(0, 0, 0)',
    //                     color: 'rgb(0, 0, 0)',
    //                   },
    //                 },
    //               ],
    //               view_layout: {
    //                 width: '100%',
    //                 no_of_cols: 12,
    //                 rows: [
    //                   {
    //                     i: 'search_fieldsetting_tab',
    //                     w: 10,
    //                     h: 1,
    //                   },
    //                   {
    //                     i: 'add_field',
    //                     w: 1,
    //                     h: 1,
    //                   },
    //                   {
    //                     i: 'configure_view',
    //                     w: 1,
    //                     h: 1,
    //                   },
    //                 ],
    //               },
    //             },
    //             {
    //               name: 'ticket_template_fields_list_view',
    //               label: 'Ticket Template Fields',
    //               show_label: false,
    //               type: 'list_exp',
    //               related_entity: 'ticket_template_fields',
    //               columns: ['name', 'type', 'actions'],
    //               filter: [
    //                 {
    //                   condition: 'is_active = true',
    //                 },
    //               ],
    //               actions: [
    //                 {
    //                   name: 'edit_fields',
    //                   icon: 'editIcon',
    //                   type: 'button',
    //                   target_type: 'popup',
    //                   target_modal_id: 'edit',
    //                 },
    //                 {
    //                   name: 'delete_fields',
    //                   icon: 'deleteIcon',
    //                   type: 'button',
    //                   target_type: 'popup',
    //                   target_modal_id: 'delete',
    //                 },
    //               ],
    //             },
    //           ],
    //           view_layout: {
    //             width: '100%',
    //             no_of_cols: 12,
    //             rows: [
    //               {
    //                 i: 'fieldsetting_tab',
    //                 w: 12,
    //                 h: 1,
    //               },
    //               {
    //                 i: 'field_search_container',
    //                 w: 12,
    //                 h: 1,
    //               },
    //               {
    //                 i: 'ticket_template_fields_list_view',
    //                 w: 12,
    //                 h: 1,
    //               },
    //             ],
    //           },
    //         },

    //         // role settings
    //         {
    //           name: 'role_settings_tab',
    //           label: 'Role Settings',
    //           show_label: false,
    //           type: 'container',
    //           filter: [],
    //         },

    //         // field defaults
    //         // {
    //         //   name: 'field_defaults_tab',
    //         //   label: 'Ticket Template Fields Default Settings Tab',
    //         //   show_label: false,
    //         //   type: 'container',
    //         //   filter: [],
    //         //   views: [
    //         //     {
    //         //       name: 'field_search_container',
    //         //       type: 'container',
    //         //       views: [
    //         //         {
    //         //           name: 'search_fieldsetting_tab',
    //         //           type: 'search',
    //         //         },
    //         //       ],
    //         //       actions: [
    //         //         {
    //         //           name: 'add_field',
    //         //           label: 'Add Field',
    //         //           show_label: true,
    //         //           icon: 'edit.ico',
    //         //           is_button: true,
    //         //           action_type: 'get',
    //         //           permission: 'edit',
    //         //           view: 'details_view',
    //         //           target_type: 'popup',
    //         //           type: 'button',
    //         //         },
    //         //         {
    //         //           name: 'congigure_view',
    //         //           label: 'Configure the View',
    //         //           show_label: true,
    //         //           icon: 'edit.ico',
    //         //           is_button: true,
    //         //           action_type: 'get',
    //         //           permission: 'edit',
    //         //           view: 'details_view',
    //         //           target_type: 'navigate',
    //         //           type: 'button',
    //         //           is_modal_footer: true,
    //         //         },
    //         //       ],
    //         //       view_layout: {
    //         //         width: '100%',
    //         //         no_of_cols: 12,
    //         //         rows: [
    //         //           {
    //         //             i: 'search_fieldsetting_tab',
    //         //             w: 8,
    //         //             h: 1,
    //         //           },
    //         //           {
    //         //             i: 'add_field',
    //         //             w: 2,
    //         //             h: 1,
    //         //           },
    //         //           {
    //         //             i: 'configure_view',
    //         //             w: 2,
    //         //           },
    //         //         ],
    //         //       },
    //         //     },
    //         //     {
    //         //       name: 'ticket_template_fields_list_view',
    //         //       label: 'Ticket Template Fields',
    //         //       show_label: false,
    //         //       type: 'list_exp',
    //         //       related_entity: 'ticket_template_fields',
    //         //       columns: ['name', 'type', 'actions'],
    //         //       filter: [],
    //         //     },
    //         //   ],
    //         //   view_layout: {
    //         //     width: '100%',
    //         //     no_of_cols: 12,
    //         //     rows: [
    //         //       {
    //         //         i: 'leftTop_view',
    //         //         w: 12,
    //         //         h: 1,
    //         //       },
    //         //       {
    //         //         i: 'ticket_template_fields_list_view',
    //         //         w: 12,
    //         //         h: 1,
    //         //       },
    //         //     ],
    //         //   },
    //         // },
    //       ],
    //     },
    //   ],
    //   view_layout: {
    //     width: '100%',
    //     no_of_cols: 12,
    //     rows: [
    //       {
    //         i: 'leftTop_view',
    //         w: 12,
    //         h: 1,
    //       },
    //       // {
    //       //   i: 'ticket_template_fields_list_view',
    //       //   w: 12,
    //       //   h: 1,
    //       // },
    //       {
    //         i: 'ticket_template_tabs_view',
    //         w: 12,
    //         h: 1,
    //       },
    //     ],
    //   },
    // },
    // {
    //   name: 'card_layout',
    //   label: 'Card Details',
    //   show_label: false,
    //   type: 'container',
    //   entity: 'card_details',

    //   views: [
    //     {
    //       name: 'card_list_view',
    //       label: 'Card view',
    //       show_label: false,
    //       type: 'card_view',
    //       elements: [
    //         {
    //           name: 'status',
    //           type: 'text',
    //           value: 'status',
    //         },
    //         {
    //           name: 'star_icon',
    //           type: 'text',
    //           value: 'star_icon',
    //         },
    //         {
    //           name: 'ticket_type',
    //           type: 'text',
    //           value: 'ticket_type',
    //         },
    //         {
    //           name: 'description',
    //           type: 'text',
    //           value: 'description',
    //         },
    //         {
    //           name: 'created_by_label',
    //           type: 'text',
    //           value: 'created_by_label',
    //         },
    //         {
    //           name: 'created_on_label',
    //           type: 'text',
    //           value: 'created_on_label',
    //         },
    //         {
    //           name: 'last_edited_label',
    //           type: 'text',
    //           value: 'last_edited_label',
    //         },
    //         {
    //           name: 'edited_by_label',
    //           type: 'text',
    //           value: 'edited_by_label',
    //         },
    //         {
    //           name: 'created_by',
    //           type: 'text',
    //           value: 'created_by',
    //         },
    //         {
    //           name: 'created_on',
    //           type: 'text',
    //           value: 'created_on',
    //         },
    //         {
    //           name: 'last_edited',
    //           type: 'text',
    //           value: 'last_edited',
    //         },
    //         {
    //           name: 'edited_by',
    //           type: 'text',
    //           value: 'edited_by',
    //         },
    //       ],
    //       actions: [
    //         {
    //           name: 'option_popup',
    //           label: 'click popup',
    //           show_label: true,
    //           icon: 'edit.ico',
    //           is_button: true,
    //           action_type: 'get',
    //           permission: 'edit',
    //           view: 'details_view',
    //           navigation: 'popup',
    //           type: 'button',
    //         },
    //       ],
    //       filter: [
    //         {
    //           AND: [
    //             {
    //               condition: 'is_active = true',
    //             },
    //           ],
    //         },
    //       ],
    //       view_layout: {
    //         width: '40%',
    //         no_of_cols: 12,
    //         rows: [
    //           // {
    //           //   i: 'option_popup',
    //           //   w: 10,
    //           //   h: 1,
    //           // },

    //           {
    //             i: 'status',
    //             w: 2,
    //             h: 1,
    //           },
    //           {
    //             i: 'star_icon',
    //             w: 10,
    //             h: 1,
    //           },
    //           {
    //             i: 'ticket_type',
    //             w: 12,
    //             h: 1,
    //           },
    //           {
    //             i: 'description',
    //             w: 12,
    //             h: 1,
    //           },
    //           {
    //             i: 'created_by_label',
    //             w: 6,
    //             h: 1,
    //           },
    //           {
    //             i: 'created_by',
    //             w: 6,
    //             h: 1,
    //           },
    //           {
    //             i: 'created_on_label',
    //             w: 6,
    //             h: 1,
    //           },
    //           {
    //             i: 'created_on',
    //             w: 6,
    //             h: 1,
    //           },
    //           {
    //             i: 'last_edited_label',
    //             w: 6,
    //             h: 1,
    //           },
    //           {
    //             i: 'last_edited',
    //             w: 6,
    //             h: 1,
    //           },
    //           {
    //             i: 'edited_by_label',
    //             w: 6,
    //             h: 1,
    //           },
    //           {
    //             i: 'edited_by',
    //             w: 6,
    //             h: 1,
    //           },
    //         ],
    //       },
    //     },
    //   ],
    // },

    // role settings screen

    {
      name: 'template_details_view',
      label: 'template_details',
      route: '/template-maker/template/:id',
      show_label: false,
      type: 'container',
      condition: {
        stateName: 'ticket_template_list_short_view',
        operator: '=',
        stateValue: 0,
      },
      views: [
        {
          name: 'leftTop_view',
          label: 'Ticket Template List Short',
          show_label: false,
          type: 'container',
          views: [
            {
              name: 'left_view',
              label: 'Ticket Template List Short',
              show_label: false,
              type: 'container',
              elements: [
                {
                  name: 'title',
                  type: 'dynamic_text',
                  value: '$name',
                  style: { fontSize: '29px', fontWeight: '600' },
                },
              ],
              actions: [
                {
                  name: 'edit',
                  label: 'Edit',
                  show_label: true,
                  is_button: true,
                  icon: '',
                  action_type: 'get',
                  permission: 'edit',
                  view: 'edit_view',
                  navigation: 'popup',
                  target_modal_id: 'create_template_modal',
                  type: 'button',
                  classname: 'editbutton',
                  style: {
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontWeight: '600',
                    height: '32px',
                  },
                },
                {
                  name: 'copy',
                  label: '',
                  show_label: false,
                  icon: 'copy.ico',
                  is_button: true,
                  action_type: 'get',
                  permission: 'edit',
                  view: 'ticket_template_field_delete_view',
                  navigation: 'popup',
                  target_modal_id: 'create_template_modal',
                  type: 'button',
                  classname: 'copybutton',
                  style: {
                    color: 'rgba(0, 0, 0, 0.85)',
                    fontSize: '19px',
                    width: '32px',
                    height: '32px',
                  },
                },
                {
                  name: 'delete',
                  label: '',
                  show_label: false,
                  icon: 'delete.ico',
                  is_button: true,
                  action_type: 'get',
                  permission: 'edit',
                  view: 'ticket_template_field_delete_view',
                  navigation: 'popup',
                  target_modal_id: 'create_template_modal',
                  type: 'button',
                  classname: 'deletebutton',
                  style: {
                    borderColor: '#ED7474',
                    color: '#ED7474',
                    fontSize: '19px',
                    width: '32px',
                    height: '32px',
                  },
                },
              ],
              view_layout: {
                width: '100%',
                no_of_cols: 12,
                rows: [
                  {
                    i: 'title',
                    w: 5,
                    h: 1,
                  },
                  {
                    i: 'edit',
                    w: 2,
                    h: 1,
                  },
                  {
                    i: 'copy',
                    w: 1,
                    h: 1,
                  },
                  {
                    i: 'delete',
                    w: 1,
                    h: 1,
                  },
                ],
              },
            },
            {
              name: 'right_view',
              label: 'Ticket Template List Short',
              show_label: false,
              type: 'container',
              classname: 'rightview',
              actions: [
                {
                  name: 'view_json',
                  label: 'View JSON',
                  show_label: true,
                  is_button: true,
                  icon: '',
                  action_type: 'get',
                  permission: 'edit',
                  view: 'edit_view',
                  navigation: 'popup',
                  target_modal_id: 'create_template_modal',
                  type: 'button',
                  classname: 'viewjsonbutton',
                  style: { color: 'rgba(0, 0, 0, 0.85)' },
                },
                {
                  name: 'preview',
                  label: 'Preview',
                  show_label: true,
                  is_button: true,
                  icon: 'view.ico',
                  action_type: 'get',
                  permission: 'edit',
                  view: 'edit_view',
                  navigation: 'popup',
                  target_modal_id: 'create_template_modal',
                  type: 'button',
                  classname: 'previewbutton',
                  style: { color: 'rgba(0, 0, 0, 0.85)' },
                },
                {
                  name: 'dropdown',
                  label: 'Ticket Template Fields',
                  show_label: false,
                  type: 'dropdown_select',
                  columns: ['status'],
                  classname: 'dropdownStyle',
                  filter: [
                    {
                      condition: 'is_active = true',
                    },
                  ],
                },
                {
                  name: 'save',
                  label: 'Save',
                  show_label: true,
                  icon: '',
                  is_button: true,
                  action_type: 'get',
                  permission: 'edit',
                  view: 'ticket_template_field_delete_view',
                  navigation: 'popup',
                  target_modal_id: 'create_template_modal',
                  type: 'button',
                  classname: 'savebutton',
                  style: { background: '#2563EB', color: '#FFFFFF' },
                },
              ],
              view_layout: {
                width: '100%',
                no_of_cols: 12,
                rows: [
                  {
                    i: 'view_json',
                    w: 2,
                    h: 1,
                  },
                  {
                    i: 'preview',
                    w: 2,
                    h: 1,
                  },
                  {
                    i: 'dropdown',
                    w: 4,
                    h: 1,
                  },
                  {
                    i: 'save',
                    w: 2,
                    h: 1,
                  },
                ],
              },
            },
          ],
          view_layout: {
            width: '100%',
            no_of_cols: 12,
            rows: [
              {
                i: 'left_view',
                w: 6,
                h: 1,
              },
              {
                i: 'right_view',
                w: 6,
                h: 1,
              },
            ],
          },
        },
        // {
        //   name: 'ticket_template_fields_list_view',
        //   label: 'Ticket Template Fields',
        //   show_label: false,
        //   type: 'list_exp',
        //   related_entity: 'ticket_template_fields',
        //   columns: ['name', 'type', 'actions'],
        //   filter: [
        //     {
        //       condition: 'is_active = true',
        //     },
        //   ],
        // },
        {
          name: 'ticket_template_tabs_view',
          label: 'Ticket Template Fields',
          show_label: false,
          type: 'tab_view',
          related_entity: 'ticket_template_fields',
          filter: [],
          views: [
            {
              name: 'fieldsetting_tab',
              label: 'Field Settings',
              show_label: false,
              type: 'container',
              filter: [],
              views: [
                {
                  name: 'field_search_container',
                  type: 'container',
                  elements: [
                    {
                      name: 'search_fieldsetting_tab',
                      type: 'input',
                      placeholder: 'Search for a field, type...',
                    },
                  ],
                  actions: [
                    {
                      name: 'add_field',
                      label: 'Add Field',
                      show_label: true,
                      icon: 'add.ico',
                      is_button: true,
                      action_type: 'get',
                      permission: 'edit',
                      view: 'details_view',
                      target_type: 'popup',
                      type: 'button',
                      style: { borderColor: '#2563EB' },
                    },
                    {
                      name: 'configure_view',
                      label: 'Configure the View',
                      show_label: true,
                      icon: 'config.ico',
                      is_button: true,
                      action_type: 'get',
                      permission: 'edit',
                      view: 'details_view',
                      target_type: 'navigate',
                      type: 'button',
                      is_modal_footer: true,
                      style: {
                        borderColor: 'rgb(0, 0, 0)',
                        color: 'rgb(0, 0, 0)',
                      },
                    },
                  ],
                  view_layout: {
                    width: '100%',
                    no_of_cols: 12,
                    rows: [
                      {
                        i: 'search_fieldsetting_tab',
                        w: 10,
                        h: 1,
                      },
                      {
                        i: 'add_field',
                        w: 1,
                        h: 1,
                      },
                      {
                        i: 'configure_view',
                        w: 1,
                        h: 1,
                      },
                    ],
                  },
                },
                {
                  name: 'ticket_template_fields_list_view',
                  label: 'Ticket Template Fields',
                  show_label: false,
                  type: 'list_exp',
                  apiReq: {
                    reqCols: ['fields'],
                    orderBy: 'created_on asc',
                    endPoint: 'templates/list',
                    isSingle: true,
                    filter: 'id',
                  },
                  // condition: {
                  //   stateName: 'ticket_template_fields_list_view',
                  //   operator: '=',
                  //   stateValue: 0,
                  // },
                  related_entity: 'ticket_template_fields',
                  columns: [
                    {
                      col_name: 'label',
                      type: 'string',
                    },
                    {
                      col_name: 'slug',
                      type: 'string',
                    },
                    {
                      col_name: 'actions',
                      type: 'actions',
                    },
                  ],
                  filter: [
                    {
                      condition: 'is_active = true',
                    },
                  ],
                  actions: [
                    {
                      name: 'edit_fields',
                      icon: 'editIcon',
                      type: 'button',
                      target_type: 'popup',
                      target_modal_id: 'edit',
                    },
                    {
                      name: 'delete_fields',
                      icon: 'deleteIcon',
                      type: 'button',
                      target_type: 'popup',
                      target_modal_id: 'delete',
                    },
                  ],
                },
                {
                  name: 'empty_fields_screen',
                  label: 'No Fields Screen',
                  type: 'empty_screen',
                  icon: 'noFieldsIcon',
                  title: 'You have not added a field in the template',
                  subTitle: 'Click ‘Add New Field’ to get started',
                  btnText: 'Add a Field',
                  target_modal_id: 'addFieldsModal',
                  condition: {
                    stateName: 'ticket_template_fields_list_view',
                    operator: '=',
                    stateValue: 0,
                  },
                },
              ],
              view_layout: {
                width: '100%',
                no_of_cols: 12,
                rows: [
                  {
                    i: 'field_search_container',
                    w: 12,
                    h: 1,
                  },
                  {
                    i: 'ticket_template_fields_list_view',
                    w: 12,
                    h: 1,
                  },
                  {
                    i: 'empty_fields_screen',
                    w: 12,
                    h: 1,
                  },
                ],
              },
            },

            // role settings
            {
              name: 'role_settings_tab',
              label: 'Role Settings',
              show_label: false,
              type: 'container',
              filter: [],
              elements: [
                {
                  name: 'search_fieldsetting_tab1',
                  type: 'input',
                  label: 'Search for a field, type...',
                },
              ],
              view_layout: {
                width: '100%',
                no_of_cols: 12,
                rows: [
                  {
                    i: 'search_fieldsetting_tab1',
                    w: 10,
                    h: 1,
                  },
                ],
              },
            },

            // field defaults
            // {
            //   name: 'field_defaults_tab',
            //   label: 'Ticket Template Fields Default Settings Tab',
            //   show_label: false,
            //   type: 'container',
            //   filter: [],
            //   views: [
            //     {
            //       name: 'field_search_container',
            //       type: 'container',
            //       views: [
            //         {
            //           name: 'search_fieldsetting_tab',
            //           type: 'search',
            //         },
            //       ],
            //       actions: [
            //         {
            //           name: 'add_field',
            //           label: 'Add Field',
            //           show_label: true,
            //           icon: 'edit.ico',
            //           is_button: true,
            //           action_type: 'get',
            //           permission: 'edit',
            //           view: 'details_view',
            //           target_type: 'popup',
            //           type: 'button',
            //         },
            //         {
            //           name: 'congigure_view',
            //           label: 'Configure the View',
            //           show_label: true,
            //           icon: 'edit.ico',
            //           is_button: true,
            //           action_type: 'get',
            //           permission: 'edit',
            //           view: 'details_view',
            //           target_type: 'navigate',
            //           type: 'button',
            //           is_modal_footer: true,
            //         },
            //       ],
            //       view_layout: {
            //         width: '100%',
            //         no_of_cols: 12,
            //         rows: [
            //           {
            //             i: 'search_fieldsetting_tab',
            //             w: 8,
            //             h: 1,
            //           },
            //           {
            //             i: 'add_field',
            //             w: 2,
            //             h: 1,
            //           },
            //           {
            //             i: 'configure_view',
            //             w: 2,
            //           },
            //         ],
            //       },
            //     },
            //     {
            //       name: 'ticket_template_fields_list_view',
            //       label: 'Ticket Template Fields',
            //       show_label: false,
            //       type: 'list_exp',
            //       related_entity: 'ticket_template_fields',
            //       columns: ['name', 'type', 'actions'],
            //       filter: [],
            //     },
            //   ],
            //   view_layout: {
            //     width: '100%',
            //     no_of_cols: 12,
            //     rows: [
            //       {
            //         i: 'leftTop_view',
            //         w: 12,
            //         h: 1,
            //       },
            //       {
            //         i: 'ticket_template_fields_list_view',
            //         w: 12,
            //         h: 1,
            //       },
            //     ],
            //   },
            // },
          ],
        },
      ],
      view_layout: {
        width: '100%',
        no_of_cols: 12,
        rows: [
          {
            i: 'leftTop_view',
            w: 12,
            h: 1,
          },
          // {
          //   i: 'ticket_template_fields_list_view',
          //   w: 12,
          //   h: 1,
          // },
          {
            i: 'ticket_template_tabs_view',
            w: 12,
            h: 1,
          },
        ],
      },
    },

    {
      name: 'empty_template_view',
      label: 'No Template Screen',
      type: 'empty_screen',
      icon: 'noTemplateIcon',
      title: 'You have not created a template',
      subTitle: 'Click ‘Create New Template’ to get started',
      btnText: 'Create New Template',
      target_modal_id: 'create_template_modal',
      condition: {
        stateName: 'ticket_template_list_short_view',
        operator: '>',
        stateValue: 0,
      },
    },
    {
      name: 'role_settings_section',
      label: 'Role Settings',
      type: 'multi_select_tags',

      elements: [
        {
          name: 'viewers',
          type: 'text',
          value: 'Viewers',
          style: { fontWeight: '600' },
        },
        {
          name: 'viewers_select',
          type: 'select',
          options: [
            { label: 'viewer_1', value: 'viewer_1' },
            { label: 'viewer_2', value: 'viewer_2' },
            { label: 'viewer_3', value: 'viewer_3' },
            { label: 'viewer_4', value: 'viewer_4' },
          ],
          value: 'viewer',
        },
        {
          name: 'anyone_text',
          type: 'text',
          value: 'Anyone logged in from',
          style: { textAlign: 'center' },
        },
        {
          name: 'viewer_input',
          type: 'input',
          value: 'viewInput',
        },
        {
          name: 'editors',
          type: 'text',
          value: 'Editors',
          style: { fontWeight: '600' },
        },

        {
          name: 'editors_select',
          type: 'select',
          options: [
            { label: 'editor_1', value: 'editor_1' },
            { label: 'editor_2', value: 'editor_2' },
            { label: 'editor_2', value: 'editor_2' },
            { label: 'editor_3', value: 'editor_3' },
          ],
          value: 'editor',
        },
        {
          name: 'editor_subcategory',
          type: 'select',
          options: [
            { label: 'editor_sub_1', value: 'editor_sub_1' },
            { label: 'editor_sub_2', value: 'editor_sub_2' },
            { label: 'editor_sub_2', value: 'editor_sub_2' },
            { label: 'editor_sub_3', value: 'editor_sub_3' },
          ],
          value: 'editor_subcategory',
        },
        {
          name: 'expressions',
          type: 'text',
          value: 'Expressions',
          style: { fontWeight: '600' },
        },

        {
          name: 'expression_input_left',
          type: 'input',
          value: 'leftOperand',
        },
        {
          name: 'expression_select',
          type: 'select',
          options: [
            { label: '===', value: '===' },
            { label: '!==', value: '!==' },
            { label: '>=', value: '>=' },
          ],
          value: 'operator',
        },
        {
          name: 'expression_input_right',
          type: 'input',
          value: 'rightOperand',
        },
        {
          name: 'editors_div',
          type: 'multi_select_div',
          value: 'editors',
          target_state_key: 'role_settings_tab', /// state were data will be accessed , default: parentName
          // condition: { stateName: 'templateName', stateValue: 'abc' },
        },
        {
          name: 'viewers_div',
          type: 'multi_select_div',
          value: 'viewers',
          target_state_key: 'role_settings_tab', /// state were data will be accessed , default: parentName
          // condition: { stateName: 'templateName', stateValue: 'abc' },
        },
        {
          name: 'expression_div',
          type: 'expression_div',
          value: 'expression',
          target_state_key: 'role_settings_tab', /// state were data will be accessed , default: parentName
          lhs_key: 'leftOperand',
          op_key: 'operator',
          rhs_key: 'rightOperand',
          // condition: { stateName: 'templateName', stateValue: 'abc' },
        },
      ],
      actions: [
        {
          name: 'add_viewer',
          label: 'Add',
          is_button: true,
          type: 'button',
          target_key: 'viewers',
          target_obj_key: 'viewer',
          target_val_key: 'viewInput',
          target_state_key: 'role_settings_tab', ///  state were data will be stored, default: parentName
        },
        {
          name: 'add_editor',
          label: 'Add',
          is_button: true,
          type: 'button',
          target_key: 'editors',
          target_obj_key: 'editor',
          target_val_key: 'editor_subcategory',
          target_state_key: 'role_settings_tab', ///  state were data will be stored, default: parentName
        },
        {
          name: 'add_expression',
          label: 'Add',
          is_button: true,
          type: 'button',
          target_key: 'expression',
          lhs: 'leftOperand',
          op: 'operator',
          rhs: 'rightOperand',
          target_state_key: 'role_settings_tab', ///  state were data will be stored, default: parentName
        },
      ],
      view_layout: {
        width: '40%',
        no_of_cols: 12,
        rows: [
          // {
          //   i: 'option_popup',
          //   w: 10,
          //   h: 1,
          // },

          {
            i: 'viewers',
            w: 12,
            h: 1,
          },
          {
            i: 'viewers_select',
            w: 3,
            h: 1,
          },
          {
            i: 'anyone_text',
            w: 3,
            h: 1,
          },
          {
            i: 'viewer_input',
            w: 4,
            h: 1,
          },
          {
            i: 'add_viewer',
            w: 1,
            h: 1,
          },
          {
            i: 'viewers_div',
            w: 12,
            h: 1,
          },
          {
            i: 'editors',
            w: 12,
            h: 1,
          },
          {
            i: 'editors_select',
            w: 3,
            h: 1,
          },
          {
            i: 'editor_subcategory',
            w: 7,
            h: 1,
          },
          {
            i: 'add_editor',
            w: 1,
            h: 1,
          },
          {
            i: 'editors_div',
            w: 12,
            h: 1,
          },
          {
            i: 'expressions',
            w: 12,
            h: 1,
          },
          {
            i: 'expression_input_left',
            w: 3,
            h: 1,
          },
          {
            i: 'expression_select',
            w: 4,
            h: 1,
          },
          {
            i: 'expression_input_right',
            w: 3,
            h: 1,
          },

          {
            i: 'add_expression',
            w: 1,
            h: 1,
          },
          {
            i: 'expression_div',
            w: 12,
            h: 1,
          },
        ],
      },
    },
  ],

  view_layout: {
    width: '100%',
    no_of_cols: 12,
    rows: [
      {
        i: 'ticket_template_sidebar',
        x: 0,
        y: 0,
        w: 2,
        h: 1,
      },
      {
        i: 'empty_template_view',
        x: 0,
        y: 1,
        w: 10,
        maxH: 'Infinity',
      },
      {
        i: 'template_details_view',
        x: 0,
        y: 1,
        w: 10,
        maxH: 'Infinity',
      },
      {
        i: 'role_settings_section',
        x: 0,
        y: 1,
        w: 10,
        maxH: 'Infinity',
      },
    ],
  },
};

export const nestedListDummmy = [
  {
    id: 'c283d95d-d8c2-43ab-9678-9d3517f69de7',
    version: 'v1',
    hasChildren: true,
    name: ' Template',
    permission_id: '6b66257d-a3d4-4fd2-bc06-f5d6e26cfa3f',
    workflow_id: 'f4a7aaec-c5fb-4e38-b4de-a2832d8396af',
    status: 'DRAFT',
    parent_id: null,
    previous_version_id: null,
    cloned_from: null,
    is_default: false,
    tenant_id: 'dd1d4fb5-856c-43f3-bd2c-8cbba1673ab1',
    group_id: null,
    collabrators: null,
    is_marked_favourite: false,
    created_by: 'ef3f5c3a-5769-4c34-a40e-f06b5fef15c1',
    updated_by: 'ef3f5c3a-5769-4c34-a40e-f06b5fef15c1',
    created_on: '2023-03-13T18:30:00.000Z',
    updated_on: '2023-03-13T18:30:00.000Z',
    children: [
      {
        id: '61199b06-c48c-4676-b33a-35e7b1dd7d43',
        version: 'v1',
        name: 'Onboarding Template',
        permission_id: '6b66257d-a3d4-4fd2-bc06-f5d6e26cfa3f',
        workflow_id: 'f4a7aaec-c5fb-4e38-b4de-a2832d8396af',
        status: 'DRAFT',
        parent_id: null,
        previous_version_id: null,
        cloned_from: null,
        is_default: false,
        tenant_id: 'dd1d4fb5-856c-43f3-bd2c-8cbba1673ab1',
        group_id: null,
        collabrators: null,
        is_marked_favourite: false,
        created_by: 'ef3f5c3a-5769-4c34-a40e-f06b5fef15c1',
        updated_by: 'ef3f5c3a-5769-4c34-a40e-f06b5fef15c1',
        created_on: '2023-03-13T13:00:00.000Z',
        updated_on: '2023-03-13T13:00:00.000Z',
      },
      {
        id: '61199b06-c48c-4676-b33a-35e7b1dd7d43',
        version: 'v1',
        name: 'Onboarding Template',
        permission_id: '6b66257d-a3d4-4fd2-bc06-f5d6e26cfa3f',
        workflow_id: 'f4a7aaec-c5fb-4e38-b4de-a2832d8396af',
        status: 'DRAFT',
        parent_id: null,
        previous_version_id: null,
        cloned_from: null,
        is_default: false,
        tenant_id: 'dd1d4fb5-856c-43f3-bd2c-8cbba1673ab1',
        group_id: null,
        collabrators: null,
        is_marked_favourite: false,
        created_by: 'ef3f5c3a-5769-4c34-a40e-f06b5fef15c1',
        updated_by: 'ef3f5c3a-5769-4c34-a40e-f06b5fef15c1',
        created_on: '2023-03-13T13:00:00.000Z',
        updated_on: '2023-03-13T13:00:00.000Z',
      },
    ],
  },
  {
    id: '61199b06-c48c-4676-b33a-35e7b1dd7d43',
    version: 'v1',
    name: 'Onboarding Template',
    permission_id: '6b66257d-a3d4-4fd2-bc06-f5d6e26cfa3f',
    workflow_id: 'f4a7aaec-c5fb-4e38-b4de-a2832d8396af',
    status: 'DRAFT',
    parent_id: null,
    previous_version_id: null,
    cloned_from: null,
    is_default: false,
    tenant_id: 'dd1d4fb5-856c-43f3-bd2c-8cbba1673ab1',
    group_id: null,
    collabrators: null,
    is_marked_favourite: false,
    created_by: 'ef3f5c3a-5769-4c34-a40e-f06b5fef15c1',
    updated_by: 'ef3f5c3a-5769-4c34-a40e-f06b5fef15c1',
    created_on: '2023-03-13T13:00:00.000Z',
    updated_on: '2023-03-13T13:00:00.000Z',
  },
  {
    id: 'b4715a8b-ba42-407c-876a-28ef693f897e',
    version: 'v1',
    name: 'Request Template',
    permission_id: '6b66257d-a3d4-4fd2-bc06-f5d6e26cfa3f',
    workflow_id: 'f4a7aaec-c5fb-4e38-b4de-a2832d8396af',
    status: 'DRAFT',
    parent_id: '61199b06-c48c-4676-b33a-35e7b1dd7d43',
    previous_version_id: null,
    cloned_from: null,
    is_default: false,
    tenant_id: 'dd1d4fb5-856c-43f3-bd2c-8cbba1673ab1',
    group_id: null,
    collabrators: null,
    is_marked_favourite: false,
    created_by: 'ef3f5c3a-5769-4c34-a40e-f06b5fef15c1',
    updated_by: 'ef3f5c3a-5769-4c34-a40e-f06b5fef15c1',
    created_on: '2023-03-13T13:00:00.000Z',
    updated_on: '2023-03-13T13:00:00.000Z',
  },
];

export const cardJson = {
  name: 'card_layout',
  label: 'Card Details',
  show_label: false,
  type: 'container',
  entity: 'card_details',

  views: [
    {
      name: 'card_list_view',
      label: 'Card view',
      show_label: false,
      type: 'card_view',
      elements: [
        {
          name: 'viewers',
          type: 'text',
          value: 'Viewers',
        },

        {
          name: 'ticket_template_tabs_view',
          label: 'Ticket Template Fields',
          show_label: false,
          type: 'tab_view',
          related_entity: 'ticket_template_fields',
          filter: [],
          views: [
            {
              name: 'fieldsetting_tab',
              label: 'Field Settings',
              show_label: false,
              type: 'container',
              filter: [],
              views: [
                {
                  name: 'field_search_container',
                  type: 'container',
                  views: [
                    // {
                    //   name: 'search_fieldsetting_tab',
                    //   label: 'Search for a field, type...',
                    //   type: 'search',
                    // },
                  ],
                  elements: [
                    {
                      name: 'search_fieldsetting_tab',
                      type: 'input',
                      label: 'Search for a field, type...',
                    },
                  ],
                  actions: [
                    {
                      name: 'add_field',
                      label: 'Add Field',
                      show_label: true,
                      icon: 'add.ico',
                      is_button: true,
                      action_type: 'get',
                      permission: 'edit',
                      view: 'details_view',
                      target_type: 'popup',
                      type: 'button',
                      style: { borderColor: '#2563EB' },
                    },
                    {
                      name: 'configure_view',
                      label: 'Configure the View',
                      show_label: true,
                      icon: 'config.ico',
                      is_button: true,
                      action_type: 'get',
                      permission: 'edit',
                      view: 'details_view',
                      target_type: 'navigate',
                      type: 'button',
                      is_modal_footer: true,
                      style: {
                        borderColor: 'rgb(0, 0, 0)',
                        color: 'rgb(0, 0, 0)',
                      },
                    },
                  ],
                  view_layout: {
                    width: '100%',
                    no_of_cols: 12,
                    rows: [
                      {
                        i: 'search_fieldsetting_tab',
                        w: 10,
                        h: 1,
                      },
                      {
                        i: 'add_field',
                        w: 1,
                        h: 1,
                      },
                      {
                        i: 'configure_view',
                        w: 1,
                        h: 1,
                      },
                    ],
                  },
                },
                {
                  name: 'ticket_template_fields_list_view',
                  label: 'Ticket Template Fields',
                  show_label: false,
                  type: 'list_exp',
                  related_entity: 'ticket_template_fields',
                  columns: [
                    {
                      col_name: 'name',
                      type: 'string',
                    },
                    {
                      col_name: 'type',
                      type: 'string',
                    },
                    {
                      col_name: 'actions',
                      type: 'actions',
                    },
                  ],
                  filter: [
                    {
                      condition: 'is_active = true',
                    },
                  ],
                  actions: [
                    {
                      name: 'edit_fields',
                      icon: 'editIcon',
                      type: 'button',
                      target_type: 'popup',
                      target_modal_id: 'edit',
                    },
                    {
                      name: 'delete_fields',
                      icon: 'deleteIcon',
                      type: 'button',
                      target_type: 'popup',
                      target_modal_id: 'delete',
                    },
                  ],
                },
              ],
              view_layout: {
                width: '100%',
                no_of_cols: 12,
                rows: [
                  {
                    i: 'fieldsetting_tab',
                    w: 12,
                    h: 1,
                  },
                  {
                    i: 'field_search_container',
                    w: 12,
                    h: 1,
                  },
                  {
                    i: 'ticket_template_fields_list_view',
                    w: 12,
                    h: 1,
                  },
                ],
              },
            },

            // role settings
            {
              name: 'role_settings_tab',
              label: 'Role Settings',
              show_label: false,
              type: 'container',
              filter: [],
            },

            // field defaults
            // {
            //   name: 'field_defaults_tab',
            //   label: 'Ticket Template Fields Default Settings Tab',
            //   show_label: false,
            //   type: 'container',
            //   filter: [],
            //   views: [
            //     {
            //       name: 'field_search_container',
            //       type: 'container',
            //       views: [
            //         {
            //           name: 'search_fieldsetting_tab',
            //           type: 'search',
            //         },
            //       ],
            //       actions: [
            //         {
            //           name: 'add_field',
            //           label: 'Add Field',
            //           show_label: true,
            //           icon: 'edit.ico',
            //           is_button: true,
            //           action_type: 'get',
            //           permission: 'edit',
            //           view: 'details_view',
            //           target_type: 'popup',
            //           type: 'button',
            //         },
            //         {
            //           name: 'congigure_view',
            //           label: 'Configure the View',
            //           show_label: true,
            //           icon: 'edit.ico',
            //           is_button: true,
            //           action_type: 'get',
            //           permission: 'edit',
            //           view: 'details_view',
            //           target_type: 'navigate',
            //           type: 'button',
            //           is_modal_footer: true,
            //         },
            //       ],
            //       view_layout: {
            //         width: '100%',
            //         no_of_cols: 12,
            //         rows: [
            //           {
            //             i: 'search_fieldsetting_tab',
            //             w: 8,
            //             h: 1,
            //           },
            //           {
            //             i: 'add_field',
            //             w: 2,
            //             h: 1,
            //           },
            //           {
            //             i: 'configure_view',
            //             w: 2,
            //           },
            //         ],
            //       },
            //     },
            //     {
            //       name: 'ticket_template_fields_list_view',
            //       label: 'Ticket Template Fields',
            //       show_label: false,
            //       type: 'list_exp',
            //       related_entity: 'ticket_template_fields',
            //       columns: ['name', 'type', 'actions'],
            //       filter: [],
            //     },
            //   ],
            //   view_layout: {
            //     width: '100%',
            //     no_of_cols: 12,
            //     rows: [
            //       {
            //         i: 'leftTop_view',
            //         w: 12,
            //         h: 1,
            //       },
            //       {
            //         i: 'ticket_template_fields_list_view',
            //         w: 12,
            //         h: 1,
            //       },
            //     ],
            //   },
            // },
          ],
          value: 'viewer',
        },
        {
          name: 'anyone_text',
          type: 'text',
          value: 'Anyone logged in from',
        },
        {
          name: 'viewer_input',
          type: 'input',
          value: 'viewInput',
        },
        {
          name: 'editors',
          type: 'text',
          value: 'Editors',
        },

        {
          name: 'editors_select',
          type: 'select',
          options: [{ label: 'one', value: 'one' }],
          value: 'editor',
        },
        {
          name: 'editor_subcategory',
          type: 'select',
          options: [{ label: 'two', value: 'two' }],
          value: 'editor_subcategory',
        },
        {
          name: 'expressions',
          type: 'text',
          value: 'Expressions',
        },
      ],
    },
    {
      name: 'role_settings_tab',
      label: 'Role Settings',
      type: 'multi_select_tags',

      elements: [
        {
          name: 'viewers',
          type: 'text',
          value: 'Viewers',
        },

        {
          name: 'viewers_select',
          type: 'select',
          options: [
            { label: 'viewer_1', value: 'viewer_1' },
            { label: 'viewer_2', value: 'viewer_2' },
            { label: 'viewer_2', value: 'viewer_2' },
            { label: 'viewer_3', value: 'viewer_3' },
          ],
          value: 'viewer',
        },
        {
          name: 'anyone_text',
          type: 'text',
          value: 'Anyone logged in from',
          style: { textAlign: 'center' },
        },
        {
          name: 'viewer_input',
          type: 'input',
          value: 'viewInput',
        },
        {
          name: 'editors',
          type: 'text',
          value: 'Editors',
        },

        {
          name: 'editors_select',
          type: 'select',
          options: [
            { label: 'editor_1', value: 'editor_1' },
            { label: 'editor_2', value: 'editor_2' },
            { label: 'editor_2', value: 'editor_2' },
            { label: 'editor_3', value: 'editor_3' },
          ],
          value: 'editor',
        },
        {
          name: 'editor_subcategory',
          type: 'select',
          options: [
            { label: 'editor_sub_1', value: 'editor_sub_1' },
            { label: 'editor_sub_2', value: 'editor_sub_2' },
            { label: 'editor_sub_2', value: 'editor_sub_2' },
            { label: 'editor_sub_3', value: 'editor_sub_3' },
          ],
          value: 'editor_subcategory',
        },
        {
          name: 'expressions',
          type: 'text',
          value: 'Expressions',
        },

        {
          name: 'expression_input_left',
          type: 'input',
          value: 'expInput1',
        },
        {
          name: 'expression_select',
          type: 'select',
          options: [
            { label: '===', value: '===' },
            { label: '!==', value: '!==' },
            { label: '>=', value: '>=' },
          ],
          value: 'operator',
        },
        {
          name: 'expression_input_right',
          type: 'input',
          value: 'expInput2',
        },
        {
          name: 'viewers_editors_div',
          type: 'multi_select_div',
          value: 'viewer_combined',
          // condition: { stateName: 'templateName', stateValue: 'abc' },
        },
        {
          name: 'expression_div',
          type: 'expression_div',
          value: 'expression_combined',
          // condition: { stateName: 'templateName', stateValue: 'abc' },
        },
      ],
      actions: [
        {
          name: 'add_viewer',
          label: 'Add',
          is_button: true,
          type: 'button',
          target_key: 'viewer_combined',
          target_obj_key: 'viewer',
          target_val_key: 'viewInput',
        },
        {
          name: 'add_editor',
          label: 'Add',
          is_button: true,
          type: 'button',
          target_key: 'viewer_combined',
          target_obj_key: 'editor',
          target_val_key: 'editor_subcategory',
        },
        {
          name: 'add_expression',
          label: 'Add',
          is_button: true,
          type: 'button',
          target_key: 'expression_combined',
          lhs: 'expInput1',
          op: 'operator',
          rhs: 'expInput2',
        },
      ],
      view_layout: {
        width: '40%',
        no_of_cols: 12,
        rows: [
          // {
          //   i: 'option_popup',
          //   w: 10,
          //   h: 1,
          // },

          {
            i: 'viewers',
            w: 12,
            h: 1,
          },
          {
            i: 'viewers_select',
            w: 3,
            h: 1,
          },
          {
            i: 'anyone_text',
            w: 3,
            h: 1,
          },
          {
            i: 'viewer_input',
            w: 4,
            h: 1,
          },
          {
            i: 'add_viewer',
            w: 2,
            h: 1,
          },
          {
            i: 'editors',
            w: 12,
            h: 1,
          },
          {
            i: 'editors_select',
            w: 3,
            h: 1,
          },
          {
            i: 'editor_subcategory',
            w: 7,
            h: 1,
          },
          {
            i: 'add_editor',
            w: 2,
            h: 1,
          },
          {
            i: 'viewers_editors_div',
            w: 12,
            h: 1,
          },
          {
            i: 'expressions',
            w: 12,
            h: 1,
          },
          {
            i: 'expression_input_left',
            w: 3,
            h: 1,
          },
          {
            i: 'expression_select',
            w: 4,
            h: 1,
          },
          {
            i: 'expression_input_right',
            w: 3,
            h: 1,
          },

          {
            i: 'add_expression',
            w: 2,
            h: 1,
          },
          {
            i: 'expression_div',
            w: 12,
            h: 1,
          },
        ],
      },
    },
  ],

  view_layout: {
    width: '100%',
    no_of_cols: 12,
    rows: [
      {
        i: 'ticket_template_sidebar',
        x: 0,
        y: 0,
        w: 2,
        h: 1,
      },
      {
        i: 'template_details_view',
        x: 0,
        y: 1,
        w: 10,
        maxH: 'Infinity',
      },
      {
        i: 'fieldset_details_view',
        x: 0,
        y: 1,
        w: 10,
        maxH: 'Infinity',
      },
      {
        i: 'role_settings_tab',
        x: 0,
        y: 1,
        w: 10,
        maxH: 'Infinity',
      },
    ],
  },
};

// for dynamic_dropdown

// {
//   name: 'actor_description',
//   type: 'dynamic_dropdown',
//   value: 'description',
//   inputType: 'string',
//   label: 'Description',
//   isMandatory: false,
//   labelKey: 'label',
//   valueKey: 'value',
//   optionsUrl:
//     'https://run.mocky.io/v3/e899b855-828c-4c8b-9469-3ab463f8e59f',
// },
