/* eslint-disable import/prefer-default-export */
export const REDUX_TICKET_TEMPLATE_KEYS = {
  FIELD_LIST: 'ticket_template_fields_list_view',
  ACTORS_LIST: 'ticket_template_actors_list_view',
  WORKFLOW: 'ticket_template_workflow',
  METRIC: 'ticket_template_metric',
  SLAS: 'ticket_template_slas',
  DEFAULT_VALUES: 'ticket_template_default_values',
  LOCALES: 'ticket_template_locales',
  FILTERS: 'ticket_template_filters',
  AUDIENCE: 'ticket_template_audience',
};
