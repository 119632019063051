import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios/axios';
import { removeCard } from './AllCardSlice';

const initialState = {
  cards: [],
  isLoading: false,
  error: null,
};

export const deleteCard = createAsyncThunk(
  'deleteCard',
  async (cardId, { dispatch }) => {
    try {
      await axiosInstance.delete(`templates/delete?$filter=id eq ${cardId}`);
      dispatch(removeCard(cardId));
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

const deleteCardSlice = createSlice({
  name: 'deleteCard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(deleteCard.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(deleteCard.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export default deleteCardSlice.reducer;
