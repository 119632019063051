import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { LIGHT } from '../../utils/enums/colors';

const CountShow = ({ value, onChange }) => {
  const handleDecrement = () => {
    if (value > 0) {
      onChange(value - 1);
    }
  };

  const handleIncrement = () => {
    if (value >= 12) {
      return;
    }
    onChange(value + 1);
  };
  return (
    <Flex spacing={4}>
      <Box
        width="26px"
        height="20px"
        borderRadius="50%"
        background={LIGHT}
        onClick={handleDecrement}
        display="flex"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
      >
        <MinusIcon color="white" width="13px" />
      </Box>
      <Flex align="center" fontSize="14px" pl="4px" pr="4px">
        {value || 0}
      </Flex>
      <Box
        width="26px"
        height="20px"
        borderRadius="50%"
        background={LIGHT}
        onClick={handleIncrement}
        display="flex"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
      >
        <AddIcon color="white" width="13px" />
      </Box>
    </Flex>
  );
};

CountShow.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CountShow;
