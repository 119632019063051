import React, { useState } from 'react';
import { ReactFlowProvider } from 'reactflow';
import CreateEditFlow from '../CreateEditFlow';
import CustomModal from '../../../components/Modal/Modal';

const FlowWithProvider = (props) => {
  const [openFullScreenWorkflow, setOpenFullScreenWorkFlow] = useState(false)
  return(
  <ReactFlowProvider>
    {!openFullScreenWorkflow ?
    <CreateEditFlow {...props} openFullScreenWorkflow={openFullScreenWorkflow} setOpenFullScreenWorkFlow={setOpenFullScreenWorkFlow} /> : null}
   {openFullScreenWorkflow && <CustomModal 
        size='l'
        open={openFullScreenWorkflow}
        setOpen={setOpenFullScreenWorkFlow}
        modalContent={<CreateEditFlow {...props} openFullScreenWorkflow={openFullScreenWorkflow} setOpenFullScreenWorkFlow={setOpenFullScreenWorkFlow} />}
        scrollOverflowFlag={false}
        showModalCloseButton={true}
        modalContentClassName='!mt-0 !mb-0 !max-w-[95%] !h-[95%]'
      />}
  </ReactFlowProvider>)
  }

export default FlowWithProvider;
