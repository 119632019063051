/* eslint-disable no-nested-ternary */
import React from 'react';

// import { useColorModeValue } from '@chakra-ui/react';
// import { TriangleUpIcon } from '@chakra-ui/icons';
// import CustomButton from '../../../components/Button/SimpleButton';

// import axiosInstance from '../../../utils/axios/axios';
import {
  FIELD_SLUG,
  FIELD_VALIDATE_KEY,
  LABELS,
  // SUB_LABELS,
} from '../../../utils/enums/labels';
// import { DARK, LIGHT } from '../../../utils/enums/colors';
// import { RenderTypes } from '../../../utils/enums/types';
import { BASE_SETTINGS_KEYS, ADVANCED_SETTINGS_KEYS } from './constants';
import {
  renderFieldLabels,
  renderMaxLengthField,
  renderMinLengthField,
} from './renderFields';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { getWholeOption } from '../../TicketTemplateWorkflow/helper';
import customStylesSelectWithHeight40 from '../../../context/CustomTheme/CustomStylesSelectWithHeight40';
import { TAG_TYPES } from '../../TenantFilters/filters.constants';

export const BasicTagSetting = ({
  data,
  setFields,
  setIsError,
  collectionData,
  setCollectionData,
  existingFields,
}) => ({
  row1: [
    renderFieldLabels({
      data,
      setFields,
      setIsError,
      fieldSlug: FIELD_SLUG.FIELD_TAG,
      existingFields,
    }),
    {
      label: LABELS.FIELD_PROPERTIES.NAME,
      value: `${data?.[BASE_SETTINGS_KEYS.SLUG] || ''}`,
      placeholder: '',
      required: true,
      key: FIELD_VALIDATE_KEY.SLUG,
      errorString: LABELS.ERROR.NAME,
      isReadOnly: true,
      valueKey: BASE_SETTINGS_KEYS.SLUG,
      onChange: (value) => {
        setFields((pre) => ({
          ...pre,
          [BASE_SETTINGS_KEYS.SLUG]: value,
          [BASE_SETTINGS_KEYS.IS_EDIT_SLUG]: true,
        }));
        setIsError((pre) => ({ ...pre, [BASE_SETTINGS_KEYS.SLUG]: !value }));
      },
    },
  ],
  row2: [
    {
      label: LABELS.FIELD_PROPERTIES.DESCRIPTION,
      value: data?.[BASE_SETTINGS_KEYS.DESCRIPTION],
      placeholder: '',
      valueKey: BASE_SETTINGS_KEYS.DESCRIPTION,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [BASE_SETTINGS_KEYS.DESCRIPTION]: value,
        })),
    },
  ],
  row7: [
    {
      label: LABELS.FIELD_PROPERTIES.DEFAULT_VALUE,
      value: `${collectionData?.[BASE_SETTINGS_KEYS.STATIC_VALUES] || ''}`,
      valueKey: BASE_SETTINGS_KEYS.STATIC_VALUES,
      onChange: (value) =>
        setCollectionData({
          ...collectionData,
          [BASE_SETTINGS_KEYS.STATIC_VALUES]: value,
        }),
      render: (newData) => {
        const localOptions = newData?.[BASE_SETTINGS_KEYS.STATIC_VALUES]
          ? typeof newData[BASE_SETTINGS_KEYS.STATIC_VALUES] === 'string'
            ? [...newData[BASE_SETTINGS_KEYS.STATIC_VALUES].split('\n')].map(
              (value) => ({
                value,
                label: value,
              })
            )
            : newData[BASE_SETTINGS_KEYS.STATIC_VALUES]
          : [];
        return (
          <CustomSelectBox
            placeholder="Select Value"
            options={localOptions}
            value={
              getWholeOption(
                newData?.[BASE_SETTINGS_KEYS.DEFAULT_VALUE],
                localOptions
              ) || ''
            } // {data?.[BASE_SETTINGS_KEYS.DEFAULT_VALUE] || ''}
            onChange={(option) =>
              setFields((prevState) => ({
                ...prevState,
                [BASE_SETTINGS_KEYS.DEFAULT_VALUE]: option?.value || null,
              }))
            }
            styles={customStylesSelectWithHeight40}
            isClearable
          />
        );
      },
    },
  ],
  row4: [
    {
      label: LABELS.FIELD_PROPERTIES.STATIC,
      value: TAG_TYPES.STATIC,
    },
    // {
    //   label: LABELS.FIELD_PROPERTIES.DYNAMIC,
    //   value: 'DYNAMIC',
    // },
  ],
  // row5: [
  //   {
  //     label: LABELS.FIELD_PROPERTIES.CURL,
  //     value: '',
  //     placeholder: '',
  //     render: () => (
  //       <CustomButton
  //         ml="10px"
  //         leftIcon={<TriangleUpIcon style={{ transform: 'rotate(90deg)' }} />}
  //         style={{ border: '1px solid #2563EB' }}
  //         color={useColorModeValue(LIGHT, DARK)}
  //         onClick={async () => {
  //           try {
  //             const response = await axiosInstance.post('/curl/execute', {
  //               data: {
  //                 curl: data?.[BASE_SETTINGS_KEYS.DYNAMIC_CURL] || '',
  //               },
  //             });
  //             setCollectionData({
  //               ...collectionData,
  //               [BASE_SETTINGS_KEYS.DYNAMIC_VALUES]: response.data,
  //             });
  //             setFields((pre) => ({
  //               ...pre,
  //               [BASE_SETTINGS_KEYS.DYNAMIC_VALUES]: response.data,
  //             }));
  //             // eslint-disable-next-line no-empty
  //           } catch (error) {}
  //         }}
  //         buttonText={LABELS.ACTIONS.EXECUTE}
  //         variant="outline"
  //         id="TagsSetings-edit-template-save"
  //       />
  //     ),
  //   },
  // ],

  // row6: [
  //   {
  //     label: '',
  //     value: `${data?.[BASE_SETTINGS_KEYS.DYNAMIC_CURL] || ''}`,
  //     key: FIELD_VALIDATE_KEY.DYNAMIC_CURL,
  //     valueKey: BASE_SETTINGS_KEYS.DYNAMIC_CURL,
  //     placeholder: 'Expression',
  //     renderType: RenderTypes.TEXT_AREA,
  //     errorString: LABELS.ERROR.DYNAMIC_CURL,
  //     onChange: (value) => {
  //       setFields((pre) => ({
  //         ...pre,
  //         [BASE_SETTINGS_KEYS.DYNAMIC_CURL]: value,
  //         apiEndpoint: data?.[BASE_SETTINGS_KEYS.DYNAMIC_CURL],
  //       }));
  //       setIsError((pre) => ({
  //         ...pre,
  //         [BASE_SETTINGS_KEYS.DYNAMIC_CURL]: !value,
  //       }));
  //     },
  //   },
  // ],
});
export const AdvanceTagSeeting = (data, setFields, setIsError) => ({
  row1: [
    {
      label: LABELS.FIELD_PROPERTIES.HINT_TEXT,
      value: `${data[ADVANCED_SETTINGS_KEYS.HINT_TEXT] || ''}`,
      placeholder: '',
      valueKey: ADVANCED_SETTINGS_KEYS.HINT_TEXT,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [ADVANCED_SETTINGS_KEYS.HINT_TEXT]: value,
        })),
    },
  ],
  row2: [
    renderMinLengthField({
      data,
      setFields,
      setIsError,
      label: LABELS.FIELD_PROPERTIES.MIN_NO_TAG,
    }),
    renderMaxLengthField({
      data,
      setFields,
      setIsError,
      label: LABELS.FIELD_PROPERTIES.MAX_NO_TAG,
    }),
  ],
  row3: [
    {
      label: LABELS.FIELD_PROPERTIES.REQUIRED,
      value: `${data?.[ADVANCED_SETTINGS_KEYS.REQUIRED] || ''}`,
      valueKey: ADVANCED_SETTINGS_KEYS.REQUIRED,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [ADVANCED_SETTINGS_KEYS.REQUIRED]: value,
        })),
    },
    // {
    //   label: LABELS.FIELD_PROPERTIES.READONLY,
    //   value: `${data?.[ADVANCED_SETTINGS_KEYS.READ_ONLY] || ''}`,
    //   valueKey: ADVANCED_SETTINGS_KEYS.READ_ONLY,
    //   onChange: (value) =>
    //     setFields((pre) => ({
    //       ...pre,
    //       [ADVANCED_SETTINGS_KEYS.READ_ONLY]: value,
    //     })),
    // },
    // {
    //   label: LABELS.FIELD_PROPERTIES.HIDDEN,
    //   value: `${data?.[ADVANCED_SETTINGS_KEYS.HIDDEN] || ''}`,
    //   valueKey: ADVANCED_SETTINGS_KEYS.HIDDEN,
    //   onChange: (value) =>
    //     setFields((pre) => ({
    //       ...pre,
    //       [ADVANCED_SETTINGS_KEYS.HIDDEN]: value,
    //     })),
    // },
    {
      label: LABELS.FIELD_PROPERTIES.SEARCHABLE,
      value: `${data?.[ADVANCED_SETTINGS_KEYS.SEARCHABLE] || ''}`,
      valueKey: ADVANCED_SETTINGS_KEYS.SEARCHABLE,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [ADVANCED_SETTINGS_KEYS.SEARCHABLE]: value,
        })),
    },
    {
      label: LABELS.FIELD_PROPERTIES.REPORTABLE,
      value: `${data?.[ADVANCED_SETTINGS_KEYS.REPORTABLE] || ''}`,
      valueKey: ADVANCED_SETTINGS_KEYS.REPORTABLE,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [ADVANCED_SETTINGS_KEYS.REPORTABLE]: value,
        })),
    },
  ],
});
