/* eslint-disable camelcase */
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Stack, Checkbox, Box, Flex, Input } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateViewState } from '../../../redux/ViewSlice/ViewReducer';
import { fetchAllFieldset } from '../utils/helper';
import CustomModal from '../../../components/Modal/Modal';
import { LABELS } from '../../../utils/enums/labels';
import { COLORS } from '../../../utils/enums/colors';
import CustomButton from '../../../components/Button/SimpleButton';
import { patchDataForTemplate } from '../utils/templateAPIUtils';

const AddFieldsetContent = ({ isOpen, setIsOpen, onModalClose }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const onClose = () => {
    setIsOpen(false);
    onModalClose();
  };

  const [data, setData] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const { ticket_template_fields_list_view } = useSelector(
    (state) => state.viewState
  );
  const [searchQuery, setSearchQuery] = useState('');

  const selectAllClick = () => {
    const allSelected = selectedFields.length === data.length;

    if (allSelected) {
      setSelectedFields([]);
    } else {
      setSelectedFields(data);
    }
  };

  const onChangeCheckbox = (field) => {
    const isSelected = selectedFields.find(
      (selectedField) => selectedField.id === field.id
    );

    if (isSelected) {
      const updatedFields = selectedFields.filter(
        (selectedField) => selectedField.id !== field.id
      );
      setSelectedFields(updatedFields);
    } else {
      setSelectedFields([...selectedFields, field]);
    }
  };

  // adding fields in tempalte using fieldset modal

  const addFieldsetsFields = async () => {
    const fieldsValue = selectedFields.reduce((accumulator, selectedField) => {
      if (selectedField.fields) {
        return accumulator.concat(selectedField.fields);
      }
      return accumulator;
    }, []);

    const newData = [...ticket_template_fields_list_view.data, ...fieldsValue];
    const res = await patchDataForTemplate({
      key: 'fields',
      data: newData,
      id,
      defaultErrorMessage: 'Failed to add fieldsets in the template',
      successMessage: 'Successfully added fieldsets in the template',
    });
    if (!res.error) {
      dispatch(
        updateViewState({
          stateKey: 'ticket_template_fields_list_view',
          value: {
            ...ticket_template_fields_list_view,
            data: newData,
          },
        })
      );
    }
    onClose();
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedData = await fetchAllFieldset();
        setData(fetchedData);
        // eslint-disable-next-line no-empty
      } catch (error) {}
    };

    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = data.filter((field) =>
    field.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <CustomModal
      size="sm"
      id="selected-field-modal"
      modalTitle={
        <>
          <h1 data-testid="AddFieldsetContentH1">
            {LABELS.ACTIONS.ADD_NEW_FIELD}
          </h1>
          <Box
            style={{ fontSize: '16px', color: COLORS.DEFAULT }}
            data-testid="AddFieldsetContentBox"
          >
            {LABELS.TITLE.INCIDENT_TICKET}
          </Box>
          <h4
            style={{
              fontSize: '16px',
              color: 'rgba(0, 0, 0, 0.85)',
              fontWeight: '600',
              lineHeight: '19px',
              paddingTop: '40px',
              paddingBottom: '20px',
            }}
            data-testid="AddFieldsetContentH4"
          >
            {LABELS.TITLE.SELECT_FIELDSET_TO_ADD_IN_TEMPLATE}
          </h4>
        </>
      }
      modalContent={
        <>
          <Input
            placeholder="Search for a fieldset"
            value={searchQuery}
            onChange={handleSearchChange}
            mb="20px"
            data-testid="AddFieldsetContentInput"
          />
          <Checkbox
            isChecked={selectedFields.length === data.length}
            onChange={selectAllClick}
            mb="15px"
            data-testid="AddFieldsetContentCheckbox"
          >
            Select All
          </Checkbox>
          <Stack spacing={2} data-testid="AddFieldsetContentStack">
            {filteredData.map((field) => (
              <Checkbox
                key={field.id}
                isChecked={selectedFields.find(
                  (selectedField) => selectedField.id === field.id
                )}
                onChange={() => onChangeCheckbox(field)}
                data-testid="AddFieldsetContentStackCheckbox"
              >
                {field.name}
              </Checkbox>
            ))}
          </Stack>
        </>
      }
      modalFooter={
        <Flex gap="8px" data-testid="AddFieldsetContentFlex">
          <CustomButton
            variant="outline"
            buttonColor="customBlue"
            size="lg"
            buttonText={LABELS.ACTIONS.CANCEL}
            onClick={() => setIsOpen(false)}
            id="AddFieldsetContentStackcanclBtn"
          />
          <CustomButton
            variant="solid"
            buttonColor="customBlue"
            size="lg"
            buttonText={LABELS.ACTIONS.ADD}
            onClick={addFieldsetsFields}
            id="AddFieldsetContentStackAddBtn"
          />
        </Flex>
      }
      open={isOpen}
      setOpen={setIsOpen}
      onClose={onClose}
    />
  );
};

AddFieldsetContent.propTypes = {
  onModalClose: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default AddFieldsetContent;
