import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios/axios';

// Define the API endpoints
const notificationsEndpoint = 'notifications/list?$top=100&$skip=0&$select=*';

export const fetchNotifications = createAsyncThunk(
  'notificationList/fetchNotifications',
  async () => {
    try {
      const notificationsResponse = await axiosInstance.get(
        notificationsEndpoint
      );
      return notificationsResponse;
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

const initialState = {
  notifications: [],
  status: 'idle',
  error: null,
};

const notificationList = createSlice({
  name: 'notificationList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.notifications = action.payload.data;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default notificationList.reducer;
