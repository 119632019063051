import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Tooltip } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useStoreApi } from 'reactflow';
import { WorkFlowNodeTypes } from '../../../utils/enums/types';
import {
  CAUTION_STATUS,
  DONE_STATUS,
  FIXING_STATUS,
  IN_PROGRESS_STATUS,
  TO_DO_STATUS,
} from '../../../utils/enums/colors';

const TransitionCard = ({ edge }) => {
  const flowStore = useStoreApi();
  const [sourceNode, setSourceNode] = useState(null);
  const [targetNode, setTargetNode] = useState(null);
  const { nodeInternals } = flowStore.getState();
  useEffect(() => {
    setSourceNode(nodeInternals.get(edge.source));
    setTargetNode(nodeInternals.get(edge.target));
  }, []);
  const determineBgColor = (type) => {
    if (type === WorkFlowNodeTypes.toDoNode) {
      return TO_DO_STATUS;
    }
    if (type === WorkFlowNodeTypes.toProgressNode) {
      return IN_PROGRESS_STATUS;
    }
    if (type === WorkFlowNodeTypes.toDoneNode) {
      return DONE_STATUS;
    }
    if (type === WorkFlowNodeTypes.toCautionNode) {
      return CAUTION_STATUS;
    }
    if (type === WorkFlowNodeTypes.toFixingNode) {
      return FIXING_STATUS;
    }
    return TO_DO_STATUS;
  };
  return (
    <Box
      className="shadow-lg p-[8px]"
      m="4px 0px"
      data-testid="TransitionCardBox"
    >
      <Box
        marginBottom="19px"
        className={`
          border-2
          border-solid
          border-gray-400
          rounded-full
          inline-flex
          flex-row
          items-center
          px-2
        `}
        data-testid="TransitionCardLabelBox"
      >
        {edge?.data?.label}
      </Box>
      <Box
        display="flex"
        justifyContent="space-around"
        pt="5px"
        data-testid="TransitionCardTooltipBox"
      >
        <Box
          flex={4}
          pt={2}
          pb={2}
          className="w-full flex flex-col gap-y-8 justify-center items-center"
          bgColor={determineBgColor(sourceNode?.type)}
          data-testid="TransitionCardSrcNodeBox"
        >
          <Tooltip
            hasArrow
            label={sourceNode?.data?.label}
            placement="bottom"
            data-testid="TransitionCardSrcNode"
          >
            <span
              className={`
              box-border
              appearance-none
              text-[11px]
              border-0
              inline-flex
              static
              px-4
              text-white
              bg-background-neutral-bold
              rounded-full
              ellipsis
              text-center
            `}
              data-testid="TransitionCardSrcNodeSpan"
            >
              {sourceNode?.data?.label}
            </span>
          </Tooltip>
        </Box>
        <Box
          flex={1}
          className={`
            w-full
            flex
            flex-col
            gap-y-8
            items-center
            justify-center
          `}
          data-testid="TransitionCardIconBox"
        >
          <ArrowForwardIcon />
        </Box>
        <Box
          flex={4}
          pt={2}
          pb={2}
          className="w-full flex flex-col gap-y-8 justify-center items-center"
          bgColor={determineBgColor(targetNode?.type)}
          data-testid="TransitionCardTypeBox"
        >
          <Tooltip
            hasArrow
            label={targetNode?.data?.label}
            placement="bottom"
            data-testid="TransitionCardTypeTooltip"
          >
            <span
              className={`
              box-border
              text-[11px]
              appearance-none
              border-0
              inline-flex
              static
              px-4
              text-white
              bg-background-neutral-bold
              rounded-full
              ellipsis
              text-center
            `}
              data-testid="TransitionCardTypeSpan"
            >
              {targetNode?.data?.label}
            </span>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};
TransitionCard.propTypes = {
  edge: PropTypes.object.isRequired,
};
export default TransitionCard;
