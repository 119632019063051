import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _pick from 'lodash/pick';

import TemplateLocalesTable from './TemplateLocalesTable';
import { columns } from './locales.utils';
import { fetchSelectedLanguages } from '../../../redux/CardReducer/localeSlice';
import TemplateLocaleDrawer from './TemplateLocaleDrawer';
import { patchDataForTemplate } from '../../TicketTemplateBuilder/utils/templateAPIUtils';
import { updateViewState } from '../../../redux/ViewSlice/ViewReducer';

const TemplateLocalesWrapper = () => {
  const [selectedlocaleDrawerData, setSelectedlocaleDrawerData] =
    useState(false);
  const [localesDrawer, setLocalesDrawer] = useState(false);
  const dispatch = useDispatch();
  const { selectedLanguages } = useSelector((state) => state.locales);
  const { selectedTemplate } = useSelector((state) => state.viewState);

  useEffect(() => {
    dispatch(fetchSelectedLanguages());
  }, []);

  const openModal = useCallback(
    (data) => {
      setSelectedlocaleDrawerData(data?.rowData);
      setLocalesDrawer(true);
    },
    [localesDrawer, selectedlocaleDrawerData]
  );

  const setLocales = async (list, id) => {
    if (selectedlocaleDrawerData?.locale_code) {
      let editedLocale = { ...selectedlocaleDrawerData };
      editedLocale = { ...editedLocale, translations: list };
      editedLocale = _pick(editedLocale, 'locale_code', 'name', 'translations');

      const newLocales =
        selectedTemplate.locales?.map((locale) => {
          if (locale.locale_code === editedLocale.locale_code) {
            return editedLocale;
          }
          return locale;
        }) || [];

      if (
        newLocales.findIndex(
          (locale) => locale.locale_code === editedLocale.locale_code
        ) === -1
      ) {
        newLocales.push(editedLocale);
      }

      const res = await patchDataForTemplate({
        id,
        data: newLocales,
        key: 'locales',
        defaultErrorMessage: 'Failed to save locale',
        successMessage: 'Locale Successfully saved',
      });
      if (!res.error) {
        dispatch(
          updateViewState({
            stateKey: 'selectedTemplate',
            value: res?.response?.data,
          })
        );
      }
    }
  };

  return (
    <>
      <TemplateLocalesTable
        columns={columns({
          showModal: openModal,
        })}
        tableData={selectedLanguages}
        // searchKey="name"
      />
      {localesDrawer && (
        <TemplateLocaleDrawer
          selectedTemplate={selectedTemplate}
          openDrawer={localesDrawer}
          setCloseDrawer={() => setLocalesDrawer(false)}
          saveLocales={(list, id) => setLocales(list, id)}
          title={selectedlocaleDrawerData?.name}
          code={selectedlocaleDrawerData?.locale_code}
        />
      )}
    </>
  );
};

export default TemplateLocalesWrapper;
