import Joi from 'joi';

export const PERMISSION__MAP_WITH_LABEL_KEY_LIST = [
  {
    key: 'TICKET_CREATE',
    label: 'Ticket Create'
  },
  {
    key: 'TICKET_UPDATE',
    label: 'Ticket Update'
  },
  {
    key: 'TICKET_TRANSITION',
    label: 'Ticket Transition'
  },
  {
    key: 'TICKET_MOVE',
    label: 'Ticket Move'
  },
  {
    key: 'TICKET_LINK',
    label: 'Ticket Link'
  },
  {
    key: 'TICKET_DELETE',
    label: 'Ticket Delete'
  },
  {
    key: 'CREATE_LINKED_TICKET',
    label: 'Create Linked Ticket'
  },
  {
    key: 'ADD_PUBLIC_NOTE',
    label: 'Add Public Note'
  },
  {
    key: 'ADD_PRIVATE_NOTE',
    label: 'Add Private Note'
  },
  {
    key: 'ADD_CHASE_NOTE',
    label: 'Add Chase Note'
  },
  {
    key: 'ADD_RESOLUTION_NOTE',
    label: 'Add Resolution Note'
  },
  {
    key: 'QUEUE_UPDATE',
    label: 'Queue Update'
  },
  {
    key: 'ACTOR_UPDATE',
    label: 'Actor Update'
  },
  {
    key: 'QUICK_ACTIONS',
    label: 'Quick Actions'
  },
  {
    key: 'BULK_OPERATIONS',
    label: 'Bulk Operations',
  },
  {
    key: 'BULK_DELETE',
    label: 'Bulk Delete',
  },
  {
    key: 'BULK_MOVE',
    label: 'Bulk Move'
  },
  {
    key: 'BULK_TRANSITION',
    label: 'Bulk Transition'
  },
  {
    key: 'BULK_UPDATE',
    label: 'Bulk Update'
  },
  {
    key: 'BULK_LINK',
    label: 'Bulk Link'
  },
  {
    key: 'BULK_IMPORT',
    label: 'Bulk Import'
  },
  {
    key: 'VIEW_DELETED_TICKETS',
    label: 'View Deleted Tickets'
  },
  {
    key: 'VIEW_WORKFLOW',
    label: 'View Workflow'
  },
];

export const ALL_PERMISSION_KEYS = PERMISSION__MAP_WITH_LABEL_KEY_LIST.map((item) => item.key);

export const ALL_PERMISSIONS_ALLOWED = {};
ALL_PERMISSION_KEYS.forEach((key) => {
  ALL_PERMISSIONS_ALLOWED[key] = true;
});

export const ALL_PERMISSION_DENIED = {};
ALL_PERMISSION_KEYS.forEach((key) => {
  ALL_PERMISSION_DENIED[key] = false;
});

export const MAP_PERMISSION_WITH_ROLE = {
  'Ticket Agent Supervisor': {
    ...ALL_PERMISSIONS_ALLOWED,
  },
  'Asc Admin': {
    ...ALL_PERMISSIONS_ALLOWED,
  },
  'Tenant Admin': {
    ...ALL_PERMISSIONS_ALLOWED,
  },
  'Ticket Admin': {
    ...ALL_PERMISSIONS_ALLOWED,
  },
  'Beta User': {
    ...ALL_PERMISSIONS_ALLOWED,
  },
  'Ticket Agent': {
    ...ALL_PERMISSION_DENIED,
    TICKET_CREATE: true,
    TICKET_UPDATE: true,
    TICKET_TRANSITION: true,
    ADD_PUBLIC_NOTE: true,
    ADD_RESOLUTION_NOTE: true,
    QUEUE_UPDATE: true,
    ACTOR_UPDATE: true,
    VIEW_WORKFLOW: true,
  },
  'Ticket User': {
    ...ALL_PERMISSION_DENIED,
    TICKET_CREATE: true,
    TICKET_UPDATE: true,
    ADD_PUBLIC_NOTE: true,
    VIEW_WORKFLOW: true,
  },
  'Bot Admin': {
    ...ALL_PERMISSION_DENIED,
    TICKET_CREATE: true,
    TICKET_UPDATE: true,
    TICKET_MOVE: true,
    ADD_PUBLIC_NOTE: true,
    VIEW_WORKFLOW: true,
  },
  'Bot Analyst': {
    ...ALL_PERMISSION_DENIED,
  },
  'Chat Agent': {
    ...ALL_PERMISSION_DENIED,
  },
  'Chat Agent Admin': {
    ...ALL_PERMISSION_DENIED,
  },
  'Chat Agent Supervisor': {
    ...ALL_PERMISSION_DENIED,
  },
  'User Admin': {
    ...ALL_PERMISSION_DENIED,
  },
  // eslint-disable-next-line quote-props
  'Approver': {
    ...ALL_PERMISSION_DENIED,
    TICKET_CREATE: true,
    TICKET_UPDATE: true,
    TICKET_TRANSITION: true,
    TICKET_MOVE: true,
    VIEW_WORKFLOW: true,
  },
  'Approval Supervisor': {
    ...ALL_PERMISSION_DENIED,
    TICKET_CREATE: true,
    TICKET_UPDATE: true,
    TICKET_TRANSITION: true,
    TICKET_MOVE: true,
    VIEW_WORKFLOW: true,
  },
  'Approval Admin': {
    ...ALL_PERMISSION_DENIED,
    TICKET_CREATE: true,
    TICKET_UPDATE: true,
    TICKET_TRANSITION: true,
    TICKET_MOVE: true,
    VIEW_WORKFLOW: true,
  },
  'Bot User': {
    ...ALL_PERMISSION_DENIED,
    TICKET_CREATE: true,
    TICKET_UPDATE: true,
    TICKET_MOVE: true,
    ADD_PUBLIC_NOTE: true,
    VIEW_WORKFLOW: true,
  },
  // eslint-disable-next-line quote-props
  'Requestor': {
    ...ALL_PERMISSION_DENIED,
    VIEW_WORKFLOW: true,
  },
};

export const PERMISSION_KEY_MAP = {
  USER_ID: 'user_id',
  USER_TYPE: 'user_type',
  PERMISSION_MAPPING: 'permission_mapping',
  ACTION_PERMISSIONS: 'action_permissions'
};

export const EMPTY_PERMISSION_ITEM = {
  user_id: '',
  user_type: '',
  permission_mapping: {
    ...ALL_PERMISSION_DENIED,
  }
};

export const removeMatchingOptionForPermission = (optionList, stateForComparison) => {
  const templateIds = stateForComparison.map((item) => item[PERMISSION_KEY_MAP.USER_ID]);
  const filteredDataArray = optionList.filter(
    (item) => !templateIds.includes(item?.value?.toString())
  );

  return filteredDataArray;
};

export const createActionPermissionValidation = Joi.array()
  .items(
    Joi.object({
      [PERMISSION_KEY_MAP.USER_ID]: Joi.string().required().trim().min(1),
      [PERMISSION_KEY_MAP.USER_TYPE]: Joi.string().required().trim().min(1),
      [PERMISSION_KEY_MAP.PERMISSION_MAPPING]: Joi.object()
    })
  )
  .default([]);
