/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { EditIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { useNavigate, useParams } from 'react-router-dom';
// import ServiceCommandUnit from '../ServiceCommandUnit';
// import StrictModeDroppable from '../Droppable';
import TriangleDown from '../../../assets/icons/TriangleDown.svg';
import CustomButton from '../../../components/Button/SimpleButton';
import { checkNested } from '../utils/helper';
import Circle from '../../../assets/icons/Circle.svg';
import styles from '../styles/templatebuilder.module.scss';
// import CreateTemplateModal from '../CreateEditTemplate/CreateTemplateModal';
import {
  LIGHT,
  DARK,
  SELECT_LIGHT,
  SELECT_DARK,
  COLORS,
  BLACK,
  WHITE,
} from '../../../utils/enums/colors';
import { LABELS } from '../../../utils/enums/labels';
import { FieldTypes } from '../../../utils/enums/types';
import { getFieldsetList } from '../../../redux/TicketTemplate/AllFieldsetReducer/AllFieldsetSlice';
import CustomToolTip from '../../../components/Tooltip/Tooltip';
import CreateFieldsetModal from './CreateFieldsetModal';

const CreateFieldsets = ({ setOpenFieldsetModal, openFieldsetModal }) => {
  const { allFieldsetsList } = useSelector((state) => state.allFieldsets);
  const [allFieldsets, setAllFieldsets] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFieldsetList());
  }, []);

  useEffect(() => {
    setAllFieldsets(allFieldsetsList);
  }, [allFieldsetsList]);

  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <>
      <Accordion
        allowMultiple={false}
        allowToggle
        defaultIndex={0}
        className={styles.accordionContainer}
        data-testid="CreateFieldsetsAccordian"
      >
        <AccordionItem
          key="all-templates-accordion"
          py="2"
          data-testid="CreateFieldsetsAccordianItem"
        >
          <AccordionButton data-testid="CreateFieldsetsAccordianButtton">
            <Box
              display="flex"
              textAlign="left"
              justifyContent="space-between"
              width="100%"
              data-testid="CreateFieldsetsTextBox"
            >
              <Text
                className={styles.accordionHeading}
                color={useColorModeValue('', 'white')}
                data-testid="CreateFieldsetsHeadingText"
              >
                {LABELS.TITLE.ALL_FIELDSETS}
                <span className={styles.ticketCounterField}>
                  {allFieldsetsList?.length || 0}
                </span>
              </Text>
              <span>
                <AccordionIcon marginRight="4" />
                <Box
                  bg={useColorModeValue('white', 'gray.900')}
                  border="1px"
                  borderColor={useColorModeValue('gray.200', 'gray.700')}
                  borderRadius="6px"
                  alignItems="center"
                  justifyContent="center"
                  p="2"
                  display="inline"
                  data-testid="CreateFieldsetsSearchBox"
                >
                  <SearchIcon
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    width="14px"
                    height="14px"
                    alignSelf="flex-end"
                  />
                </Box>
              </span>
            </Box>
          </AccordionButton>
          <AccordionPanel
            pb={4}
            className={`${styles.allTemplateText}`}
            data-testid="CreateFieldsetsAccordionPanel"
          >
            <CustomButton
              leftIcon={<EditIcon />}
              id="CreateFieldsetscreate-template"
              width="100%"
              onClick={() => {
                setOpenFieldsetModal(true);
              }}
              buttonText={LABELS.ACTIONS.CREATE_NEW_FIELDSET}
              variant="outline"
              className={styles.createTemplateBtnAccordion}
              color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
              mb="4"
              size="md"
            />
            <div className={styles.overflowSection}>
              {allFieldsets?.map((item) => (
                <Box
                  className={`${styles.templateSelect} `}
                  m="0"
                  as="span"
                  fontSize="15px"
                  pl="3"
                  borderRadius="6px"
                  _hover={{
                    bg: COLORS.BG_LIGHT,
                    color: useColorModeValue('black', 'white'),
                  }}
                  bg={
                    item?.id === id
                      ? useColorModeValue(SELECT_LIGHT, SELECT_DARK)
                      : ''
                  }
                  color={
                    item?.id === id
                      ? useColorModeValue(LIGHT, DARK)
                      : useColorModeValue(BLACK, WHITE)
                  }
                  onClick={() =>
                    navigate(`/template-builder/fieldset/${item?.id}`)
                  }
                  data-testid="CreateFieldsetsReactSvgBox"
                >
                  <ReactSVG
                    src={checkNested(item?.children) ? TriangleDown : Circle}
                    className={
                      checkNested(item?.children)
                        ? styles.triangleDown
                        : styles.circle
                    }
                  />
                  <CustomToolTip id="tooltip-test" toolTipText={item?.name}>
                    <span
                      style={{ cursor: 'pointer' }}
                      className="ellipsis ml-2"
                    >
                      {item.name}
                    </span>
                  </CustomToolTip>
                </Box>
              ))}
            </div>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
      {openFieldsetModal && (
        <CreateFieldsetModal
          type={FieldTypes.TEXT}
          templateName={LABELS.ACTIONS.CREATE_NEW_FIELDSET}
          isTemplatedModel={openFieldsetModal}
          setIsTemplatedModel={setOpenFieldsetModal}
        />
      )}
    </>
  );
};

CreateFieldsets.propTypes = {
  setOpenFieldsetModal: PropTypes.func.isRequired,
  openFieldsetModal: PropTypes.bool.isRequired,
};

export default CreateFieldsets;
