/* eslint-disable import/no-cycle */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import WorkFlowMainService from './WorkFlowMainService';
import {
  dummyEdge,
  dummyNode,
  dummyStatusCategories,
} from '../../pages/TicketTemplateWorkflow/DummyData';
import { isEmtpty } from '../../pages/TicketTemplateBuilder/utils/helper';
import { APPROVAL_TYPES } from '../../utils/enums/types';
import { handleLastTenWorkFLowState, lastTenSelectedFlowSessionKey } from '../../pages/TicketTemplateWorkflow/CreateEditFlow/helper';

export const initSelectedRule = {
  transitionId: '',
  listRestricType: {},
  id: '',
  ruleType: '',
  reviewValueStatus: '',
  checkValueStatus: '',
  valueListObj: [],
  statusId: '',
  includedStatus: false,
  reverseRuleStatus: false,
  approvalsType: APPROVAL_TYPES.MIN_APPROVAL_COUNT,
  approvals: '1',
};
const initialState = {
  selectedWorkFlow: {},
  undoIndex: 0,
  selectedWorkFlowStatus: {},
  defaultViewport: { x: 0, y: 0, zoom: 1 },
  selectedWorkFlowTransition: {},
  inputValueForCreateDropDown: '',
  isAutoMoveAllowed: false,
  dropDownState: '',
  toTransitionState: [],
  fromTransitionState: {},
  connectionObjectState: {},
  selectedWorkFlowStatusCategory: {},
  selectedWorkFlowRule: { ...initSelectedRule },
  isEdgeSave: false,
  toolTipInfo: {},
};
export const getWorkFlowById = createAsyncThunk(
  'workFlowMain/getWorkFlowById',
  async (id, thunkAPI) => {
    try {
      const workFlowData = await WorkFlowMainService.getWorkFlowById(id);
      const newObj = { ...workFlowData };
      if (newObj.workflow_status.length === 0) {
        newObj.workflow_status = dummyNode;
      }
      if (newObj.workflow_category.length === 0) {
        newObj.workflow_category = dummyStatusCategories;
      }
      if (newObj.workflow_transitions.length === 0) {
        newObj.workflow_transitions = dummyEdge;
      }
      if (isEmtpty(newObj.workflow_rules)) {
        newObj.workflow_rules = {
          list: [],
        };
      }
      if (sessionStorage.getItem(lastTenSelectedFlowSessionKey)) {
        const lastTenWorkflowStateMain = JSON.parse(sessionStorage.getItem(lastTenSelectedFlowSessionKey));
        if (lastTenWorkflowStateMain.selectedTemplateId !== id) {
          handleLastTenWorkFLowState(newObj, id);
        }
      } else {
        handleLastTenWorkFLowState(newObj, id);
      }

      return newObj;
    } catch (error) {
      const { message } = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getWorkFlowStateByTemeplateId = createAsyncThunk(
  'workFlowMain/getWorkFlowStateByTemeplateId',
  async ({ id, entityRoute, createdBy }, thunkAPI) => {
    try {
      const workFlowData =
        await WorkFlowMainService.getWorkFlowStateByTemeplateId(
          id,
          entityRoute
        );
      const newObj = { ...workFlowData.workFlow };
      // if (isEmtpty(newObj)) {
      //   newObj.workflow_status = dummyNode;
      //   newObj.workflow_category = dummyStatusCategories;
      //   newObj.workflow_transitions = dummyEdge;
      //   newObj.workflow_rules = {
      //     list: [],
      //   };
      // }
      // if (isEmtpty(newObj.workflow_status)) {
      //   newObj.workflow_status = dummyNode;
      // }
      // if (isEmtpty(newObj.workflow_category)) {
      //   newObj.workflow_category = dummyStatusCategories;
      // }
      // if (isEmtpty(newObj.workflow_transitions)) {
      //   newObj.workflow_transitions = dummyEdge;
      // }
      // if (isEmtpty(newObj.workflow_rules)) {
      //   newObj.workflow_rules = {
      //     list: [],
      //   };
      // }
      if (isEmtpty(newObj.workflow_rules) && entityRoute === 'template') {
        newObj.workflow_rules = {
          list: [],
        };
      }

      // for undo Redo imp
      if (sessionStorage.getItem(lastTenSelectedFlowSessionKey)) {
        const lastTenWorkflowStateMain =
        JSON.parse(sessionStorage.getItem(lastTenSelectedFlowSessionKey));
        if (lastTenWorkflowStateMain.selectedTemplateId !== id) {
          handleLastTenWorkFLowState(newObj, id);
        }
      } else {
        handleLastTenWorkFLowState(newObj, id);
      }
      return {
        updateWorkFlow: newObj,
        templateId: workFlowData.templateId,
        fieldList: workFlowData.fieldList,
        entityRoute: workFlowData.entityRoute,
        templateLifeCycle: workFlowData.templateLifeCycle,
        properties: workFlowData.properties || {},
        createdBy: createdBy || ''
      };
    } catch (error) {
      const { message } = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const addNewStateWorkFlowWithKeyValue = createAsyncThunk(
  'workFlowMain/addNewStateWorkFlowWithKeyValue',
  async ({ keyValue, value, editTranstion, defaultRole, dispatch }, thunkAPI) => {
    try {
      const { workFlowMain } = thunkAPI.getState();
      const updateObject =
        await WorkFlowMainService.addNewStateWorkFlowWithKeyValue({
          keyValue,
          value,
          state: workFlowMain.selectedWorkFlow,
          templateId: workFlowMain.selectedTemplateId,
          entityRoute: workFlowMain.entityRoute,
          editTranstion,
          defaultRole,
          templateProperties: workFlowMain.templateProperties,
          dispatch
        });
      handleLastTenWorkFLowState(updateObject.localState, workFlowMain.selectedTemplateId);
      return updateObject;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const updateSelectedWorkFlowWithKeyValue = createAsyncThunk(
  'workFlowMain/updateSelectedWorkFlowWithKeyValue',
  async ({ keyValue, value, dispatch }, thunkAPI) => {
    try {
      const { workFlowMain } = thunkAPI.getState();
      const updateObject =
        await WorkFlowMainService.updateSelectedWorkFlowWithKeyValue({
          keyValue,
          value,
          state: workFlowMain.selectedWorkFlow,
          templateId: workFlowMain.selectedTemplateId,
          entityRoute: workFlowMain.entityRoute,
          templateProperties: workFlowMain.templateProperties,
          dispatch
        });
      handleLastTenWorkFLowState(updateObject.updatedState, workFlowMain.selectedTemplateId);
      return { updateObject, entityRoute: workFlowMain.entityRoute };
    } catch (error) {
      const { message } = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateSelectedWorkFlowName = createAsyncThunk('workFlowMain/updateSelectedWorkFlowName', async ({ keyValue, value }, thunkAPI) => {
  try {
    const { workFlowMain } = thunkAPI.getState();
    const updateObject =
      await WorkFlowMainService.updateSelectedWorkFlowName({
        keyValue,
        value,
        state: workFlowMain.selectedWorkFlow,
        templateId: workFlowMain.selectedTemplateId,
        entityRoute: workFlowMain.entityRoute,
      });
    handleLastTenWorkFLowState(updateObject.updatedState, workFlowMain.selectedTemplateId);
    return updateObject;
  } catch (error) {
    const { message } = error.response.data;
    return thunkAPI.rejectWithValue(message);
  }
});
export const saveCategoryInWorkFlow = createAsyncThunk(
  'workFlowMain/saveCategoryInWorkFlow',
  async ({ value, dispatch }, thunkAPI) => {
    try {
      const { workFlowMain } = thunkAPI.getState();
      const updateObject = await WorkFlowMainService.saveCategoryInWorkFlow({
        value,
        state: workFlowMain.selectedWorkFlow,
        templateId: workFlowMain.selectedTemplateId,
        entityRoute: workFlowMain.entityRoute,
        templateProperties: workFlowMain.templateProperties,
        dispatch
      });
      handleLastTenWorkFLowState(updateObject.updatedObj, workFlowMain.selectedTemplateId);
      return updateObject;
    } catch (error) {
      const { message } = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteCategoryFromWorkFlow = createAsyncThunk(
  'workFlowMain/deleteCategoryFromWorkFlow',
  async ({ value, dispatch }, thunkAPI) => {
    try {
      const { workFlowMain } = thunkAPI.getState();
      const updateObject = await WorkFlowMainService.deleteCategoryFromWorkFlow(
        {
          value,
          state: workFlowMain.selectedWorkFlow,
          templateId: workFlowMain.selectedTemplateId,
          entityRoute: workFlowMain.entityRoute,
          templateProperties: workFlowMain.templateProperties,
          dispatch
        }
      );
      handleLastTenWorkFLowState(updateObject.updatedObj, workFlowMain.selectedTemplateId);
      return updateObject;
    } catch (error) {
      const { message } = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteNodeFromWorkFlow = createAsyncThunk(
  'workFlowMain/deleteNodeFromWorkFlow',
  async ({ nodeId, dispatch }, thunkAPI) => {
    try {
      const { workFlowMain } = thunkAPI.getState();
      const updateObject = await WorkFlowMainService.deleteNodeFromWorkFlow({
        nodeId,
        state: workFlowMain.selectedWorkFlow,
        templateId: workFlowMain.selectedTemplateId,
        entityRoute: workFlowMain.entityRoute,
        templateProperties: workFlowMain.templateProperties,
        dispatch
      });
      handleLastTenWorkFLowState(updateObject.updatedObj, workFlowMain.selectedTemplateId);
      return updateObject;
    } catch (error) {
      const { message } = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addRuleToSelectedWorkFlowSate = createAsyncThunk(
  'workFlowMain/addRuleToSelectedWorkFlowSate',
  async ({ dispatch }, thunkAPI) => {
    try {
      const { workFlowMain } = thunkAPI.getState();
      const updateObject =
        await WorkFlowMainService.addRuleToSelectedWorkFlowSate({
          value: workFlowMain.selectedWorkFlowRule,
          state: workFlowMain.selectedWorkFlow,
          templateId: workFlowMain.selectedTemplateId,
          templateProperties: workFlowMain.templateProperties,
          dispatch
        });
      handleLastTenWorkFLowState(updateObject.updatedObj, workFlowMain.selectedTemplateId);
      return updateObject;
    } catch (error) {
      const { message } = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateRuleToSelectedWorkFlowSate = createAsyncThunk(
  'workFlowMain/updateRuleToSelectedWorkFlowSate',
  async ({ dispatch }, thunkAPI) => {
    try {
      const { workFlowMain } = thunkAPI.getState();
      const updateObject =
        await WorkFlowMainService.updateRuleToSelectedWorkFlowSate({
          value: workFlowMain.selectedWorkFlowRule,
          state: workFlowMain.selectedWorkFlow,
          templateId: workFlowMain.selectedTemplateId,
          templateProperties: workFlowMain.templateProperties,
          dispatch
        });
      handleLastTenWorkFLowState(updateObject.updatedObj, workFlowMain.selectedTemplateId);
      return updateObject;
    } catch (error) {
      const { message } = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const removeRuleFromSelectedWorkFlowState = createAsyncThunk(
  'workFlowMain/removeRuleFromSelectedWorkFlowState',
  async ({ dispatch }, thunkAPI) => {
    try {
      const { workFlowMain } = thunkAPI.getState();
      const updateObject =
        await WorkFlowMainService.removeRuleFromSelectedWorkFlowState({
          value: workFlowMain.selectedWorkFlowRule,
          state: workFlowMain.selectedWorkFlow,
          templateId: workFlowMain.selectedTemplateId,
          templateProperties: workFlowMain.templateProperties,
          dispatch
        });
      handleLastTenWorkFLowState(updateObject.updatedObj, workFlowMain.selectedTemplateId);
      return updateObject;
    } catch (error) {
      const { message } = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteEdgeFromWorkFlow = createAsyncThunk(
  'workFlowMain/deleteEdgeFromWorkFlow',
  async (_, thunkAPI) => {
    try {
      const { workFlowMain } = thunkAPI.getState();
      const updateObject = await WorkFlowMainService.deleteEdgeFromWorkFlow({
        value: workFlowMain.selectedWorkFlowTransition,
        state: workFlowMain.selectedWorkFlow,
        templateId: workFlowMain.selectedTemplateId,
        entityRoute: workFlowMain.entityRoute,
        templateProperties: workFlowMain.templateProperties,
      });
      handleLastTenWorkFLowState(updateObject.updatedObj, workFlowMain.selectedTemplateId);
      return updateObject;
    } catch (error) {
      const { message } = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateNodePostionWhenDrag = createAsyncThunk(
  'workFlowMain/updateNodePostionWhenDrag',
  async (nodeList, thunkAPI) => {
    try {
      const { workFlowMain } = thunkAPI.getState();
      const updateObject = await WorkFlowMainService.updateNodePostionWhenDrag({
        nodeList,
        state: workFlowMain.selectedWorkFlow,
        templateId: workFlowMain.selectedTemplateId,
        entityRoute: workFlowMain.entityRoute,
        templateProperties: workFlowMain.templateProperties,
      });
      handleLastTenWorkFLowState(updateObject.updatedObj, workFlowMain.selectedTemplateId);
      return updateObject;
    } catch (error) {
      const { message } = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// autoSavePostionWhenDrag
export const autoSavePostionWhenDrag = createAsyncThunk(
  'workFlowMain/autoSavePostionWhenDrag',
  async ({nodeList, dispatch }, thunkAPI) => {
    try {
      const { workFlowMain } = thunkAPI.getState();
      const updateObject = await WorkFlowMainService.autoSavePostionWhenDrag({
        nodeList,
        state: workFlowMain.selectedWorkFlow,
        templateId: workFlowMain.selectedTemplateId,
        entityRoute: workFlowMain.entityRoute,
        templateProperties: workFlowMain.templateProperties,
        dispatch
      });
      handleLastTenWorkFLowState(updateObject, workFlowMain.selectedTemplateId);
      return updateObject;
    } catch (error) {
      const { message } = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateWorkflowOnUndoRedo = createAsyncThunk(
  'workFlowMain/updateWorkflowOnUndoRedo',
  async (updatedWorkFlow, thunkAPI) => {
    try {
      const { workFlowMain } = thunkAPI.getState();
      const updateObject = await WorkFlowMainService.updateWorkflowOnUndoRedo({
        updatedWorkFlow,
        templateId: workFlowMain.selectedTemplateId,
        entityRoute: workFlowMain.entityRoute,
        templateProperties: workFlowMain.templateProperties,
      });
      return updateObject;
    } catch (error) {
      const { message } = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
const workFlowMain = createSlice({
  name: 'workFlowMain',
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.error = '';
    },
    resetError: (state) => {
      state.isError = false;
      state.error = '';
    },
    updateDropDownState: (state, action) => {
      state.dropDownState = action.payload;
    },
    updateInputValueForCreateDropDown: (state, action) => {
      state.inputValueForCreateDropDown = action.payload;
    },
    updateAutoMove: (state, action) => {
      state.isAutoMoveAllowed = action.payload;
    },
    updateSelectedWorkFlowStatus: (state, action) => {
      state.selectedWorkFlowStatus = action.payload;
    },
    updateStateOfWorkFlowWithKey: (state, action) => {
      const { keyValue, value } = action.payload;
      state[keyValue] = value;
    },
    removeStateFromWorkFlowWithKeyValue: (state, action) => {
      const { keyValue, value } = action.payload;
      const newUpdate = { ...state.selectedWorkFlow };
      const obj = {
        ...newUpdate,
        [keyValue]: newUpdate[keyValue].filter((item) => item.id !== value),
      };
      state.selectedWorkFlow = obj;
    },
    updateSelectedWorkFlow: (state, action) => {
      state.selectedWorkFlow = action.payload;
    },
    changeSelectedRuleWithKey: (state, action) => {
      const { keyValue, value } = action.payload;
      state.selectedWorkFlowRule = {
        ...state.selectedWorkFlowRule,
        [keyValue]: value,
      };
    },
    changeSelectedRuleWithMultiKeyValue: (state, action) => {
      const newSelectedWorkflowRule = { ...state.selectedWorkFlowRule };
      action.payload.forEach((keyValCollection) => {
        const { keyValue, value } = keyValCollection;
        newSelectedWorkflowRule[keyValue] = value;
      });

      state.selectedWorkFlowRule = newSelectedWorkflowRule;
    },
    addlistRestricTypeWithKeyValue: (state, action) => {
      const { keyValue, value } = action.payload;
      const existingValue =
        state.selectedWorkFlowRule.listRestricType[keyValue] || [];
      const updatedValue = [...existingValue, value];
      const updatedListRestrictType = {
        ...state.selectedWorkFlowRule.listRestricType,
        [keyValue]: updatedValue,
      };
      state.selectedWorkFlowRule = {
        ...state.selectedWorkFlowRule,
        listRestricType: updatedListRestrictType,
      };
    },
    removeListRestricTypeValue: (state, action) => {
      const { keyValue, value } = action.payload;
      const existingValue =
        state.selectedWorkFlowRule.listRestricType[keyValue] || [];
      const updatedValue = existingValue.filter((item) => item !== value);
      const updatedListRestrictType = {
        ...state.selectedWorkFlowRule.listRestricType,
        [keyValue]: updatedValue,
      };
      state.selectedWorkFlowRule = {
        ...state.selectedWorkFlowRule,
        listRestricType: updatedListRestrictType,
      };
    },
    addValueInValueListObject: (state, action) => {
      const { keyValue, value } = action.payload;
      const existingValue =
        state.selectedWorkFlowRule.valueListObj[keyValue] || [];
      const updatedValue = [...existingValue, value];
      const updatedListRestrictType = {
        ...state.selectedWorkFlowRule.valueListObj,
        [keyValue]: updatedValue,
      };
      state.selectedWorkFlowRule = {
        ...state.selectedWorkFlowRule,
        valueListObj: updatedListRestrictType,
      };
    },
    updateDefaultViewport: (state, action) => {
      state.defaultViewport = action.payload;
    },
    removeValueFromValueListObject: (state, action) => {
      const { keyValue, value } = action.payload;
      const existingValue =
        state.selectedWorkFlowRule.valueListObj[keyValue] || [];
      const updatedValue = existingValue.filter((item) => item !== value);
      const updatedListRestrictType = {
        ...state.selectedWorkFlowRule.valueListObj,
        [keyValue]: updatedValue,
      };
      state.selectedWorkFlowRule = {
        ...state.selectedWorkFlowRule,
        valueListObj: updatedListRestrictType,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewStateWorkFlowWithKeyValue.fulfilled, (state, action) => {
        state.selectedWorkFlow = action.payload.localState;
        state.toolTipInfo = action.payload.toolTipObj;
      })
      .addCase(deleteEdgeFromWorkFlow.fulfilled, (state, action) => {
        state.selectedWorkFlow = action.payload.updatedObj;
        state.toolTipInfo = action.payload.toolTipObj;
        state.selectedWorkFlowTransition = {};
      })
      .addCase(
        updateSelectedWorkFlowWithKeyValue.fulfilled,
        (state, action) => {
          // updatedState, toolTipObj
          const { updatedState, toolTipObj } = action.payload.updateObject;
          state.selectedWorkFlow = updatedState;
          state.toolTipInfo = toolTipObj;
        }
      )
      .addCase(updateSelectedWorkFlowName.fulfilled, (state, action) => {
        state.selectedWorkFlow = action.payload.updatedState;
        state.toolTipInfo = action.payload.toolTipObj;
      })
      .addCase(updateNodePostionWhenDrag.fulfilled, (state, action) => {
        state.selectedWorkFlow = action.payload.updatedObj;
        state.toolTipInfo = action.payload.toolTipObj;
      })
      .addCase(autoSavePostionWhenDrag.fulfilled, (state, action) => {
        state.selectedWorkFlow = action.payload;
      })
      .addCase(updateWorkflowOnUndoRedo.fulfilled, (state, action) => {
        state.selectedWorkFlow = action.payload;
      })
      .addCase(deleteCategoryFromWorkFlow.fulfilled, (state, action) => {
        state.selectedWorkFlow = action.payload.updatedObj;
        state.toolTipInfo = action.payload.toolTipObj;
      })
      .addCase(deleteNodeFromWorkFlow.fulfilled, (state, action) => {
        state.selectedWorkFlow = action.payload.updatedObj;
        state.toolTipInfo = action.payload.toolTipObj;
      })
      .addCase(saveCategoryInWorkFlow.fulfilled, (state, action) => {
        state.selectedWorkFlow = action.payload.updatedObj;
        state.toolTipInfo = action.payload.toolTipObj;
      })
      .addCase(addRuleToSelectedWorkFlowSate.fulfilled, (state, action) => {
        state.selectedWorkFlow = action.payload.updatedObj;
        state.toolTipInfo = action.payload.toolTipObj;
        state.selectedWorkFlowRule = { ...initSelectedRule };
      })
      .addCase(updateRuleToSelectedWorkFlowSate.fulfilled, (state, action) => {
        state.selectedWorkFlow = action.payload.updatedObj;
        state.toolTipInfo = action.payload.toolTipObj;
        state.selectedWorkFlowRule = { ...initSelectedRule };
      })
      .addCase(
        removeRuleFromSelectedWorkFlowState.fulfilled,
        (state, action) => {
          state.selectedWorkFlow = action.payload.updatedObj;
          state.toolTipInfo = action.payload.toolTipObj;
          state.selectedWorkFlowRule = { ...initSelectedRule };
        }
      )
      .addCase(getWorkFlowStateByTemeplateId.fulfilled, (state, action) => {
        // const { updateWorkFlow, templateId, fieldList } = action.payload;
        state.selectedWorkFlow = action.payload.updateWorkFlow;
        state.selectedTemplateId = action.payload.templateId;
        state.fieldList = action.payload.fieldList;
        state.entityRoute = action.payload.entityRoute;
        state.templateLifeCycle = action.payload.templateLifeCycle;
        state.templateProperties = {
          id: action.payload.templateId,
          properties: action?.payload?.properties || {},
          created_by: action?.payload?.createdBy
        };
      })
      .addCase(getWorkFlowById.fulfilled, (state, action) => {
        state.selectedWorkFlow = action.payload;
      });
  },
});
export const {
  reset,
  resetError,
  updateDropDownState,
  updateInputValueForCreateDropDown,
  updateAutoMove,
  updateSelectedWorkFlowStatus,
  updateStateOfWorkFlowWithKey,
  removeStateFromWorkFlowWithKeyValue,
  updateSelectedWorkFlow,
  updateDefaultViewport,
  changeSelectedRuleWithKey,
  changeSelectedRuleWithMultiKeyValue,
  addlistRestricTypeWithKeyValue,
  removeListRestricTypeValue,
  addValueInValueListObject,
  removeValueFromValueListObject,
} = workFlowMain.actions;
export default workFlowMain.reducer;
