import React, { useCallback } from 'react';

import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

import PropTypes from 'prop-types';

import styles from '../filters.module.scss';
import CustomButton from '../../../components/Button/SimpleButton';
import { PrimaryCustomButton, SecondaryCustomButton } from '../../../components/Button/PrimarySecondaryButton';

const CustomDrawer = ({
  title,
  closeDrawer,
  handleSave,
  open,
  drawerBody,
  errorMessage,
  isLoading,
  showDrawerFooter,
}) => {
  const close = useCallback(() => {
    if (typeof closeDrawer === 'function') {
      closeDrawer();
    }
  }, [closeDrawer]);

  return (
    <Drawer size="md" placement="right" onClose={close} isOpen={open}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <div className={styles.drawer__header}>
            <IconButton
              colorScheme="blue"
              aria-label="Close Drawer"
              size="sm"
              className={styles.drawer__close__button}
              variant="outline"
              icon={<ArrowBackIcon />}
              onClick={closeDrawer}
            />
            <p>{title}</p>
          </div>
        </DrawerHeader>
        <DrawerBody>{drawerBody}</DrawerBody>
        {showDrawerFooter ? (
        <DrawerFooter>
          <Flex>
            {errorMessage ? (
              <p className="field-error !mt-0">{errorMessage}</p>
            ) : null}

            <Spacer />
            <Flex gap={3}>
              <SecondaryCustomButton
                onClick={close}
                buttonText="Cancel"
                isDisabled={isLoading}
              />

              <PrimaryCustomButton
                onClick={handleSave}
                buttonText="Save"
                isLoading={isLoading}
              />
            </Flex>
          </Flex>
        </DrawerFooter>
        ) : null}
      </DrawerContent>
    </Drawer>
  );
};

CustomDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  closeDrawer: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  drawerBody: PropTypes.node.isRequired,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  showDrawerFooter: PropTypes.bool,
};

CustomDrawer.defaultProps = {
  title: '',
  errorMessage: '',
  isLoading: false,
  showDrawerFooter: true,
};

export default CustomDrawer;
