import { fieldNameLabelMap } from '../../pages/DynamicRenderer/customEventAndTriggers/customEventAndTriggers.constant';
import axiosInstance from '../../utils/axios/axios';
import { validateCompleteEventData } from '../../utils/validationSchemas/templates/events';
import { getErrorMessagesPath } from '../../utils/validationSchemas/validationSchemas';
import CustomToast from '../Notification/Notification';

const { addToast } = CustomToast();

export const showToastMessage = ({ status = 'success', description }) => {
  addToast({
    title: status === 'success' ? 'Success' : 'Something went wrong',
    message: description,
    type: status,
  });
};

export const cloneApiCall = async (data, url, masterType) => {
  try {
    const res = await axiosInstance.post(url, {
      data,
    });
    if (res.status < 300) {
      showToastMessage({
        description: `Successfully cloned the ${masterType}`,
      });
      return {
        response: res.data,
        error: null,
      };
    }
    throw new Error('Something went wrong');
  } catch (err) {
    showToastMessage({
      status: 'error',
      description:
        err?.response?.data?.message || `Failed to clone the ${masterType}`,
    });
    return {
      response: null,
      error:
        err?.response?.data?.message || `Failed to clone the ${masterType}`,
    };
  }
};

export const completeCheck = (payload) => {
  const { error } = validateCompleteEventData.validate(payload.events, {
    abortEarly: false,
  });

  if (error) {
    const errors = getErrorMessagesPath(error, fieldNameLabelMap);

    errors.forEach((e) => {
      addToast({
        message: e,
        type: 'error',
        duration: null,
        isClosable: true,
      });
    });

    return true;
  }
  return false;
};

//while cloning metric UPDATE sla Name Because sla name can not be same as field, metric or sla name
export const updateCloneMetricSlaName =(selectedMetricSla, metricName)=>{
  const deepCopySelectedMetricsSla = [...selectedMetricSla].map(item=>({...item, name:`${item.name} (${metricName})`}))
  return deepCopySelectedMetricsSla
}