// validate sla, metric and field name (label) cannot be same
export function validateSlaMetricFieldCannotHaveSameName(input = '', selectedTemplate, id) {
  const { fields, metric, slas } = selectedTemplate || {};
  let error = '';
  // check if name(label) already been use in fields
  if (fields) {
    fields.forEach((field) => {
      if (field.label.toLowerCase() === input && field.id !== id) {
        error = `${field.label} name is already been used by another field try with any another name.`;
      }
    });
  }

  // check if name(label) already been use in metrics
  if (metric) {
    metric.forEach((metric) => {
      if (metric.name.toLowerCase() === input && metric.id !== id) {
        error = `${metric.name} name is already been used by another metric try with any another name.`;
      }
    });
  }

  // check if name(label) already been use in SLAs
  if (slas) {
    Object.values(slas).forEach((slaArray) => {
      slaArray.forEach((sla) => {
        if (sla.name.toLowerCase() == input && sla.id !== id) {
          error = `${sla.name} name is already been used by another SLA try with any another name.`;
        }
      });
    });
  }

  // return updated error message
  return error;
}
