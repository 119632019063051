/* eslint-disable import/prefer-default-export */
/* eslint-disable indent */
import React from 'react';
import { EditIcon } from '@chakra-ui/icons';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _map from 'lodash/map';
import Joi from 'joi';
import TableColumnText from '../../components/Text/TableColumnText';

export const COLUMNS = ({ onEdit }) => [
  {
    name: 'QUEUE FIELD',
    col_name: 'name',
    type: 'string',
    cellRenderer: ({ rowData }) => <TableColumnText text={rowData?.name} />,
  },
  {
    name: 'DEFAULT TEAM',
    col_name: 'default_team',
    type: 'string',
    cellRenderer: ({ rowData }) => {
        const team = rowData?.default_team || '';
        return <div>{team?.length ? team : 'N/A'}</div>;
      },
  },
  {
    name: 'ACTIONS',
    label: 'actions',
    col_name: 'Actions',
    cellRenderer: ({ rowData }) => (
      <div className="flex gap-[10px] justify-center">
        <EditIcon className="cursor-pointer" onClick={() => onEdit(rowData)} />
      </div>
    ),
  },
];

export const ROUTING_QUEUE_KEY_MAP = {
    NAME: 'name',
    QUEUE_FIELD_ID: 'queue_field_id',
    DEFAULT_TEAM: 'default_team',
    DEFAULT_QUEUE_ID: 'default_queue_id',
    DESCRIPTION: 'description',
    CONDITIONS: 'conditions',
    TEAM: 'team',
    QUEUE_ID: 'queue_id',
    RANK: 'rank',
    ROUTES: 'routes'
  };

export const mapQueueFieldData = (fieldsData, ticketRoutingData) => {
  const queueData = [];
  _forEach(fieldsData, (field) => {
    const data = _find(
      ticketRoutingData,
      (queue) => queue.queue_field_id === field.id
    );
    const tempData = {};
    if (data) {
      tempData[ROUTING_QUEUE_KEY_MAP.QUEUE_FIELD_ID] = field.id;
      tempData[[ROUTING_QUEUE_KEY_MAP.NAME]] = field.label;
      tempData[ROUTING_QUEUE_KEY_MAP.DEFAULT_TEAM] = data.default_team || '';
      tempData[ROUTING_QUEUE_KEY_MAP.DEFAULT_QUEUE_ID] = data.default_queue_id || '';
      tempData.routes = _map(data?.routes, (item) => ({
        [ROUTING_QUEUE_KEY_MAP.CONDITIONS]: item.conditions || {},
        [ROUTING_QUEUE_KEY_MAP.TEAM]: item.team || '',
        [ROUTING_QUEUE_KEY_MAP.QUEUE_ID]: item.queue_id || null,
        [ROUTING_QUEUE_KEY_MAP.DESCRIPTION]: item.description || '',
        [ROUTING_QUEUE_KEY_MAP.RANK]: item.rank || ''
      }));
    } else {
        tempData[ROUTING_QUEUE_KEY_MAP.QUEUE_FIELD_ID] = field.id;
        tempData[[ROUTING_QUEUE_KEY_MAP.NAME]] = field.label;
        tempData[ROUTING_QUEUE_KEY_MAP.DEFAULT_TEAM] = '';
        tempData[ROUTING_QUEUE_KEY_MAP.DEFAULT_QUEUE_ID] = '';
        tempData.routes = [];
      }

    if (field?.type_based_attributes?.collection?.collection_type === 'SPECIAL_FIELD') {
      queueData.push(tempData);
    }
  });
  return queueData;
};

export const DRAWER_TITLE = 'Add New Routing Conditions';

export const ROUTE_INITIAL_DATA = {
  [ROUTING_QUEUE_KEY_MAP.CONDITIONS]: {},
  [ROUTING_QUEUE_KEY_MAP.TEAM]: '',
  [ROUTING_QUEUE_KEY_MAP.QUEUE_ID]: null,
  [ROUTING_QUEUE_KEY_MAP.DESCRIPTION]: '',
  [ROUTING_QUEUE_KEY_MAP.RANK]: ''
};

export const ticketRoutingValidation = Joi.object({
  queue_field_id: Joi.string().uuid().allow(''),
  default_team: Joi.string().allow(''),
  default_queue_id: Joi.number().integer().allow(''),
  routes: Joi.array().items(Joi.object({
    conditions: Joi.object(),
    team: Joi.string().allow('', null).optional(),
    queue_id: Joi.when(
      Joi.exist(),
      {
        then: Joi.number().integer().allow('', null).optional(),
        otherwise: Joi.forbidden()
      }
    ),
    description: Joi.string().allow(''),
    rank: Joi.when(
      Joi.exist(),
      {
        then: Joi.number().integer().allow(''),
        otherwise: Joi.forbidden()
      }
    )
  })).unique(
    (a, b) => a?.rank === b?.rank
  ).messages({
    'array.unique': 'Rank must be unique',
    'number.base': 'Rank must be a number'
  })
});
