import React from 'react';
import { Spinner, Flex } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const Loader = ({ loaderText, height }) => (
  <Flex
    flexDirection="column"
    w="100%"
    h={height}
    display="flex"
    justifyContent="center"
    alignItems="center"
    zIndex="9999"
    data-testid="LoaderContainerFLex"
  >
    <Spinner width="30px" height="30px" />
    <div
      style={{
        fontSize: '12px',
        color: '#999',
        textTransform: 'uppercase',
        marginTop: '20px',
      }}
      data-testid="LoaderTextDiv"
    >
      {loaderText}
    </div>
  </Flex>
);

Loader.propTypes = {
  loaderText: PropTypes.string,
  height: PropTypes.string,
};

Loader.defaultProps = {
  loaderText: 'Loading ...',
  height: '100vh',
};

export default Loader;
