/* eslint-disable import/prefer-default-export */

export const signaturesJson = {
  name: 'signature',
  label: 'Signatures',
  table_name: 'signatures_table',
  route: '/signature-list',
  permissions: [
    {
      name: 'edit',
      type: 'edit',
      roles: ['ticket_analyst', 'ticket_admin'],
      conditions: [
        {
          AND: [
            {
              OR: [
                {
                  condition: 'user_id = $user.id',
                },
                {
                  condition: 'collaborator_id = $user.id',
                },
              ],
            },
            {
              condition: 'status != published',
            },
          ],
        },
      ],
    },
    {
      name: 'view',
      type: 'view',
      roles: ['ticket_analyst', 'ticket_admin'],
    },
    {
      name: 'create',
      type: 'create',
      roles: ['ticket_analyst', 'ticket_admin'],
    },
    {
      name: 'approve',
      type: 'edit',
      roles: ['ticket_admin'],
    },
    {
      name: 'reject',
      type: 'edit',
      roles: ['ticket_admin'],
    },
  ],
  columns: [
    {
      name: 'name',
      label: 'Name',
      column_name: 'name',
      data_type: 'string',
    },
    {
      name: 'status',
      label: 'Status',
      column_name: 'status',
      data_type: 'string',
    },
    {
      name: 'parent',
      label: 'Parent',
      column: 'name',
      related_entity: 'parent_ticket_template',
    },
    {
      name: 'updated_on',
      label: 'Update On',
      column_name: 'updated_on',
      data_type: 'timestamp',
    },
  ],
  related_entities: [
    {
      name: 'parent_ticket_template',
      join_column: 'parent_id',
      related_table: 'ticket_template',
      related_join_column: 'id',
    },
    {
      name: 'ticket_template_fields',
      join_column: 'id',
      related_table: 'ticket_template_fields',
      related_join_column: 'ticket_template_id',
    },
  ],
  views: [
    {
      name: 'signatures_container',
      label: 'Signature Container',
      show_label: false,
      type: 'container',
      style: { padding: '0px 32px', boxSizing: 'borderBox' },
      elements: [
        {
          name: 'signatures_title',
          type: 'text',
          value: 'Signatures',
          style: { fontSize: '16px', fontWeight: '600' },
        },
        {
          name: 'signatures_count',
          type: 'count_div',
          listKey: 'signatures_expanded_list',
        },
      ],
      views: [
        {
          name: 'signatures_expanded_list',
          label: 'Signatures List',
          type: 'list_exp',
          apiReq: {
            reqCols: ['*'],
            orderBy: 'created_on asc',
            endPoint: '/signatures/list',
          },
          related_entity: 'ticket_template_fields',
          columns: [
            {
              col_name: 'name',
              type: 'string',
            },
            {
              col_name: 'description',
              type: 'string',
            },
            {
              col_name: 'signature',
              type: 'string',
            },
            {
              col_name: 'bound to',
              type: 'bound_to',
            },
            {
              col_name: 'status',
              type: 'string',
            },
            {
              col_name: 'actions',
              type: 'actions',
            },
          ],
          filter: [
            {
              condition: 'is_active = true',
            },
          ],
          actions: [
            {
              name: 'edit_signatures',
              icon: 'editIcon',
              type: 'button',
              target_type: 'popup',
              target_modal_id: 'editSignatureDrawer',
            },
            {
              name: 'delete_signatures',
              icon: 'deleteIcon',
              type: 'button',
              target_type: 'popup',
              label_key: 'label', // Key name to be displayed in modal as label
              target_modal_id: 'deleteModal',
              master_type: 'Signature',
              listKey: 'signatures_expanded_list', // identify the redux status for update it
              // eslint-disable-next-line no-template-curly-in-string
              target_url: '/signatures/delete?$filter=id eq ${id}',
            },
            {
              name: 'copy_fieldsets',
              icon: 'copyIcon',
              type: 'button',
              target_url: '/signatures/clone', // *for api call
              method: 'post',
              listKey: 'signatures_expanded_list', // identify the redux status for update it
              target_type: 'popup',
              target_modal_id: 'crudTemplateCloneModal',
              master_type: 'signature',
            },
          ],
          filterKey: 'name',
          listKey: 'signatures_expanded_list',
        },
        {
          name: 'action_container',
          label: 'Action Container',
          show_label: false,
          type: 'container',
          elements: [
            {
              name: 'search_signatures_item',
              type: 'search',
              label: 'Search signatures...',
              placeholder: 'Search signatures...',
              entity: 'Signatures',
              listKey: 'signatures_expanded_list',
              filterKey: '',
              style: { height: '40px' },
              inputGroupStyle: { width: 'fit-content' },
            },
          ],
          actions: [
            {
              name: 'signatures_new_action',
              label: 'Add New Signature',
              show_label: true,
              is_button: true,
              icon: '',
              action_type: 'get',
              permission: 'edit',
              view: 'edit_view',
              navigation: 'popup',
              target_type: 'popup',
              target_modal_id: 'addSignatureDrawer',
              type: 'button',
              classname: 'editbutton',
              style: {
                color: '#FFFFFF',
                fontWeight: '600',
                backgroundColor: '#2563EB',
              },
            },
          ],
          view_layout: {
            width: '100%',
            no_of_cols: 12,
            rows: [
              {
                i: 'search_signatures_item',
                x: 0,
                y: 1,
                w: 4,
                // maxH: 'Infinity',
              },
              {
                i: 'signatures_new_action',
                x: 0,
                y: 1,
                w: 4,
                colEnd: -1,
                style: {
                  display: 'inherit',
                  justifyContent: 'end',
                },
                maxH: 'Infinity',
              },
            ],
          },
        },
      ],

      view_layout: {
        width: '100%',
        no_of_cols: 12,
        rows: [
          {
            i: 'signatures_title',
            x: 0,
            y: 1,
            w: 2,
            maxH: 'Infinity',
          },
          {
            i: 'signatures_count',
            x: 0,
            y: 1,
            w: 1,
            maxH: 'Infinity',
          },

          {
            i: 'action_container',
            x: 0,
            y: 0,
            w: 12,
            h: 1,
          },
          {
            i: 'signatures_expanded_list',
            x: 0,
            y: 0,
            w: 12,
            h: 1,
          },
        ],
      },
    },
  ],

  view_layout: {
    width: '100%',
    no_of_cols: 12,
    rows: [
      {
        i: 'signatures_container',
        x: 0,
        y: 0,
        w: 12,
        h: 1,
      },
    ],
  },
};
