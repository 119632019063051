import React, { memo, useLayoutEffect, useRef, useState } from 'react';
import { Handle, Position } from 'reactflow';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';
import {
  ArrowBackIcon,
  ArrowDownIcon,
  ArrowForwardIcon,
  ArrowUpIcon,
} from '@chakra-ui/icons';
import { useSelector } from 'react-redux';
import { TO_DO_STATUS } from '../../../utils/enums/colors';
import { LifecycleStatusTypes } from '../../../utils/enums/lifecycleStatus';

const HandleStyleCheck = {
  top: {
    right: -10,
    top: 7,
    background: '#555',
    minWidth: 20,
    height: 20,
    borderRadius: 4,
    placeItems: 'center',
    display: 'grid',
    color: '#fff',
    zIndex: 2,
  },
  bottom: {
    right: -10,
    top: 33,
    background: '#555',
    minWidth: 20,
    height: 20,
    borderRadius: 4,
    placeItems: 'center',
    display: 'grid',
    color: '#fff',
    zIndex: 2,
  },
};
const HandleStyleTop = {
  top: {
    left: 60,
    top: -10,
    background: '#555',
    minWidth: 20,
    height: 20,
    borderRadius: 4,
    placeItems: 'center',
    display: 'grid',
    color: '#fff',
    zIndex: 2,
  },
  bottom: {
    background: '#555',
    left: 95,
    top: -10,
    minWidth: 20,
    height: 20,
    borderRadius: 4,
    placeItems: 'center',
    display: 'grid',
    color: '#fff',
    zIndex: 2,
  },
};
const HandleStyleBottom = {
  top: {
    left: 60,
    top: 38,
    background: '#555',
    minWidth: 20,
    height: 20,
    borderRadius: 4,
    placeItems: 'center',
    display: 'grid',
    color: '#fff',
    zIndex: 2,
  },
  bottom: {
    background: '#555',
    left: 95,
    top: 38,
    minWidth: 20,
    height: 20,
    borderRadius: 4,
    placeItems: 'center',
    display: 'grid',
    color: '#fff',
    zIndex: 2,
  },
};
const CustomNode = memo(({ data, isConnectable, bgColor }) => {
  const divRef = useRef(null);
  const [innerHeight, setInnerHeight] = useState(40);
  const { templateLifeCycle } = useSelector((state) => state.workFlowMain);

  useLayoutEffect(() => {
    const updateDimensions = () => {
      if (divRef.current) {
        const { height } = divRef.current.getBoundingClientRect();
        setInnerHeight(height);
      }
    };

    updateDimensions();

    const resizeObserver = new ResizeObserver(updateDimensions);
    if (divRef.current) {
      resizeObserver.observe(divRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [data.label]);
  const styleArrow ={ pointerEvents: 'none' }

  return (
    <Box
      bg={bgColor}
      borderRadius="5px"
      pt="10px"
      pb="10px"
      ref={divRef}
      data-testid="HandleStyleCheckBox"
    >
      <Handle
        type="target"
        id="target_2"
        position={Position.Right}
        style={
          templateLifeCycle === LifecycleStatusTypes.APPROVED
            ? { ...HandleStyleCheck.top, visibility: 'hidden' }
            : { ...HandleStyleCheck.top }
        }
        isConnectable={
          templateLifeCycle === LifecycleStatusTypes.APPROVED
            ? false
            : isConnectable
        }
      >
        <ArrowBackIcon style={styleArrow}/>
      </Handle>
      <Handle
        type="source"
        id="source_2"
        position={Position.Right}
        style={
          templateLifeCycle === LifecycleStatusTypes.APPROVED
            ? {
                ...HandleStyleCheck.bottom,
                top: innerHeight > 40 ? innerHeight - 7 : innerHeight - 7,
                visibility: 'hidden',
              }
            : {
                ...HandleStyleCheck.bottom,
                top: innerHeight > 40 ? innerHeight - 7 : innerHeight - 7,
              }
        }
        isConnectable={
          templateLifeCycle === LifecycleStatusTypes.APPROVED
            ? false
            : isConnectable
        }
      >
        <ArrowForwardIcon style={styleArrow} />
      </Handle>
      <div style={{margin:"0.5rem"}} data-testid="HandleStyleCheckLabelDiv" >{data.label}</div>
      <Handle
        type="target"
        id="target_1"
        position={Position.Left}
        style={
          templateLifeCycle === LifecycleStatusTypes.APPROVED
            ? { ...HandleStyleCheck.top, visibility: 'hidden' }
            : { ...HandleStyleCheck.top }
        }
        isConnectable={
          templateLifeCycle === LifecycleStatusTypes.APPROVED
            ? false
            : isConnectable
        }
      >
        <ArrowBackIcon style={styleArrow} />
      </Handle>
      <Handle
        type="source"
        id="source_1"
        position={Position.Left}
        style={
          templateLifeCycle === LifecycleStatusTypes.APPROVED
            ? {
                ...HandleStyleCheck.bottom,
                top: innerHeight > 40 ? innerHeight - 7 : innerHeight - 7,
                visibility: 'hidden',
              }
            : {
                ...HandleStyleCheck.bottom,
                top: innerHeight > 40 ? innerHeight - 7 : innerHeight - 7,
              }
        }
        isConnectable={
          templateLifeCycle === LifecycleStatusTypes.APPROVED
            ? false
            : isConnectable
        }
      >
        <ArrowForwardIcon style={styleArrow} />
      </Handle>
      {/* Top */}
      <Handle
        type="target"
        id="target_3"
        position={Position.Top}
        style={
          templateLifeCycle === LifecycleStatusTypes.APPROVED
            ? { ...HandleStyleTop.top, visibility: 'hidden' }
            : { ...HandleStyleTop.top }
        }
        isConnectable={
          templateLifeCycle === LifecycleStatusTypes.APPROVED
            ? false
            : isConnectable
        }
      >
        <ArrowDownIcon style={styleArrow} />
      </Handle>
      <Handle
        type="source"
        id="source_3"
        position={Position.Top}
        style={
          templateLifeCycle === LifecycleStatusTypes.APPROVED
            ? { ...HandleStyleTop.bottom, visibility: 'hidden' }
            : { ...HandleStyleTop.bottom }
        }
        isConnectable={
          templateLifeCycle === LifecycleStatusTypes.APPROVED
            ? false
            : isConnectable
        }
      >
        <ArrowUpIcon style={styleArrow} />
      </Handle>
      <Handle
        type="target"
        id="target_4"
        position={Position.Bottom}
        style={
          templateLifeCycle === LifecycleStatusTypes.APPROVED
            ? {
                ...HandleStyleBottom.top,
                top: innerHeight > 40 ? innerHeight - 7 : innerHeight - 7,
                visibility: 'hidden',
              }
            : {
                ...HandleStyleBottom.top,
                top: innerHeight > 40 ? innerHeight - 7 : innerHeight - 7,
              }
        }
        isConnectable={
          templateLifeCycle === LifecycleStatusTypes.APPROVED
            ? false
            : isConnectable
        }
      >
        <ArrowUpIcon style={styleArrow} />
      </Handle>
      <Handle
        type="source"
        id="source_4"
        position={Position.Bottom}
        style={
          templateLifeCycle === LifecycleStatusTypes.APPROVED
            ? {
                ...HandleStyleBottom.bottom,
                top: innerHeight > 40 ? innerHeight - 7 : innerHeight - 7,
                visibility: 'hidden',
              }
            : {
                ...HandleStyleBottom.bottom,
                top: innerHeight > 40 ? innerHeight - 7 : innerHeight - 7,
              }
        }
        isConnectable={
          templateLifeCycle === LifecycleStatusTypes.APPROVED
            ? false
            : isConnectable
        }
      >
        <ArrowDownIcon style={styleArrow}/>
      </Handle>
    </Box>
  );
});
CustomNode.propTypes = {
  data: PropTypes.object.isRequired,
  isConnectable: PropTypes.bool.isRequired,
  bgColor: PropTypes.string,
};
CustomNode.defaultProps = {
  bgColor: TO_DO_STATUS,
};

export default CustomNode;
