/* eslint-disable import/prefer-default-export */
export const conditionsExpressions = {
  CONDITION_COMPONENT_HEADER: 'Condition',
  DYNAMIC_EXPRESSION_CONDITION_INITIAL_HEADER: 'IF THE FOLLOWING IS TRUE',
  DYNAMIC_EXPRESSION_CONDITION_HEADER: 'AND THE FOLLOWING IS TRUE',
  BUTTON_ADD_AND_CONDITION_TEXT: 'Add AND Condition',
  BUTTON_ADD_OR_CONDITION_TEXT: 'Add OR Condition',
  BUTTON_ADD_AND_OPERATION_TEXT: 'Add AND',
  BUTTON_ADD_OR_OPERATION_TEXT: 'Add OR',
  DYNAMIC_EXPRESSION_CONDITION_ADD_HEADER: 'AND THE FOLLOWING IS TRUE',
  DYNAMIC_EXPRESSION_CONDITION_OR_HEADER: 'OR THE FOLLOWING IS TRUE',
};
