/* eslint-disable import/no-cycle */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import _ from 'lodash';
// import { TriangleDownIcon } from '@chakra-ui/icons';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import {
  // Button,
  useColorModeValue,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
  Tooltip,
  Box,
} from '@chakra-ui/react';
import TemplateApproveRejectModal from './customTemplateModal/TemplateApproveRejectModal';
import { LifecycleStatusTypes } from '../../utils/enums/lifecycleStatus';
import useKeycloak from '../../utils/hooks/useKeyCloak';
import { formatStatus } from './helper';
import { COLORS } from '../../utils/enums/colors';
import { enableApproveOnlyForOwnersInTemplate } from '../../utils/helper/roleBasedAccess';
import { TEMPLATE_TOOLTIPS } from '../../utils/enums/labels';

const ApproveRejectFlow = () => {
  const [openModal, setOpenModal] = useState(false);
  const { id } = useParams();
  const params = new URLSearchParams(window.location.search);
  const [transitionOptions, setTransitionOptions] = useState([]);
  const [transitionOption, setTransitionOption] = useState();
  // eslint-disable-next-line no-unused-vars
  const [isStatusEditable, setIsStatusEditable] = useState(false);
  const [role, setRole] = useState();
  const name = params.get('name');
  const [showTooltip, setShowTooltip] = useState(false);

  const lifeCycleRef = useRef(null);

  const [keycloak] = useKeycloak();
  const roles = keycloak?.realmAccess?.roles;
  const getRole = () => {
    if (roles) {
      if (roles.includes('ticket_admin')) {
        setRole(roles.includes('ticket_agent') ? 'both' : 'ticket_admin');
      } else if (roles.includes('ticket_agent')) {
        setRole('ticket_agent');
      } else setRole('none');
    }
  };

  useEffect(() => {
    getRole();
  }, [roles]);

  // const navigate = useNavigate();
  const selectedTemplate = useSelector(
    (state) => state?.viewState?.selectedTemplate
  );
  const templateStatus = selectedTemplate?.lifecycle_status || ' ';
  const onChangeTransitionStatus = (item) => {
    setTransitionOption(item);
    setOpenModal(true);
  };

  const otherOptions = [
    { label: 'Draft', value: LifecycleStatusTypes.DRAFT },
    { label: 'Send To Review', value: LifecycleStatusTypes.PENDING_REVIEW },
  ];
  const adminOption = [
    { label: 'Draft', value: LifecycleStatusTypes.DRAFT },
    { label: 'Approve', value: LifecycleStatusTypes.APPROVED },
    { label: 'Reject', value: LifecycleStatusTypes.REJECTED },
  ];

  const getByLabelText = (value) => {
    if (value === null || value === undefined) {
      return '';
    }
    const option = _.find([...adminOption, ...otherOptions], {
      value,
    });
    return option ?? '';
  };

  const loadTransitionStatusBasedOnRoles = async () => {
    if (role === 'none') {
      setTransitionOptions([]);
      setTransitionOption();
      // navigate('/notfound');
      return;
    }

    if (role === 'ticket_admin') {
      if (templateStatus === LifecycleStatusTypes.DRAFT) {
        setIsStatusEditable(false);
        setTransitionOptions([]);
      } else if (templateStatus === LifecycleStatusTypes.PENDING_REVIEW) {
        setIsStatusEditable(true);
        setTransitionOptions([
          {
            label: 'Approve',
            value: LifecycleStatusTypes.APPROVED,
          },
          {
            label: 'Reject',
            value: LifecycleStatusTypes.REJECTED,
          },
        ]);
      } else if (templateStatus === LifecycleStatusTypes.APPROVED) {
        setIsStatusEditable(true);
        setTransitionOptions([
          {
            label: 'Send To Draft',
            value: LifecycleStatusTypes.DRAFT,
          },
        ]);
      }
    } else if (role === 'ticket_agent') {
      if (templateStatus === LifecycleStatusTypes.DRAFT) {
        setIsStatusEditable(true);
        setTransitionOptions([
          {
            label: 'Send To Review',
            value: LifecycleStatusTypes.PENDING_REVIEW,
          },
        ]);
      } else if (templateStatus === LifecycleStatusTypes.PENDING_REVIEW) {
        setIsStatusEditable(true);
        setTransitionOptions([
          {
            label: 'Send To Draft',
            value: LifecycleStatusTypes.DRAFT,
          },
        ]);
      } else if (templateStatus === LifecycleStatusTypes.APPROVED) {
        setIsStatusEditable(false);
        setTransitionOptions([]);
      }
    } else if (role === 'both') {
      if (templateStatus === LifecycleStatusTypes.DRAFT) {
        setIsStatusEditable(true);
        setTransitionOptions([
          {
            label: 'Send To Review',
            value: LifecycleStatusTypes.PENDING_REVIEW,
          },
        ]);
      } else if (templateStatus === LifecycleStatusTypes.PENDING_REVIEW) {
        setIsStatusEditable(true);
        setTransitionOptions([
          {
            label: 'Approve',
            value: LifecycleStatusTypes.APPROVED,
          },
          {
            label: 'Reject',
            value: LifecycleStatusTypes.REJECTED,
          },
        ]);
      } else if (templateStatus === LifecycleStatusTypes.APPROVED) {
        setIsStatusEditable(true);
        setTransitionOptions([
          {
            label: 'Send To Draft',
            value: LifecycleStatusTypes.DRAFT,
          },
        ]);
      }
    }

    if (selectedTemplate?.lifecycle_status) {
      setTransitionOption(getByLabelText(selectedTemplate?.lifecycle_status));
    }
  };

  useEffect(() => {
    if (openModal === false && roles) {
      loadTransitionStatusBasedOnRoles();
    }
  }, [id, openModal, selectedTemplate, templateStatus, role, roles]);

  const onMouseEnter = useCallback(() => {
    if (lifeCycleRef.current) {
      if (
        lifeCycleRef.current?.clientWidth < lifeCycleRef.current?.scrollWidth
      ) {
        setShowTooltip(true);
      }
    }
  }, []);

  const onMouseLeave = useCallback(() => {
    setShowTooltip(false);
  }, []);

  const shouldDisableApproveButton = (label) =>
    enableApproveOnlyForOwnersInTemplate(selectedTemplate, label);

  return (
    <>
      {templateStatus && (
        <Menu data-testid="ApproveRejectFlowContainerMenu">
          <Tooltip
            placement="top"
            isOpen={showTooltip}
            label={formatStatus(templateStatus)}
            data-testid="ApproveRejectFlowMenuButtonTooltip"
          >
            <MenuButton position="relative">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                rounded="6px"
                border="1px solid"
                borderColor={useColorModeValue('#e2e8f0', '#3F444E')}
                width="100%"
                color={useColorModeValue('#6B7280', COLORS.WHITE)}
                data-testid="ApproveRejectFlowMenuButton"
                onMouseLeave={onMouseLeave}
                onMouseEnter={onMouseEnter}
                _hover={{
                  bg: useColorModeValue(COLORS.WHITE, COLORS.DARK),
                  color: useColorModeValue(COLORS.TEXT_LIGHT, COLORS.WHITE),
                }}
              >
                <p
                  ref={lifeCycleRef}
                  data-testid="ApproveRejectFlowStatusTextP"
                  className="px-6 text-sm font-medium text-start leading-5 ellipsis"
                >
                  {formatStatus(templateStatus)}
                </p>
                <Box
                  borderLeft="1px solid"
                  borderColor={useColorModeValue('#e2e8f0', '#3F444E')}
                  px="4px"
                  py="6px"
                >
                  <ChevronDownIcon width="16px" height="16px" color="#94A3B8" />
                </Box>
              </Box>
            </MenuButton>
          </Tooltip>
          {transitionOptions?.length > 0 && (
            <MenuList
              left={1}
              position="relative"
              zIndex={30}
              data-testid="ApproveRejectFlowMenuList"
            >
              {transitionOptions?.map((i) => {
                const isDisabled = shouldDisableApproveButton(i?.label);

                return (
                  <Tooltip
                    hasArrow
                    key={i?.id}
                    label={isDisabled ? TEMPLATE_TOOLTIPS.ONLY_OWNERS : ''}
                  >
                    <MenuItem
                      fontWeight={600}
                      fontSize="14px"
                      key={i?.id}
                      onClick={() => onChangeTransitionStatus(i)}
                      isDisabled={isDisabled}
                    >
                      {i?.label}
                    </MenuItem>
                  </Tooltip>
                );
              })}
            </MenuList>
          )}
        </Menu>
      )}
      <TemplateApproveRejectModal
        isOpen={openModal}
        setIsOpen={setOpenModal}
        templateName={name}
        templateId={id}
        statusType={transitionOption?.value}
        selectedTemplate={selectedTemplate}
        transitionLabel={transitionOption?.label}
      />
    </>
  );
};
export default ApproveRejectFlow;
