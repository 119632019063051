import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allTemplateFieldsObj: {},
  isLoading: false,
  isError: false,
};

const allTemplateFields = createSlice({
  name: 'allTemplateFields',
  initialState,
  reducers: {
    reset: (state) => {
      state.allTemplateFieldsObj = {};
      state.isError = false;
      state.error = '';
    },
    updateFielsStore: (state, action) => {
      state.allTemplateFieldsObj = action.payload;
    },
  },
});

export const { reset, updateFielsStore } = allTemplateFields.actions;

export default allTemplateFields.reducer;
