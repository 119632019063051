/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux';
import allTemplates from '../redux/TicketTemplate/AllTemplatesReducer/AllTemplateSlice';
import allTemplateFields from '../redux/TicketTemplate/TemplateFieldsReducer/FieldSlice';
import allFieldsets from '../redux/TicketTemplate/AllFieldsetReducer/AllFieldsetSlice';
import allComposite from '../redux/TicketTemplate/AllCompositeReducer/AllCompositeSlice';
import allRoutes from '../redux/AllRouteReducer/AllRouteSlice';
import allModals from '../redux/ModalReducer/ModalSlice';
import viewState from '../redux/ViewSlice/ViewReducer';
import workFlowRules from '../redux/WorkFlowRuleSlice/WorkFlowRuleReducer';
import workFlowMain from '../redux/WorkFlowMainSlice/WorkFlowMainReducer';
import cards from '../redux/CardReducer/cardSlice';
import filterDatesReducer from '../redux/CardReducer/createFilterSlice';
import quickFilterReducer from '../redux/CardReducer/quickFilterSlice';
import allcardReducer from '../redux/CardReducer/AllCardSlice';
import clonedCardsReducer from '../redux/CardReducer/clonedCardSlice';
import deleteCardSlice from '../redux/CardReducer/deleteCardsSlice';
import recentCardsReducer from '../redux/CardReducer/recentCardSlice';
import favoritesCardsReducer from '../redux/CardReducer/favoritesSlice';
import notificationList from '../redux/CardReducer/notificationCard';
import metaFields from '../redux/MetaFieldsSlice/MetaFieldsReducer';
import mailServer from '../redux/ViewSlice/MailServerReducer';
import fieldDefaults from '../redux/FieldDefaults';
import locales from '../redux/CardReducer/localeSlice';
import userInfoRole from '../redux/UserService/UserReducer';

const rootReducer = combineReducers({
  allTemplates,
  allTemplateFields,
  allFieldsets,
  allComposite,
  allRoutes,
  allModals,
  viewState,
  workFlowRules,
  workFlowMain,
  cards,
  filterDates: filterDatesReducer,
  quickFilter: quickFilterReducer,
  allcard: allcardReducer,
  clonedCards: clonedCardsReducer,
  deleteCard: deleteCardSlice,
  recentCards: recentCardsReducer,
  favorites: favoritesCardsReducer,
  notificationList,
  metaFields,
  mailServer,
  fieldDefaults,
  locales,
  userInfoRole,
});

export default rootReducer;
