/* eslint-disable import/no-cycle */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ReactWindowComponent from './ReactWindowComponent';
import axiosInstance from '../../utils/axios/axios';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';

const InfiniteScrollComponent = ({
  endPoint,
  requestedCols,
  orderBy,
  columns,
  onClickListItem,
  name,
}) => {
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const top = 8;
  const [skip, setSkip] = useState(0);
  const { viewState } = useSelector((state) => state);
  const dispatch = useDispatch();

  const loadNextPage = () => {
    setIsNextPageLoading(true);
    setHasNextPage(false);
    axiosInstance
      .get(
        `${endPoint}?$top=${top}&$skip=${skip}&$select=${requestedCols?.toString()}&$orderby=${orderBy}`
      )
      .then((response) => {
        dispatch(
          updateViewState({
            stateKey: name,
            value: {
              filter: '',
              data: [
                ...viewState?.[name]?.data,
                ...(response?.data?.rows || []),
              ],
            },
          })
        );

        setHasNextPage(viewState?.[name]?.data.length < response?.data?.count);
        setIsNextPageLoading(false);
        setSkip(top + skip);
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error) => {});
  };

  return (
    <ReactWindowComponent
      hasNextPage={hasNextPage}
      isNextPageLoading={isNextPageLoading}
      items={viewState?.[name]?.data}
      loadNextPage={loadNextPage}
      columns={columns}
      height={750}
      onClickListItem={onClickListItem}
    />
  );
};

InfiniteScrollComponent.propTypes = {
  endPoint: PropTypes.string.isRequired,
  requestedCols: PropTypes.array.isRequired,
  orderBy: PropTypes.string.isRequired,
  columns: PropTypes.string.isRequired,
  onClickListItem: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default InfiniteScrollComponent;
