import { Flex, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';
import CustomButton from '../../../components/Button/SimpleButton';
import { BLACK,  WHITE } from '../../../utils/enums/colors';
import { LABELS } from '../../../utils/enums/labels';
import { PrimaryCustomButton, SecondaryCustomButton } from '../../../components/Button/PrimarySecondaryButton';

const CustomModalFooter = ({
  onCancelClick,
  onAddClick,
  btnLabel,
  isDisabled,
  pt,
  isLoading,
}) => (
  <Flex gap="8px" pt={pt} data-testid="CustomModalFooterFlex">
    <SecondaryCustomButton
      buttonText={LABELS.ACTIONS.CANCEL}
      onClick={onCancelClick}
      id="CustomModalFooterFlexCancelBtn"
    />
    <PrimaryCustomButton
      isDisabled={isDisabled}
      buttonText={btnLabel}
      onClick={onAddClick}
      isLoading={isLoading || false}
      id="CustomModalFooterFlexAddBtn"
    />
  </Flex>
);
CustomModalFooter.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  btnLabel: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  pt: PropTypes.string,
  isLoading: PropTypes.bool,
};
CustomModalFooter.defaultProps = {
  pt: '1rem',
  isLoading: false,
};
export default CustomModalFooter;
