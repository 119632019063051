/* eslint-disable no-await-in-loop */
import React from 'react';

import { Flex } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';

import Fields from './Fields';

import { FieldTypes } from '../../../utils/enums/types';
import { boolSelectOption } from '../../../utils/enums/selectOption';
import { createMap } from '../MetricsAndSLA/Metrics/utils';
import axiosInstance from '../../../utils/axios/axios';
import { DROPDOWN_TYPES } from '../../TenantFilters/filters.constants';

const ENRICH_BOOLEAN_MAP = boolSelectOption.map((op) => ({
  value: op.value,
  label: op.name,
}));

const BOOLEAN_MAP = createMap(ENRICH_BOOLEAN_MAP);

export const getDynamicCurlMap = (() => {
  let curlMap = null;
  return (type, curl, value) => {
    switch (type) {
      case 'SET':
        curlMap = {
          ...curlMap,
          [curl]: value,
        };
        return curlMap;
      case 'GET':
        return curlMap?.[curl];
      case 'RESET':
        curlMap = null;
        return curlMap;
      default:
        return curlMap;
    }
  };
})();

export const setDefaultValue = (type, val) => {
  if (type === FieldTypes.BOOLEAN) {
    return BOOLEAN_MAP[val] ? val : '';
  }

  return val || '';
};

const getOptionsForDynamic = async (curl = '') => {
  if (!curl) {
    return [];
  }

  const storedData = getDynamicCurlMap('GET', curl);

  if (storedData) {
    return storedData;
  }

  try {
    const res = await axiosInstance.post('/curl/execute', {
      data: {
        curl,
      },
    });
    getDynamicCurlMap('SET', curl, res.data);
    return res.data;
  } catch (err) {
    return [];
  }
};

export const getOptionsForStatic = (str = '') => {
  const options = str.split('\n');
  return options.map((op) => ({
    value: op,
    label: op,
  }));
};

export const getValues = async (data) => {
  const collections = data?.type_based_attributes?.collection || {};

  const {
    collection_type: collectionType,
    staticValues,
    dynamicCurl,
  } = collections;

  const types = [FieldTypes.DROPDOWN, FieldTypes.TAGS];

  if (types.includes(data.type)) {
    if (collectionType === DROPDOWN_TYPES.STATIC) {
      return getOptionsForStatic(staticValues);
    }
    const options = await getOptionsForDynamic(dynamicCurl);
    return options;
  }

  return data?.values || [];
};

export const getDefaultValue = ({ type, val, collection = {} }) => {
  const { collection_type: collectionType } = collection;

  switch (type) {
    case FieldTypes.BOOLEAN:
      return { label: BOOLEAN_MAP[val], value: val };

    case FieldTypes.DROPDOWN:
    case FieldTypes.TAGS:
      if (collectionType === DROPDOWN_TYPES.STATIC) {
        return { label: val, value: val };
      }

      if (collectionType === DROPDOWN_TYPES.DYNAMIC) {
        return val;
      }

      return null;

    default:
      return val;
  }
};

export const getOptions = async (rowData) => {
  if (rowData.type === FieldTypes.BOOLEAN) {
    return ENRICH_BOOLEAN_MAP;
  }

  const TYPES = [FieldTypes.TAGS, FieldTypes.DROPDOWN];

  if (TYPES.includes(rowData.type)) {
    return rowData?.values || [];
  }

  return [];
};

export const columns = ({ updateValue, onDelete }) => [
  {
    col_name: 'field_name',
    type: 'string',
  },
  {
    col_name: 'type',
    type: 'type',
  },
  {
    col_name: 'default_value',
    type: 'string',
    cellRenderer: (params) => {
      const { rowData } = params;

      return (
        <Fields
          fieldType={rowData.type}
          options={getOptions(rowData)}
          id={rowData.id}
          updateValue={updateValue}
          defaultValue={getDefaultValue({
            type: rowData.type,
            val: rowData.default_value,
            collection: rowData.collection,
          })}
          rowData={rowData}
        />
      );
    },
  },
  {
    col_name: 'actions',
    type: 'actions',
    cellRenderer: ({ rowData }) => (
      <Flex justifyContent="center" gap="5px">
        <DeleteIcon
          className="cursor-pointer"
          onClick={() => onDelete(rowData.id)}
        />
      </Flex>
    ),
  },
];
