import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Define the async thunk for adding cloned cards
export const addClonedCard = createAsyncThunk(
  'clonedCards/addClonedCard',
  async (templateId) => {
    try {
      const response = await axios.post('/templates/clone', {
        templateId,
      });

      return response.data;
    } catch (error) {
      throw Error('Error cloning card');
    }
  }
);

const clonedCardsSlice = createSlice({
  name: 'clonedCards',
  initialState: [],
  reducers: {
    updateAllCloneCards: (state, action) => {
      state = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addClonedCard.fulfilled, (state, action) => {
      state.push(action.payload);
    });
  },
});

export const { updateAllCloneCards } = clonedCardsSlice.actions;

export default clonedCardsSlice.reducer;
