/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { checkVisibility } from './utils';
import axiosInstance from '../../utils/axios/axios';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';

const ContainerView = ({
  name,
  children,
  viewLayout,
  style,
  loadInitialData,
  enpPoint,
  methodType,
  viewStateName,
  responsePath = '',
  show,
  fallback,
}) => {
  const [detailsItems, setDetailstems] = useState([]);
  const { viewState } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const loadData = async () => {
    let data = {};
    if (methodType === 'get') {
      const response = await axiosInstance.get(
        `${enpPoint}${id ? `&$filter=id eq ${id}` : ''}`
      );
      data = _.get(response, responsePath);
    }
    dispatch(
      updateViewState({
        stateKey: viewStateName,
        value: data,
      })
    );
  };

  useEffect(() => {
    if (loadInitialData) {
      loadData();
    }
  }, [loadInitialData, id]);

  const sortByViewLayout = (items, viewLayout) => {
    if (JSON.stringify(viewLayout) === JSON.stringify({})) return items;
    let merged = [];
    for (let i = 0; i < viewLayout?.rows?.length; i++) {
      merged.push({
        ...viewLayout?.rows[i],
        ...items.find(
          (itmInner) => itmInner?.props?.name === viewLayout?.rows[i].i
        ),
      });
    }
    merged = merged.filter((i) => JSON.stringify(i) !== JSON.stringify({}));
    return merged;
  };
  useEffect(() => {
    setDetailstems(sortByViewLayout(children, viewLayout));
  }, []);

  const showPage = useMemo(() => {
    if (typeof show === 'function') {
      return show({ viewState });
    }
    return show;
  }, [show, viewState]);

  const content = () => {
    if (showPage) {
      return (
        <Grid
          templateColumns={`repeat(${viewLayout?.no_of_cols}, 1fr)`}
          gap={2.5}
          alignItems="center"
          data-testid="ContainerViewGrid"
        >
          {detailsItems?.map((i) => (
            <GridItem
              key={i.id}
              style={i?.style}
              colSpan={i.w}
              colEnd={i.colEnd}
              data-testid="ContainerViewGridItem"
            >
              {checkVisibility(i, viewState) ? i : ''}
            </GridItem>
          ))}
        </Grid>
      );
    }

    if (typeof fallback === 'function') {
      fallback({ navigate });
    }

    return null;
  };

  return (
    <Box
      className="container-view"
      style={style}
      data-testid="ContainerViewBox"
    >
      {content()}
    </Box>
  );
};

ContainerView.propTypes = {
  name: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  viewLayout: PropTypes.object.isRequired,
  loadInitialData: PropTypes.bool.isRequired,
  enpPoint: PropTypes.string.isRequired,
  methodType: PropTypes.string.isRequired,
  viewStateName: PropTypes.string.isRequired,
  responsePath: PropTypes.string.isRequired,
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  fallback: PropTypes.func,
};

ContainerView.defaultProps = {
  show: true,
  fallback: null,
};

export default ContainerView;
