/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { CopyIcon, DownloadIcon } from '@chakra-ui/icons';
import { Box, Flex, Spacer } from '@chakra-ui/layout';
import PropTypes from 'prop-types';
import CustomButton from '../../../components/Button/SimpleButton';
// import CustomModal from '../../../components/Modal/Modal';
import styles from './customTemplate.module.scss';
import { COLORS } from '../../../utils/enums/colors';
import { LABELS } from '../../../utils/enums/labels';
import axiosInstance from '../../../utils/axios/axios';
import CustomToast from '../../../components/Notification/Notification';
import { CustomDrawer } from '../../TicketTemplateBuilder/ConfigureTemplateView/AddEditTemplateLayoutModal';

const CustomTemplateJSONViewer = ({
  isJsonModal,
  setIsJsonModal,
  // templateName,
  templateId,
}) => {
  const [templateJson, setTemplateJson] = useState({});
  const { addToast } = CustomToast();

  const loadTemplateJson = async () => {
    const response = await axiosInstance.get(
      `templates/list?$top=1&$skip=0&$select=*&$filter=id eq ${templateId}`
    );
    if (response?.data) {
      setTemplateJson(response?.data?.rows[0]);
    }
  };

  const exportToJson = (objectData) => {
    const filename = `template-${templateId}.json`;
    const contentType = 'application/json;charset=utf-8;';
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      const blob = new Blob(
        [decodeURIComponent(encodeURI(JSON.stringify(objectData)))],
        { type: contentType }
      );
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement('a');
      a.download = filename;
      a.href = `data:${contentType},${encodeURIComponent(
        JSON.stringify(objectData)
      )}`;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const handleCopy = () => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      const jsonText = JSON.stringify(templateJson, null, 4);
      navigator.clipboard.writeText(jsonText);
      addToast({
        type: 'success',
        title: '',
        message: 'copied successfully',
        position: 'top-center',
      });
    } else {
      addToast({
        type: 'error',
        title: '',
        message: 'Copy to clipboard is not supported',
        position: 'top-center',
      });
    }
  };

  useEffect(() => {
    loadTemplateJson();
  }, []);

  return (
    <CustomDrawer
      drawerBody={
        <>
          <Flex data-testid="CustomTemplateJSONViewerFlexContainer">
            {/* <h1 data-testid="CustomTemplateJSONViewerHeaderH1">
          {templateName}
        </h1> */}
            <Spacer data-testid="CustomTemplateJSONViewerSpacer" />
            <Flex
              data-testid="CustomTemplateJSONViewerInnerContainerFlex"
            >
              <CustomButton
                leftIcon={<CopyIcon />}
                color={useColorModeValue('black', 'white')}
                onClick={handleCopy}
                id="CustomTemplateJSONVieweBtncopy"
                buttonText={LABELS.ACTIONS.COPY}
                variant="outline"
                mr={4}
                className={styles.lightBorderbutton}
              />
              <CustomButton
                leftIcon={<DownloadIcon />}
                color={useColorModeValue('black', 'white')}
                onClick={() => exportToJson(templateJson)}
                id="CustomTemplateJSONViewerBtndownload"
                buttonText={LABELS.ACTIONS.DOWNLOAD}
                variant="outline"
                className={styles.lightBorderbutton}
              />
            </Flex>
          </Flex>
          <Box
            mt="15px"
            borderWidth="1px"
            borderRadius="lg"
            maxHeight="430px"
            overflowY="auto"
          >
            <pre>{JSON.stringify(templateJson, null, 2)}</pre>
          </Box>
        </>
      }
      drawerFooter={
        <Flex
          gap="8px"
          data-testid="CustomTemplateJSONViewerButtonContainerFlex"
        >
          <CustomButton
            color="white"
            id="CustomTemplateJSONViewerBtnsave"
            buttonText={LABELS.ACTIONS.CLOSE}
            variant="solid"
            className={styles.createTemplateBtn}
            bg={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
            onClick={() => setIsJsonModal(false)}
          />
        </Flex>
      }
      modalTitle={LABELS.ACTIONS.VIEW_JSON}
      openDrawer={isJsonModal}
      setOpenDrawer={setIsJsonModal}
    />
  );
};

CustomTemplateJSONViewer.propTypes = {
  // templateName: PropTypes.string.isRequired,
  isJsonModal: PropTypes.bool.isRequired,
  setIsJsonModal: PropTypes.func.isRequired,
  templateId: PropTypes.string.isRequired,
};
export default CustomTemplateJSONViewer;
