/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import CustomInput from '../InputBox/Input';
import CustomButton from '../Button/SimpleButton';
import { LABELS } from '../../utils/enums/labels';
import { BLACK, WHITE } from '../../utils/enums/colors';
import { cloneApiCall, updateCloneMetricSlaName } from './helper';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import { FORM_KEY_MAP } from '../../pages/DynamicRenderer/MetricsAndSLA/Metrics/utils';
import {
  patchDataForTemplateWithPath,
  patchMultipleFieldsForTemplate,
  showToastMessage,
} from '../../pages/TicketTemplateBuilder/utils/templateAPIUtils';
import {
  updateTemplate,
} from '../../pages/DynamicRenderer/customEventAndTriggers/customEventAndTriggers.helper';
import {
  COMMUNICATION_TEMPLATES,
  EMAIL_TEMPLATE_KEY_MAP,
} from '../../pages/CommunicationTemplates/utills';
import { QUICK_ACTION_DATA_KEYS } from '../../pages/QuickAction/quickAction.constants';
import { CustomDrawer } from '../../pages/TicketTemplateBuilder/ConfigureTemplateView/AddEditTemplateLayoutModal';
import { addOrEditTemplateLayoutApi } from '../../pages/TicketTemplateBuilder/utils/helper';
import { validateSlaMetricFieldCannotHaveSameName } from '../../pages/DynamicRenderer/duplicateMetricSlaFieldsVerificationHelper';

const CrudCloneTemplate = ({
  title,
  isOpen,
  setIsOpen,
  id,
  onModalClose,
  labelKey = '',
  masterType,
  targetUrl,
  listKey,
  patchFilterId = '',
  method,
  patchData,
  ...props
}) => {
  const [name, setName] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const dispatch = useDispatch();

  const viewState = useSelector((state) => state.viewState);
  const onClone = async () => {
    if (!name) {
      setErrorMessage('Please input name');
      return;
    }
    setIsLoading(true);
    if (method === 'post') {
      const res = await cloneApiCall({ id, name }, targetUrl, masterType);
      if (!res.error) {
        let updatedList = _.cloneDeep(viewState[listKey]);
        const responseData = res?.response;
        const COUNT_NO = viewState[listKey]?.count || 0;
        if (COUNT_NO > 0) {
          updatedList.count += 1;
        }
        if (updatedList && updatedList.data) {
          updatedList.data = Array.isArray(updatedList.data)
            ? [responseData, ...updatedList.data]
            : [responseData];
        } else {
          const newData = responseData ? [responseData] : [];
          updatedList = { ...updatedList, data: newData };
        }

        dispatch(
          updateViewState({
            stateKey: listKey,
            value: updatedList,
          })
        );
        setIsOpen(false);
        onModalClose();
      } else {
        setErrorMessage(res?.error);
      }
    }
    if (method === 'patch' && masterType === 'metric') {
      const tempId = uuidv4();
      const METRIC_DATA = viewState?.selectedTemplate?.metric || [];

      // clone metric name can not be same as field, sla or any other metric name
      const validationMessage = validateSlaMetricFieldCannotHaveSameName(name?.toLowerCase(),viewState?.selectedTemplate, patchData.id)
      if(validationMessage){
          showToastMessage({
            description: validationMessage,
            status: 'info',
          });
          return
        }
      const newData = {
        ...patchData,
        id: tempId,
        [FORM_KEY_MAP.NAME]: `${name || ''}`,
        [FORM_KEY_MAP.UPDATED_AT]: new Date().getTime(),
        [FORM_KEY_MAP.UPDATED_BY]: localStorage.getItem('userName') || '',
      };
      
      const tempMetricData = [...METRIC_DATA];
      const rowIndex = METRIC_DATA.findIndex((m) => m.id === patchData.id);

      tempMetricData.splice(rowIndex + 1, 0, newData);

      const SLA_DATA = viewState?.selectedTemplate?.slas || {};
      // Copying the SLAs for the metric as well
      const requestPayloadSla = {
        ...SLA_DATA,
        [tempId]: updateCloneMetricSlaName(SLA_DATA[patchData.id], name),
      };

      const res = await patchMultipleFieldsForTemplate({
        id,
        payload: {
          metric: tempMetricData,
          slas: requestPayloadSla,
        },
        defaultErrorMessage: 'Failed to clone metric',
        successMessage: 'Successfully cloned metric',
      });

      if (!res.error) {
        dispatch(
          updateViewState({
            stateKey: 'selectedTemplate',
            value: {
              ...viewState?.selectedTemplate,
              ...(res?.response?.data || {}),
              metric: tempMetricData,
              slas: requestPayloadSla,
            },
          })
        );
        setIsOpen(false);
        onModalClose();
      } else {
        setErrorMessage(res?.error);
      }
    }
    if (method === 'patch' && masterType === 'event') {
      const rowIndex = patchData.eventSettingList.findIndex(
        (e) => e.id === patchData.cloneItemId
      );
      const newData = {
        ...patchData?.eventSettingList[rowIndex],
        event_name: `${name}`,
        id: uuidv4(),
        wasApproved: false,
      };

      const res = await patchDataForTemplateWithPath({
        data: {
          path: 'events.events',
          operation: 'create',
          data: newData,
        },
        id,
        defaultErrorMessage: 'Failed to clone the event',
        successMessage: 'Successfully cloned the event',
      });

      if (!res.error) {
        dispatch(
          updateViewState({
            stateKey: 'selectedTemplate',
            value: res?.response?.data,
          })
        );
        setIsOpen(false);
        onModalClose();
      } else {
        setErrorMessage(res?.error);
      }
    }
    if (method === 'patch' && masterType === 'action') {
      const newEventSettings = _.cloneDeep();
      const rowIndex = patchData?.eventSettingList.findIndex(
        (e) => e.id === patchData?.cloneItemId
      );
      const newData = {
        ...patchData?.eventSettingList[rowIndex],
        action_name: `${name}`,
        id: uuidv4(),
        wasApproved: false,
      };

      const res = await patchDataForTemplateWithPath({
        data: {
          path: 'events.actions',
          operation: 'create',
          data: newData,
        },
        id,
        defaultErrorMessage: 'Failed to clone the action',
        successMessage: 'Successfully cloned the action',
      });
      if (!res.error) {
        dispatch(
          updateViewState({
            stateKey: 'selectedTemplate',
            value: res?.response?.data,
          })
        );
        setIsOpen(false);
        onModalClose();
      } else {
        setErrorMessage(res?.error);
      }
    }
    if (method === 'patch' && masterType === 'quick action') {
      const tempId = uuidv4();
      const QUICK_ACTION_DATA =
        viewState?.selectedTemplate?.[QUICK_ACTION_DATA_KEYS.QUICK_ACTIONS] ||
        [];
      const newData = {
        ...patchData.data,
        id: tempId,
        [FORM_KEY_MAP.NAME]: `${name || ''} `,
      };

      const tempQuickActionData = [...QUICK_ACTION_DATA];
      const rowIndex = QUICK_ACTION_DATA.findIndex(
        (m) => m.id === patchData?.data?.id
      );

      tempQuickActionData.splice(rowIndex + 1, 0, newData);

      const res = await updateTemplate({
        data: tempQuickActionData,
        id,
        defaultErrorMessage: 'Failed to clone the action',
        successMessage: 'Successfully cloned the action',
        key: [QUICK_ACTION_DATA_KEYS.QUICK_ACTIONS],
      });
      if (!res.error) {
        dispatch(
          updateViewState({
            stateKey: 'selectedTemplate',
            value: res?.response?.data,
          })
        );
        setIsOpen(false);
        onModalClose();
      } else {
        setErrorMessage(res?.error);
      }
    }
    if (method === 'patch' && masterType === 'layout') {
      const tempId = uuidv4();
      const QUICK_ACTION_DATA = viewState?.selectedTemplate?.layouts || [];
      const newData = {
        ...patchData.data,
        id: tempId,
        [FORM_KEY_MAP.NAME]: `${name || ''} `,
      };

      const res = await addOrEditTemplateLayoutApi({
        data: {
          path: 'layouts',
          operation: 'create',
          data: newData,
        },
        id,
        defaultErrorMessage: 'Failed to clone the layout',
        successMessage: 'Successfully cloned the layout',
      });
      if (!res.error) {
        dispatch(
          updateViewState({
            stateKey: 'selectedTemplate',
            value: res?.response?.data,
          })
        );
        setIsOpen(false);
        onModalClose();
      } else {
        setErrorMessage(res?.error);
      }
    }
    if (method === 'patch' && masterType?.includes('communication')) {
      const tempId = uuidv4();
      const COMMUNICATION_DATA =
        viewState?.selectedTemplate?.[COMMUNICATION_TEMPLATES] || [];

      const newData = {
        ...patchData.data,
        id: tempId,
        [FORM_KEY_MAP.NAME]: `${name || ''} `,
        [EMAIL_TEMPLATE_KEY_MAP.CREATED_BY]: localStorage.getItem('userEmail'),
      };

      // const tempCommunicationData = [...COMMUNICATION_DATA];
      // const rowIndex = COMMUNICATION_DATA.findIndex(
      //   (m) => m.id === patchData?.data?.id
      // );

      // tempCommunicationData.splice(rowIndex + 1, 0, newData);

      const res = await patchDataForTemplateWithPath({
        data: {
          path: COMMUNICATION_TEMPLATES,
          operation: 'create',
          data: newData,
        },
        id,
        key: [COMMUNICATION_TEMPLATES],
        defaultErrorMessage: patchData.toastFailedMsg,
        successMessage: patchData.toastSuccessMsg,
      });
      if (!res.error) {
        dispatch(
          updateViewState({
            stateKey: 'selectedTemplate',
            value: res?.response?.data,
          })
        );
        setIsOpen(false);
        onModalClose();
      } else {
        setErrorMessage(res?.error);
      }
    }
    setIsLoading(false);
  };
  const onClose = () => {
    onModalClose();
    setIsOpen(false);
  };
  return (
    <CustomDrawer
      drawerBody={
        <>
          <Box data-testid="CrudCloneTemplateInputContainerBox">
            {`Provide a new name for cloned ${masterType} -> ${title || ''}`}
          </Box>
          <CustomInput
            label="Name"
            isMandatory
            value={name}
            onChange={(e) => setName(e.target.value)}
            isError={errorMessage?.length}
            errorMessage={errorMessage}
          />
        </>
      }
      drawerFooter={
        <Flex gap="8px" data-testid="CrudCloneTemplateButtonContainerFlex">
          <CustomButton
            color={useColorModeValue(BLACK, WHITE)}
            classname="calculatedHeight"
            onClick={onClose}
            buttonText={LABELS.ACTIONS.CANCEL}
            variant="outline"
            data-testid="CrudCloneTemplate_cancel_btn"
          />
          <CustomButton
            classname="calculatedHeight"
            variant="solid"
            pl="25px"
            pr="25px"
            buttonColor="customBlue"
            isDisabled={!name}
            buttonText="Submit"
            onClick={() => {
              onClone();
            }}
            size="md"
            data-testid="CrudCloneTemplate_submit_btn"
            isLoading={isLoading}
          />
        </Flex>
      }
      modalTitle={`Clone ${masterType}`}
      openDrawer={isOpen}
      setOpenDrawer={onClose}
    />
  );
};

CrudCloneTemplate.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onModalClose: PropTypes.func.isRequired,
  labelKey: PropTypes.string.isRequired,
  masterType: PropTypes.string.isRequired,
  targetUrl: PropTypes.string.isRequired,
  listKey: PropTypes.string.isRequired,
  patchFilterId: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  patchData: PropTypes.object,
};
CrudCloneTemplate.defaultProps = {
  patchData: {},
};

export default CrudCloneTemplate;
