import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import _filter from 'lodash/filter';
import EditDependentFieldDrawer from './EditDependentFieldDrawer';
import {
  COLUMNS,
  mapDependentFieldData,
  INITIAL_DEPENDENT_FIELD_DATA,
} from './utils';
import { patchDataForTemplate } from '../TicketTemplateBuilder/utils/templateAPIUtils';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import TableViewWithSearch from '../../components/Table/TableWithSearch';
import { DROPDOWN_TYPES } from '../TenantFilters/filters.constants';

const DependentFields = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const selectedTemplate = useSelector(
    (state) => state?.viewState?.selectedTemplate
  );

  const FIELDSDATA = _filter(
    selectedTemplate.fields,
    (field) =>
      field?.type_based_attributes?.collection?.collection_type === DROPDOWN_TYPES.STATIC
  );

  const DEPENDENT_FIELD_DATA = selectedTemplate?.dependent_fields;

  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});

  const onEdit = useCallback((rowData = INITIAL_DEPENDENT_FIELD_DATA) => {
    setSelectedRowData({ ...rowData });
    setOpenEditDrawer(true);
  }, []);

  const onSave = async (dataObj) => {
    const tempData = DEPENDENT_FIELD_DATA ? [...DEPENDENT_FIELD_DATA] : [];
    const index = tempData.findIndex(
      (data) => data?.dependencies?.id === dataObj?.dependencies?.id
    );
    if (index === -1) {
      tempData.push(dataObj);
    } else {
      tempData[index] = dataObj;
    }

    const res = await patchDataForTemplate({
      id,
      data: tempData,
      key: 'dependent_fields',
      defaultErrorMessage: 'Failed to add a new field',
      successMessage: 'Successfully added a new field',
    });

    if (!res.error) {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: {
            ...selectedTemplate,
            ...res?.response?.data || {},
            dependent_fields: tempData,
          },
        })
      );
      setOpenEditDrawer(false);
    }
  };

  return (
    <>
      <TableViewWithSearch
        placeholder="Search"
        columns={COLUMNS({ onEdit, DEPENDENT_FIELD_DATA })}
        tableData={
          mapDependentFieldData(FIELDSDATA, DEPENDENT_FIELD_DATA) || []
        }
        searchKey="dependent_field_name"
        onCreateNew={() => {
          onEdit();
        }}
        buttonText="Add New Dependent Field"
        buttonVariant="solid"
        headerTitle="Dependent Fields"
      />
      {openEditDrawer && (
        <EditDependentFieldDrawer
          openDrawer={openEditDrawer}
          setOpenDrawer={setOpenEditDrawer}
          selectedRowData={selectedRowData}
          onSaveData={onSave}
        />
      )}
    </>
  );
};

export default DependentFields;
