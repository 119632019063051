// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BiiET2VBkGZQXI2v9ocw>label{background:#fff;border:1px solid #e2e3e4;border-radius:6px;padding:12px;font-size:16px;min-width:160px;color:#868e96}.BiiET2VBkGZQXI2v9ocw>label:hover,.BiiET2VBkGZQXI2v9ocw>label:active,.BiiET2VBkGZQXI2v9ocw>label:checked,.BiiET2VBkGZQXI2v9ocw>label:visited{background:#f0f0fe}.BJG2RQl7sYES8NPVjBH9{display:flex}.SaCxIZlYBkZMqvThv88M>label{background:#fff;border:1px solid #e2e3e4;border-radius:6px;padding:12px;font-size:16px;min-width:160px;color:#868e96}.SaCxIZlYBkZMqvThv88M>label:hover,.SaCxIZlYBkZMqvThv88M>label:active,.SaCxIZlYBkZMqvThv88M>label:checked,.SaCxIZlYBkZMqvThv88M>label:visited{background:#f0f0fe}.SaCxIZlYBkZMqvThv88M>label{margin:0 10px}`, "",{"version":3,"sources":["webpack://./src/pages/TicketTemplateBuilder/CreateEditTemplate/createtemplate.module.scss"],"names":[],"mappings":"AAAA,4BACE,eAAA,CACA,wBAAA,CACA,iBAAA,CACA,YAAA,CACA,cAAA,CACA,eAAA,CACA,aAAA,CAEA,6IAIE,kBAAA,CAGJ,sBACE,YAAA,CAGF,4BACE,eAAA,CACA,wBAAA,CACA,iBAAA,CACA,YAAA,CACA,cAAA,CACA,eAAA,CACA,aAAA,CAGF,6IAII,kBAAA,CAGJ,4BACE,aAAA","sourcesContent":[".radiobutton > label {\n  background: #ffffff;\n  border: 1px solid #e2e3e4;\n  border-radius: 6px;\n  padding: 12px;\n  font-size: 16px;\n  min-width: 160px;\n  color: #868e96;\n}\n  .radiobutton > label:hover,\n  .radiobutton > label:active,\n  .radiobutton > label:checked,\n  .radiobutton > label:visited {\n    background: #f0f0fe;\n  }\n\n.flex {\n  display: flex;\n}\n\n.radiobuttonM > label {\n  background: #ffffff;\n  border: 1px solid #e2e3e4;\n  border-radius: 6px;\n  padding: 12px;\n  font-size: 16px;\n  min-width: 160px;\n  color: #868e96;\n}\n\n.radiobuttonM > label:hover,\n  .radiobuttonM > label:active,\n  .radiobuttonM > label:checked,\n  .radiobuttonM > label:visited {\n    background: #f0f0fe;\n  }\n\n.radiobuttonM > label {\n  margin: 0 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radiobutton": `BiiET2VBkGZQXI2v9ocw`,
	"flex": `BJG2RQl7sYES8NPVjBH9`,
	"radiobuttonM": `SaCxIZlYBkZMqvThv88M`
};
export default ___CSS_LOADER_EXPORT___;
