import { createSlice } from '@reduxjs/toolkit';
import { cardJson } from './cards';

const cardSlice = createSlice({
  name: 'cardList',
  initialState: {
    statusFilter: 'all',
    cards: cardJson,
    loading: false,
    error: null,
  },
  reducers: {
    setStatusFilter: (state, action) => {
      state.statusFilter = action.payload;
    },
  },
  // extraReducers: {
  //   [loadCards.pending]: (state) => {
  //     state.loading = true;
  //     state.error = null;
  //   },
  //   [loadCards.fulfilled]: (state, action) => {
  //     state.loading = false;
  //     state.cards = action.payload;
  //   },
  //   [loadCards.rejected]: (state, action) => {
  //     state.loading = false;
  //     state.error = action.error.message;
  //   },
  // },
});

export const { setStatusFilter } = cardSlice.actions;

export default cardSlice.reducer;
