import { Box, Flex, Tooltip, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { useStoreApi } from 'reactflow';
import { useSelector } from 'react-redux';
import styles from '../styles/CategoryList.module.scss';
import {
  BLACK,
  BLACK_OPACITY,
  RULE_CARD_BORDER,
  WHITE,
} from '../../../utils/enums/colors';
import CustomButton from '../../../components/Button/SimpleButton';
import { ROLE_TITLE, RULE_MODAL_TYPE } from '../../../utils/enums/labels';
import { isTemplateApproved } from '../helper';

const RuleCard = ({
  ruleInfo,
  openModalAsPerModalType,
  onDeleteBtnClickFromRuleCard,
}) => {
  const flowStore = useStoreApi();
  const { nodeInternals } = flowStore.getState();
  const { templateLifeCycle } = useSelector((state) => state.workFlowMain);
  const findTitle = (value) => {
    if (value === RULE_MODAL_TYPE.MOVE_ISSUE) {
      return ROLE_TITLE.MOVE_ISSUE;
    }
    if (value === RULE_MODAL_TYPE.SPECIFIC_STATUS) {
      return ROLE_TITLE.SPECIFIC_STATUS;
    }
    if (value === RULE_MODAL_TYPE.SPECIFIC_VALUE) {
      return ROLE_TITLE.SPECIFIC_VALUE;
    }
    if (value === RULE_MODAL_TYPE.RESTRICT_LINKED_TICKET) {
      return ROLE_TITLE.RESTRICT_LINKED_TICKET;
    }
    return '';
  };
  const findSubTitle = (value) => {
    if (value.ruleType === RULE_MODAL_TYPE.MOVE_ISSUE) {
      let count = 0;

      if (value?.listRestricType?.org?.length > 0) {
        count += 1;
      }

      if (value?.listRestricType?.teams?.length > 0) {
        count += 1;
      }

      if (value?.listRestricType?.queues?.length > 0) {
        count += 1;
      }

      if (value?.listRestricType?.groups?.length > 0) {
        count += 1;
      }

      if (value?.listRestricType?.actors?.length > 0) {
        count += 1;
      }

      if (value?.listRestricType?.roles?.length > 0) {
        count += 1;
      }
      return `Only ${count} Role and ${
        value?.listRestricType?.users?.length || 0
      } people can see this transition`;
    }
    if (value.ruleType === RULE_MODAL_TYPE.SPECIFIC_STATUS) {
      const str = `Check if the issue has been in the ‘${
        nodeInternals.get(value.statusId).data.label
      }’ Status `;
      return str;
    }
    if (value.ruleType === RULE_MODAL_TYPE.SPECIFIC_VALUE) {
      const str = `Check if the issue has been in the ${
        value.reviewValueStatus.split('-')[0]
      } Field`;
      return str;
    }
    return '';
  };

  return (
    <Flex
      justify="space-between"
      mt="10px"
      ml="10px"
      borderRadius="5px"
      border={`1px solid ${RULE_CARD_BORDER}`}
      p="5px"
      boxShadow="1px 1px 15px 0px rgba(0,0,0,0.1)"
      data-testid="RuleCardFlex"
    >
      <Box data-testid="RuleCardTitleBox">
        <p
          className="text-[12px] font-semibold"
          color={BLACK_OPACITY}
          data-testid="RuleCardTitleP"
        >
          {findTitle(ruleInfo.ruleType)}
        </p>
        <p className="text-[12px] font-normal">{findSubTitle(ruleInfo)}</p>
      </Box>
      <Flex data-testid="RuleCardIconFlex">
        <Tooltip
          hasArrow
          label={
            isTemplateApproved(templateLifeCycle)
              ? `Rule can't be edited as it was approved`
              : ''
          }
          placement="top-start"
          data-testid="RuleCardApprovedTooltip"
        >
          <Box position="relative" data-testid="RuleCardApprovedBox">
            <CustomButton
              color={useColorModeValue(BLACK, WHITE)}
              onClick={() => {
                openModalAsPerModalType(ruleInfo);
              }}
              buttonText=""
              variant="outline"
              leftIcon={<EditIcon />}
              className={styles.emptyBtnWithIcon}
              isDisabled={isTemplateApproved(templateLifeCycle)}
              id="RuleCardApprovedBoxEmptyTextbtn"
            />
          </Box>
        </Tooltip>
        <Tooltip
          hasArrow
          label={
            isTemplateApproved(templateLifeCycle)
              ? `Rule can't be deleted as it was approved`
              : ''
          }
          placement="top-start"
          data-testid="RuleCardDeleteToolTip"
        >
          <Box position="relative" data-testid="RuleCardDeleteBox">
            <CustomButton
              color={useColorModeValue(BLACK, WHITE)}
              onClick={() => {
                onDeleteBtnClickFromRuleCard(ruleInfo);
              }}
              buttonText=""
              variant="outline"
              leftIcon={<DeleteIcon />}
              className={styles.emptyBtnWithIcon}
              isDisabled={isTemplateApproved(templateLifeCycle)}
              id="RuleCardApprovedBoxIconTextBtn"
            />
          </Box>
        </Tooltip>
      </Flex>
    </Flex>
  );
};
RuleCard.propTypes = {
  ruleInfo: PropTypes.object.isRequired,
  openModalAsPerModalType: PropTypes.func.isRequired,
  onDeleteBtnClickFromRuleCard: PropTypes.func.isRequired,
};
export default RuleCard;
