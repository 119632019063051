/* eslint-disable indent */
import React from 'react';
import { Popconfirm } from 'antd';
import { DeleteIcon } from '@chakra-ui/icons';
import { ReactSVG } from 'react-svg';
import TableColumnText from '../../components/Text/TableColumnText';
import metaLinkIcon from '../../assets/icons/metaLinkIcon.svg';
import {
  META_FIELD_KEY,
} from './LinkedMetaFieldDrawer/utils';
import { PRE_FIELDS_WITH_ASSOCIATIONS, PRE_FIELDS_NO_ASSOCIATED } from '../../redux/MetaFieldsSlice/MetaFieldsService';
import CustomToast from '../../components/Notification/Notification';

const valueWithPlus = (value) => {
  const thresholds = [10, 100, 1000, 10000, 100000];

  for (let i = thresholds.length - 1; i >= 0; i--) {
    if (value >= thresholds[i]) {
      const roundedValue = Math.floor(value / thresholds[i]) * thresholds[i];
      return `${roundedValue}+`;
    }
  }
  if (value === 0 || value < 10) {
    return value;
  }
  return 0;
};
export const renderUpdatedInfo = (rowData) => (
  <div className="text-center inline-flex align-middle">
    <p className="text-[14px]">
      {valueWithPlus(rowData[META_FIELD_KEY.FIELD_DATA]?.length)}
{' '}
Fields
    </p>
  </div>
);

export const deleteRenderer = ({
  onConfirm,
  title = 'Delete this',
  description = 'Are you sure you want to delete?',
  loading = false,
  dataTestId = ''
}) => (
  <Popconfirm
    title={title}
    description={description}
    onConfirm={onConfirm}
    okText="Yes"
    cancelText="No"
    arrow={{
      pointAtCenter: true,
    }}
    placement="top"
    okButtonProps={{
      loading,
      style: { backgroundColor: '#1677ff' },
    }}
  >
    <DeleteIcon data-testid={dataTestId} className="cursor-pointer" />
  </Popconfirm>
);

export const columns = ({ showModal, onDelete }) => [
  {
    name: 'Name',
    col_name: 'name',
    type: 'string',
    cellRenderer: ({ rowData }) => <TableColumnText text={rowData?.name} />,
  },
  {
    name: 'ASSOCIATED TO',
    col_name: 'associated_to',
    type: 'string',
    cellRenderer: ({ rowData }) => renderUpdatedInfo(rowData),
  },
  {
    name: 'actions',
    label: 'actions',
    col_name: 'Actions',
    cellRenderer: ({ rowData }) => {
      const { addToast } = CustomToast();
      const arr = [...PRE_FIELDS_NO_ASSOCIATED, ...PRE_FIELDS_WITH_ASSOCIATIONS];
      const noAssociatedFieldArray = [...PRE_FIELDS_NO_ASSOCIATED];
      const isDeleteButtonVisible = arr.every(
        (field) => field.meta_field_id !== rowData.meta_field_id
      );
     const isNotAssociated = noAssociatedFieldArray.every(
      (field) => field.meta_field_id !== rowData.meta_field_id
    );
      return (
        <div
          className="flex justify-center items-center"
          style={{ gap: '0.8rem' }}
        >
          <ReactSVG
            className="cursor-pointer"
            data-testid={`metaFields-${rowData.name}-link`}
            src={metaLinkIcon}
            onClick={() => {
              if (isNotAssociated) {
                showModal({
                  rowData,
                });
              } else {
                addToast({
                  title: `Meta field can't be edited`,
                  message: `${rowData.name} is a pre-defined field and can't be edited`,
                  type: 'info',
                });
              }
            }
          }
          />
          {isDeleteButtonVisible && deleteRenderer({
            title: 'Delete this meta-field',
            description: 'Are you sure you want to delete this meta-field?',
            onConfirm: () => onDelete({ rowData }),
            dataTestId: `metaFields-${rowData.name}-delete`
          })}
        </div>
      );
    },
  },
];
