import { SPECIAL_FIELD_TYPE } from '../../pages/TenantFilters/filters.constants';
import { FieldTypes } from '../enums/types';

export const getQueueFields = (template) =>
  template?.fields
    ?.map((field) => {
      if (field.type_based_attributes?.collection?.specialField === SPECIAL_FIELD_TYPE.QUEUE && field.type_based_attributes?.collection?.collection_type !== 'DEPENDENT') {
        return field;
      }
      return null;
    })
    .filter((field) => field);

export const getActorFields = (template) =>
  template?.fields?.filter((field) => {
    if (field.type_based_attributes?.collection?.specialField === SPECIAL_FIELD_TYPE.ACTORS) {
      return true;
    }
    return false;
  });

export const getStatusFields = (template) =>
  template?.fields?.filter((field) => {
    if (field.type_based_attributes?.collection?.specialField === SPECIAL_FIELD_TYPE.WORKFLOW) {
      return true;
    }
    return false;
  });

export const makeOptions = (fields) =>
  fields?.map((d) => ({
    label: d?.label,
    value: d?.id,
  }));

export const makeOptionValue = (id, fields) => {
  const matchedField = fields?.find((f) => f?.id === id);
  if (matchedField) {
    return {
      label: matchedField?.label,
      value: matchedField?.id,
    };
  }
  return null;
};

export const isV1FieldType = (item) => {
  if (item?.type === FieldTypes.LABEL) {
    return true;
  }

  if (item?.type === FieldTypes.DROPDOWN) {
    if (item?.type_based_attributes?.collection?.collection_type === 'DYNAMIC') {
      return true;
    }

    return false;
  }

  return false;
};
