import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import PropTypes from 'prop-types';

const CustomStartNode = memo(({ data, isConnectable }) => (
  <>
    <Handle
      type="source"
      id={Date.now().toString()}
      position={Position.Bottom}
      // eslint-disable-next-line no-unused-vars
      onConnect={(params) => {}}
      isConnectable={isConnectable}
    />
    <div data-testid="CustomStartNodeLabelDiv">{data.label}</div>
  </>
));
CustomStartNode.propTypes = {
  data: PropTypes.object.isRequired,
  isConnectable: PropTypes.bool.isRequired,
};
export default CustomStartNode;
