import ReactQuill from 'react-quill';

class CustomImageBlot extends ReactQuill.Quill.import('formats/image') {
  static create(value) {
    const node = super.create(value);
    node.setAttribute('src', value.src);
    //  custom attribute
    node.setAttribute('uniqueAttr', value.myCustomAttribute);
    node.setAttribute('height', value.height);
    node.setAttribute('width', value.width);
    node.setAttribute(
      'style',
      `height: ${value.height}px; width: ${value.width}px;`
    );

    return node;
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      myCustomAttribute: node.getAttribute('uniqueAttr'),
      height: node.getAttribute('height'),
      width: node.getAttribute('width'),
    };
  }
}
export default CustomImageBlot;
