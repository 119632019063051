import React from 'react';
import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import { COLORS, TEXT_LIGHT } from '../../../utils/enums/colors';
import CustomButton from '../../../components/Button/SimpleButton';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { getWholeOption } from '../../TicketTemplateWorkflow/helper';
import CustomModal from '../../../components/Modal/Modal';
import QuillEditor from '../../../components/QuillEditor';
import {
  EMAIL_QUILL_TOOLBAR,
  EMAIL_TEMPLATE_KEY_MAP,
  parseAPIToRichTextMention,
  SEND_AS_OPTIONS,
} from '../../CommunicationTemplates/utills';
import CustomMention from '../../../components/Mention';
import { getStatusObject } from '../../DynamicRenderer/AddEditSignatureDrawer/utils';
import CodeEditor from '../../../components/CodeEditor';

const renderEmailForm = (
  actionToPerform,
  handleChange,
  handleChanges,
  actionToPerformSelectedIndex,
  handleActionErrorData,
  actionErrorData,
  getDataForEmail,
  openPreviewModal,
  isModalOpen,
  closePreviewModal,
  mentionObj,
  linkedRelationItemId
) => (
  <>
    <Box marginTop="10px" data-testid="DynamicRendererSendMailBox">
      {/* <CustomInput
        label="To"
        value={actionToPerform.to}
        onChange={(e) => {
          handleChange(
            e.target.value,
            `action_to_perform[${actionToPerformSelectedIndex}].to`
          );
          handleActionErrorData('to');
        }}
        isError={!!actionErrorData?.to}
        errorMessage={actionErrorData?.to || ''}
        isMandatory={false}
        id="DynamicRenderer6DivCInput"
      /> */}
      <CustomMention
        label="To"
        value={actionToPerform.to}
        onChange={(e) => {
          handleChange(
            e,
            `action_to_perform[${actionToPerformSelectedIndex}].to`
          );
          handleActionErrorData('to');
        }}
        isError={!!actionErrorData?.to}
        errorMessage={actionErrorData?.to || ''}
        isMandatory={false}
        id="DynamicRenderer6DivCInput"
        linkedItemId={linkedRelationItemId}
        showQueueAndActorEmailOnly
      />
    </Box>
    <Box marginTop="10px" data-testid="DynamicRendererSendMailBoxCc">
      {/* <CustomInput
        label="Cc"
        value={actionToPerform.cc}
        onChange={(e) => {
          handleChange(
            e.target.value,
            `action_to_perform[${actionToPerformSelectedIndex}].cc`
          );
          handleActionErrorData('cc');
        }}
        isError={!!actionErrorData?.cc}
        errorMessage={actionErrorData?.cc || ''}
        isMandatory={false}
        id="DynamicRenderer6DivCInput"
      /> */}
      <CustomMention
        label="Cc"
        value={actionToPerform.cc}
        onChange={(e) => {
          handleChange(
            e,
            `action_to_perform[${actionToPerformSelectedIndex}].cc`
          );
          handleActionErrorData('cc');
        }}
        isError={!!actionErrorData?.cc}
        errorMessage={actionErrorData?.cc || ''}
        isMandatory={false}
        id="DynamicRenderer6DivCInput"
        linkedItemId={linkedRelationItemId}
        showQueueAndActorEmailOnly
      />
    </Box>
    <Box marginTop="10px" data-testid="DynamicRendererSendMailBoxBcc">
      {/* <CustomInput
        label="Bcc"
        value={actionToPerform.bcc}
        onChange={(e) => {
          handleChange(
            e.target.value,
            `action_to_perform[${actionToPerformSelectedIndex}].bcc`
          );
          handleActionErrorData('bcc');
        }}
        isError={!!actionErrorData?.bcc}
        errorMessage={actionErrorData?.bcc || ''}
        isMandatory={false}
        id="DynamicRenderer6DivCInput"
      /> */}
      <CustomMention
        label="Bcc"
        value={actionToPerform.bcc}
        onChange={(e) => {
          handleChange(
            e,
            `action_to_perform[${actionToPerformSelectedIndex}].bcc`
          );
          handleActionErrorData('bcc');
        }}
        isError={!!actionErrorData?.bcc}
        errorMessage={actionErrorData?.bcc || ''}
        isMandatory={false}
        id="DynamicRenderer6DivCInput"
        linkedItemId={linkedRelationItemId}
        showQueueAndActorEmailOnly
      />
    </Box>
    <Box marginTop="10px" data-testid="DynamicRendererSendMailSujectBox">
      {/* <CustomInput
        label="Subject"
        value={actionToPerform.subject}
        onChange={(e) => {
          handleChange(
            e.target.value,
            `action_to_perform[${actionToPerformSelectedIndex}].subject`
          );
          handleActionErrorData('subject');
        }}
        isError={!!actionErrorData?.subject}
        errorMessage={actionErrorData?.subject || ''}
        isMandatory={false}
        id="DynamicRenderer7DivCInput"
      /> */}
      <CustomMention
        label="Subject"
        value={actionToPerform.subject}
        onChange={(e) => {
          handleChange(
            e,
            `action_to_perform[${actionToPerformSelectedIndex}].subject`
          );
          handleActionErrorData('subject');
        }}
        isError={!!actionErrorData?.subject}
        errorMessage={actionErrorData?.subject || ''}
        isMandatory={false}
        id="DynamicRenderer7DivCInput"
        linkedItemId={linkedRelationItemId}
      />
    </Box>
    <Box marginTop="10px" data-testid="DynamicRendererSendMailV2Box">
      <CustomSelectBox
        options={getDataForEmail()}
        value={getWholeOption(
          actionToPerform.template,
          getDataForEmail(),
          'emailBody'
        )}
        onChange={(e) => {
          const events = [
            {
              value: parseAPIToRichTextMention(e.emailBody),
              path: `action_to_perform[${actionToPerformSelectedIndex}].template`,
            },
            {
              value: parseAPIToRichTextMention(e.emailSignature),
              path: `action_to_perform[${actionToPerformSelectedIndex}].signature`,
            },
            {
              value: e.subject,
              path: `action_to_perform[${actionToPerformSelectedIndex}].subject`,
            },
            {
              value: e.send_as,
              path: `action_to_perform[${actionToPerformSelectedIndex}].send_as`,
            },
          ];
          handleChanges(events);
          handleActionErrorData('template');
        }}
        label="Email Template"
        isError={false}
        aria-label="emailTemplate"
      />
    </Box>
    <Box mt="10px">
      <CustomSelectBox
        value={getStatusObject(
          actionToPerform[EMAIL_TEMPLATE_KEY_MAP.SEND_AS] || 'text',
          SEND_AS_OPTIONS
        )}
        options={SEND_AS_OPTIONS}
        onChange={(e) => {
          handleChange(
            e.value,
            `action_to_perform[${actionToPerformSelectedIndex}].send_as`
          );
          handleActionErrorData('send_as');
        }}
        label="Send as"
        aria-label="send-as"
      />
    </Box>
    {actionToPerform.send_as === 'html' ? (
      <Box mt="20px" w="100%">
        <CodeEditor
          value={actionToPerform.template || ''}
          onChange={(value) => {
            handleChange(
              value,
              `action_to_perform[${actionToPerformSelectedIndex}].template`
            );
            handleActionErrorData('template');
          }}
          label="Email body"
          height="260px"
        />
        {actionErrorData?.template ? (
          <span className="field-error">{actionErrorData?.template}</span>
        ) : null}
      </Box>
    ) : (
      <Box marginTop="10px" data-testid="DynamicRendererSendMailV2TextAreaBox">
        {/* <TextAreaBox
        rows={10}
        value={actionToPerform.template}
        onChange={(e) => {
          handleChange(
            e.target.value,
            `action_to_perform[${actionToPerformSelectedIndex}].template`
          );
          handleActionErrorData('template');
        }}
        className="monospace-textarea"
        isMandatory
        label="Email Body"
      /> */}
        <QuillEditor
          editorvalue={actionToPerform.template}
          toolBarModules={EMAIL_QUILL_TOOLBAR}
          format=""
          customEditorContainerStyles={{ height: '120px' }}
          handleOnChange={(value) => {
            handleChange(
              value,
              `action_to_perform[${actionToPerformSelectedIndex}].template`
            );
            handleActionErrorData('template');
          }}
          label="Email Body"
          testId="AddOrEditEmailTemplateDrawerQuillEditor"
          mentionObj={mentionObj}
        />
        {actionErrorData?.template ? (
          <span className="field-error">{actionErrorData?.template}</span>
        ) : null}
      </Box>
    )}

    <Box marginRight="10px" data-testid="DynamicRendererSendMailBtnBox">
      <CustomButton
        buttonText="Preview"
        variant="outline"
        colorScheme="customBlue"
        style={{
          height: 30,
          marginTop: '8px',
        }}
        color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
        onClick={openPreviewModal}
        id="DynamicRendererSendMailPreviewBtnText"
      />
      <CustomModal
        open={isModalOpen}
        setOpen={() => false}
        modalTitle="Email Preview"
        id="dynamicRenderZ-modal"
        // eslint-disable-next-line react/no-danger
        modalContent={
          <>
            <Text
              fontWeight="600"
              fontSize="16px"
              color={useColorModeValue(TEXT_LIGHT, '')}
              marginTop="20px"
              marginBottom="10px"
              data-testid="DynamicRendererSendMailBodyText"
            >
              Email Body
            </Text>
            <Box
              border="1px"
              borderColor={useColorModeValue('gray.200', 'gray.700')}
              borderRadius="6px"
              p="2"
              marginBottom="10px"
              overflow="auto"
              data-testid="DynamicRendererSendMailBodyBox"
            >
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: actionToPerform.template,
                }}
              />
            </Box>
            <Text
              fontWeight="600"
              fontSize="16px"
              color={useColorModeValue(TEXT_LIGHT, '')}
              marginTop="20px"
              marginBottom="10px"
              data-testid="DynamicRendererSendMailSignatureText"
            >
              Email Signature
            </Text>
            <Box
              border="1px"
              borderColor={useColorModeValue('gray.200', 'gray.700')}
              borderRadius="6px"
              placeholder="Email Signature"
              p="2"
              marginBottom="10px"
              overflow="auto"
              data-testid="DynamicRendererSendMailSignatureBox"
            >
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: actionToPerform.signature,
                }}
              />
            </Box>
          </>
        }
        modalFooter={
          <CustomButton
            variant="outline"
            buttonColor="customBlue"
            size="lg"
            buttonText="Close"
            onClick={closePreviewModal}
            id="DynamicRendererSendMailClostBtl"
          />
        }
        onModalClose={closePreviewModal}
      />
    </Box>
    {actionToPerform.send_as === 'html' ? (
      <Box mt="20px" w="100%">
        <CodeEditor
          value={actionToPerform.signature || ''}
          onChange={(value) => {
            handleChange(
              value,
              `action_to_perform[${actionToPerformSelectedIndex}].signature`
            );
            handleActionErrorData('signature');
          }}
          label="Email Signature"
          placeholder="Email Signature"
          height="260px"
        />
      </Box>
    ) : (
      <Box mt="20px" w="100%">
        <QuillEditor
          editorvalue={actionToPerform.signature}
          toolBarModules={EMAIL_QUILL_TOOLBAR}
          format=""
          customEditorContainerStyles={{ height: '120px' }}
          handleOnChange={(value) => {
            handleChange(
              value,
              `action_to_perform[${actionToPerformSelectedIndex}].signature`
            );
            handleActionErrorData('signature');
          }}
          label="Email Signature"
          placeholder="Email Signature"
          testId="AddOrEditEmailTemplateDrawerQuillEditor"
          mentionObj={mentionObj}
        />
      </Box>
    )}
  </>
);

export default renderEmailForm;
