import React, { useCallback, useState } from 'react';

import {
  Box,
  // Flex,
  HStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import DynamicMutliSelectWithInputTags from '../../../components/dynamicSelectWithInputTags/DynamicSelectWithInputTags';
import CustomButton from '../../../components/Button/SimpleButton';
import { COLORS } from '../../../utils/enums/colors';
import { CustomDrawer } from '../../TicketTemplateBuilder/ConfigureTemplateView/AddEditTemplateLayoutModal';

const DashboardEditScreen = ({
  data,
  onNavigateBack,
  isOpen,
  setIsOpen,
  updateDashboard,
  // setIsLoadingForApi,
  isLoadingForApi,
}) => {
  const { id } = useParams();
  const [selectedData, setSelectedData] = useState({ ...data });

  const handleChange = useCallback((key, val) => {
    setSelectedData((prev) => ({
      ...prev,
      [key]: val,
    }));
  });

  const navigateBack = () => {
    onNavigateBack(false, null);
  };

  return (
    <Box data-testid="DashboardEditScreenContainerBox">
      <CustomDrawer
        modalTitle={
          <p
            className="font-semibold text-[16px]"
            data-testid="DashboardEditScreenInnerP"
          >
            {data?.name || ''}
          </p>
        }
        drawerBody={
          <div
            className="mt-[20px]"
            data-testid="DashboardEditScreenDynamicMultiSelectDiv"
          >
            <DynamicMutliSelectWithInputTags
              values={selectedData?.viewers || []}
              label="Viewers"
              onChange={(value) => {
                handleChange('viewers', value);
              }}
              templateId={id}
            />
          </div>
        }
        drawerFooter={
          <HStack
            mt="10px"
            justifyContent="flex-end"
            alignItems="center"
            spacing="10px"
            data-testid="DashboardEditScreenButtonContainerflex"
          >
            <CustomButton
              classname="calculatedHeight"
              buttonText="Cancel"
              variant="unstyled"
              onClick={navigateBack}
              _hover={{}}
              style={{
                color: 'gray',
                border: '1px solid #d1d5db',
                borderRadius: '5px',
                paddingRight: '12px',
                paddingLeft: '12px',
              }}
              id="DashboardEditScreenButtonCancelBtn"
            />
            <CustomButton
              classname="calculatedHeight"
              buttonText="Save"
              variant="solid"
              onClick={() => updateDashboard(selectedData)}
              style={{
                backgroundColor: useColorModeValue(COLORS.LIGHT, COLORS.DARK),
                height: 40,
                color: '#fff',
              }}
              isLoading={isLoadingForApi}
              id="DashboardEditScreenButtonSaveBtn"
            />
          </HStack>
        }
        openDrawer={isOpen}
        setOpenDrawer={setIsOpen}
      />
    </Box>
  );
};

DashboardEditScreen.propTypes = {
  data: PropTypes.object,
  onNavigateBack: PropTypes.func.isRequired,
  updateDashboard: PropTypes.func.isRequired,
  // setIsLoadingForApi: PropTypes.func,
  isLoadingForApi: PropTypes.bool,
};

DashboardEditScreen.defaultProps = {
  data: {},
  // setIsLoadingForApi: () => {},
  isLoadingForApi: false,
};

export default DashboardEditScreen;
