import React from "react";
import { Box, Flex, InputGroup, InputRightElement, useColorModeValue } from "@chakra-ui/react";
import CustomInput from "../../components/InputBox/Input";
import CustomButton from "../../components/Button/SimpleButton";
import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import { COLORS } from "../../utils/enums/colors";
import PropTypes from 'prop-types';

export const ActionHeader = ({ 
    searchInputValue, 
    setSearchInputValue, 
    filterData, 
    handleClearInput, 
    selectedTabType, 
    addButtonClick, 
    placeholder,
    addButtonText,
 }) => {


    return <Flex
        gap="15px"
        flexWrap="wrap"
        data-testid="CommunicationTemplateCommonTableContainerFlex"
        width="100%"
        justifyContent="flex-end"
    >
        <Box
            data-testid="CommunicationTemplateCommonTableInnerBox"
        >
            <InputGroup size="l">
                <CustomInput
                    placeholder={placeholder}
                    type="text"
                    size="sm"
                    value={searchInputValue}
                    onChange={(e) => {
                        setSearchInputValue(e.target.value);
                        filterData(e);
                    }}
                />
                (
                <InputRightElement
                    className= 'calculatedHeight'
                    style={{ right: 12, cursor: 'pointer' }}
                    // eslint-disable-next-line react/no-children-prop
                    children={searchInputValue ? <CloseIcon color="gray.300" onClick={handleClearInput} /> : <SearchIcon color="gray.300" />}
                    size="l"
                />
                )
            </InputGroup>
        </Box>
        <CustomButton
            classname="calculatedHeight"
            buttonText={addButtonText}
            variant="outline"
            // style={{ height: '32px', borderColor: '#2563EB' }}
            style= {{
                borderColor: '#2563EB',
                backgroundColor: COLORS.LIGHT,
                color: COLORS.WHITE,
                // height:'32px'
              }}
            color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
            onClick={() => {
                addButtonClick(selectedTabType)
            }}
            data-testid={`CommunicationTemplateCommonTableContainerAddNew${selectedTabType}`}
        />
    </Flex>
}

ActionHeader.propTypes = {
    placeholder: PropTypes.string,
    searchInputValue: PropTypes.string.isRequired,
    setSearchInputValue: PropTypes.func.isRequired,
    filterData: PropTypes.array.isRequired,
    handleClearInput: PropTypes.func.isRequired,
    addButtonClick: PropTypes.func.isRequired,
    selectedTabType: PropTypes.string.isRequired,
    addButtonText: PropTypes.string,
};

ActionHeader.defaultProps = {
    placeholder: 'Search',
    addButtonText: 'Add New'
};