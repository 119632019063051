import React, { useEffect, useState } from 'react';

import { Box, Textarea } from '@chakra-ui/react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import CustomInput from '../../../components/InputBox/Input';
import CustomSelectBox from '../../../components/SelectBox/Select';
import CustomDatePicker from '../../../components/Datepicker/AntdDatePicker';

import { customStyleFromRuleForSelect } from '../../../context/CustomTheme/CustomStyleFromRuleForSelect';
import { FieldTypes } from '../../../utils/enums/types';
import { DROPDOWN_TYPES } from '../../TenantFilters/filters.constants';

export const customStyles = {
  control: (provided) => ({
    ...provided,
    height: '35px',
    width: '200px',
  }),
  menu: customStyleFromRuleForSelect?.menu,
  input: customStyleFromRuleForSelect?.input,
};

const Fields = ({
  id,
  defaultValue,
  fieldType,
  updateValue,
  options,
  rowData,
}) => {
  const [value, setValue] = useState(defaultValue);
  const [optns, setOptns] = useState([]);

  useEffect(() => {
    options.then((res) => {
      setOptns([...res]);

      // Setting the value for the dynamic options
      if (
        rowData?.collection?.collection_type === DROPDOWN_TYPES.DYNAMIC &&
        rowData?.collection?.dynamicCurl &&
        (fieldType === FieldTypes.DROPDOWN || fieldType === FieldTypes.TAGS)
      ) {
        const selectedOption = res?.find((op) => op.value === defaultValue);
        setValue({ value: defaultValue, label: selectedOption?.label });
      }
    });
  }, [options]);

  const onChange = (val) => {
    setValue(val);
    updateValue(id, val, !!rowData?.id);
  };

  const commonProps = {};

  switch (fieldType) {
    case FieldTypes.TEXT:
    case FieldTypes.EMAIL:
      return (
        <Box w="200px">
          <CustomInput
            value={value}
            onChange={(e) => onChange(e.target.value)}
            {...commonProps}
            id="FieldsDivCInput"
          />
        </Box>
      );

    case FieldTypes.BOOLEAN:
    case FieldTypes.TAGS:
    case FieldTypes.DROPDOWN:
      return (
        <CustomSelectBox
          value={value}
          options={optns}
          onChange={onChange}
          styles={customStyles}
          {...commonProps}
          id="FieldsSelectViewBox"
        />
      );

    case FieldTypes.NUMBER:
      return (
        <Box w="200px">
          <CustomInput
            value={value}
            onChange={(e) => {
              if (/^\d+$/.test(e.target.value)) {
                onChange(e.target.value);
              }
            }}
            {...commonProps}
            id="FieldsADivCInput"
          />
        </Box>
      );

    case FieldTypes.RICH_TEXT:
      return (
        <Box w="200px">
          <Textarea
            rows={3}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            {...commonProps}
          />
        </Box>
      );

    case FieldTypes.DATE:
      return (
        <CustomDatePicker
          value={value ? new Date(value) : undefined}
          onChange={(e) => {
            if (e) {
              onChange(dayjs(e).format('YYYY-MM-DD'));
            } else {
              onChange('');
            }
          }}
          allowClear
          dateFormat="MMM DD, YYYY"
          {...commonProps}
        />
      );

    default:
      return null;
  }
};

Fields.propTypes = {
  id: PropTypes.string.isRequired,
  fieldType: PropTypes.string.isRequired,
  updateValue: PropTypes.func.isRequired,
  rowData: PropTypes.object,
  options: PropTypes.object.isRequired,
  defaultValue: PropTypes.string || PropTypes.object,
};

Fields.defaultProps = {
  defaultValue: '',
  rowData: {},
};

export default Fields;
