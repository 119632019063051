/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  GridItem,
  Flex,
  useColorModeValue,
  IconButton,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { DARK, LIGHT } from '../../utils/enums/colors';
import CustomSelectBox from '../../components/SelectBox/Select';

import { getWholeOption } from '../TicketTemplateWorkflow/helper';
import customStyleFromRuleForSelect from '../../context/CustomTheme/CustomStyleFromRuleForSelect';

const ActionEventConfigRow = ({
  index,
  actionState,
  handleChange,
  triggerFilteredEventsByTemplateId,
  filteredRelatedTemplateOptions,
  selectedTemplate,
  templateAndRelationMap,
  onAddEventConfigRow,
  onRemoveEventConfigRow,
}) => {
  const eventConfig = actionState.eventConfig[index] || {};
  const [eventOptions, setEventOptions] = useState([]);

  useEffect(() => {
    if (!eventConfig.linked_item_type) {
      return;
    }

    if (Object.keys(triggerFilteredEventsByTemplateId).length === 0) {
      return;
    }

    const events = triggerFilteredEventsByTemplateId[eventConfig.linked_item_type];

    const eventOptionsArray = events.map((event) => ({
      value: event.id,
      label: event.event_name,
    }));
    setEventOptions(eventOptionsArray);
  }, [
    eventConfig.linked_item_type,
    triggerFilteredEventsByTemplateId,
    selectedTemplate
  ]);

  return (
    <Grid templateColumns="repeat(12, 1fr)" columnGap={8} mt="30px">
      <GridItem colSpan={3}>
        <Box>
          <CustomSelectBox
            options={filteredRelatedTemplateOptions || []}
            placeholder="Select item type"
            value={getWholeOption(
              eventConfig.linked_item_type,
              filteredRelatedTemplateOptions || []
            )}
            onChange={(value) => {
              handleChange(value.value, `eventConfig[${index}].linked_item_type`);
            }}
            focusBorderColor={useColorModeValue(LIGHT, DARK)}
            styles={customStyleFromRuleForSelect}
            id="DynamicRendererEditTriggerListingEventSelectCustomBox"
            label="Linked Item Type"
            isMandatory
            isDisabled={!actionState.trigger}
          />
        </Box>
      </GridItem>
      {eventConfig.linked_item_type !== selectedTemplate?.id && (
        <GridItem colSpan={3}>
          <Box>
            <CustomSelectBox
              options={templateAndRelationMap[eventConfig.linked_item_type] || []}
              placeholder="Select Relation"
              value={getWholeOption(
                eventConfig.linked_item_relation,
                templateAndRelationMap[eventConfig.linked_item_type] || []
              )}
              onChange={(value) => {
                handleChange(value.value, `eventConfig[${index}].linked_item_relation`);
              }}
              focusBorderColor={useColorModeValue(LIGHT, DARK)}
              styles={customStyleFromRuleForSelect}
              id="DynamicRendererEditTriggerListingEventSelectCustomBox"
              label="Linked Item Relation"
              isMandatory
              isDisabled={!actionState.trigger || !eventConfig.linked_item_type}
            />
          </Box>
        </GridItem>
      )}
      <GridItem colSpan={3}>
        <Box>
          <CustomSelectBox
            options={eventOptions}
            placeholder="Select Event"
            value={getWholeOption(eventConfig.listened_event, eventOptions)}
            onChange={(value) => {
              handleChange(value.value, `eventConfig[${index}].listened_event`);
            }}
            focusBorderColor={useColorModeValue(LIGHT, DARK)}
            styles={customStyleFromRuleForSelect}
            id="DynamicRendererEditTriggerListingEventSelectCustomBox"
            label="Listened Event"
            isMandatory
            isDisabled={
              !actionState.trigger
              || !eventConfig.linked_item_type
              || (
                eventConfig.linked_item_type !== selectedTemplate?.id
                && !eventConfig.linked_item_relation
              )
            }
          />
        </Box>
      </GridItem>
      <GridItem colSpan={3}>
        <Flex alignItems="end" height="100%">
          <IconButton
            variant="outline"
            aria-label="Add"
            icon={<AddIcon />}
            onClick={onAddEventConfigRow}
            style={{ marginRight: '8px' }}
          />
          <IconButton
            variant="outline"
            aria-label="Delete"
            color="red"
            icon={<DeleteIcon />}
            onClick={onRemoveEventConfigRow}
            isDisabled={actionState.eventConfig.length === 1}
          />
        </Flex>
      </GridItem>
    </Grid>
  );
};

export default ActionEventConfigRow;
