import React, { useCallback } from 'react';

import { Checkbox } from '@chakra-ui/react';

import { PropTypes } from 'prop-types';

import { checkboxStatus } from './utils';

const CheckboxComponent = ({
  indeterminate,
  checked,
  id,
  compute,
  name,
  dataTestId,
}) => {
  const handleChange = useCallback((isChecked) => {
    const newStatus = isChecked
      ? checkboxStatus.checked
      : checkboxStatus.unchecked;

    compute(id, newStatus);
  }, [compute, id]);

  return (
    <Checkbox
      isChecked={checked || false}
      isIndeterminate={indeterminate || false}
      onChange={(e) => handleChange(e.target.checked)}
      data-testid={dataTestId}
    >
      {name}
    </Checkbox>
  );
};

CheckboxComponent.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  checked: PropTypes.bool.isRequired,
  indeterminate: PropTypes.bool.isRequired,
  compute: PropTypes.func.isRequired,
  name: PropTypes.string,
  dataTestId: PropTypes.string,
};

CheckboxComponent.defaultProps = {
  name: '',
  dataTestId:'',
};

export default CheckboxComponent;
