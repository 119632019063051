import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axios/axios';
import { storeFetchedFavorites } from './favoritesSlice';
import { canViewTemplate } from '../../pages/TicketTemplateBuilder/utils/templateAPIUtils';

const initialState = {
  data: [],
  loading: true,
  error: null,
};

export const fetchFavorites = createAsyncThunk(
  'templates/fetchFavorites',
  async (favoritesFilter, { dispatch }) => {
    try {
      const params = {
        $top: 1000,
        $skip: 0,
        $select: '*',
        $filter: favoritesFilter,
      };

      const response = await axiosInstance.get('favourites/list', {
        params,
      });

      dispatch(storeFetchedFavorites(response.data.rows));

      return response.data.rows;
    } catch (error) {
      throw new Error('Failed to fetch favorites.');
    }
  }
);

export const fetchAllCards = createAsyncThunk(
  'templates/fetchTemplates',
  async ({ query, orderBy } = {}) => {
    try {
      const params = {
        // $top: 1000,
        // $skip: 0,
        $select: '*',
      };

      if (query) {
        params.$filter = query;
      }
      if (orderBy) {
        params.$orderby = orderBy;
      }

      const response = await axiosInstance.get('templates/list', {
        params,
      });
      return response.data.rows;
    } catch (error) {
      throw new Error('Failed to fetch templates.');
    }
  }
);

export const removeCard = createAsyncThunk(
  'templates/removeCard',
  async (cardId, thunkAPI) => {
    try {
      const { allcard } = thunkAPI.getState();
      const newUpdatedCard = allcard.data.filter((card) => card.id !== cardId);
      return newUpdatedCard;
    } catch (error) {
      throw new Error('Failed to fetch templates.');
    }
  }
);

const allcardSlice = createSlice({
  name: 'allcard',
  initialState,
  reducers: {
    storeFetchedCards: (state, action) => {
      state.data = action.payload;
    },
    updateCards: (state, action) => {
      state.data = [...state.data, action.payload];
    },
    updateIsEmptyCardList: (state, action) => {
      state.isEmptyCardList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCards.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllCards.fulfilled, (state, action) => {
        state.loading = false;

        // Checking the View access
        const filteredData = Array.isArray(action.payload)
          ? action.payload.filter((t) => canViewTemplate({ data: t }))
          : [];

        state.data = filteredData;
        state.isEmptyCardList = action.payload?.length === 0;
      })
      .addCase(fetchAllCards.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(removeCard.fulfilled, (state, action) => {
        state.data = action.payload;
      });
  },
});

export const { updateCards, storeFetchedCards, updateIsEmptyCardList } =
  allcardSlice.actions;

export const selectAllCards = (state) => state.allcard.data;
export const selectAllCardsLoading = (state) => state.allcard.loading;
export const selectAllCardsError = (state) => state.allcard.error;
export const isEmptyCardList = (state) => state.allcard.isEmptyCardList;

export default allcardSlice.reducer;
