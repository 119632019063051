/* eslint-disable no-unused-vars */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
// import { Flex } from '@chakra-ui/layout';
import { ReactSVG } from 'react-svg';
import { Flex, useColorModeValue } from '@chakra-ui/react';
import { forEach, isEmpty, join } from 'lodash';
import {
  mediaFormatSelectOpt2,
  mediaFormatSelectOpt2FileType,
} from '../../../utils/enums/selectOption';
import { InputBoxTypes } from '../../../utils/enums/types';
import { removedInvalidChar, stringToSlug } from './helper';
import { BASE_SETTINGS_KEYS, ADVANCED_SETTINGS_KEYS } from './constants';
import CustomSelectBox from '../../../components/SelectBox/Select';
import customStylesSelectWithHeight40 from '../../../context/CustomTheme/CustomStylesSelectWithHeight40';
import { renderFieldLabels } from './renderFields';
import {
  COLORS
} from '../../../utils/enums/colors';
import {
  FIELD_SLUG,
  FIELD_VALIDATE_KEY,
  LABELS,
  SUB_LABELS,
} from '../../../utils/enums/labels';
import { isAttachmentCountValid, isMediaSizeValid } from './MediaSetting';
import CustomCheckBox from '../../../components/CheckBox/CheckBox';
import CustomInput from '../../../components/InputBox/Input';

export const richTextBaseSettings = ({
  data,
  setFields,
  setIsError,
  existingFields,
}) => ({
  row1: [
    renderFieldLabels({
      data,
      setFields,
      fieldSlug: FIELD_SLUG.FIELD_RICH_TEXT,
      setIsError,
      existingFields,
    }),
    {
      label: LABELS.FIELD_PROPERTIES.NAME,
      value: `${data?.[BASE_SETTINGS_KEYS.SLUG] || ''}`,
      placeholder: '',
      required: true,
      key: FIELD_VALIDATE_KEY.SLUG,
      errorString: LABELS.ERROR.NAME,
      isReadOnly: true,
      valueKey: BASE_SETTINGS_KEYS.SLUG,
      onChange: (value) => {
        setFields((pre) => ({
          ...pre,
          [BASE_SETTINGS_KEYS.SLUG]: value,
          [BASE_SETTINGS_KEYS.IS_EDIT_SLUG]: true,
        }));
        setIsError((pre) => ({ ...pre, [BASE_SETTINGS_KEYS.SLUG]: !value }));
      },
    },
  ],
  row2: [
    {
      label: LABELS.FIELD_PROPERTIES.DEFAULT_VALUE,
      value: `${data?.[BASE_SETTINGS_KEYS.DEFAULT_VALUE] || ''}`,
      placeholder: '',
      valueKey: BASE_SETTINGS_KEYS.DEFAULT_VALUE,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [BASE_SETTINGS_KEYS.DEFAULT_VALUE]: value,
        })),
    },
    {
      label: LABELS.FIELD_PROPERTIES.DESCRIPTION,
      value: data?.[BASE_SETTINGS_KEYS.DESCRIPTION],
      placeholder: '',
      valueKey: BASE_SETTINGS_KEYS.DESCRIPTION,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [BASE_SETTINGS_KEYS.DESCRIPTION]: value,
        })),
    },
    {
      label: '',
      render: (newData, isError) => {
        const selectedOptions =
          newData[BASE_SETTINGS_KEYS.IS_ADD_ATTACHMENT] || false;
        return (
          <CustomCheckBox
            isChecked={selectedOptions}
            onChange={(e) => {
              setFields((prev) => ({
                ...prev,
                [BASE_SETTINGS_KEYS.IS_ADD_ATTACHMENT]: e.target.checked,
              }));
            }}
            checkBoxText={LABELS.FIELD_PROPERTIES.ADD_ATTACHMENT}
            isDisabled={false}
          />
        );
      },
    },
    // {
    //   label: '',
    //   value: `${data?.[BASE_SETTINGS_KEYS.MEDIA_TYPE] || ''}`,
    //   required: true,
    //   key: BASE_SETTINGS_KEYS.MEDIA_TYPE,
    //   placeholder: '',
    //   valueKey: BASE_SETTINGS_KEYS.MEDIA_TYPE,
    //   onChange: (value) =>
    //     setFields((pre) => ({
    //       ...pre,
    //       [BASE_SETTINGS_KEYS.MEDIA_TYPE]: value,
    //     })),
    //   render: (newData, isError) => {
    //     const selectedOptions = mediaFormatSelectOpt2.filter((option) =>
    //       newData[BASE_SETTINGS_KEYS.MEDIA_TYPE]?.includes(option.value)
    //     );
    //     const selectedOptionsFileType = [];
    //     forEach(selectedOptions, (option) => {
    //       selectedOptionsFileType.push(
    //         ...mediaFormatSelectOpt2FileType[option.value]
    //       );
    //     });
    //     const fileTypeString = join(selectedOptionsFileType, ' ');
    //     return (
    //       newData[BASE_SETTINGS_KEYS.IS_ADD_ATTACHMENT] && (
    //         <>
    //           <CustomSelectBox
    //             isMandatory
    //             label={LABELS.FIELD_PROPERTIES.MEDIA_TYPE}
    //             isMulti
    //             myFontSize="14px"
    //             options={mediaFormatSelectOpt2}
    //             value={selectedOptions}
    //             isError={isError?.[BASE_SETTINGS_KEYS.MEDIA_TYPE] || false}
    //             errorMessage={LABELS.ERROR.MEDIA_TYPE}
    //             onChange={(selectedOptionsData) => {
    //               const all = '*';
    //               const length = selectedOptionsData?.length || 0;
    //               let values = [...selectedOptionsData];
    //               if (length > 0) {
    //                 if (selectedOptionsData[length - 1].value === all) {
    //                   if (
    //                     selectedOptionsData.some((item) => item.value === all)
    //                   ) {
    //                     values = mediaFormatSelectOpt2.filter(
    //                       (item) => item.value !== '*'
    //                     );
    //                   } else {
    //                     values = [selectedOptionsData[length - 1]];
    //                   }
    //                 } else if (selectedOptionsData[0].value === all) {
    //                   values = mediaFormatSelectOpt2.filter(
    //                     (item) => item.value !== '*'
    //                   );
    //                   // selectedOptionsData.slice(1);
    //                 }
    //               }
    //               const selectedValues = values.map((option) => option.value);
    //               setFields((prevData) => ({
    //                 ...prevData,
    //                 [BASE_SETTINGS_KEYS.MEDIA_TYPE]: selectedValues,
    //               }));
    //               setIsError((pre) => ({
    //                 ...pre,
    //                 [BASE_SETTINGS_KEYS.MEDIA_TYPE]:
    //                   !selectedOptionsData.length,
    //               }));
    //             }}
    //             getOptionLabel={(e) => (
    //               <Flex align="center" className="text-[14px]">
    //                 {e.icon && (
    //                   <ReactSVG
    //                     src={e.icon}
    //                     pr="7px"
    //                     width="15px"
    //                     height="13px"
    //                   />
    //                 )}
    //                 <span className="ml-1">{e.label}</span>
    //               </Flex>
    //             )}
    //             styles={customStylesSelectWithHeight40}
    //           />
    //           {selectedOptionsFileType.length ? (
    //             <Flex
    //               justifyContent="flex-start"
    //               alignItems="center"
    //               bg={useColorModeValue(
    //                 COLORS.WRAP_OPERATOR_BG,
    //                 COLORS.WRAP_OPERATOR_BG_DARK
    //               )}
    //               borderRadius="4px"
    //               fontWeight={500}
    //               paddingLeft="1rem"
    //               paddingRight="1rem"
    //               marginTop="10px"
    //               width="fit-content"
    //               height="fit-content"
    //               marginBottom="10px"
    //             >
    //               {`Supported Formats: ${fileTypeString}`}
    //             </Flex>
    //           ) : null}
    //         </>
    //       )
    //     );
    //   },
    // },
  ],
  row3: [
    // {
    //   label: '',
    //   value: `${data?.[BASE_SETTINGS_KEYS.MEDIA_SIZE] || '4'}`,
    //   key: FIELD_VALIDATE_KEY.MEDIA_SIZE,
    //   errorString: LABELS.ERROR.MEDIA_SIZE,
    //   type: InputBoxTypes.NUMBER,
    //   valueKey: BASE_SETTINGS_KEYS.MEDIA_SIZE,
    //   onChange: (value) => {
    //     setFields((pre) => ({
    //       ...pre,
    //       [BASE_SETTINGS_KEYS.MEDIA_SIZE]: value,
    //     }));
    //     setIsError((pre) => ({
    //       ...pre,
    //       [BASE_SETTINGS_KEYS.MEDIA_SIZE]: !isMediaSizeValid(value),
    //     }));
    //   },
    //   subLabel: SUB_LABELS.FIELDS.MEDIA_SIZE,
    //   render: (newData, isError) =>
    //     newData[BASE_SETTINGS_KEYS.IS_ADD_ATTACHMENT] && (
    //       <CustomInput
    //         label={LABELS.FIELD_PROPERTIES.MAX_SIZE}
    //         myFontSize="14px"
    //         value={newData?.[BASE_SETTINGS_KEYS.MEDIA_SIZE]}
    //         isError={isError?.[BASE_SETTINGS_KEYS.MEDIA_SIZE] || false}
    //         errorMessage={LABELS.ERROR.MEDIA_SIZE}
    //         onChange={(value) => {
    //           setFields((pre) => ({
    //             ...pre,
    //             [BASE_SETTINGS_KEYS.MEDIA_SIZE]: value.target.value,
    //           }));
    //           setIsError((pre) => ({
    //             ...pre,
    //             [BASE_SETTINGS_KEYS.MEDIA_SIZE]: !isMediaSizeValid(
    //               value.target.value
    //             ),
    //           }));
    //         }}
    //       />
    //     ),
    // },
    // {
    //   label: '',
    //   value: `${data?.[BASE_SETTINGS_KEYS.MAX_NUMBER_ATTACHMENT] || '10'}`,
    //   key: FIELD_VALIDATE_KEY.MAX_NUMBER_ATTACHMENT,
    //   errorString: LABELS.ERROR.MAX_NUMBER_ATTACHMENT,
    //   type: InputBoxTypes.NUMBER,
    //   valueKey: BASE_SETTINGS_KEYS.MAX_NUMBER_ATTACHMENT,
    //   onChange: (value) => {
    //     setFields((pre) => ({
    //       ...pre,
    //       [BASE_SETTINGS_KEYS.MAX_NUMBER_ATTACHMENT]: value,
    //     }));
    //     setIsError((pre) => ({
    //       ...pre,
    //       [BASE_SETTINGS_KEYS.MAX_NUMBER_ATTACHMENT]:
    //         !isAttachmentCountValid(value),
    //     }));
    //   },
    //   render: (newData, isError) =>
    //     newData[BASE_SETTINGS_KEYS.IS_ADD_ATTACHMENT] && (
    //       <CustomInput
    //         label={LABELS.FIELD_PROPERTIES.MAX_NUMBER_ATTACHMENT}
    //         myFontSize="14px"
    //         value={newData?.[BASE_SETTINGS_KEYS.MAX_NUMBER_ATTACHMENT]}
    //         isError={isError?.[BASE_SETTINGS_KEYS.MAX_NUMBER_ATTACHMENT] || false}
    //         errorMessage={LABELS.ERROR.MAX_NUMBER_ATTACHMENT}
    //         onChange={(value) => {
    //           setFields((pre) => ({
    //             ...pre,
    //             [BASE_SETTINGS_KEYS.MAX_NUMBER_ATTACHMENT]: value.target.value,
    //           }));
    //           setIsError((pre) => ({
    //             ...pre,
    //             [BASE_SETTINGS_KEYS.MAX_NUMBER_ATTACHMENT]: !isAttachmentCountValid(
    //               value.target.value
    //             ),
    //           }));
    //         }}
    //       />
    //     ),
    // },
  ],
});
export const advancedRichTextFields = (data, setFields) => ({
  row1: [
    {
      label: LABELS.FIELD_PROPERTIES.PLACEHOLDER,
      value: `${data?.[ADVANCED_SETTINGS_KEYS.PLACEHOLDER] || ''}`,
      placeholder: '',
      valueKey: ADVANCED_SETTINGS_KEYS.PLACEHOLDER,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [ADVANCED_SETTINGS_KEYS.PLACEHOLDER]: value,
        })),
    },
    {
      label: LABELS.FIELD_PROPERTIES.HINT_TEXT,
      value: `${data[ADVANCED_SETTINGS_KEYS.HINT_TEXT] || ''}`,
      placeholder: '',
      valueKey: ADVANCED_SETTINGS_KEYS.HINT_TEXT,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [ADVANCED_SETTINGS_KEYS.HINT_TEXT]: value,
        })),
    },
  ],
  row2: [],
  row3: [
    {
      label: `${LABELS.FIELD_PROPERTIES.REQUIRED}`,
      value: `${data?.[ADVANCED_SETTINGS_KEYS.REQUIRED] || ''}`,
      valueKey: ADVANCED_SETTINGS_KEYS.REQUIRED,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [ADVANCED_SETTINGS_KEYS.REQUIRED]: value,
        })),
    },
    // {
    //   label: LABELS.FIELD_PROPERTIES.READONLY,
    //   value: `${data?.[ADVANCED_SETTINGS_KEYS.READ_ONLY] || ''}`,
    //   valueKey: ADVANCED_SETTINGS_KEYS.READ_ONLY,
    //   onChange: (value) =>
    //     setFields((pre) => ({
    //       ...pre,
    //       [ADVANCED_SETTINGS_KEYS.READ_ONLY]: value,
    //     })),
    // },
    // {
    //   label: LABELS.FIELD_PROPERTIES.HIDDEN,
    //   value: `${data?.[ADVANCED_SETTINGS_KEYS.HIDDEN] || ''}`,
    //   valueKey: ADVANCED_SETTINGS_KEYS.HIDDEN,
    //   onChange: (value) =>
    //     setFields((pre) => ({
    //       ...pre,
    //       [ADVANCED_SETTINGS_KEYS.HIDDEN]: value,
    //     })),
    // },
    {
      label: LABELS.FIELD_PROPERTIES.SEARCHABLE,
      value: `${data?.[ADVANCED_SETTINGS_KEYS.SEARCHABLE] || ''}`,
      valueKey: ADVANCED_SETTINGS_KEYS.SEARCHABLE,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [ADVANCED_SETTINGS_KEYS.SEARCHABLE]: value,
        })),
    },
    {
      label: LABELS.FIELD_PROPERTIES.INCLUDE_IN_REPORT,
      value: `${data?.[ADVANCED_SETTINGS_KEYS.INCLUDE_IN_REPORT] || ''}`,
      valueKey: ADVANCED_SETTINGS_KEYS.INCLUDE_IN_REPORT,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [ADVANCED_SETTINGS_KEYS.INCLUDE_IN_REPORT]: value,
        })),
    },

  ],
  row4: [
    // {
    //   label: LABELS.FIELD_PROPERTIES.METRICS,
    //   value: `${data?.[ADVANCED_SETTINGS_KEYS.METRICS] || ''}`,
    //   placeholder: '',
    //   onChange: (value) =>
    //     setFields({ ...data, [ADVANCED_SETTINGS_KEYS.METRICS]: value }),
    // },
  ],
});
