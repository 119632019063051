import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, FormLabel, Wrap } from '@chakra-ui/react';
import _cloneDeep from 'lodash/cloneDeep';
import _remove from 'lodash/remove';
import _isEmpty from 'lodash/isEmpty';
import { AddIcon } from '@chakra-ui/icons';
import styles from './mailServer.module.scss';
import CustomSelectBox from '../../components/SelectBox/Select';
import CustomInput from '../../components/InputBox/Input';
import CustomButton from '../../components/Button/SimpleButton';
import { getWholeOption } from '../TicketTemplateWorkflow/helper';
import CustomWrapItemWithRemoveAction from './CustomWrapItemWithRemoveAction';
import { removeMatchingElementsForFolder } from './utils';

const RightAndLeftWithAddButton = ({
  label,
  values,
  onChange,
  leftOptionList,
  leftKey,
  rightKey,
}) => {
  const [expressionList, setExpressionsList] = useState(
    !_isEmpty(values) ? values : []
  );
  const [innerState, setInnerState] = useState({
    [leftKey]: '',
    [rightKey]: '',
  });

  const addExpression = () => {
    const newExpressions = _cloneDeep(expressionList);
    const localExpression = {
      [leftKey]: innerState[leftKey],
      [rightKey]: innerState[rightKey],
    };
    if (
      !newExpressions.some(
        (obj) =>
          obj[rightKey] === innerState[rightKey] &&
          obj[leftKey] === innerState[leftKey]
      )
    ) {
      newExpressions.push(localExpression);
      setExpressionsList(newExpressions);
      onChange(newExpressions);
    }
    setInnerState({
      [leftKey]: '',
      [rightKey]: '',
    });
  };
  const onAddExpressionInList = () => {
    if (innerState[leftKey] && innerState[rightKey]) {
      addExpression();
    }
  };
  const removeExpression = (expression) => {
    const newExpressions = _cloneDeep(expressionList);
    _remove(
      newExpressions,
      (ex) =>
        ex[leftKey] === expression[leftKey] &&
        ex[rightKey] === expression[rightKey]
    );
    setExpressionsList(newExpressions);
    onChange(newExpressions);
  };
  return (
    <>
      <FormLabel className="SCLabel">{label}</FormLabel>
      <div className={styles.flexFormBox}>
        <Box w="270px">
          <CustomSelectBox
            options={removeMatchingElementsForFolder(
              expressionList,
              leftOptionList
            )}
            onChange={(e) => {
              setInnerState((pre) => ({
                ...pre,
                [leftKey]: e.value,
              }));
            }}
            value={getWholeOption(innerState[leftKey], leftOptionList)}
          />
        </Box>
        <Box w="270px">
          <CustomInput
            value={innerState[rightKey] || ''}
            onChange={(e) => {
              setInnerState((pre) => ({
                ...pre,
                [rightKey]: e.target.value,
              }));
            }}
            height="38px"
          />
        </Box>
        <CustomButton
          leftIcon={<AddIcon />}
          variant="solid"
          buttonColor="customBlue"
          buttonText=""
          className="iconButton"
          style={{ borderRadius: '50%' }}
          onClick={onAddExpressionInList}
        />
      </div>
      {!_isEmpty(expressionList) && (
        <Wrap className={styles.right__left_wrap}>
          {expressionList.map((exp) => (
            <CustomWrapItemWithRemoveAction
              wrapTitle={`${
                getWholeOption(exp[leftKey], leftOptionList).label
              } : ${exp[rightKey]}`}
              onRemoved={() => {
                removeExpression(exp);
              }}
            />
          ))}
        </Wrap>
      )}
    </>
  );
};

RightAndLeftWithAddButton.propTypes = {
  label: PropTypes.string.isRequired,
  values: PropTypes.array,
  onChange: PropTypes.func,
  leftOptionList: PropTypes.array,
  leftKey: PropTypes.string.isRequired,
  rightKey: PropTypes.string.isRequired,
};
RightAndLeftWithAddButton.defaultProps = {
  values: [],
  onChange: () => {},
  leftOptionList: [],
};

export default RightAndLeftWithAddButton;
