/* eslint-disable camelcase */
import React, { useReducer, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ArrowBackIcon, WarningIcon } from '@chakra-ui/icons';
import {
  BG_BLUE,
  RULE_CARD_BORDER,
  WHITE_COLOR,
} from '../../../utils/enums/colors';
import { LABELS } from '../../../utils/enums/labels';
import AddEditActorContainer from './AddEditActorContainer';
import {
  makeApiCallForActor,
  makeApiCallForField,
  reducerForState,
} from './helper';
import { updateViewState } from '../../../redux/ViewSlice/ViewReducer';
import { setActiveModal } from '../../../redux/ModalReducer/ModalSlice';
import { patchDataForTemplate } from '../utils/templateAPIUtils';
import CustomToast from '../../../components/Notification/Notification';
import { PrimaryCustomButton, SecondaryCustomButton } from '../../../components/Button/PrimarySecondaryButton';

const AddEditActorModal = ({
  edit,
  isAddEditActorModal,
  setIsAddEditActorModal,
  initStateForActor,
}) => {
  const [actorOption, setActorOption] = useState([]);
  const [fieldOption, setFieldOption] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { addToast } = CustomToast();
  const [actorState, actorDispatch] = useReducer(
    reducerForState,
    initStateForActor
  );
  const { ticket_template_actors_list_view, actors_expanded_list } = useSelector(
    (state) => state.viewState
  );
  const { editActorInTemplateModal } = useSelector((state) => state.viewState);
  const dispatch = useDispatch();
  const { id } = useParams();

  const addNewActorInTemplate = async () => {
    setIsLoading(true);
    const newData = [
      ...ticket_template_actors_list_view.data,
      { ...actorState, id: uuidv4() },
    ];
    const response = await patchDataForTemplate({
      id,
      data: newData,
      key: 'actors',
      successMessage: 'Added an actor in template level',
      defaultErrorMessage: 'Failed to add an actor in template',
    });
    if (!response.error) {
      dispatch(
        updateViewState({
          stateKey: 'ticket_template_actors_list_view',
          value: {
            ...ticket_template_actors_list_view,
            data: newData,
          },
        })
      );
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: {
            ...response?.response?.data,
          },
        })
      );
    }
    setIsLoading(false);
    setIsAddEditActorModal(false);
  };
  const updateActorInTemplate = async () => {
    setIsLoading(true);
    const editedActorIndex = ticket_template_actors_list_view.data.findIndex(
      (actor) => actor.id === editActorInTemplateModal.id
    );
    if (editedActorIndex !== -1) {
      const updatedActors = [...ticket_template_actors_list_view.data];
      updatedActors[editedActorIndex] = actorState;
      const response = await patchDataForTemplate({
        id,
        data: updatedActors,
        key: 'actors',
        successMessage: 'Updated an actor in template level',
        defaultErrorMessage: 'Failed to update an actor in template',
      });
      if (!response.error) {
        dispatch(
          updateViewState({
            stateKey: 'ticket_template_actors_list_view',
            value: {
              ...ticket_template_actors_list_view,
              data: updatedActors,
            },
          })
        );
        dispatch(
          updateViewState({
            stateKey: 'selectedTemplate',
            value: {
              ...response?.response?.data,
            },
          })
        );
      }
    }
    setIsLoading(false);
    setIsAddEditActorModal(false);
  };
  const duplicateValidate = () => {
    let isDuplicate = false;
    // eslint-disable-next-line no-restricted-syntax
    for (const actor of ticket_template_actors_list_view.data) {
      if (actor.id !== actorState.id) {
      if (actor.name === actorState.name) {
        addToast({
          title: 'Failed to Add Actor',
          message: 'Actor Name already linked with another actor',
          type: 'error',
        });
        isDuplicate = true;
        break;
      } else if (actor.associated_field === actorState.associated_field) {
        addToast({
          title: 'Failed to Add Actor',
          message: 'Ticket Field already linked with another actor',
          type: 'error',
        });
        isDuplicate = true;
        break;
      }
    }}

    if (isDuplicate) setIsLoading(false);
    return isDuplicate;
  };
  const handleAddOrUpdateButton = () => {
    setIsLoading(true);
    if (duplicateValidate()) {
      setIsLoading(false); 
      return;
    }
    if (edit) {
      updateActorInTemplate();
    } else {
      addNewActorInTemplate();
    }
    dispatch(setActiveModal({ targetModalId: undefined }));
  };
  const fetchDataForActorOption = async () => {
    const { rows, count } = await makeApiCallForActor();
    // Handle the result here
    const option = Array.isArray(rows)
      ? rows.map((item) => ({
        value: item.id,
        label: item.name,
      }))
      : [];
    dispatch(updateViewState({ stateKey: 'actors_expanded_list', value: { data: rows, count, filter: '' } }));
    setActorOption(option);
  };
  const fetchDataForFieldOption = async () => {
    const result = await makeApiCallForField(id);
    const option = result?.fields?.map((item) => ({
      value: item.id,
      label: item.label,
    }));
    setFieldOption(option);
  };

  // this for every time actor list change in redux it will update actor option list
  useEffect(() => {
    // here data is actor list
    const option = Array.isArray(actors_expanded_list?.data)
      ? actors_expanded_list.data.map((item) => ({
        value: item.id,
        label: item.name,
      }))
      : [];
    setActorOption(option);
  }, [actors_expanded_list?.data]);

  useEffect(() => {
    fetchDataForActorOption();
    fetchDataForFieldOption();
  }, []);

  return (
    <Drawer
      size="lg"
      placement="right"
      onClose={() => {
        setIsAddEditActorModal(!isAddEditActorModal);
        dispatch(setActiveModal({ targetModalId: undefined }));
      }}
      isOpen={isAddEditActorModal}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Flex
            alignItems="center"
            gap="10px"
            borderBottom={`1px solid ${RULE_CARD_BORDER}`}
            pb="10px"
          >
            <IconButton
              colorScheme="blue"
              aria-label="Close Drawer"
              size="sm"
              borderRadius="50%"
              minW="1.5rem"
              height="1.5rem"
              variant="outline"
              icon={<ArrowBackIcon />}
              onClick={() => {
                setIsAddEditActorModal(!isAddEditActorModal);
                dispatch(setActiveModal({ targetModalId: undefined }));
              }}
            />
            <Text fontSize="16px">{edit ? 'Edit Actor' : 'Add Actor'}</Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <Box
            display="flex"
            p="8px"
            bgColor={WHITE_COLOR}
            mt="8px"
            data-testid="ShowEditStatusWarningBox"
            color={BG_BLUE}
          >
            <WarningIcon color={BG_BLUE} />
            <p
              className="text-[12px] pl-[5px]"
              data-testid="ShowEditStatusWarningP"
            >
              Actors will also be added in the masterlist
            </p>
          </Box>
          <AddEditActorContainer
            actorState={actorState}
            actorDispatch={actorDispatch}
            actorOption={actorOption}
            setActorOption={setActorOption}
            fieldOption={fieldOption}
          />
        </DrawerBody>
        <DrawerFooter>
          <Flex gap="8px" pt="60px" data-testid="AddEditActorModalFlex">
            <SecondaryCustomButton
              buttonText={LABELS.ACTIONS.CANCEL}
              onClick={() => {
                setIsAddEditActorModal(false);
                dispatch(setActiveModal({ targetModalId: undefined }));
              }}
              id="CreateActorInMasterListCancelBtn"
            />
            <PrimaryCustomButton
              isDisabled={
                isEmpty(actorState) ||
                !actorState.actor_id ||
                !actorState.associated_field_id
              }
              buttonText={edit ? 'Update Actor' : 'Add Actor'}
              onClick={handleAddOrUpdateButton}
              id="CreateActorInMasterListGridsubmit_btn"
              isLoading={isLoading}
            />
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
AddEditActorModal.propTypes = {
  edit: PropTypes.bool,
  isAddEditActorModal: PropTypes.bool.isRequired,
  setIsAddEditActorModal: PropTypes.func.isRequired,
  initStateForActor: PropTypes.object,
};
AddEditActorModal.defaultProps = {
  edit: false,
  initStateForActor: {},
};
export default AddEditActorModal;
