// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.U7XOBrEUzIM8p56Vzhr0:hover>span{stroke:#fff}`, "",{"version":3,"sources":["webpack://./src/pages/DynamicRenderer/WorkFlowLinkButton.module.scss"],"names":[],"mappings":"AAAA,iCACY,WAAA","sourcesContent":[".workflow_button:hover > span {\n            stroke: white;\n        }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workflow_button": `U7XOBrEUzIM8p56Vzhr0`
};
export default ___CSS_LOADER_EXPORT___;
