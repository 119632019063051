import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';

import CustomSelectBox from '../../../components/SelectBox/Select';

import { useQueue } from './customDynamicSelect.helpers';

const QueueField = ({ value, onChange }) => {
  const { teamOptionsState, queueOptionState, getTeamAndQueueInfo } =
    useQueue();

  const [selectedTeam, setSelectedTeam] = useState(null);

  const handleChange = useCallback((queue) => {
    const temp = Array.isArray(value) ? cloneDeep(value) : [];
    temp.push(queue);
    onChange(queue);
  }, [value, onChange]);

  const getOption = useCallback((val) => {
    if (!val) {
      return null;
    }

    if (Array.isArray(val)) {
      return val.map((v) => ({
        label: v,
        value: v,
      }));
    }

    return {
      label: val,
      value: val,
    };
  }, []);

  useEffect(() => {
    getTeamAndQueueInfo();
  }, [getTeamAndQueueInfo]);

  return (
    <div>
      <div className="mb-2">
        <CustomSelectBox
          options={teamOptionsState}
          value={getOption(selectedTeam)}
          onChange={(e) => {
            setSelectedTeam(e.value);
          }}
        />
      </div>

      <div className="mb-2">
        <CustomSelectBox
          options={queueOptionState?.[selectedTeam] || []}
          isMulti
          value={getOption(value)}
          onChange={(e) => {
            handleChange(e.map((v) => v.value));
          }}
        />
      </div>
    </div>
  );
};

QueueField.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

QueueField.defaultProps = {
  value: [],
};

export default QueueField;
