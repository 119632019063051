import React, { useCallback, useMemo, useState } from 'react';

import { Flex, Grid, GridItem, useColorModeValue } from '@chakra-ui/react';

import PropTypes from 'prop-types';

import CustomInput from '../../../components/InputBox/Input';
import CustomButton from '../../../components/Button/SimpleButton';

import { saveRelationDefinition } from './relation.service';

import { COLORS } from '../../../utils/enums/colors';
import { LABELS } from '../../../utils/enums/labels';
import { CustomDrawer } from '../../TicketTemplateBuilder/ConfigureTemplateView/AddEditTemplateLayoutModal';

const KEYS = {
  FORWARD: 'forward',
  REVERSE: 'reverse',
};

const NewRelationModal = ({ show, setShow, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [relation, setRelation] = useState({
    [KEYS.FORWARD]: '',
    [KEYS.REVERSE]: '',
  });

  const [error, setError] = useState({
    [KEYS.FORWARD]: '',
    [KEYS.REVERSE]: '',
  });

  const handleChange = useCallback((key, value) => {
    setRelation((prev) => ({
      ...prev,
      [key]: value,
    }));

    setError((prev) => ({
      ...prev,
      [key]: '',
    }));
  }, []);

  const onAddNewRelation = useCallback(() => {
    if (!relation[KEYS.FORWARD] || !relation[KEYS.REVERSE]) {
      setError({
        [KEYS.FORWARD]: !relation[KEYS.FORWARD] ? 'This field is required' : '',
        [KEYS.REVERSE]: !relation[KEYS.REVERSE] ? 'This field is required' : '',
      });
      return;
    }

    setIsLoading(true);
    const payload = {
      string_pair_a: relation?.[KEYS.FORWARD]?.trim(),
      string_pair_b: relation?.[KEYS.REVERSE]?.trim(),
    };

    saveRelationDefinition(payload)
      .then((res) => {
        if (res) {
          onSuccess(res);
          setShow(false);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [relation, onSuccess, setShow]);

  const modalContent = useMemo(
    () => (
      <Grid mt="38px" data-testid="RelationModalGrid">
        <GridItem w="100%" data-testid="RelationModalGridItem1">
          <CustomInput
            id="RelationModalAtoB"
            label="A -> B"
            type="text"
            onChange={(e) => {
              handleChange(KEYS.FORWARD, e.target.value);
            }}
            value={relation[KEYS.FORWARD]}
            isMandatory
            isError={!!error[KEYS.FORWARD]}
            errorMessage={error[KEYS.FORWARD]}
          />
        </GridItem>

        <GridItem w="100%" data-testid="RelationModalGridItem2">
          <CustomInput
            id="RelationModalBtoA"
            label="B -> A"
            type="text"
            onChange={(e) => {
              handleChange(KEYS.REVERSE, e.target.value);
            }}
            isMandatory
            value={relation[KEYS.REVERSE]}
            isError={!!error[KEYS.REVERSE]}
            errorMessage={error[KEYS.REVERSE]}
          />
        </GridItem>
      </Grid>
    ),
    [relation, error, handleChange]
  );

  const modalFooter = useMemo(
    () => (
      <Flex gap="8px" data-testid="relationModalFlex">
        <CustomButton
          variant="outline"
          pl="25px"
          pr="25px"
          buttonColor="#868E96"
          buttonText={LABELS.ACTIONS.CANCEL}
          style={{ border: 'none' }}
          _hover={{ color: useColorModeValue(COLORS.BLACK, 'white') }}
          onClick={() => {
            setShow(false);
          }}
          size="lg"
          id="relationModal_cancel_button"
        />
        <CustomButton
          variant="solid"
          pl="25px"
          pr="25px"
          buttonColor="customBlue"
          buttonText="Submit"
          onClick={() => {
            onAddNewRelation();
          }}
          isDisabled={!relation[KEYS.FORWARD] || !relation[KEYS.REVERSE]}
          size="lg"
          id="relationModal_submit_btn"
          isLoading={isLoading}
        />
      </Flex>
    ),
    [setShow, relation, isLoading, onAddNewRelation]
  );

  return (
    <CustomDrawer
      drawerBody={modalContent}
      drawerFooter={modalFooter}
      modalTitle="Create Relation"
      openDrawer={show}
      setOpenDrawer={setShow}
    />
  );
};

NewRelationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default NewRelationModal;
