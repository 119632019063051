/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Box, SimpleGrid } from '@chakra-ui/layout';
import PropTypes from 'prop-types';
import { FormControl, FormLabel, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { DARK, LIGHT } from '../../../utils/enums/colors';
import SelectComponent from '../../../components/SelectBox/SelectComponent';
import {
  fieldColSpanOptions,
  fieldColorOptions,
  fieldFontOptions,
  slaOptions,
  layoutViewTypes,
  noteTypeOptions,
  badgeOptions,
} from '../../../utils/enums/selectOption';
import CustomInput from '../../../components/InputBox/Input';
import { LABELS } from '../../../utils/enums/labels';
import CustomCheckBox from '../../../components/CheckBox/CheckBox';
import DynamicMutliSelectWithTags from '../../../components/dynamicSelectWithTags/DynamicSelectWithTags';
import { FieldTypes } from '../../../utils/enums/types';
import { CustomDrawer } from './AddEditTemplateLayoutModal';
import {
  PrimaryCustomButton,
  SecondaryCustomButton,
} from '../../../components/Button/PrimarySecondaryButton';
import { getRelations } from '../../DynamicRenderer/Relation/relation.service';
import axiosInstance from '../../../utils/axios/axios';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { getWholeOption } from '../../TicketTemplateWorkflow/helper';
import { generateOfferIdMap } from '../../DynamicRenderer/Relation/relation.helper';

const getName = (fieldData) => {
  const name = fieldData?.name || fieldData?.label;
  if (fieldData?.type === 'SF_METRIC') {
    return `${name} (Metric)`;
  }
  if (fieldData?.type === 'SF_SLAS') {
    return `${name} (SLA)`;
  }
  return name;
};

const EditFieldLayoutModal = ({
  isOpen,
  setIsOpen,
  fieldData,
  onCancel,
  onConfirmation,
  viewType,
}) => {
  const [fieldColSpan, setfieldColSpan] = useState(fieldData?.width);
  const [fieldRowSpan, setfieldRowSpan] = useState(fieldData?.height);
  const [error, setError] = useState('');
  const [fieldFontSize, setFieldFontSize] = useState(fieldData?.fieldFontSize);
  const [fieldFontColor, setFieldFontColor] = useState(
    fieldData?.fieldFontColor
  );
  const [slaViewType, setSlaViewType] = useState(fieldData?.slaViewType);
  // const [fieldReadOnly, setFieldReadOnly] = useState(
  //   fieldData?.type_based_attributes?.read_only || false
  // );
  const [fontWeight, setFontWeight] = useState(fieldData?.fieldFontWeight);
  const [noteTypes, setNoteTypes] = useState(fieldData?.noteTypes || []);
  const [isNoteRequired, setIsNoteRequired] = useState(
    fieldData?.isNoteRequired || false
  );
  const [isBadge, setIsBadge] = useState(fieldData?.isBadge || false);
  const [badgeColors, setBadgeColors] = useState(fieldData?.badgeColors);
  const [isPriority, setIsPriority] = useState(fieldData?.isPriority || false);
  const [fieldName, setFieldName] = useState(fieldData?.label || '');
  const [relationType, setRelationType] = useState(
    fieldData?.relationType || []
  );
  const [itemId, setItemId] = useState(fieldData?.itemId || '');
  const [offerId, setOfferId] = useState(fieldData?.offerId || '');
  const [relations, setRelations] = useState([]);
  const [relationItemTypeOption, setRelationItemTypeOption] = useState([]);
  const [itemListOption, setItemListOption] = useState([]);
  const [offers, setOffers] = useState([]);
  const [offerIdMap, setOfferIdMap] = useState({});
  const { selectedTemplate } = useSelector(
    (reduxState) => reduxState.viewState
  );

  const modalFooter = (
    <Box data-testid="EditFieldLayoutModalBox" className="flex gap-3">
      <SecondaryCustomButton
        onClick={() => {
          onCancel();
        }}
        buttonText={LABELS.ACTIONS.CANCEL}
        id="EditFieldLayoutCancenBtn"
      />
      <PrimaryCustomButton
        onClick={() => {
          const commonData = {
            ...fieldData,
            width: fieldColSpan,
            height: fieldRowSpan || 1,
            // read_only: fieldReadOnly,
            fieldFontSize,
            fieldFontColor,
            fieldFontWeight: fontWeight,
            slaViewType,
            noteTypes,
            isNoteRequired,
            isBadge,
            isPriority,
            badgeColors,
          };

          const extendedData =
            fieldData?.type === 'SF_LINKED_TICKETS_CUSTOM'
              ? {
                  ...commonData,
                  label: fieldName,
                  relationType,
                  itemId,
                  offerId,
                }
              : commonData;
          onConfirmation(extendedData);
        }}
        buttonText="Save"
        d="EditFieldLayoutSaveBtn"
      />
    </Box>
  );
  useEffect(() => {
    if (selectedTemplate) {
      getRelations(selectedTemplate.id).then((res) => {
        setRelations(res);
      });
    }
  }, [selectedTemplate]);

  useEffect(() => {
    axiosInstance
      .get(`fieldDefaults/list?$select=*&$filter=offer_type ne 'email'`)
      .then((response) => {
        const allOffers = response?.data?.rows || [];
        setOffers(allOffers);
      });
  }, []);

  useEffect(() => {
    setOfferIdMap(generateOfferIdMap(offers));
  }, [offers]);

  const generateRelations = (list, relationsData) => {
    const result = {};

    // Initialize the result object with empty arrays for each item in itemList
    list.forEach((item) => {
      result[item] = [];
    });

    // Iterate through each relation
    relationsData.forEach((relation) => {
      // Check if template_id_left matches an item in itemList
      // eslint-disable-next-line no-prototype-builtins
      if (result.hasOwnProperty(relation.offer_id_left)) {
        const leftValue = relation.relation_def.right_to_left;
        if (
          !result[relation.offer_id_left].some((r) => r.value === leftValue)
        ) {
          result[relation.offer_id_left].push({
            value: leftValue,
            label: leftValue,
          });
        }
      }

      // Check if template_id_right matches an item in itemList
      // eslint-disable-next-line no-prototype-builtins
      if (result.hasOwnProperty(relation.offer_id_right)) {
        const rightValue = relation.relation_def.left_to_right;
        if (
          !result[relation.offer_id_right].some((r) => r.value === rightValue)
        ) {
          result[relation.offer_id_right].push({
            value: rightValue,
            label: rightValue,
          });
        }
      }
    });

    return result;
  };

  const getTemplateId = (offerID, relationList) => {
    const relation = relationList.find(
      (rel) => rel.offer_id_left === offerID || rel.offer_id_right === offerID
    );

    if (!relation) return '';

    return relation.offer_id_left === offerID
      ? relation.template_id_left
      : relation.template_id_right;
  };

  useEffect(() => {
    const FILTER_ITEM_RELATION = [
      ...new Set(
        relations.flatMap((item) => {
          if (item.offer_id_left === item.offer_id_right) {
            return [item.offer_id_left];
          }
          return [item.offer_id_left, item.offer_id_right];
        })
      ),
    ];
    const ITEM_WITH_VALUE_LABEL = FILTER_ITEM_RELATION.map((item) => ({
      value: item,
      label: offerIdMap[item]?.name,
      item: getTemplateId(item, relations),
    }));
    setItemListOption(ITEM_WITH_VALUE_LABEL);
    const itemOptionList = generateRelations(FILTER_ITEM_RELATION, relations);
    setRelationItemTypeOption(itemOptionList);
  }, [offerIdMap]);

  const handleChange = (e) => {
    let { value } = e.target;
    value = value.replace(/-/g, '');
    if (/^\d+$/.test(value)) {
      setfieldRowSpan(value);
      setError('');
    } else if (value === '') {
      setfieldRowSpan('');
      setError('Please enter a positive value.');
    } else {
      setError('Please enter a positive value.');
    }
  };

  return (
    <CustomDrawer
      modalTitle="Edit Field Layout"
      id="delete-modal"
      drawerBody={
        <>
          {fieldData?.type === 'SF_LINKED_TICKETS_CUSTOM' ? (
            <CustomInput
              value={fieldName}
              onChange={(e) => {
                setFieldName(e.target.value);
              }}
              label="Name"
            />
          ) : (
            <div>{getName(fieldData)}</div>
          )}
          <SimpleGrid columns={2} spacing={4} mt="4">
            <FormControl data-testid="EditFieldLayoutModalFormControl">
              <FormLabel data-testid="EditFieldLayoutModalFormLabel">
                Colspan(s)
              </FormLabel>
              <SelectComponent
                options={fieldColSpanOptions}
                value={fieldColSpan}
                handleChange={(value) => setfieldColSpan(value)}
                focusBorderColor={useColorModeValue(LIGHT, DARK)}
              />
            </FormControl>
            <FormControl data-testid="EditFieldLayoutModalCIFormControl">
              <FormLabel>RowSpan(s)</FormLabel>
              <CustomInput
                value={fieldRowSpan}
                // onChange={(e) => {
                //   setfieldRowSpan(e.target.value);
                // }}
                onChange={handleChange}
                id="EditFieldCIDivCInput"
              />
              {error && <Text color="red.500" fontSize="sm" mt="1">{error}</Text>}
            </FormControl>
            {fieldData?.type === 'SF_SLAS' && (
              <FormControl data-testid="EditFieldLayoutModalSLAType">
                <FormLabel>SLA View Type </FormLabel>
                <SelectComponent
                  options={slaOptions}
                  value={slaViewType}
                  handleChange={(value) => {
                    setSlaViewType(value);
                  }}
                  focusBorderColor={useColorModeValue(LIGHT, DARK)}
                />
              </FormControl>
            )}
            {fieldData?.type !== 'SF_SLAS' &&
              viewType === layoutViewTypes.INBOX && (
                <>
                  <FormControl data-testid="EditFieldLayoutModalCIFormControl">
                    <FormLabel>Font Size</FormLabel>
                    <SelectComponent
                      options={fieldFontOptions}
                      value={fieldFontSize}
                      handleChange={(value) => {
                        setFieldFontSize(value);
                        const selectedOption = fieldFontOptions.find(
                          (option) => option.value === value
                        );
                        setFontWeight(
                          selectedOption ? selectedOption.fontWeight : ''
                        );
                      }}
                      focusBorderColor={useColorModeValue(LIGHT, DARK)}
                    />
                  </FormControl>
                  <FormControl data-testid="EditFieldLayoutModalCIFormControl">
                    <FormLabel>Color</FormLabel>
                    <SelectComponent
                      options={fieldColorOptions}
                      value={fieldFontColor}
                      handleChange={(value) => setFieldFontColor(value)}
                      focusBorderColor={useColorModeValue(LIGHT, DARK)}
                    />
                  </FormControl>
                </>
              )}

            {/* For adding badge Types */}
            {fieldData?.type !== FieldTypes.DATE &&
              fieldData?.type !== FieldTypes.NUMBER &&
              fieldData?.type !== FieldTypes.TEXT &&
              fieldData?.type !== FieldTypes.TEXT_AREA &&
              fieldData?.type !== FieldTypes.RICH_TEXT &&
              fieldData?.type !== 'SF_SLAS' &&
              fieldData?.type !== 'SF_METRIC' &&
              fieldData?.type !== 'SF_NOTES' &&
              viewType === layoutViewTypes.INBOX && (
                <>
                  <FormControl data-testid="EditFieldLayoutModalCIFormControlBadge">
                    <CustomCheckBox
                      onChange={() => setIsBadge(!isBadge)}
                      isChecked={isBadge}
                      checkBoxText="isBadge"
                    />
                  </FormControl>
                  <FormControl data-testid="EditFieldLayoutModalCIFormControlBadgeColor">
                    <CustomCheckBox
                      onChange={() => setIsPriority(!isPriority)}
                      isChecked={isPriority}
                      checkBoxText="isPriority"
                    />
                    {isPriority && (
                      <FormLabel mt={10}> Map Badge Color </FormLabel>
                    )}

                    {isPriority &&
                      fieldData?.type_based_attributes?.collection?.staticValues?.map(
                        (i) => (
                          <>
                            <FormLabel mt={4}>{i?.label}</FormLabel>
                            <SelectComponent
                              options={badgeOptions}
                              value={badgeColors?.[i?.label] || ''}
                              handleChange={(value) =>
                                setBadgeColors((prevBadgeColors) => ({
                                  ...prevBadgeColors,
                                  [i.label]: value,
                                }))
                              }
                              focusBorderColor={useColorModeValue(LIGHT, DARK)}
                            />
                          </>
                        )
                      )}
                  </FormControl>
                </>
              )}
            {fieldData?.type === 'SF_NOTES' &&
              viewType === layoutViewTypes.TRANSITION && (
                <>
                  <FormControl data-testid="EditFieldLayoutModalCIFormControl">
                    <DynamicMutliSelectWithTags
                      label="Note Type"
                      onChange={setNoteTypes}
                      selectOptions={noteTypeOptions}
                      values={noteTypes}
                      labelWeight="500"
                      runHandlerOnEmptyTags
                    />
                  </FormControl>
                  <FormControl data-testid="EditFieldLayoutModalCIFormControl" />
                  <FormControl data-testid="EditFieldLayoutModalCIFormControl">
                    <CustomCheckBox
                      onChange={() => setIsNoteRequired(!isNoteRequired)}
                      isChecked={isNoteRequired}
                      checkBoxText="Note Required for Transition?"
                    />
                  </FormControl>
                </>
              )}
            {/* <CustomCheckBox
              onChange={() => setFieldReadOnly(!fieldReadOnly)}
              isChecked={fieldReadOnly}
              checkBoxText="Read Only"
              isDisabled={false}
            /> */}
          </SimpleGrid>
          {fieldData?.type === 'SF_LINKED_TICKETS_CUSTOM' && (
            <>
              <Box>
                <CustomSelectBox
                  options={itemListOption}
                  placeholder="Select Offer"
                  value={getWholeOption(offerId, itemListOption)}
                  onChange={({ value, item }) => {
                    //   handleBasicErrorData('trigger');
                    setOfferId(value);
                    setItemId(item);
                    setRelationType([]);
                  }}
                  focusBorderColor={useColorModeValue(LIGHT, DARK)}
                  label="Select Offer"
                />
              </Box>
              <Box>
                <CustomSelectBox
                  options={relationItemTypeOption[offerId] || []}
                  placeholder="Select Relation"
                  value={relationType || []}
                  onChange={(value) => {
                    setRelationType(value);
                  }}
                  focusBorderColor={useColorModeValue(LIGHT, DARK)}
                  label="Select Relation"
                  isMulti
                />
              </Box>
            </>
          )}
        </>
      }
      drawerFooter={modalFooter}
      openDrawer={isOpen}
      setOpenDrawer={setIsOpen}
    />
  );
};

EditFieldLayoutModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onConfirmation: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  fieldData: PropTypes.object.isRequired,
  viewType: PropTypes.string,
};

EditFieldLayoutModal.defaultProps = {
  viewType: '',
};
export default EditFieldLayoutModal;
