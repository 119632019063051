import React from 'react';

import {
  Box
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import _camelCase from 'lodash/camelCase';
import _startCase from 'lodash/startCase';
import CustomTable from '../../components/Table/Table';
import NoRecords from '../../components/Table/NoRecords';

const CommunicationTemplateCommonTable = ({
  columns,
  tableData,
  onRowClick,
  filteredData,
  addButtonClick,
  tabType,
  showNoRecord,
}) => (
    <Box p="16px" data-testid="CommunicationTemplateCommonTableContainerBox">
      {showNoRecord && isEmpty(tableData) ? (
        <NoRecords
          title={`You have not added ${tabType.toLowerCase()}`}
          subTitle="Click 'Create New' to get started"
          buttonText="Create New"
          onAdd={() => {
            addButtonClick(tabType);
          }}
        />
      ) : (
        <Box
          my="16px"
          data-testid="CommunicationTemplateCommonTableTableContainerBox"
        >

            <CustomTable
              height="calc(100vh - 20rem)"
              columns={columns}
              tableData={filteredData}
              onRowClick={onRowClick}
              title={_startCase(_camelCase(tabType))}
            />
        </Box>
      )}
    </Box>
);

CommunicationTemplateCommonTable.propTypes = {
  columns: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  addButtonClick: PropTypes.func.isRequired,
  tabType: PropTypes.string,
  showNoRecord: PropTypes.bool,
};

CommunicationTemplateCommonTable.defaultProps = {
  onRowClick: undefined,
  tabType: 'EMAIL',
  showNoRecord: false,
};

export default CommunicationTemplateCommonTable;
