/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';

export const AddOrIcon = ({ color, stroke }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="19"
      height="19"
      rx="9.5"
      fill={color}
      stroke={stroke}
    />
    <path
      d="M10 5V15M15 10H5"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

AddOrIcon.propTypes = {
  color: PropTypes.string.isRequired,
  stroke: PropTypes.string.isRequired,
};
