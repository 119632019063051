import Joi from 'joi';
import { v4 as uuidv4 } from 'uuid';
import axiosInstance from '../../../utils/axios/axios';
import { DROPDOWN_TYPES } from '../../TenantFilters/filters.constants';

export const META_FIELD_KEY = {
  NAME: 'name',
  LINK_DATA: 'link_data',
  IS_ASSOCIATED_PM: 'is_associated_pm',
  FIELD_DATA: 'field_data',
  TEMPLATE_ID: 'template_id',
  FIELD_ID: 'field_id',
  TENANT_ID: 'tenant_id',
  META_FIELD_ID: 'meta_field_id',
  META_FIELD_TYPE: 'meta_field_type',
  META_UUID_COLUMN: 'uuid_column',
  META_FIELD_SCOPE: 'meta_field_scope',
  META_FIELD_SUB_TYPE: 'meta_field_sub_type',
  META_FIELD_SPECIAL_FIELD_TYPE: 'meta_field_special_field_type',
  META_FIELD_LEVEL_OF_FIELD_TYPE: 'meta_field_dependent_level',
  META_FIELD_HIERARCHY_ID: 'meta_field_hierarchy_id'
};
export const META_FIELD_DEFAULT_STATE = {
  [META_FIELD_KEY.NAME]: '',
  [META_FIELD_KEY.META_FIELD_ID]: '',
  [META_FIELD_KEY.FIELD_DATA]: [],
  [META_FIELD_KEY.META_FIELD_TYPE]: '',
  [META_FIELD_KEY.META_FIELD_SCOPE]: 'TICKET',
  [META_FIELD_KEY.META_FIELD_SUB_TYPE]: null,
  [META_FIELD_KEY.META_FIELD_SPECIAL_FIELD_TYPE]: null,
};

export const removeMatchingOption = (optionList, stateForComparison) => {
  const templateIds = stateForComparison.map((item) => item.template_id);
  const filteredDataArray = [...(optionList || [])].filter(
    (item) => !templateIds.includes(item?.value?.toString())
  );

  return filteredDataArray;
};

export const updateMetaFieldValidation = Joi.object({
  [META_FIELD_KEY.NAME]: Joi.string().required(),
  [META_FIELD_KEY.META_FIELD_ID]: Joi.string(),
  [META_FIELD_KEY.TENANT_ID]: Joi.string().guid({ version: 'uuidv4' }),
  [META_FIELD_KEY.FIELD_DATA]: Joi.array()
    .items(
      //Joi.object({
        // [META_FIELD_KEY.TEMPLATE_ID]: Joi.string().required().trim().min(1),
        // [META_FIELD_KEY.FIELD_ID]: Joi.string().required().trim().min(1),
        // [META_FIELD_KEY.META_UUID_COLUMN]: Joi.string(),
     // })
    )
    .default([]),
  [META_FIELD_KEY.META_FIELD_SCOPE]: Joi.string(),
  [META_FIELD_KEY.META_FIELD_SUB_TYPE]: Joi
    .valid(DROPDOWN_TYPES.STATIC, DROPDOWN_TYPES.SPECIAL_FIELD, DROPDOWN_TYPES.DEPENDENT)
    .allow(null),
  [META_FIELD_KEY.META_FIELD_SPECIAL_FIELD_TYPE]: Joi.alternatives().conditional('meta_field_sub_type', {
    is: DROPDOWN_TYPES.SPECIAL_FIELD,
    then: Joi.string().required(),
    otherwise: Joi.any(),
  }),
  [META_FIELD_KEY.META_FIELD_TYPE]: Joi.string(),
  [META_FIELD_KEY.META_FIELD_HIERARCHY_ID]: Joi.alternatives().conditional('meta_field_sub_type', {
    is: DROPDOWN_TYPES.DEPENDENT,
    then: Joi.string().required(),
    otherwise: Joi.any(),
  }),
  [META_FIELD_KEY.META_FIELD_LEVEL_OF_FIELD_TYPE]: Joi.alternatives().conditional('meta_field_sub_type', {
    is: DROPDOWN_TYPES.DEPENDENT,
    then: Joi.number().min(1).required(),
    otherwise: Joi.any(),
  }),
});

export const createMetaFieldPayload = (data) => {
  const payload = {
    name: data?.[META_FIELD_KEY.NAME]?.replace(/\s+/g, ' ').trim(),
    meta_field_id: data?.[META_FIELD_KEY.META_FIELD_ID] || uuidv4(),
    field_data: [...(data?.[META_FIELD_KEY.FIELD_DATA] || [])].filter((item) => item.template_id !== '' && item.field_id !== ''),
    meta_field_type: data?.[META_FIELD_KEY.META_FIELD_TYPE],
    meta_field_scope: 'TICKET',
    meta_field_sub_type: data?.[META_FIELD_KEY.META_FIELD_SUB_TYPE] || null,
    meta_field_special_field_type: data?.[META_FIELD_KEY.META_FIELD_SPECIAL_FIELD_TYPE] || null,
    [META_FIELD_KEY.META_FIELD_LEVEL_OF_FIELD_TYPE]:
    data?.[META_FIELD_KEY.META_FIELD_LEVEL_OF_FIELD_TYPE] || null,
    [META_FIELD_KEY.META_FIELD_HIERARCHY_ID]:
    data?.[META_FIELD_KEY.META_FIELD_HIERARCHY_ID] || null,
  };
  return payload;
};

export const createMetaFieldValidation = (nameCheckArray) =>
  Joi.object({
    [META_FIELD_KEY.NAME]: Joi.string()
      .required()
      .custom((value, helpers) => {
        if (nameCheckArray.includes(value)) {
          return helpers.error('any.invalid');
        }
        return value;
      }, 'uniqueName'),
    [META_FIELD_KEY.META_FIELD_SCOPE]: Joi.string(),
    [META_FIELD_KEY.META_FIELD_SUB_TYPE]: Joi
      .valid(DROPDOWN_TYPES.STATIC, DROPDOWN_TYPES.SPECIAL_FIELD, DROPDOWN_TYPES.DEPENDENT)
      .allow(null),
    [META_FIELD_KEY.META_FIELD_SPECIAL_FIELD_TYPE]: Joi.alternatives().conditional('meta_field_sub_type', {
      is: DROPDOWN_TYPES.SPECIAL_FIELD,
      then: Joi.string().required(),
      otherwise: Joi.any(),
    }),
    [META_FIELD_KEY.META_FIELD_HIERARCHY_ID]: Joi.alternatives().conditional('meta_field_sub_type', {
      is: DROPDOWN_TYPES.DEPENDENT,
      then: Joi.string().required(),
      otherwise: Joi.any(),
    }),
    [META_FIELD_KEY.META_FIELD_LEVEL_OF_FIELD_TYPE]: Joi.alternatives().conditional('meta_field_sub_type', {
      is: DROPDOWN_TYPES.DEPENDENT,
      then: Joi.number().min(1).required(),
      otherwise: Joi.any(),
    }),
    [META_FIELD_KEY.META_FIELD_ID]: Joi.string(),
    [META_FIELD_KEY.TENANT_ID]: Joi.string().guid({ version: 'uuidv4' }),
    [META_FIELD_KEY.FIELD_DATA]: Joi.array()
      .items(
       // Joi.object({
        //  [META_FIELD_KEY.TEMPLATE_ID]: Joi.string().required().trim().min(1),
         //  [META_FIELD_KEY.FIELD_ID]: Joi.string().required().trim().min(1),
         // [META_FIELD_KEY.META_UUID_COLUMN]: Joi.string(),
       // })
      )
      .default([]),
    [META_FIELD_KEY.META_FIELD_TYPE]: Joi.string(),
  });
export const createOrUpdateMetaFields = async (value, defaultErrorMessage) => {
  try {
    const requestBody = {
      data: value,
    };

    const response = await axiosInstance.put(`/metaFields/update`, requestBody);
    return {
      response: response?.data,
      error: null,
    };
  } catch (error) {
    return {
      error: defaultErrorMessage,
      response: null,
    };
  }
};
export const saveMetaTableLayout = async (value, defaultErrorMessage) => {
  try {
    const requestBody = {
      data: value,
    };

    const response = await axiosInstance.put(`/metaTables/update`, requestBody);
    return {
      response: response?.data,
      error: null,
    };
  } catch (error) {
    return {
      error: defaultErrorMessage,
      response: null,
    };
  }
};

export const COLUMN_TYPE_OPTION = [
  {
    label: 'TEXT',
    value: 'TEXT',
  },
  {
    label: 'EMAIL',
    value: 'EMAIL',
  },
  {
    label: 'NUMBER',
    value: 'NUMBER',
  },
  {
    label: 'DATE',
    value: 'DATE',
  },
  {
    label: 'BOOLEAN',
    value: 'BOOLEAN',
  },
  {
    label: 'TAGS',
    value: 'TAGS',
  },
  {
    label: 'DROPDOWN',
    value: 'DROPDOWN',
  },
  // {
  //   label: 'LABEL',
  //   value: 'LABEL',
  // },
  {
    label: 'TEXT_AREA',
    value: 'TEXT_AREA',
  },
  {
    label: 'SLA',
    value: 'SLA',
  },
  {
    label: 'METRICS',
    value: 'METRICS',
  },
];
const isFieldMatching = (field, payload) =>
  payload.field_data.some((item) => item.field_id === field.field_id);
export const findMatchingObjectsForMetaField = (metaFieldList, payload) => {
  if (!Array.isArray(metaFieldList)) {
    console.error('metaFieldList is not an array:', metaFieldList);
    return [];
  }
  return metaFieldList.reduce((acc, obj) => {
    if (!Array.isArray(obj.field_data)) {
      console.warn('field_data is not an array or missing in object:', obj);
      return acc;
    }
    const filteredFieldData = obj.field_data.filter(
      (field) => !isFieldMatching(field, payload)
    );
    if (filteredFieldData.length !== obj.field_data.length) {
      acc.push({
        name: obj.name,
        meta_field_id: obj.meta_field_id,
        meta_field_type: obj.meta_field_type,
        meta_field_scope: obj.meta_field_scope,
        meta_field_sub_type: obj.meta_field_sub_type,
        meta_field_special_field_type: obj.meta_field_special_field_type,
        field_data: filteredFieldData,
      });
    }

    return acc;
  }, []);
};
export const removeDuplicateElement = (data, key = 'meta_field_id') => {
  const seenValues = new Set();
  return data.filter((item) => {
    if (seenValues.has(item[key])) {
      return false; // Duplicate found, filter out
    }
    seenValues.add(item[key]);
    return true; // Unique, keep it
  });
};
