/* eslint-disable import/prefer-default-export */
import Joi from 'joi';

import { FORM_MAP } from '../../../pages/DynamicRenderer/ChildTemplates/childTemplates.utils';

export const childTemplateValidations = Joi.array().items(
  Joi.object({
    [FORM_MAP.CHILD_TEMPLATE_ID]: Joi.number().required(),
    [FORM_MAP.PROPERTIES]: Joi.object({
      [FORM_MAP.UPDATE_FIELDS]: Joi.array().items(
        // Joi.string().guid({ version: 'uuidv4' })
        Joi.string()
      ),
      [FORM_MAP.EDGE_CONDITION]: Joi.string().allow(''),
    }),
    [FORM_MAP.WAS_APPROVED]: Joi.boolean(),
  })
);
