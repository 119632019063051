import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Tooltip } from '@chakra-ui/react';

const DynamicTextView = ({ style }) => {
  const location = useLocation();
  const { name } = queryString.parse(location.search);
  return (
    <Tooltip
      hasArrow
      label={name}
      placement="bottom"
      data-testid="DynamicTextViewTooltip"
    >
      <p style={style} className="ellipsis">
        {name}
      </p>
    </Tooltip>
  );
};

DynamicTextView.propTypes = {
  style: PropTypes.object.isRequired,
};

export default DynamicTextView;
