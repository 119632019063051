import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Tabs, TabList, Tab, TabPanels, TabPanel, Flex, Text, Box, useColorModeValue, HStack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { checkVisibility } from './utils';
// import { ACTION_LABELS } from '../../utils/enums/labels';

import styles from './tabView.module.scss';

const TabView = ({ size, children }) => {
  const { viewState } = useSelector((state) => state);
  // const isLight = useColorModeValue(ACTION_LABELS.LIGHT, ACTION_LABELS.DARK);
  const [selectedSetting, setSelectedSetting] = useState('Basic');

  const { id } = useParams();
  const tabs = useMemo(
    () =>
      children.filter((i) => {
        if (i?.props?.isBasicSettingFields === (selectedSetting === 'Basic')) {
          if (typeof i?.props?.show === 'function') {
            return i.props.show(viewState);
          }
          return true;
        }
        return false;
      }),
    [children, viewState, selectedSetting]
  );

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const tabListRef = useRef(null);

  useLayoutEffect(() => {
    setActiveTabIndex(0);
  }, [id]);

  const onChange = (selectedIndex) => {
    setActiveTabIndex(selectedIndex);
    tabListRef.current
      ?.querySelectorAll('button')[selectedIndex]
      ?.scrollIntoView({ behavior: 'auto', block: 'center' });
  };
  const isBotIntegrationView=()=>{
      return children.find(i=>i?.props?.isBotIntegration)?.props?.isBotIntegration
    }
  const isBotIntegrationTabs=()=>{
      return tabs.some(item=>{
        return (item?.props?.label==="Triggers" || item?.props?.label==="Bot Identification Fields")
      })
    }

  return (
    <Tabs
      size={size}
      key={id}
      index={activeTabIndex}
      onChange={onChange}
      data-testid="TabViewTabs"
    >
      <Flex
        className={`justify-between items-center ${useColorModeValue(styles.tabs_light, styles.tabs_dark)}`}
        whiteSpace="nowrap"
        borderRadius={isBotIntegrationTabs() ? "100px":"6px"}
        width={(isBotIntegrationView() && isBotIntegrationTabs())?"300px":"100%"}
        backgroundColor={isBotIntegrationTabs() && '#ECECEC'}
      >
      <TabList
        ref={tabListRef}
        py="6px"
        px="7px"
        border="none"
        className={`${styles.tab__list__container} ${styles.tab__container}`}
        data-testid="TabViewTabList"
      >
        {tabs.map((i) => (
          <Tab
            _selected={{ color: useColorModeValue('#374151', '#6B7280'), bg: useColorModeValue('#FFFFFF', 'rgb(32, 32, 34)') }}
            lineHeight="20px"
            fontSize="14px"
            color="#6B7280"
            fontWeight="500"
            key={i.props.label}
            px="12px"
            py="8px"
            borderRadius={isBotIntegrationTabs() ? "21px":"6px"}
            className={styles.tab}
          >
          {i.props.label}
          </Tab>
        ))}
      </TabList>

      {/* <button onClick={()=>{
      // setSelectedSetting(!selectedSetting)
    }}>{selectedSetting ? 'adv': 'Basic Settings'}</button> */}
    <HStack display={isBotIntegrationView() ? 'none' : 'flex'} className="" mx="6px" my="6px" spacing="6px" border="1px solid #D1D5DB" borderRadius="100px" p="5px">
       {['Basic', 'Advance']?.map((settingsTabType) => (
            <Box
              key={settingsTabType}
              role="tab"
              className={`${styles.tab} ${selectedSetting === settingsTabType ? useColorModeValue(styles.tab__selected__light, styles.tab__selected__dark) : ''}`}
              cursor="pointer"
              onClick={() => {
                setSelectedSetting(settingsTabType);
                setActiveTabIndex(0);
              }
              }
              data-testid="CustomTemplateModalTabsContainerBox"
            >
              <Text
                lineHeight="20px"
                fontSize="14px"
                color="#6B7280"
                fontWeight="500"
                className={`px-3 py-1 rounded-full ${styles.tab__text} ${
                  selectedSetting === settingsTabType
                    ? useColorModeValue(
                      styles.tab__selected__light,
                      styles.tab__selected__dark
                    )
                    : ''
                }`.trim()}
                whiteSpace="nowrap"
              >
                {selectedSetting === settingsTabType ? settingsTabType : `${settingsTabType} Settings`}
              </Text>
            </Box>
       ))}
    </HStack>

      </Flex>
      <TabPanels data-testid="TabViewTabPanels">
        {tabs.map((i, index) => (
          <TabPanel key={i.props.label} data-testid="TabViewTabPanel">
            {index === activeTabIndex && checkVisibility(i, viewState) ? i : ''}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

TabView.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.string.isRequired,
};

export default TabView;
