/* eslint-disable import/prefer-default-export */
const checkIsTransitionReferenced = (transition, template) => {
  if (transition === undefined || template === undefined) {
    return '';
  }

  const eventReferences = [];
  if (template?.events?.events) {
    template.events?.events?.forEach((event) => {
      if (event.transition_name === transition.id) {
        eventReferences.push(event.event_name);
      }
    });
  }

  if (eventReferences.length === 0) {
    return '';
  }

  return `Events - ${eventReferences.join(', ')}`;
};

export {
  checkIsTransitionReferenced,
};
