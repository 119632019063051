/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@chakra-ui/input';
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Text,
  Textarea,
  Radio,
  RadioGroup,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import createBaseSettings from './BaseSetting';
import styles from './createtemplate.module.scss';
import CustomSelectBox from '../../../components/SelectBox/Select';
import {
  FieldTypes,
  SettingsTabTypes,
  TemplateTypes,
} from '../../../utils/enums/types';
import { LABELS } from '../../../utils/enums/labels';
import { COLORS, DEFAULT, LIGHT, DARK } from '../../../utils/enums/colors';
import CustomInput from '../../../components/InputBox/Input';

const SETTINGS_TABS = [SettingsTabTypes.BASIC, SettingsTabTypes.ROLE];
const SETTINGS_TABS_LABEL_MAP = {
  [SettingsTabTypes.BASIC]: LABELS.TITLE.BASIC_SETTINGS,
  [SettingsTabTypes.ROLE]: LABELS.TITLE.ROLE_SETTINGS,
};

const FieldContent = ({
  type,
  fields,
  setFields,
  baseData,
  fieldData,
  isError,
}) => {
  const [activeSetting, setActiveSetting] = useState(SettingsTabTypes.BASIC);
  const { allTemplatesList } = useSelector((state) => state.allTemplates);
  const templateOptions = allTemplatesList.map((i) => ({
    label: i?.name,
    value: i,
  }));
  return (
    <>
      <Flex as="b" gap="18px" pb="30px" data-testid="FieldContentFlex">
        {SETTINGS_TABS?.map((settingsTabType) => (
          <Box
            key={settingsTabType}
            id={settingsTabType}
            px="5px"
            borderBottomColor={
              activeSetting === settingsTabType &&
              useColorModeValue(LIGHT, DARK)
            }
            color={
              activeSetting === settingsTabType
                ? useColorModeValue('black', DARK)
                : DEFAULT
            }
            borderBottomWidth={activeSetting === settingsTabType && '4px'}
            onClick={() => fields?.content && setActiveSetting(settingsTabType)}
            cursor="pointer"
            data-testid="FieldContentBox"
          >
            {SETTINGS_TABS_LABEL_MAP[settingsTabType]}
          </Box>
        ))}
      </Flex>

      {activeSetting === SettingsTabTypes.BASIC && (
        <>
          <Grid
            templateColumns="repeat(1, 1fr)"
            gap="30px"
            data-testid="FieldContentInfoBasicGrid"
          >
            {baseData?.row1?.map((fieldInfo) => (
              <GridItem
                key={fieldInfo.label}
                w="100%"
                data-testid="FieldContentBasicGridItem"
              >
                <Text mb="8px" as="samp">
                  {fieldInfo.label}
                </Text>
                {fieldInfo.render ? (
                  fieldInfo.render()
                ) : (
                  <CustomInput
                    onChange={(e) => fieldInfo.onChange(e.target.value)}
                    value={fieldInfo.value}
                    placeholder={fieldInfo.placeholder}
                    isError={isError[fieldInfo.key] || false}
                    helperText=" "
                    errorMessage={fieldInfo.errorString}
                    isDisabled={fieldInfo.isReadOnly}
                    id="FieldContentCIDivCInput"
                  />
                )}
              </GridItem>
            ))}
          </Grid>
          <Text pb="10px" pt="30px" data-testid="FieldContentBasicTypeText">
            {LABELS.TITLE.TEMPLATE_TYPE}
          </Text>
          <Grid
            templateColumns="repeat(4, 1fr)"
            gap={5}
            data-testid="FieldContentRow2Grid"
          >
            {baseData?.row2?.map((fieldInfo, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <GridItem
                key={`row2-field${index}`}
                data-testid="FieldContentRow2GridItem"
              >
                <Text mb="8px" as="samp" />
                {fieldInfo.render ? (
                  fieldInfo.render()
                ) : (
                  <RadioGroup
                    className={styles.radiobutton}
                    value={fields?.templateType || TemplateTypes.SINGLE}
                    onChange={(e) => {
                      setFields({ ...fields, templateType: e });
                    }}
                    data-testid="FieldContentRow2RadioGroup"
                  >
                    {' '}
                    <Radio value={fieldInfo.value} key={fieldInfo.value}>
                      {fieldInfo.label}
                    </Radio>
                  </RadioGroup>
                )}
              </GridItem>
            ))}
          </Grid>
          {fields?.templateType === TemplateTypes.NESTED && (
            <div className="pt-4" data-testid="FieldContentSetFieldsDiv">
              <CustomSelectBox
                defaultValue={fields?.selectedTemplates || []}
                isMulti
                name="templates"
                options={templateOptions}
                onChange={(e) => {
                  setFields({
                    ...fields,
                    selectedTemplates: e,
                  });
                }}
                id="FiedContentSelectBox"
              />
            </div>
          )}
          <Grid
            templateColumns="repeat(1, 1fr)"
            data-testid="FieldContentRow3Grid"
          >
            {baseData?.row3?.map((fieldInfo) => (
              <GridItem
                pt="30px"
                key={fieldInfo.label}
                w="100%"
                data-testid="FieldContentRow3GridItem"
              >
                <Text mb="8px" data-testid="FieldContentRow3Text">
                  {fieldInfo.label}
                </Text>
                {fieldInfo.render ? (
                  fieldInfo.render()
                ) : (
                  <Textarea
                    value={fieldInfo.value}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        description: e?.target?.value,
                      })
                    }
                    placeholder={fieldInfo.Placeholder}
                    size="sm"
                    data-testid="FieldContentRow3Textarea"
                  />
                )}
              </GridItem>
            ))}
          </Grid>

          {fields?.templateType === TemplateTypes.NESTED && (
            <Alert
              mt="10"
              variant="left-accent"
              status="warning"
              style={{ borderRadius: '6px' }}
              data-testid="FieldContentEditAlert"
            >
              <AlertIcon />
              <Box
                style={{ paddingLeft: '18px' }}
                data-testid="FieldContentEditAlertBox"
              >
                <AlertTitle
                  style={{ fontSize: '16px', color: COLORS.ALERT_COLOR }}
                  data-testid="FieldContentEditAlertTitle"
                >
                  {LABELS.TITLE.WARNING}
                </AlertTitle>
                <AlertDescription
                  style={{ fontSize: '16px', color: COLORS.ALERT_COLOR }}
                  data-testid="FieldContentEditAlertDescription"
                >
                  {LABELS.DESCRIPTIONS.CHANGE_NESTED_TEMPLATE_TO_SINGLE_WARNING}
                </AlertDescription>
              </Box>
            </Alert>
          )}
        </>
      )}

      {activeSetting === SettingsTabTypes.ROLE && (
        <Grid
          pb="30px"
          templateColumns="repeat(2, 1fr)"
          gap="30px"
          data-testid="FieldContentRoleGrid"
        >
          {fieldData?.row1?.map((fieldInfo) => (
            <GridItem
              key={fieldInfo.label}
              w="100%"
              data-testid="FieldContentRoleGridItem"
            >
              <Text mb="8px" as="samp" data-testid="FieldContentRoleText">
                {fieldInfo.label}
              </Text>
              {fieldInfo.render ? (
                fieldInfo.render()
              ) : (
                <Input
                  onChange={(e) => fieldInfo.onChange(e.target.value)}
                  value={fieldInfo.value}
                  placeholder={fieldInfo.placeholder}
                  data-testid="FieldContentRoleInput"
                />
              )}
            </GridItem>
          ))}
        </Grid>
      )}
    </>
  );
};

FieldContent.propTypes = {
  type: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  setFields: PropTypes.func.isRequired,
  baseData: PropTypes.array.isRequired,
  fieldData: PropTypes.array.isRequired,
  isError: PropTypes.object.isRequired,
};

export default FieldContent;
