/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Tooltip,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import classnames from 'classnames';
import styles from '../TicketTemplateBuilder/styles/templatebuilder.module.scss';
import Search from './Search';

const getIds = (data) => {
  // initialize an empty array to store the ids
  let ids = [];
  // loop through the data array
  data?.forEach((item) => {
    ids?.push(item?.id);
    // if the item has children, recursively call the function on the children array
    // and concatenate the result to the ids array
    if (item?.children && item?.children?.length > 0) {
      ids = ids?.concat(getIds(item?.children));
    }
  });
  // return the ids array
  return ids;
};

const AccordionView = ({
  accordionTitle,
  targetSearchList,
  children,
  entity,
  filterKey,
  listKey,
  searchContainerStyle,
  inputRightElementStyle,
}) => {
  const { viewState } = useSelector((state) => state);

  const sum = useMemo(
    () => getIds(viewState[listKey]?.data || []).length,
    [viewState[listKey]?.data]
  );

  return (
    <Accordion
      width="100%"
      allowMultiple={false}
      allowToggle
      defaultIndex={0}
      className={styles.accordionContainer}
      data-testid="AccordionViewAccordian"
    >
      <AccordionItem
        border="none"
        key="all-templates-accordion"
        py="2"
        data-testid="AccordionViewAccordianItem"
      >
        <AccordionButton data-testid="AccordionViewAccordianButton">
          <Box
            display="flex"
            textAlign="left"
            justifyContent="space-between"
            width="100%"
            flexWrap="wrap"
            alignItems="center"
            data-testid="AccordionViewToolTipContainerBox"
          >
            <Tooltip label={sum} data-testid="AccordionViewCounterTooltip">
              <Text
                as="span"
                flex="1"
                className={classnames(
                  useColorModeValue(
                    styles.ticketCounterField,
                    styles.ticketCounterFieldDark
                  ),
                  'ellipsis'
                )}
                overflow="unset"
                textAlign="center"
              >
                {sum || 0}
              </Text>
            </Tooltip>
            <Tooltip
              label={accordionTitle}
              data-testid="AccordionViewTitleView"
            >
              <Text
                as="span"
                flex="10"
                className={classnames(styles.accordionHeading, 'ellipsis')}
                color={useColorModeValue('', 'white')}
              >
                {accordionTitle}
              </Text>
            </Tooltip>

            <Box data-testid="AccordionViewAccordianIconBox">
              <AccordionIcon marginRight="2" />
            </Box>
          </Box>
        </AccordionButton>

        <Box
          mt="4"
          style={searchContainerStyle}
          data-testid="AccordionViewSearchBox"
        >
          <Search
            targetSearchList={targetSearchList}
            entity={entity}
            filterKey={filterKey}
            listKey={listKey}
            inputRightElementStyle={inputRightElementStyle}
          />
        </Box>

        <AccordionPanel
          pb={4}
          className={`${styles.allTemplateText}`}
          data-testid="AccordionViewAccordionPanel"
        >
          {children}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default AccordionView;
