import React from 'react';
import { Link } from 'react-router-dom';

const Login = () => (
  <div>
    <h1>This is the login page</h1>
    <Link to="dashboard"> Dash</Link>
  </div>
);

export default Login;
