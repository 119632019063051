import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { triggerPatchApi } from '../../pages/TicketTemplateBuilder/utils/templateAPIUtils';
import { updateSelectedWorkFlowWithKeyValue } from '../WorkFlowMainSlice/WorkFlowMainReducer';
export const associateWorkflow = createAsyncThunk(
  'viewState/associateWorkflow',
  async ({ id,
    data,
    key,
    defaultErrorMessage,
    successMessage,
    displayToastMessage = true,
    bypassEditCheck = false }, thunkAPI) => {
    try {
      const requestBody = {
        data: {
          [key]: data,
        },
      };
      const res = await triggerPatchApi({
        id,
        requestBody,
        defaultErrorMessage,
        successMessage,
        displayToastMessage,
        bypassEditCheck,
      });
      return res;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  testDummy:{}
};
const viewState = createSlice({
  name: 'viewState',
  initialState,
  reducers: {
    updateViewState: (state, action) => {
      const { stateKey, value } = action.payload;
      state[stateKey] = value;
      return state;
    },
    updateMultipleViewStates: (state, action) => {
      action.payload.forEach((act) => {
        const { stateKey, value } = act;
        state[stateKey] = value;
      });
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(associateWorkflow.fulfilled, (state, action) => {
      state.selectedTemplate = action.payload.response.data
    })
    .addCase(updateSelectedWorkFlowWithKeyValue.fulfilled, (state, action) => {
      if(action.payload.entityRoute =='template'){
        const { updatedState } = action.payload.updateObject
        state.selectedTemplate.workflow = updatedState
     }
    })
  }
});

export const { updateViewState, updateMultipleViewStates } = viewState.actions;

export default viewState.reducer;
