const height = '32px';

export const customStylesReactSelect = {
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    borderColor: '#e2e8f0',
    borderRadius: '6px',
    minHeight: height,
    minWidth: '200px',
    height,
    boxShadow: state.isFocused ? null : null,
  }),
  valueContainer: (provided) => ({
    ...provided,
    height,
    padding: '0 6px',
  }),

  input: (provided) => ({
    ...provided,
    margin: '0px',
    '::before': {
      position: 'relative',
    },
    '::after': {
      position: 'relative',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height,
  }),
};

export default customStylesReactSelect;
