import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { cloneDeep, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import EditFieldDefault from './EditFieldDefault';

import {
  FORM_MAP,
  OFFER_TYPES,
  columns,
  enrichDataForEdit,
} from './fieldDefaults.utils';
import {
  createFieldDefault,
  deleteFieldDefault,
  updateFieldDefault,
} from './fieldDefaults.service';
import {
  getDataForIndeterminateCheckbox,
  getIntent,
} from '../CreateEditIntentBuilder/intent.service';
import { getAudiences } from '../MasterAudience/audience.services';
import {
  createMap,
} from '../TenantFilters/CustomDynamicSelect/customDynamicSelect.helpers';
import {
  getAudience,
  getIntentGroups,
  keys,
  setData,
} from '../../redux/FieldDefaults';
import { deleteAttachment } from '../../components/AttachmentUpload/utils';
import axiosInstance from '../../utils/axios/axios';
import TableViewWithSearch from '../../components/Table/TableWithSearch';

const MasterFieldDefaults = () => {
  const dispatch = useDispatch();

  const [fieldDefaults, setFieldDefaults] = useState([]);
  const [isOfferListLoading, setIsOfferListLoading] = useState(false);
  const audiences = useSelector(getAudience);
  const intentGroups = useSelector(getIntentGroups);
  const { selectedTemplate } = useSelector((state) => state.viewState || {});

  const [loading, setLoading] = useState(false);
  const [fieldDefaultEditDrawer, setFieldDefaultEditDrawer] = useState(false);

  const selectedFieldDefault = useRef({});
  const isuseEffectTriggered = useRef(false);

  const loadAndSetFieldDefaults = () => {
    setIsOfferListLoading(true);
    axiosInstance
      .get(`fieldDefaults/list?$select=*&$filter=item_type eq '${selectedTemplate.id}' and offer_type ne 'email'&$orderby=id asc`)
      .then((res) => {
        const fieldDefaultRows = res?.data?.rows || [];
        const defaultOffers = fieldDefaultRows.filter(
          (item) => item.offer_type === OFFER_TYPES.TEMPLATE
        );
        const otherOffers = fieldDefaultRows.filter(
          (item) => item.offer_type !== OFFER_TYPES.TEMPLATE
        );

        setFieldDefaults([...defaultOffers, ...otherOffers]);
        setIsOfferListLoading(false);
        setIsOfferListLoading(false);
      })
      .catch(() => {
        setFieldDefaults([]);
        setIsOfferListLoading(false);
        setIsOfferListLoading(false);
      });
  };

  useEffect(() => {
    if (!selectedTemplate) {
      return;
    }

    loadAndSetFieldDefaults();
  }, [selectedTemplate]);

  const onEditClick = useCallback((data = {}) => {
    selectedFieldDefault.current = enrichDataForEdit(
      data, selectedTemplate.id, selectedTemplate.name
    );
    setFieldDefaultEditDrawer(true);
  }, [selectedTemplate]);

  const onCreateClick = useCallback(() => {
    onEditClick({
      [FORM_MAP.OFFER_TYPE]: OFFER_TYPES.OFFER,
    });
  }, [onEditClick]);

  const onDelete = useCallback((data) => {
    deleteFieldDefault(data?.id).then((res) => {
      if (res) {
        // dispatch(deleteData({ key: keys.FIELD_DEFAULTS, id: data?.id }));
        loadAndSetFieldDefaults();
        if (data?.[FORM_MAP.THUMBNAIL]) {
          deleteAttachment(data?.[FORM_MAP.THUMBNAIL]);
        }
      }
    });
  }, []);

  const intentOptions = useMemo(() => {
    if (!Array.isArray(intentGroups?.intent_groups)) {
      return [];
    }

    const ed = getDataForIndeterminateCheckbox(intentGroups?.intent_groups);
    return ed;
  }, [intentGroups]);

  const audienceOptions = useMemo(() => {
    if (!Array.isArray(audiences)) {
      return [];
    }

    const tempAudience = audiences.map((a) => ({
      label: a?.name,
      value: a?.id,
    }));

    return tempAudience;
  }, [audiences]);

  const audienceMap = useMemo(
    () =>
      createMap({
        options: audienceOptions,
      }),
    [audienceOptions]
  );

  const colDefs = useMemo(() => {
    const cols = columns({
      onEditClick,
      onDelete,
      audienceMap,
    });

    return cols;
  }, [onEditClick, onDelete, audienceMap]);

  const onCloseEditMode = useCallback(() => {
    setFieldDefaultEditDrawer(false);
  }, []);

  const onSave = useCallback(
    (data) => {
      const temp = cloneDeep(data);
      const id = temp?.id;
      delete temp.id;

      setLoading(true);
      const promise = id
        ? updateFieldDefault(temp, id)
        : createFieldDefault(temp);
      promise
        .then((res) => {
          if (res) {
            loadAndSetFieldDefaults();
            onCloseEditMode();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [onCloseEditMode]
  );

  useEffect(() => {
    if (isuseEffectTriggered.current) {
      return;
    }

    isuseEffectTriggered.current = true;

    if (isEmpty(intentGroups)) {
      getIntent().then((res) => {
        if (res) {
          dispatch(setData({ key: keys.INTENT_GROUPS, data: res }));
        }
      });
    }

    if (isEmpty(audiences)) {
      getAudiences().then((res) => {
        dispatch(setData({ key: keys.AUDIENCE, data: res }));
      });
    }
  }, [intentGroups, audiences]);

  return (
    <div className="pt-5">
      {fieldDefaultEditDrawer ? (
        <EditFieldDefault
          currentFieldDefault={selectedFieldDefault.current}
          onCancel={onCloseEditMode}
          OfferList={fieldDefaults.filter(({ id }) => id !== selectedFieldDefault.current?.id)}
          onSave={onSave}
          isLoading={loading}
          audienceMap={audienceMap}
          audience={audienceOptions}
          selectedTemplate={selectedTemplate}
          intentOptions={intentOptions}
        />
      ) : (
        <TableViewWithSearch
          placeholder="Search for offer"
          buttonText="Add New Offer"
          buttonVariant="solid"
          isTableLoading={isOfferListLoading}
          onCreateNew={onCreateClick}
          columns={colDefs}
          tableData={fieldDefaults || []}
          title="Offers"
        />
      )}
    </div>
  );
};

export default MasterFieldDefaults;
