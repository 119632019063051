import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers as createMultiple } from '@chakra-ui/react';
import { DARK, DEFAULT, LIGHT } from '../../utils/enums/colors';

const { definePartsStyle, defineMultiStyleConfig } = createMultiple(
  tabsAnatomy.keys
);
const baseStyle = definePartsStyle({
  // define the part you're going to style
  tab: {
    fontWeight: 'semibold', // change the font weight
    color: DEFAULT,
    borderBottom: 'none',
    _selected: {
      color: LIGHT,
      borderBottomWidth: '4px',
      _dark: {
        color: DARK,
      },
    },
  },
  tablist: {
    border: 'none',
  },
  tabpanel: {
    padding: '0',
  },
});

// export the component theme
const tabsTheme = defineMultiStyleConfig({ baseStyle });
export default tabsTheme;
