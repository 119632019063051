import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import CustomToast from '../../components/Notification/Notification';
import axiosInstance from '../../utils/axios/axios';

const { addToast } = CustomToast();

const showToastMessage = ({ title, description, status = 'success' }) => {
  addToast({
    title,
    message: description,
    type: status,
  });
};

const initialState = {
  quickFiltersList: [],
  defaultFilterId: '',
  selectedFilterId: '',
  appliedFilterData: {},
  appliedFilters: {},
};

const quickFilterSlice = createSlice({
  name: 'quickFilter',
  initialState,
  reducers: {
    setDefaultFilterId: (state, action) => {
      state.defaultFilterId = action.payload;
    },
    setSelectedFilterId: (state, action) => {
      state.selectedFilterId = action.payload;
    },
    applyFilter: (state, action) => {
      state.appliedFilterData = state.quickFiltersList.find(
        (filter) => filter.id === action.payload
      );
    },
    storeFetchedQuickFilters: (state, action) => {
      state.quickFiltersList = action.payload;
    },
    saveAppliedFilters: (state, action) => {
      state.appliedFilters = action.payload;
    },
  },
});

export const fetchQuickFilters = createAsyncThunk(
  'quickFilters/fetch',
  async (_, { dispatch }) => {
    try {
      const params = {
        $top: 1,
        $skip: 0,
        $select: 'filters',
      };

      const response = await axiosInstance.get('userFilters/list', {
        params,
      });

      dispatch(
        quickFilterSlice.actions.storeFetchedQuickFilters(
          response.data.rows[0].filters
        )
      );

      const defaultFilterId = response.data.rows[0].filters.find(
        (filter) => filter.isDefault
      );
      if (defaultFilterId) {
        dispatch(
          quickFilterSlice.actions.setDefaultFilterId(defaultFilterId.id)
        );
        dispatch(
          quickFilterSlice.actions.setSelectedFilterId(defaultFilterId.id)
        );
      }

      return response.data.rows;
    } catch (error) {
      throw new Error('Failed to fetch user filters.');
    }
  }
);

const updateQuickFilters = async (filtersList, defaultErrorMessage) => {
  try {
    const requestBody = {
      data: {
        filters: filtersList,
      },
    };

    const res = await axiosInstance.post('userFilters/upsert', requestBody);

    if (res.status < 400) {
      return {
        response: res.body,
        error: null,
      };
    }

    throw new Error(defaultErrorMessage);
  } catch (err) {
    return {
      response: null,
      error: err?.response?.data?.message || defaultErrorMessage,
    };
  }
};

export const addNewQuickFilter = createAsyncThunk(
  'quickFilters/add',
  async (newFilter, { dispatch, getState }) => {
    const oldFilters = [...getState().quickFilter.quickFiltersList];
    let filtersList;
    const { isEdited } = { ...newFilter };
    if (isEdited) {
      delete newFilter.isEdited;
      const index = oldFilters.findIndex((f) => f.id === newFilter.id);
      oldFilters.splice(index, 1, newFilter);
      filtersList = oldFilters;
    } else {
      delete newFilter.isEdited;
      filtersList = [...oldFilters, newFilter];
    }

    const res = await updateQuickFilters(
      filtersList,
      isEdited
        ? 'Failed to update the quick filter'
        : 'Failed to create a new quick filter'
    );
    if (res.error) {
      showToastMessage({
        title: 'Something went wrong',
        description: res.error,
        status: 'error',
      });
    } else {
      dispatch(quickFilterSlice.actions.storeFetchedQuickFilters(filtersList));
      showToastMessage({
        title: `Quick Filter ${isEdited ? 'Updated' : 'Created'}`,
        description: isEdited
          ? 'Successfully updated the quick filter'
          : 'Successfully created a new quick filter',
      });
    }
  }
);

export const deleteQuickFilter = createAsyncThunk(
  'quickFilters/delete',
  async (filterId, { dispatch, getState }) => {
    const { quickFiltersList, defaultFilterId, selectedFilterId } =
      getState().quickFilter;

    const newFilters = quickFiltersList.filter(
      (filter) => filter.id !== filterId
    );

    const res = await updateQuickFilters(
      newFilters,
      'Failed to delete the quick filter'
    );
    if (res.error) {
      showToastMessage({
        title: 'Something went wrong',
        description: res.error,
        status: 'error',
      });
    } else {
      dispatch(quickFilterSlice.actions.storeFetchedQuickFilters(newFilters));
      if (defaultFilterId === filterId) {
        dispatch(quickFilterSlice.actions.setDefaultFilterId(''));
      }
      if (selectedFilterId === filterId) {
        dispatch(quickFilterSlice.actions.setSelectedFilterId(''));
      }
      showToastMessage({
        title: 'Success',
        description: 'Successfully deleted the quick filter',
      });
    }
  }
);

export const setDefaultFilter = createAsyncThunk(
  'quickFilters/setDefault',
  async (filterId, { dispatch, getState }) => {
    const { quickFiltersList } = getState().quickFilter;

    const newFilters = quickFiltersList.map((filter) => ({
      ...filter,
      isDefault: filter.id === filterId,
    }));

    const res = await updateQuickFilters(
      newFilters,
      'Failed to mark the quick filter as default'
    );
    if (res.error) {
      showToastMessage({
        title: 'Something went wrong',
        description: res.error,
        status: 'error',
      });
    } else {
      dispatch(quickFilterSlice.actions.storeFetchedQuickFilters(newFilters));
      dispatch(quickFilterSlice.actions.setDefaultFilterId(filterId));
      showToastMessage({
        title: 'Success',
        description: 'Successfully marked the quick filter as default',
      });
    }
  }
);

export const removeDefaultFilter = createAsyncThunk(
  'quickFilters/removeDefault',
  async (_, { dispatch, getState }) => {
    const { quickFiltersList } = getState().quickFilter;

    const newFilters = quickFiltersList.map((filter) => ({
      ...filter,
      isDefault: false,
    }));

    const res = await updateQuickFilters(
      newFilters,
      'Failed to remove the default'
    );
    if (res.error) {
      showToastMessage({
        title: 'Something went wrong',
        description: res.error,
        status: 'error',
      });
    } else {
      dispatch(quickFilterSlice.actions.storeFetchedQuickFilters(newFilters));
      dispatch(quickFilterSlice.actions.setDefaultFilterId(''));
      showToastMessage({
        title: 'Success',
        description: 'Successfully removed the default',
      });
    }
  }
);

export const {
  setDefaultFilterId,
  setSelectedFilterId,
  applyFilter,
  saveAppliedFilters,
} = quickFilterSlice.actions;

export default quickFilterSlice.reducer;
