/* eslint-disable import/no-cycle */
/* eslint-disable no-restricted-syntax */
import { Box, Flex, GridItem, Text, useColorModeValue } from '@chakra-ui/react';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AddIcon, ArrowDownIcon, CloseIcon } from '@chakra-ui/icons';
import {
  MarkerType,
  applyEdgeChanges,
  applyNodeChanges,
  useEdgesState,
  useNodesState,
  useStoreApi,
} from 'reactflow';
import { useSelector, useDispatch } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  COLORS,
  LIGHT,
  TEXT_LIGHT,
  TO_DO_STATUS,
} from '../../../utils/enums/colors';
import CustomButton from '../../../components/Button/SimpleButton';
import {
  ACTION_LABELS,
  ADD_TRANSITION,
  LABELS,
  WORK_FLOW_ACTION_LABEL,
} from '../../../utils/enums/labels';
import StatusAction from './StatusAction';
import { handlesToSourceCheck, handlesToTargetCheck } from '../DummyData';
import CustomFlow from '../CustomFlow';
import CustomNode from '../CustomNode';
import CustomStartNode from '../CustomStartNode/CustomStartNode';
import CustomEdge from '../CustomEdge/CustomEdge';
import CustomStraightEdge from '../CustomEdge/CustomStraightEdge';
import CustomToast from '../../../components/Notification/Notification';
import InProgressNode from '../NodeTypes/InProgressNode';
import DoneNode from '../NodeTypes/DoneNode';
// import CustomModal from '../../../components/Modal/Modal';
import AddStatus from '../AddStatus';
import {
  addedPreFixText,
  findColorPalette,
  formatDate,
  getMaxXPosition,
} from '../helper';
import WorkFlowSideBarContent from './WorkFlowSideBarContent';
import AddTransition from '../AddTransition/index';
import CustomModalFooter from './CustomModalFooter';
import FixingNode from '../NodeTypes/FixingNode';
import CautionNode from '../NodeTypes/CautionNode';
import AddNewRoleModal from '../AddNewRoleModal';
import RestricMoveRoleModal from '../RestrictMoveRole';
import RestrictSpecificStatusModal from '../RestrictSpecificStatusModal';
import RestrictValidateSpecialPermissionModal from '../RestrictValidateSpecialPermissionModal';
import RestrictSpecificValueModal from '../RestrictSpecificValueModal';
import RoleWhite from '../../../assets/icons/RuleWhite.svg';

import {
  addNewStateWorkFlowWithKeyValue,
  autoSavePostionWhenDrag,
  changeSelectedRuleWithKey,
  // getWorkFlowById,
  getWorkFlowStateByTemeplateId,
  updateDropDownState,
  // updateNodePostionWhenDrag,
  // updateSelectedWorkFlow,
  updateSelectedWorkFlowStatus,
  updateStateOfWorkFlowWithKey,
  updateWorkflowOnUndoRedo,
} from '../../../redux/WorkFlowMainSlice/WorkFlowMainReducer';
import { loadState } from '../../TicketTemplateBuilder/utils/helper';
import { checkForV2 } from '../../../utils/helper';
import { LifecycleStatusTypes } from '../../../utils/enums/lifecycleStatus';
import Carousel from '../../../components/Carousel';
import styles from '../styles/workflowSlider.module.scss';
// import { canViewTemplate } from '../../TicketTemplateBuilder/utils/templateAPIUtils';
import WorkflowNameChange from '../WorkflowNameChange';
import AssociateAnotherFlowBtn from '../../TicketTemplateBuilder/WorkflowConfigModal/SelectAssigneAnotherWorkflow';
import { CustomDrawer } from '../../TicketTemplateBuilder/ConfigureTemplateView/AddEditTemplateLayoutModal';
import UndoIcon from '../../../assets/icons/Undo.svg';
import RedoIcon from '../../../assets/icons/Redo.svg';
import UndoWhite from '../../../assets/icons/UndoWhite.svg';
import RedoWhite from '../../../assets/icons/RedoWhite.svg';
import { lastTenSelectedFlowSessionKey } from './helper';
import RestrictLinkedTicketSpecificStatusModal from '../RestrictLinkedTicketSpecificStatusModal';
import WorkflowStatusCarousel from '../WorkflowStatusCarousel';

const nodeTypes = {
  toDoNode: CustomNode,
  selectorCreateNode: CustomStartNode,
  toProgressNode: InProgressNode,
  toDoneNode: DoneNode,
  toFixingNode: FixingNode,
  toCautionNode: CautionNode,
};
const edgeTypes = {
  customEdges: CustomEdge,
  straightEdges: CustomStraightEdge,
};

const CreateEditFlow = (props) => {
  const { openFullScreenWorkflow, setOpenFullScreenWorkFlow } = props
  const { selectedTemplate } = useSelector((state) => state.viewState || {});
  const dispatch = useDispatch();
  const { addToast } = CustomToast();
  const {
    selectedWorkFlow,
    dropDownState,
    toTransitionState,
    fromTransitionState,
    connectionObjectState,
    inputValueForCreateDropDown,
    selectedWorkFlowStatusCategory,
    isAutoMoveAllowed,
    entityRoute,
    toolTipInfo,
    templateLifeCycle,
    templateProperties,
  } = useSelector((state) => state.workFlowMain);
  // const { ruleModalId } = useSelector((state) => state.workFlowRules);
  const [isRestricMoveRoleModal, setIsRestricMoveRoleModal] = useState(false);
  const [isRestrictSpecificStatusModal, setIsRestrictSpecificStatusModal] =
    useState(false);
  const [isRestrictSpecificValue, setIsRestrictSpecificValue] = useState(false);
  const [isRestricSpecialRulePermission, setIsRestricSpecialRulePermission] =
    useState(false);
  const [
    isRestrictLinkedTicketSpecificStatusValue,
    setIsRestrictLinkedTicketSpecificStatusValue,
  ] = useState(false);
  const [isAddRole, setIsAddRole] = useState(false);
  const [roleModalOpen, setRoleModalOpen] = useState('');
  const [nodes, setNodes] = useNodesState([]);
  const [innerEdges, setInnerEdges] = useEdgesState([]);
  const [isInProgressStatesModal, setIsInProgressStatesModal] = useState(false);
  const [isTransitinModal, setIsTransitinModal] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [isTransitionWithOutDragAndDrop, setIsTransitionWithOutDragAndDrop] =
    useState(false);

  const [manageCategory, setManageCategory] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isUpdateWorkFlow, setIsUpdateWorkFlow] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [errorCommonFlow, setErrorCommonFlow] = useState('');
  const [isEditWorkflowName, setIsEditWorkflowName] = useState(false);
  const flowStore = useStoreApi();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const id = searchParams.get('id');
  const sourceRoute = location.pathname.includes('template')
    ? 'template'
    : searchParams.get('sourceRoute');
  const ticketTypeName = searchParams.get('ticketTypeName');
  // eslint-disable-next-line no-unused-vars
  const [isAutoSave, setIsAutoSave] = useState(false);
  const lastTenFlowState = JSON.parse(
    sessionStorage.getItem(lastTenSelectedFlowSessionKey)
  );

  const clearUndoRedoState = () => {
    sessionStorage.removeItem(lastTenSelectedFlowSessionKey);
    sessionStorage.setItem(
      lastTenSelectedFlowSessionKey,
      JSON.stringify({ ...lastTenFlowState, undoIndex: 0 })
    );
  };

  const handleUndoReDo = (isUnDo) => {
    const { lastTenWorkflowState, undoIndex } = lastTenFlowState;
    let currentPosition;
    if (isUnDo) {
      sessionStorage.setItem(
        lastTenSelectedFlowSessionKey,
        JSON.stringify({ ...lastTenFlowState, undoIndex: undoIndex - 1 })
      );
      currentPosition = lastTenWorkflowState[undoIndex - 1];
    } else {
      sessionStorage.setItem(
        lastTenSelectedFlowSessionKey,
        JSON.stringify({ ...lastTenFlowState, undoIndex: undoIndex + 1 })
      );
      currentPosition = lastTenWorkflowState[undoIndex + 1];
    }
    dispatch(updateWorkflowOnUndoRedo(currentPosition));
  };

  const titleRef = useRef(null);

  let tempComponentState = useMemo(() => ({}), []);
  const onNodesChange = useCallback((changes) => {
    setNodes((nds) => applyNodeChanges(changes, nds));
    if (changes.some((item) => item.type === 'position')) {
      setIsUpdateWorkFlow(true);
      setIsAutoSave(true);
    }
  }, []);
  const onEdgesChange = useCallback(
    (changes) => setInnerEdges((eds) => applyEdgeChanges(changes, eds)),
    [setInnerEdges, innerEdges]
  );
  const onConnect = useCallback((connection) => {
    // setIsTransitinModal(true);
    // setConnectionObj(connection);
    dispatch(
      updateStateOfWorkFlowWithKey({
        keyValue: 'connectionObjectState',
        value: connection,
      })
    );
    /* inside onConnect() nodes state is always empty
    that why we use flow store for get node details, */
    const { nodeInternals } = flowStore.getState();
    const toOption = {
      label: nodeInternals.get(connection.source).data?.label,
      value: nodeInternals.get(connection.source)?.id,
    };
    const fromOption = {
      label: nodeInternals.get(connection.target).data?.label,
      value: nodeInternals.get(connection.target)?.id,
    };

    const toTransitions = [...toTransitionState, toOption];

    dispatch(
      updateStateOfWorkFlowWithKey({
        keyValue: 'toTransitionState',
        value: toTransitions,
      })
    );
    dispatch(
      updateStateOfWorkFlowWithKey({
        keyValue: 'fromTransitionState',
        value: fromOption,
      })
    );

    tempComponentState.toTransitionState = toTransitions;
    tempComponentState.fromTransitionState = fromOption;
    tempComponentState.connectionObjectState = connection;

    setIsTransitinModal(true);
  }, []);

  useEffect(() => {
    if (selectedWorkFlow?.workflow_status) {
      setNodes(selectedWorkFlow?.workflow_status);
    }
    if (selectedWorkFlow?.workflow_transitions) {
      setInnerEdges(selectedWorkFlow.workflow_transitions);
    }
  }, [
    // selectedWorkFlow?.workflow_status,
    // selectedWorkFlow?.workflow_transitions,
    // selectedWorkFlow.workflow_category,
    selectedWorkFlow,
  ]);
  const checkPresentHandler = (
    handlesToCheck,
    data,
    nodeName,
    type,
    handlerType
  ) => {
    const handlePresent = handlesToCheck.map((handle) => {
      let isPresent = false;
      for (const obj of data) {
        if (obj[type] === nodeName && obj[handlerType] === handle) {
          isPresent = true;
          break;
        }
      }
      return isPresent;
    });
    return handlePresent;
  };
  const findFalseSource = (boolArray, sourceArray) => {
    for (let i = 0; i < boolArray.length; i += 1) {
      if (boolArray[i] === false) {
        return sourceArray[i];
      }
    }
    const randomIndex = Math.floor(Math.random() * sourceArray.length);
    // If no false value found in boolArray, return a default value
    return sourceArray[randomIndex];
  };
  const resetTransitionRequiredState = () => {
    dispatch(
      updateStateOfWorkFlowWithKey({
        keyValue: 'inputValueForCreateDropDown',
        value: '',
      })
    );
    dispatch(
      updateStateOfWorkFlowWithKey({
        keyValue: 'toTransitionState',
        value: [],
      })
    );
    dispatch(
      updateStateOfWorkFlowWithKey({
        keyValue: 'fromTransitionState',
        value: {},
      })
    );
    dispatch(
      updateStateOfWorkFlowWithKey({
        keyValue: 'connectionObjectState',
        value: {},
      })
    );
    dispatch(
      updateStateOfWorkFlowWithKey({
        keyValue: 'selectedWorkFlowTransition',
        value: {},
      })
    );
  };
  const getOptionLabel = (option) => option.label || option.value;
  const handleAddTransitionWithOutDragAndDrop = () => {
    const { edges } = flowStore.getState();
    const listOfSource = toTransitionState.map((item) =>
      checkPresentHandler(
        handlesToSourceCheck,
        edges,
        item.value,
        WORK_FLOW_ACTION_LABEL.SOURCE,
        WORK_FLOW_ACTION_LABEL.SOURCE_HANDLE
      )
    );
    const targetPresent = checkPresentHandler(
      handlesToTargetCheck,
      edges,
      fromTransitionState.value,
      WORK_FLOW_ACTION_LABEL.TARGET,
      WORK_FLOW_ACTION_LABEL.TARGET_HANDLE
    );
    const innerSourceList = listOfSource.map((item) =>
      findFalseSource(item, handlesToSourceCheck)
    );
    const innerTargetHandle = findFalseSource(
      targetPresent,
      handlesToTargetCheck
    );
    const connectionList = toTransitionState.map((item, index) => ({
      source: item.value,
      sourceHandle: innerSourceList[index],
      target: fromTransitionState.value,
      targetHandle: innerTargetHandle,
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
      type: WORK_FLOW_ACTION_LABEL.CUSTOM_EDGES,
      data: { label: inputValueForCreateDropDown },
      isAutoMoveAllowed,
      id: uuidv4(),
    }));
    dispatch(
      addNewStateWorkFlowWithKeyValue({
        keyValue: 'workflow_transitions',
        value: connectionList,
        editTranstion: false,
        defaultRole: entityRoute !== 'workflow-list' && !checkForV2(),
        dispatch,
      })
    );
    resetTransitionRequiredState();
    setIsTransitionWithOutDragAndDrop(false);
    setIsTransitinModal(false);
  };
  const handleAddTransition = () => {
    const toTransitions =
      tempComponentState.toTransitionState || toTransitionState;
    const fromTransitions =
      tempComponentState.fromTransitionState || fromTransitionState;
    const connections =
      tempComponentState.connectionObjectState || connectionObjectState;

    const connectionList = toTransitions.map((item) => ({
      source: item.value,
      sourceHandle: connections.sourceHandle,
      target: fromTransitions.value,
      targetHandle: connections.targetHandle,
      markerEnd: {
        type: MarkerType.ArrowClosed,
      },
      type: WORK_FLOW_ACTION_LABEL.CUSTOM_EDGES,
      data: { label: inputValueForCreateDropDown },
      isAutoMoveAllowed,
      id: uuidv4(),
    }));
    dispatch(
      addNewStateWorkFlowWithKeyValue({
        keyValue: 'workflow_transitions',
        value: connectionList,
        editTranstion: false,
        defaultRole: entityRoute !== 'workflow-list' && !checkForV2(),
        dispatch,
      })
    );
    resetTransitionRequiredState();
    setIsTransitinModal(false);
    tempComponentState = {};
  };
  const handleInProgressModalOpen = () => {
    dispatch(updateDropDownState(''));
    setIsInProgressStatesModal(!isInProgressStatesModal);
  };
  const handleInProgressStateAdd = () => {
    const maxX = getMaxXPosition(nodes);
    const newNode = {
      id: uuidv4(),
      type: selectedWorkFlowStatusCategory?.color?.value,
      position: {
        x: maxX + 170,
        y: 200,
      },

      data: {
        label: dropDownState,
        name: selectedWorkFlowStatusCategory?.name,
        categoryId: selectedWorkFlowStatusCategory.id,
      },
    };
    dispatch(
      addNewStateWorkFlowWithKeyValue({
        keyValue: 'workflow_status',
        value: newNode,
        editTranstion: false,
        defaultRole: false,
        dispatch,
      })
    );
    dispatch(updateDropDownState(''));
    setIsInProgressStatesModal(false);
  };
  const handleCheckNameIsExit = () => {
    const index = nodes.findIndex(
      (element) =>
        element.data.label.replace(/ /g, '').toLowerCase() ===
        dropDownState.replace(/ /g, '').toLowerCase()
    );
    if (index === -1) {
      handleInProgressStateAdd();
    } else {
      setErrorName(true);
    }
  };
  const handleTransition = () => {
    resetTransitionRequiredState();
    setIsTransitinModal(!isTransitinModal);
    setIsTransitionWithOutDragAndDrop(false);

    // on close and cancle resetting error state
    setErrorCommonFlow('');
    setErrorName(false);
  };
  useEffect(() => {
    const resizeListener = () => {
      // forcing menu to close so that to provide space to content in mobile view
      setOpenMenu(window.innerWidth < 660 ? false : openMenu);
    };
    window.addEventListener('resize', resizeListener);
    resizeListener();
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const handleRuleModalOpen = (fromId) => {
    if (fromId === 'moveIssue') {
      setIsAddRole(false);
      dispatch(
        changeSelectedRuleWithKey({
          keyValue: 'ruleType',
          value: 'moveIssue',
        })
      );
      setIsRestricMoveRoleModal(true);
    }
    if (fromId === 'specificStatus') {
      setIsAddRole(false);
      setIsRestrictSpecificStatusModal(true);
    }
    if (fromId === 'specialPermission') {
      setIsAddRole(false);
      setIsRestricSpecialRulePermission(true);
    }
    if (fromId === 'specificValue') {
      setIsAddRole(false);
      dispatch(
        changeSelectedRuleWithKey({
          keyValue: 'ruleType',
          value: 'specificValue',
        })
      );
      setIsRestrictSpecificValue(true);
    }
    if (fromId === 'restrictLinkedTicket') {
      setIsAddRole(false);
      dispatch(
        changeSelectedRuleWithKey({
          keyValue: 'restrictLinkedTicket',
          value: 'specificValue',
        })
      );
      setIsRestrictLinkedTicketSpecificStatusValue(true);
      // RestrictLinkedTicketSpecificStatusModal
    }
  };

  useEffect(() => {
    dispatch(
      getWorkFlowStateByTemeplateId({
        entityRoute: sourceRoute,
        // eslint-disable-next-line react/destructuring-assignment, react/prop-types
        id: id ? parseInt(id, 10) : parseInt(props.id, 10),
        createdBy: selectedTemplate?.created_by,
      })
    );
  }, [id]);
  const handleReplaceRoute = () => {
    if (entityRoute !== 'workflow-list') {
      const url = loadState('url');
      navigate(url, { replace: true });
    } else {
      navigate('/workflow-list', { replace: true });
    }
  };
  const showApiToastMessage = useCallback(
    (toastTitle, toastDescription, toastInfo) => {
      addToast({
        title: toastTitle,
        message: toastDescription,
        type: toastInfo,
        duration: 5000,
        isClosable: true,
      });
      dispatch(
        updateStateOfWorkFlowWithKey({
          keyValue: 'toolTipInfo',
          value: {},
        })
      );
    },
    [toolTipInfo.title, toolTipInfo.title, toolTipInfo.description]
  );

  const handleTransitionNameCheck = () => {
    const { edges } = flowStore.getState();
    const index = edges.findIndex((element) => {
      const transitionStateAlreadyExit = toTransitionState.find(
        ({ value }) => value === element.source
      );
      const isFlowAlreadyExist =
        transitionStateAlreadyExit &&
        element.target === fromTransitionState.value;
      const isNameAlreadyExist =
        element.data.label.replace(/ /g, '').toLowerCase() ===
        inputValueForCreateDropDown.replace(/ /g, '').toLowerCase();

      // set Error if flow is duplicate
      if (isFlowAlreadyExist) {
        setErrorCommonFlow(
          `${transitionStateAlreadyExit.label} to ${fromTransitionState.label} transition flow is already in use on the canvas.`
        );
      }

      // set Error if name is duplicate
      if (isNameAlreadyExist) {
        setErrorName(true);
      }

      return isNameAlreadyExist || isFlowAlreadyExist;
    });

    // checks for flow and name of transitions  duplication
    if (index === -1) {
      if (isTransitionWithOutDragAndDrop) {
        handleAddTransitionWithOutDragAndDrop();
        setOpenMenu(false);
      } else {
        handleAddTransition();
      }
    }
  };

  // useEffect(() => {
  //   const debouncedAutoSave = debounce((nodeList) => {
  //     dispatch(autoSavePostionWhenDrag(nodeList));
  //     setIsAutoSave(false);
  //   }, 500);
  //   if (isAutoSave) {
  //     debouncedAutoSave(nodes);
  //   } else {
  //     debouncedAutoSave.cancel();
  //   }
  //   return () => {
  //     debouncedAutoSave.cancel();
  //   };
  // }, [isAutoSave, nodes]);

  const onNodeDragStop = () => {
    if (!nodes.find((node) => node.dragging)) return;
    dispatch(autoSavePostionWhenDrag({nodeList: nodes,dispatch}));
  };

  // open transition modal in edit status
  const openTransitionModalInEditStatus = () => {
    setIsTransitinModal(true);
    setIsTransitionWithOutDragAndDrop(true);
  };
  const onFitView =()=>{
    if(sourceRoute==='template'){
      setOpenFullScreenWorkFlow(true)
    }
  }
  // const getAvailableWidthForCarousel = () => {
  //   const titleContainerWidth =
  //     titleRef.current?.getBoundingClientRect()?.width || 200;

  //   const buttonContainerWidth = 380;
  //   const gap = '4rem';
  //   return `calc(100% - ${
  //     Math.ceil(titleContainerWidth) + buttonContainerWidth
  //   }px - ${gap})`;
  // };

  useLayoutEffect(() => {
    if (sourceRoute === 'template') {
      if (templateProperties) {
        // const hasViewAccess = canViewTemplate({ data: templateProperties });
        // // if (!hasViewAccess) {
        // //   navigate('/workflow-list', { replace: true });
        // // }
      }
    }
  }, [sourceRoute, templateProperties]);

  return (
    <Box
      data-testid="CreateEditFlowBox"
      height={
        sourceRoute === 'template'
          ? `calc(100vh - ${!openFullScreenWorkflow ? '16rem':'5rem'})`
          : 'calc(100vh - 9rem)'
      }
      overflow="hidden"
    >
      <Flex
        flex="1"
        p={`0 10px 15px ${sourceRoute === 'template' ? '0px' : '30px'}`}
        // gap="1rem"
        role="group"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        borderBottom="1px solid #E2E8F0"
        position="sticky"
        top="10px"
        zIndex="1"
        backgroundColor={useColorModeValue(
          COLORS.WHITE,
          COLORS.WORKFLOW_DARK_MODE_BG
        )}
        data-testid="CreateEditFlowFlex"
      >
        <Flex
          ref={titleRef}
          role="group"
          direction="column"
          flex="0 0 auto"
          data-testid="CreateEditFlowNameFlex"
        >
          {/** text will come through props for now it is hard coded */}
          <Text
            color={useColorModeValue(COLORS.TEMPLATE_TITLE, '')}
            fontSize="16px"
            fontWeight="600"
            data-testid="CreateEditFlowNameText"
            cursor={!ticketTypeName && 'pointer'}
          >
            <span
              onClick={() => {
                if (!ticketTypeName) {
                  setIsEditWorkflowName(true);
                }
              }}
            >
              {ticketTypeName
                ? `Workflow for ${ticketTypeName}`
                : selectedWorkFlow?.name}
            </span>
          </Text>
          <span style={{ fontSize: '12px' }}>
            {formatDate(selectedWorkFlow?.updated_on)}
          </span>
        </Flex>

        <Flex data-testid="CreateEditFlowContainerFlex">
          <Flex
            justifyContent="center"
            alignItems="center"
            data-testid="CreateEditFlowElementFlex"
            gap="2"
          >
            {selectedWorkFlow.workflow_category ? (
              <Box data-testid="CreateEditFlowCarouselBox">
                <Carousel
                  wrapperClassName={styles.carousel__container}
                  buttonProps={{
                    bgColor: useColorModeValue(
                      COLORS.WHITE,
                      COLORS.WORKFLOW_DARK_MODE_BG
                    ),
                  }}
                  listenForOpenMenuChange
                >
                  {selectedWorkFlow.workflow_category.map((list, index) => (
                    <Box
                      key={`status_${index + 1}`}
                      data-testid="CreateEditFlowActionBox"
                    >
                      <StatusAction
                        onClick={() => {
                          dispatch(
                            updateStateOfWorkFlowWithKey({
                              keyValue: 'selectedWorkFlowStatusCategory',
                              value: list,
                            })
                          );
                          setIsInProgressStatesModal(true);
                        }}
                        text={list?.name}
                        bgColor={findColorPalette(list?.color?.value)}
                        icon={
                          <AddIcon color="white" style={{ display: 'block' }} />
                        }
                      />
                    </Box>
                  ))}
                </Carousel>
              </Box>
            ) : null}
            <Box data-testid="CreateEditFlowStatusBox">
              <Flex
                direction="column"
                // width={"110px"}
                flexGrow={1}
                align="center"
                onClick={() => {
                  setIsTransitinModal(true);
                  setIsTransitionWithOutDragAndDrop(true);
                }}
                data-testid="CreateEditFlowStatusFlex"
              >
                <Box
                  w="25px"
                  h="8px"
                  bg={TO_DO_STATUS}
                  data-testid="CreateEditFlowIconBox"
                />
                <ArrowDownIcon w="15px" h="10px" color={LIGHT} />
                <Box
                  w="25px"
                  h="8px"
                  bg={TO_DO_STATUS}
                  data-testid="CreateEditFlowTransitionBox"
                />
                <p className="text-center text-xs font-medium pt-2">
                  Transition
                </p>
              </Flex>
            </Box>

            {entityRoute !== 'workflow-list' && checkForV2() && (
              <GridItem
                style={{ direction: 'ltr' }}
                data-testid="CreateEditFlowActionGridItem"
              >
                <StatusAction
                  isDisabled={
                    entityRoute === 'workflow-list' &&
                    templateLifeCycle === LifecycleStatusTypes.APPROVED
                  }
                  toolTipLabel="we can't associate rule because entityApi is Workflow list"
                  onClick={() => {
                    setIsAddRole(true);
                  }}
                  text={WORK_FLOW_ACTION_LABEL.RULE}
                  bgColor={TO_DO_STATUS}
                  icon={<ReactSVG src={RoleWhite} />}
                />
              </GridItem>
            )}
          </Flex>
        </Flex>

        <Flex
          justifyContent="center"
          alignItems="center"
          py="4"
          gap="3"
          data-testid="DragAndResizeLayoutButtonBox"
        >
          <CustomButton
            // leftIcon={<ReactSVG src={useColorModeValue(UndoIcon, UndoWhite)} />}
            svg={UndoIcon}
            svghover={UndoWhite}
            color={useColorModeValue('black', 'white')}
            onClick={() => {
              handleUndoReDo(true);
            }}
            buttonText="undo"
            variant="outline"
            isDisabled={!lastTenFlowState?.undoIndex}
            id="DragAndResizeLayoutUndoTextBtn"
          />
          <CustomButton
            // leftIcon={<ReactSVG src={useColorModeValue(RedoIcon, RedoWhite)} />}
            svg={RedoIcon}
            svghover={RedoWhite}
            color={useColorModeValue('black', 'white')}
            onClick={() => {
              handleUndoReDo(false);
            }}
            buttonText="Redo"
            variant="outline"
            isDisabled={
              !(
                // eslint-disable-next-line no-unsafe-optional-chaining
                (
                  lastTenFlowState?.undoIndex <
                  lastTenFlowState?.lastTenWorkflowState?.length - 1
                )
              )
            }
            id="DragAndResizeLayoutRedoBtn"
          />
        </Flex>

        <Flex gap="2">
          <CustomButton
            classname="calculatedHeight"
            color={useColorModeValue('black', 'white')}
            id="CreateEditFlowStatusManageCategory"
            buttonText={ACTION_LABELS.MANAGE_CATEGORY}
            variant="outline"
            onClick={() => {
              // if (templateLifeCycle === LifecycleStatusTypes.APPROVED) {
              //   return;
              // }
              dispatch(updateSelectedWorkFlowStatus(null));
              dispatch(
                updateStateOfWorkFlowWithKey({
                  keyValue: 'selectedWorkFlowTransition',
                  value: {},
                })
              );
              setManageCategory(true);
              setOpenMenu(true);
            }}
            // isDisabled={templateLifeCycle === LifecycleStatusTypes.APPROVED}
          />
          {/* <CustomButton
            color={useColorModeValue('black', 'white')}
            id="CreateEdditFlowclose"
            buttonText={ACTION_LABELS.CLOSE}
            variant="outline"
            onClick={handleReplaceRoute}
            marginLeft="10px"
          /> */}
          {/* to check from */}
          {/* eslint-disable-next-line react/destructuring-assignment, react/prop-types */}
          {!props.id ? (
            <CustomButton
              leftIcon={<CloseIcon />}
              colorScheme={useColorModeValue('red', 'white')}
              borderColor={useColorModeValue('red', COLORS.BORDER_DARK)}
              onClick={handleReplaceRoute}
              buttonText="Exit"
              variant="outline"
              _hover={{ background: '#C5303035' }}
            />
          ) : (
            <AssociateAnotherFlowBtn
              clearUndoRedoState={clearUndoRedoState}
              selectedTemplate={selectedTemplate}
            />
          )}
        </Flex>
      </Flex>
      <Box display="flex" data-testid="CreateEditFlowBarContentBox">
        <WorkFlowSideBarContent
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          manageCategory={manageCategory}
          openTransitionModalInEditStatus={openTransitionModalInEditStatus}
        />
        <Box
          height={
            sourceRoute === 'template'
              ? `calc(100vh - ${!openFullScreenWorkflow ? '24rem':'12rem'})`
              : `calc(100vh - 15.4rem)`
          }
          w="99%"
          data-testid="CreateEditFlowCustomFlowBox"
        >
          <CustomFlow
            nodes={nodes}
            edges={innerEdges}
            onNodesChange={
              onNodesChange
            }
            onEdgesChange={
              onEdgesChange
            }
            nodeTypes={nodeTypes}
            onNodeDragStop={onNodeDragStop}
            onConnect={
              onConnect
            }
            edgeTypes={edgeTypes}
            controlShow={openMenu}
            onEdgeClick={(_event, edge) => {
              resetTransitionRequiredState();
              setManageCategory(false);
              dispatch(updateSelectedWorkFlowStatus(null));
              if (edge?.id === 'edges_1') {
                return;
              }
              dispatch(
                updateStateOfWorkFlowWithKey({
                  keyValue: 'selectedWorkFlowTransition',
                  value: { ...edge, selected: false },
                })
              );
              const { nodeInternals } = flowStore.getState();
              const toOption = {
                label: nodeInternals.get(edge.source).data?.label,
                value: nodeInternals.get(edge.source)?.id,
              };
              const fromOption = {
                label: nodeInternals.get(edge.target).data?.label,
                value: nodeInternals.get(edge.target)?.id,
              };
              dispatch(
                updateStateOfWorkFlowWithKey({
                  keyValue: 'toTransitionState',
                  value: [toOption],
                })
              );
              dispatch(
                updateStateOfWorkFlowWithKey({
                  keyValue: 'fromTransitionState',
                  value: fromOption,
                })
              );
              setOpenMenu(true);
            }}
            onNodeClick={(_event, node) => {
              // if (templateLifeCycle === LifecycleStatusTypes.APPROVED) {
              //   setIsApprovedShowToastMessage(true);
              //   return;
              // }
              setManageCategory(false);
              dispatch(updateSelectedWorkFlowStatus(null));
              dispatch(
                updateStateOfWorkFlowWithKey({
                  keyValue: 'selectedWorkFlowTransition',
                  value: {},
                })
              );
              dispatch(updateSelectedWorkFlowStatus(node));
              setOpenMenu(true);
            }}
            onPaneClick={() => {
              setManageCategory(false);
              dispatch(updateSelectedWorkFlowStatus(null));
              resetTransitionRequiredState();
              setOpenMenu(false);
            }}
            onFitView={onFitView}
            {...props}
          />
        </Box>
      </Box>
      <CustomDrawer
        drawerBody={
          <>
            <h4
              style={{
                fontSize: '16px',
                color: useColorModeValue(TEXT_LIGHT, ''),
                fontWeight: '500',
                lineHeight: '19px',
                paddingBottom: '20px',
              }}
            >
              {selectedWorkFlowStatusCategory?.description}
            </h4>
            <Box minH="90px" mt="20px">
              <AddStatus
                getOptionLabel={getOptionLabel}
                errorName={errorName}
                setErrorName={setErrorName}
              />
            </Box>
          </>
        }
        drawerFooter={
          <CustomModalFooter
            onCancelClick={() => {
              setErrorName(false);
              dispatch(updateDropDownState(''));
              dispatch(
                updateStateOfWorkFlowWithKey({
                  keyValue: 'selectedWorkFlowStatusCategory',
                  value: {},
                })
              );
              setIsInProgressStatesModal(false);
            }}
            onAddClick={handleCheckNameIsExit}
            btnLabel={LABELS.ACTIONS.ADD}
            isDisabled={!dropDownState?.trim()}
          />
        }
        modalTitle={addedPreFixText(selectedWorkFlowStatusCategory?.name)}
        openDrawer={isInProgressStatesModal}
        setOpenDrawer={handleInProgressModalOpen}
      />
      <CustomDrawer
        drawerBody={
          <Box minH="90px">
            <h4
              style={{ color: useColorModeValue(TEXT_LIGHT, '') }}
              className="text-base font-medium leading-5 pb-0 mb-4"
            >
              {ADD_TRANSITION.SUB_TITLE}
            </h4>
            <AddTransition
              errorName={errorName}
              setErrorName={setErrorName}
              errorCommonFlow={errorCommonFlow}
              setErrorCommonFlow={setErrorCommonFlow}
            />
          </Box>
        }
        drawerFooter={
          <CustomModalFooter
            onCancelClick={() => {
              handleTransition();
            }}
            onAddClick={handleTransitionNameCheck}
            btnLabel={LABELS.ACTIONS.CREATE_TRANSITION}
            isDisabled={!inputValueForCreateDropDown}
          />
        }
        modalTitle={ADD_TRANSITION.TITLE}
        openDrawer={isTransitinModal}
        setOpenDrawer={handleTransition}
      />
      <AddNewRoleModal
        isAddRole={isAddRole}
        setIsAddRole={setIsAddRole}
        onClick={setRoleModalOpen}
        roleModalOpen={roleModalOpen}
        handleRuleModalOpen={handleRuleModalOpen}
      />

      <RestricMoveRoleModal
        isRestricMoveRole={isRestricMoveRoleModal}
        setIsRestricMoveRole={setIsRestricMoveRoleModal}
      />
      <RestrictSpecificStatusModal
        isRestricMoveRole={isRestrictSpecificStatusModal}
        setIsRestricMoveRole={setIsRestrictSpecificStatusModal}
      />
      <RestrictValidateSpecialPermissionModal
        isRestricSpecialRulePermission={isRestricSpecialRulePermission}
        setIsRestricSpecialRulePermission={setIsRestricSpecialRulePermission}
      />
      <RestrictSpecificValueModal
        isRestrictSpecificValue={isRestrictSpecificValue}
        setIsRestrictSpecificValue={setIsRestrictSpecificValue}
      />
      <RestrictLinkedTicketSpecificStatusModal
        setIsRestrictLinkedTicketSpecificStatusValue={
          setIsRestrictLinkedTicketSpecificStatusValue
        }
        isRestrictLinkedTicketSpecificStatusValue={
          isRestrictLinkedTicketSpecificStatusValue
        }
      />
      <WorkflowNameChange
        isEditWorkflowName={isEditWorkflowName}
        setIsEditWorkflowName={setIsEditWorkflowName}
      />
      {!isEmpty(toolTipInfo) &&
        showApiToastMessage(
          toolTipInfo.title,
          toolTipInfo.description,
          toolTipInfo.info || 'info'
        )}
    </Box>
  );
};

export default CreateEditFlow;
