import React from 'react';
import {
  DEFAULT_VALUE,
  FIELD_SLUG,
  FIELD_VALIDATE_KEY,
  LABELS,
} from '../../../utils/enums/labels';
import { boolSelectOptionTwo } from '../../../utils/enums/selectOption';
import { stringToSlug } from './helper';
import { BASE_SETTINGS_KEYS, ADVANCED_SETTINGS_KEYS } from './constants';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { getOptionFormBool } from '../../TicketTemplateWorkflow/helper';
import customStylesSelectWithHeight40 from '../../../context/CustomTheme/CustomStylesSelectWithHeight40';
import { renderFieldLabels } from './renderFields';

export const BooleanBasicSetting = ({
  data,
  setFields,
  setIsError,
  existingFields,
}) => ({
  row1: [
    renderFieldLabels({
      data,
      setFields,
      fieldSlug: FIELD_SLUG.FIELD_BOOL,
      setIsError,
      existingFields,
    }),
    {
      label: LABELS.FIELD_PROPERTIES.NAME,
      key: FIELD_VALIDATE_KEY.SLUG,
      value: `${stringToSlug(data?.[BASE_SETTINGS_KEYS.SLUG]) || ''}`,
      placeholder: DEFAULT_VALUE.EMPTY_STRING,
      required: true,
      errorString: LABELS.ERROR.NAME,
      isReadOnly: true,
      valueKey: BASE_SETTINGS_KEYS.SLUG,
      onChange: (value) => {
        setFields((pre) => ({
          ...pre,
          [BASE_SETTINGS_KEYS.SLUG]: stringToSlug(value),
          [BASE_SETTINGS_KEYS.IS_EDIT_SLUG]: true,
        }));
        setIsError((prev) => ({
          ...prev,
          [BASE_SETTINGS_KEYS.SLUG]: !value,
        }));
      },
    },
  ],
  box2: [
    {
      label: LABELS.FIELD_PROPERTIES.LABEL,
      value: `${data?.[BASE_SETTINGS_KEYS.LABEL] || ''}`,
      placeholder: '',
      valueKey: BASE_SETTINGS_KEYS.LABEL,
      onChange: (value) =>
        setFields((pre) => ({ ...pre, [BASE_SETTINGS_KEYS.LABEL]: value })),
    },
  ],
  row3: [
    {
      label: LABELS.FIELD_PROPERTIES.DEFAULT_VALUE,
      value: data?.[BASE_SETTINGS_KEYS.DEFAULT_VALUE] ?? false,
      placeholder: '',
      valueKey: BASE_SETTINGS_KEYS.DEFAULT_VALUE,
      onChange: (value) => {
        setFields((pre) => ({
          ...pre,
          [BASE_SETTINGS_KEYS.DEFAULT_VALUE]: value,
        }));
      },
      render: (fields) => {
        let val = fields?.[BASE_SETTINGS_KEYS.DEFAULT_VALUE];
        // this is give a boolean value each time 
        let valBool = val === "true" ? true : val === "false" ? false : ''
        // if (typeof (val) !== 'boolean') {
        //   val = '';
        // }
        return (
        <CustomSelectBox
        placeholder={_.capitalize(fields?.[BASE_SETTINGS_KEYS.DEFAULT_VALUE])}  // Add this line to pass the placeholder prop to CustomSelectBox
          options={boolSelectOptionTwo}
          value={getOptionFormBool(
            valBool,
            boolSelectOptionTwo
          )}
          onChange={(option) => {
            setFields((pre) => { 
              const updated  = {
              ...pre,
              [BASE_SETTINGS_KEYS.DEFAULT_VALUE]: option?.value,
            } 
            return updated
          });
          }}
          styles={customStylesSelectWithHeight40}
          id="BooleanSettingsSelectBox"
        />
        );
      },
    },
  ],
  row2: [
    {
      label: LABELS.FIELD_PROPERTIES.DESCRIPTION,
      value: data?.[BASE_SETTINGS_KEYS.DESCRIPTION],
      placeholder: '',
      valueKey: BASE_SETTINGS_KEYS.DESCRIPTION,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [BASE_SETTINGS_KEYS.DESCRIPTION]: value,
        })),
    },
  ],
});
export const advancedBoolField = (data, setFields) => ({
  row1: [
    {
      label: LABELS.FIELD_PROPERTIES.HINT_TEXT,
      value: `${data[ADVANCED_SETTINGS_KEYS.HINT_TEXT] || ''}`,
      placeholder: '',
      valueKey: ADVANCED_SETTINGS_KEYS.HINT_TEXT,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [ADVANCED_SETTINGS_KEYS.HINT_TEXT]: value,
        })),
    },
  ],
  row2: [],
  row3: [
    {
      label: LABELS.FIELD_PROPERTIES.REQUIRED,
      value: `${data?.[ADVANCED_SETTINGS_KEYS.REQUIRED] || ''}`,
      valueKey: ADVANCED_SETTINGS_KEYS.REQUIRED,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [ADVANCED_SETTINGS_KEYS.REQUIRED]: value,
        })),
    },
    // {
    //   label: LABELS.FIELD_PROPERTIES.READONLY,
    //   value: `${data?.[ADVANCED_SETTINGS_KEYS.READ_ONLY] || ''}`,
    //   valueKey: ADVANCED_SETTINGS_KEYS.READ_ONLY,
    //   onChange: (value) =>
    //     setFields((pre) => ({
    //       ...pre,
    //       [ADVANCED_SETTINGS_KEYS.READ_ONLY]: value,
    //     })),
    // },
    // {
    //   label: LABELS.FIELD_PROPERTIES.HIDDEN,
    //   value: `${data?.[ADVANCED_SETTINGS_KEYS.HIDDEN] || ''}`,
    //   valueKey: ADVANCED_SETTINGS_KEYS.HIDDEN,
    //   onChange: (value) =>
    //     setFields((pre) => ({
    //       ...pre,
    //       [ADVANCED_SETTINGS_KEYS.HIDDEN]: value,
    //     })),
    // },
    {
      label: LABELS.FIELD_PROPERTIES.SEARCHABLE,
      value: `${data?.[ADVANCED_SETTINGS_KEYS.SEARCHABLE] || ''}`,
      valueKey: ADVANCED_SETTINGS_KEYS.SEARCHABLE,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [ADVANCED_SETTINGS_KEYS.SEARCHABLE]: value,
        })),
    },
    {
      label: LABELS.FIELD_PROPERTIES.REPORTABLE,
      value: `${data?.[ADVANCED_SETTINGS_KEYS.REPORTABLE] || ''}`,
      valueKey: ADVANCED_SETTINGS_KEYS.REPORTABLE,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [ADVANCED_SETTINGS_KEYS.REPORTABLE]: value,
        })),
    }
  ],
  row4: [
    {
      label: LABELS.FIELD_PROPERTIES.METRICS,
      value: `${data?.[ADVANCED_SETTINGS_KEYS.METRICS] || ''}`,
      placeholder: '',
      valueKey: ADVANCED_SETTINGS_KEYS.METRICS,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [ADVANCED_SETTINGS_KEYS.METRICS]: value,
        })),
    },
  ],
});
