import axiosInstance from '../../utils/axios/axios';

import { createData, deleteData, getData, showToast } from '../../utils/crud';
import { associateBy } from './CustomDynamicSelect/customDynamicSelect.helpers';
import { FORM_MAP } from './filters.constants';

// To get metaFields
export const getMetaFields = async () => {
  try {
    const res = await axiosInstance.get('/metaTables/list');
    const metaFields = res?.data?.rows?.[0]?.metaFields;
    const filterData = res?.data?.rows?.[0]?.filterData;
    return {
      metaFields: Array.isArray(metaFields) ? metaFields : [],
      filterData: associateBy({ options: filterData, key: 'meta_field_id' }),
    };
  } catch (err) {
    return {
      metaFields: [],
      filterData: [],
    };
  }
};

// To get filters
export const getFilters = async () => {
  const res = await getData({
    endpoint: 'filters/list?$select=*',
  });

  return res;
};

// To get the filter role mapping
export const getFilterRoleMapping = async () => {
  const res = await getData({
    endpoint: 'filtersRolesMappings/list?$select=*',
  });

  return res;
};

// To create filter
export const createFilter = async (payload) => {
  const res = await createData({
    endpoint: 'filters/create',
    payload,
    successMessage: 'Filter created successfully',
    errorMessage: 'Failed to create a new filter',
  });

  return res;
};

// To create filter role mapping
export const createFilterRoleMapping = async (payload) => {
  const res = await createData({
    endpoint: 'filtersRolesMappings/create',
    payload,
    successMessage: 'Filter role mapping created successfully',
    errorMessage: 'Failed to create a new filter role mapping',
  });

  return res;
};

// To update filter
export const updateFilter = async (
  id,
  payload,
  { successToast, errorToast, showToastMessage = true }
) => {
  try {
    const res = await axiosInstance.patch(
      `filters/update?$filter=id eq ${id}`,
      {
        data: payload,
      }
    );

    if (res?.data?.rows_updated !== 1) {
      throw new Error('Something went wrong');
    }

    if (showToastMessage) {
      showToast({
        type: 'success',
        message: successToast || 'Filter updated successfully',
      });
    }

    return res?.data?.data;
  } catch (err) {
    if (showToastMessage) {
      showToast({
        type: 'error',
        message: errorToast || 'Failed to update the filter',
      });
    }

    return null;
  }
};

// To update filter role mapping
export const updateFilterRoleMapping = async (
  id,
  payload,
  { successToast, errorToast, showToastMessage = true }
) => {
  try {
    const res = await axiosInstance.patch(
      `/filtersRolesMappings/update?$filter=id eq ${id}`,
      {
        data: payload,
      }
    );

    if (res?.data?.rows_updated !== 1) {
      throw new Error('Something went wrong');
    }

    if (showToastMessage) {
      showToast({
        type: 'success',
        message: successToast || 'Succesfully updated filter map for the role',
      });
    }

    return res?.data?.data;
  } catch (err) {
    if (showToastMessage) {
      showToast({
        type: 'error',
        message: errorToast || 'Failed to update the filter map for the role',
      });
    }

    return null;
  }
};

// To delete Filter
export const deleteFilter = async (id) => {
  const res = deleteData({
    endpoint: `filters/delete?$filter=id eq ${id}`,
    successMessage: 'Filter deleted successfully',
    errorMessage: 'Failed to delete the filter',
  });

  return res;
};

// To delete the filter role map
export const deleteFilterRoleMapping = async (id) => {
  const res = deleteData({
    endpoint: `filtersRolesMappings/delete?$filter=id eq ${id}`,
    successMessage: 'Filter for the role deleted successfully',
    errorMessage: 'Failed to delete the filter for the role',
  });

  return res;
};

// To mark a filter as default
export const toggleDefault = async ({
  id,
  currentDefaultFilterId,
  isDefault,
}) => {
  let prevDefault = true;
  let currDefault = false;

  if (isDefault && currentDefaultFilterId) {
    prevDefault = await updateFilter(
      currentDefaultFilterId,
      {
        [FORM_MAP.IS_DEFAULT]: false,
      },
      {
        showToastMessage: false,
      }
    );
  }

  if (prevDefault) {
    currDefault = await updateFilter(
      id,
      {
        [FORM_MAP.IS_DEFAULT]: isDefault,
      },
      {
        successToast: isDefault
          ? 'Successfully marked the filter as default'
          : 'Successfully unmarked the filter as default',
        errorToast: isDefault
          ? 'Failed to mark the filter as default'
          : 'Failed to unmark the filter as default',
      }
    );
  }

  return prevDefault && currDefault;
};
