import { createData, deleteData, getData, updateData } from '../../utils/crud';

export const getAudiences = async () => {
  const res = await getData({
    endpoint: '/audiences/list?$select=*'
  });

  return res;
};

export const createAudience = async (payload) => {
  const res = await createData({
    endpoint: '/audiences/create',
    payload,
    successMessage: 'Audience created successfully',
    errorMessage: 'Failed to create a new audience',
  });

  return res;
};

export const updateAudience = async (payload, id) => {
  const res = await updateData({
    payload,
    endpoint: `audiences/update?$filter=id eq ${id}`,
    successMessage: 'Audience updated successfully',
    errorMessage: 'Failed to update audience',
  });

  return res;
};

export const deleteAudience = async (id) => {
  const res = await deleteData({
    endpoint: `audiences/delete?$filter=id eq ${id}`,
    successMessage: 'Audience deleted successfully',
    errorMessage: 'Failed to delete the audience',
  });

  return res;
};
