/* eslint-disable camelcase */
import React, { useState } from 'react';
import { EdgeLabelRenderer, getSmoothStepPath, useStoreApi } from 'reactflow';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { useSelector, useDispatch } from 'react-redux';
import RoleBlack from '../../../assets/icons/RuleBlack.svg';
import { getEdgeFromId, isIdFoundInArrays } from '../helper';
import WorkFlowSideBarContent from '../CreateEditFlow/WorkFlowSideBarContent';
import {
  updateSelectedWorkFlowStatus,
  updateStateOfWorkFlowWithKey,
} from '../../../redux/WorkFlowMainSlice/WorkFlowMainReducer';

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  data,
  markerEnd,
}) => {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const { selectedWorkFlow, entityRoute } = useSelector(
    (state) => state.workFlowMain
  );
  const [openMenu, setOpenMenu] = useState(false);
  const dispatch = useDispatch();
  const flowStore = useStoreApi();
  const openTransitionDrawer = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (id === 'edges_1') {
      return;
    }
    const selectedEdge = getEdgeFromId(
      id,
      selectedWorkFlow?.workflow_transitions || []
    );
    dispatch(updateSelectedWorkFlowStatus(null));
    dispatch(
      updateStateOfWorkFlowWithKey({
        keyValue: 'selectedWorkFlowTransition',
        value: { ...selectedEdge, selected: false },
      })
    );
    const { nodeInternals } = flowStore.getState();
    const toOption = {
      label: nodeInternals.get(selectedEdge.source).data?.label,
      value: nodeInternals.get(selectedEdge.source)?.id,
    };
    const fromOption = {
      label: nodeInternals.get(selectedEdge.target).data?.label,
      value: nodeInternals.get(selectedEdge.target)?.id,
    };
    dispatch(
      updateStateOfWorkFlowWithKey({
        keyValue: 'toTransitionState',
        value: [toOption],
      })
    );
    dispatch(
      updateStateOfWorkFlowWithKey({
        keyValue: 'fromTransitionState',
        value: fromOption,
      })
    );
    setOpenMenu(true);
  };

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <EdgeLabelRenderer>
        <div
          onClick={(event) => {
            openTransitionDrawer(event);
          }}
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            paddingLeft: '6px',
            paddingRight: '6px',
            borderRadius: 12,
            fontSize: 12,
            fontWeight: 600,
            color: '#333333',
            background: 'white',
            border: '1px solid black',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            zIndex: 2,
            pointerEvents: 'auto',
          }}
          className="nodrag nopan"
        >
          {entityRoute === 'template' &&
            isIdFoundInArrays(id, selectedWorkFlow?.workflow_rules?.list) && (
              <ReactSVG src={`${RoleBlack}`} alt="image" />
            )}
          {data.label}
        </div>
      </EdgeLabelRenderer>
      {openMenu && (
        <WorkFlowSideBarContent
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          manageCategory={false}
          openTransitionModalInEditStatus={() => {}}
        />
      )}
    </>
  );
};
CustomEdge.propTypes = {
  data: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  sourceX: PropTypes.number.isRequired,
  sourceY: PropTypes.number.isRequired,
  targetX: PropTypes.number.isRequired,
  targetY: PropTypes.number.isRequired,
  sourcePosition: PropTypes.string.isRequired,
  targetPosition: PropTypes.string.isRequired,
  style: PropTypes.object,
  markerEnd: PropTypes.string.isRequired,
};
CustomEdge.defaultProps = {
  style: {},
};
export default CustomEdge;
