import { Box, GridItem, Grid, Text } from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useStoreApi } from 'reactflow';
import CustomInput from '../../../components/InputBox/Input';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { mapToJsonArray } from '../helper';
import {
  updateInputValueForCreateDropDown,
  updateStateOfWorkFlowWithKey,
} from '../../../redux/WorkFlowMainSlice/WorkFlowMainReducer';
import { customStyleFromRuleForSelect } from '../../../context/CustomTheme/CustomStyleFromRuleForSelect';

const AddTransition = ({
  errorName,
  setErrorName,
  errorCommonFlow,
  setErrorCommonFlow,
}) => {
  const dispatch = useDispatch();
  const {
    inputValueForCreateDropDown,
    toTransitionState,
    fromTransitionState,
    // isAutoMoveAllowed,
  } = useSelector((state) => state.workFlowMain);
  const flowStore = useStoreApi();
  const { nodeInternals } = flowStore.getState();
  const nodeOption = mapToJsonArray(nodeInternals)
    .filter((i) => i.id !== 'start_1')
    .map((i) => ({
      label: i.data?.label,
      value: i?.id,
    }));

  return (
    <Box data-testid="AddTransitionContainerBox">
      <Grid
        templateColumns="repeat(2, 1fr)"
        gap="115px"
        data-testid="AddTransitionTextGrid"
      >
        <Text
          className="text-lg font-semibold"
          data-testid="AddTransitionFromText"
        >
          From
          <span className="text-red-600 text-sm pt-[10px] pl-[3px]">*</span>
        </Text>
        <Text
          className="text-lg font-semibold"
          data-testid="AddTransitionToText"
        >
          To
          <span className="text-red-600 text-sm pt-[10px] pl-[3px]">*</span>
        </Text>
      </Grid>
      <Grid
        templateColumns="6fr 1fr 6fr"
        gap="30px"
        pt="10px"
        pb="15px"
        data-testid="AddTransitionContainerGrid"
      >
        <GridItem data-testid="AddTransitionSelectGridItem">
          <div data-testid="AddTransitionSelectDiv">
            <CustomSelectBox
              defaultValue={toTransitionState}
              isMulti
              name="to"
              options={nodeOption}
              isError={!!errorCommonFlow}
              onChange={(e) => {
                setErrorCommonFlow('');
                dispatch(
                  updateStateOfWorkFlowWithKey({
                    keyValue: 'toTransitionState',
                    value: [...e],
                  })
                );
              }}
              styles={customStyleFromRuleForSelect}
              id="AddTransitionSelectBox"
            />
          </div>
        </GridItem>
        <GridItem
          className="flex justify-center items-center"
          data-testid="AddTransitionArrowIconGridItem"
        >
          <ArrowForwardIcon />
        </GridItem>
        <GridItem data-testid="AddTransitionDelectFromGridItem">
          <div data-testid="AddTransitionSelectFromDiv">
            <CustomSelectBox
              defaultValue={fromTransitionState}
              name="from"
              options={nodeOption}
              isError={!!errorCommonFlow}
              styles={customStyleFromRuleForSelect}
              onChange={(e) => {
                dispatch(
                  updateStateOfWorkFlowWithKey({
                    keyValue: 'fromTransitionState',
                    value: e,
                  })
                );
              }}
              id="AddTransition1SelectBox"
            />
          </div>
        </GridItem>
      </Grid>
      {errorCommonFlow && (
        <span className="field-error">{errorCommonFlow}</span>
      )}
      <Grid
        templateColumns="repeat(1, 1fr)"
        pt="32px"
        data-testid="AddTransitionNameGrid"
      >
        <GridItem data-testid="AddTransitionNameGridItem">
          <Text
            className="text-lg font-semibold"
            data-testid="AddTransitionNameText"
          >
            Name
            <span className="text-red-600 text-sm pt-[10px] pl-[3px]">*</span>
          </Text>
          <CustomInput
            onChange={(e) => {
              setErrorName(false);
              dispatch(updateInputValueForCreateDropDown(e.target.value));
            }}
            value={inputValueForCreateDropDown}
            placeholder=""
            helperText=" "
            isError={errorName}
            errorMessage="The transition name is already in use on the canvas."
            id="AddTransitionedge_name"
          />
          <span className="text-sm font-normal">
            {`Tip: Name your transition as an action people take to move an issue,
          like "start work" or "merge".`}
          </span>
        </GridItem>
      </Grid>

      <Box mt="20px" data-testid="AddTransitionBox">
        {/* <CustomCheckBox
          checkBoxText="Auto move"
          isChecked={isAutoMoveAllowed}
          onChange={(e) => {
            dispatch(updateAutoMove(e.target.checked));
          }}
        >
          <span className="text-[12px] font-semibold block">AUTO MOVE</span>
        </CustomCheckBox> */}
      </Box>
    </Box>
  );
};
AddTransition.propTypes = {
  errorName: PropTypes.bool.isRequired,
  setErrorName: PropTypes.func.isRequired,
};
export default AddTransition;
