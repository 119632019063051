/* eslint-disable no-unused-vars */
import { LABELS } from '../../../utils/enums/labels';
import { ADVANCED_SETTINGS_KEYS } from './constants';

const labelAdvanceFields = (data, setFields, setIsError) => ({
  row1: [
    {
      label: LABELS.FIELD_PROPERTIES.HINT_TEXT,
      value: `${data[ADVANCED_SETTINGS_KEYS.HINT_TEXT] || ''}`,
      placeholder: '',
      valueKey: ADVANCED_SETTINGS_KEYS.HINT_TEXT,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [ADVANCED_SETTINGS_KEYS.HINT_TEXT]: value,
        })),
    },
  ],
  row2: [],
  row3: [
    // {
    //   label: LABELS.FIELD_PROPERTIES.REQUIRED,
    //   value: `${data?.[ADVANCED_SETTINGS_KEYS.REQUIRED] || ''}`,
    //   valueKey: ADVANCED_SETTINGS_KEYS.REQUIRED,
    //   onChange: (value) =>
    //     setFields((pre) => ({
    //       ...pre,
    //       [ADVANCED_SETTINGS_KEYS.REQUIRED]: value,
    //     })),
    // },
    // {
    //   label: LABELS.FIELD_PROPERTIES.UNIQUE,
    //   value: `${data?.[ADVANCED_SETTINGS_KEYS.UNIQUE] || ''}`,
    //   valueKey: ADVANCED_SETTINGS_KEYS.UNIQUE,
    //   onChange: (value) =>
    //     setFields((pre) => ({
    //       ...pre,
    //       [ADVANCED_SETTINGS_KEYS.UNIQUE]: value,
    //     })),
    // },
    // {
    //   label: LABELS.FIELD_PROPERTIES.READONLY,
    //   value: `${data?.[ADVANCED_SETTINGS_KEYS.READ_ONLY] || ''}`,
    //   valueKey: ADVANCED_SETTINGS_KEYS.READ_ONLY,
    //   onChange: (value) =>
    //     setFields((pre) => ({
    //       ...pre,
    //       [ADVANCED_SETTINGS_KEYS.READ_ONLY]: value,
    //     })),
    // },
    // {
    //   label: LABELS.FIELD_PROPERTIES.HIDDEN,
    //   value: `${data?.[ADVANCED_SETTINGS_KEYS.HIDDEN] || ''}`,
    //   valueKey: ADVANCED_SETTINGS_KEYS.HIDDEN,
    //   onChange: (value) =>
    //     setFields((pre) => ({
    //       ...pre,
    //       [ADVANCED_SETTINGS_KEYS.HIDDEN]: value,
    //     })),
    // },
    {
      label: LABELS.FIELD_PROPERTIES.SEARCHABLE,
      value: `${data?.[ADVANCED_SETTINGS_KEYS.SEARCHABLE] || ''}`,
      valueKey: ADVANCED_SETTINGS_KEYS.SEARCHABLE,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [ADVANCED_SETTINGS_KEYS.SEARCHABLE]: value,
        })),
    },
    {
      label: LABELS.FIELD_PROPERTIES.REPORTABLE,
      value: `${data?.[ADVANCED_SETTINGS_KEYS.REPORTABLE] || ''}`,
      valueKey: ADVANCED_SETTINGS_KEYS.REPORTABLE,
      onChange: (value) =>
        setFields((pre) => ({
          ...pre,
          [ADVANCED_SETTINGS_KEYS.REPORTABLE]: value,
        })),
    },
  ],
  row4: [],
});
export default labelAdvanceFields;
