import Joi from 'joi';

import { stringRequiredValidation } from '../validationSchemas';
import { FORM_MAP } from '../../../pages/MasterFieldDefaults/fieldDefaults.utils';

const FIELD_DEFAULT_VALIDATIONS = Joi.object({
  [FORM_MAP.OFFER_TYPE]: stringRequiredValidation,
  [FORM_MAP.NAME]: stringRequiredValidation,
  [FORM_MAP.DESCRIPTION]: stringRequiredValidation,
  [FORM_MAP.ITEM_TYPE]: stringRequiredValidation,
  [FORM_MAP.THUMBNAIL]: Joi.string().allow('', null),
  [FORM_MAP.AUDIENCES]: Joi.array(),
  [FORM_MAP.INTENT_GROUPS]: Joi.array(),
  [FORM_MAP.FIELD_ATTRIBUTES]: Joi.array().allow(null),
  id: Joi.string().allow(null, ''),
});

export default FIELD_DEFAULT_VALIDATIONS;
