/* eslint-disable no-lone-blocks */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable-next-line no-lone-blocks */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@chakra-ui/layout';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { v4 as uuidv4 } from 'uuid';
import _remove from 'lodash/remove';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';

import {
  FormLabel,
  Wrap,
  WrapItem,
  IconButton,
  useColorModeValue,
} from '@chakra-ui/react';
import moment from 'moment';
import { cloneDeep, isEmpty } from 'lodash';
import CustomButton from '../Button/SimpleButton';
import CustomSelect from '../SelectBox/Select';
import styles from './dynamicExpressionWithTags.module.scss';
import { COLORS, DARK, LIGHT } from '../../utils/enums/colors';
import { LABELS, FIELD_VS_INPUT_TYPE } from '../../utils/enums/labels';
import {
  ruleCheckForBooleanOption,
  ruleCheckForSelectOption,
  ruleCheckForTextNumberOption,
  ruleCheckForTextOption,
} from '../../utils/enums/selectOption';
import { FieldTypes } from '../../utils/enums/types';
import {
  transformFieldIntoOptions,
  getSelectedFieldType,
  transformValuesIntoOptions,
  getTemplateFields,
  isSelectedFieldSpecialTypeOfQueue,
  getHierarchyById,
  getTitlesHierarchy,
} from './dynamicExpressionWithTags.helper';
import CustomField from './CustomField';
import { getLabelByValue } from '../../utils/helper';

const DATE_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

const DynamicMutliSelectWithInputTags = ({
  label,
  onChange,
  values,
  templateId,
  omitFields,
  showRightOperand,
  overrideOptions,
}) => {
  const workflowData = useRef(null);

  const [fieldHierarchyMap, setFieldHierarchyMap] = useState(null);
  const [hierarchy, setHierarchy] = useState(null);
  const [fields, setFields] = useState();
  const [leftOperandOptions, setLeftOperandOptions] = useState();
  const [rightOperandOptions, setRightOperandOptions] = useState([]);
  const [operatorOptions, setOperatorOptions] = useState();
  const [rightOperandNeeded, setRightOperandNeeded] = useState(true);
  const [expressions, setExpressions] = useState(
    !_isEmpty(values) ? values : []
  );
  const [expression, setExpression] = useState({
    leftOperand: '',
    operator: '',
    rightOperand: '',
    labelOfValue: '',
  });
  const addExpression = () => {
    const newExpressions = _cloneDeep(expressions);
    const newlyAddedExpression = {
      operator: expression.operator?.label
        ? expression.operator?.label
        : expression.operator,
      leftOperand: expression.leftOperand.value,
      rightOperand: expression?.rightOperand
        ? expression.rightOperand.map((ro) => ro.value || ro)
        : '',
      labelOfValue: [...(expression?.rightOperand || [])]?.map((ro) => ro.label || ro),
      id: uuidv4(),
    };

    // Deleting the right operand for the cases when we don't want right operand
    if (!showRightOperand) {
      delete newlyAddedExpression.rightOperand;
    }

    newExpressions.push(newlyAddedExpression);
    setExpressions(newExpressions);
    setExpression({ leftOperand: '', operator: '', rightOperand: '' });
    setOperatorOptions([]);
    onChange(newExpressions);
  };

  const removeExpression = (id) => {
    const newExpressions = _cloneDeep(expressions);
    _remove(newExpressions, (ex) => ex.id === id);
    setExpressions(newExpressions);
    onChange(newExpressions);
  };

  useEffect(() => {
    setExpression((prevState) => ({
      ...prevState,
      rightOperand: '',
      operator: '',
    }));

    if (overrideOptions && overrideOptions?.length > 0) {
      const transformOverrideOptions = overrideOptions.map((op) => ({
        value: op,
        label: op,
      }));
      setOperatorOptions(transformOverrideOptions);
      return;
    }
    const type = getSelectedFieldType(fields, expression);
    if (type) {
      if (
        type === FieldTypes.TEXT ||
        type === FieldTypes.EMAIL ||
        type === FieldTypes.LOOKUP ||
        type === FieldTypes.RICH_TEXT ||
        type === FieldTypes.LABEL ||
        type === FieldTypes.TEXT_AREA
      ) {
        setOperatorOptions(ruleCheckForTextOption);
      } else if (type === FieldTypes.NUMBER) {
        setOperatorOptions(ruleCheckForTextNumberOption);
      } else if (
        type === FieldTypes.DROPDOWN ||
        type === FieldTypes.BOOLEAN ||
        type === FieldTypes.TAGS
      ) {
        if (
          workflowData.current?.workflowLinkedField ===
          expression?.leftOperand?.value
        ) {
          setRightOperandOptions(workflowData.current?.workFlowStates || []);
        } else {
          transformValuesIntoOptions(
            fields, expression, workflowData?.current?.workFlowStates || [])
            .then((options) => {
              if (_isEmpty(options)) setRightOperandOptions([]);
              else setRightOperandOptions(options);
            });
        }
        setOperatorOptions(ruleCheckForSelectOption);
        if (type === FieldTypes.BOOLEAN) {
          setOperatorOptions(ruleCheckForBooleanOption);
        }
      }
    }
  }, [expression?.leftOperand]);

  useEffect(() => {
    let operandNeeded = true;

    switch (expression?.operator?.value) {
      case 'is not empty':
      case 'is empty':
      case 'true':
      case 'false':
        operandNeeded = false;
        break;
      default:
        operandNeeded = true;
    }

    setRightOperandNeeded(operandNeeded);
  }, [expression?.operator]);

  useEffect(() => {
    getTemplateFields(templateId, (templateFields, workflow, fhmap) => {
      // Getting the linked workflow field
      const workflowLinkedField = workflow?.workflow_field_liked_id;

      // Getting the workflow states
      const workFlowStates = (workflow?.workflow_status || [])
        .filter((state) => state.id !== 'start_1')
        .map((state) => ({ label: state.data.label, value: state.id }));

      workflowData.current = {
        workflowLinkedField,
        workFlowStates,
      };

      setFieldHierarchyMap(fhmap ? fhmap[0] : null);
      setFields(templateFields);
    });
  }, [templateId]);

  useEffect(() => {
    if (!isEmpty(fieldHierarchyMap) && fieldHierarchyMap.heirachyListId) {
      getHierarchyById(fieldHierarchyMap.heirachyListId, setHierarchy);
    }
  }, [fieldHierarchyMap]);

  useEffect(() => {
    if (hierarchy && fieldHierarchyMap) {
      const titles = getTitlesHierarchy(hierarchy.hierarchy, fieldHierarchyMap);

      const enrichedFields = fields.map((field) => {
        if (titles[field.id]) {
          const depField = cloneDeep(field);
          depField.type_based_attributes.collection.staticValues =
            titles[field.id].map((item) => ({ value: item, label: item }));
          return depField;
        }

        return field;
      });

      setFields(enrichedFields);
    }
  }, [hierarchy, fieldHierarchyMap]);

  useEffect(() => {
    setLeftOperandOptions(transformFieldIntoOptions(fields, omitFields));
  }, [fields, omitFields]);

  const onChangeRightOperand = (value) => {
    setExpression((prevState) => ({
      ...prevState,
      rightOperand: Array.isArray(value) ? value : [value],
    }));
  };
  const renderRightOperand = (rightOperand, labelRight) => {
    if (
      rightOperand &&
      Array.isArray(rightOperand) &&
      rightOperand.length > 0
    ) {
      if (
        (rightOperand[0] instanceof Date || DATE_REGEX.test(rightOperand[0])) &&
        !Number.isNaN(rightOperand[0]) &&
        moment(rightOperand[0]).isValid()
      ) {
        return rightOperand?.map((op, idx) => (
          <>
            {idx < rightOperand.length - 1 && (
              <span>{`${moment(op).format('MMM DD, YYYY')} - `}</span>
            )}
            {idx === rightOperand.length - 1 && (
              <span>{` ${moment(op).format('MMM DD, YYYY')}`}</span>
            )}
          </>
        ));
      }
      return labelRight?.map((opLabel, idx) => (
        <>
          {idx < labelRight.length - 1 && <span>{`${opLabel}, `}</span>}
          {idx === labelRight.length - 1 && <span>{opLabel}</span>}
        </>
      ));
    }
    return null;
  };

  const onAddExpression = () => {
    // If right operand shouldn't be shown then we can add the expression
    if (!showRightOperand && expression?.leftOperand && expression?.operator) {
      addExpression();
      return;
    }

    const fieldType = getSelectedFieldType(fields, expression);

    // If the selected field type is DATE then operator will not be present
    if (fieldType === FieldTypes.DATE) {
      if (expression?.leftOperand && expression?.rightOperand) {
        addExpression();
        return;
      }
    }

    if (
      expression?.leftOperand &&
      expression?.operator &&
      (expression?.rightOperand || !rightOperandNeeded)
    ) {
      addExpression();
    }
  };

  return (
    <>
      <FormLabel
        className="SCLabel"
        data-testid="DynamicExpressionWithTazFormLbel"
      >
        {label}
      </FormLabel>
      <Flex
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        gap="20px"
        marginTop="9px"
        mb="30px"
        flexWrap="wrap"
        data-testid="DynamicExpressionWithTazFlex"
      >
        <CustomSelect
          mt="10px"
          height="30px"
          options={leftOperandOptions}
          value={expression?.leftOperand}
          onChange={(value) => {
            setExpression((prevState) => ({
              ...prevState,
              leftOperand: value,
            }));
          }}
          id="DynamicExpressionWithTazCusSelect"
        />
        {/* If override options are available then show it irrespective of type */}
        {(overrideOptions?.length ||
          !(getSelectedFieldType(fields, expression) === FieldTypes.DATE)) && (
          <CustomSelect
            options={operatorOptions}
            value={expression?.operator}
            onChange={(value) => {
              setExpression((prevState) => ({
                ...prevState,
                operator: value,
              }));
            }}
            placeholder="Select Operator"
            data-testid="DynamicExpressionWithTazOperstorCusSelect"
          />
        )}
        {expression?.leftOperand && (
          <>
            {getSelectedFieldType(fields, expression) === FieldTypes.DATE &&
              showRightOperand && rightOperandNeeded && (
                <>
                  <CustomField
                    label="Between"
                    value={_get(expression, `rightOperand[0]`)}
                    type={
                      FIELD_VS_INPUT_TYPE[
                        getSelectedFieldType(fields, expression)
                      ]
                    }
                    onChange={(value) => {
                      setExpression((prevState) => {
                        const rightOperand = prevState.rightOperand || [];
                        rightOperand[0] = value;
                        return {
                          ...prevState,
                          rightOperand,
                        };
                      });
                    }}
                  />
                  <CustomField
                    label="And"
                    value={_get(expression, `rightOperand[1]`)}
                    type={
                      FIELD_VS_INPUT_TYPE[
                        getSelectedFieldType(fields, expression)
                      ]
                    }
                    onChange={(value) => {
                      setExpression((prevState) => {
                        const rightOperand = prevState.rightOperand || [];
                        rightOperand[1] = value;
                        return {
                          ...prevState,
                          rightOperand,
                        };
                      });
                    }}
                    options={rightOperandOptions}
                  />
                </>
            )}
            {!(
              getSelectedFieldType(fields, expression) === FieldTypes.DATE ||
              getSelectedFieldType(fields, expression) === FieldTypes.BOOLEAN
            ) &&
              showRightOperand && rightOperandNeeded && (
                <CustomField
                  value={expression?.rightOperand}
                  type={
                    FIELD_VS_INPUT_TYPE[
                      getSelectedFieldType(fields, expression)
                    ]
                  }
                  onChange={onChangeRightOperand}
                  options={rightOperandOptions}
                />
            )}
            <CustomButton
              className={` ml-4 border-[#2563EB] `}
              color={useColorModeValue(LIGHT, DARK)}
              id="button-addExpressionWithTag-role"
              buttonText={LABELS.ACTIONS.ADD}
              variant="outline"
              height="32px"
              mt="0px"
              width="66px"
              padding="0px 30px"
              onClick={onAddExpression}
            />
          </>
        )}
      </Flex>
      {expressions && (
        <Wrap spacing="15px" mt="22px">
          {!_isEmpty(expressions) &&
            expressions?.map((expObj) => (
              <Wrap
                spacing={2}
                pl="10px"
                pt="3px"
                pb="3px"
                maxW="380px"
                display={expObj.id ? '' : 'none'}
                border={useColorModeValue(
                  `1px solid ${LIGHT}`,
                  `1px solid ${DARK}`
                )}
                borderRadius="4px"
                key={uuidv4()}
                data-testid="DynamicExpressionWithTazWrap"
              >
                <WrapItem data-testid="DynamicExpressionWithTazWItem">
                  <Wrap
                    className={styles.expression__tag__container}
                    data-testid="DynamicExpressionWithTazBoxWrap"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                      data-testid="DynamicExpressionWithTazWarpBox"
                    >
                      {getLabelByValue(expObj?.leftOperand, leftOperandOptions)}
                    </Box>
                    {expObj?.operator && (
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        bg={useColorModeValue(
                          COLORS.WRAP_OPERATOR_BG,
                          COLORS.WRAP_OPERATOR_BG_DARK
                        )}
                        p="10px"
                        borderRadius="4px"
                        fontWeight={500}
                        height="28px"
                        maxW="350px"
                        key={expObj.id}
                        data-testid="DynamicExpressionWithTazBoxFlex"
                      >
                        <Box data-testid="DynamicExpressionWithTazQBox">
                          {expObj?.operator}
                        </Box>
                      </Flex>
                    )}
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                      data-testid="DynamicExpressionWithTazExpBox"
                    >
                      {renderRightOperand(expObj?.rightOperand, expObj?.labelOfValue)}
                    </Box>
                    <IconButton
                      background="ffffff"
                      icon={<SmallCloseIcon />}
                      onClick={() => removeExpression(expObj?.id)}
                      data-testid="DynamicExpressionWithTazIconBtn"
                    />
                  </Wrap>
                </WrapItem>
              </Wrap>
            ))}
        </Wrap>
      )}
    </>
  );
};

DynamicMutliSelectWithInputTags.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  templateId: PropTypes.number.isRequired,
  omitFields: PropTypes.array,
  values: PropTypes.array.isRequired,
  overrideOptions: PropTypes.array,
  showRightOperand: PropTypes.bool,
};

DynamicMutliSelectWithInputTags.defaultProps = {
  omitFields: [],
  overrideOptions: [],
  showRightOperand: true,
};

export default DynamicMutliSelectWithInputTags;
