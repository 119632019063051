import React from 'react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import NotFoundLogo from '../../assets/icons/404Icon.svg';
import CustomButton from '../../components/Button/SimpleButton';
import styles from './common-layout.module.scss';
import { LABELS } from '../../utils/enums/labels';

export const NotFoundPage = () => {
  const navigate = useNavigate();

  const goBack = () => {
    // window.location.href = '/template-cardlist';
    navigate('/template-cardlist');
  };

  return (
    <div className={styles.container} data-testid="NotFoundPageContainerDiv">
      <div className={styles.center} data-testid="NotFoundPageCenterDiv">
        <img
          alt="Page Not Found"
          src={NotFoundLogo}
          className={styles.logoImage}
          data-testid="NotFoundPageLogoImage"
        />
        <h2 className={styles.firstLine} data-testid="NotFoundPageFirstLineH2">
          {LABELS.TITLE.PAGE_NOT_FOUND_HERO_TEXT}
        </h2>
        <p
          className={`${styles.marginBottom} ${styles.secondLine}`}
          data-testid="NotFoundPageMarginBottomP"
        >
          {LABELS.TITLE.PAGE_NOT_FOUND_DESCRIPTION}
        </p>
        <div className={styles.block} data-testid="NotFoundPageBlockDiv">
          <CustomButton
            className={styles.btnRight}
            color="white"
            leftIcon={<ArrowBackIcon />}
            id="button-not-found-page"
            onClick={goBack}
            buttonText={LABELS.ACTIONS.GO_HOME}
            variant="solid"
          />
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
