import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, classname } from '@chakra-ui/react';
import { dummy } from '../TicketTemplateBuilder/dummyDataForTemplate';
import CustomSelectBox from '../../components/SelectBox/Select';
import styles from '../TicketTemplateBuilder/styles/particularTemplate.module.scss';

const DropdownSelectBox = ({ columns, children, name, classname }) => {
  const allTemplates = dummy;
  return (
    <Box data-testid="DropdownSelectBox">
      <CustomSelectBox
        className={classname === 'dropdownStyle' ? styles.dropdownStyle : ''}
        options={allTemplates
          ?.map((item) =>
            columns?.map((option) => ({
              value: item[option],
              label: item[option],
            }))
          )
          .flat()}
        id="DropdownSelectOptionBox"
      />
    </Box>
  );
};
DropdownSelectBox.propTypes = {
  children: PropTypes.node.isRequired,
};
export default DropdownSelectBox;
