import React from 'react';

import { EditIcon } from '@chakra-ui/icons';

import { deleteRenderer } from '../../DynamicRenderer/MetricsAndSLA/Metrics/utils';
import { FORM_MAP } from '../filters.constants';

export const enrichSelectedData = ({ rowData, roleMap, filterMap }) => {
  const filters = rowData?.[FORM_MAP.FILTERS]?.map((f) => ({
    label: filterMap[f],
    value: f,
  }));

  const roles = {
    label: roleMap[rowData?.[FORM_MAP.ROLES]],
    value: rowData?.[FORM_MAP.ROLES],
  };

  const defaultFilter = {
    label: filterMap[rowData?.[FORM_MAP.DEFAULT_FILTER]],
    value: rowData?.[FORM_MAP.DEFAULT_FILTER],
  };

  return {
    [FORM_MAP.ROLES]: roles,
    [FORM_MAP.DEFAULT_FILTER]: defaultFilter,
    [FORM_MAP.FILTERS]: filters,
    id: rowData?.id,
  };
};

export const columns = ({ onEdit, onDelete, filterIdMap, roleMap }) => {
  const cols = [
    {
      name: FORM_MAP.ROLES,
      col_name: FORM_MAP.ROLES,
      type: 'string',
      cellRenderer: ({ rowData }) =>
        roleMap[rowData?.[FORM_MAP.ROLES]] || rowData?.[FORM_MAP.ROLES] || '',
    },
    {
      name: FORM_MAP.FILTERS,
      col_name: FORM_MAP.FILTERS,
      cellRenderer: ({ rowData }) => {
        if (!Array.isArray(rowData?.[FORM_MAP.FILTERS])) {
          return null;
        }

        return rowData?.[FORM_MAP.FILTERS]?.length;
      },
    },
    {
      name: FORM_MAP.DEFAULT_FILTER,
      col_name: FORM_MAP.DEFAULT_FILTER,
      cellRenderer: ({ rowData }) => {
        if (!rowData?.[FORM_MAP.DEFAULT_FILTER]) {
          return null;
        }

        return filterIdMap?.[rowData?.[FORM_MAP.DEFAULT_FILTER]] || '';
      },
    },
    {
      col_name: 'actions',
      cellRenderer: ({ rowData }) => (
        <div className="flex gap-[10px] justify-center">
          <EditIcon
            className="cursor-pointer"
            onClick={() => onEdit(rowData)}
          />
          {deleteRenderer({
            title: 'Delete this mapping',
            description: 'Are you sure you want to delete this mapping?',
            onConfirm: () => onDelete(rowData),
          })}
        </div>
      ),
    },
  ];

  return cols;
};
