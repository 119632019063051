import React, { useState, useRef, useEffect, useCallback } from 'react';

import { Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import axios from 'axios';

import Loader from './Loader';
import CustomToast from '../../../components/Notification/Notification';
import TableViewWithSearch from '../../../components/Table/TableWithSearch';
import DashboardEditScreen from './DashboardEditScreen';

import { getTenantServiceUrl } from '../../../helpers/tenantUrl';

import {
  columns,
  deleteDashboard,
  editDashboard,
  getHeadersForAxios,
} from './dashboard.utils';
import { getTenantRolesAndTeams } from '../../../components/dynamicSelectWithInputTags/dynamicSelectWithInputTags.helper';

const Dashboard = () => {
  const { addToast } = CustomToast();

  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditClicked, setisEditClicked] = useState(false);
  const [isLoadingForApi, setIsLoadingForApi] = useState(false);

  const selectedTemplate = useSelector(
    (state) => state?.viewState?.selectedTemplate
  );

  const selectedDashboard = useRef(null);

  const getDashBoardList = async (currentTemplate) => {
    const axiosData = await getHeadersForAxios();

    try {
      const res = await axios.get(
        `${axiosData.baseUrl}/data/items/${currentTemplate?.uuid_column}`,
        {
          headers: axiosData.headers,
        }
      );

      return {
        response: res?.data,
        error: null,
      };
    } catch (error) {
      return {
        response: null,
        error: error.toString(),
      };
    }
  };

  const toggleEditing = useCallback((value, rowData) => {
    selectedDashboard.current = rowData;
    setisEditClicked(value);
  }, []);

  const getListData = async (val) => {
    const res = await getTenantServiceUrl();
    const tenantData = await getTenantRolesAndTeams();
    if (res && tenantData) {
      setIsLoading(true);
      const response = await getDashBoardList(val);
      const org = tenantData?.domain?.split('.');
      if (response.error) {
        setListData([]);
      } else {
        const listItems = (response?.response || []).map((item) => ({
          name: item?.name,
          id: item?.id,
          viewers: {
            ...item?.permission?.permission_details?.viewers,
            org:
              item?.permission?.permission_details?.viewers.org === true
                ? [org[0]]
                : item?.permission?.permission_details?.viewers.org,
          } || {
            org: [org[0]],
            roles: [],
            teams: [],
            users: [],
            actors: [],
            groups: [],
            queues: [],
          },
          editors: item?.permission?.permission_details?.editors || {},
        }));
        setListData(listItems);
      }
    }
    setIsLoading(false);
  };

  const showToastMessage = useCallback((isSuccess, message) => {
    addToast({
      title: isSuccess ? 'Successful' : 'Something went wrong',
      message,
      type: isSuccess ? 'success' : 'error',
    });
  }, []);

  const updateDashboard = async (updatedData) => {
    setIsLoadingForApi(true);
    const res = await editDashboard(updatedData);
    if (res.error) {
      showToastMessage(false, res.error);
    } else {
      setListData((prev) => {
        const tempData = cloneDeep(prev);
        const rowIndex = tempData.findIndex((d) => d.id === updatedData.id);
        tempData[rowIndex] = updatedData;
        return [...tempData];
      });
      toggleEditing(false, null);
      showToastMessage(true, 'Successfully updated dashboard');
    }
    setIsLoadingForApi(false);
  };

  const onDelete = async (dashboardId) => {
    const res = await deleteDashboard(dashboardId);
    if (res.error) {
      showToastMessage(false, res.error);
    } else {
      showToastMessage(true, 'Successfully deleted dashboard');
      setListData((prev) => {
        const tempData = cloneDeep(prev);
        const rowIndex = tempData.findIndex((d) => d.id === dashboardId);
        tempData.splice(rowIndex, 1);
        return [...tempData];
      });
    }
  };

  useEffect(() => {
    if (selectedTemplate) {
      getListData(selectedTemplate);
    }
  }, [selectedTemplate]);

  if (isLoading) {
    return <Loader height="50vh" loaderText="Dashboards List Loading..." />;
  }

  if (isEditClicked) {
    return (
      <DashboardEditScreen
        isOpen={isEditClicked}
        setIsOpen={setisEditClicked}
        data={selectedDashboard.current}
        onNavigateBack={toggleEditing}
        updateDashboard={updateDashboard}
        isLoadingForApi={isLoadingForApi}
        setIsLoadingForApi={setIsLoadingForApi}
      />
    );
  }

  return (
    <Flex
      py="16px"
      // height="100vh"
      direction="column"
      data-testid="DashboardContainerFlex"
    >
      <TableViewWithSearch
        tableData={listData}
        columns={columns({
          onEdit: toggleEditing,
          onDelete,
        })}
        onCreateNew={() => {}}
        showCreateNewButton={false}
        title="Dashboard"
      />
    </Flex>
  );
};

export default Dashboard;
