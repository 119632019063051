import React, { useState } from 'react';
import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import { ReactSVG } from 'react-svg';
import { AddIcon } from '@chakra-ui/icons';
import NoTemplateIcon from '../../../assets/icons/NoTemplateIcon.svg';
import CustomButton from '../../../components/Button/SimpleButton';
import styles from '../styles/templatebuilder.module.scss';
import { LABELS } from '../../../utils/enums/labels';
import { COLORS } from '../../../utils/enums/colors';
import CreateTemplateModal from '../CreateEditTemplate/CreateTemplateModal';
import { FieldTypes } from '../../../utils/enums/types';

const NoTemplateScreen = () => {
  const [openNewTemplateModal, setOpenNewTemplateModal] = useState(false);
  const colorType = useColorModeValue('light', 'dark');
  return (
    <>
      <Box
        bg={useColorModeValue('white', 'gray.900')}
        height="calc(100vh - 80px)"
        borderRightColor={useColorModeValue('gray.200', 'gray.700')}
        display="flex"
        flex="1"
        justifyContent="center"
        data-testid="TemplateDashboardDataContainerBox"
      >
        <Flex
          flex="1"
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          justifyContent="center"
          data-testid="TemplateDashboardDataContainerFlex"
        >
          <Box
            bg={useColorModeValue('white', 'gray.900')}
            border="1px"
            borderColor={useColorModeValue('gray.200', 'gray.700')}
            width="450px"
            height="330px"
            align="center"
            justifyContent="center"
            mt="-40"
            data-testid="TemplateDashboardDataInnerBox"
          >
            <Box
              bg={useColorModeValue('white', 'gray.900')}
              border="1px"
              width="fit-content"
              borderColor="#E2E3E4"
              borderRadius="10px"
              mt="10"
              data-testid="TemplateDashboardDataContentBox"
            >
              <ReactSVG
                src={NoTemplateIcon}
                className={`p-2 ${
                  colorType === 'dark' ? styles.colorWhite : ''
                }`}
              />
            </Box>
            <Box
              className={styles.createHeadText}
              color={useColorModeValue('', 'white')}
              data-testid="TemplateDashboardDataHeaderBox"
            >
              {LABELS.TITLE.NO_TEMPLATES_CREATED}
            </Box>
            <div className={styles.paraText} data-testid="TemplateDashboardDataParaTextBox">
              {LABELS.TITLE.CLICK_ADD_NEW_TEMPLATE}
            </div>
            <Box pt="6" pb="10" data-testid="TemplateDashboardDataCBtnBox">
              <CustomButton
                leftIcon={<AddIcon />}
                id="TemplateDashboardDataCBtnBoxbtn"
                onClick={() => setOpenNewTemplateModal(true)}
                buttonText={LABELS.ACTIONS.CREATE_NEW_TEMPLATE}
                variant="solid"
                width="85%"
                size="md"
                className={styles.createTemplateBtn}
                bg={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
              />
            </Box>
          </Box>
        </Flex>
      </Box>
      {openNewTemplateModal && (
        <CreateTemplateModal
          type={FieldTypes.TEXT}
          templateName={LABELS.ACTIONS.CREATE_NEW_TEMPLATE}
          isTemplatedModel={openNewTemplateModal}
          setIsTemplatedModel={setOpenNewTemplateModal}
        />
      )}
    </>
  );
};

export default NoTemplateScreen;
