/* eslint-disable import/no-cycle */
import React, { useCallback, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import TableViewWithSearch from '../../../components/Table/TableWithSearch';
import EditMetrics from './Metrics/EditMetrics';
import { columns, parseExpressionMetricForState } from './Metrics/utils';
import { updateViewState } from '../../../redux/ViewSlice/ViewReducer';
import {
  patchDataForTemplate,
  patchMultipleFieldsForTemplate,
} from '../../TicketTemplateBuilder/utils/templateAPIUtils';
import { setActiveModal } from '../../../redux/ModalReducer/ModalSlice';

const MetricsAndSLA = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const viewState = useSelector((state) => state.viewState || {});
  const METRIC_DATA = viewState?.selectedTemplate?.metric || [];

  const [modalState, setModalState] = useState({
    show: false,
    isEdit: false,
    rowData: null,
  });

  /* Changing state to set the show modal */
  const showModal = useCallback(({ rowData = {}, isEdit = false }) => {
    const isExpression = !isEmpty(rowData.expression);
    const afterConvertData = parseExpressionMetricForState(
      rowData.expression || ''
    );

    if (isExpression) {
      setModalState({
        show: true,
        isEdit,
        rowData: {
          ...rowData,
          expression: afterConvertData,
        },
      });
      return;
    }
    setModalState({
      show: true,
      isEdit,
      rowData,
    });
  }, []);

  /* Changing state to close modal */
  const closeModal = useCallback(() => {
    setModalState({
      show: false,
      isEdit: false,
      rowData: null,
    });
  }, []);

  const onSaveMetric = async (dataObj) => {
    setIsLoading(true);
    const tempData = [...METRIC_DATA];
    const index = tempData.findIndex((data) => data.id === dataObj.id);

    if (index === -1) {
      tempData.push(dataObj);
    } else {
      tempData[index] = dataObj;
    }

    const res = await patchDataForTemplate({
      id,
      data: tempData,
      key: 'metric',
      defaultErrorMessage:
        index === -1 ? 'Failed to add a new metric' : 'Failed to update metric',
      successMessage:
        index === -1
          ? 'Successfully added a new metric'
          : 'Successfully updated metric',
    });

    if (!res.error) {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: {
            ...viewState?.selectedTemplate,
            ...res?.response?.data || {},
            metric: tempData,
          },
        })
      );
      closeModal();
    }
    setIsLoading(false);
  };

  const onClone = async (rowData) => {
    dispatch(setActiveModal({ targetModalId: 'crudTemplateCloneModal' }));
    dispatch(
      updateViewState({
        stateKey: 'crudTemplateCloneModal',
        value: {
          id,
          patchData: rowData,
          title: 'metric',
          masterType: 'metric',
          listKey: 'selectedTemplate',
          targetUrl: `/templates/update?$filter=id eq ${id}`,
          method: 'patch',
        },
      })
    );
  };

  const onDelete = async (selectedRow) => {
    const tempData = [...METRIC_DATA];
    const rowIndex = METRIC_DATA.findIndex((m) => m.id === selectedRow.id);
    const [rowData] = tempData.splice(rowIndex, 1);

    const SLA_DATA = viewState?.selectedTemplate?.slas || {};

    // Deleting the SLAs for the metric as well
    const tempSLAData = { ...SLA_DATA };
    delete tempSLAData[rowData.id];

    const res = await patchMultipleFieldsForTemplate({
      id,
      payload: {
        metric: tempData,
        slas: tempSLAData,
      },
      defaultErrorMessage: 'Failed to delete metric',
      successMessage: 'Successfully deleted metric',
    });

    if (!res.error) {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: {
            ...viewState?.selectedTemplate,
            ...res?.response?.data || {},
            metric: tempData,
            slas: tempSLAData,
          },
        })
      );
    }
  };

  return (
    <>
      <Box paddingTop="16px" data-testid="MetricsAndSLAContainerBox">
        <TableViewWithSearch
          placeholder="Search for a field name"
          buttonText="Create new metrics"
          onCreateNew={() => showModal({})}
          columns={columns(showModal, onClone, onDelete)}
          tableData={METRIC_DATA}
          showAddIcon={false}
          buttonVariant="solid"
          title="Metrics"
        />
      </Box>
      <EditMetrics
        closeModal={closeModal}
        openDrawer={modalState.show}
        data={modalState.rowData}
        isEdit={modalState.isEdit}
        onSaveMetric={onSaveMetric}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </>
  );
};

export default MetricsAndSLA;
