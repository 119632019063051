// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.n8LFxXHGTQITCRE6QPR6 .Xk20kE9_RqAjgHz2uGyu{margin-top:1.5rem;font-weight:500}.n8LFxXHGTQITCRE6QPR6 .RPsRG_30R7sUsQzPJnBW{display:inline;font-size:14px;line-height:18px;font-weight:500;width:172px}`, "",{"version":3,"sources":["webpack://./src/components/dynamicSelectWithInputTags/dynamicSelectWithInputTags.module.scss"],"names":[],"mappings":"AACA,4CACQ,iBAAA,CACA,eAAA,CAEJ,4CACI,cAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA,CACA,WAAA","sourcesContent":["\n.dynamicSelectWithMutliInputTags .gridRow{\n        margin-top: 1.5rem;\n        font-weight: 500;\n      }\n    .dynamicSelectWithMutliInputTags .roleTitleText {\n        display:inline;\n        font-size: 14px;\n        line-height: 18px;\n        font-weight: 500;\n        width: 172px;\n      }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dynamicSelectWithMutliInputTags": `n8LFxXHGTQITCRE6QPR6`,
	"gridRow": `Xk20kE9_RqAjgHz2uGyu`,
	"roleTitleText": `RPsRG_30R7sUsQzPJnBW`
};
export default ___CSS_LOADER_EXPORT___;
