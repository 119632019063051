import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

import { theme } from 'antd';

const ThemeContext = createContext({
  algorithm: theme.defaultAlgorithm,
});

const ThemeProvider = ({ children }) => {
  const [themeName, setThemeName] = useState({
    algorithm: theme.defaultAlgorithm,
  });

  const toggleTheme = () => {
    const dark = {
      algorithm: theme.darkAlgorithm,
    };
    const light = {
      algorithm: theme.defaultAlgorithm,
    };
    if (themeName.algorithm === theme.defaultAlgorithm) {
      setThemeName(dark);
    } else {
      setThemeName(light);
    }
  };

  // const themeObject = {theme: themeName,
  //   toggleTheme: toggleTheme,
  // };

  return (
    <ThemeContext.Provider
      value={{
        // themeName,
        toggleTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

ThemeProvider.prototype = {
  children: PropTypes.node.isRequired,
};

export { ThemeProvider, ThemeContext };
