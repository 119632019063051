/* eslint-disable indent */
import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import CustomInput from '../../../components/InputBox/Input';
import CustomSelectBox from '../../../components/SelectBox/Select';
import CustomDatePicker from '../../../components/Datepicker/AntdDatePicker';
import QueueField from './QueueField';

import { SUPPORTED_FIELD_TYPES } from '../filters.constants';
import { BOOLEAN_OPTIONS } from './customDynamicSelect.helpers';
import CustomCheckBox from '../../../components/CheckBox/CheckBox';

const CustomField = ({
  type,
  options,
  value,
  onChange,
  rightOptionMap,
  isMulti,
  isSLA,
  onSLAChange,
}) => {
  // To get the date value
  const dateValue = useMemo(() => {
    if (type === SUPPORTED_FIELD_TYPES.DATE && value) {
      return [new Date(value)];
    }
  }, [type, value]);

  // Formatting the dropdown value
  const dropdownValue = useMemo(() => {
    if (type === SUPPORTED_FIELD_TYPES.DROPDOWN) {
      if (Array.isArray(value)) {
        return value.map((v) => ({
          label: rightOptionMap[v],
          value: v,
        }));
      }

      if (value && !isMulti) {
        return {
          label: rightOptionMap[value],
          value,
        };
      }
    }

    return null;
  }, [rightOptionMap, type, value]);

  switch (type) {
    case SUPPORTED_FIELD_TYPES.TEXT:
    case SUPPORTED_FIELD_TYPES.NUMBER:
      return (
        <CustomInput
          type={type === SUPPORTED_FIELD_TYPES.TEXT ? 'text' : 'number'}
          value={value || ''}
          onChange={(e) => onChange(e.target.value)}
        />
      );
    case 'READ_ONLY':
      return (
        <CustomInput
          value="-1"
          onChange={(e) => onChange(e.target.value)}
          isReadOnly
        />
      );
    case SUPPORTED_FIELD_TYPES.METRICS:
      return (
        <>
          <CustomInput
            type={type === SUPPORTED_FIELD_TYPES.TEXT ? 'text' : 'number'}
            value={value || ''}
            onChange={(e) => onChange(e.target.value)}
          />

          <CustomDatePicker
            dateFormat="DD/MM/YYYY"
            selectedDate={value}
            showTime
            allowClear
            onChange={(val) => {
              onChange(val);
            }}
            style={{
              height: 37,
              margin: '10px 0',
            }}
          />

          <CustomCheckBox
            isChecked={isSLA}
            onChange={() => {
              onSLAChange();
            }}
            size="md"
          >
            IsSLA
          </CustomCheckBox>
        </>
      );

    case SUPPORTED_FIELD_TYPES.DATE:
      return (
        <CustomDatePicker
          value={dateValue}
          onChange={(val) => {
            if (val) {
              onChange(val.getTime());
            }
          }}
          dateFormat="MMM DD, YYYY"
          popupStyle={{
            zIndex: 9999,
          }}
          style={{
            height: 37,
          }}
        />
      );

    case SUPPORTED_FIELD_TYPES.DROPDOWN:
      if (!Array.isArray(options)) {
        return null;
      }

      return (
        <CustomSelectBox
          options={options}
          value={dropdownValue}
          onChange={(val) => {
            if (isMulti) {
              onChange(val.map((e) => e.value));
            } else {
              onChange(val.value);
            }
          }}
          isMulti={isMulti}
        />
      );

    case SUPPORTED_FIELD_TYPES.BOOLEAN:
      return (
        <CustomSelectBox
          options={BOOLEAN_OPTIONS}
          value={
            typeof value === 'boolean'
              ? {
                  label: value ? 'True' : 'False',
                  value: value.toString(),
                }
              : null
          }
          onChange={(val) => {
            onChange(val.value === 'true');
          }}
          isMulti={false}
        />
      );

    case SUPPORTED_FIELD_TYPES.QUEUES:
      return <QueueField value={value} onChange={onChange} />;

    default:
      return null;
  }
};

CustomField.propTypes = {
  type: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func.isRequired,
  rightOptionMap: PropTypes.object,
  isMulti: PropTypes.bool,
  isSLA: PropTypes.bool,
  onSLAChange: PropTypes.func,
};

CustomField.defaultProps = {
  type: '',
  options: [],
  value: '',
  rightOptionMap: {},
  isMulti: true,
  isSLA: false,
  onSLAChange: {},
};

export default CustomField;
