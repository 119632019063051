// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nvVlecO5spHscD7Vn4he{margin-bottom:1rem}.nvVlecO5spHscD7Vn4he .dynamic__select__flex__container{flex-wrap:wrap}.nvVlecO5spHscD7Vn4he .org__label__container{flex:none !important;margin-top:0 !important}`, "",{"version":3,"sources":["webpack://./src/pages/MasterAudience/audience.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAGA,wDACI,cAAA,CAGJ,6CACI,oBAAA,CACA,uBAAA","sourcesContent":[".dynamicSelect {\n  margin-bottom: 1rem;\n}\n\n  .dynamicSelect :global .dynamic__select__flex__container {\n      flex-wrap: wrap;\n    }\n\n  .dynamicSelect :global .org__label__container {\n      flex: none !important;\n      margin-top: 0 !important;\n    }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dynamicSelect": `nvVlecO5spHscD7Vn4he`
};
export default ___CSS_LOADER_EXPORT___;
