// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.b1FTu8IXEhuJdRu7NVtj{background-color:#f2f3f4;border:1px solid #d1d5db}.UZLGZOrkJsSv4SglOttV{border:1px solid #fff;background-color:#4f5765;color:#fff}`, "",{"version":3,"sources":["webpack://./src/components/Tabs/tabs.module.scss"],"names":[],"mappings":"AAAA,sBAII,wBAAA,CACA,wBAAA,CAEJ,sBACE,qBAAA,CACE,wBAAA,CACA,UAAA","sourcesContent":[".tabListContainer {\n  /*  width: 15%; \n    padding-left: '4px';\n    border-radius: '5px'; */\n    background-color: #F2F3F4;\n    border: 1px solid #D1D5DB;\n}\n.tabListContainerDark {\n  border: 1px solid white;\n    background-color: #4F5765;\n    color: #FFF;\n}\n@media (max-width: 1440px) {\n    .tabListContainer {\n    /*  width: 20%; */\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabListContainer": `b1FTu8IXEhuJdRu7NVtj`,
	"tabListContainerDark": `UZLGZOrkJsSv4SglOttV`
};
export default ___CSS_LOADER_EXPORT___;
