import { useEffect, useState } from 'react';
import Keycloak from 'keycloak-js';
// eslint-disable-next-line import/no-cycle
import { initOptions } from '../../Routing';

const useKeycloak = () => {
  const [kc, setKc] = useState(null);
  const [authenticate, setAuthenticate] = useState(false);
  const token = localStorage.getItem('kc_token');
  const refreshToken = localStorage.getItem('kc_refreshToken');
  useEffect(() => {
    const keycloakUse = new Keycloak({
      url: initOptions.url,
      realm: initOptions.realm,
      clientId: initOptions.clientId,
    });
    keycloakUse
      .init({
        onLoad: 'login-required',
        token,
        refreshToken,
        checkLoginIframe: false,
      })
      .then((authenticated) => {
        setAuthenticate(authenticated);
        setKc(keycloakUse);
      })
      .catch(() => setAuthenticate(false));
  }, []);
  return [kc, authenticate];
};

export default useKeycloak;
