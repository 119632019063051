import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';

import CustomModal from '../../components/Modal/Modal';
import CustomButton from '../../components/Button/SimpleButton';

import { ACTION_LABELS, TITLE_LABELS } from '../../utils/enums/labels';
import { COLORS } from '../../utils/enums/colors';

const DeleteModalV2 = () => {
  const [isOpen, setIsOpen] = useState(false);

  const deleteModalProps = useRef({
    title: '',
    onDelete: () => {},
    descriptionText: '',
    okButtonText: '',
    cancelButtonText: '',
  });

  const onOpen = useCallback(
    ({ title, onDelete, okButtonText, cancelButtonText, descriptionText }) => {
      deleteModalProps.current = {
        ...deleteModalProps.current,
        title,
        descriptionText,
        onDelete,
        okButtonText,
        cancelButtonText,
      };
      setIsOpen(true);
    },
    []
  );

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const modalFooter = useMemo(
    () => (
      <>
        <CustomButton
          buttonText={
            deleteModalProps.current?.cancelButtonText || ACTION_LABELS.CANCEL
          }
          onClick={onClose}
          mr={3}
          border="none"
          variant="solid"
          bg={COLORS.TEXT_DARK}
          color={COLORS.GRAY_800}
          className="!text-xs"
          _hover={{}}
        />
        <CustomButton
          buttonText={
            deleteModalProps.current?.okButtonText || ACTION_LABELS.DELETE
          }
          variant="solid"
          colorScheme="red"
          _hover={{}}
          onClick={() => {
            if (typeof deleteModalProps.current?.onDelete === 'function') {
              deleteModalProps.current.onDelete();
            }
            onClose();
          }}
          className="!text-xs"
        />
      </>
    ),
    [deleteModalProps.current?.onDelete]
  );

  const modalContent = useMemo(
    () => (
      <Box>
        <Text fontSize="14px">
          {deleteModalProps.current?.descriptionText ||
            TITLE_LABELS.CUSTOM_DELETE}
        </Text>
      </Box>
    ),
    [deleteModalProps.current?.descriptionText]
  );

  const modalTitle = useMemo(
    () => (
      <Box mb="16px" mt="-16px">
        <Text className="text-lg font-bold">
          {deleteModalProps.current?.title || ACTION_LABELS.DELETE}
        </Text>
      </Box>
    ),
    [deleteModalProps.current?.title]
  );

  const jsxContent = useMemo(
    () => (
      <CustomModal
        size="xl"
        id="delete-modal"
        open={isOpen}
        setOpen={onClose}
        modalTitle={modalTitle}
        modalContent={modalContent}
        modalFooter={modalFooter}
        showModalCloseButton={false}
      />
    ),
    [isOpen]
  );
  return { isOpen, onOpen, jsxContent };
};

export default DeleteModalV2;
