/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Tooltip, useColorModeValue, Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import CustomButton from '../../components/Button/SimpleButton';
import { COLORS } from '../../utils/enums/colors';
import { setActiveModal } from '../../redux/ModalReducer/ModalSlice';
import axiosInstance from '../../utils/axios/axios';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import CustomToast from '../../components/Notification/Notification';
import { checkDisabled } from './utils';

const Actions = ({
  name,
  buttonText,
  leftIcon,
  targetType,
  targetModalId,
  style,
  targetUrl,
  method,
  payloadKeysMaps,
  updatePayloadPath = '',
  payloadStateKey,
  listKey,
  setOpenModal = () => {},
  size = 'sm',
  styleDark = {},
  classname,
  tooltip,
  disabledCondition,
  masterType,
  navigatetUrl,
  dataTestId = ''
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const viewState = useSelector((state) => state.viewState);
  const [isInProgress, setInProgress] = useState(false);
  const { addToast } = CustomToast();

  const { id } = useParams();
  const params = new URLSearchParams(window.location.search);
  const targetName = params.get('name');

  const getDescriptionForToast = () => {
    const {
      location: { pathname },
    } = window;

    if (pathname.includes('workflow')) {
      return 'Updated Workflow';
    }

    if (pathname.includes('/fields')) {
      return 'Updated Field';
    }

    if (pathname.includes('template-makers/template')) {
      return 'Updated Template';
    }

    return 'Success';
  };

  const apiActionHander = async () => {
    setInProgress(true);
    const updateKeymapObj = _.get(payloadKeysMaps, updatePayloadPath);
    const stateData = viewState[payloadStateKey];

    if (method === 'post') {
      await axiosInstance.post(targetUrl, { data: { id } });
      addToast({
        message: getDescriptionForToast(),
        type: 'success',
        duration: 2000,
        isClosable: true,
      });
      dispatch(
        updateViewState({
          stateKey: listKey,
          value: {
            refresh: uuidv4(),
          },
        })
      );
      setInProgress(false);
      return;
    }
    if (stateData) {
      const updatedData = _.reduce(
        _.keys(updateKeymapObj),
        (acc, key) => ({
          ...acc,
          [key]: stateData[key] ?? updateKeymapObj[key],
        }),
        {}
      );
      const apiReq = _.set(payloadKeysMaps, updatePayloadPath, updatedData);
      if (method === 'patch') {
        await axiosInstance.patch(
          `${targetUrl}${id ? `&$filter=id eq ${id}` : ''}`,
          { data: apiReq }
        );
        addToast({
          message: 'Edited Successfully',
          type: 'success',
          duration: 2000,
          isClosable: true,
        });
      }
    }

    setInProgress(false);
  };

  const actionHandler = () => {
    switch (targetType) {
      case 'navigate':
        navigate(`${targetUrl}${id}?name=${targetName}`);
        // code for navigate
        break;
      case 'popup':
        // code for pop-up
        dispatch(setActiveModal({ targetModalId }));
        if (targetModalId === 'crudTemplateCloneModal') {
          dispatch(
            updateViewState({
              stateKey: targetModalId,
              value: {
                id: viewState?.selectedTemplate?.id,
                title: viewState?.selectedTemplate?.name,
                masterType,
                listKey,
                targetUrl,
                method,
              },
            })
          );
        }
        break;
      case 'api_call':
        // code for api
        apiActionHander();
        setOpenModal(true);
        break;
      case 'state_change':
        setOpenModal(false);
        // code for state change
        break;
      case 'route':
        navigate(`${navigatetUrl}`);
        break;
      default:
    }
  };

  return (
    <Tooltip hasArrow label={tooltip} data-testid="ActionsContainerToolTip">
      <Box data-testid="ActionsButtonContainerBox" className="calculatedHeight">
        <CustomButton
          leftIcon={leftIcon}
          id={dataTestId || 'ActionsButtonNameButton'}
          name={name}
          width="100%"
          onClick={() => {
            actionHandler();
          }}
          buttonText={buttonText}
          variant="outline"
          color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
          // mb="4"
          size={size}
          style={useColorModeValue(style, styleDark)}
          isLoading={isInProgress}
          className={classname}
          isDisabled={checkDisabled(disabledCondition, viewState)}
        />
      </Box>
    </Tooltip>
  );
};

export default Actions;
