import { createStandaloneToast } from '@chakra-ui/react';

const { toast } = createStandaloneToast();

const getDuration = (duration) => {
  if (duration) {
    return duration;
  }
  if (typeof duration === 'object') {
    return null;
  }
  return 2000;
};

export const CustomToast = () => {
  const addToast = (newRes) => {
    toast({
      title: newRes?.title,
      description: newRes?.message,
      status: newRes?.type,
      position: newRes?.position || 'bottom-left',
      isClosable: true,
      duration: getDuration(newRes?.duration),
    });
  };

  return { addToast };
};

CustomToast.Types = {
  success: 'success',
};

export default CustomToast;
