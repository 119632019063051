export function isObjectEqual(obj1, obj2) {
    // Check if both values are identical
    if (obj1 === obj2) return true;

    // Check if both values are objects and not null
    if (obj1 == null || typeof obj1 !== 'object' ||
        obj2 == null || typeof obj2 !== 'object') {
        return false;
    }

    // Get the keys of both objects
    let keys1 = Object.keys(obj1);
    let keys2 = Object.keys(obj2);

    // If the number of keys is different, the objects are not equal
    if (keys1.length !== keys2.length) return false;

    // Check if all keys and values are identical
    for (let key of keys1) {
        if (!keys2.includes(key) || !isObjectEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
}
