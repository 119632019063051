// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bo1UG_aSDvXeu3pW6euR span{margin:0px !important;margin-inline-end:0}.XV5FvRcXYlnA8m9Y3PEy{display:flex;align-items:center;justify-content:center;height:100%;width:100%}.oGvolJaC5Cu6WYA1uALc{background-color:#e6e6e6 !important;color:#333 !important;border:1px solid #e2e3e4 !important;font-size:14px !important}.Tifw1STZXZfypLwjBjug{background-color:#333 !important;color:#fff !important;border:1px solid #fff}`, "",{"version":3,"sources":["webpack://./src/components/AttachmentUpload/Attachment.module.scss"],"names":[],"mappings":"AAAA,2BACI,qBAAA,CACA,mBAAA,CAGJ,sBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CACA,UAAA,CAGF,sBACE,mCAAA,CACA,qBAAA,CACA,mCAAA,CACA,yBAAA,CAGF,sBACI,gCAAA,CACA,qBAAA,CACA,qBAAA","sourcesContent":[".emptyBtnWithIcon span {\n    margin: 0px !important;\n    margin-inline-end: 0;\n  }\n\n.image__loader__container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  width: 100%;\n}\n\n.browse__files__button {\n  background-color: hsl(0, 0%, 90%) !important;\n  color: hsl(0, 0%, 20%) !important;\n  border: 1px solid #e2e3e4 !important;\n  font-size: 14px !important;\n}\n\n.browse__files__button__dark {\n    background-color: hsl(0, 0%, 20%) !important;\n    color: #fff !important;\n    border: 1px solid #fff;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emptyBtnWithIcon": `bo1UG_aSDvXeu3pW6euR`,
	"image__loader__container": `XV5FvRcXYlnA8m9Y3PEy`,
	"browse__files__button": `oGvolJaC5Cu6WYA1uALc`,
	"browse__files__button__dark": `Tifw1STZXZfypLwjBjug`
};
export default ___CSS_LOADER_EXPORT___;
