import React from 'react';
import { Box } from '@chakra-ui/react';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { getWholeMultiSelectOptions, getWholeOption } from '../../TicketTemplateWorkflow/helper';
import CustomField from '../../../components/dynamicExpressionWithTags/CustomField';
import { availableUsersListInTenantLevel } from '../../../utils/helper/roleBasedAccess';

const watcherTypeOptions = [
  {
    label: 'To',
    value: 'toWatchers'
  },
  {
    label: 'CC',
    value: 'ccWatchers',
  }
];

const renderAddWatcherForm = (
  handleChange,
  actionToPerformSelectedIndex,
  handleActionErrorData,
  actionToPerform,
  errorData,
) => (
  <>
    <Box data-testid="DynamicRendererNoteFormBox1" marginTop="8px">
      <CustomSelectBox
        options={watcherTypeOptions}
        label="Watcher Type"
        placeholder="Select Watcher Type"
        value={getWholeOption(
          actionToPerform.watcherType,
          watcherTypeOptions
        )}
        onChange={(value) => {
          handleChange(
            value.value,
            `action_to_perform[${actionToPerformSelectedIndex}].watcherType`
          );

          handleActionErrorData('watcherType');
        }}
        id="DynamicRendererManualEventCustomSelectNoteType"
      />
    </Box>
    <Box data-testid="DynamicRendererNoteFormBox1" marginTop="8px">
      <CustomField
        type="multi-select"
        options={availableUsersListInTenantLevel()}
        label="Watchers"
        placeholder="Select Watchers"
        value={getWholeMultiSelectOptions(
          actionToPerform.watchers,
          availableUsersListInTenantLevel()
        )}
        isMulti
        onChange={(selectedValues) => {
          handleChange(
            selectedValues.map((selectedItem) => selectedItem.value),
            `action_to_perform[${actionToPerformSelectedIndex}].watchers`
          );

          handleActionErrorData('watchers');
        }}
        id="DynamicRendererManualEventCustomSelectNoteType"
      />
    </Box>
  </>
);

export default renderAddWatcherForm;
