import React from "react"
import { Input } from "@chakra-ui/react"
import { deleteRenderer } from "../../../DynamicRenderer/MetricsAndSLA/Metrics/utils"
export const BOT_IDENTICATION_FIELDS_TRIGGERS=({onEditDescription,onDelete})=>[
    {
        name: 'Field',
        label: 'Field',
        type: 'string',
        cellRenderer: ({ rowData }) => <Input value={rowData?.label} disabled={true} /> ,
    },
    {
        name: 'Type',
        label: 'Type',
        type: 'string',
        cellRenderer: ({ rowData }) => <Input value={rowData?.type} disabled={true} />,
    },
    {
        name: 'Category',
        label: 'Category',
        type: 'string',
        cellRenderer: ({ rowData }) => <Input value={rowData?.type_based_attributes?.required ? "Required" : "Optional"} disabled={true} />,
    },
    {
        name: 'Description for Bot',
        label: 'Description for Bot',
        type: 'string',
        cellRenderer: ({ rowData }) => <Input onChange={(e)=>{onEditDescription(rowData,e.target.value)}} value={rowData?.description}/>,
    },
    {
        name: 'Action',
        label: 'Action',
        type: 'string',
        cellRenderer: ({ rowData }) => (
            !rowData?.type_based_attributes?.required && 
            <div>
                {deleteRenderer({
                    title: 'Delete this field',
                    description: 'Are you sure you want to delete this field?',
                    onConfirm: () => {
                    onDelete(rowData);
                    },
                    dataTestId: `Fields-${rowData.name}-delete`
                })}
            </div>
        ),
    }
]