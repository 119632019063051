import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';
import axiosInstance from '../../utils/axios/axios';

// Define the API endpoints
const recentCardsEndpoint =
  'recentlyViewed/list?$top=200&$skip=0&$orderby=id asc&$select=*';
const templatesEndpoint =
  'templates/list?$top=200&$skip=0&$select=id,name,properties,lifecycle_status';
const favouritesEndpoint =
  'favourites/list?$top=200&$skip=0&$select=template_id';

export const fetchRecentCards = createAsyncThunk(
  'recentCards/fetchRecentCards',
  async () => {
    try {
      const recentCardsResponse = await axiosInstance.get(recentCardsEndpoint);
      const templatesList =
        recentCardsResponse?.data?.rows?.[0]?.template_list || [];

      if (templatesList?.length > 0) {
        const templateIdFilters = templatesList
          .map((template) => `id eq ${template.id}`)
          .join(' or ');

        const recentlyViewedTemplatedList = templatesList.reduce(
          (prev, curr) => {
            prev[curr.id] = curr;
            return prev;
          },
          {}
        );

        const {
          data: { rows: templates },
        } = await axiosInstance.get(
          `${templatesEndpoint}&$filter=${templateIdFilters}`
        );

        const favoriteIdFilters = templates
          .map((template) => `template_id eq ${template.id}`)
          .join(' or ');

        const {
          data: { rows: favourites },
        } = await axiosInstance.get(
          favoriteIdFilters
            ? `${favouritesEndpoint}&$filter=${favoriteIdFilters}`
            : favouritesEndpoint
        );

        const favouritesIdMap = favourites?.reduce((prev, curr) => {
          prev[curr.template_id] = true;
          return prev;
        }, {});

        const mergedTemplates = templates.map((template) => ({
          ...template,
          ...(recentlyViewedTemplatedList[template.id] || {}),
          isFavorite: favouritesIdMap[template.id] || false,
        }));
        const sortedMergedTemplates = _reverse(
          _sortBy(mergedTemplates, ['last_viewed'])
        );
        return { cards: sortedMergedTemplates };
      }
      return { cards: [] };
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

const initialState = {
  cards: [],
  status: 'idle',
  error: null,
};

const recentCardsSlice = createSlice({
  name: 'recentCards',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecentCards.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRecentCards.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.cards = action.payload.cards;
      })
      .addCase(fetchRecentCards.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default recentCardsSlice.reducer;
