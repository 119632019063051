/* eslint-disable indent */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Box,
  DrawerBody,
  DrawerFooter,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';

import CustomInput from '../../../../components/InputBox/Input';
import CustomSelectBox from '../../../../components/SelectBox/Select';
import CustomButton from '../../../../components/Button/SimpleButton';

import {
  IS_SLA,
  METRIC_TYPE,
  METRIC_TYPE_VALUE,
  UNIT,
  FORM_KEY_MAP,
  METRIC_TYPE_MAP,
  SLA_MAP,
  UNIT_MAP,
  customStyles,
  createMap,
  parseExpressionMetricForApi,
  removeUUIDFromExpression,
} from './utils';
import { COLORS } from '../../../../utils/enums/colors';
import { renderTagsCategory2 } from '../Tags/TagBuilder';
import { REDUX_TICKET_TEMPLATE_KEYS } from '../helper';
import { validationErrorMessage } from '../../../../utils/validationSchemas/validationSchemas';
import { METRICS_VALIDATIONS } from '../../../../utils/validationSchemas/templates/metricsAndSla';
import { checkForV2 } from '../../../../utils/helper';
import {
  getQueueFields,
  getQueueFieldsForV1,
  makeOptions,
} from '../../../../utils/helper/QueueAndActorhelper';
import { selectOptionsNoteTypes } from '../../customTemplateModal/CustomTemplateModalLayout';
import { DROPDOWN_TYPES } from '../../../TenantFilters/filters.constants';
import {
  PrimaryCustomButton,
  SecondaryCustomButton,
} from '../../../../components/Button/PrimarySecondaryButton';
import CustomMention from '../../../../components/Mention';
import { validateSlaMetricFieldCannotHaveSameName } from '../../duplicateMetricSlaFieldsVerificationHelper';
import { showToastMessage } from '../../../TicketTemplateBuilder/utils/templateAPIUtils';

const EditMetricModal = ({
  rowData,
  navigateBack,
  isMetricUpdated,
  onSaveMetric,
  isLoading,
  setIsLoading,
}) => {
  const viewState = useSelector((state) => state.viewState || {});
  const METRIC_DATA = viewState?.selectedTemplate?.metric || [];

  const TEMPLATE_FIELDS = useMemo(() => {
    try {
      return (
        viewState?.[REDUX_TICKET_TEMPLATE_KEYS.FIELD_LIST]?.data || []
      ).map((field) => ({ label: field.label, value: field.id }));
    } catch (err) {
      return [];
    }
  }, [viewState]);

  const ACTOR_FIELD_LIST = useMemo(() => {
    try {
      let fields = [];

      if (viewState?.[REDUX_TICKET_TEMPLATE_KEYS.FIELD_LIST]?.data?.length) {
        fields = viewState?.[REDUX_TICKET_TEMPLATE_KEYS.FIELD_LIST]?.data;
      } else if (viewState?.selectedTemplate?.fields?.length) {
        fields = viewState?.selectedTemplate?.fields;
      }

      return fields
        .filter(
          (field) =>
            field.type === 'DROPDOWN' &&
            field.type_based_attributes.collection.collection_type ===
              DROPDOWN_TYPES.SPECIAL_FIELD &&
            field.type_based_attributes.collection.specialField === 'actor'
        )
        .map((field) => ({
          label: field.label,
          value: field.id,
        }));
    } catch (err) {
      return [];
    }
  }, [viewState]);

  const REFERRED_METRIC_FIELDS = useMemo(() => {
    try {
      return (viewState?.selectedTemplate?.metric || []).map((metric) => ({
        label: metric.name,
        value: metric.id,
      }));
    } catch (err) {
      return [];
    }
  }, [viewState]);
  const METRIC_FIELD_WITHOUT_REFERRED = useMemo(() => {
    try {
      return (viewState?.selectedTemplate?.metric || [])
        .filter((item) => item.type !== METRIC_TYPE_VALUE.CALCULATED_METRICS)
        .map((metric) => ({
          label: metric.name,
          value: metric.id,
        }));
    } catch (err) {
      return [];
    }
  }, [viewState]);

  const WORKFLOW_STATES = useMemo(() => {
    try {
      return (viewState?.selectedTemplate?.workflow?.workflow_status || [])
        .filter((state) => state.id !== 'start_1')
        .map((state) => ({ label: state.data.label, value: state.id }));
    } catch (err) {
      return [];
    }
  }, [viewState]);

  const QUEUES = useMemo(() => {
    if (checkForV2()) {
      const option = makeOptions(getQueueFields(viewState?.selectedTemplate));
      return option || [];
    }
    const option = makeOptions(
      getQueueFieldsForV1(viewState?.selectedTemplate)
    );
    return option || [];
  }, [viewState]);

  const TEMPLATE_FIELD_MAP = useMemo(
    () => createMap(TEMPLATE_FIELDS),
    [viewState]
  );
  const WORKFLOW_MAP = useMemo(() => createMap(WORKFLOW_STATES), [viewState]);
  const ACTOR_MAP = useMemo(() => createMap(ACTOR_FIELD_LIST), [viewState]);
  const REFERRED_FIELD_MAP = useMemo(
    () => createMap(REFERRED_METRIC_FIELDS),
    [viewState]
  );
  const QUEUE_MAP = useMemo(() => createMap(QUEUES), [viewState]);

  const [metricData, setMetricData] = useState({ ...rowData });
  const [initialMetricData, setInitialMetricData] = useState({ ...rowData });
  useEffect(() => {
    isMetricUpdated(initialMetricData, metricData);
  }, [metricData]);
  const [errorData, setErrorData] = useState({});

  const handleErrorData = useCallback((key, val) => {
    setErrorData((prev) => {
      if (prev[key] && val) {
        return {
          ...prev,
          [key]: '',
        };
      }
      return prev;
    });
  }, []);

  const handleChange = useCallback((key, val) => {
    setMetricData((prev) => {
      const copyPrev = { ...prev };
      if (!val) {
        delete copyPrev[key];
        return copyPrev;
      }
      return {
        ...copyPrev,
        [key]: val,
      };
    });

    handleErrorData(key, val);
  }, []);

  const excludeData = useCallback(
    ({ completeData = [], selectedData = [] }) =>
      completeData.filter((state) => !selectedData.includes(state.value)),
    []
  );
  const includeData = useCallback(
    ({ completeData = [], selectedData = [] }) =>
      completeData.filter((state) => selectedData.includes(state.value)),
    []
  );
  const getValueForSelect = (key) => {
    const value = rowData?.[key] || '';
    switch (key) {
      case FORM_KEY_MAP.METRIC_TYPE:
        return { label: METRIC_TYPE_MAP[value], value };
      case FORM_KEY_MAP.SLA:
        return { label: SLA_MAP[value], value };
      case FORM_KEY_MAP.METRIC_UNIT:
        return { label: UNIT_MAP[value], value };
      case FORM_KEY_MAP.ACTOR:
        return { label: ACTOR_MAP[value], value };
      case FORM_KEY_MAP.LOG_TIME_FIELD:
        return { label: TEMPLATE_FIELD_MAP[value], value };
      default:
        return { label: value, value };
    }
  };

  const formateMetricData = (prev) => ({
    ...prev,
    [FORM_KEY_MAP.SLA]: getValueForSelect(FORM_KEY_MAP.SLA),
    [FORM_KEY_MAP.METRIC_TYPE]: getValueForSelect(FORM_KEY_MAP.METRIC_TYPE),
    [FORM_KEY_MAP.METRIC_UNIT]: getValueForSelect(FORM_KEY_MAP.METRIC_UNIT),
    [FORM_KEY_MAP.ACTOR]: getValueForSelect(FORM_KEY_MAP.ACTOR),
    [FORM_KEY_MAP.LOG_TIME_FIELD]: getValueForSelect(
      FORM_KEY_MAP.LOG_TIME_FIELD
    ),
  });

  useEffect(() => {
    setMetricData(formateMetricData);
    setInitialMetricData(formateMetricData);
  }, [rowData]);

  const addDataForTags = ({ selectedFieldName, listFieldName, value }) => {
    let selectedOption = value || metricData?.[selectedFieldName];
    selectedOption =
      typeof selectedOption === 'object'
        ? selectedOption?.value
        : selectedOption;

    if (selectedOption) {
      const tempData = metricData?.[listFieldName] || [];
      const updatedData = [...tempData, selectedOption];
      setMetricData((prev) => ({
        ...prev,
        [selectedFieldName]: '',
        [listFieldName]: updatedData,
      }));

      handleErrorData(listFieldName, updatedData);
    }
  };

  const deleteTags = ({ index, key }) => {
    const tempData = [...metricData[key]];
    if (tempData.length > 0) {
      const delItemValue = tempData[index];
      const afterSpliceExpression = removeUUIDFromExpression(
        metricData[FORM_KEY_MAP.CALCULATED_METRICS_EXPRESSION] || '',
        `@(${delItemValue})`
      );
      tempData.splice(index, 1);
      setMetricData((prev) => ({
        ...prev,
        [key]: tempData,
        [FORM_KEY_MAP.CALCULATED_METRICS_EXPRESSION]:
          tempData.length > 0 ? afterSpliceExpression : '',
      }));
      handleErrorData(FORM_KEY_MAP.CALCULATED_METRICS_EXPRESSION, key);
    }
  };

  const onSave = () => {

    // validate metric name can not be same as field, sla or any other metric name
    const validationMessage = validateSlaMetricFieldCannotHaveSameName(metricData?.[FORM_KEY_MAP.NAME]?.trim().toLowerCase(),viewState?.selectedTemplate, metricData.id || uuidv4())
    if(validationMessage){
      showToastMessage({
        description: validationMessage,
        status: 'info',
      });
      return
    }
    
    const dataObj = {
      id: metricData.id || uuidv4(),
      [FORM_KEY_MAP.NAME]: metricData?.[FORM_KEY_MAP.NAME]?.replace(/\s+/g, ' ').trim(),
      [FORM_KEY_MAP.SLA]: metricData?.[FORM_KEY_MAP.SLA]?.value,
      [FORM_KEY_MAP.METRIC_TYPE]: metricData?.[FORM_KEY_MAP.METRIC_TYPE]?.value,
      [FORM_KEY_MAP.METRIC_UNIT]: metricData?.[FORM_KEY_MAP.METRIC_UNIT]?.value,
      [FORM_KEY_MAP.UPDATED_AT]: new Date().getTime(),
      [FORM_KEY_MAP.UPDATED_BY]: localStorage.getItem('userName') || '',
    };

    switch (dataObj[FORM_KEY_MAP.METRIC_TYPE]) {
      case METRIC_TYPE_VALUE.STATE_TIME:
        Object.assign(dataObj, {
          [FORM_KEY_MAP.INCLUDED_STATES]:
            metricData?.[FORM_KEY_MAP.INCLUDED_STATES],
          [FORM_KEY_MAP.EXIT_CRETERIA_MOVE_OUT]:
            metricData?.[FORM_KEY_MAP.EXIT_CRETERIA_MOVE_OUT],
          [FORM_KEY_MAP.EXIT_CRETERIA_MOVE_IN]:
            metricData?.[FORM_KEY_MAP.EXIT_CRETERIA_MOVE_IN],
          [FORM_KEY_MAP.QUEUE]: metricData?.[FORM_KEY_MAP.QUEUE],
        });
        break;

      case METRIC_TYPE_VALUE.ACTOR_TIME:
        Object.assign(dataObj, {
          [FORM_KEY_MAP.ACTOR]: metricData?.[FORM_KEY_MAP.ACTOR]?.value,
          [FORM_KEY_MAP.EXIT_CRETERIA_MOVE_OUT]:
            metricData?.[FORM_KEY_MAP.EXIT_CRETERIA_MOVE_OUT],
          [FORM_KEY_MAP.EXIT_CRETERIA_MOVE_IN]:
            metricData?.[FORM_KEY_MAP.EXIT_CRETERIA_MOVE_IN],
        });
        break;

      case METRIC_TYPE_VALUE.QUEUE_TIME:
        Object.assign(dataObj, {
          [FORM_KEY_MAP.EXIT_CRETERIA_MOVE_OUT]:
            metricData?.[FORM_KEY_MAP.EXIT_CRETERIA_MOVE_OUT],
          [FORM_KEY_MAP.EXIT_CRETERIA_MOVE_IN]:
            metricData?.[FORM_KEY_MAP.EXIT_CRETERIA_MOVE_IN],
          [FORM_KEY_MAP.QUEUE]: metricData?.[FORM_KEY_MAP.QUEUE],
        });
        break;

      case METRIC_TYPE_VALUE.LOG_TIME:
        Object.assign(dataObj, {
          [FORM_KEY_MAP.ACTOR]: metricData?.[FORM_KEY_MAP.ACTOR]?.value,
          [FORM_KEY_MAP.LOG_TIME_FIELD]:
            metricData?.[FORM_KEY_MAP.LOG_TIME_FIELD]?.value,
        });
        break;

      case METRIC_TYPE_VALUE.FIRST_RESPONSE_TIME:
        Object.assign(dataObj, {
          [FORM_KEY_MAP.FIELDS]: metricData?.[FORM_KEY_MAP.FIELDS],
          [FORM_KEY_MAP.NOTES]: metricData?.[FORM_KEY_MAP.NOTES],
          [FORM_KEY_MAP.QUEUE]: metricData?.[FORM_KEY_MAP.QUEUE],
        });
        break;

      case METRIC_TYPE_VALUE.CALCULATED_METRICS:
        Object.assign(dataObj, {
          [FORM_KEY_MAP.CALCULATED_METRICS_EXPRESSION]:
            parseExpressionMetricForApi(
              metricData?.[FORM_KEY_MAP.CALCULATED_METRICS_EXPRESSION] || ''
            ),
          [FORM_KEY_MAP.REFERRED_FIELDS]:
            metricData?.[FORM_KEY_MAP.REFERRED_FIELDS],
        });
        break;

      default:
        break;
    }

    const { error } = METRICS_VALIDATIONS.validate(dataObj, {
      abortEarly: false,
    });
    const nameDuplicateErrorIndex = METRIC_DATA?.filter(
      (item) => item.id !== dataObj.id
    ).findIndex(
      (element) =>
        element.name?.replace(/ /g, '').toLowerCase() ===
        dataObj.name?.replace(/ /g, '').toLowerCase()
    );

    if (nameDuplicateErrorIndex === 0 || nameDuplicateErrorIndex) {
      if (nameDuplicateErrorIndex !== -1) {
        setErrorData((pre) => ({
          ...pre,
          [FORM_KEY_MAP.NAME]: 'Name is already exist"',
        }));
        setIsLoading(false);
        return;
      }
    }
    if (error) {
      const errors = validationErrorMessage(error);
      setErrorData({ ...errors });

      setIsLoading(false);
      return;
    }
    onSaveMetric(dataObj);
  };

  const renderQueues = (
    <CustomSelectBox
      label="Queue"
      options={QUEUES}
      value={
        metricData?.[FORM_KEY_MAP.QUEUE]
          ? {
              value: metricData[FORM_KEY_MAP.QUEUE],
              label: QUEUE_MAP[metricData[FORM_KEY_MAP.QUEUE]],
            }
          : {}
      }
      isClearable
      styles={customStyles('100%')}
      onChange={(e) => {
        handleChange(FORM_KEY_MAP.QUEUE, e?.value);
      }}
      isError={!!errorData?.[FORM_KEY_MAP.QUEUE]}
      errorMessage={errorData?.[FORM_KEY_MAP.QUEUE] || ''}
      id="EditMetricModal1SelectBox"
    />
  );

  const renderExtraFields = () => {
    switch (metricData?.[FORM_KEY_MAP.METRIC_TYPE]?.value || '') {
      case METRIC_TYPE_VALUE.ACTOR_TIME:
        return (
          <Box width="50%">
            <Box mr="15px">
              <CustomSelectBox
                label="Actor Field"
                options={ACTOR_FIELD_LIST}
                value={metricData?.[FORM_KEY_MAP.ACTOR] || ''}
                styles={customStyles('100%')}
                onChange={(e) => {
                  handleChange(FORM_KEY_MAP.ACTOR, e);
                }}
                isError={!!errorData?.[FORM_KEY_MAP.ACTOR]}
                errorMessage={errorData?.[FORM_KEY_MAP.ACTOR] || ''}
                id="EditMetricModal2SelectBox"
              />
            </Box>
          </Box>
        );
      case METRIC_TYPE_VALUE.LOG_TIME:
        return (
          <Flex gap="30px">
            {checkForV2() && (
              <Box flex="1">
                <CustomSelectBox
                  label="Actor Field"
                  options={ACTOR_FIELD_LIST}
                  value={metricData?.[FORM_KEY_MAP.ACTOR] || ''}
                  styles={customStyles('100%')}
                  onChange={(e) => {
                    handleChange(FORM_KEY_MAP.ACTOR, e);
                  }}
                  isError={!!errorData?.[FORM_KEY_MAP.ACTOR]}
                  errorMessage={errorData?.[FORM_KEY_MAP.ACTOR] || ''}
                  id="EditMetricModal3SelectBox"
                />
              </Box>
            )}
            {/* <Box flex="1">
              <CustomSelectBox
                label="Log Time Field"
                options={TEMPLATE_FIELDS}
                value={metricData?.[FORM_KEY_MAP.LOG_TIME_FIELD] || ''}
                styles={customStyles('100%')}
                onChange={(e) => {
                  handleChange(FORM_KEY_MAP.LOG_TIME_FIELD, e);
                }}
                isError={!!errorData?.[FORM_KEY_MAP.LOG_TIME_FIELD]}
                errorMessage={errorData?.[FORM_KEY_MAP.LOG_TIME_FIELD] || ''}
                id="EditMetricModal4SelectBox"
              />
            </Box> */}
          </Flex>
        );

      case METRIC_TYPE_VALUE.FIRST_RESPONSE_TIME:
        return (
          <>
            <Flex gap="30px">
              <Box flex="1">
                <Flex>
                  <Box flex="1">
                    <CustomSelectBox
                      label="Fields"
                      options={excludeData({
                        completeData: TEMPLATE_FIELDS,
                        selectedData: metricData?.[FORM_KEY_MAP.FIELDS],
                      })}
                      value={metricData?.[FORM_KEY_MAP.SELECTED_FIELD] || ''}
                      styles={customStyles('100%')}
                      onChange={(e) => {
                        addDataForTags({
                          selectedFieldName: FORM_KEY_MAP.SELECTED_FIELD,
                          listFieldName: FORM_KEY_MAP.FIELDS,
                          value: e.value,
                        });
                      }}
                      controlShouldRenderValue={false}
                      id="EditMetricModal5SelectBox"
                    />
                  </Box>
                </Flex>
                {errorData?.[FORM_KEY_MAP.FIELDS] ? (
                  <span className="field-error">
                    {errorData[FORM_KEY_MAP.FIELDS]}
                  </span>
                ) : null}

                <Box mt="10px">
                  {renderTagsCategory2({
                    key: FORM_KEY_MAP.FIELDS,
                    data: metricData,
                    title: 'Fields',
                    deleteTags,
                    renderTagLabel: (data) => TEMPLATE_FIELD_MAP[data],
                  })}
                </Box>
              </Box>
              <Box flex="1">
                <CustomSelectBox
                  isMulti
                  options={selectOptionsNoteTypes}
                  value={
                    !_isEmpty(metricData?.[FORM_KEY_MAP.NOTES])
                      ? _map(metricData?.[FORM_KEY_MAP.NOTES], (c) => {
                          const option = selectOptionsNoteTypes.find(
                            (note) => note.value === c
                          );
                          return option;
                        })
                      : undefined
                  }
                  onChange={(selectedOptionsData) => {
                    const selectedValues = selectedOptionsData.map(
                      (option) => option.value
                    );
                    handleChange(FORM_KEY_MAP.NOTES, [
                      ...(selectedValues || []),
                    ]);
                  }}
                  label="Notes"
                  isError={!!errorData?.[FORM_KEY_MAP.NOTES]}
                  errorMessage={errorData?.[FORM_KEY_MAP.NOTES] || ''}
                />
              </Box>
            </Flex>

            <Flex gap="30px">
              <Box flex="1">{renderQueues}</Box>
              <Box flex="1" />
            </Flex>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box p="16px" height="calc(100vh - 7rem)" overflow="auto" data-testid="EditMetricModalContainerBox">
      <DrawerBody padding="10px">
        <Flex gap="30px" data-testid="EditMetricModalContainerFLex">
          <Box flex={1} data-testid="EditMetricModalNameCIBox">
            <CustomInput
              label="Name"
              value={metricData?.[FORM_KEY_MAP.NAME] || ''}
              onChange={(e) => {
                handleChange(FORM_KEY_MAP.NAME, e.target.value);
              }}
              isError={!!errorData?.[FORM_KEY_MAP.NAME]}
              errorMessage={errorData?.[FORM_KEY_MAP.NAME] || ''}
              id="EditMetricModalSDivCInput"
            />
          </Box>
          <Box flex={1} data-testid="EditMetricModalSLASelectBox">
            <CustomSelectBox
              label="SLA"
              options={IS_SLA}
              value={metricData?.[FORM_KEY_MAP.SLA] || ''}
              styles={customStyles('100%')}
              onChange={(e) => {
                handleChange(FORM_KEY_MAP.SLA, e);
              }}
              isError={!!errorData?.[FORM_KEY_MAP.SLA]}
              errorMessage={errorData?.[FORM_KEY_MAP.SLA] || ''}
              id="EditMetricModal6SelectBox"
            />
          </Box>
        </Flex>

        <Flex mt="10px" gap="30px" data-testid="EditMetricModalTypeCSFlex">
          <Box flex={1} data-testid="EditMetricModalTypeCSBox">
            <CustomSelectBox
              label="Type"
              options={METRIC_TYPE}
              value={metricData?.[FORM_KEY_MAP.METRIC_TYPE] || ''}
              styles={customStyles('100%')}
              onChange={(e) => {
                handleChange(FORM_KEY_MAP.METRIC_TYPE, e);
              }}
              isError={!!errorData?.[FORM_KEY_MAP.METRIC_TYPE]}
              errorMessage={errorData?.[FORM_KEY_MAP.METRIC_TYPE] || ''}
              id="EditMetricModal7SelectBox"
            />
          </Box>
          <Box flex={1}>
            <CustomSelectBox
              label="Unit"
              options={UNIT}
              value={metricData?.[FORM_KEY_MAP.METRIC_UNIT] || ''}
              styles={customStyles('100%')}
              onChange={(e) => {
                handleChange(FORM_KEY_MAP.METRIC_UNIT, e);
              }}
              isError={!!errorData?.[FORM_KEY_MAP.METRIC_UNIT]}
              errorMessage={errorData?.[FORM_KEY_MAP.METRIC_UNIT] || ''}
              id="EditMetricModal8SelectBox"
            />
          </Box>
        </Flex>

        <Flex gap="30px" data-testid="EditMetricModalRenderQueueFlex">
          {[
            METRIC_TYPE_VALUE.STATE_TIME,
            METRIC_TYPE_VALUE.QUEUE_TIME,
          ].includes(metricData?.[FORM_KEY_MAP.METRIC_TYPE]?.value || '') ? (
            <Box mt="10px" flex={1}>
              {renderQueues}
            </Box>
          ) : null}
          <Box mt="10px" flex={1}>
            {renderExtraFields()}
          </Box>
        </Flex>

        {metricData?.[FORM_KEY_MAP.METRIC_TYPE]?.value ===
        METRIC_TYPE_VALUE.STATE_TIME ? (
          <Box mt="10px" data-testid="EditMetricModalStateContainerBox">
            <p className="SCLabel" data-testid="EditMetricModalStateTextP">
              {' '}
              States
{' '}
            </p>
            <div
              className="border border-[#E2E8F0] p-[15px] rounded-[6px]"
              data-testid="EditMetricModalStateContainerDiv"
            >
              <Flex
                alignItems="flex-end"
                gap="10px"
                data-testid="EditMetricModalStateSelectFlex"
              >
                <CustomSelectBox
                  label="Included State"
                  options={excludeData({
                    completeData: WORKFLOW_STATES,
                    selectedData: metricData?.[FORM_KEY_MAP.INCLUDED_STATES],
                  })}
                  value={metricData?.[FORM_KEY_MAP.SELECTED_STATE] || ''}
                  styles={customStyles()}
                  onChange={(e) => {
                    handleChange(FORM_KEY_MAP.SELECTED_STATE, e);
                  }}
                  id="EditMetricModalStateSelectStateSelect"
                />
                <CustomButton
                  buttonText="Add"
                  variant="outline"
                  style={{ height: 40 }}
                  color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
                  onClick={() => {
                    addDataForTags({
                      selectedFieldName: FORM_KEY_MAP.SELECTED_STATE,
                      listFieldName: FORM_KEY_MAP.INCLUDED_STATES,
                    });
                  }}
                  id="EditMetricModalStateSelectAddBtn"
                />
              </Flex>

              {errorData?.[FORM_KEY_MAP.INCLUDED_STATES] ? (
                <span className="field-error">
                  {errorData[FORM_KEY_MAP.INCLUDED_STATES]}
                </span>
              ) : null}

              <div
                className="mt-[10px] flex flex-wrap"
                data-testid="EditMetricModalRenderCategoryDiv"
              >
                <div
                  className="mr-[10px]"
                  data-testid="EditMetricModalRenderCtaegoryInnerDiv"
                >
                  {renderTagsCategory2({
                    key: FORM_KEY_MAP.INCLUDED_STATES,
                    data: metricData,
                    title: 'Included States',
                    deleteTags,
                    renderTagLabel: (data) => WORKFLOW_MAP[data],
                  })}
                </div>
                {metricData?.[FORM_KEY_MAP.INCLUDED_STATES] &&
                metricData[FORM_KEY_MAP.INCLUDED_STATES].length
                  ? renderTagsCategory2({
                      data: excludeData({
                        completeData: WORKFLOW_STATES,
                        selectedData:
                          metricData?.[FORM_KEY_MAP.INCLUDED_STATES],
                      }),
                      title: 'Excluded States',
                      colorScheme: 'red',
                      borderColor: 'red',
                      renderTagLabel: (data) => WORKFLOW_MAP[data.value],
                    })
                  : null}
              </div>
            </div>
          </Box>
        ) : null}

        {[
          METRIC_TYPE_VALUE.STATE_TIME,
          METRIC_TYPE_VALUE.ACTOR_TIME,
          METRIC_TYPE_VALUE.QUEUE_TIME,
        ].includes(metricData?.[FORM_KEY_MAP.METRIC_TYPE]?.value || '') ? (
          <Flex
            mt="10px"
            gap="30px"
            flexWrap="wrap"
            data-testid="EditMetricModalExitCustomContainerFlex"
          >
            <Box flex="1" data-testid="EditMetricModalExitCustomContainerBox">
              <div
                className="inline-flex items-end gap-[10px] w-full"
                data-testid="EditMetricModalExitCustomContainerDiv"
              >
                <Box
                  flex="1"
                  data-testid="EditMetricModalExitCustomContinerInnerBox"
                >
                  <CustomSelectBox
                    label="Exit Criteria (Move out of states)"
                    options={excludeData({
                      completeData: WORKFLOW_STATES,
                      selectedData:
                        metricData?.[FORM_KEY_MAP.EXIT_CRETERIA_MOVE_OUT],
                    })}
                    value={metricData?.[FORM_KEY_MAP.SELECTED_MOVE_OUT] || ''}
                    styles={customStyles('100%')}
                    onChange={(e) => {
                      handleChange(FORM_KEY_MAP.SELECTED_MOVE_OUT, e);
                    }}
                    id="EditMetricModalStateSelectExitSelect"
                  />
                </Box>
                <CustomButton
                  buttonText="Add"
                  variant="outline"
                  colorScheme="customBlue"
                  style={{ height: 40 }}
                  color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
                  onClick={() => {
                    addDataForTags({
                      selectedFieldName: FORM_KEY_MAP.SELECTED_MOVE_OUT,
                      listFieldName: FORM_KEY_MAP.EXIT_CRETERIA_MOVE_OUT,
                    });
                  }}
                  id="EditMetricModalStateSelectExitBtn"
                />
              </div>

              {errorData?.[FORM_KEY_MAP.EXIT_CRETERIA_MOVE_OUT] ? (
                <span className="field-error">
                  {errorData[FORM_KEY_MAP.EXIT_CRETERIA_MOVE_OUT]}
                </span>
              ) : null}

              <div
                className="mt-[10px]"
                data-testid="EditMetricModalMetricDataContainerDiv"
              >
                {renderTagsCategory2({
                  key: FORM_KEY_MAP.EXIT_CRETERIA_MOVE_OUT,
                  data: metricData,
                  title: 'Move Out',
                  renderTagLabel: (data) => WORKFLOW_MAP[data],
                  deleteTags,
                })}
              </div>
            </Box>
            <Box
              flex="1"
              data-testid="EditMetricModalCriteriaCustomContainerBox"
            >
              <div
                className="inline-flex items-end gap-[10px] w-full"
                data-testid="EditMetricModalCriteriaCustomContainerDiv"
              >
                <Box
                  flex="1"
                  data-testid="EditMetricModalCriteriaCustomContainerInnerBox"
                >
                  <CustomSelectBox
                    label="Exit Criteria (Move Into states)"
                    options={excludeData({
                      selectedData:
                        metricData?.[FORM_KEY_MAP.EXIT_CRETERIA_MOVE_IN],
                      completeData: WORKFLOW_STATES,
                    })}
                    value={metricData?.[FORM_KEY_MAP.SELECTED_MOVE_IN] || ''}
                    styles={customStyles('100%')}
                    onChange={(e) => {
                      handleChange(FORM_KEY_MAP.SELECTED_MOVE_IN, e);
                    }}
                    id="EditMetricModalStateSelectCriteriaSelect"
                  />
                </Box>
                <CustomButton
                  buttonText="Add"
                  variant="outline"
                  colorScheme="customBlue"
                  style={{ height: 40 }}
                  color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
                  onClick={() => {
                    addDataForTags({
                      selectedFieldName: FORM_KEY_MAP.SELECTED_MOVE_IN,
                      listFieldName: FORM_KEY_MAP.EXIT_CRETERIA_MOVE_IN,
                    });
                  }}
                  id="EditMetricModalStateSelectCriteriabtn"
                />
              </div>
              <div
                className="mt-[10px]"
                data-testid="EditMetricModalMoveInTextDiv"
              >
                {renderTagsCategory2({
                  key: FORM_KEY_MAP.EXIT_CRETERIA_MOVE_IN,
                  data: metricData,
                  title: 'Move In',
                  renderTagLabel: (data) => WORKFLOW_MAP[data],
                  deleteTags,
                })}
              </div>
            </Box>
          </Flex>
        ) : null}

        {[METRIC_TYPE_VALUE.CALCULATED_METRICS].includes(
          metricData?.[FORM_KEY_MAP.METRIC_TYPE]?.value || ''
        ) ? (
          <>
            <Box mt="20px" data-testid="EditMetricModalRefferedMetricsBox">
              <Box
                gap="30px"
                data-testid="EditMetricModalRefferedMetricsInnerBox"
                width="100%"
              >
                <Box
                  flex="1"
                  display="flex"
                  gap="10px"
                  alignItems="flex-end"
                  data-testid="EditMetricModalRefferedMetricsCSInnerBox"
                  width="100%"
                >
                  <div
                    className="flex-1"
                    data-testid="EditMetricModalRefferedMetricsInnerDiv"
                  >
                    <CustomSelectBox
                      label="Referred Metrics"
                      options={excludeData({
                        completeData: METRIC_FIELD_WITHOUT_REFERRED,
                        selectedData:
                          metricData?.[FORM_KEY_MAP.REFERRED_FIELDS],
                      })}
                      value={
                        metricData?.[FORM_KEY_MAP.SELECTED_REFERRED_FIELD] || ''
                      }
                      styles={customStyles('100%')}
                      onChange={(e) => {
                        handleChange(FORM_KEY_MAP.SELECTED_REFERRED_FIELD, e);
                      }}
                      id="EditMetricModalStateSelectMetricSelect"
                    />
                  </div>
                  <div data-testid="EditMetricModalRfferedMetricsButtonDiv">
                    <CustomButton
                      buttonText="Add"
                      variant="outline"
                      style={{ height: 38 }}
                      color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
                      onClick={() => {
                        addDataForTags({
                          selectedFieldName:
                            FORM_KEY_MAP.SELECTED_REFERRED_FIELD,
                          listFieldName: FORM_KEY_MAP.REFERRED_FIELDS,
                        });
                      }}
                      id="EditMetricModalStateSelectMetricsBtn"
                    />
                  </div>
                </Box>
                <Box flex="1" />
              </Box>

              {errorData?.[FORM_KEY_MAP.REFERRED_FIELDS] ? (
                <span className="field-error">
                  {errorData[FORM_KEY_MAP.REFERRED_FIELDS]}
                </span>
              ) : null}

              <Box mt="10px" data-testid="EditMetricModalRefferedFieldsBox">
                {renderTagsCategory2({
                  key: FORM_KEY_MAP.REFERRED_FIELDS,
                  data: metricData,
                  title: 'Referred Fields',
                  renderTagLabel: (data) => REFERRED_FIELD_MAP[data],
                  deleteTags,
                })}
              </Box>
            </Box>

            <Flex
              mt="20px"
              data-testid="EditMetricModalExpressionContainerFlex"
            >
              <Box flex="1" data-testid="EditMetricModalExpressionContainerBox">
                {/* <CustomInput
                label="Expression"
                value={
                  metricData?.[FORM_KEY_MAP.CALCULATED_METRICS_EXPRESSION] || ''
                }
                onChange={(e) => {
                  handleChange(
                    FORM_KEY_MAP.CALCULATED_METRICS_EXPRESSION,
                    e.target.value
                  );
                }}
                isError={
                  !!errorData?.[FORM_KEY_MAP.CALCULATED_METRICS_EXPRESSION]
                }
                errorMessage={
                  errorData?.[FORM_KEY_MAP.CALCULATED_METRICS_EXPRESSION] || ''
                }
                id="EditMetricModalZDivCInput"
              /> */}
                {(metricData[FORM_KEY_MAP.REFERRED_FIELDS]?.length || 0) >
                  0 && (
                  <CustomMention
                    label="Expression"
                    value={
                      metricData?.[
                        FORM_KEY_MAP.CALCULATED_METRICS_EXPRESSION
                      ] || ''
                    }
                    onChange={(e) => {
                      handleChange(
                        FORM_KEY_MAP.CALCULATED_METRICS_EXPRESSION,
                        e
                      );
                    }}
                    isError={
                      !!errorData?.[FORM_KEY_MAP.CALCULATED_METRICS_EXPRESSION]
                    }
                    errorMessage={
                      errorData?.[FORM_KEY_MAP.CALCULATED_METRICS_EXPRESSION] ||
                      ''
                    }
                    isMandatory={false}
                    id="DynamicRenderer6DivCInput"
                    isShowReferredMetric
                    onlyReferredMetric={[
                      ...(includeData({
                        completeData: REFERRED_METRIC_FIELDS,
                        selectedData:
                          metricData?.[FORM_KEY_MAP.REFERRED_FIELDS],
                      }) || []),
                    ].map((item) => ({ id: item.value, display: item.label }))}
                  />
                )}
              </Box>
              {/* <Box flex="1" /> */}
            </Flex>
          </>
        ) : null}
      </DrawerBody>
      <DrawerFooter>
        <Flex gap="3">
          <SecondaryCustomButton
            onClick={navigateBack}
            buttonText="Cancel"
            id="EditMetricModalStateSelectCancelBtn"
          />
          <PrimaryCustomButton
            buttonText={rowData?.id ? 'Edit Metric' : 'Add Metrics'}
            onClick={onSave}
            isLoading={isLoading}
            id="EditMetricModalStateSelectEditAddBtn"
          />
        </Flex>
      </DrawerFooter>
    </Box>
  );
};

EditMetricModal.propTypes = {
  rowData: PropTypes.object.isRequired,
  navigateBack: PropTypes.func.isRequired,
  isMetricUpdated: PropTypes.func.isRequired,
  onSaveMetric: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
};
EditMetricModal.defaultProps = {
  isLoading: false,
  setIsLoading: () => {},
};
export default EditMetricModal;
