import { ChakraProvider } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import LightTheme from './context/LightTheme';
import Routing from './Routing';
import { getTenantInformation } from './helpers/tenantUrl';
import Loader from './pages/DynamicRenderer/Dashboard/Loader';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  const getTenantInfo = async () => {
    await getTenantInformation();
    setIsLoading(false);
  };

  useEffect(() => {
    getTenantInfo();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ChakraProvider theme={LightTheme}>
      <div className="App" data-testid="AppDiv">
        <Routing />
      </div>
    </ChakraProvider>
  );
};

export default App;
