import TextIcon from '../../../assets/icons/icon-text.svg';
import LabelIcon from '../../../assets/icons/FieldLabelIcon.svg';
import EmailIcon from '../../../assets/icons/icon-email.svg';
import NumberIcon from '../../../assets/icons/icon-number.svg';
import RichTextIcon from '../../../assets/icons/icon-richtext.svg';
import DateIcon from '../../../assets/icons/Date.svg';
import MediaIcon from '../../../assets/icons/icon-media.svg';
import BooleanIcon from '../../../assets/icons/icon-boolean.svg';
// import FieldSetsIcon from '../../../assets/icons/icon-fieldsets.svg';
import TagIcon from '../../../assets/icons/icon-tags.svg';
import DropdownIcon from '../../../assets/icons/icon-dropdown.svg';
import TextAreaIcon from '../../../assets/icons/TextAreaFields.svg';
import { FieldTypes } from '../../../utils/enums/types';
import {
  LABELS,
  getFieldLabel,
  FIELD_PROPERTY_LABELS,
} from '../../../utils/enums/labels';
import { checkForV2 } from '../../../utils/helper';

// Order defined as per: https://actionablescience.atlassian.net/browse/DRI-492
// eslint-disable-next-line import/prefer-default-export
export const FIELDS = [
  {
    image: TextIcon,
    label: getFieldLabel(FieldTypes.TEXT),
    type: FieldTypes.TEXT,
    description: LABELS.FIELD_DESCRIPTIONS.TEXT,
    show: true,
  },
  {
    image: DropdownIcon,
    label: FIELD_PROPERTY_LABELS.DROPDOWN,
    type: FieldTypes.DROPDOWN,
    description: LABELS.FIELD_DESCRIPTIONS.DROPDOWN,
    isDisabled: false,
    show: true,
  },
  {
    image: RichTextIcon,
    label: getFieldLabel(FieldTypes.RICH_TEXT),
    type: FieldTypes.RICH_TEXT,
    description: LABELS.FIELD_DESCRIPTIONS.RICH_TEXT,
    show: true,
  },
  {
    image: LabelIcon,
    label: getFieldLabel(FieldTypes.LABEL),
    type: FieldTypes.LABEL,
    description: LABELS.FIELD_DESCRIPTIONS.LABEL,
    show: !checkForV2(),
  },
  {
    image: MediaIcon,
    label: getFieldLabel(FieldTypes.MEDIA),
    type: FieldTypes.MEDIA,
    description: LABELS.FIELD_DESCRIPTIONS.MEDIA,
    show: true,
  },
  {
    image: DateIcon,
    label: getFieldLabel(FieldTypes.DATE),
    type: FieldTypes.DATE,
    description: LABELS.FIELD_DESCRIPTIONS.DATE,
    show: true,
  },
  {
    image: NumberIcon,
    label: getFieldLabel(FieldTypes.NUMBER),
    type: FieldTypes.NUMBER,
    description: LABELS.FIELD_DESCRIPTIONS.NUMBER,
    show: checkForV2(),
  },
  {
    image: TextAreaIcon,
    label: getFieldLabel(FieldTypes.TEXT_AREA),
    type: FieldTypes.TEXT_AREA,
    description: LABELS.FIELD_DESCRIPTIONS.TEXT_AREA,
    isDisabled: false,
    show: true,
  },
  {
    image: BooleanIcon,
    label: getFieldLabel(FieldTypes.BOOLEAN),
    type: FieldTypes.BOOLEAN,
    description: LABELS.FIELD_DESCRIPTIONS.BOOLEAN,
    show: checkForV2(),
  },
  {
    image: TagIcon,
    label: getFieldLabel(FieldTypes.TAGS),
    type: FieldTypes.TAGS,
    description: LABELS.FIELD_DESCRIPTIONS.TAGS,
    isDisabled: false,
    show: checkForV2(),
  },
  {
    image: EmailIcon,
    label: FIELD_PROPERTY_LABELS.EMAIL,
    type: FieldTypes.EMAIL,
    description: LABELS.FIELD_DESCRIPTIONS.MAIL,
    show: checkForV2(),
  },

  // {
  //   image: FieldSetsIcon,
  //   label: getFieldLabel(FieldTypes.FIELDSETS),
  //   type: FieldTypes.FIELDSETS,
  //   description: LABELS.FIELD_DESCRIPTIONS.FIELDSETS,
  //   isDisabled: false,
  //   show: checkForV2(),
  // },
].filter((i) => i.show);
