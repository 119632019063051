/* eslint-disable consistent-return */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import _ from 'lodash';
import CustomSelectBox from '../../components/SelectBox/Select';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import ReactSelectViewTheme from '../../context/CustomTheme/ReactSelectViewTheme';

const SelectView = ({ name, value, options, parentName, label, ...rest }) => {
  const { viewState } = useSelector((state) => state);
  const dispatch = useDispatch();
  const getValue = () => {
    const stateValue = get(viewState, `${parentName}.${value}`);
    if (typeof stateValue === 'string') {
      const option = _.find(options, { value: stateValue });
      return option ?? '';
    }
    return stateValue ?? '';
  };
  return (
    <CustomSelectBox
      {...rest}
      value={getValue()}
      options={options}
      label={label}
      onChange={(val) => {
        dispatch(
          updateViewState({
            stateKey: parentName,
            value: { ...viewState[parentName], [value]: val.value },
          })
        );
      }}
      styles={ReactSelectViewTheme}
      id="DynamicSelectViewBox"
    />
  );
};

export default SelectView;
