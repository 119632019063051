import { createSlice } from '@reduxjs/toolkit';

const filterDatesSlice = createSlice({
  name: 'filterDates',
  initialState: [],
  reducers: {
    addDateRange: (state, action) => {
      state.push(action.payload);
    },
    removeDateRange: (state, action) => {
      state.splice(action.payload, 1);
    },
  },
});

export const { addDateRange, removeDateRange } = filterDatesSlice.actions;

export default filterDatesSlice.reducer;
