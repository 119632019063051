import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import CustomModal from '../../../components/Modal/Modal';
import CustomSelectBox from '../../../components/SelectBox/Select';
import CustomButton from '../../../components/Button/SimpleButton';
import Loader from '../Dashboard/Loader';
import TableViewWithSearch from '../../../components/Table/TableWithSearch';

import {
  FORM_MAP,
  associateBy,
  columns,
  enrichResponse,
  getAllTemplates,
  getFieldData,
} from './childTemplates.utils';
import { BLACK, DARK, LIGHT, WHITE } from '../../../utils/enums/colors';
import { childTemplateValidations } from '../../../utils/validationSchemas/templates/childTemplates';
import { TemplateTypes } from '../../../utils/enums/types';
import { updateViewState } from '../../../redux/ViewSlice/ViewReducer';
import { LifecycleStatusTypes } from '../../../utils/enums/lifecycleStatus';
import { FIELD_DESCRIPTION_TEXTS } from '../../../utils/enums/labels';
import { patchDataForTemplate } from '../../TicketTemplateBuilder/utils/templateAPIUtils';

const ChildTemplates = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedTemplate = useSelector(
    (state) => state?.viewState?.selectedTemplate
  );

  const { data: templateFields } = useSelector(
    (state) => state?.viewState?.ticket_template_fields_list_view || {}
  );

  const childTemplatesList = useMemo(
    () => selectedTemplate?.properties?.child_templates_list,
    [selectedTemplate]
  );

  const childTemplates = useMemo(
    () => selectedTemplate?.properties?.child_templates,
    [selectedTemplate]
  );

  // To save the data, instead of using the state
  const childTemplatesData = useMemo(
    () => ({
      childTemplates: [],
      childTemplateMap: {},
      selectedChildTemplates: [],
      fieldsData: {},
      fieldIdMap: {},
      isApprovedTemplatesApiDone: false,
    }),
    []
  );

  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [approvedTemplateMap, setApprovedTemplateMap] = useState({});

  const onNavigate = useCallback(
    (templateId, templateName) => {
      navigate(`/template-makers/template/${templateId}?name=${templateName}`);
    },
    [navigate]
  );

  const getTemplatesFieldData = useCallback(async (templateIds) => {
    setLoading(true);
    let filteredTemplateIds = templateIds.filter(
      (t) => !childTemplatesData.fieldsData[t]
    );

    filteredTemplateIds = filteredTemplateIds.filter((t) => {
      const isAvailable = childTemplatesData.childTemplateMap[t];
      if (isAvailable) {
        const template = childTemplatesData.childTemplateMap[t];
        const enrichedResponse = enrichResponse([
          {
            fields: template?.fields || [],
            id: template?.value || '',
            name: template?.label || '',
          },
        ]);

        // Storing the templateId => templateFields map
        childTemplatesData.fieldsData = {
          ...childTemplatesData.fieldsData,
          ...(enrichedResponse?.fieldsData || {}),
        };

        // Storing the fieldId => fieldLabel map
        childTemplatesData.fieldIdMap = {
          ...childTemplatesData.fieldIdMap,
          ...(enrichedResponse?.fieldIdMap || {}),
        };
      }
      return !isAvailable;
    });

    const res = await getFieldData(filteredTemplateIds);

    // Resetting the selected fields
    childTemplatesData.selectedChildTemplates = [];

    // Storing the templateId => templateFields map
    childTemplatesData.fieldsData = {
      ...childTemplatesData.fieldsData,
      ...(res?.fieldsData || {}),
    };

    // Storing the fieldId => fieldLabel map
    childTemplatesData.fieldIdMap = {
      ...childTemplatesData.fieldIdMap,
      ...(res?.fieldIdMap || {}),
    };
  }, []);

  const getApprovedTemplates = useCallback(async () => {
    // To get all the child templates
    let allTemplates = await getAllTemplates();

    if (!Array.isArray(allTemplates)) {
      allTemplates = [];
    }

    const childTemplatesWithoutParent = allTemplates
      ?.filter(
        (template) =>
          (template?.properties?.parent_id === null ||
            template?.properties?.parent_id === id) &&
          template.id !== id
      )
      ?.map((x) => ({
        value: x?.id,
        label: x?.name,
        fields: x?.fields,
        lifeCycle: x?.lifecycle_status,
      }));

    childTemplatesData.childTemplateMap = associateBy(
      childTemplatesWithoutParent,
      'value'
    );

    childTemplatesData.childTemplates = childTemplatesWithoutParent;
    childTemplatesData.isApprovedTemplatesApiDone = true;

    setApprovedTemplateMap(childTemplatesData.childTemplateMap);
    setLoading(false);
  }, []);

  useEffect(() => {
    const getInitialData = async () => {
      if (!childTemplatesData.isApprovedTemplatesApiDone) {
        setLoading(true);
        await getApprovedTemplates();
      }

      const templateIds = (childTemplates || []).map(
        (v) => v?.[FORM_MAP.CHILD_TEMPLATE_ID]
      );
      await getTemplatesFieldData(templateIds);
      setTableData(childTemplates || []);
      setLoading(false);
    };

    if (childTemplates) {
      getInitialData();
    } else {
      setTableData([]);
    }
  }, [childTemplates, childTemplatesList]);

  // To open the Modal when clicked on Create New button
  const onCreateNew = useCallback(() => {
    setOpenModal(true);
  }, []);

  // For filtering the table data
  const filterData = (val) => {
    if (!val) {
      return tableData;
    }

    const filteredData = tableData.filter((ct) =>
      (
        childTemplatesData.childTemplateMap[ct?.[FORM_MAP.CHILD_TEMPLATE_ID]]
          ?.label || ''
      )
        .toLowerCase()
        .includes(val.toLowerCase())
    );

    return filteredData;
  };

  // On editing the table data
  const onEdit = ({ column, value, subColumn = '', rowId }) => {
    const tempData = cloneDeep(tableData);
    const rowIndex = tempData.findIndex((c) => c.id === rowId);
    if (subColumn) {
      tempData[rowIndex][column] = {
        ...(tempData?.[rowIndex]?.[column] || {}),
        [subColumn]: value,
      };
    } else {
      tempData[rowIndex][column] = value;
    }
    if (error) {
      setError('');
    }
    setTableData(tempData);
  };

  // On Deleting the table row
  const onDelete = (rowId) => {
    const tempData = cloneDeep(tableData);
    const rowIndex = tempData.findIndex((c) => c.id === rowId);
    tempData.splice(rowIndex, 1);
    setTableData(tempData);
  };

  // When we save the data
  const onSave = async () => {
    const { error: validationError } = childTemplateValidations.validate(
      tableData,
      { abortEarly: false }
    );

    if (validationError) {
      setError('All fields are mandatory');
      return;
    }

    const data = {
      ...(selectedTemplate?.properties || {}),
      template_type: TemplateTypes.NESTED,
      child_templates: tableData,
      child_templates_list: tableData.map(
        (td) => `${td[FORM_MAP.CHILD_TEMPLATE_ID]}`
      ),
    };

    const res = await patchDataForTemplate({
      id,
      data,
      key: 'properties',
      successMessage: 'Successfully saved child templates',
      defaultErrorMessage: 'Failed to save the child templates',
    });
    if (!res.error) {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: {
            ...selectedTemplate,
            ...res?.response?.data || {},
            properties: data,
          },
        })
      );

      // Fetching the tree view
      dispatch(
        updateViewState({
          stateKey: 'ticket_template_list_short_view',
          value: {
            refresh: uuidv4(),
          },
        })
      );
    }
  };

  // To get the fields data on selecting the templates
  const getChildTemplateFieldsData = useCallback(async (values) => {
    const templateIds = values.map((v) => v.value);

    await getTemplatesFieldData(templateIds);

    const formatTableData = templateIds.map((i) => ({
      [FORM_MAP.CHILD_TEMPLATE_ID]: i,
    }));

    // Adding the selected child templates to the tree data
    setTableData((prev) => [...prev, ...(formatTableData || [])]);
    setLoading(false);
  }, []);

  // To get the unused child templates
  const unusedTemplates = useMemo(() => {
    const usedChildIds = tableData
      .map((td) => td?.[FORM_MAP.CHILD_TEMPLATE_ID])
      .reduce((prev, curr) => {
        prev[curr] = true;
        return prev;
      }, {});

    return childTemplatesData.childTemplates.filter(
      (ct) => !usedChildIds[ct.value]
    );
  }, [childTemplatesData.childTemplates, tableData.length]);

  // Modal Content to select the templates
  const modalContent = useMemo(
    () => (
      <CustomSelectBox
        label="Select Child Template(s)"
        options={unusedTemplates}
        isMulti
        onChange={(value) => {
          childTemplatesData.selectedChildTemplates = value;
        }}
        focusBorderColor={useColorModeValue(LIGHT, DARK)}
        id="ChildTemplatesCustomSelectBox"
      />
    ),
    [unusedTemplates]
  );

  const modalFooter = useMemo(
    () => (
      <Box>
        <CustomButton
          color={useColorModeValue(BLACK, WHITE)}
          onClick={() => {
            setOpenModal(false);
          }}
          buttonText="Cancel"
          variant="outline"
          className="mr-4"
          id="ChildTemplatesCancelBtn"
        />
        <CustomButton
          color="white"
          onClick={() => {
            getChildTemplateFieldsData(
              childTemplatesData.selectedChildTemplates
            );
            setOpenModal(false);
          }}
          buttonText="Continue"
          variant="solid"
          bg={useColorModeValue(LIGHT, DARK)}
          m="16px 0"
          p="0px 16px"
          fontSize="16px"
          id="ChildTemplatesContinueBtn"
        />
      </Box>
    ),
    []
  );

  const doesChildHaveUnApprovedTemplates = useMemo(() => {
    const templateIds = tableData?.map((t) => t?.[FORM_MAP.CHILD_TEMPLATE_ID]);
    return templateIds?.some(
      (t) =>
        childTemplatesData.childTemplateMap[t]?.lifeCycle !==
        LifecycleStatusTypes.APPROVED
    );
  }, [tableData?.length]);

  return (
    <Box mt="20px" mr="1rem" data-testid="ChildTemplatesContainerBox">
      <Text
        fontSize="20px"
        fontWeight={600}
        data-testid="ChildTemplatesHeaderText"
      >
        Child Templates
      </Text>

      {loading ? (
        <Loader height="50vh" data-testid="ChildTemplatesLoader" />
      ) : (
        <>
          <Box mt="20px" data-testid="ChildTemplatesInnerBox">
            <CustomModal
              size="xl"
              open={openModal}
              setOpen={setOpenModal}
              modalTitle="Add Child Template(s)"
              modalContent={modalContent}
              modalFooter={modalFooter}
              id="childTemplateZmodal"
            />

            {selectedTemplate?.lifecycle_status !==
              LifecycleStatusTypes.APPROVED &&
            doesChildHaveUnApprovedTemplates ? (
              <Alert
                status="info"
                mt="8"
                mb="8"
                borderRadius="4px"
                data-testid="ChildTemplatesAlert"
              >
                <AlertIcon />
                {FIELD_DESCRIPTION_TEXTS.PARENT_CHILD_INFO}
              </Alert>
            ) : null}

            <TableViewWithSearch
              placeholder="Search For Child Template"
              buttonText="Add Child Template"
              onCreateNew={onCreateNew}
              columns={columns({
                childData: childTemplatesData,
                approvedTemplateMap,
                onEdit,
                onDelete,
                onNavigate,
                templateFields: templateFields || selectedTemplate?.fields,
              })}
              tableData={tableData}
              filterTableData={filterData}
            />

            {error ? <span className="field-error">{error}</span> : null}
          </Box>

          <Flex
            justifyContent="flex-end"
            data-testid="ChildTemplatesButtonFlex"
          >
            <CustomButton
              buttonText="Save"
              variant="solid"
              onClick={onSave}
              style={{
                backgroundColor: useColorModeValue(LIGHT, DARK),
                height: 40,
                color: '#fff',
              }}
              id="ChildTemplatesSaveBtn"
            />
          </Flex>
        </>
      )}
    </Box>
  );
};

export default ChildTemplates;
