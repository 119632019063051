/* eslint-disable react/prop-types */
import { DeleteIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { forEach, join } from 'lodash';

import { useSelector } from 'react-redux';
import CustomSelectBox from '../../components/SelectBox/Select';
import { getWholeOption } from '../TicketTemplateWorkflow/helper';
import { FieldTypes } from '../../utils/enums/types';
import {
  mediaFormatSelectOpt2,
  mediaFormatSelectOpt2FileType,
} from '../../utils/enums/selectOption';
import TextBadge from './TextBadge';
import AttachmentUpload from '../../components/AttachmentUpload';
import { parserForUploadAttachment } from './quickAction.constants';

const WrapperForAttachment = ({
  label,
  onDelete,
  values,
  onUpdatedValue,
  onUpdatedFieldId,
}) => {
  const { selectedTemplate } = useSelector((state) => state.viewState || {});

  const MEDIA_FIELD_OPTION = [...(selectedTemplate?.fields || [])]
    .filter((item) => item.type === FieldTypes.MEDIA)
    .map((field) => ({ value: field.id, label: field.label }));
  const SELECTED_MEDIA_FIELD_DETAIL = useMemo(
    () =>
      [...(selectedTemplate?.fields || [])].find(
        (item) => item.id === values.fieldId
      ),
    [values.fieldId]
  );
  const showSupportedType = () => {
    const selectedOptions = mediaFormatSelectOpt2.filter((option) =>
      SELECTED_MEDIA_FIELD_DETAIL?.type_based_attributes?.media_type?.includes(
        option.value
      )
    );
    const selectedOptionsFileType = [];
    forEach(selectedOptions, (option) => {
      selectedOptionsFileType.push(
        ...mediaFormatSelectOpt2FileType[option.value]
      );
    });
    const fileTypeString = join(selectedOptionsFileType, ',');
    return { fileTypeString, fileTypeList: selectedOptionsFileType };
  };
  return (
    <Box
      border="1px solid #D1D5DB"
      pl="16px"
      pr="16px"
      borderRadius="4px"
      mb="15px"
    >
      <Flex justifyContent="space-between" pt="16px">
        <Text className="SCLabel">{label}</Text>
        <DeleteIcon color="red" className="cursor-pointer" onClick={onDelete} />
      </Flex>
      <Box mb="15px">
        <Box mb="15px" mt="15px">
          <CustomSelectBox
            value={getWholeOption(values.fieldId, MEDIA_FIELD_OPTION)}
            options={MEDIA_FIELD_OPTION}
            onChange={({ value }) => {
              onUpdatedFieldId(value);
            }}
            size="md"
            style={{ position: 'absolute', left: 30 }}
          />
        </Box>
        {SELECTED_MEDIA_FIELD_DETAIL && (
          <Flex gap="10px" wrap="wrap">
            <TextBadge
              message={`Max no. of attachments : ${SELECTED_MEDIA_FIELD_DETAIL.type_based_attributes.maximum_number_attachment}`}
            />
            <TextBadge
              message={`Max File Size : ${SELECTED_MEDIA_FIELD_DETAIL.type_based_attributes.media_size}`}
            />
            <TextBadge
              message={`Supported Formats: ${
                showSupportedType().fileTypeString
              }`}
            />
          </Flex>
        )}
        {SELECTED_MEDIA_FIELD_DETAIL && (
          <AttachmentUpload
            key={values.list_data}
            blobName={values?.list_data?.map((item) => item.file_name) || []}
            onImageUpload={(data) => {
              onUpdatedValue(parserForUploadAttachment(data));
            }}
            onImageDelete={(data) => onUpdatedValue(data)}
            fileSupportForUpload={showSupportedType().fileTypeList}
            maxFileSize={
              +SELECTED_MEDIA_FIELD_DETAIL.type_based_attributes.media_size *
              1024 *
              1024
            }
            maxNoOfFileAllow={
              +SELECTED_MEDIA_FIELD_DETAIL.type_based_attributes
                .maximum_number_attachment
            }
          />
        )}
      </Box>
    </Box>
  );
};

export default WrapperForAttachment;
