import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import CustomSelect from '../../../components/SelectBox/Select';
import { getFields } from '../preDefinedPermissions/preDefinedPermissions.helper';
import { CHANNEL_VALUES } from '../customTemplateModal/CustomTemplateModalLayout';
import { FIELD_VALUES, FIELD_LABELS } from './emailAndBotMapping.constant';

// eslint-disable-next-line react/prop-types
const FieldMapping = ({ onChange, type, mapping }) => {
  const viewState = useSelector((state) => state.viewState);

  return (
    <Box
      data-testid="EmailAndBotMappingContainerDiv"
      p="15px"
      border="1px solid #E2E3E4"
      borderRadius="6px"
    >
      <Flex>
        <Box width="100px" mr={40} fontWeight={600} mt={2}>
          {FIELD_LABELS.subject}
        </Box>
        <Flex
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          gap="20px"
          mb="30px"
          flexWrap="wrap"
          data-testid="mapping_select_field"
        >
          <CustomSelect
            placeholder="Select Field"
            options={getFields(viewState, mapping?.body || '')}
            value={mapping?.subject}
            onChange={(val) => onChange(val, type, FIELD_VALUES.subject)}
          />
        </Flex>
      </Flex>
      <Flex>
        <Box width="100px" mr={40} fontWeight={600} mt={2}>
          {FIELD_LABELS.body}
        </Box>
        <Flex
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          gap="20px"
          mb="30px"
          flexWrap="wrap"
          data-testid="mapping_select_field"
        >
          <CustomSelect
            placeholder="Select Field"
            value={mapping?.body}
            options={getFields(viewState, mapping?.subject || '')}
            onChange={(val) => onChange(val, type, FIELD_VALUES.body)}
          />
        </Flex>
      </Flex>
      {type === CHANNEL_VALUES.email && (
        <Flex>
          <Box width="100px" mr={40} fontWeight={600} mt={2}>
            {FIELD_LABELS.attachment}
          </Box>
          <Flex
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            gap="20px"
            mb="30px"
            flexWrap="wrap"
            data-testid="mapping_select_field"
          >
            <CustomSelect
              placeholder="Select Field"
              value={mapping?.attachment}
              options={getFields(viewState, '', 'MEDIA')}
              onChange={(val) => onChange(val, type, FIELD_VALUES.attachment)}
            />
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default FieldMapping;
