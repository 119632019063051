// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.T_9bbdhzjpzJz6ILqSqg{border:1px solid #e2e3e4;border-radius:8px;padding:10px 0}.T_9bbdhzjpzJz6ILqSqg .carousel__container{gap:1rem;padding:0 10px}.T_9bbdhzjpzJz6ILqSqg .prev__button{border-right:1px solid #e2e3e4;border-top-left-radius:8px;border-bottom-left-radius:8px;border-bottom-right-radius:0;border-top-right-radius:0}.T_9bbdhzjpzJz6ILqSqg .next__button{border-left:1px solid #e2e3e4;border-top-left-radius:0;border-bottom-left-radius:0;border-bottom-right-radius:8px;border-top-right-radius:8px}`, "",{"version":3,"sources":["webpack://./src/pages/TicketTemplateWorkflow/styles/workflowSlider.module.scss"],"names":[],"mappings":"AAEA,sBACE,wBAAA,CACA,iBAAA,CACA,cAAA,CAEF,2CACM,QAAA,CACA,cAAA,CAEN,oCACM,8BAAA,CACA,0BAZS,CAaT,6BAbS,CAcT,4BAAA,CACA,yBAAA,CAEN,oCACM,6BAAA,CACA,wBAAA,CACA,2BAAA,CACA,8BArBS,CAsBT,2BAtBS","sourcesContent":["$borderColor: #e2e3e4;\n$borderRadius: 8px;\n.carousel__container {\n  border: 1px solid $borderColor;\n  border-radius: 8px;\n  padding: 10px 0;\n}\n.carousel__container :global .carousel__container {\n      gap: 1rem;\n      padding: 0 10px;\n    }\n.carousel__container :global .prev__button {\n      border-right: 1px solid $borderColor;\n      border-top-left-radius: $borderRadius;\n      border-bottom-left-radius: $borderRadius;\n      border-bottom-right-radius: 0;\n      border-top-right-radius: 0;\n    }\n.carousel__container :global .next__button {\n      border-left: 1px solid $borderColor;\n      border-top-left-radius: 0;\n      border-bottom-left-radius: 0;\n      border-bottom-right-radius: $borderRadius;\n      border-top-right-radius: $borderRadius;\n    }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"carousel__container": `T_9bbdhzjpzJz6ILqSqg`
};
export default ___CSS_LOADER_EXPORT___;
