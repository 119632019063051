import React, { useEffect, useReducer, useState } from 'react';
import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import CustomButton from '../../../components/Button/SimpleButton';
import { setActiveModal } from '../../../redux/ModalReducer/ModalSlice';
import {
  makeApiCallForWorkflowList,
  reducerForState,
} from '../AddEditActorModal/helper';
import { dummyDefaultRole } from '../../TicketTemplateWorkflow/DummyData';
import CustomSelectBox from '../../../components/SelectBox/Select';
import customStyleFromRuleForSelect from '../../../context/CustomTheme/CustomStyleFromRuleForSelect';
import { getWholeOption } from '../../TicketTemplateWorkflow/helper';
import { updateSelectedWorkFlow } from '../../../redux/WorkFlowMainSlice/WorkFlowMainReducer';
import { associateWorkflow } from '../../../redux/ViewSlice/ViewReducer';
import { showToast } from '../../../utils/crud';

const ConnectWorkflowCard = () => {
  const [workflowState, dispatchWorkflow] = useReducer(reducerForState, {});
  const [workflowMasterListOptions, setworkflowMasterListOptions] = useState(
    []
  );
  const dispatch = useDispatch();
  const { id } = useParams();
  const fetchWorkflowListOption = async () => {
    const response = await makeApiCallForWorkflowList();
    const options = response?.map((item) => ({
      value: item.id,
      label: item.name,
      workflow_status: item.workflow_status,
      workflow_category: item.workflow_category,
      workflow_transitions: item.workflow_transitions,
    }));
    setworkflowMasterListOptions(options);
  };

  useEffect(() => {
    if (isEmpty(workflowMasterListOptions)) {
      fetchWorkflowListOption();
    }
  }, []);

  const configWorkflowInTemplate = async () => {
    if (!workflowState?.id || !workflowState?.name) {
      showToast({
        title: 'Workflow Configuration Failed',
        message: 'Please select a workflow',
        type: 'error'
      });
      return;
    }
    const workflowId = workflowState.id;
    delete workflowState.id;
    const newData = {
      ...workflowState,
      workflow_rules: { list: [] },
      updated_on: new Date().toISOString(),
    };
    dispatch(
      associateWorkflow({
        data: newData,
        id,
        key: 'workflow',
        successMessage: 'Workflow Configuration Successful',
        defaultErrorMessage: 'Failed to configure workflow',
      })
    );
    newData.id = workflowId;
    dispatch(updateSelectedWorkFlow(newData));
    dispatch(setActiveModal({ targetModalId: undefined }));
  };

  const handleWorkFlowSelect = (e) => {
    const newObj = {
      id: e.value,
      name: e.label,
      workflow_status: e.workflow_status,
      workflow_category: e.workflow_category,
      workflow_transitions: e.workflow_transitions,
    };
    dispatchWorkflow({
      type: 'SET_STATE',
      value: { ...newObj },
    });
  };

  const selectedWorkflow = getWholeOption(workflowState?.id, workflowMasterListOptions)
  
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      py="4%"
      w="100%"
    >
      <Box
        px="20px"
        py="25px"
        borderRadius="10px"
        border="1px solid #D1D5DB"
        width="570px"
        display="flex"
        flexDir="column"
        alignItems="center"
        bg={useColorModeValue('white', 'black')}
      >
        <Box
          p="15px"
          borderRadius="10px"
          border="1px solid #D1D5DB"
          display="inline-flex"
          mb="15px"
        >
          <ExclamationTriangleIcon color="red" width="25px" height="25px" />
        </Box>
        <Text
          textAlign="center"
          fontSize="16px"
          color="#6B7280"
          fontWeight="600"
          lineHeight="normal"
          pb="15px"
        >
          This template is not associated with any workflow. Please select work
          flow
        </Text>
        <Box width="100%" mb="15px">
          <CustomSelectBox
            styles={customStyleFromRuleForSelect}
            options={workflowMasterListOptions || []}
            value={selectedWorkflow}
            onChange={(e) => {
              handleWorkFlowSelect(e);
            }}
            placeholder="Select workflow"
            id="WorkFlowConfigSelectBox"
          />
        </Box>
        <CustomButton
          width="100%"
          mb="15px"
          px="13px"
          py="9px"
          bg="#2563EB"
          borderRadius="5px"
          fontSize="14px"
          color="#FFFFFF"
          fontWeight="500"
          lineHeight="normal"
          display="inline-flex"
          justifyContent="center"
          buttonText="Connect Workflow"
          isDisabled={!selectedWorkflow}
          onClick={async () => {
            await configWorkflowInTemplate();
          }}
          size="lg"
          id="WorkConfigModalConnectBtn"
        />
        <Text
          textAlign="center"
          fontSize="16px"
          color="#6B7280"
          fontWeight="600"
          lineHeight="normal"
          pb="15px"
        >
          Or
        </Text>
        <Box
          width="100%"
          mb="15px"
          px="13px"
          py="9px"
          borderRadius="5px"
          fontSize="14px"
          fontWeight="500"
          lineHeight="normal"
          display="inline-flex"
          justifyContent="center"
          border="1px solid #D1D5DB"
          cursor="pointer"
          onClick={() => {
            dispatch(
              setActiveModal({ targetModalId: 'addWorkFlowInListModal' })
            );
          }}
        >
          Create New Workflow
        </Box>
      </Box>
    </Box>
  );
};
export default ConnectWorkflowCard;
