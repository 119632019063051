/* eslint-disable no-eval */
import Joi from 'joi';

import { stringRequiredValidation, stringRequiredValidationMethod } from '../validationSchemas';
import {
  FORM_KEY_MAP,
  METRIC_TYPE_VALUE,
} from '../../../pages/DynamicRenderer/MetricsAndSLA/Metrics/utils';
import { SLA_FORM_KEY_MAP } from '../../../pages/DynamicRenderer/MetricsAndSLA/SLA/utils';
import { checkForV2 } from '../../helper';

const customMathExpressionValidation = (value, helpers) => {
  const uuidRegex = /\b[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}\b/gi;
  const replaceUUIDWithZero = value.replace(uuidRegex, '0');
  const mathExpressionPattern = /^[0-9+\-*/().\s]+$/;
  if (typeof value !== 'string' || value.trim() === '' || value === '' || value === undefined) {
    return helpers.message('The field must be a non-empty string');
  }
  try {
    if (!mathExpressionPattern.test(replaceUUIDWithZero)) {
      return helpers.message('The field must be a valid mathematical expression.');
    }
    eval(replaceUUIDWithZero);
  } catch (e) {
    return helpers.message('The field must be a valid mathematical expression.');
  }
  return value; // If validation passes, return the value
};
export const SLA_VALIDATIONS = Joi.object({
  [SLA_FORM_KEY_MAP.NAME]: stringRequiredValidation,
  [SLA_FORM_KEY_MAP.GOAL]: Joi.number().positive().required().messages({
    'number.base': 'Field must be a number.',
    'number.positive': 'Field must be greater than 0.',
    'any.required': 'Field is required.',
  }),
  [SLA_FORM_KEY_MAP.ATTRIBUTES_CONTAINER]: Joi.array()
    .min(1)
    .required()
    .messages({
      'array.min': 'Select at least one attribute.',
      'any.required': 'Select at least one attribute.',
    }),
  [SLA_FORM_KEY_MAP.FREQUENCY]: Joi.number().positive().required().messages({
    'number.base': 'Frequency must be a number.',
    'number.positive': 'Frequency must be greater than 0.',
    'any.required': 'Frequency is required.',
  }),
  [SLA_FORM_KEY_MAP.REMAINDER]: Joi.array()
    .items(Joi.number().greater(0).less(101))
    .min(1)
    .required()
    .messages({
      'array.min': 'Add at least one reminder',
      'any.required': 'Add at least one reminder',
      'number.greater': 'The reminders must be greater than 0.',
      'number.less': 'The reminders must be less than or equal to 100.',
    }),
  [SLA_FORM_KEY_MAP.OUTCOME]: Joi.array().min(1).required().messages({
    'array.min': 'Add at least one outcome',
    'any.required': 'Add at least one outcome',
  }),
}).unknown();

export const METRICS_VALIDATIONS = Joi.object({
  [FORM_KEY_MAP.NAME]: stringRequiredValidationMethod('Name'),
  [FORM_KEY_MAP.METRIC_TYPE]: stringRequiredValidationMethod('Type'),
  [FORM_KEY_MAP.METRIC_UNIT]: stringRequiredValidationMethod('Unit'),

  // Validations for the State Time
  [FORM_KEY_MAP.INCLUDED_STATES]: Joi.alternatives().conditional(
    FORM_KEY_MAP.METRIC_TYPE,
    {
      is: METRIC_TYPE_VALUE.STATE_TIME,
      then: Joi.array().min(1).required().messages({
        'array.min': 'Include atleast one state',
        'any.required': 'Include at least one state',
      }),
      otherwise: Joi.any(),
    }
  ),
  // [FORM_KEY_MAP.QUEUE]: Joi.alternatives().conditional(
  //   FORM_KEY_MAP.METRIC_TYPE,
  //   {
  //     is: [METRIC_TYPE_VALUE.STATE_TIME, METRIC_TYPE_VALUE.QUEUE_TIME],
  //     then: stringRequiredValidation,
  //     otherwise: Joi.any(),
  //   }
  // ),
  [FORM_KEY_MAP.EXIT_CRETERIA_MOVE_OUT]: Joi.alternatives().conditional(
    FORM_KEY_MAP.METRIC_TYPE,
    {
      is: [METRIC_TYPE_VALUE.STATE_TIME, METRIC_TYPE_VALUE.ACTOR_TIME],
      then: Joi.array().min(1).required().messages({
        'array.min': 'Include atleast one state',
        'any.required': 'Include at least one state',
      }),
      otherwise: Joi.any(),
    }
  ),

  // Validations for Log time
  [FORM_KEY_MAP.ACTOR]: Joi.alternatives().conditional(
    FORM_KEY_MAP.METRIC_TYPE,
    {
      is: [METRIC_TYPE_VALUE.LOG_TIME, METRIC_TYPE_VALUE.ACTOR_TIME],
      then: checkForV2() ? stringRequiredValidation : Joi.any(),
      otherwise: Joi.any(),
    }
  ),
  // [FORM_KEY_MAP.LOG_TIME_FIELD]: Joi.alternatives().conditional(
  //   FORM_KEY_MAP.METRIC_TYPE,
  //   {
  //     is: METRIC_TYPE_VALUE.LOG_TIME,
  //     then: stringRequiredValidation,
  //     otherwise: Joi.any(),
  //   }
  // ),

  // Validations for FRT
  [FORM_KEY_MAP.FIELDS]: Joi.alternatives().conditional(
    FORM_KEY_MAP.METRIC_TYPE,
    {
      is: METRIC_TYPE_VALUE.FIRST_RESPONSE_TIME,
      then: Joi.array().min(1).required().messages({
        'array.min': 'Field is required',
        'any.required': 'Field is required',
      }),
      otherwise: Joi.any(),
    }
  ),

  // Validations for Calculated Metrics
  [FORM_KEY_MAP.CALCULATED_METRICS_EXPRESSION]: Joi.alternatives().conditional(
    FORM_KEY_MAP.METRIC_TYPE,
    {
      is: METRIC_TYPE_VALUE.CALCULATED_METRICS,
      then: Joi.string().custom(customMathExpressionValidation, 'mathematical expression validation').required().messages({
        'string.empty': 'This field is required',
        'any.required': 'This field is required.',
      }),
      otherwise: Joi.any(),
    }
  ),
  [FORM_KEY_MAP.REFERRED_FIELDS]: Joi.alternatives().conditional(
    FORM_KEY_MAP.METRIC_TYPE,
    {
      is: METRIC_TYPE_VALUE.CALCULATED_METRICS,
      then: Joi.array().min(1).required().messages({
        'array.min': 'Select atleast one field',
        'any.required': 'Select at least one field est---',
      }),
      otherwise: Joi.any(),
    }
  ),
}).unknown();

export const LIST_SLA_VALIDATIONS = Joi.array().items(
  Joi.object({
    [SLA_FORM_KEY_MAP.NAME]: stringRequiredValidation,
    // [SLA_FORM_KEY_MAP.EMAIL_TEMPLATE_ID]: stringRequiredValidation,
    [SLA_FORM_KEY_MAP.GOAL]: Joi.number().positive().required().messages({
      'number.base': 'Field must be a number.',
      'number.positive': 'Field must be greater than 0.',
      'any.required': 'Field is required.',
    }),
    // This validation is not required for single SLAs
    // [SLA_FORM_KEY_MAP.ATTRIBUTES_CONTAINER]: Joi.array()
    //   .min(1)
    //   .required()
    //   .messages({
    //     'array.min': 'Select at least one attribute.',
    //     'any.required': 'Select at least one attribute.',
    //   }),
    // [SLA_FORM_KEY_MAP.FREQUENCY]: Joi.number().positive().required().messages({
    //   'number.base': 'Frequency must be a number.',
    //   'number.positive': 'Frequency must be greater than 0.',
    //   'any.required': 'Frequency is required.',
    // }),
    // [SLA_FORM_KEY_MAP.REMAINDER]: Joi.array()
    //   .items(Joi.number().greater(0).less(101))
    //   .min(1)
    //   .required()
    //   .messages({
    //     'array.min': 'Add at least one reminder',
    //     'any.required': 'Add at least one reminder',
    //     'number.greater': 'The reminders must be greater than 0.',
    //     'number.less': 'The reminders must be less than or equal to 100.',
    //   }),
    [SLA_FORM_KEY_MAP.OUTCOME]: Joi.array().min(2).required().messages({
      'array.min': 'Add at least two outcomes',
      'any.required': 'Add at least two outcomes',
    }),
  }).unknown()
).min(1);

export const LIST_SLA_VALIDATIONS_MULTI_SLA = Joi.array().items(
  Joi.object({
    [SLA_FORM_KEY_MAP.NAME]: stringRequiredValidation,
    // [SLA_FORM_KEY_MAP.EMAIL_TEMPLATE_ID]: stringRequiredValidation,
    [SLA_FORM_KEY_MAP.GOAL]: Joi.number().positive().required().messages({
      'number.base': 'Field must be a number.',
      'number.positive': 'Field must be greater than 0.',
      'any.required': 'Field is required.',
    }),
    // [SLA_FORM_KEY_MAP.ATTRIBUTES_CONTAINER]: Joi.object({
    //   and: Joi.array()
    //     .messages({
    //       'array.min': 'Select at least one attribute.',
    //       'any.required': 'Select at least one attribute.',
    //     })
    // }),
    // [SLA_FORM_KEY_MAP.FREQUENCY]: Joi.number().positive().required().messages({
    //   'number.base': 'Frequency must be a number.',
    //   'number.positive': 'Frequency must be greater than 0.',
    //   'any.required': 'Frequency is required.',
    // }),
    // [SLA_FORM_KEY_MAP.REMAINDER]: Joi.array()
    //   .items(Joi.number().greater(0).less(101))
    //   .min(1)
    //   .required()
    //   .messages({
    //     'array.min': 'Add at least one reminder',
    //     'any.required': 'Add at least one reminder',
    //     'number.greater': 'The reminders must be greater than 0.',
    //     'number.less': 'The reminders must be less than or equal to 100.',
    //   }),
    [SLA_FORM_KEY_MAP.OUTCOME]: Joi.array().min(2).required().messages({
      'array.min': 'Add at least two outcomes',
      'any.required': 'Add at least two outcomes',
    }),
  }).unknown()
).min(1);
