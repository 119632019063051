/* eslint-disable react/prop-types */
import { DeleteIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import SelectBoxWithAddButton from './SelectBoxWithAddButton';
import CustomSelectBox from '../../components/SelectBox/Select';
import {
  NOTE_TYPE_OPTION,
  QUICK_ACTION_DATA_KEYS,
  QUICK_ACTION_LABEL,
} from './quickAction.constants';
import { getWholeOption } from '../TicketTemplateWorkflow/helper';

const WrapperForCanned = ({
  label,
  optionList,
  onDelete,
  values,
  onUpdatedValue,
  errorMessage,
}) => {
  const getErrorMessage = (errorInput, key) => {
    const str = errorInput?.[QUICK_ACTION_DATA_KEYS.ACTION_LIST] || '';
    if (
      errorInput?.[QUICK_ACTION_DATA_KEYS.ACTION_LIST] === '' ||
      errorInput?.[QUICK_ACTION_DATA_KEYS.ACTION_LIST] === undefined
    ) {
      return false;
    }
    if (str.includes(key)) {
      if (key === 'canned_noted_id') {
        return 'Please select note';
      }
      return 'Please select note type';
    }
    return '';
  };
  return (
    <Box
      border="1px solid #D1D5DB"
      pl="16px"
      pr="16px"
      borderRadius="4px"
      mb="15px"
    >
      <Flex justifyContent="space-between" pt="16px">
        <Text className="SCLabel">{label}</Text>
        <DeleteIcon color="red" className="cursor-pointer" onClick={onDelete} />
      </Flex>
      <Box mb="15px">
        <CustomSelectBox
          value={getWholeOption(values.canned_noted_id, optionList)}
          options={optionList}
          label={QUICK_ACTION_LABEL.SELECT_NOTE}
          onChange={({ value }) => {
            onUpdatedValue(value, 'canned_noted_id');
          }}
          size="md"
          style={{ position: 'absolute', left: 30 }}
          isError={
            errorMessage?.[QUICK_ACTION_DATA_KEYS.ACTION_LIST] === '' ||
            errorMessage?.[QUICK_ACTION_DATA_KEYS.ACTION_LIST] === undefined
              ? false
              : errorMessage?.[QUICK_ACTION_DATA_KEYS.ACTION_LIST].includes(
                  'canned_noted_id'
                )
          }
          errorMessage={getErrorMessage(errorMessage, 'canned_noted_id')}
        />
        <CustomSelectBox
          value={getWholeOption(values.canned_noted_type, NOTE_TYPE_OPTION)}
          options={NOTE_TYPE_OPTION}
          label={QUICK_ACTION_LABEL.NOTE_TYPE}
          onChange={({ value }) => {
            onUpdatedValue(value, 'canned_noted_type');
          }}
          size="md"
          style={{ position: 'absolute', left: 30 }}
          isError={
            errorMessage?.[QUICK_ACTION_DATA_KEYS.ACTION_LIST] === '' ||
            errorMessage?.[QUICK_ACTION_DATA_KEYS.ACTION_LIST] === undefined
              ? false
              : errorMessage?.[QUICK_ACTION_DATA_KEYS.ACTION_LIST].includes(
                  'canned_noted_type'
                )
          }
          errorMessage={getErrorMessage(errorMessage, 'canned_noted_type')}
        />
      </Box>
    </Box>
  );
};

export default WrapperForCanned;
