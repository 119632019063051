/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import { LABELS } from '../../../../utils/enums/labels';
import {
  layoutViewTypeOption,
  layoutTypeOption,
  maxWidthDesktop,
  minWidthDesktop,
  layoutViewGridTypeOption,
  layoutInboxViewOption,
  layoutTransitionViewOption,
  layoutTypeOptionNoBot
} from '../../../../utils/enums/selectOption';

export const LayoutFields = () => [
  {
    label: LABELS.LAYOUT_PROPERTIES.NAME,
    key: 'name',
    type: 'input',
    placeholder: '',
    isRequired: true,
    errorMessage: LABELS.LAYOUT_FIELD_ERROR.NAME_ERROR,
  },
  {
    label: LABELS.LAYOUT_PROPERTIES.LAYOUT_TYPE,
    key: 'type',
    type: 'select',
    options: layoutTypeOption,
    option2: layoutInboxViewOption,
    option3: layoutTransitionViewOption,
    option4: layoutTypeOptionNoBot,
    placeholder: '',
    isRequired: true,
    errorMessage: LABELS.LAYOUT_FIELD_ERROR.LAYOUT_TYPE_ERROR,
    defaultValue: layoutTypeOption[0].value,
  },
  {
    label: LABELS.LAYOUT_PROPERTIES.VIEW_TYPE,
    key: 'viewType',
    type: 'radio',
    options: layoutViewGridTypeOption,
    placeholder: '',
    isRequired: false,
    errorMessage: LABELS.LAYOUT_FIELD_ERROR.LAYOUT_TYPE_ERROR,
    defaultValue: layoutViewGridTypeOption[0].value,
  },
  // {
  //   label: LABELS.LAYOUT_PROPERTIES.VIEW_TYPE,
  //   key: 'viewType',
  //   type: 'select',
  //   options: layoutViewTypeOption,
  //   placeholder: '',
  //   isRequired: false,
  //   errorMessage: LABELS.LAYOUT_FIELD_ERROR.LAYOUT_TYPE_ERROR,
  // },
  // {
  //   label: LABELS.LAYOUT_PROPERTIES.MIN_WIDTH,
  //   key: 'min_width',
  //   type: 'select',
  //   options: minWidthDesktop,
  //   placeholder: '',
  //   isDisabled: !data?.type,
  //   errorMessage: LABELS.LAYOUT_FIELD_ERROR.MIN_WIDTH_ERROR,
  // },
  // {
  //   label: LABELS.LAYOUT_PROPERTIES.MAX_WIDTH,
  //   key: 'max_width',
  //   type: 'select',
  //   options: maxWidthDesktop,
  //   placeholder: '',
  //   isDisabled: !data?.type,
  //   errorMessage: LABELS.LAYOUT_FIELD_ERROR.MAX_WIDTH_ERROR,
  // },
];
