import React from 'react';
import QueueAssignmentEvent from '../../DynamicRenderer/customEventAndTriggers/QueueAssignmentEvent';

const renderQueueAssignmentForm = (
  actionState,
  handleChange,
  actionToPerformSelectedIndex,
  actionErrorData,
  handleActionErrorData,
  selectedTemplate
) => (
  <QueueAssignmentEvent
    eventSettings={actionState}
    handleChange={handleChange}
    actionToPerformSelectedIndex={actionToPerformSelectedIndex}
    actionErrorData={actionErrorData}
    handleActionErrorData={handleActionErrorData}
    template={selectedTemplate}
  />
);

export default renderQueueAssignmentForm;
