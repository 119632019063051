import _map from 'lodash/map';
import _cloneDeep from 'lodash/cloneDeep';

export const stripEmptyArraysFromObject = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key]) && obj[key].length === 0) {
      delete obj[key];
    }
  });
  return obj;
};

export const createAPIReadyData = (tags) => {
  const newTags = _cloneDeep(tags);
  return _map(newTags, (tag) => tag.value).filter((x) => x);
};

export const createRenderableDataFromValues = (tags, options) => {
  const newTags = _map(tags, (tag) => options?.find((op) => op.value === tag));
  return newTags.filter((x) => x);
};
