import { Box, Card, CardBody, Grid, GridItem, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { StarIcon } from '@chakra-ui/icons';
import { cardData } from '../TicketTemplateBuilder/dummyDataForTemplate';
import styles from '../TicketTemplateBuilder/styles/particularTemplate.module.scss';

const CradRender = ({
  name,
  children,
  elements,
  style,
  actions,
  viewLayout,
}) => {
  const [cardItems, setCardItems] = useState([]);
  const sortByViewLayout = (items, viewLayout) => {
    if (JSON.stringify(viewLayout) === JSON.stringify({})) return items;
    let merged = [];
    for (let i = 0; i < viewLayout?.rows?.length; i++) {
      merged.push({
        ...viewLayout?.rows[i],
        ...items.find(
          (itmInner) => itmInner?.props?.name === viewLayout?.rows[i].i
        ),
      });
    }
    merged = merged.filter((i) => JSON.stringify(i) !== JSON.stringify({}));
    return merged;
  };

  const convertToCamelCase = (str) => {
    return str.replace(/_./g, (match) => match.charAt(1).toUpperCase());
  };

  // Function to convert cardObject1 to cardData format
  const convertCardData = (obj) => {
    const cardDataItem = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        // eslint-disable-next-line no-shadow
        const name = key;
        const value = obj[key];
        cardDataItem.push({ name, value });
      }
    }
    return cardDataItem;
  };

  const cardObject = {
    status: 'Draft',
    ticket_type: 'Hardware request',
    description: 'This description is related to Hardware',
    star_icon: <StarIcon />,
    // created_by_label: 'CREATED BY',
    // created_on_label: 'CREATED ON',
    // last_edited_label: 'LAST EDITED',
    // edited_by_label: 'EDITED BY',
    created_by: 'samsmith@gmail.com',
    created_on: 'Feb 28 2023, 15:09 pm',
    last_edited: 'Feb 28 2023, 15:09 pm',
    edited_by: 'samsmith@gmail.com',
  };
  const cardViewData = convertCardData(cardObject);

  useEffect(() => {
    setCardItems(sortByViewLayout(children, viewLayout));
    convertCardData(cardObject);
  }, []);
  return (
    <Card w="450px" data-testid="CradRenderContainerCard">
      <CardBody data-testid="CradRenderCardBody">
        <Box data-testid="CradRenderContainerBox">
          <Grid
            style={style}
            templateColumns={`repeat(${viewLayout?.no_of_cols}, 1fr)`}
            gap={3}
            data-testid="CradRenderContainerGrid"
          >
            {/* Use this  */}
            {cardItems?.map((i) => {
              const keysvalue = Object.values(i);
              const element = cardViewData.find(
                (element) => element.name === keysvalue[0]
              );
              if (element) {
                const className = `${
                  keysvalue[0] === 'status' ? styles.statusStyle : ''
                } ${
                  keysvalue[0] === 'ticket_type' ? styles.templateName : ''
                } ${keysvalue[0] === 'star_icon' ? styles.starIcon : ''} ${
                  keysvalue[0] === 'description'
                    ? styles.templateDescription
                    : ''
                }`;
                return (
                  <GridItem
                    key={i.id}
                    colSpan={i.w}
                    className={className}
                    data-testid="CradRenderGridItem"
                  >
                    <span
                      className={`${styles.labelNameStyle} ${
                        keysvalue[0] === 'ticket_type' ||
                        keysvalue[0] === 'status' ||
                        keysvalue[0] === 'description' ||
                        keysvalue[0] === 'star_icon'
                          ? styles.displayNone
                          : ''
                      }`}
                    >
                      {keysvalue[0] !== 'ticket_type' &&
                      keysvalue[0] !== 'description' &&
                      keysvalue[0] !== 'star_icon'
                        ? keysvalue[0].replace('_', ' ')
                        : null}{' '}
                      <br />
                    </span>
                    <Text data-testid="CradRenderText">
                      {cardObject[i.props.value]}
                    </Text>
                  </GridItem>
                );
              }
              return null;
            })}
          </Grid>
        </Box>
      </CardBody>
    </Card>
  );
};
export default CradRender;
