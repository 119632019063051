import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { COLORS } from '../../utils/enums/colors';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    borderRadius: '12px',
    color: COLORS.RULE_CARD_BORDER,
    _checked: {
      bg: COLORS.LIGHT,
      borderColor: COLORS.LIGHT,
      _dark: { bg: COLORS.DARK, borderColor: COLORS.DARK },
    },
  },
  container: {
    bg: COLORS.WHITE,
    _dark: {
      bg: COLORS.DRAG_LIGHT,
    },
    _checked: {
      bg: COLORS.RADIO_SELECTED_BG,
      borderColor: COLORS.LIGHT,
      _dark: {
        bg: COLORS.LIGHT,
        borderColor: COLORS.BLACK_OPACITY,
      },
    },
  },
  label: {
    color: COLORS.DEFAULT,
    _checked: {
      color: COLORS.BLACK_OPACITY,
    },
    _dark: {
      color: COLORS.TEXT_DARK,
    },
  },
});

const radioTheme = defineMultiStyleConfig({ baseStyle });
export default radioTheme;
