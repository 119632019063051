/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Button, useColorModeValue } from '@chakra-ui/react';
import PropTypes, { func } from 'prop-types';

const CustomOperatorButton = ({
  onClick,
  buttonVariant,
  color,
  buttonLabel,
  icon,
  size,
  width,
  height,
  borderColor,
  _hover,
  isButtonDisabled,
  testingId,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const borderValue = useColorModeValue('#e2e8f0', '#3F444E');
  return (
    <Button
      data-testid={testingId}
      isDisabled={isButtonDisabled}
      leftIcon={icon}
      variant={buttonVariant || 'outline'}
      color={color}
      onClick={onClick}
      width={width}
      size={size}
      className='calculatedHeight'
      borderColor={borderColor || borderValue}
      _hover={_hover}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {buttonLabel}
    </Button>
  );
};

CustomOperatorButton.propTypes = {
  buttonLabel: PropTypes.string,
  onClick: PropTypes.func,
  buttonVariant: PropTypes.string.isRequired,
  color: PropTypes.string,
  // id: PropTypes.string,
  size: PropTypes.string,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  borderColor: PropTypes.string,
  _hover: PropTypes.object,
  icon: PropTypes.element,
  isButtonDisabled: PropTypes.bool,
  testingId: PropTypes.string,
};

CustomOperatorButton.defaultProps = {
  buttonLabel: 'Click',
  onClick: func,
  color: '#2563EB',
  // id: '',
  size: 'sm',
  borderColor: '',
  _hover: {
    background: '#2563EB',
    color: 'white',
  },
  icon: null,
  isButtonDisabled: false,
  testingId: '',
};
export default CustomOperatorButton;
