// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nXfdbDDj12_aE9ArRxkA{font-size:30px;font-weight:600;color:rgba(0,0,0,.8509803922);margin-top:22px;margin-bottom:15px;line-height:35px}.b1DA8ZxbXSNIM4VKxXNN{font-size:16px;color:#968e96;margin-top:15px;line-height:22px}.mC0P5lTyMRPIZgDvmJpz{margin:16px 0;padding:0px 16px !important;font-size:16px}.EVBW2VLbA_LDQBcwtvF7{padding-top:8px;margin-right:0px}.EVBW2VLbA_LDQBcwtvF7 svg path{stroke:gray !important}.EVBW2VLbA_LDQBcwtvF7 svg{height:8px;width:8px}`, "",{"version":3,"sources":["webpack://./src/pages/DynamicRenderer/crudStyle.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,eAAA,CACA,6BAAA,CACA,eAAA,CACA,kBAAA,CACA,gBAAA,CAGF,sBACE,cAAA,CACA,aAAA,CACA,eAAA,CACA,gBAAA,CAGF,sBACE,aAAA,CACA,2BAAA,CACA,cAAA,CAGF,sBACE,eAAA,CACA,gBAAA,CAGF,+BACM,sBAAA,CAGN,0BACI,UAAA,CACA,SAAA","sourcesContent":[".createHeadText {\n  font-size: 30px;\n  font-weight: 600;\n  color: #000000d9;\n  margin-top: 22px;\n  margin-bottom: 15px;\n  line-height: 35px;\n}\n\n.paraText {\n  font-size: 16px;\n  color: #968e96;\n  margin-top: 15px;\n  line-height: 22px;\n}\n\n.createTemplateBtn {\n  margin: 16px 0;\n  padding: 0px 16px !important;\n  font-size: 16px;\n}\n\n.circle {\n  padding-top: 8px;\n  margin-right: 0px;\n}\n\n.circle svg path {\n      stroke: grey !important;\n    }\n\n.circle svg {\n    height: 8px;\n    width: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"createHeadText": `nXfdbDDj12_aE9ArRxkA`,
	"paraText": `b1DA8ZxbXSNIM4VKxXNN`,
	"createTemplateBtn": `mC0P5lTyMRPIZgDvmJpz`,
	"circle": `EVBW2VLbA_LDQBcwtvF7`
};
export default ___CSS_LOADER_EXPORT___;
