/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
import { Box, Flex, FormLabel } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useStoreApi } from 'reactflow';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../styles/RestrictMoveRole.module.scss';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { LABELS } from '../../../utils/enums/labels';
import { FieldTypes, WorkFlowNodeTypes } from '../../../utils/enums/types';
import {
  CAUTION_STATUS,
  DONE_STATUS,
  FIXING_STATUS,
  IN_PROGRESS_STATUS,
  TO_DO_STATUS,
} from '../../../utils/enums/colors';
import { getWholeOption } from '../helper';
import { changeSelectedRuleWithKey } from '../../../redux/WorkFlowMainSlice/WorkFlowMainReducer';
import DynamicExpressionWithTags from '../../../components/dynamicExpressionWithTags/DynamicExpressionWithTags';
import { checkForV2, isEmptyCondition } from '../../../utils/helper';
import { getTemplateById } from '../../../components/dynamicExpressionWithTags/dynamicExpressionWithTags.helper';
import ConditionsExpressions from '../../../components/ConditionsExpressions/ConditionsExpressions';

const RestrictSpecificValueContainer = ({ errorData, setErrorData }) => {
  const dispatch = useDispatch();
  const { selectedWorkFlowRule, fieldList, selectedTemplateId } = useSelector(
    (state) => state.workFlowMain
  );

  const [fields, setFields] = useState([]);
  // const [omitFields, setOmitFields] = useState(['LABEL']);

  const flowStore = useStoreApi();
  const { edges, nodeInternals } = flowStore.getState();
  const innerEdgeList = edges
    .filter((i) => i.id !== 'edges_1')
    .map((i) => ({
      label: i.data?.label,
      value: i?.id,
      source: i?.source,
      target: i?.target,
    }));
  const fieldListOption = fieldList.map((item) => ({
    value: `${item.label}-${item.type}`,
    name: item.label,
  }));
  fieldListOption.unshift({ value: '', name: '' });
  const determineBgColor = (type) => {
    if (type === WorkFlowNodeTypes.toDoNode) {
      return TO_DO_STATUS;
    }
    if (type === WorkFlowNodeTypes.toProgressNode) {
      return IN_PROGRESS_STATUS;
    }
    if (type === WorkFlowNodeTypes.toDoneNode) {
      return DONE_STATUS;
    }
    if (type === WorkFlowNodeTypes.toCautionNode) {
      return CAUTION_STATUS;
    }
    if (type === WorkFlowNodeTypes.toFixingNode) {
      return FIXING_STATUS;
    }
    return TO_DO_STATUS;
  };
  const handleErrorData = useCallback((keyName, val) => {
    setErrorData((prev) => {
      if (prev[keyName] && val) {
        return {
          ...prev,
          [keyName]: '',
        };
      }
      return prev;
    });
  }, []);

  useEffect(() => {
    const getTemplateFields = async () => {
      const res = await getTemplateById(selectedTemplateId);
      setFields(res?.rows[0]?.fields || []);
    };

    getTemplateFields();
  }, [selectedTemplateId]);

  const omitFields = useMemo(() => {
    const omittedFields = [FieldTypes.LABEL];
    if (selectedWorkFlowRule?.valueListObj?.length && fields.length) {
      const map = selectedWorkFlowRule?.valueListObj.reduce((prev, curr) => {
        prev[curr.leftOperand] = true;
        return prev;
      }, {});

      omittedFields.push(
        ...fields.filter((f) => map[f.label]).map((f) => f.id)
      );
    }
    return omittedFields;
  }, [selectedWorkFlowRule?.valueListObj, fields]);

  return (
    <>
      <FormLabel
        marginTop="30px"
        className={styles.formLabelRestric}
        data-testid="RestrictSpecificValueContainerTransFormLabel"
      >
        {LABELS.TITLE.FOR_TRANSTION}
      </FormLabel>
      <CustomSelectBox
        name=""
        value={getWholeOption(selectedWorkFlowRule.transitionId, innerEdgeList)}
        options={innerEdgeList}
        onChange={(e) => {
          handleErrorData('transitionId', e.value);
          dispatch(
            changeSelectedRuleWithKey({
              keyValue: 'transitionId',
              value: e.value,
            })
          );
        }}
        // eslint-disable-next-line react/no-unstable-nested-components
        getOptionLabel={(e) => (
          <Flex
            align="center"
            className="text-[14px] font-500"
            data-testid="RestrictSpecificValueContainerBoxFlex"
          >
            <span
              className="ml-1"
              data-testid="RestrictSpecificValueContainerLabelSpan"
            >
              {e.label}
            </span>
            <Box
              className="ml-[10px] mr-[10px] text-white pl-[7px] pr-[7px]"
              bgColor={determineBgColor(nodeInternals.get(e.source)?.type)}
              borderRadius="4px"
              data-testid="RestrictSpecificValueContainerSrcBox"
            >
              {nodeInternals.get(e.source)?.data.label}
            </Box>
            {e?.label && <ArrowForwardIcon />}
            <Box
              className="ml-[10px] mr-[10px] text-white pl-[7px] pr-[7px]"
              bgColor={determineBgColor(nodeInternals.get(e.target)?.type)}
              borderRadius="4px"
              data-testid="RestrictSpecificValueContainerTargetBox"
            >
              {nodeInternals.get(e.target)?.data.label}
            </Box>
          </Flex>
        )}
        isError={!!errorData?.transitionId}
        errorMessage={errorData?.transitionId || ''}
        id="RestrcitSpecificValueSelectBox"
      />
      <Box
        className={styles.differentTransitionTitle}
        data-testid="RestrictSpecificValueContainerTitleBox"
      >
        {LABELS.TITLE.DIFFERENT_TRANSITION_RULE}
      </Box>
      {/* <FormLabel marginTop="13px" className={styles.formLabelRestric}>
        {LABELS.TITLE.FOR_THIS_FIELD}
      </FormLabel>
      <Box mb={selectedWorkFlowRule.reviewValueStatus ? '35px' : '69px'}>
        <SelectComponent
          height="32px"
          options={fieldListOption}
          value={selectedWorkFlowRule.reviewValueStatus}
          handleChange={(value) => {
            dispatch(
              changeSelectedRuleWithKey({
                keyValue: 'reviewValueStatus',
                value,
              })
            );
            dispatch(
              changeSelectedRuleWithKey({
                keyValue: 'checkValueStatus',
                value: '',
              })
            );
          }}
          focusBorderColor={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
        />
      </Box> */}
      {/* <DynamicExpressionWithTags
        templateId={selectedTemplateId}
        values={selectedWorkFlowRule?.valueListObj || []}
        onChange={(value) => {
          handleErrorData('valueListObj', value);
          dispatch(
            changeSelectedRuleWithKey({
              keyValue: 'valueListObj',
              value,
            })
          );
        }}
        label="Conditions"
        overrideOptions={!checkForV2() ? ['IS NOT NULL'] : []}
        showRightOperand={checkForV2()}
        omitFields={omitFields}
      /> */}
      <ConditionsExpressions
        templateId={selectedTemplateId}
        value={selectedWorkFlowRule?.valueListObj || {}}
        onChange={(value) => {
          const { and } = value || {};
          const updatedValue = isEmptyCondition(and) ? { and: [] } : value;
          handleErrorData('valueListObj', value);
          dispatch(
            changeSelectedRuleWithKey({
              keyValue: 'valueListObj',
              value: updatedValue,
            })
          );
        }}
        label="Conditions"
      />
      {errorData?.valueListObj ? (
        <span
          className="field-error"
          style={{ bottom: '110px', position: 'absolute' }}
          data-testid="RestrictSpecificValueContainerErrorSpan"
        >
          {errorData.valueListObj}
        </span>
      ) : null}
    </>
  );
};
RestrictSpecificValueContainer.propTypes = {
  errorData: PropTypes.object.isRequired,
  setErrorData: PropTypes.func.isRequired,
};
export default RestrictSpecificValueContainer;
