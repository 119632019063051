import React, { useEffect, useState } from 'react';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Box, SimpleGrid, Text, Stack } from '@chakra-ui/layout';
import PropTypes from 'prop-types';
import { Checkbox } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import CustomButton from '../../../components/Button/SimpleButton';
import styles from '../styles/templatebuilder.module.scss';
import { DARK, LIGHT, BLACK, WHITE } from '../../../utils/enums/colors';
import { LABELS } from '../../../utils/enums/labels';
import { CustomDrawer } from './AddEditTemplateLayoutModal';

const SpecialFieldsInboxModal = ({
  isOpen,
  setIsOpen,
  templateName,
  isEdit,
  onConfirmation,
  itemList,
  layoutData,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [data, setData] = useState();
  const viewState = useSelector((state) => state.viewState);
  const SLA_DATA = viewState?.selectedTemplate?.slas || {};
  const METRIC_DATA = viewState?.selectedTemplate?.metric;

  const flattenArray = (arr) =>
    arr.reduce((flatArray, subArray) => flatArray.concat(subArray), []);

  const onFinish = async () => {
    onConfirmation(selectedFields);
    setIsOpen(false);
  };

  const selectAllClick = () => {
    if (selectAll) {
      setSelectedFields([]);
      setSelectAll(false);
    } else {
      setSelectedFields([...data]);
      setSelectAll(true);
    }
  };

  useEffect(() => {
    const slas = flattenArray(Object.values(SLA_DATA))?.map((i) => ({
      ...i,
      width: 6,
      label: `${i?.name} (SLA)`,
      type: 'SF_SLAS',
    }));
    const metrics = METRIC_DATA?.map((i) => ({
      ...i,
      width: 6,
      label: `${i?.name} (Metric)`,
      type: 'SF_METRIC',
    }));

    // check if metrics or SLA is undefined or not
    const filteredMetric = Array.isArray(metrics) ? metrics : [];
    const filteredsla = Array.isArray(slas) ? slas : [];

    const totalSpcl = [...filteredsla, ...filteredMetric];

    const filteredArray = totalSpcl.filter((obj) => {
      // Check if obj.type is 'SF_METRIC' or 'SF_SLAS'
      if (obj.type === 'SF_METRIC' || obj.type === 'SF_SLAS') {
        // Check if obj.id is present in itemList
        if (itemList.some((item) => item.id === obj.id)) {
          return false; // Exclude the object if its id is present in itemList
        }
        // eslint-disable-next-line brace-style
      }
      // For other types, check if obj.type is present in itemList
      else {
        if (itemList.some((item) => item.type === obj.type)) {
          return false; // Exclude the object if its type is present in itemList
        }

        // Check if obj.type is present in any subItem of itemList
        if (
          itemList.some(
            (item) =>
              item.subItems &&
              item.subItems.some((subItem) => subItem.type === obj.type)
          )
        ) {
          return false; // Exclude the object if its type is present in any subItem of itemList
        }
      }
      return true; // Include the object in the filteredArray
    });

    setData(filteredArray);
  }, []);

  useEffect(() => {
    if (selectedFields?.length === data?.length) setSelectAll(true);
    else setSelectAll(false);
  }, [selectedFields, data]);

  const onChangeCheckbox = (field) => {
    setSelectedFields((prevSelectedFields) => {
      const isSelected = prevSelectedFields.some(
        (selectedField) => selectedField.id === field.id
      );

      if (isSelected) {
        return prevSelectedFields.filter(
          (selectedField) => selectedField.id !== field.id
        );
      }
      return [...prevSelectedFields, { ...field }];
    });
  };

  const modalFooter = (
    <Box>
      <CustomButton
        color={useColorModeValue(BLACK, WHITE)}
        onClick={() => {
          setIsOpen(false);
        }}
        buttonText={LABELS.ACTIONS.CANCEL}
        variant="outline"
        className="mr-4"
        id="SpecialFieldsModalCancelBtn"
      />
      <CustomButton
        color="white"
        onClick={() => {
          onFinish();
        }}
        buttonText="Save"
        variant="solid"
        className={`${styles.createTemplateBtn} `}
        bg={useColorModeValue(LIGHT, DARK)}
        id="SpecialFieldsModalSaveBtn"
      />
    </Box>
  );

  return (
    <CustomDrawer
      id="delete-modal"
      modalTitle={`${isEdit ? 'Edit' : 'Add'} Special Fields`}
      drawerBody={
        <>
          <Text data-testid="SpecialFieldsModalText">{layoutData?.name}</Text>
          <div className="mt-4" data-testid="SpecialFieldsModalDiv">
            <Checkbox
              isChecked={selectAll}
              onChange={() => selectAllClick()}
              mb="15px"
              data-testid="SpecialFieldsModalCheckbox"
            >
              Select All
            </Checkbox>
            <Stack spacing={2} data-testid="SpecialFieldsModalStack">
              <SimpleGrid columns={2} spacing={4} mt="2">
                {data?.map((field, index) => (
                  <Checkbox
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${field.id}_${index}`}
                    isChecked={selectedFields.some(
                      (selectedField) => selectedField.id === field.id
                    )}
                    onChange={() => onChangeCheckbox(field)}
                  >
                    {field?.label}
                  </Checkbox>
                ))}
              </SimpleGrid>
            </Stack>
          </div>
        </>
      }
      drawerFooter={modalFooter}
      openDrawer={isOpen}
      setOpenDrawer={setIsOpen}
    />
  );
};

SpecialFieldsInboxModal.propTypes = {
  templateName: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onConfirmation: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  itemList: PropTypes.array,
  layoutData: PropTypes.object.isRequired,
};

SpecialFieldsInboxModal.defaultProps = {
  isEdit: false,
  itemList: [],
};

export default SpecialFieldsInboxModal;
