import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import { InputGroup, InputRightElement } from '@chakra-ui/react';
import CustomInput from '../../components/InputBox/Input';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import { searchApi } from './utils';

const SearchView = ({
  // eslint-disable-next-line no-unused-vars
  name,
  size,
  entity,
  filterKey,
  listKey,
  placeholder,
  style,
  inputRightElementStyle,
  inputGroupStyle,
}) => {
  const dispatch = useDispatch();
  const { viewState } = useSelector((state) => state);
  const [searchInputValue, setSearchInputValue] = useState(null);
  const [typingTimeout, setTypingTimeout] = useState(null);

  useEffect(() => {
    if (searchInputValue === '') {
      dispatch(
        updateViewState({
          stateKey: listKey,
          value: {
            refresh: uuidv4(),
            search: false,
          },
        })
      );
    }
  }, [searchInputValue]);

  const onSearch = async (val) => {
    if (val !== '') {
      const response = await searchApi(entity, val, filterKey);
      dispatch(
        updateViewState({
          stateKey: listKey,
          value: {
            ...viewState[listKey],
            data: response?.hits,
            count: response?.hits?.length,
            search: true,
          },
        })
      );
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSearchInputValue(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    const newTypingTimeout = setTimeout(() => {
      onSearch(value);
    }, 500);
    setTypingTimeout(newTypingTimeout);
  };

  const handleClearInput = () => {
    setSearchInputValue('');
  };

  return (
    <div className="px-2" data-testid="SearchViewDiv">
      <InputGroup style={inputGroupStyle} size="l">
        <CustomInput
          placeholder={placeholder || 'Search...'}
          type="text"
          size={size}
          value={searchInputValue}
          onChange={handleInputChange}
          style={style}
          rightElement={<SearchIcon />}
          inputRightElementStyle={inputRightElementStyle}
          id="SearchViewDivCInput"
        />
        {searchInputValue && (
          <InputRightElement
            style={{
              right: style?.height || inputRightElementStyle?.height || 40,
              top: '50%',
              cursor: 'pointer',
              transform: 'translateY(-50%)',
            }}
            // eslint-disable-next-line react/no-children-prop
            children={<CloseIcon color="gray.300" />}
            size="l"
            onClick={() => handleClearInput()}
          />
        )}
      </InputGroup>
    </div>
  );
};

SearchView.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  entity: PropTypes.string.isRequired,
  filterKey: PropTypes.string.isRequired,
  listKey: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  inputRightElementStyle: PropTypes.object,
  inputGroupStyle: PropTypes.object,
};

SearchView.defaultProps = {
  placeholder: '',
  style: {},
  size: 'sm',
  inputRightElementStyle: undefined,
  inputGroupStyle: undefined,
};

export default SearchView;
