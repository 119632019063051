/* eslint-disable react/prop-types */
import React from 'react';
import { Box } from '@chakra-ui/react';
import RightAndLeftWithAddButton from './RightAndLeftWithAddButton';
import {
  FOLDER_MAP_OPTION_LIST,
  removeMatchingElementsForFolder,
} from './utils';
import InputWithAddButton from './InputWithAddButton';

const MailConnectionPage = ({
  mailServerState,
  handleInputWithPath,
  errorListWithKey,
}) => (
  <Box mt="15px">
    <RightAndLeftWithAddButton
      label="Folder Mapping"
      leftKey="source"
      rightKey="destination"
      leftOptionList={FOLDER_MAP_OPTION_LIST}
      onChange={(values) => handleInputWithPath(values, 'folderMapping')}
      values={mailServerState.folderMapping || []}
      key={mailServerState.folderMapping?.[0]?.source}
    />
    {errorListWithKey.folderMapping ? (
      <span className="field-error">Please enter at least one folder map</span>
    ) : null}
    <InputWithAddButton
      label="Allowed Domains"
      buttonLabel="Add New Domain"
      inputWidth="75%"
      onChange={(values) => handleInputWithPath(values, 'allowedDomains')}
      values={mailServerState.allowedDomains}
      key={mailServerState.allowedDomains?.[0]}
    />
    {/* {errorListWithKey.allowedDomains ? (
      <span className="field-error">Please enter a valid domain name</span>
    ) : null} */}
    <InputWithAddButton
      label="Exclude AutoReplies"
      buttonLabel="Add"
      onChange={(values) => handleInputWithPath(values, 'excludeAutoReplies')}
      values={mailServerState.excludeAutoReplies}
      key={mailServerState?.excludeAutoReplies?.[0]}
    />
    {errorListWithKey.excludeAutoReplies ? (
      <span className="field-error">Please enter a valid email</span>
    ) : null}
    <InputWithAddButton
      label="Alias To Email"
      buttonLabel="Add"
      onChange={(values) => handleInputWithPath(values, 'aliasToEmail')}
      values={mailServerState.aliasToEmail}
      key={mailServerState.aliasToEmail?.[0]}
    />
    {errorListWithKey.aliasToEmail ? (
      <span className="field-error">Please enter a valid email</span>
    ) : null}
  </Box>
);

export default MailConnectionPage;
