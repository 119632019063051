/* eslint-disable no-shadow */
import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { useNavigate, useParams } from 'react-router-dom';
import { useColorModeValue, Box } from '@chakra-ui/react';
import Circle from '../../assets/icons/Circle.svg';
import styles from './styles/templatebuilder.module.scss';
import {
  COLORS,
  DARK,
  LIGHT,
  SELECT_DARK,
  SELECT_LIGHT,
} from '../../utils/enums/colors';

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'transparent' : 'transparent',
  padding: '2px',
  margin: '0',
});

export const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: '0px 4px',
  margin: `0 0px 0 6px`,
  // change background colour if dragging
  background: isDragging ? COLORS.DRAGGING : 'transparent',
  // styles we need to apply on draggables
  ...draggableStyle,
});

export const ServiceCommandUnit = ({ type, subItems }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <Droppable droppableId={type} type="droppableSubItem">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={getListStyle(snapshot.isDraggingOver)}
        >
          {subItems.map((item, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index}>
              {(provided, snapshot) => (
                <>
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    {item?.children?.length > 0 ? (
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <ServiceCommandUnit
                          type={item?.id}
                          subItems={item?.children}
                        />
                      </div>
                    ) : (
                      <Box
                        {...provided.dragHandleProps}
                        className={styles.templateSelect}
                        as="span"
                        fontSize="md"
                        _hover={{
                          bg: COLORS.BG_LIGHT,
                          color: useColorModeValue('black', 'white'),
                        }}
                        bg={
                          item?.id === id
                            ? useColorModeValue(SELECT_LIGHT, SELECT_DARK)
                            : ''
                        }
                        color={
                          item?.id === id ? useColorModeValue(LIGHT, DARK) : ''
                        }
                        onClick={() =>
                          navigate(`/template-builder/${item?.id}`)
                        }
                      >
                        <ReactSVG src={Circle} className={styles.circle} />
                        {item.name}
                      </Box>
                    )}
                  </div>
                  {provided.placeholder}
                </>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

ServiceCommandUnit.propTypes = {
  type: PropTypes.string.isRequired,
  subItems: PropTypes.instanceOf(Array),
};

ServiceCommandUnit.defaultProps = {
  subItems: [],
};
export default ServiceCommandUnit;
