import React from 'react';
import _get from 'lodash/get';
import { useColorModeValue, Box } from '@chakra-ui/react';
import { PropTypes } from 'prop-types';
import CustomSelect from '../../../components/SelectBox/Select';
import { makeOptions, getActorFields } from '../../../utils/helper/fieldHelper';
import { LIGHT, DARK } from '../../../utils/enums/colors';

const ActorAssignmentEvent = ({
  eventSettings = {},
  handleChange,
  actionToPerformSelectedIndex,
  actionErrorData,
  handleActionErrorData,
  template,
}) => {
  const actorField = _get(
    eventSettings,
    `action_to_perform[${actionToPerformSelectedIndex}].actor_field`
  );
  const options = makeOptions(getActorFields(template));

  const handleActorFieldChange = (value) => {
    handleChange(
      value,
      `action_to_perform[${actionToPerformSelectedIndex}].actor_field`
    );
    handleActionErrorData('actor_field');
  };
  const isError = !!actionErrorData?.status;
  const errorMessage = actionErrorData?.status || '';

  return (
    <Box marginTop="20px" data-testid="actorAssignmentBox">
      <CustomSelect
        label="Actor Field"
        options={options}
        placeholder="select actor field"
        value={actorField}
        onChange={handleActorFieldChange}
        focusBorderColor={useColorModeValue(LIGHT, DARK)}
        isError={isError}
        errorMessage={errorMessage}
        isMandatory
      />
    </Box>
  );
};

ActorAssignmentEvent.propTypes = {
  eventSettings: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  actionToPerformSelectedIndex: PropTypes.number.isRequired,
  actionErrorData: PropTypes.object,
  handleActionErrorData: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
};

ActorAssignmentEvent.defaultProps = {
  actionErrorData: {},
};

export default ActorAssignmentEvent;
