import React, { useState,useEffect } from 'react'
import FieldsTableViewWithSearch from './FieldsTableViewWithSearch'
import { BOT_IDENTICATION_FIELDS_TRIGGERS } from './utils'
import axiosInstance from '../../../../utils/axios/axios';
import { useParams } from 'react-router';

const BotIdentificationFields = () => {
  const[selectedTemplate,setSelectedTemplate]=useState([])
  const[descriptionData,setDescriptionData]=useState([])
  const[isFetching,setIsFetching]=useState(false)
  const[dropdownFields,setDropdownFields]=useState([])
  const[deletedFields,setDeletedFields]=useState([])
  const templateId = useParams()
  const id=templateId['*'].match(/\d+/)[0]
  const getFields=async()=>{
    setIsFetching(true)
    try {
      const response = await axiosInstance.get(
        `/templates/list?$top=1&$skip=0&$select=*&$filter=id%20eq%20${id}&history=true`
      );
      if (Array.isArray(response?.data?.rows)) {
        setSelectedTemplate(response?.data?.rows)
      }
      setIsFetching(false)
      return [];
    } catch (err) {
      setIsFetching(false)
      return [];
    }
  }
  useEffect(()=>{
   getFields()
   },[])
   const onEditDescription=(rowData,value)=>{
    const updatedTableData = descriptionData.map(item => {
      if (item.id === rowData.id) {
        return { ...item, description: value };
      }
      return item;
    });
    setDescriptionData(updatedTableData);
  }
  useEffect(() => {
    // const nonBotUserFields=selectedTemplate[0]?.fields.filter(item=>!(item?.permissions?.viewers?.roles.includes("bot_user")))
    // const initialRequiredFields=allMandatoryFields.filter(item=>{
    //   if(Array.isArray(item?.permissions?.viewers?.roles)){
    //     const nonBotUserFields=!(item?.permissions?.viewers?.roles.includes("bot_user"))
    //     return nonBotUserFields.filter(element=>element.id===item.id)
    //   }
    // })
    // setDescriptionData(initialRequiredFields)

    // const initialFields=selectedTemplate[0]?.fields.filter((item)=>{

    //   if(Array.isArray(item?.permissions?.viewers?.roles) && item?.type_based_attributes?.required){
    //     const allMandatoryFields=item?.type_based_attributes?.required
    //     const requiredFieldsForBot=allMandatoryFields.filter(element=>{
    //      return !element?.permissions?.viewers?.roles.includes("bot_user")
    //     })
    //     console.log("req",requiredFieldsForBot)
    //     if((item?.permissions?.viewers?.roles.includes("bot_user") && item?.type_based_attributes?.required)||(item?.permissions?.viewers?.roles.includes("bot_user") && item?.requiredForBot)){return item}
    //   }
    // })
    if(Array.isArray(selectedTemplate[0]?.fields)){
      const formatText=(input)=> {
        let formattedText = input.replace(/_/g, ' ').toLowerCase();
        formattedText = formattedText.replace(/\b\w/g, function(char) {
            return char.toUpperCase();
        });
        
        return formattedText;
    }
      const allMandatoryFields=selectedTemplate[0]?.fields.filter(item=>{
        if(item?.type_based_attributes?.required){
          item.type = formatText(item.type);
          return true;
        }
        else {return false}
      })
      const allNonMandatoryFields=selectedTemplate[0]?.fields.filter(item=>{
        if(!item?.type_based_attributes?.required){
          item.type = formatText(item.type);
          return true;
        }
        else {return false}
      })
      const nonBotUserMandatoryFields = allMandatoryFields.filter(item =>{
        if(item?.permissions?.viewers.roles){
          const roles = item.permissions.viewers.roles
          return !roles.includes("bot_user")
        }
        else{return item}
      });
      const nonBotUserNonMandatoryFields = allNonMandatoryFields.filter(item =>{
        if(item?.permissions?.viewers.roles){
          const roles = item.permissions.viewers.roles
          return !roles.includes("bot_user")
        }
        else{return item}
      });
      const botUserFields=allMandatoryFields.filter(item=>{
        if(item?.type_based_attributes?.required){
          return !nonBotUserMandatoryFields.some(item2 => item2.id === item.id)
        }
      })
      const dropdownBotUserFields=allNonMandatoryFields.filter(item=>{
        if(!item?.type_based_attributes?.required){
          return !nonBotUserNonMandatoryFields.some(item2 => item2.id === item.id)
        }
      })
      const additionalBotUserFields=allNonMandatoryFields.filter(item=>item?.requiredForBot)
      const fields=[...botUserFields,...additionalBotUserFields]
      const mandatoryFields=fields.filter(item=>item?.type_based_attributes?.collection?.collection_type!=="SPECIAL_FIELD")
      const optionalFields=dropdownBotUserFields.filter(item=>item?.type_based_attributes?.collection?.collection_type!=="SPECIAL_FIELD")
      setDescriptionData(mandatoryFields)
      setDropdownFields(optionalFields)
    }
    // const initialDropdownFields=selectedTemplate[0]?.fields.filter((item)=>{
    //   if(Array.isArray(item?.permissions?.viewers?.roles)){
    //   if(item?.permissions?.viewers?.roles.includes("bot_user") && !item?.type_based_attributes?.required ){return item}
    //   }
    // })
   
  }, [selectedTemplate]);
  const onDelete=async (fieldToBeDelete)=>{
    const fields=descriptionData.filter(item=>item.id!==fieldToBeDelete?.id)
    setDescriptionData(fields)
    setDeletedFields(fieldToBeDelete)
  }
  return (
    <>
        <FieldsTableViewWithSearch 
            columns={BOT_IDENTICATION_FIELDS_TRIGGERS({onEditDescription,onDelete})}
            tableData={descriptionData}
            selectedTemplate={selectedTemplate}
            descriptionData={descriptionData}
            setDescriptionData={setDescriptionData}
            getFields={getFields}
            isFetching={isFetching}
            setDropdownFields={setDropdownFields}
            dropdownFields={dropdownFields}
            deletedFields={deletedFields}
            setDeletedFields={setDeletedFields}
        />
    </>
  )
}

export default BotIdentificationFields