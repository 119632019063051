import React, { useCallback, useState } from 'react';

import { Box, Flex, Text, Textarea, useColorModeValue } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import DynamicMutliSelectWithInputTags from '../../../../components/dynamicSelectWithInputTags/DynamicSelectWithInputTags';
import DynamicMutliSelectWithTags from '../../../../components/dynamicExpressionWithTags/DynamicExpressionWithTags';
import CustomInput from '../../../../components/InputBox/Input';
import CustomSelectBox from '../../../../components/SelectBox/Select';

import {
  AUDIENCE_KEY_MAP,
  INTENT_OPTIONS,
  INTENT_OPTIONS_MAP,
} from './audience.utls';
import { customStyles } from '../../MetricsAndSLA/Metrics/utils';
import CustomButton from '../../../../components/Button/SimpleButton';
import { COLORS } from '../../../../utils/enums/colors';
import { renderTagsCategory2 } from '../../MetricsAndSLA/Tags/TagBuilder';

const AudienceEditModal = ({ selectedData, navigateBack, onSave }) => {
  const { id } = useParams();

  const [data, setData] = useState({ ...selectedData });

  const handleChange = useCallback((key, val) => {
    setData((prev) => ({
      ...prev,
      [key]: val,
    }));
  }, []);

  const getValueForSelect = (key) => {
    const val = data?.[key];
    if (val) {
      switch (key) {
        case AUDIENCE_KEY_MAP.SELECTED_INTENT:
          return { label: INTENT_OPTIONS_MAP[val], value: val };
        default:
          return { label: val, value: val };
      }
    }
    return {};
  };

  const saveData = () => {
    const dataObj = { ...data };

    Object.assign(dataObj, {
      id: dataObj?.id || uuidv4(),
    });

    delete dataObj[AUDIENCE_KEY_MAP.SELECTED_INTENT];
    delete dataObj.rowIndex;

    onSave(dataObj);
  };

  const addIntent = () => {
    const val = data?.[AUDIENCE_KEY_MAP.SELECTED_INTENT];

    const all = 'all';

    if (val) {
      let selectedValues = Array.isArray(data?.[AUDIENCE_KEY_MAP.INTENT])
        ? [...data[AUDIENCE_KEY_MAP.INTENT]]
        : [];

      if (val === all) {
        selectedValues = [all];
      } else if (selectedValues[0] === all) {
        selectedValues = [val];
      } else {
        selectedValues.push(val);
      }

      setData((prev) => ({
        ...prev,
        [AUDIENCE_KEY_MAP.INTENT]: selectedValues,
        [AUDIENCE_KEY_MAP.SELECTED_INTENT]: '',
      }));
    }
  };

  const deleteTags = ({ index, key }) => {
    const tempData = [...data[key]];
    tempData.splice(index, 1);
    handleChange(key, tempData);
  };

  const excludeData = useCallback(
    ({ completeData = [], selected = [] }) =>
      completeData.filter((state) => !selected.includes(state.value)),
    []
  );

  return (
    <div className="p-[16px]" data-testid="AudienceEditModalParentDiv">
      <div
        className="flex items-center gap-[5px]"
        data-testid="AudienceEditModalContainerDiv"
      >
        <ArrowBackIcon
          className={`
            cursor-pointer
            border
            border-[#E2E8F0]
            p-1
            rounded-[5px]
          `}
          h="32px"
          w="32px"
          onClick={navigateBack}
          data-testid="AudienceEditModalArrowBackIcon"
        />
        <p className="font-semibold text-[16px]">Audience</p>
      </div>

      <Flex mt="10px" gap="10px" data-testid="AudienceEditModalContainerFlex">
        <Box flex="1" data-testid="AudienceEditModalCIBox">
          <CustomInput
            label="Name"
            isMandatory
            value={data?.[AUDIENCE_KEY_MAP.NAME] || ''}
            onChange={(e) =>
              handleChange(AUDIENCE_KEY_MAP.NAME, e.target.value)
            }
            id="AudienceEditModalDivCInput"
          />
        </Box>
        <Box flex="1" data-testid="AudienceEditModalCSBOuterBox">
          <div
            className="flex gap-[10px] items-end"
            data-testid="AudienceEditModalInnerDiv"
          >
            <Box flex="1" data-testid="AudienceEditModal">
              <CustomSelectBox
                label="Intent"
                options={excludeData({
                  completeData: INTENT_OPTIONS,
                  selected: data?.[AUDIENCE_KEY_MAP.INTENT],
                })}
                value={getValueForSelect(AUDIENCE_KEY_MAP.SELECTED_INTENT)}
                onChange={(e) => {
                  handleChange(AUDIENCE_KEY_MAP.SELECTED_INTENT, e.value);
                }}
                id="AudienceEditModalSelectBox"
                styles={customStyles('100%')}
              />
            </Box>
            <CustomButton
              buttonText="Add"
              variant="outline"
              colorScheme="customBlue"
              style={{ height: 30 }}
              color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
              onClick={addIntent}
              id="AudienceEditModalAddBtn"
            />
          </div>
          <div className="mt-[10px]">
            {renderTagsCategory2({
              key: AUDIENCE_KEY_MAP.INTENT,
              data,
              title: 'Intent',
              deleteTags,
              renderTagLabel: (d) => INTENT_OPTIONS_MAP[d],
            })}
          </div>
        </Box>
      </Flex>

      <Box data-testid="AudienceEditModalDynamicInputBox">
        <DynamicMutliSelectWithInputTags
          values={data?.[AUDIENCE_KEY_MAP.ROLES] || {}}
          label="Role"
          templateId={id}
          onChange={(value) => {
            handleChange(AUDIENCE_KEY_MAP.ROLES, value);
          }}
        />
      </Box>

      <Box data-testid="AudienceEditModalDynamicMultiSelectBox">
        <DynamicMutliSelectWithTags
          label="Filters"
          templateId={id}
          values={data?.[AUDIENCE_KEY_MAP.FILTERS] || []}
          onChange={(value) => {
            handleChange(AUDIENCE_KEY_MAP.FILTERS, value);
          }}
        />
      </Box>

      <Box mt="10px" data-testid="AudienceEditModalTextContainerBox">
        <Text fontWeight="600" mb="8px" data-testid="AudienceEditModalText">
          Description
        </Text>
        <Textarea
          value={data?.[AUDIENCE_KEY_MAP.DESCRIPTION] || ''}
          onChange={(e) => {
            handleChange(AUDIENCE_KEY_MAP.DESCRIPTION, e.target.value);
          }}
          placeholder="Description"
          size="md"
          data-testid="AudienceEditModalTextarea"
        />
      </Box>

      <div
        className="flex justify-end items-center mt-[10px]"
        data-testid="AudienceEditModalCancelSaveContainerDiv"
      >
        <CustomButton
          buttonText="Cancel"
          variant="solid"
          onClick={navigateBack}
          _hover=""
          style={{
            color: useColorModeValue(COLORS.LIGHT, COLORS.DARK),
          }}
          id="AudienceEditModalCancelBtn"
        />
        <CustomButton
          buttonText="Save"
          variant="solid"
          onClick={saveData}
          style={{
            backgroundColor: useColorModeValue(COLORS.LIGHT, COLORS.DARK),
            height: 40,
            color: '#fff',
          }}
          id="AudienceEditModalSaveBtn"
        />
      </div>
    </div>
  );
};

AudienceEditModal.propTypes = {
  navigateBack: PropTypes.func.isRequired,
  selectedData: PropTypes.object,
  onSave: PropTypes.func.isRequired,
};

AudienceEditModal.defaultProps = {
  selectedData: {},
};

export default AudienceEditModal;
