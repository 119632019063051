import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { EditIcon } from '@chakra-ui/icons';
import TableColumnText from '../../../components/Text/TableColumnText';
import { getWholeOption } from '../../TicketTemplateWorkflow/helper';
import { specialFields } from '../../TicketTemplateBuilder/ConfigureTemplateView/utils/specialFieldsJson';

// eslint-disable-next-line import/prefer-default-export
export const specialFieldColumn = ({ showModal }) => [
  {
    name: 'Name',
    col_name: 'name',
    type: 'string',
    cellRenderer: ({ rowData }) => <TableColumnText text={getWholeOption(rowData?.type, [...specialFields, { id: uuidv4(), label: 'Approvers', type: 'SF_APPROVERS', width: 6 }], 'type').label || 'N/A'} />,
  },
  {
    name: 'actions',
    label: 'actions',
    col_name: 'Actions',
    cellRenderer: ({ rowData }) => (
      <div
        className="flex justify-center items-center"
        style={{ gap: '0.8rem' }}
      >
        <EditIcon
          className="cursor-pointer"
          onClick={() =>
            showModal({
              rowData,
            })
          }
        />
      </div>
    ),
  },
];
