import React, { useState } from 'react';

import { Flex, useColorModeValue } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

import PropTypes from 'prop-types';

import Fields, { customStyles } from './Fields';
import CustomSelectBox from '../../../components/SelectBox/Select';
import CustomButton from '../../../components/Button/SimpleButton';

import { FieldTypes } from '../../../utils/enums/types';
import { getDefaultValue, getOptions } from './utils';
import { COLORS } from '../../../utils/enums/colors';

const SelectedDefaultField = ({
  options,
  enrichedDataMap,
  updateValue,
  onTemplateSelect,
  onAddDefaultClick,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [selectedRowData, setSelectedRowData] = useState({});

  return (
    <Flex
      alignItems="center"
      gap="20px"
      p="20px"
      data-testid="SelectedDefaultFieldContainerFlex"
    >
      <CustomSelectBox
        options={options}
        value={selectedTemplate}
        onChange={(e) => {
          setSelectedTemplate(e);
          const currTemplate = enrichedDataMap[e.value];
          setSelectedRowData({ ...currTemplate });
          onTemplateSelect(currTemplate);
        }}
        styles={customStyles}
        id="SelectedDefaultFieldsViewBox"
      />
      <div data-testid="SelectedDefaultFieldHyphenDiv">{' - '}</div>
      <Fields
        fieldType={selectedRowData.type || FieldTypes.TEXT}
        id={selectedRowData.id}
        updateValue={updateValue}
        options={getOptions(selectedRowData)}
        defaultValue={getDefaultValue({
          type: selectedRowData.type,
          val: selectedRowData.default_value,
          collection: selectedRowData.collection,
        })}
      />
      <CustomButton
        leftIcon={<AddIcon mr="10px" />}
        buttonText="Add Field Default"
        variant="outline"
        colorScheme="customBlue"
        style={{ height: 40 }}
        color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
        onClick={onAddDefaultClick}
        id="SelectedDefaultFieldHyphenCBtn"
      />
    </Flex>
  );
};

SelectedDefaultField.propTypes = {
  options: PropTypes.array.isRequired,
  enrichedDataMap: PropTypes.object.isRequired,
  updateValue: PropTypes.func.isRequired,
  onTemplateSelect: PropTypes.func.isRequired,
  onAddDefaultClick: PropTypes.func.isRequired,
};

export default SelectedDefaultField;
