/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { staticRoutes } from '../../utils/enums/RouteHelper';

const initialState = {
  allRoutes: staticRoutes,
  isLoading: false,
  isError: false,
};

const allRoutes = createSlice({
  name: 'allRoutes',
  initialState,
  reducers: {
    reset: (state) => {
      state.allRoutes = [];
      state.isError = false;
      state.error = '';
    },
    resetError: (state) => {
      state.isError = false;
      state.error = '';
    },
    updateRoute: (state, action) => {
      state.allRoutes = action.payload;
    },
  },
});

export const { reset, resetError, updateRoute } = allRoutes.actions;

export default allRoutes.reducer;
