import React, { useEffect, useState } from 'react';
import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import { ReactSVG } from 'react-svg';
import { AddIcon } from '@chakra-ui/icons';
import FieldIcon from '../../../assets/icons/FieldIcon.svg';
import CustomButton from '../../../components/Button/SimpleButton';
import styles from '../styles/templatebuilder.module.scss';
import CreateEditFieldModal from '../CreateEditFieldModal';
import AddNewFieldModal from '../AddNewFieldModal';
import { COLORS } from '../../../utils/enums/colors';
import { LABELS } from '../../../utils/enums/labels';

const NoFieldScreen = () => {
  const [openFieldsModal, setOpenFieldsModal] = useState(false);
  const [type, setType] = useState('');
  const [openCreateFieldModal, setOpenCreateFieldModal] = useState(false);
  const colorType = useColorModeValue('light', 'dark');

  useEffect(() => {
    if (type) {
      setOpenFieldsModal(false);
      setOpenCreateFieldModal(true);
    }
  }, [type]);
  useEffect(() => {
    if (!openCreateFieldModal) setType('');
  }, [openCreateFieldModal]);

  return (
    <Box
      bg={useColorModeValue('white', 'gray.900')}
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      height="calc(100vh - 160px)"
      display="flex"
      flex="1"
      justifyContent="center"
      data-testid="NoFieldScrrenContaineBox"
    >
      <Flex
        flex="1"
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        justifyContent="center"
        data-testid="NoFieldScrrenContainerFlex"
      >
        <Box
          bg={useColorModeValue('white', 'gray.900')}
          border="1px"
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          borderRadius="10px"
          width="450px"
          height="330px"
          align="center"
          justifyContent="center"
          mt="-40"
          data-testid="NoFieldScrrenContainerInnerBox"
        >
          <Box
            bg={useColorModeValue('white', 'gray.900')}
            border="1px"
            width="fit-content"
            borderColor={COLORS.LIGHT_BORDER}
            borderRadius="10px"
            mt="10"
            data-testid="NoFieldScrrenReactSvgBox"
          >
            <ReactSVG
              src={FieldIcon}
              className={`p-2 ${colorType === 'dark' ? styles.colorWhite : ''}`}
            />
          </Box>
          <Box
            className={styles.createHeadText}
            color={useColorModeValue('', 'white')}
            px="8"
            data-testid="NoFieldScrrenLabelBox"
          >
            {LABELS.TITLE.NO_FIELDS_ADDED}
          </Box>
          <div className={styles.paraText} data-testid="NoFieldScrrenParatextP">
            {LABELS.TITLE.CLICK_ADD_NEW_FIELD}
          </div>
          <Box pt="6" data-testid="NoFieldScrrenCusBtn">
            <CustomButton
              width="90%"
              size="md"
              leftIcon={<AddIcon />}
              id="NoFieldScreencreate-new-File-btn"
              onClick={() => setOpenFieldsModal(true)}
              buttonText={LABELS.ACTIONS.ADD_NEW_FIELD}
              variant="solid"
              className={styles.createTemplateBtn}
              bg={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
            />
          </Box>
        </Box>
      </Flex>
      <AddNewFieldModal
        isAddField={openFieldsModal}
        setIsAddField={setOpenFieldsModal}
        onClick={setType}
      />
      {openCreateFieldModal && (
        <CreateEditFieldModal
          type={type}
          setType={setType}
          templateName={LABELS.TITLE.INCIDENT_TICKET}
          // fieldData={{}}
          // edit={true}
          isFieldModel={openCreateFieldModal}
          setIsfieldModel={setOpenCreateFieldModal}
        />
      )}
    </Box>
  );
};

export default NoFieldScreen;
