/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { viewBasedOnType, viewsBasedOnType } from '../DynamicRenderer/helper';
import PreviewBuilder from '../DynamicRenderer/PreviewBuilder';
import { addRoute } from '../../utils/enums/RouteHelper';
import { updateRoute } from '../../redux/AllRouteReducer/AllRouteSlice';
import axiosInstance from '../../utils/axios/axios';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import { getTenantConfig } from '../../helpers/tenantUrl';

const MasterScreenContainer = ({ configJson }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dynamicItemList, setDynamicItemList] = useState([]);
  const allRoutesList = useSelector((state) => state.allRoutes);
  const [showDashboard, setShowDashboard] = useState({
    loading: true,
    show: false,
  });

  const { id } = useParams();

  const createPreview = (json, reactEls, parentViewLayout, parentName) => {
    if (json.label === 'Dashboard' && !showDashboard.show) {
      return null;
    }
    const actions = viewsBasedOnType(json?.actions);
    const elements = viewsBasedOnType(json?.elements, json?.name);
    reactEls.push(...actions, ...elements);
    return viewBasedOnType(json, reactEls, parentViewLayout, parentName);
  };

  const createOutlet = () => <Outlet />;

  const createNestedTree = (parent, views) => {
    const reactEls = [];
    const outletViews = {};
    views?.forEach((i) => {
      if (i.views?.length) {
        const childEls = createNestedTree(
          i,
          i?.views,
          parent?.view_layout,
          parent.name
        );
        const parentReactelement = createPreview(i, childEls);
        if (!parentReactelement) {
          return;
        }
        if (i?.route) {
          const key = parentReactelement?.props?.name;
          outletViews[key] = { ele: parentReactelement, route: i?.route };
          reactEls.push(createOutlet());
        } else {
          reactEls.push(parentReactelement);
        }
      } else {
        const sibling = createPreview(i, [], parent?.view_layout, parent.name);
        if (!sibling) {
          return;
        }
        if (i?.route) {
          const key = sibling?.props?.name;
          outletViews[key] = { ele: sibling, route: i?.route };
          reactEls.push(createOutlet());
        } else {
          reactEls.push(sibling);
        }
      }
    });

    const childOutlets = Object.values(outletViews).map((ins) => ({
      path: ins?.route,
      element: ins?.ele,
    }));

    const routes = addRoute(
      parent.route,
      allRoutesList.allRoutes,
      childOutlets
    );
    dispatch(updateRoute(routes));
    return reactEls;
  };

  useEffect(() => {
    const loadTenantConfig = async () => {
      const res = await getTenantConfig();
      if (!res.error) {
        res?.response?.forEach((item) => {
          if (
            item.config_type === 'uiFeature' ||
            item.configType === 'uiFeature' ||
            item.name === 'serviceCatalog'
          ) {
            setShowDashboard({
              loading: false,
              show: item?.properties?.showDashboard,
            });
          }
        });
      }
    };
    loadTenantConfig();
  }, []);

  useEffect(() => {
    if (!showDashboard.loading) {
      const rootView = createNestedTree(configJson, configJson.views);
      setDynamicItemList(rootView);
    }
  }, [showDashboard]);

  /**
   * TODO: move it to a correct place
   */
  useEffect(() => {
    const loadParticularTemplateData = async () => {
      if (id) {
        const response = await axiosInstance.get(
          `/templates/list?$top=1&$skip=0&$select=*&$filter=id eq ${id}&history=true`
        );
        if (response?.data?.rows?.length > 0) {
          dispatch(
            updateViewState({
              stateKey: 'selectedTemplate',
              value: response?.data?.rows[0],
            })
          );
        } else {
          navigate('/not-found');
        }
      }
    };

    if (window.location.pathname.includes('/template-makers')) {
      loadParticularTemplateData();
    }
    if (window.location.pathname.includes('/bot-integration')) {
      loadParticularTemplateData();
    }
  }, [id]);

  return (
    <div data-testid="MasterScreenContainerDiv">
      <PreviewBuilder
        items={dynamicItemList}
        viewLayout={configJson?.view_layout}
      />
    </div>
  );
};

MasterScreenContainer.propTypes = {
  configJson: PropTypes.object.isRequired,
};

export default MasterScreenContainer;
