import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { COLORS } from '../utils/enums/colors';
import ModalTheme from './CustomTheme/ModalTheme';
import TabsTheme from './CustomTheme/TabsTheme';
import radioTheme from './CustomTheme/RadioTheme';

const dark = '#1A202C';
const light = '#FFFFFF';

const LightTheme = extendTheme({
  components: { Modal: ModalTheme, Tabs: TabsTheme, Radio: radioTheme },
  colors: {
    primary: {
      light: '#319795',
      dark: '#81E6D9',
    },
    selectedBg: {
      light: '#E6FFFA',
      dark: '#2e8c7a4d',
    },
    selectedColor: {
      light: '#285E61',
      dark: '#81E6D9',
    },
    customBlue: {
      500: COLORS.LIGHT,
      200: COLORS.DARK,
      600: COLORS.LIGHT,
      300: COLORS.DARK,
    },
    customRed: {
      600: COLORS.RED,
    },
  },
  borderColors: {
    light: '#E2E8F0',
    dark: '#EDF2F7',
  },
  fonts: {
    body: 'Inter',
    heading: 'Inter',
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
  },
  styles: {
    global: (props) => ({
      body: {
        bg: mode(light, dark)(props),
        color: mode('#2D3748', '#EDF2F7')(props),
      },
      borderColor: mode(COLORS.LIGHT, COLORS.DARK)(props),
      '::before, ::after': {
        content: `""`,
        position: 'absolute',
        borderColor: mode('#E2E8F0', '#EDF2F7')(props),
      },
    }),
  },
  config: {
    cssVarPrefix: 'ck',
  },
});
export default LightTheme;
