/* eslint-disable react/prop-types */
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { COLORS } from '../../utils/enums/colors';

const TextBadge = ({ message }) => (
  <Flex
    alignItems="center"
    bg={useColorModeValue(COLORS.WRAP_BG_LIGHT, COLORS.WRAP_BG_LIGHT)}
    borderRadius="4px"
    fontWeight={500}
    paddingLeft="1rem"
    paddingRight="1rem"
    marginTop="10px"
    width="fit-content"
    height="fit-content"
    marginBottom="10px"
    wrap="wrap"
    color={COLORS.WRAP_COLOR_LIGHT}
  >
    <Text wordBreak="break-word">{message}</Text>
  </Flex>
);

export default TextBadge;
