import React from 'react';

import { Box } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

import PropTypes from 'prop-types';
import NoRecordsIcon from '../../assets/icons/NoRecords.svg';
import { PrimaryCustomButton } from '../Button/PrimarySecondaryButton';

const NoRecords = ({ title, subTitle, buttonText, onAdd }) => (
  <Box
    textAlign="center"
    p="40px"
    border="1px solid #E2E3E4"
    borderRadius="10px"
    m="20px"
    width="450px"
    marginRight="auto"
    marginLeft="auto"
    data-testid="NoRecordContainerBox"
  >
    <Box
      p="10px"
      border="1px solid #E2E3E4"
      display="inline-flex"
      borderRadius="10px"
      data-testid="NoRecordInnerBox"
    >
      <img src={NoRecordsIcon} alt="No Records" />
    </Box>

    <div className="font-semibold text-[30px] mt-5" data-testid="NoRecordTitleDiv">{title}</div>

    <p className="font-normal text-[16px] text-[#968E96] mt-4 mb-7" data-testid="NoRecordSubTitleP">
      {subTitle}
    </p>

    <PrimaryCustomButton
      buttonText={buttonText}
      leftIcon={<AddIcon />}
      onClick={onAdd}
      id="NoRecordButton"
    />

  </Box>
);

NoRecords.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  buttonText: PropTypes.string,
  onAdd: PropTypes.func.isRequired,
};

NoRecords.defaultProps = {
  title: 'No content found',
  subTitle: 'Add some to start',
  buttonText: 'Add'
};

export default NoRecords;
