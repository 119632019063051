import { Text, IconButton, Checkbox } from '@chakra-ui/react';

import React, { useMemo } from 'react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import PropTypes from 'prop-types';
import CustomSelectBox from '../../components/SelectBox/Select';
import customStyleFromRuleForSelect from '../../context/CustomTheme/CustomStyleFromRuleForSelect';
import styles from './mapHierarchy.module.scss';
import { getWholeOption } from '../TicketTemplateWorkflow/helper';
import { FLEX_WIDTHS, getComponentPosition, getDefaultFieldOption } from './utils';

const HierarchyMapperRow = ({
  index,
  rowIndex,
  hierarchyLevels,
  hierarchyMapState,
  dependentFields,
  selectedHierarchyDepth,
  onCheckboxChange,
  disableValueSelect,
  isLoadingHierarchySet,
  onAddRow,
  onDeleteRow,
  onDefaultValueChange,
  onFieldChange,
  isAdminUser,
}) => {
  const hierarchyData = hierarchyMapState[index];
  const fieldId = hierarchyData.map[rowIndex];
  const fieldOptions = (hierarchyData.fieldOptions || {})[fieldId] || getDefaultFieldOption();
  const optionsByLevel = ((hierarchyLevels || [])[rowIndex] || []);
  const defaultValueOptions = [{ label: 'Select a value', value: null }, ...optionsByLevel];

  const filteredDependentFieldsMemo = useMemo(() => {
    const allSelectedFields = [];

    hierarchyMapState.forEach((mapping) => {
      allSelectedFields.push(...mapping.map.filter((item) => !!item));
    });

    const otherSelectedFields = allSelectedFields.filter((item) => item !== fieldId);

    return dependentFields.filter((item) => !otherSelectedFields.includes(item.value));
  }, [dependentFields, hierarchyMapState, fieldId]);

  const isAddDisabled = () => {
    // Disable if no hierarchy is selected
    if (!hierarchyData.heirachyListId) {
      return true;
    }

    // Disabled if no more fields are available
    if ((filteredDependentFieldsMemo || []).filter((item) => item.value !== fieldId).length === 0) {
      return true;
    }

    // Disable if the selected hierarchy depth is reached
    if (rowIndex === selectedHierarchyDepth - 1) {
      return true;
    }

    // Disable if not the last row
    if (rowIndex !== hierarchyData.map.length - 1) {
      return true;
    }

    // Disable if no value is selected
    if (!fieldId) {
      return true;
    }

    return false;
  };

  const isDeleteDisabled = () => {
    // Disable if no hierarchy is selected
    if (!hierarchyData.heirachyListId) {
      return true;
    }
    // Disable if there is only one row
    if (hierarchyData.map.length === 1) {
      return true;
    }

    // Disable if not the last row
    if (rowIndex !== hierarchyData.map.length - 1) {
      return true;
    }

    return false;
  };

  return (
    <div className={styles.hierarchyMapperRow}>
      <div
        className={styles.hierarchyMapperRowComponent}
        style={{
          flex: FLEX_WIDTHS[0],
          justifyContent: getComponentPosition(0),
        }}
      >
        <Text fontSize="sm">
          {`Level ${rowIndex + 1}`}
        </Text>
      </div>

      <div
        className={styles.hierarchyMapperRowComponent}
        style={{
          flex: FLEX_WIDTHS[1],
          justifyContent: getComponentPosition(1),
        }}
      >
        <Checkbox
          isChecked={fieldOptions?.showAllValues}
          isDisabled={
            !hierarchyData.heirachyListId
            || !isAdminUser
            || isLoadingHierarchySet
            || !fieldId
          }
          onChange={() => onCheckboxChange(rowIndex)}
        />
      </div>

      <div
        className={styles.hierarchyMapperRowComponent}
        style={{
          flex: FLEX_WIDTHS[2],
          justifyContent: getComponentPosition(2),
          cursor: !hierarchyData.heirachyListId ? 'not-allowed' : 'pointer'
        }}
      >
        <CustomSelectBox
          isDisabled={!hierarchyData.heirachyListId || isLoadingHierarchySet}
          options={filteredDependentFieldsMemo}
          value={getWholeOption(fieldId, filteredDependentFieldsMemo)}
          onChange={({ value }) => onFieldChange(rowIndex, value)}
          placeholder="Select a field"
          styles={customStyleFromRuleForSelect}
        />
      </div>

      <div
        className={styles.hierarchyMapperRowComponent}
        style={{
          flex: FLEX_WIDTHS[3],
          justifyContent: getComponentPosition(3),
          cursor: (!hierarchyData.heirachyListId || disableValueSelect || !fieldId) ? 'not-allowed' : 'pointer'
        }}
      >
        <CustomSelectBox
          isDisabled={
            !hierarchyData.heirachyListId
            || disableValueSelect
            || isLoadingHierarchySet
            || !fieldId
          }
          options={defaultValueOptions}
          value={fieldOptions?.selectedValue}
          onChange={(selectedValue) => {
            if (!selectedValue.value) {
              onDefaultValueChange(rowIndex, null);
            } else {
              onDefaultValueChange(rowIndex, selectedValue);
            }
          }}
          placeholder="Select a value"
          styles={customStyleFromRuleForSelect}
        />
      </div>

      <div
        className={styles.hierarchyMapperRowComponent}
        style={{
          flex: FLEX_WIDTHS[4],
          justifyContent: getComponentPosition(4),
        }}
      >
        <IconButton
          variant="outline"
          aria-label="Add"
          icon={<AddIcon />}
          isDisabled={isAddDisabled(rowIndex) || isLoadingHierarchySet}
          onClick={() => onAddRow(rowIndex)}
          style={{ marginRight: '8px' }}
        />
        <IconButton
          variant="outline"
          aria-label="Delete"
          icon={<DeleteIcon />}
          isDisabled={isDeleteDisabled(rowIndex) || isLoadingHierarchySet}
          onClick={() => onDeleteRow(rowIndex)}
        />
      </div>

    </div>
  );
};

HierarchyMapperRow.propTypes = {
  index: PropTypes.number.isRequired,
  rowIndex: PropTypes.number.isRequired,
  hierarchyLevels: PropTypes.array.isRequired,
  hierarchyMapState: PropTypes.object.isRequired,
  dependentFields: PropTypes.array.isRequired,
  selectedHierarchyDepth: PropTypes.number.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  disableValueSelect: PropTypes.bool.isRequired,
  isLoadingHierarchySet: PropTypes.bool.isRequired,
  onAddRow: PropTypes.func.isRequired,
  onDeleteRow: PropTypes.func.isRequired,
  onDefaultValueChange: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  isAdminUser: PropTypes.bool.isRequired,
};

export default HierarchyMapperRow;
