import React from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';
import { LABELS } from './utils/enums/labels';

const TicketServiceCatalog = () => (
  <div className="App">
    <Box
      bg={useColorModeValue('white', 'gray.900')}
      width="100%"
      height="90vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      { LABELS.TITLE.TICKET_SERVICE_CATALOGUE }
    </Box>
  </div>
);

export default TicketServiceCatalog;
