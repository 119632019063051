/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import Keycloak from 'keycloak-js';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Login from './Login';
import AppLayout from './layouts/AppLayout';
import ErrorBoundary from './layouts/common/ErrorBoundary';
import ProtectedRoute from './layouts/common/ProtectedRoute';
// import AuthRoute from './layouts/common/AuthRoute';
// import TemplateEditor from './pages/TicketTemplateBuilder/TemplateEditor';
// eslint-disable-next-line max-len
// import TicketTemplateDashboard from './pages/TicketTemplateBuilder/TemplateDashboard/TicketTemplateDashboard';
// eslint-disable-next-line max-len
// import ConfigureTemplateViewContainer from './pages/TicketTemplateBuilder/ConfigureTemplateView/index';
// import Example from './pages/TicketTemplateBuilder/ConfigureTemplateView/DragAndResizeLayout';
// import CreateEditWorkFlow from './pages/TicketTemplateWorkflow/CreateEditWorkFlow/index';
// import FlowWithProvider from './pages/TicketTemplateWorkflow/FlowWithProvider';
import { allRoutesList } from './utils/enums/RouteHelper';
import { getTenantData, getTenantInformation } from './helpers/tenantUrl';
import Loader from './pages/DynamicRenderer/Dashboard/Loader';
import { getUserInfo } from './utils/helper/roleBasedAccess';
import { getUserInfoRole } from './redux/UserService/UserReducer';

const tenantCofig = getTenantData();

export const initOptions = {
  url:
    tenantCofig?.federationServerUrl ||
    'https://federation-sts-test.apps.actionable-science.com/auth',
  realm: tenantCofig?.tenantId || 'multicase',
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || 'adminui-service',
  onLoad: 'login-required',
};

/* for ref old code */
// const loadOption = async () => {
//   if (isEmpty(tenantCofig?.tenantId)) {
//     const result = await getTenantInformation();
//     return {
//       ...initOptions,
//       realm: result?.tenantId,
//       url: result?.federationServerUrl,
//       clientId: 'adminui-service',
//     };
//   }
//   return initOptions;
// };

// let keycloak;
// const token = localStorage.getItem('kc_token');
// const refreshToken = localStorage.getItem('kc_refreshToken');

// const initializeKeyCloak = async () => {
//   const options = await loadOption();
//   keycloak = new Keycloak(options);
//   keycloak
//     .init({
//       onLoad: initOptions.onLoad,
//       token,
//       refreshToken,
//       checkLoginIframe: false,
//     })
//     .then(async (auth) => {
//       if (!auth) {
//         window.location.reload();
//       } else {
//         localStorage.setItem('kc_token', keycloak.token);
//         localStorage.setItem('kc_refreshToken', keycloak.refreshToken);
//         localStorage.setItem('isAuth', auth);
//         const { name } = await keycloak.loadUserInfo();
//         localStorage.setItem('userName', name || '');
//       }
//     });
// };

// initializeKeyCloak();

const Routing = () => {
  const [keycloak, setKeycloak] = useState(null);
  const allRouteList = useSelector((state) => state.allRoutes);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.userInfoRole.data);
  const loadOption1 = async (tenantConfig) => {
    if (!tenantConfig || !tenantConfig.tenantId) {
      const result = await getTenantInformation();
      return {
        url:
          result?.federationServerUrl ||
          'https://federation-sts-test.apps.actionable-science.com/auth',
        realm: result?.tenantId || 'multicase',
        clientId: 'adminui-service',
        onLoad: 'login-required',
      };
    }
    return {
      url:
        tenantConfig.federationServerUrl ||
        'https://federation-sts-test.apps.actionable-science.com/auth',
      realm: tenantConfig.tenantId || 'multicase',
      clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || 'adminui-service',
      onLoad: 'login-required',
    };
  };

  const initializeKeyCloakNew = async () => {
    let timer;
    const tenantConfig = getTenantData();
    const options = await loadOption1(tenantConfig);

    const newKeycloak = new Keycloak(options);

    newKeycloak
      .init({
        onLoad: options.onLoad,
        token: localStorage.getItem('kc_token'),
        refreshToken: localStorage.getItem('kc_refreshToken'),
        checkLoginIframe: false,
      })
      .then(async (auth) => {
        if (!auth) {
          console.warn('No Auth');
          window.location.reload();
        } else {
          //  Logout user before 10sec of exp time
          const { tokenParsed } = newKeycloak;
          const expInMilli = tokenParsed.exp * 1000 - Date.now();
          timer = setTimeout(() => {
            newKeycloak.logout();
          }, expInMilli - 10000);
          console.warn('Authenticated', newKeycloak);
          localStorage.setItem('kc_token', newKeycloak.token);
          localStorage.setItem('kc_refreshToken', newKeycloak.refreshToken);
          localStorage.setItem('isAuth', auth);
          const res = await newKeycloak.loadUserInfo();
          localStorage.setItem('userName', res?.name || '');
          localStorage.setItem('userEmail', res?.email || '');
          localStorage.setItem(
            'preferredUserName',
            res?.preferred_username || ''
          );

          // Passing the keycloack to get the roles & teams
          getUserInfo(newKeycloak);
          dispatch(getUserInfoRole());

          setKeycloak(newKeycloak);
        }
      });

    return timer;
  };

  useEffect(() => {
    const timer = initializeKeyCloakNew();

    return () => {
      if (timer) clearInterval(timer);
    };
  }, []);

  if (!keycloak) {
    // we might want to show a loading indicator here
    return <Loader />;
  }

  return (
    <BrowserRouter
      // basename={process.env.NODE_ENV === 'production' ? '/catalog' : ''}
      basename="/catalog-v2"
    >
      <ErrorBoundary>
        {/* <Routes>
        <Route path="/login" elemekeycloaknt={<Login />} />
        <Route
          path=""
          element={
            <ProtectedRoute>
              <AppLayout keycloak={keycloak} />
            </ProtectedRoute>
          }
        >
          <Route
            path="/"
            element={<Navigate replace to="/template-builder" />}
          />
          <Route path="/template-builder" element={<TicketTemplateBuilder />}>
            <Route path="" element={<TicketTemplateDashboard />} />
            <Route path="/template-builder/:id" element={<TemplateEditor />} />
            <Route path="/template-builder/fieldset/:id" element={<FieldsetEditor />} />
            <Route path="/template-builder/composite/:id" element={<CompositeEditor />} />
            <Route
              path="/template-builder/configure-template-view/:id"
              element={<ConfigureTemplateViewContainer />}
            />
          </Route>
          <Route path="/work-flow" element={<FlowWithProvider />} />
          <Route
            path="/template-catalog"
            element={
              <AuthRoute appRole="admins">
                {' '}
                <TicketServiceCatalog />
              </AuthRoute>
            }
          />
          <Route path="/dashboard" element={<DummyContainer />} />
        </Route>
        <Route path="/work-flow1" element={<CreateEditWorkFlow />} />
        <Route path="/dashboard" element={<Example />} />
        <Route path="/template-maker" element={<TemplateBuilderContainer />} />
        <Route path="/*" element={<NotFoundPage />} />
      </Routes> */}

        {/* New Route Implementation  */}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path=""
            element={
              <ProtectedRoute>
                <AppLayout keycloak={keycloak} />
              </ProtectedRoute>
            }
          >
            {allRoutesList(allRouteList?.allRoutes, keycloak?.realmAccess?.roles)}
          </Route>
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default Routing;
