import { Box, Flex, Tooltip, useColorModeValue } from '@chakra-ui/react';
import React, { useState } from 'react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { findColorPalette, isTemplateApproved } from '../helper';
import CustomButton from '../../../components/Button/SimpleButton';
import StatusDeleteModal from './StatusDeleteModal';
import styles from '../styles/CategoryList.module.scss';
import { BLACK, WHITE } from '../../../utils/enums/colors';
import {
  deleteCategoryFromWorkFlow,
  updateStateOfWorkFlowWithKey,
} from '../../../redux/WorkFlowMainSlice/WorkFlowMainReducer';
import CustomToast from '../../../components/Notification/Notification';

const StatusList = ({ status, setAddingStatus, setEditStatus }) => {
  const dispatch = useDispatch();
  // const selectedWorkFlow = useSelector(
  //   (state) => state.workFlowMain.selectedWorkFlow
  // );
  const { selectedWorkFlow, templateLifeCycle } = useSelector(
    (state) => state.workFlowMain
  );

  const { addToast } = CustomToast();

  const [isDeleteModal, setIsDeleteModal] = useState(false);

  return (
    <Flex justify="flex-end" data-testid="StatusListFlex">
      <Flex
        align="center"
        marginRight="auto"
        justifyContent="flex-start"
        data-testid="StatusListNameFlex"
      >
        <Box maxW="200px" overflow="hidden" data-testid="StatusListNameBox">
          {status?.name}
        </Box>

        <Box
          w="16px"
          h="16px"
          bgColor={findColorPalette(status?.color?.value)}
          ml="10px"
          borderRadius="4px"
        />
      </Flex>
      <Flex justifyContent="flex-end" data-testid="StatusListApprovedFlex">
        <Tooltip
          hasArrow
          label={
            isTemplateApproved(templateLifeCycle)
              ? `Category can't be edited as it was approved.`
              : ''
          }
          placement="top-start"
          data-testid="StatusListApprovedTooltip"
        >
          <Box position="relative" data-testid="StatusListApprovedBox">
            <CustomButton
              color={useColorModeValue(BLACK, WHITE)}
              onClick={() => {
                dispatch(
                  updateStateOfWorkFlowWithKey({
                    keyValue: 'selectedWorkFlowStatusCategory',
                    value: status,
                  })
                );
                setEditStatus(true);
                setAddingStatus(true);
              }}
              buttonText=""
              leftIcon={<EditIcon />}
              className={styles.emptyBtnWithIcon}
              isDisabled={isTemplateApproved(templateLifeCycle)}
              id="StatusListApprovedBoxEmptyyytextbtn"
            />
          </Box>
        </Tooltip>
        <Tooltip
          hasArrow
          label={
            isTemplateApproved(templateLifeCycle)
              ? `Category can't be deleted as it was approved.`
              : ''
          }
          placement="top-start"
          data-testid="StatusListDeleteTooltip"
        >
          <Box position="relative" data-testid="StatusListDeleteBox">
            <CustomButton
              color={useColorModeValue(BLACK, WHITE)}
              onClick={() => {
                const isCategoryUsed = selectedWorkFlow.workflow_status.some(
                  (node) => node.data.categoryId === status.id
                );
                if (isCategoryUsed) {
                  addToast({
                    title: 'Unable to delete category',
                    message: `The category can't be deleted as it is used in the canvas`,
                    type: 'info',
                    duration: 5000,
                    isClosable: true,
                  });
                  return;
                }
                setIsDeleteModal(true);
              }}
              buttonText=""
              leftIcon={<DeleteIcon />}
              className={styles.emptyBtnWithIcon}
              isDisabled={isTemplateApproved(templateLifeCycle)}
              id="StatusListApprovedBoxCycletexttn"
            />
          </Box>
        </Tooltip>
      </Flex>
      <StatusDeleteModal
        statusName={status?.name}
        isDeleteModal={isDeleteModal}
        setIsDeleteModal={setIsDeleteModal}
        onConfirmation={() => {
          dispatch(deleteCategoryFromWorkFlow({ value: status, dispatch }));
          setIsDeleteModal(false);
        }}
      />
    </Flex>
  );
};
StatusList.propTypes = {
  setAddingStatus: PropTypes.func.isRequired,
  setEditStatus: PropTypes.func.isRequired,
  status: PropTypes.object.isRequired,
};
export default StatusList;
