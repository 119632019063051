/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import InfiniteScrollComponent from './InfiniteScrolling';

const ListShort = ({
  label,
  actions,
  filter,
  entity,
  columns,
  name,
  style,
  apiReq,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { endPoint, reqCols, orderBy } = apiReq;
  const { viewState } = useSelector((state) => state);

  useEffect(() => {
    dispatch(
      updateViewState({
        stateKey: name,
        value: { filter: '', data: [] },
      })
    );
    return () => {
      dispatch(
        updateViewState({
          stateKey: name,
          value: { filter: '', data: [] },
        })
      );
    };
  }, [viewState[name]?.refresh]);

  const onClickListItem = (value) => {
    const action = actions[0];
    switch (action.target_type) {
      case 'navigate':
        navigate(
          `/${action.target}${value[action.target_params[0]]}?name=${
            value[action.target_params[1]]
          }`
        );
        break;
      case 'popup':
        // code for pop-up
        break;
      default:
    }
  };

  return (
    <Box style={style} data-testid="ListShortBox">
      <InfiniteScrollComponent
        endPoint={endPoint}
        requestedCols={reqCols}
        orderBy={orderBy}
        columns={columns}
        listStateKey={name}
        onClickListItem={onClickListItem}
        name={name}
      />
    </Box>
  );
};

export default ListShort;
