import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DrawerContent,
  Drawer,
  DrawerHeader,
  Flex,
  IconButton,
  Text,
  useColorModeValue,
  DrawerFooter,
  DrawerBody,
  Box,
  Grid,
  GridItem,
  DrawerOverlay,
} from '@chakra-ui/react';
import { map, set, isEmpty } from 'lodash';
import { ArrowBackIcon, DeleteIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';
import { LABELS } from '../../utils/enums/labels';
import { COLORS } from '../../utils/enums/colors';
import CustomInput from '../../components/InputBox/Input';
import CustomSelectBox from '../../components/SelectBox/Select';
import { getStatusObject } from '../DynamicRenderer/AddEditSignatureDrawer/utils';
import {
  DRAWER_TITLE,
  ROUTING_QUEUE_KEY_MAP,
  ROUTE_INITIAL_DATA,
} from './utils';
import { getTenantData } from '../../helpers/tenantUrl';
import axiosInstance from '../../utils/axios/axios';
import ConditionsExpressions from '../../components/ConditionsExpressions/ConditionsExpressions';
import { PrimaryCustomButton, SecondaryCustomButton } from '../../components/Button/PrimarySecondaryButton';

const filterWhitelistedQueues = (fields, team, queueOptions, currentQueueField) => {
  const queueField = (fields || []).find((field) => field.id === currentQueueField);
  if (queueField && team && queueOptions && queueOptions.length) {
    const allowedQueues = queueField?.type_based_attributes?.collection?.list || [];
    const isWhitelist = queueField?.type_based_attributes?.collection?.isWhiteList;

    if (isWhitelist) {
      return queueOptions.filter(
        (option) => allowedQueues.some((allowedQueue) => allowedQueue.queueId === option.value)
      );
    }

    return queueOptions;
  }
  return queueOptions;
};

const EditQueueFieldDrawer = ({
  setOpenDrawer,
  openDrawer,
  selectedRowData,
  onSaveData,
  errorWithPathAsKey,
  setErrorWithPathAsKey,
  isLoading,
}) => {
  const [rowData, setRowData] = useState(
    {
      ...selectedRowData,
    } || {}
  );
  const { selectedTemplate } = useSelector((state) => state.viewState || {});
  const [teamOptions, setTeamOptions] = useState([]);
  const [defaultQueueOptions, setDefaultQueueOptions] = useState([]);
  const [teamMappedQueueData, setTeamMappedQueueData] = useState([]);

  const getTeam = async () => {
    const tenantInformation = getTenantData();
    const tenantId = tenantInformation?.id;
    const params = {
      tenant_id: tenantId,
    };
    const response = await axiosInstance.get('teams', { params });
    return response.data;
  };

  const getQueue = async (team) => {
    const tenantInformation = getTenantData();
    const tenantId = tenantInformation?.id;
    const params = {
      tenant_id: tenantId,
      team_name: team,
    };
    const response = await axiosInstance.get('queueTypes/teams', { params });
    const data = response?.data[0];
    const options = map(data, (item) => ({
      label: item.properties?.queueLabel || item.queue_name,
      value: item.id,
    }));
    return options;
  };

  const getTeamMappedQueueData = async (team) => {
    const queueTeamMappedOptions = {};
    // Using map to create an array of promises
    const promises = team.map(async (item) => {
      const optionData = await getQueue(item);
      queueTeamMappedOptions[item] = optionData;
    });
    // Wait for all promises to settle
    await Promise.all(promises);

    setTeamMappedQueueData({ ...queueTeamMappedOptions });
  };

  useEffect(() => {
    const getOptions = getTeam();
    getOptions.then((res) => {
      const data = res[0];
      const team = data.length ? data[0].team : [];
      const options = map(team, (item) => ({
        label: item,
        value: item,
      }));
      setTeamOptions(options);
      getTeamMappedQueueData(team);
    });
  }, []);

  useEffect(() => {
    const getOptions = getQueue(rowData[ROUTING_QUEUE_KEY_MAP.DEFAULT_TEAM]);
    getOptions.then((data) => setDefaultQueueOptions(data));
  }, [rowData?.default_team]);

  const handleOnChange = (value, key) => {
    setRowData((pre) => ({
      ...pre,
      [key]: value,
    }));
  };

  const onChangeRouteData = (value, path) => {
    const newRouteData = [...rowData?.routes || []];
    set(newRouteData, path, value);
    setRowData((prevData) => ({
      ...prevData,
      routes: newRouteData,
    }));
  };

  const onAddRouteData = () => {
    const initialData = { ...ROUTE_INITIAL_DATA };
    const newRouteData = [...rowData?.routes || [], initialData];
    setRowData((prevData) => ({
      ...prevData,
      routes: newRouteData,
    }));
  };

  const onDeleteRouteData = (index) => {
    const newRouteData = [...rowData?.routes || []];
    newRouteData.splice(index, 1);
    setRowData((prevData) => ({
      ...prevData,
      routes: newRouteData,
    }));
  };

  return (
    <Drawer
      size="xl"
      placement="right"
      onClose={setOpenDrawer}
      isOpen={openDrawer}
      data-testid="EditQueueFieldDrawer"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Flex alignItems="center" gap="10px">
            <IconButton
              colorScheme="blue"
              aria-label="Close Drawer"
              size="sm"
              borderRadius="50%"
              minW="1.5rem"
              height="1.5rem"
              variant="outline"
              icon={<ArrowBackIcon />}
              onClick={() => setOpenDrawer(false)}
            />
            <Text fontSize="16px">{DRAWER_TITLE}</Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <Text style={{ fontWeight: 'bold', marginBottom: '15px' }}>
            {rowData[ROUTING_QUEUE_KEY_MAP.NAME]}
          </Text>
          <Box>
            <Grid display="flex" gap="30px" templateColumns="5.5fr 5.5fr 1fr">
              <GridItem pt="30px" w="100%">
                <CustomSelectBox
                  value={getStatusObject(
                    rowData[ROUTING_QUEUE_KEY_MAP.DEFAULT_TEAM],
                    teamOptions
                  )}
                  options={teamOptions}
                  onChange={(e) => {
                    handleOnChange(e.value, [
                      ROUTING_QUEUE_KEY_MAP.DEFAULT_TEAM,
                    ]);
                  }}
                  label="Default Team"
                  aria-label="Default Team"
                />
              </GridItem>
              <GridItem pt="30px" w="100%">
                <CustomSelectBox
                  value={getStatusObject(
                    rowData[ROUTING_QUEUE_KEY_MAP.DEFAULT_QUEUE_ID],
                    defaultQueueOptions
                  )}
                  options={filterWhitelistedQueues(
                    selectedTemplate.fields,
                    rowData[ROUTING_QUEUE_KEY_MAP.DEFAULT_TEAM],
                    defaultQueueOptions,
                    selectedRowData.queue_field_id
                  )}
                  onChange={(e) => {
                    handleOnChange(e.value, [
                      ROUTING_QUEUE_KEY_MAP.DEFAULT_QUEUE_ID,
                    ]);
                  }}
                  label="Default Queue"
                  aria-label="Queue"
                />
              </GridItem>
            </Grid>
          </Box>
          <Box>
            {rowData.routes.map((item, index) => !item ? null : (
                <Box
                  data-testid="template-field-list"
                  border="2px solid #E2E8F0"
                  m="10px 0"
                  p="10px"
                >
                  <Grid display="flex" justifyContent="space-between">
                    <GridItem pt="10px">
                      <Text fontSize="13px" fontWeight="bold">
                        {`Route ${index + 1}`}
                      </Text>
                    </GridItem>
                    <GridItem pt="10px" alignSelf="center">
                      <DeleteIcon
                        mx="4"
                        color="red"
                        onClick={() => {
                          setErrorWithPathAsKey((pre) => ({
                            ...pre,
                            [`${ROUTING_QUEUE_KEY_MAP.ROUTES}.${index}`]: '',
                          }));
                          onDeleteRouteData(index);
                        }}
                      />
                    </GridItem>
                  </Grid>
                  <Box
                    // border="2px solid #E2E8F0"
                    data-testid="CreateEditCategoryPalleteBox"
                  >
                    {/* <DynamicExpressionWithTags
                      templateId={selectedTemplate?.id}
                      values={item?.[ROUTING_QUEUE_KEY_MAP.CONDITIONS]}
                      onChange={(e) =>
                        onChangeRouteData(
                          e,
                          `[${index}].[${ROUTING_QUEUE_KEY_MAP.CONDITIONS}]`
                        )
                      }
                      label="Conditions"
                    /> */}
                    <ConditionsExpressions
                      templateId={selectedTemplate?.id}
                      value={item?.[ROUTING_QUEUE_KEY_MAP.CONDITIONS]}
                      onChange={(e) => {
                        if (Object.keys(e).length) {
                          onChangeRouteData(
                            e,
                            `[${index}].[${ROUTING_QUEUE_KEY_MAP.CONDITIONS}]`
                          );
                        }
                      }
                      }
                      label="Conditions"
                    />
                  </Box>
                  <Text fontSize="13px" fontWeight="bold" pt="20px">
                    Move it to
                  </Text>
                  <Grid
                    display="flex"
                    gap="30px"
                    templateColumns="5.5fr 5.5fr 1fr"
                  >
                    <GridItem pt="20px" w="100%">
                      <CustomSelectBox
                        value={item?.[ROUTING_QUEUE_KEY_MAP.TEAM] && getStatusObject(
                          item?.[ROUTING_QUEUE_KEY_MAP.TEAM],
                          teamOptions
                        )}
                        options={teamOptions}
                        onChange={(e) =>
                          onChangeRouteData(
                            e.value,
                            `[${index}].[${ROUTING_QUEUE_KEY_MAP.TEAM}]`
                          )
                        }
                        label="Team"
                        aria-label="Team"
                      />
                    </GridItem>

                    <GridItem pt="20px" w="100%">
                      <CustomSelectBox
                        value={
                          !isEmpty(teamMappedQueueData) &&
                          teamMappedQueueData[item.team]
                          && item?.[ROUTING_QUEUE_KEY_MAP.QUEUE_ID]
                            ? getStatusObject(
                              item?.[ROUTING_QUEUE_KEY_MAP.QUEUE_ID],
                              teamMappedQueueData[item.team]
                            ) : {}
                        }
                        options={
                          filterWhitelistedQueues(
                            selectedTemplate.fields,
                            rowData[ROUTING_QUEUE_KEY_MAP.DEFAULT_TEAM],
                            teamMappedQueueData[item.team],
                            selectedRowData.queue_field_id
                          )
                        }
                        onChange={(e) => {
                          onChangeRouteData(
                            e.value,
                            `[${index}].[${ROUTING_QUEUE_KEY_MAP.QUEUE_ID}]`
                          );
                        }}
                        label="Queue"
                        aria-label="Queue"
                      />
                    </GridItem>
                  </Grid>
                  <div style={{ marginTop: '20px' }}>
                    <CustomInput
                      onChange={(e) => {
                        onChangeRouteData(
                          e.target.value,
                          `[${index}].[${ROUTING_QUEUE_KEY_MAP.RANK}]`
                        );
                        setErrorWithPathAsKey((pre) => ({
                          ...pre,
                          [`${ROUTING_QUEUE_KEY_MAP.ROUTES}.${index}`]: '',
                        }));
                      }}
                      value={item?.[ROUTING_QUEUE_KEY_MAP.RANK]}
                      placeholder=""
                      helperText=" "
                      label="Rank"
                      data-testid="EditQueueFieldDrawerDescription"
                      height="38px"
                      isError={
                        errorWithPathAsKey[
                          `${ROUTING_QUEUE_KEY_MAP.ROUTES}.${index}`
                        ] || false
                      }
                      errorMessage={
                        errorWithPathAsKey[
                          `${ROUTING_QUEUE_KEY_MAP.ROUTES}.${index}`
                        ] || ''
                      }
                    />
                  </div>
                  <div style={{ marginTop: '20px' }}>
                    <CustomInput
                      onChange={(e) =>
                        onChangeRouteData(
                          e.target.value,
                          `[${index}].[${ROUTING_QUEUE_KEY_MAP.DESCRIPTION}]`
                        )
                      }
                      value={item?.[ROUTING_QUEUE_KEY_MAP.DESCRIPTION]}
                      placeholder=""
                      helperText=" "
                      label="Description"
                      data-testid="EditQueueFieldDrawerDescription"
                      height="38px"
                    />
                  </div>
                </Box>
            ))}
           <Box className="pt-2">
<SecondaryCustomButton
  buttonText="Add New Route"
  onClick={() => {
    onAddRouteData();
  }}
/>
           </Box>
          </Box>
        </DrawerBody>
        <DrawerFooter display="flex" alignItems="center" gap="8px">
          <SecondaryCustomButton
            buttonText={LABELS.ACTIONS.DISCARD}
            onClick={() => {
              setOpenDrawer(false);
            }}
          />
          <PrimaryCustomButton
            buttonText="Save"
            onClick={() => onSaveData(rowData)}
            isLoading={isLoading}
            id="FilterEditModalButtonSaveBtn"
          />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

EditQueueFieldDrawer.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  setOpenDrawer: PropTypes.func.isRequired,
  selectedRowData: PropTypes.object,
  onSaveData: PropTypes.func.isRequired,
  errorWithPathAsKey: PropTypes.object,
  setErrorWithPathAsKey: PropTypes.func,
  isLoading: PropTypes.bool,
};
EditQueueFieldDrawer.defaultProps = {
  selectedRowData: {},
  errorWithPathAsKey: {},
  setErrorWithPathAsKey: () => {},
  isLoading: false,
};
export default EditQueueFieldDrawer;
