/* eslint-disable react/prop-types */
import React from 'react';
import { Box } from '@chakra-ui/react';
import CustomInput from '../../components/InputBox/Input';
import CustomSelectBox from '../../components/SelectBox/Select';
import { getWholeOption } from '../TicketTemplateWorkflow/helper';
import customStylesReactSelect from '../../context/CustomTheme/ReactSelectTheme';
import { AUTH_TYPE, AUTH_TYPES } from './utils';
import { MAIL_STATUS_OPTION } from '../../utils/enums/selectOption';

const ConnectMailServer = ({
  mailServerState,
  handleInputWithPath,
  errorListWithKey,
}) => (
  <Box>
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap="32px"
      py="20px"
    >
      <Box width="50%">
        <CustomSelectBox
          isError={errorListWithKey?.protocol || false}
          options={[{ value: 'IMAP', label: 'IMAP' }]}
          onChange={(e) => {
            handleInputWithPath(e.value, 'protocol');
          }}
          value={getWholeOption(mailServerState.protocol, [
            { value: 'IMAP', label: 'IMAP' },
          ])}
          label="Protocol"
          errorMessage={errorListWithKey?.protocol || ''}
        />
      </Box>
      <Box w="50%">
        <CustomInput
          label="Host/IP"
          value={mailServerState.host || ''}
          onChange={(e) => {
            handleInputWithPath(e.target.value, 'host');
          }}
          isError={errorListWithKey?.host || false}
          errorMessage={errorListWithKey?.host || ''}
        />
      </Box>
    </Box>

    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gap="32px"
      pb="20px"
    >
      <Box width="50%">
        <CustomInput
          label="Username"
          value={mailServerState.userName || ''}
          onChange={(e) => {
            handleInputWithPath(e.target.value, 'userName');
          }}
          isError={errorListWithKey?.userName || false}
          errorMessage={errorListWithKey?.userName || ''}
        />
      </Box>
      <Box width="50%">
        <CustomInput
          label="Port"
          type="number"
          value={mailServerState.port || ''}
          onChange={(e) => {
            handleInputWithPath(e.target.value, 'port');
          }}
          isError={errorListWithKey?.port || false}
          errorMessage={errorListWithKey?.port || ''}
          w="100%"
        />
      </Box>
    </Box>

    <Box w="100%" pb="20px">
      <CustomSelectBox
        isError={errorListWithKey?.authType || false}
        options={AUTH_TYPE}
        onChange={(e) => {
          handleInputWithPath(e.value, 'authType');
        }}
        value={getWholeOption(mailServerState.authType, AUTH_TYPE)}
        label="Auth Type"
        errorMessage={errorListWithKey?.authType || ''}
        styles={customStylesReactSelect}
      />
    </Box>

    {mailServerState.authType === AUTH_TYPES.PASSWORD && (
      <Box w="100%" pb="20px">
        <CustomInput
          label="Password"
          value={mailServerState.password || ''}
          onChange={(e) => {
            handleInputWithPath(e.target.value, 'password');
          }}
          isError={errorListWithKey?.password || false}
          errorMessage={errorListWithKey?.password || ''}
          type="password"
        />
      </Box>
    )}
    {mailServerState.authType === AUTH_TYPES.O_AUTH && (
      <>
        <Box w="100%" pb="20px">
          <CustomInput
            label="Azure Active Directory Tenant Id"
            value={mailServerState.azureDirId || ''}
            onChange={(e) => handleInputWithPath(e.target.value, 'azureDirId')}
            isError={errorListWithKey?.azureDirId || false}
            errorMessage={errorListWithKey?.azureDirId || ''}
          />
        </Box>
        <Box w="100%" pb="20px">
          <CustomInput
            label="Redirect URL"
            value={mailServerState.redirectUrl || ''}
            onChange={(e) => {
              handleInputWithPath(e.target.value, 'redirectUrl');
            }}
            isError={errorListWithKey?.redirectUrl || false}
            errorMessage={errorListWithKey?.redirectUrl || ''}
          />
        </Box>

        <Box w="100%" pb="20px">
          <CustomInput
            label="Refresh Token"
            value={mailServerState.refreshToken || ''}
            onChange={(e) => {
              handleInputWithPath(e.target.value, 'refreshToken');
            }}
            isError={errorListWithKey?.refreshToken || false}
            errorMessage={errorListWithKey?.refreshToken || ''}
          />
        </Box>

        <Box w="100%" pb="20px">
        <CustomInput
          label="Client ID"
          value={mailServerState.clientId || ''}
          onChange={(e) => {
            handleInputWithPath(e.target.value, 'clientId');
          }}
          isError={errorListWithKey?.clientId || false}
          errorMessage={errorListWithKey?.clientId || ''}
        />
        </Box>

        <Box w="100%" pb="20px">
        <CustomInput
          label="Client Secret"
          value={mailServerState.clientSecret || ''}
          onChange={(e) => {
            handleInputWithPath(e.target.value, 'clientSecret');
          }}
          isError={errorListWithKey.clientSecret || false}
          errorMessage={errorListWithKey.clientSecret || ''}
        />
        </Box>

        <Box w="100%" pb="20px">
        <CustomInput
          label="Scope"
          value={mailServerState.scope || ''}
          onChange={(e) => {
            handleInputWithPath(e.target.value, 'scope');
          }}
          isError={errorListWithKey?.scope || false}
          errorMessage={errorListWithKey?.scope || ''}
        />
        </Box>

        <Box w="100%" pb="20px">
        <CustomInput
          label="Token Endpoint"
          value={mailServerState.tokenEndpoint || ''}
          onChange={(e) => {
            handleInputWithPath(e.target.value, 'tokenEndpoint');
          }}
          isError={errorListWithKey.tokenEndpoint || false}
          errorMessage={errorListWithKey.tokenEndpoint || ''}
        />
        </Box>
      </>
    )}
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap="32px"
      pb="20px"
    >
      <Box w="50%">
        <CustomInput
          label="Max tries for Connection before Fail"
          type="number"
          value={mailServerState.maxTry || ''}
          onChange={(e) => handleInputWithPath(e.target.value, 'maxTry')}
          isError={errorListWithKey?.maxTry || false}
          errorMessage={errorListWithKey?.maxTry || ''}
        />
      </Box>
      <Box w="50%">
        <CustomInput
          label="Cooloff Time after Fail (Mins)"
          type="number"
          value={mailServerState.coolOffTime || ''}
          onChange={(e) => handleInputWithPath(e.target.value, 'coolOffTime')}
          isError={errorListWithKey?.coolOffTime || false}
          errorMessage={errorListWithKey?.coolOffTime || ''}
        />
      </Box>
    </Box>

    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap="32px"
      pb="20px"
    >
      <Box w="50%">
        <CustomInput
          type="number"
          label="Skip Times"
          value={mailServerState.skipTime || ''}
          onChange={(e) => handleInputWithPath(e.target.value, 'skipTime')}
          isError={errorListWithKey?.skipTime || false}
          errorMessage={errorListWithKey?.skipTime || ''}
        />
      </Box>

      <Box w="50%">
        <CustomInput
          label="Connection Timeout (Milli secs)"
          type="number"
          value={mailServerState.timeout}
          onChange={(e) => handleInputWithPath(e.target.value, 'timeout')}
          isError={errorListWithKey?.timeout || false}
          errorMessage={errorListWithKey?.timeout || ''}
        />
      </Box>
    </Box>
    <Box w="100%">
      <CustomSelectBox
        isError={errorListWithKey?.status || false}
        options={MAIL_STATUS_OPTION}
        onChange={(e) => {
          handleInputWithPath(e.value, 'lifecycle_status');
        }}
        value={getWholeOption(
          mailServerState?.lifecycle_status || 'DRAFT',
          MAIL_STATUS_OPTION
        )}
        label="Status"
        errorMessage={errorListWithKey?.lifecycle_status || ''}
        // styles={customStylesReactSelect}
      />
    </Box>
  </Box>
);
export default ConnectMailServer;
