// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Z6HFU9tf9yrhHByypT4T>ul{align-items:center}.DnjEIv2DalvNcKvuBdQB{width:100% !important}`, "",{"version":3,"sources":["webpack://./src/components/ConditionsExpressions/dynamicExpressionWithTags.module.scss"],"names":[],"mappings":"AAyBA,yBACE,kBAAA,CAEF,sBACC,qBAAA","sourcesContent":["$breakpoint-xs: 320px;\n$breakpoint-sm: 576px;\n$breakpoint-md: 768px;\n$breakpoint-lg: 992px;\n$breakpoint-xl: 1200px;\n@mixin breakpoint($breakpoint) {\n    @if $breakpoint == xs {\n      @media (max-width: 319px) { @content; }\n    }\n    @else if $breakpoint == sm {\n      @media (min-width: 320px) and (max-width: 575px) { @content; }\n    }\n    @else if $breakpoint == md {\n      @media (min-width: 576px) and (max-width: 767px) { @content; }\n    }\n    @else if $breakpoint == lg {\n      @media (min-width: 768px) and (max-width: 991px) { @content; }\n    }\n    @else if $breakpoint == xl {\n      @media (min-width: 992px) and (max-width: 1199px) { @content; }\n    }\n    @else if $breakpoint == xxl {\n      @media (min-width: 1200px) { @content; }\n    }\n  }\n.expression__tag__container > ul {\n\t\talign-items: center;\n\t}\n.inputBox {\n\twidth: 100% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expression__tag__container": `Z6HFU9tf9yrhHByypT4T`,
	"inputBox": `DnjEIv2DalvNcKvuBdQB`
};
export default ___CSS_LOADER_EXPORT___;
