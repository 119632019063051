/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import { renderFieldsBasedonType } from './utils/RenderFieldsBasedOnType';

const PlainSectionLayout = ({ item, previewRenderer }) => (
  <Grid
    templateColumns="repeat(12, 1fr)"
    flex="1 1 0px"
    gap={4}
    py="4"
    pl="2"
    pr="2"
    data-testid="PlainSectionLayoutGrid"
  >
    <GridItem colSpan={12} data-testid="PlainSectionLayoutGridItem">
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        data-testid="PlainSectionLayoutBox"
      >
        <Box>{!item?.hideSectionLabel && item?.sectionName}</Box>
      </Box>
    </GridItem>
    {item?.type === 'component' ? (
      previewRenderer(item?.subItems)
    ) : !item?.subItems?.length ? (
      <GridItem colSpan={12}>{}</GridItem>
    ) : (
      item?.subItems?.map((subItem) => (
        <Box
          key={subItem.id}
          gridColumnEnd={`span ${subItem.width}`}
          display="block"
          alignItems="center"
          justifyContent="space-between"
          data-testid="PlainSectionLayoutTypeBox"
        >
          <>
            <Box p="2">{subItem?.label}</Box>
            {renderFieldsBasedonType(subItem)}
          </>
        </Box>
      ))
    )}
  </Grid>
);

PlainSectionLayout.propTypes = {
  item: PropTypes.array.isRequired,
  previewRenderer: PropTypes.func.isRequired,
};

export default PlainSectionLayout;
