/* eslint-disable camelcase */
import React from 'react';
import { useStoreApi } from 'reactflow';
import { Box, Checkbox, Flex, FormLabel } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import styles from '../styles/RestrictMoveRole.module.scss';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { WorkFlowNodeTypes } from '../../../utils/enums/types';
import {
  CAUTION_STATUS,
  COLORS,
  DONE_STATUS,
  FIXING_STATUS,
  IN_PROGRESS_STATUS,
  TO_DO_STATUS,
} from '../../../utils/enums/colors';
import { getWholeOption, mapToJsonArray } from '../helper';
import { LABELS } from '../../../utils/enums/labels';
import { changeSelectedRuleWithKey } from '../../../redux/WorkFlowMainSlice/WorkFlowMainReducer';

const RestrictSpecificStatusContainer = () => {
  const dispatch = useDispatch();
  const { selectedWorkFlowRule } = useSelector((state) => state.workFlowMain);
  const flowStore = useStoreApi();
  const { edges, nodeInternals } = flowStore.getState();
  const innerEdgeList = edges
    .filter((i) => i.id !== 'edges_1')
    .map((i) => ({
      label: i.data?.label,
      value: i?.id,
      source: i?.source,
      target: i?.target,
    }));
  const innerStatusList = mapToJsonArray(nodeInternals)
    .filter((i) => i.id !== 'start_1')
    .map((i) => ({
      label: i.data?.label,
      value: i?.id,
    }));
  // const [transitionState, setTransitionState] = useState({});
  // const [selectedNode, setSelectedNode] = useState({});
  // const [includeStatus, setIncludeStatus] = useState(false);
  // const [reverseRuleStatus, setReverseRuleStatus] = useState(false);
  const determineBgColor = (type) => {
    if (type === WorkFlowNodeTypes.toDoNode) {
      return TO_DO_STATUS;
    }
    if (type === WorkFlowNodeTypes.toProgressNode) {
      return IN_PROGRESS_STATUS;
    }
    if (type === WorkFlowNodeTypes.toDoneNode) {
      return DONE_STATUS;
    }
    if (type === WorkFlowNodeTypes.toCautionNode) {
      return CAUTION_STATUS;
    }
    if (type === WorkFlowNodeTypes.toFixingNode) {
      return FIXING_STATUS;
    }
    return TO_DO_STATUS;
  };
  // useEffect(() => {
  //   const matchingRestrictIssue = restrict_specific_status.find(
  //     (issue) => issue.transition_id === transitionState?.value
  //   );
  //   if (matchingRestrictIssue) {
  //     setSelectedNode({
  //       value: matchingRestrictIssue[RULE_SPECIFIC_STATUS_KEY.STATUS_ID],
  //       label: getLabelByValue(
  //         matchingRestrictIssue[RULE_SPECIFIC_STATUS_KEY.STATUS_ID],
  //         innerStatusList
  //       ),
  //     });
  //     setIncludeStatus(
  //       matchingRestrictIssue[RULE_SPECIFIC_STATUS_KEY.INCLUDE_STATUS]
  //     );
  //     setReverseRuleStatus(
  //       matchingRestrictIssue[RULE_SPECIFIC_STATUS_KEY.REVERSE_RULE]
  //     );
  //   }
  // }, [transitionState.value]);

  return (
    <>
      <FormLabel
        marginTop="30px"
        className={styles.formLabelRestric}
        data-testid="RestrictSpecificStatusContainerTransFormLabel"
      >
        {LABELS.TITLE.FOR_TRANSTION}
      </FormLabel>
      <CustomSelectBox
        name=""
        value={getWholeOption(selectedWorkFlowRule.transitionId, innerEdgeList)}
        options={innerEdgeList}
        onChange={(e) => {
          // setIncludeStatus(false);
          // setReverseRuleStatus(false);
          // setSelectedNode({});
          dispatch(
            changeSelectedRuleWithKey({
              keyValue: 'transitionId',
              value: e.value,
            })
          );
        }}
        // eslint-disable-next-line react/no-unstable-nested-components
        getOptionLabel={(e) => (
          <Flex align="center" className="text-[14px] font-500">
            <span className="ml-1">{e.label}</span>
            <Box
              className="ml-[10px] mr-[10px] text-white pl-[7px] pr-[7px]"
              bgColor={determineBgColor(nodeInternals.get(e.source)?.type)}
              borderRadius="4px"
            >
              {nodeInternals.get(e.source)?.data.label}
            </Box>
            {e?.label && <ArrowForwardIcon />}
            <Box
              className="ml-[10px] mr-[10px] text-white pl-[7px] pr-[7px]"
              bgColor={determineBgColor(nodeInternals.get(e.target)?.type)}
              borderRadius="4px"
            >
              {nodeInternals.get(e.target)?.data.label}
            </Box>
          </Flex>
        )}
        id="RestrictSpecificStatusSelectBox"
      />
      <FormLabel
        marginTop="40px"
        className={styles.formLabelRestric}
        data-testid="RestrictSpecificStatusContainerRuleFormLabel"
      >
        {LABELS.TITLE.CHECK_ISSUE_RULE}
      </FormLabel>
      <CustomSelectBox
        name=""
        value={getWholeOption(selectedWorkFlowRule.statusId, innerStatusList)}
        options={innerStatusList}
        onChange={(e) => {
          dispatch(
            changeSelectedRuleWithKey({
              keyValue: 'statusId',
              value: e.value,
            })
          );
        }}
        // eslint-disable-next-line react/no-unstable-nested-components
        getOptionLabel={(e) => (
          <Flex
            align="center"
            className="text-[14px] font-500"
            data-testid="RestrictSpecificStatusContainerBoxFlex"
          >
            <Box
              className="text-white pl-[7px] pr-[7px]"
              bgColor={determineBgColor(nodeInternals.get(e.value)?.type)}
              borderRadius="4px"
              data-testid="RestrictSpecificStatusContainerLabelBox"
            >
              {e.label}
            </Box>
          </Flex>
        )}
        id="RestrictSpecificStatusNodeSelectBox"
      />
      <Box
        marginTop="50px"
        data-testid="RestrictSpecificStatusContainerIncludedBox"
      >
        <Checkbox
          fontWeight={600}
          isChecked={selectedWorkFlowRule.includedStatus}
          colorScheme="customBlue"
          onChange={(e) => {
            dispatch(
              changeSelectedRuleWithKey({
                keyValue: 'includedStatus',
                value: e.target.checked,
              })
            );
          }}
          data-testid="RestrictSpecificStatusContainerIncludedCheckBox"
        >
          {LABELS.TITLE.INCLUDE_ISSUE_STATUS}
        </Checkbox>
      </Box>
      <Box
        marginTop="20px"
        data-testid="RestrictSpecificStatusContainerReverseBox"
      >
        <Checkbox
          fontWeight={600}
          isChecked={selectedWorkFlowRule.reverseRuleStatus}
          colorScheme="customBlue"
          onChange={(e) => {
            dispatch(
              changeSelectedRuleWithKey({
                keyValue: 'reverseRuleStatus',
                value: e.target.checked,
              })
            );
          }}
          data-testid="RestrictSpecificStatusContainerReverseCheckBox"
        >
          {LABELS.TITLE.REVERSE_THE_RULE}
        </Checkbox>
      </Box>
      <Box
        fontSize="13px"
        ml="25px"
        color={COLORS.DEFAULT}
        data-testid="RestrictSpecificStatusContainerTransIssueBox"
      >
        {LABELS.TITLE.TRANSITION_ISSUE_HAS_NOT}
      </Box>
    </>
  );
};

export default RestrictSpecificStatusContainer;
