/* eslint-disable camelcase */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CustomButton from '../../../components/Button/SimpleButton';
import NoRecords from '../../../components/Table/NoRecords';
import TableViewWithSearch from '../../../components/Table/TableWithSearch';
import SelectedDefaultField from './SelectedDefaultField';
import { COLORS } from '../../../utils/enums/colors';
import { updateViewState } from '../../../redux/ViewSlice/ViewReducer';
import {
  columns,
  getDynamicCurlMap,
  setDefaultValue,
  getValues,
} from './utils';
import { patchDataForTemplate } from '../../TicketTemplateBuilder/utils/templateAPIUtils';

const FieldDefaults = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { selectedTemplate, ticket_template_fields_list_view } = useSelector(
    (state) => state.viewState || {}
  );

  // Template fields
  const TEMPLATE_FIELDS = useMemo(
    () => ticket_template_fields_list_view?.data || [],
    [ticket_template_fields_list_view]
  );

  // Default values
  const DEFAULT_VALUES = useMemo(
    () => selectedTemplate?.default_values || {},
    [selectedTemplate]
  );

  const defaultValuesMap = useRef(DEFAULT_VALUES || {});
  const currentSelectedTemplate = useRef({});
  const isDirty = useRef(false);

  const [data, setData] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [key, setKey] = useState(1);
  const [isAdded, setIsAdded] = useState(false);

  // Creating the map for the default values
  const enrichedData = useMemo(
    () =>
      TEMPLATE_FIELDS.map((field) => ({
        id: field?.id || '',
        field_name: field?.label || '',
        type: field.type,
        default_value: setDefaultValue(field.type, DEFAULT_VALUES?.[field.id]),
        values: getValues(field),
        collection: field?.type_based_attributes?.collection || {},
      })),
    [TEMPLATE_FIELDS, DEFAULT_VALUES]
  );

  const enrichedDataMap = useMemo(() => {
    const map = {};
    (enrichedData || []).forEach((t) => {
      map[t.id] = t;
    });

    return map;
  }, [enrichedData]);

  const saveDefaultValues = async (defaultData = defaultValuesMap.current) => {
    if (!Object.keys(defaultData).length) {
      return '';
    }

    const res = await patchDataForTemplate({
      id,
      data: defaultData,
      key: 'default_values',
      defaultErrorMessage: 'Failed to set the default values',
      successMessage: 'Successfully set default value',
    });

    if (res.response) {
      setIsAdded(false);
    }

    return res;
  };

  useEffect(() => {
    defaultValuesMap.current = DEFAULT_VALUES || {};
    // If some default values are already added then directly show the table
    if (Object.keys(defaultValuesMap.current).length) {
      setShowDropdown(true);
    }
  }, [DEFAULT_VALUES]);

  // Updating the filtered data
  useEffect(() => {
    setData(
      (enrichedData || []).filter((d) =>
        Object.prototype.hasOwnProperty.call(defaultValuesMap.current, d.id)
      )
    );
  }, [enrichedData]);

  useEffect(() => {
    if (isAdded) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
    isDirty.current = isAdded;
  }, [isAdded]);

  useEffect(
    () => () => {
      if (isDirty.current) {
        const tempData = { ...defaultValuesMap.current };
        saveDefaultValues(tempData);
        defaultValuesMap.current = {};
      }
      getDynamicCurlMap('RESET');
    },
    []
  );

  // To get the list of templates which we can add
  const templates = () => {
    const filtereddata = enrichedData
      .filter(
        (t) =>
          !Object.prototype.hasOwnProperty.call(defaultValuesMap.current, t.id)
      )
      .map((t) => ({
        label: t.field_name,
        value: t.id,
      }));
    return filtereddata;
  };

  const selectTemplate = (templateData) => {
    currentSelectedTemplate.current = templateData;
  };

  const onAddDefaultClick = () => {
    const currentTemplate = currentSelectedTemplate.current;
    const defaultValue = defaultValuesMap.current[currentTemplate?.id];

    if (currentTemplate.id && defaultValue) {
      const tempDataObj = {
        ...currentTemplate,
        default_value: defaultValue,
      };

      setData((prev) => [...prev, tempDataObj]);

      setKey((prev) => {
        if (prev === 1) {
          return -1;
        }
        return 1;
      });

      setIsAdded(true);

      currentSelectedTemplate.current = {};
    }
  };

  const onDelete = (rowId) => {
    const tempData = [...data];
    const rowIndex = tempData.findIndex((f) => f.id === rowId);
    const [rowData] = tempData.splice(rowIndex, 1);
    const tempObj = { ...defaultValuesMap.current };
    delete tempObj[rowData.id];
    defaultValuesMap.current = tempObj;
    setIsAdded(true);
    setData([...tempData]);
  };

  const onSave = async () => {
    const res = await saveDefaultValues();

    if (!res) {
      return;
    }

    if (!res.error) {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: {
            ...selectedTemplate,
            default_values: defaultValuesMap.current,
          },
        })
      );
    }
  };

  const updateValue = (templateId, value, fromTable) => {
    defaultValuesMap.current = {
      ...defaultValuesMap.current,
      [templateId]: typeof value === 'object' ? value.value : value,
    };

    if (fromTable) {
      const tempData = [...data];
      const rowIndex = tempData.findIndex((d) => d.id === templateId);
      tempData[rowIndex].default_value =
        typeof value === 'object' ? value.value : value;
      setData(tempData);
    }

    setIsAdded(true);
  };

  if (!showDropdown && data.length === 0) {
    return (
      <NoRecords
        title="You have not added any field defaults"
        subTitle="Click 'Add Field Default' to get started"
        buttonText="Add Field Default"
        onAdd={() => {
          setShowDropdown(true);
        }}
      />
    );
  }

  return (
    <Box data-testid="FieldDefaultsContainerBox">
      {(data || []).length > 0 ? (
        <Box p="16px" data-testid="FieldDefaultsTableSearchBox">
          <TableViewWithSearch
            placeholder="Search for a field name"
            buttonText="Add field Default"
            onCreateNew={onAddDefaultClick}
            columns={columns({ updateValue, onDelete })}
            tableData={data}
            searchKey="field_name"
          />
        </Box>
      ) : null}

      <SelectedDefaultField
        key={key}
        options={templates()}
        enrichedDataMap={enrichedDataMap}
        updateValue={updateValue}
        onTemplateSelect={selectTemplate}
        onAddDefaultClick={onAddDefaultClick}
      />

      <div
        className="flex justify-end p-[20px]"
        data-testid="FieldDefaultsButtonContainerDiv"
      >
        <CustomButton
          buttonText="Save"
          variant="solid"
          isDisabled={!isAdded}
          onClick={onSave}
          style={{
            backgroundColor: useColorModeValue(COLORS.LIGHT, COLORS.DARK),
            height: 40,
            color: '#fff',
          }}
          id="FieldDefaultsButtonCustomDivBtn"
        />
      </div>
    </Box>
  );
};

FieldDefaults.propTypes = {};

FieldDefaults.defautProps = {};

export default FieldDefaults;
