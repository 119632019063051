import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { EditIcon } from '@chakra-ui/icons';
import { FunnelIcon } from '@heroicons/react/24/outline';

import {
  renderUpdatedInfo,
  FORM_KEY_MAP,
  deleteRenderer,
} from '../Metrics/utils';
import TableColumnText from '../../../../components/Text/TableColumnText';

export const SLA_COLOR_MAP = [
  { label: 'red', value: 'red' },
  { label: 'orange', value: 'orange' },
  { label: 'brown', value: 'brown' },
];

export const SLA_MET_COLORS = [
  { label: 'Amber', value: '#FFBF00' },
  { label: 'Blue', value: '#0000FF' },
  { label: 'Brown', value: '#A52A2A' },
  { label: 'Cyan', value: '#00FFFF' },
  { label: 'Emerald', value: '#50C878' },
  { label: 'Fuchsia', value: '#FF00FF' },
  { label: 'Green', value: '#008000' },
  { label: 'Indigo', value: '#4B0082' },
  { label: 'Lime', value: '#00FF00' },
  { label: 'Mint', value: '#98FF98' },
  { label: 'Orange', value: '#FFA500' },
  { label: 'Pink', value: '#FFC0CB' },
  { label: 'Purple', value: '#800080' },
  { label: 'Rose', value: '#FF007F' },
  { label: 'Sky', value: '#87CEEB' },
  { label: 'Teal', value: '#008080' },
  { label: 'Violet', value: '#EE82EE' },
  { label: 'Yellow', value: '#FFFF00' },
];

export const SLA_FORM_KEY_MAP = {
  QUEUE: 'queue',
  GOAL: 'goals',
  STAGES: 'stages',
  COLOR: 'color',
  LABEL: 'label',
  REMAINDER_STAGES: 'reminder_stages',
  FREQUENCY: 'frequency',
  SELECTED_RECEIPIENT_OPTION: 'selectedOption',
  SUB_RECEIPIENT: 'suboption',
  ATTRIBUTES_CONTAINER: 'attribute_container',
  OUTCOME: 'outcome',
  REMAINDER: 'reminders',
  RECEIPIENTS: 'receipients',
  UPDATED_AT: FORM_KEY_MAP.UPDATED_AT,
  NAME: FORM_KEY_MAP.NAME,
  UPDATED_BY: FORM_KEY_MAP.UPDATED_BY,
  EMAIL_TEMPLATE_ID: 'emailTemplate',
  SLA_MET_COLOR: 'slaMetColor',
  SLA_DEFAULT_OUTCOME_COLOR: 'slaDefaultOutcomeColor',
  SLA_MET_COLOR: 'slaMetColor',
};

export const slaColumns = (showModal, onDelete, metricUnit = '') => [
  {
    name: 'NAME',
    col_name: SLA_FORM_KEY_MAP.NAME,
    type: 'string',
  },
  {
    name: 'FILTER COUNT',
    col_name: 'FILTER COUNT',
    headerRenderer: () => <FunnelIcon height="32px" width="32px" />,
    cellRenderer: ({ rowData }) => {
      const count =
        rowData?.[SLA_FORM_KEY_MAP.ATTRIBUTES_CONTAINER]?.length || 0;
      return count;
    },
  },
  {
    name: SLA_FORM_KEY_MAP.GOAL,
    col_name: SLA_FORM_KEY_MAP.GOAL,
    headerRenderer: () => `${SLA_FORM_KEY_MAP.GOAL} (in ${metricUnit})`,
  },
  {
    name: SLA_FORM_KEY_MAP.REMAINDER,
    col_name: SLA_FORM_KEY_MAP.REMAINDER,
    headerStyle: {
      textAlign: 'center',
    },
    cellRenderer: ({ rowData }) => {
      const remainders = rowData?.[SLA_FORM_KEY_MAP.REMAINDER] || [];
      let columnText = remainders
        .reduce(
          (prev, curr) => `
            ${prev}
            ${curr}%,
          `,
          ''
        )
        .trim()
        .slice(0, -1);

      columnText += ` every ${
        rowData[SLA_FORM_KEY_MAP.FREQUENCY]
      } ${metricUnit?.toLowerCase()}`;

      return <TableColumnText text={columnText} />;
    },
  },
  {
    name: 'Update Info',
    col_name: 'Update Info',
    headerStyle: {
      textAlign: 'center',
    },
    cellRenderer: ({ rowData }) => renderUpdatedInfo(rowData),
  },
  {
    name: 'actions',
    col_name: 'Actions',
    cellRenderer: ({ rowData }) => (
      <div className="flex gap-[10px]">
        <EditIcon
          className="cursor-pointer"
          onClick={() => showModal({ rowData, isSlaEdit: true })}
        />
        {deleteRenderer({
          title: 'Delete this SLA',
          description: 'Are you sure you want to delete this SLA?',
          onConfirm: () => onDelete(rowData),
        })}
      </div>
    ),
  },
];

export const emptySla = {
  id: uuidv4(),
  [SLA_FORM_KEY_MAP.GOAL]: '',
  [SLA_FORM_KEY_MAP.ATTRIBUTES_CONTAINER]: [],
  [SLA_FORM_KEY_MAP.OUTCOME]: [],
  [SLA_FORM_KEY_MAP.REMAINDER]: [],
  [SLA_FORM_KEY_MAP.RECEIPIENTS]: {},
  [SLA_FORM_KEY_MAP.FREQUENCY]: '',
  [SLA_FORM_KEY_MAP.UPDATED_AT]: new Date().getTime(),
  [SLA_FORM_KEY_MAP.NAME]: '',
  [SLA_FORM_KEY_MAP.UPDATED_BY]: localStorage.getItem('userName') || '',
  [SLA_FORM_KEY_MAP.EMAIL_TEMPLATE_ID]: '',
};
export const SLA_OUTPUT_COLORS = [
  { label: 'Amber', value: '#FFBF00' },
  { label: 'Blue', value: '#0000FF' },
  { label: 'Brown', value: '#A52A2A' },
  { label: 'Cyan', value: '#00FFFF' },
  { label: 'Emerald', value: '#50C878' },
  { label: 'Fuchsia', value: '#FF00FF' },
  { label: 'Green', value: '#008000' },
  { label: 'Indigo', value: '#4B0082' },
  { label: 'Lime', value: '#00FF00' },
  { label: 'Mint', value: '#98FF98' },
  { label: 'Orange', value: '#FFA500' },
  { label: 'Pink', value: '#FFC0CB' },
  { label: 'Purple', value: '#800080' },
  { label: 'Rose', value: '#FF007F' },
  { label: 'Sky', value: '#87CEEB' },
  { label: 'Teal', value: '#008080' },
  { label: 'Violet', value: '#EE82EE' },
  { label: 'Yellow', value: '#FFFF00' },
];
