import React from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';
import TextAreaBox from '../../DynamicRenderer/TextArea';
import { DARK, LIGHT } from '../../../utils/enums/colors';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { SCRIPT_TYPE } from '../../DynamicRenderer/customEventAndTriggers/customEventAndTriggers.constant';

const renderRunScript = (actionToPerform,
  handleChange,
  actionToPerformSelectedIndex,
  handleActionErrorData,
  actionErrorData
) => (
<>
    <Box
      marginTop="20px"
      data-testid="DynamicRendererWebhookTypeBox"
    >
      <CustomSelectBox
        label="Script Type"
        options={SCRIPT_TYPE}
        placeholder="select script type"
        value={actionToPerform.script_type}
        onChange={(value) => {
          handleChange(
            value,
            `action_to_perform[${actionToPerformSelectedIndex}].script_type`
          );
          handleActionErrorData('script_type');
        }}
        focusBorderColor={useColorModeValue(LIGHT, DARK)}
        isError={!!actionErrorData?.script_type}
        errorMessage={actionErrorData?.script_type || ''}
        isMandatory
        id="DynamicRendererWebhookScriptCSelect"
      />
    </Box>
    <Box
      marginTop="10px"
      data-testid="DynamicRendererWebhookRunBox"
    >
      <TextAreaBox
        rows={2}
        value={actionToPerform.script_to_run}
        label="Script To Run"
        onChange={(e) => {
          handleChange(
            e.target.value,
            `action_to_perform[${actionToPerformSelectedIndex}].script_to_run`
          );
          handleActionErrorData('script_to_run');
        }}
        isMandatory
      />
      {actionErrorData?.script_to_run ? (
        <span className="field-error">
          {actionErrorData.script_to_run}
        </span>
      ) : null}
    </Box>
</>
);

export default renderRunScript;
