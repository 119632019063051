/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import {
  Box,
  Flex,
  FormLabel,
  Radio,
  RadioGroup,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useStoreApi } from 'reactflow';
// import CreatableSelect from 'react-select/creatable';
import { useDispatch, useSelector } from 'react-redux';
import { isNumber } from 'lodash';
import { capitalizeFirstLetter, checkForV2 } from '../../../utils/helper';
import useKeycloak from '../../../utils/hooks/useKeyCloak';
// import SelectComponent from '../../../components/SelectBox/SelectComponent';
import {
  BLACK,
  CAUTION_STATUS,
  // COLORS,
  DARK,
  DONE_STATUS,
  FIXING_STATUS,
  IN_PROGRESS_STATUS,
  LIGHT,
  TO_DO_STATUS,
  WHITE,
} from '../../../utils/enums/colors';
import CustomInput from '../../../components/InputBox/Input';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { APPROVAL_TYPES, WorkFlowNodeTypes } from '../../../utils/enums/types';
import {
  roleSettingSelectOpt,
  roleSettigWorkflowOpt,
  roleSettingWorkflowOptV2,
  roleSettingForMoveAnIssue,
} from '../../../utils/enums/selectOption';
import styles from '../styles/RestrictMoveRole.module.scss';
import { LABELS } from '../../../utils/enums/labels';
import { getWholeOption } from '../helper';
import {
  changeSelectedRuleWithKey,
  changeSelectedRuleWithMultiKeyValue,
} from '../../../redux/WorkFlowMainSlice/WorkFlowMainReducer';
import { customStyleFromRuleForSelect } from '../../../context/CustomTheme/CustomStyleFromRuleForSelect';
import { makeApiCallForActorsFromTemplate } from '../../TicketTemplateBuilder/AddEditActorModal/helper';
import DynamicMutliSelectWithInputTags from '../../../components/dynamicSelectWithInputTags/DynamicSelectWithInputTags';

const RestrictMoveRoleContainer = ({ setErrorData, errorData }) => {
  const [teamOptionList, setTeamOptionList] = useState([]);
  const [roleOptionList, setRoleOptionList] = useState([]);
  const [actorOptionList, setActorOptionList] = useState([]);
  const dispatch = useDispatch();
  const [keycloak, _] = useKeycloak();
  const { selectedWorkFlowRule, selectedTemplateId } = useSelector(
    (state) => state.workFlowMain
  );

  const [key, setKey] = useState(roleSettingSelectOpt[0]?.value);
  const flowStore = useStoreApi();
  const { edges, nodeInternals } = flowStore.getState();
  const innerEdgeList = edges
    .filter((i) => i.id !== 'edges_1')
    .map((i) => ({
      label: i.data?.label,
      value: i?.id,
      source: i?.source,
      target: i?.target,
    }));
  // const [transitionState, setTransitionState] = useState({});
  const [inputState, setInputState] = useState('');
  const determineBgColor = (type) => {
    if (type === WorkFlowNodeTypes.toDoNode) {
      return TO_DO_STATUS;
    }
    if (type === WorkFlowNodeTypes.toProgressNode) {
      return IN_PROGRESS_STATUS;
    }
    if (type === WorkFlowNodeTypes.toDoneNode) {
      return DONE_STATUS;
    }
    if (type === WorkFlowNodeTypes.toCautionNode) {
      return CAUTION_STATUS;
    }
    if (type === WorkFlowNodeTypes.toFixingNode) {
      return FIXING_STATUS;
    }
    return TO_DO_STATUS;
  };
  const [selectedTwoOption, setSelectedTwoOption] = useState(
    roleSettingSelectOpt[0]
  );
  // when org dropdown option change also change state for optionlist
  const handleOptionList = useCallback(() => {
    setInputState('');
  }, [key]);
  // handle onChange event of the dropdown

  const handleRadioChange = (e) => {
    let approvalValue = '';

    if (e === APPROVAL_TYPES.ALL_APPROVALS) {
      approvalValue = 'ALL';
    } else if (e === APPROVAL_TYPES.MIN_APPROVAL_COUNT) {
      approvalValue = isNumber(approvalValue) ? approvalValue : '1';
    }
    dispatch(
      changeSelectedRuleWithMultiKeyValue([
        {
          keyValue: 'approvalsType',
          value: e,
        },
        {
          keyValue: 'approvals',
          value: approvalValue,
        },
      ])
    );
  };

  const handleApprovalCountChange = (e) => {
    dispatch(
      changeSelectedRuleWithKey({
        keyValue: 'approvals',
        value: e.target.value,
      })
    );
  };

  const getApprovalCountValue = () => {
    if (
      selectedWorkFlowRule?.approvalsType === APPROVAL_TYPES.MIN_APPROVAL_COUNT
    ) {
      return selectedWorkFlowRule?.approvals;
    }
    return '1';
  };

  const getErrorMessage = () => {
    if (!errorData) {
      return '';
    }

    return (
      errorData.listRestricType ||
      errorData.approvals ||
      errorData.approvalsType
    );
  };

  useEffect(() => {
    if (keycloak) {
      const newArr = keycloak?.realmAccess?.roles?.map((item) => ({
        value: item,
        name: capitalizeFirstLetter(item),
      }));
      setRoleOptionList([{ value: '', name: '' }, ...newArr]);
      keycloak?.loadUserInfo().then((userInfo) => {
        const newArr2 = userInfo.team.map((item) => ({
          value: item,
          name: capitalizeFirstLetter(item),
        }));
        setTeamOptionList([{ value: '', name: '' }, ...newArr2]);
      });
    }
  }, [keycloak]);
  const fetchDataForActorOption = async () => {
    const result = await makeApiCallForActorsFromTemplate(selectedTemplateId);
    const option = result?.actors?.map((item) => ({
      value: item.id,
      name: item.name,
    }));
    setActorOptionList([{ value: '', name: '' }, ...(option || [])]);
  };
  useEffect(() => {
    fetchDataForActorOption();
  }, []);

  const handleErrorData = useCallback((keyName, val) => {
    setErrorData((prev) => {
      if (prev[keyName] && val) {
        return {
          ...prev,
          [keyName]: '',
        };
      }
      return prev;
    });
  }, []);
  return (
    <>
      <FormLabel
        className={styles.formLabelRestric}
        data-testid="RestrictMoveRoleContainerFormLabel"
      >
        {LABELS.TITLE.FOR_TRANSTION}
      </FormLabel>
      <CustomSelectBox
        name=""
        value={getWholeOption(selectedWorkFlowRule.transitionId, innerEdgeList)}
        styles={customStyleFromRuleForSelect}
        options={innerEdgeList}
        onChange={async (e) => {
          handleErrorData('transitionId', e.value);
          await dispatch(
            changeSelectedRuleWithKey({
              keyValue: 'transitionId',
              value: e.value,
            })
          );
        }}
        // eslint-disable-next-line react/no-unstable-nested-components
        getOptionLabel={(e) => (
          <Flex align="center" className="text-[14px] font-500">
            <span className="ml-1">{e.label}</span>
            <Box
              className="ml-[10px] mr-[10px] text-white pl-[7px] pr-[7px]"
              bgColor={determineBgColor(nodeInternals.get(e.source)?.type)}
              borderRadius="4px"
            >
              {nodeInternals.get(e.source)?.data.label}
            </Box>
            {e?.label && <ArrowForwardIcon />}
            <Box
              className="ml-[10px] mr-[10px] text-white pl-[7px] pr-[7px]"
              bgColor={determineBgColor(nodeInternals.get(e.target)?.type)}
              borderRadius="4px"
            >
              {nodeInternals.get(e.target)?.data.label}
            </Box>
          </Flex>
        )}
        isError={!!errorData?.transitionId}
        errorMessage={errorData?.transitionId || ''}
        id="RestrictMoveRoleSelectBox"
      />
      <FormLabel
        marginTop="20px"
        className={styles.formLabelRestric}
        data-testid="RestrictMoveRoleContainerFormLabel2"
      >
        {LABELS.TITLE.TRANSTION_PEOPLE_DESCRIPTION}
      </FormLabel>
      <Flex
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        gap="20px"
        marginTop="9px"
        mb="30px"
        data-testid="RestrictMoveRoleContainerMultiSelectFlex"
      >
        <DynamicMutliSelectWithInputTags
          values={selectedWorkFlowRule.listRestricType || []}
          selectOptions={
            checkForV2() ? roleSettingForMoveAnIssue : roleSettigWorkflowOpt
          }
          onChange={(value) => {
            handleErrorData('listRestricType', value);
            dispatch(
              changeSelectedRuleWithKey({
                keyValue: 'listRestricType',
                value,
              })
            );
          }}
          disableAddButton={
            !checkForV2()
              ? !!selectedWorkFlowRule?.listRestricType?.roles?.length
              : false
          }
          templateId={selectedTemplateId}
          includeUserAttributes
        />
      </Flex>
      <FormLabel
        marginTop="20px"
        className={styles.formLabelRestric}
        data-testid="RestrictMoveRoleContainerFormLabel3"
      >
        {LABELS.TITLE.MOVE_TO_NEXT_AFTER}
      </FormLabel>
      <RadioGroup
        className={styles.radiobutton}
        value={
          selectedWorkFlowRule?.approvalsType
        }
        onChange={handleRadioChange}
        data-testid="RestrictMoveRoleRadioGroup"
      >
        <Box className={styles.radioitem}>
          <Radio
            value={APPROVAL_TYPES.ALL_APPROVALS}
            key={APPROVAL_TYPES.ALL_APPROVALS}
          />
          <Text
            className={styles.radiolabel}
            color={useColorModeValue(BLACK, WHITE)}
          >
            {LABELS.TITLE.ALL_APPROVALS}
          </Text>
        </Box>
        <Box className={styles.radioitem}>
          <Radio
            value={APPROVAL_TYPES.MIN_APPROVAL_COUNT}
            key={APPROVAL_TYPES.MIN_APPROVAL_COUNT}
          />
          <Box className={styles.radiolabel}>
            <CustomInput
              type="number"
              width="50px"
              value={getApprovalCountValue()}
              onChange={handleApprovalCountChange}
              disabled={
                selectedWorkFlowRule?.approvalsType !==
                APPROVAL_TYPES.MIN_APPROVAL_COUNT
              }
            />
            <Text
              className={styles.radiolabel}
              color={useColorModeValue(BLACK, WHITE)}
            >
              {LABELS.TITLE.N_APPROVALS}
            </Text>
          </Box>
        </Box>
      </RadioGroup>
      {getErrorMessage() ? (
        <span
          className="field-error"
          data-testid="RestrictMoveRoleContainerErrorSpan"
        >
          {getErrorMessage()}
        </span>
      ) : null}
    </>
  );
};
RestrictMoveRoleContainer.propTypes = {
  errorData: PropTypes.object.isRequired,
  setErrorData: PropTypes.func.isRequired,
};
export default RestrictMoveRoleContainer;
