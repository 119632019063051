import axiosInstance from '../../../utils/axios/axios';

const allTemplateUrl = 'templates';

const getAllTemplatesApi = async (options) => {
  const list = await axiosInstance.get(allTemplateUrl, { params: options });
  return {
    allTemplatesList: [...list.data],
  };
};

const alltemplateService = {
  getAllTemplatesApi,
};

export default alltemplateService;
