import React from 'react';

import { Flex, HStack, Text } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';

import { deleteRenderer } from '../MetricsAndSLA/Metrics/utils';

import styles from './Relation.module.scss';

// eslint-disable-next-line import/prefer-default-export
export const columns = ({
  onEdit,
  onDelete,
  templateIdMap = {},
  offerIdMap = {},
  currentTemplateId,
  navigate,
}) => {
  const column = [
    {
      col_name: 'name',
      type: 'string',
      filterable: true,
    },
    {
      col_name: 'Relationship',
      type: 'string',
      cellRenderer: ({ rowData }) => {
        const leftTemplate = templateIdMap?.[rowData?.template_id_left];
        const rightTemplate = templateIdMap?.[rowData?.template_id_right];
        const offerRight = offerIdMap?.[rowData?.offer_id_right];
        const offerLeft = offerIdMap?.[rowData?.offer_id_left];

        if (!leftTemplate || !rightTemplate) {
          return null;
        }

        const isCurrentTemplateLeft = currentTemplateId === leftTemplate?.id;

        const leftText = isCurrentTemplateLeft ? offerLeft?.name : offerRight?.name;
        const rightText = isCurrentTemplateLeft ? offerRight?.name : offerLeft?.name;

        return (
          <HStack>
            <Text>{leftText}</Text>

            <Text p={2} bg="rgba(37, 99, 235, 0.17)" borderRadius={5}>
              {isCurrentTemplateLeft
                ? rowData.relation_def.left_to_right
                : rowData.relation_def.right_to_left}
            </Text>

            <Text
              className={styles.right__template__text}
              onClick={() => {
                navigate(
                  `/template-makers/template/${
                    isCurrentTemplateLeft ? rightTemplate?.id : leftTemplate?.id
                  }?name=${isCurrentTemplateLeft ? rightTemplate?.name : leftTemplate?.name}`
                );
              }}
            >
              {rightText}
            </Text>
          </HStack>
        );
      },
    },
    {
      col_name: 'prefix',
      type: 'string',
      filterable: true,
      cellRenderer: ({ rowData }) => {
        const otherTemplateId = currentTemplateId === rowData?.template_id_left
          ? rowData?.template_id_right
          : rowData?.template_id_left;
        const otherTemplate = templateIdMap?.[otherTemplateId];
        return <Text>{otherTemplate?.properties?.prefix}</Text>;
      }
    },
    {
      col_name: 'actions',
      type: 'actions',
      cellRenderer: ({ rowData }) => (
        <Flex justifyContent="center" gap="5px">
          <EditIcon
            className="cursor-pointer"
            onClick={() => onEdit(rowData)}
          />

          {deleteRenderer({
            title: 'Delete this relation?',
            description: 'Are you sure you want to delete this relationship?',
            onConfirm: () => onDelete(rowData),
          })}
        </Flex>
      ),
      align: 'center',
    },
  ];

  return column;
};
