import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _pick from 'lodash/pick';

import { useToast } from '@chakra-ui/react';
import TenantLocales from './TenantLocales';
import axiosInstance from '../../utils/axios/axios';
import { columns } from './helper';
import LanguageDrawer from './LanguageDrawer';
import {
  fetchSelectedLanguages,
  fetchLanguages,
} from '../../redux/CardReducer/localeSlice';
import LocaleDrawer from './LocalDrawer';
import { getMetaFieldList } from '../../redux/MetaFieldsSlice/MetaFieldsReducer';

const LocalesWrapper = () => {
  const [selectedlocaleDrawerData, setSelectedlocaleDrawerData] =
    useState(false);
  const [localesDrawer, setLocalesDrawer] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();
  const { selectedLanguages } = useSelector((state) => state.locales);

  const saveSelectedLanguages = async (list) => {
    const updatedData = list.map((item) => {
      item = _pick(item, 'is_active', 'locale_code', 'name');
      return item;
    });
    const data = [...updatedData];
    try {
      const response = await axiosInstance.put('locales/createOrUpdate', {
        data,
      });
      if (response?.status === 200 || response?.status === 201) {
        toast({
          title: 'Language added successfully',
          status: 'success',
          duration: 3000,
          position: 'bottom-left',
          isClosable: true,
        });
        dispatch(fetchSelectedLanguages());
      } else {
        toast({
          title: 'Failed to add language',
          status: 'error',
          duration: 3000,
          position: 'bottom-left',
          isClosable: true,
        });
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    dispatch(fetchLanguages());
    dispatch(fetchSelectedLanguages());
    dispatch(getMetaFieldList('some thing went wrong'));
  }, []);

  const openModal = useCallback(
    (data) => {
      setSelectedlocaleDrawerData(data?.rowData);
      setLocalesDrawer(true);
    },
    [localesDrawer, selectedlocaleDrawerData]
  );

  const setLanguage = (list) => {
    saveSelectedLanguages(list);
  };

  const setLocales = async (list) => {
    if (selectedlocaleDrawerData?.locale_code) {
      let data = { ...selectedlocaleDrawerData };
      data = { ...data, meta_field_translations: list.meta_field_translations };
      data = { ...data, other_translations: list.other_translations };

      data = [
        _pick(
          data,
          'is_active',
          'locale_code',
          'name',
          'meta_field_translations',
          'other_translations'
        ),
      ];
      try {
        const response = await axiosInstance.put('locales/createOrUpdate', {
          data,
        });
        if (response?.status === 200 || response?.status === 201) {
          toast({
            title: 'Translations successfully saved',
            status: 'success',
            duration: 3000,
            position: 'bottom-left',
            isClosable: true,
          });
          dispatch(fetchSelectedLanguages());
        } else {
          toast({
            title: 'Failed to save Translations',
            status: 'error',
            duration: 3000,
            position: 'bottom-left',
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: 'Failed to save Translations',
          status: 'error',
          duration: 3000,
          position: 'bottom-left',
          isClosable: true,
        });
        throw new Error(error);
      }
    }
  };

  return (
    <>
      <TenantLocales
        columns={columns({
          showModal: openModal,
        })}
        tableData={selectedLanguages}
        addLanguage={() => setOpenDrawer(true)}
        searchKey="name"
      />
      {localesDrawer && (
        <LocaleDrawer
          openDrawer={localesDrawer}
          setCloseDrawer={() => setLocalesDrawer(false)}
          setLocales={(list, type) => setLocales(list, type)}
          title={selectedlocaleDrawerData?.name}
          code={selectedlocaleDrawerData?.locale_code}
        />
      )}
      {openDrawer && (
        <LanguageDrawer
          openDrawer={openDrawer}
          title={"Add Language"}
          setCloseDrawer={() => setOpenDrawer(false)}
          setLanguage={(list) => setLanguage(list)}
        />
      )}
    </>
  );
};

export default LocalesWrapper;
