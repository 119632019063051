import _ from 'lodash';
import { FieldTypes } from '../../utils/enums/types';
import axiosInstance from '../../utils/axios/axios';
import { getTenantData } from '../../helpers/tenantUrl';
import { DROPDOWN_TYPES } from '../../pages/TenantFilters/filters.constants';

export const getDynamicValuesFromCurl = async (curl) => {
  try {
    const response = await axiosInstance.post('/curl/execute', {
      data: {
        curl,
      },
    });
    return response?.data;
  } catch (error) {
    return [];
  }
};
export const getQueueListForWholeTenant = async (listFilter, isWhiteList) => {
  try {
    const tenantInformation = getTenantData();
    const tenantId = tenantInformation?.id;
    const params = {
      tenant_id: tenantId,
    };
    const response = await axiosInstance.get('queueTypes', { params });
    const data =
      response?.data[0]?.map((item) => ({
        value: item.id,
        label: item.properties?.queueLabel || item.queue_name,
      })) || [];
    const filteredData = data.filter((item) =>
      listFilter.map((i) => i.queueId).includes(item.value)
    );

    if (isWhiteList) {
      return filteredData;
    }
    return data.filter((item) => !filteredData.includes(item));
  } catch (error) {
    return [];
  }
};
export const getTemplateById = async (id) => {
  const response = await axiosInstance.get(
    `/templates/list?$top=1&$skip=0&$select=name,id,fields,workflow,field_hierarchy_map&$filter=id eq ${id}`
  );
  if (response.status === 200) {
    return response.data;
  }
  return [];
};

export const getHierarchyById = async (id, callback = undefined) => {
  try {
    const response = await axiosInstance.get(
      `/hierarchies/list?$top=1&$skip=0&$select=*&$filter=id eq ${id}`
    );

    if (response.status === 200) {
      const data = response?.data?.rows[0];

      if (typeof callback === 'function') {
        callback(data);
        return;
      }

      // eslint-disable-next-line consistent-return
      return data;
    }
    throw new Error(`Failed to fetch hierarchy: ${response.statusText}`);
  } catch (error) {
    console.error('Error fetching hierarchy:', error);

    if (typeof callback === 'function') {
      callback(null); // Provide error handling for callback usage
      return; // Exit early if callback is used
    }

    throw error; // Re-throw the error for promise-based usage
  }
};

export const getHierarchies = async (callback = undefined) => {
  try {
    const response = await axiosInstance.get(
      `/hierarchies/list?$select=*`
    );

    if (response.status === 200) {
      const data = response?.data?.rows || [];

      if (typeof callback === 'function') {
        callback(data);
        return;
      }

      // eslint-disable-next-line consistent-return
      return data;
    }
    throw new Error(`Failed to fetch hierarchies: ${response.statusText}`);
  } catch (error) {
    console.error('Error fetching hierarchy:', error);

    if (typeof callback === 'function') {
      callback(null); // Provide error handling for callback usage
      return; // Exit early if callback is used
    }

    throw error; // Re-throw the error for promise-based usage
  }
};

export const getTemplateFields = async (id, callback) => {
  if (!id) {
    return;
  }

  try {
    const response = await getTemplateById(id);
    let fields = [];
    let workflows = null;
    let fieldHierarchyMap = null;

    if (response.count === 1) {
      const template = response?.rows[0];
      fields = template?.fields;
      workflows = template?.workflow;
      fieldHierarchyMap = template?.field_hierarchy_map;
    }
    if (typeof callback === 'function') {
      callback(fields, workflows, fieldHierarchyMap);
    }
  } catch (err) {
    // handle error
  }
};

export const transformFieldIntoOptions = (fields, omitFields) => {
  const fieldToRemove = omitFields?.map((item) => item.id);

  const transformedFields = fields?.map((field) => {
    if (omitFields?.includes(field?.id)) {
      return null;
    }
    if (
      field?.type === FieldTypes.MEDIA ||
      omitFields?.findIndex((x) => x === field?.type) > -1
    ) {
      return null;
    }

    if (!field) {
      return null;
    }

    if (omitFields?.includes(field?.id)) {
      return null;
    }

    return { value: field.id, label: field.label };
  });
  return transformedFields
    ?.filter((x) => x)
    .filter((item) => !fieldToRemove?.includes(item.value));
};

export const getSelectedFieldType = (fields, expression) => {
  const field = fields?.find((f) => f?.id === expression?.leftOperand?.value);
  return field?.type;
};

export const transformValuesIntoOptions = async (
  fields,
  expression,
  workflow
) => {
  const field = fields?.find((f) => f?.id === expression?.leftOperand?.value);
  let values;
  if (field?.type === FieldTypes.DROPDOWN || field?.type === FieldTypes.TAGS) {
    const collectionType = _.get(
      field,
      'type_based_attributes.collection.collection_type'
    );
    const specialFieldType = _.get(
      field,
      'type_based_attributes.collection.specialField'
    );
    if (collectionType === DROPDOWN_TYPES.DYNAMIC) {
      values = _.get(field, 'type_based_attributes.collection.dynamicCurl'); // curlKey name is change
      return getDynamicValuesFromCurl(values);
    }
    if (collectionType === DROPDOWN_TYPES.STATIC || collectionType === DROPDOWN_TYPES.DEPENDENT) {
      values = _.get(field, 'type_based_attributes.collection.staticValues');
      if (typeof values === 'string') {
        values = values?.split(/\r?\n|\r|\n/g);
        const options = values?.map((v) => {
          if (!v) return null;
          return { value: v, label: v };
        });
        return options?.filter((x) => x);
      }
      return values?.filter((x) => x.value && x.label) || [];
    }
    if (collectionType === DROPDOWN_TYPES.SPECIAL_FIELD && specialFieldType === 'queue') {
      const listFilter = _.get(field, 'type_based_attributes.collection.list');
      const isWhiteList = _.get(
        field,
        'type_based_attributes.collection.isWhiteList'
      );
      return getQueueListForWholeTenant(listFilter, isWhiteList);
    }
    if (
      collectionType === DROPDOWN_TYPES.SPECIAL_FIELD &&
      specialFieldType === 'workflowState'
    ) {
      return workflow;
    }
  }
  return [];
};
// // SPECIAL_FIELD
export const isSelectedFieldSpecialTypeOfQueue = (fields, expression) => {
  const field = fields?.find(
    (f) =>
      f?.id === expression?.leftOperand?.value &&
      f?.type_based_attributes?.collection?.collection_type ===
      DROPDOWN_TYPES.SPECIAL_FIELD &&
      f?.type_based_attributes?.collection?.specialField === 'queue'
  );
  return field;
};

export const getTitlesHierarchy = (hierarchy, fieldHierarchyMap, level = 0, titlesObj = {}) => {
  hierarchy.forEach((item) => {
    if (!titlesObj[fieldHierarchyMap.map[level]]) {
      titlesObj[fieldHierarchyMap.map[level]] = [];
    }
    titlesObj[fieldHierarchyMap.map[level]].push(item.title);
    if (item.children && item.children.length > 0) {
      getTitlesHierarchy(item.children, fieldHierarchyMap, level + 1, titlesObj);
    }
  });

  return titlesObj;
};
