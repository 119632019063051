import React, { useCallback, useMemo, useState } from 'react';
import { Text, Tooltip } from '@chakra-ui/react';

import PropTypes from 'prop-types';

const TextWithTooltip = ({ text, defaultClassName, showLength, ...props }) => {
  const [showToltip, setShowTooltip] = useState(false);

  const onMouseEnter = useCallback((e) => {
    // Showing the tooltip conditionally
    if (e.target.clientWidth < e.target.scrollWidth) {
      setShowTooltip(true);
    }
    if(showLength && text.length > showLength){
      setShowTooltip(true);
    }
  }, []);

  const onMouseLeave = useCallback(() => {
    setShowTooltip(false);
  }, []);

  const className = useMemo(() => {
    let str = '';
    if (defaultClassName) {
      str += `${defaultClassName} `;
    }
    str += 'ellipsis';
    return str;
  }, [defaultClassName]);
  let limitedText = ''
  // if (showToltip) {
  //   limitedText= text?.split(' ').slice(0, 100).join(' ') + '...';
  // }

  return (
    <Tooltip
      hasArrow
      isOpen={showToltip}
      label={showToltip ? (text.length> 100 ? `${text.slice(0,100)}...` : text) : ''}
      data-testid="TextWithToolTip"
    >
      <Text
        fontSize="14px"
        fontWeight="400"
        lineHeight="normal"
        display="block"
        alignItems="center"
        className={className}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        {...props}
      >
      {( showLength && text.length > showLength) ? text.slice(0, showLength) + "..." : text}
      </Text>
    </Tooltip>
  );
};

TextWithTooltip.propTypes = {
  text: PropTypes.string.isRequired,
  defaultClassName: PropTypes.string,
  showLength: PropTypes.number,
};

TextWithTooltip.defaultProps = {
  defaultClassName: '',
};

export default TextWithTooltip;
