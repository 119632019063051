/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { Tooltip, useColorModeValue, Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import styles from '../TemplateCardListView/cardLayout.module.scss';
import sumStyles from '../TicketTemplateBuilder/styles/templatebuilder.module.scss';

const CountView = ({ listKey }) => {
  const viewState = useSelector((state) => state.viewState);
  const currentCount = useMemo(
    () => viewState?.[listKey]?.count || 0,
    [viewState?.[listKey]]
  );

  return (
    <Box data-testid="CountViewBox">
      <div
        className={`
        ${useColorModeValue(
          sumStyles.ticketCounterField,
          sumStyles.ticketCounterFieldDark
        )}`}
        style={{ margin: 0, width: ' fit-content' }}
        data-testid="CountViewDiv"
      >
        <Tooltip hasArrow label={currentCount} data-testid="CountViewTooltip">
          <span className={styles.count}>{`Count: ${currentCount}`}</span>
        </Tooltip>
      </div>
    </Box>
  );
};

export default CountView;
