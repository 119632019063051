/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@chakra-ui/input';
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Text,
  Textarea,
  Radio,
  RadioGroup,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import createBaseSettings from './BaseSetting';
import styles from '../CreateEditTemplate/createtemplate.module.scss';
import CustomSelectBox from '../../../components/SelectBox/Select';
import {
  FieldTypes,
  SettingsTabTypes,
  TemplateTypes,
} from '../../../utils/enums/types';
import { LABELS } from '../../../utils/enums/labels';
import { COLORS, DEFAULT, LIGHT, DARK } from '../../../utils/enums/colors';

const SETTINGS_TABS = [SettingsTabTypes.BASIC, SettingsTabTypes.ROLE];
const SETTINGS_TABS_LABEL_MAP = {
  [SettingsTabTypes.BASIC]: LABELS.TITLE.BASIC_SETTINGS,
  [SettingsTabTypes.ROLE]: LABELS.TITLE.ROLE_SETTINGS,
};

const FieldContent = ({ type, fields, setFields }) => {
  const [activeSetting, setActiveSetting] = useState(SettingsTabTypes.BASIC);
  const [baseData, setBaseData] = useState([]);
  const [fieldData, setFieldData] = useState([]);
  const { allTemplatesList } = useSelector((state) => state.allTemplates);

  const handleData = () => {
    switch (type) {
      case FieldTypes.TEXT:
      case FieldTypes.EMAIL:
        setBaseData(createBaseSettings(fields, setFields));
        break;
      default:
        break;
    }
  };

  const templateOptions = allTemplatesList.map((i) => ({
    label: i?.name,
    value: i,
  }));

  useEffect(() => {
    handleData();
  }, [fields]);

  return (
    <>
      <Flex as="b" gap="18px" pb="30px" data-testid="FieldContentSettingsFlex">
        {SETTINGS_TABS?.map((settingsTabType) => (
          <Box
            key={settingsTabType}
            id={settingsTabType}
            px="5px"
            borderBottomColor={
              activeSetting === settingsTabType &&
              useColorModeValue(LIGHT, DARK)
            }
            color={
              activeSetting === settingsTabType
                ? useColorModeValue('black', DARK)
                : DEFAULT
            }
            borderBottomWidth={activeSetting === settingsTabType && '4px'}
            onClick={() => fields?.content && setActiveSetting(settingsTabType)}
            cursor="pointer"
            data-testid="FieldContentEditorSettingsBox"
          >
            {SETTINGS_TABS_LABEL_MAP[settingsTabType]}
          </Box>
        ))}
      </Flex>

      {activeSetting === SettingsTabTypes.BASIC && (
        <>
          <Grid
            templateColumns="repeat(2, 1fr)"
            gap="30px"
            data-testid="FieldContentFieldBasicGrid"
          >
            {baseData?.row1?.map((fieldInfo) => (
              <GridItem
                key={fieldInfo.label}
                w="100%"
                data-testid="FieldContentFieldBasicGridItem"
              >
                <Text
                  mb="8px"
                  as="samp"
                  data-testid="FieldContentFieldInfoText"
                >
                  {fieldInfo.label}
                </Text>
                {fieldInfo.render ? (
                  fieldInfo.render()
                ) : (
                  <Input
                    onChange={(e) => fieldInfo.onChange(e.target.value)}
                    value={fieldInfo.value}
                    placeholder={fieldInfo.placeholder}
                    data-testid="FieldContentFieldInfoValue"
                  />
                )}
              </GridItem>
            ))}
          </Grid>
          <Grid
            templateColumns="repeat(1, 1fr)"
            data-testid="FieldContentInfoRow2Grid"
          >
            {baseData?.row2?.map((fieldInfo) => (
              <GridItem
                pt="30px"
                key={fieldInfo.label}
                w="100%"
                data-testid="FieldContentInfoRow2GridItem"
              >
                <Text mb="8px" data-testid="FieldContentInfoRow2Text">
                  {fieldInfo.label}
                </Text>
                {fieldInfo.render ? (
                  fieldInfo.render()
                ) : (
                  <Textarea
                    value={fieldInfo.value}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        description: e?.target?.value,
                      })
                    }
                    placeholder={fieldInfo.Placeholder}
                    size="sm"
                    data-testid="FieldContentInfoRow2TextArea"
                  />
                )}
              </GridItem>
            ))}
          </Grid>
          <Alert
            mt="10"
            variant="left-accent"
            status="info"
            style={{ borderRadius: '6px' }}
            data-testid="FieldContentAlert"
          >
            <AlertIcon />
            <Box
              style={{ paddingLeft: '18px' }}
              data-testid="FieldContentAlertBox"
            >
              <AlertTitle
                style={{ fontSize: '16px', color: COLORS.ALERT_COLOR }}
                data-testid="FieldContentAlertTitle"
              >
                {LABELS.TITLE.INFO}
              </AlertTitle>
              <AlertDescription
                style={{ fontSize: '16px', color: COLORS.ALERT_COLOR }}
                data-testid="FieldContentAlertDescription"
              >
                {LABELS.DESCRIPTIONS.FIELDSET_INFORMATION}
              </AlertDescription>
            </Box>
          </Alert>
        </>
      )}
    </>
  );
};

FieldContent.propTypes = {
  type: PropTypes.string.isRequired,
  fields: PropTypes.instanceOf(Object).isRequired,
  setFields: PropTypes.func.isRequired,
};

export default FieldContent;
