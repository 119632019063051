import React, { useEffect, useRef, useState } from 'react';
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { EmailIcon, QuestionOutlineIcon } from '@chakra-ui/icons';
import CustomButton from '../../../components/Button/SimpleButton';
import DeleteIcon from '../../../assets/icons/DeleteIcon.svg';
import CopyIcon from '../../../assets/icons/CopyIcon.svg';
import CustomTabs from '../../../components/Tabs/Tabs';
import FieldSettingsTab from './FieldSettingsTab';
import RoleSettingsTab from './RoleSettingsTab';
import NoFieldScreen from '../TemplateEditor/NoFieldScreen';
// import CreateTemplateModal from '../CreateEditTemplate/CreateTemplateModal';
import { updateAllCompositeList } from '../../../redux/TicketTemplate/AllCompositeReducer/AllCompositeSlice';
import CustomToast from '../../../components/Notification/Notification';
import TemplateJSONViewer from '../TemplateEditor/TemplateJSONViewer';
import styles from '../styles/particularTemplate.module.scss';
import { COLORS } from '../../../utils/enums/colors';
import { LABELS } from '../../../utils/enums/labels';
import { FieldTypes, SettingsTabTypes } from '../../../utils/enums/types';
import {
  addCompositeApi,
  deleteFieldsetApi,
  getCompositeApi,
} from '../utils/helper';
import { updateFielsStore } from '../../../redux/TicketTemplate/TemplateFieldsReducer/FieldSlice';
// import CreateFieldsetModal from '../CreateNewFieldset/CreateFieldsetModal';
import TemplateDeleteModal from '../TemplateEditor/TemplateDeleteModal';
import CreateCompositeModal from '../CreateNewComposite/CreateCompositeModal';

const CompositeEditor = () => {
  const { allCompositeList = [] } = useSelector((state) => state.allComposite);
  const { allTemplateFieldsObj = {} } = useSelector(
    (state) => state.allTemplateFields
  );
  const { id } = useParams();
  const [selectedTicket, setSelectedTicket] = useState();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [isJsonModal, setIsJsonModal] = useState(false);
  const dispatch = useDispatch();
  const { addToast } = CustomToast();

  const navigate = useNavigate();

  const previousStateRef = useRef();

  const getFieldData = async (obj) => {
    if (!allTemplateFieldsObj[id]) {
      // if fields already present do nothing
      const result = await getCompositeApi(id);
      const editedList = obj.map((i) => {
        if (i.id === id) {
          const selected = { ...i, fields: [...result] };
          return selected;
        }
        return i;
      });

      dispatch(
        updateFielsStore({
          ...allTemplateFieldsObj,
          [id]: [...result],
        })
      );

      dispatch(
        updateAllCompositeList({
          allCompositeList: editedList,
        })
      );
    }
  };

  function updateObjectById(objId, arr) {
    return arr.map((obj) => {
      if (obj.id === id) {
        // updates selected object
        setSelectedTicket(obj);
        // getFieldData(obj);
        return obj;
      }
      if (obj.subItems && obj.subItems.length) {
        // recursively checks subItems arrays
        return {
          ...obj,
          subItems: updateObjectById(objId, obj.subItems),
        };
      }
      // if no template found
      // setSelectedTicket(null);
      return obj;
    });
  }

  useEffect(() => {
    // we are having more than one ticket and none is selected we will redirect to first selected
    if (!id && !!allCompositeList?.length) {
      navigate(`${allCompositeList[0]?.id}`);
    }
  }, []);

  useEffect(() => {
    updateObjectById(id, allCompositeList);
  }, [id, allCompositeList]);

  useEffect(() => {
    if (previousStateRef.current !== allCompositeList) {
      getFieldData(allCompositeList);
      previousStateRef.current = allCompositeList;
    } else {
      getFieldData(allCompositeList);
    }
  }, [id, allCompositeList]);

  const editTemplate = () => {
    setOpenEditModal(true);
  };

  const copyTemplate = async () => {
    const datas = {
      ...selectedTicket,
      name: `${selectedTicket?.name}-copy-${allCompositeList.length - 2}`,
    };
    delete datas.id;
    const response = await addCompositeApi(datas);
    dispatch(
      updateAllCompositeList({
        allCompositeList: [response, ...allCompositeList],
      })
    );
    addToast({
      message: 'Copied Successfully',
      type: 'success',
      id: 'copy-template-toast',
    });
    navigate(`/template-builder/composite/${response.id}`);
  };

  const deleteTemplate = async () => {
    const response = await deleteFieldsetApi(id);
    if (response?.status === 200) {
      const updatedList = allCompositeList?.filter(
        (i) => i?.id !== selectedTicket?.id
      );
      dispatch(
        updateAllCompositeList({
          allCompositeList: updatedList,
        })
      );
      navigate(
        `/template-builder/composite/${
          updatedList?.length ? updatedList[0]?.id : ''
        }`
      );
      addToast({
        message: LABELS.TITLE.DELETED_SUCCESSFULLY,
        type: CustomToast.Types.success,
        id: 'delete-template-toast',
      });
    } else {
      addToast({
        message: LABELS.TITLE.FAILED,
        type: CustomToast.Types.error,
        id: 'delete-template-toast-failed',
      });
    }
    setIsDeleteModal(false);
  };

  const saveTemplate = () => {
    addToast({
      message: LABELS.TITLE.SAVED_SUCCESSFULLY,
      type: CustomToast.Types.success,
      id: 'save-template-toast',
    });
  };

  const tabsData = [
    {
      id: SettingsTabTypes.FIELD,
      title: LABELS.TITLE.FIELD_SETTINGS,
      content: (
        <FieldSettingsTab data={selectedTicket} setData={setSelectedTicket} />
      ),
    },

    {
      id: SettingsTabTypes.ROLE,
      title: LABELS.TITLE.ROLE_SETTINGS,
      content: <RoleSettingsTab />,
    },
  ];

  const onChange = () => {};

  return (
    <>
      <Box
        display="block"
        width="100%"
        flex="1"
        data-testid="CompositeEditorBox"
      >
        <Flex
          mr="20px"
          mt="25px"
          alignItems="flex-end"
          justifyContent="flex-end"
          data-testid="CompositeEditorButtonFlex"
        >
          <CustomButton
            leftIcon={<EmailIcon />}
            color={useColorModeValue('black', 'white')}
            id="CompositeEditorButtonFlexcopy"
            buttonText={LABELS.ACTIONS.FEEDBACK}
            variant="outline"
            mr={4}
            className={styles.lightBorderbutton}
          />
          <CustomButton
            leftIcon={<QuestionOutlineIcon />}
            color={useColorModeValue('black', 'white')}
            id="CompositeEditorButtonFlexdownload"
            buttonText={LABELS.ACTIONS.HELP}
            variant="outline"
            className={styles.lightBorderbutton}
          />
        </Flex>
        <Flex
          flex="1"
          p="4"
          mx="4"
          pr="0px"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          justifyContent="space-between"
          flexWrap="wrap"
          data-testid="CompositeEditorEleFlex"
        >
          <Flex
            role="group"
            flex="1"
            alignItems="center"
            data-testid="CompositeEditorInnerEleFlex"
          >
            <Text
              className={styles.templateHeaderText}
              color={useColorModeValue(COLORS.TEMPLATE_TITLE, '')}
              data-testid="CompositeEditorTitleText"
            >
              {selectedTicket?.name}
            </Text>
            <CustomButton
              className="ml-4"
              color={useColorModeValue('#000000', 'white')}
              id="CompositeEditorButtonFlexbutton-edit-template"
              onClick={editTemplate}
              buttonText={LABELS.ACTIONS.EDIT}
              variant="outline"
            />
            <ReactSVG
              onClick={copyTemplate}
              className={`${styles.deleteIcon} ml-4`}
              src={CopyIcon}
              data-testid="CompositeEditorTempReactSvg"
            />
            <ReactSVG
              onClick={() => setIsDeleteModal(true)}
              className={`${styles.deleteIcon} ml-4`}
              src={DeleteIcon}
              data-testid="CompositeEditorDeleteReactSvg"
            />
          </Flex>
          <Box data-testid="CompositeEditorButtonBox">
            <CustomButton
              color={useColorModeValue('black', 'white')}
              id="viewjsonCompositeEditorButtonFlex"
              onClick={() => setIsJsonModal(true)}
              buttonText={LABELS.ACTIONS.VIEW_JSON}
              variant="outline"
              className="mr-4"
            />
            <CustomButton
              color={useColorModeValue('white', 'black')}
              id="button-CompositeEditorButtonFlexsave"
              onClick={saveTemplate}
              buttonText={LABELS.ACTIONS.SAVE}
              variant="solid"
              className={`${styles.saveButton}`}
              bg={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
            />
          </Box>
        </Flex>
      </Box>
      {selectedTicket?.fields?.length > 0 ? (
        <Box mx="6" data-testid="CompositeEditorCustomTabsBox">
          <CustomTabs
            className={styles.tabStyle}
            tabsData={tabsData}
            onChange={onChange}
          />
        </Box>
      ) : (
        <NoFieldScreen />
      )}
      {openEditModal && (
        <CreateCompositeModal
          type={FieldTypes.TEXT}
          templateName={`${LABELS.ACTIONS.EDIT_TEMPLATE} - ${selectedTicket?.name}`}
          edit
          isTemplatedModel={openEditModal}
          setIsTemplatedModel={setOpenEditModal}
        />
      )}
      <TemplateJSONViewer
        isJsonModal={isJsonModal}
        setIsJsonModal={setIsJsonModal}
        templateName={selectedTicket?.name}
      />
      <TemplateDeleteModal
        isDeleteModal={isDeleteModal}
        setIsDeleteModal={setIsDeleteModal}
        templateName={selectedTicket?.name}
        onConfirmation={deleteTemplate}
      />
    </>
  );
};

export default CompositeEditor;
