import React from 'react';
import {
  Grid,
  GridItem,
  Card,
  CardBody,
  Text,
  Stack,
  Heading,
  Box,
  useColorModeValue,
} from '@chakra-ui/react';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import { FIELDS } from './fieldsCollection';
import styles from './addnewfield.module.scss';

const NewFieldContent = ({ onClick }) => {
  const modifiedFields =
  // eslint-disable-next-line no-nested-ternary
  window.location.pathname.includes('/fields')
    ? FIELDS
    : (window.location.pathname.includes('/fieldset')
      ? FIELDS.filter((field, index) => index !== 8)
      : FIELDS);

  return (
    <Box>
      <Text
        className={`${useColorModeValue(
          styles.modalDataTitleLight,
          styles.modalDataTitleDark
        )}`}
        pt="10px"
        pb="15px"
      >
        {/* Select a field for your template */}
      </Text>
      <Grid
        templateColumns="repeat(2, 1fr)"
        gap="15px"
        data-testid="NewFieldContentGrid"
      >
        {modifiedFields.map((field, index) => (
          <GridItem
            key={`field_${index + 1}`}
            w="100%"
            data-testid="NewFieldContentGridItem"
          >
            <Card
              direction={{ base: 'column', sm: 'row' }}
              overflow="hidden"
              variant="outline"
              className={styles.cardStyle}
              onClick={() =>
                field.isDisabled ? () => {} : onClick(field.type)
              }
              data-testid="NewFieldContentCard"
            >
              <Box className={styles.boxSvg} data-testid="NewFieldContentBox">
                <ReactSVG src={`${field.image}`} alt="image" />
              </Box>
              <Stack data-testid="NewFieldContentStack">
                <CardBody
                  className={styles.cardbody}
                  data-testid="NewFieldContentCardBody"
                >
                  <div style={{ marginLeft: '20px' }}>
                    <Heading
                      className={styles.cardheading}
                      data-testid="NewFieldContentHeading"
                    >
                      {field.label}
                    </Heading>
                    <Text
                      className={styles.carddesc}
                      data-testid="NewFieldContentText"
                    >
                      {field.description}
                    </Text>
                  </div>
                </CardBody>
              </Stack>
            </Card>
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};

NewFieldContent.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default NewFieldContent;
