import React from 'react';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { CopyIcon, DownloadIcon } from '@chakra-ui/icons';
import { Box, Flex, Spacer } from '@chakra-ui/layout';
import PropTypes from 'prop-types';
import CustomButton from '../../../components/Button/SimpleButton';
import CustomModal from '../../../components/Modal/Modal';
import styles from '../styles/particularTemplate.module.scss';
import { COLORS } from '../../../utils/enums/colors';
import { LABELS } from '../../../utils/enums/labels';

const TemplateJSONViewer = ({ isJsonModal, setIsJsonModal, templateName }) => (
  <CustomModal
    id="View-json-modal"
    modalTitle={LABELS.ACTIONS.VIEW_JSON}
    modalContent={
      <>
        <Flex data-testid="TemplateJonViewerContainerFlex">
          <h1 data-testid="TemplateJonViewerNAmeH1">{templateName}</h1>
          <Spacer />
          <Flex mt="35px" data-testid="TemplateJonViewerBtnFlex">
            <CustomButton
              leftIcon={<CopyIcon />}
              color={useColorModeValue('black', 'white')}
              id="TemplateJonViewerBtncopy"
              buttonText={LABELS.ACTIONS.COPY}
              variant="outline"
              mr={4}
              className={styles.lightBorderbutton}
            />
            <CustomButton
              leftIcon={<DownloadIcon />}
              color={useColorModeValue('black', 'white')}
              id="TemplateJonViewerBtndownload"
              buttonText={LABELS.ACTIONS.DOWNLOAD}
              variant="outline"
              className={styles.lightBorderbutton}
            />
          </Flex>
        </Flex>
        <Box mt="15px" borderWidth="1px" borderRadius="lg" height="430px" data-testid="TemplateJonViewerEmptyBox">
          {/* <pre>{JSON.stringify(json, null, 2)}</pre> */}
        </Box>
      </>
    }
    modalFooter={
      <Flex gap="8px" ata-testid="TemplateJonViewerFooterFlex">
        <CustomButton
          color="white"
          id="TemplateJsonViewerbutton-edit-template-save"
          buttonText={LABELS.ACTIONS.CLOSE}
          variant="solid"
          className={styles.createTemplateBtn}
          bg={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
          onClick={() => setIsJsonModal(false)}
        />
      </Flex>
    }
    open={isJsonModal}
    setOpen={setIsJsonModal}
  />
);

TemplateJSONViewer.propTypes = {
  templateName: PropTypes.string.isRequired,
  isJsonModal: PropTypes.bool.isRequired,
  setIsJsonModal: PropTypes.func.isRequired,
};
export default TemplateJSONViewer;
