// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReLjY2vrqWJUKI258yGO svg{stroke:#fff !important;fill:none}.XtTphNyWe6nPjPVWHAg6 svg{stroke:rgba(0,0,0,.5215686275);fill:none}.YxLEtvOF4wG9FKlOjqQP{color:#6b7280;font-size:14px;font-weight:400;line-height:normal}.HZr9_6daKRPBFArwNlWt{color:#fff;font-size:14px;font-weight:400;line-height:normal}.oKkvbeGFhkgzO0uaSjQs{font-size:14px;color:#6b7280;font-weight:400;border-bottom:1px solid #dddcdc}.Fnk0IuocN6dbdx2IwTno{font-size:14px;color:#fff;font-weight:400;border-bottom:1px solid #dddcdc}`, "",{"version":3,"sources":["webpack://./src/components/Table/table.module.scss"],"names":[],"mappings":"AAIA,0BACI,sBAAA,CACA,SAAA,CAGJ,0BACI,8BAAA,CACA,SAAA,CAGJ,sBACE,aAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CAGF,sBACE,UAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CAGF,sBACE,cAAA,CACA,aAAA,CACA,eAAA,CACA,+BAAA,CAGF,sBACE,cAAA,CACA,UAAA,CACA,eAAA,CACA,+BAAA","sourcesContent":["/*  .nested-table-content {\n  padding-left: 40px !important;\n} */\n\n.dark svg {\n    stroke: white !important; /* Set the stroke color to white */\n    fill: none; /* Remove fill or set it to 'none' to ensure it doesn't interfere with the stroke */\n  }\n\n.light svg {\n    stroke: #00000085; /* Set the stroke color to white */\n    fill: none; /* Remove fill or set it to 'none' to ensure it doesn't interfere with the stroke */\n  }\n\n.tableNestedContentLight {\n  color: #6b7280;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: normal;\n}\n\n.tableNestedContentDark {\n  color: white;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: normal;\n}\n\n.tableRowDataLight {\n  font-size: 14px;\n  color: #6b7280;\n  font-weight: 400;\n  border-bottom: 1px solid #dddcdc;\n}\n\n.tableRowDataDark {\n  font-size: 14px;\n  color: white;\n  font-weight: 400;\n  border-bottom: 1px solid #dddcdc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dark": `ReLjY2vrqWJUKI258yGO`,
	"light": `XtTphNyWe6nPjPVWHAg6`,
	"tableNestedContentLight": `YxLEtvOF4wG9FKlOjqQP`,
	"tableNestedContentDark": `HZr9_6daKRPBFArwNlWt`,
	"tableRowDataLight": `oKkvbeGFhkgzO0uaSjQs`,
	"tableRowDataDark": `Fnk0IuocN6dbdx2IwTno`
};
export default ___CSS_LOADER_EXPORT___;
