import React, { useEffect } from 'react';
import { Controls, ReactFlow, useViewport } from 'reactflow';
import PropTypes from 'prop-types';
import 'reactflow/dist/style.css';
import { useColorModeValue } from '@chakra-ui/react';
import styles from './customflow.module.scss';
import { TO_DO_STATUS } from '../../../utils/enums/colors';
import { useDispatch, useSelector } from 'react-redux';
import { updateDefaultViewport } from '../../../redux/WorkFlowMainSlice/WorkFlowMainReducer';

const snapGrid = [20, 20];
const CustomFlow = ({
  nodes,
  edges,
  onNodesChange,
  onEdgesChange,
  onConnect,
  nodeTypes,
  edgeTypes,
  controlShow,
  onNodeDragStop,
  onFitView,
  ...props
}) =>{ 
  const { x, y, zoom } = useViewport();
  const { defaultViewport } = useSelector((state) => state.workFlowMain);
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(updateDefaultViewport({x,y,zoom}))
  },[x,y,zoom])

  return (
  <ReactFlow
    nodes={nodes}
    edges={edges}
    onNodesChange={onNodesChange}
    onEdgesChange={onEdgesChange}
    onConnect={onConnect}
    nodeTypes={nodeTypes}
    snapToGrid
    onNodeDragStop={onNodeDragStop}
    snapGrid={snapGrid}
    edgeTypes={edgeTypes}
    defaultViewport={defaultViewport}
    // fitView
    {...props}
  >
    <Controls
      position="top"
      style={{marginTop: '30px', ...useColorModeValue(
        { right: controlShow ? 360 : 0},
        {
          right: controlShow ? 360 : 0,
          background: TO_DO_STATUS,
          color: 'white',
          fill: 'white',
        }
      )}}
      onFitView={onFitView}
      className={useColorModeValue('', styles.controls)}
    />
    {/* <MiniMap /> */}
  </ReactFlow>
)}
CustomFlow.propTypes = {
  nodes: PropTypes.array.isRequired,
  edges: PropTypes.array.isRequired,
  onNodesChange: PropTypes.func.isRequired,
  onEdgesChange: PropTypes.func.isRequired,
  onNodeDragStop: PropTypes.func.isRequired,
  onFitView: PropTypes.func.isRequired,
  onConnect: PropTypes.func.isRequired,
  nodeTypes: PropTypes.object.isRequired,
  edgeTypes: PropTypes.object.isRequired,
  controlShow: PropTypes.bool.isRequired,
};
export default CustomFlow;
