import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const isAuth = localStorage.getItem('isAuth');

  if (!isAuth) {
    navigate('https://federation-sts-test.apps.actionable-science.com/auth');
  }
  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
