/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  IconButton,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import _capitalize from 'lodash/capitalize';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import CustomInput from '../../../components/InputBox/Input';
import CustomTextArea from '../../../components/Textarea/CustomTextArea';
import CustomButton from '../../../components/Button/SimpleButton';
import { LABELS } from '../../../utils/enums/labels';
import { COLORS } from '../../../utils/enums/colors';
import CustomSelectBox from '../../../components/SelectBox/Select';
import CustomSelectWithTag from './CustomSelectWithTab';
import { createMap } from '../MetricsAndSLA/Metrics/utils';
import { makeApiCallForActor } from '../../TicketTemplateBuilder/AddEditActorModal/helper';
import { getTenantRolesAndTeams } from '../../../components/dynamicSelectWithInputTags/dynamicSelectWithInputTags.helper';
import {
  SIGNATURE_MAP_KEY,
  STATUS_OPTION,
  createSignatureAtMasterList,
  createSignaturePayload,
  getActors,
  getNonMatchingElements,
  getStatusObject,
  processErrors,
} from './utils';
import { updateViewState } from '../../../redux/ViewSlice/ViewReducer';
import CustomToast from '../../../components/Notification/Notification';
import {
  addSignatureInMasterSignatures,
  updateSignatureInMasterSignatures,
} from './helper';

const AddEditSignatureDrawer = ({
  edit,
  selectedSignatureValue,
  openDrawer,
  setOpenDrawer,
}) => {
  const { addToast } = CustomToast();
  const [signatureValue, setSignatureValue] = useState({
    ...selectedSignatureValue,
  });
  const [actorOption, setActorOption] = useState([]);
  const [teamOption, setTeamOption] = useState([]);
  const [errorKey, setErrorKey] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { signatures_expanded_list } = useSelector((state) => state.viewState);

  const fetchDataForActorOption = async () => {
    const { rows } = await makeApiCallForActor();
    const option = Array.isArray(rows)
      ? rows.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      : [];
    setActorOption(option);
  };

  const addDataForTags = (key, value) => {
    if (value && Array.isArray(value) && value?.length > 0) {
      const tempData =
        signatureValue?.[SIGNATURE_MAP_KEY.ASSOCIATIONS]?.[key] || [];

      const valuesToAdd = value.map((item) => item.value);

      if (valuesToAdd.length > 0) {
        const updatedData = [...new Set([...tempData, ...valuesToAdd])];

        setSignatureValue((pre) => ({
          ...pre,
          [SIGNATURE_MAP_KEY.ASSOCIATIONS]: {
            ...pre?.[SIGNATURE_MAP_KEY.ASSOCIATIONS],
            [key]: updatedData,
          },
        }));

        setErrorKey((pre) => ({
          ...pre,
          [SIGNATURE_MAP_KEY.ASSOCIATIONS]: false,
        }));
      }
    }
  };

  const deleteTags = ({ index, key }) => {
    const tempData = signatureValue?.[SIGNATURE_MAP_KEY.ASSOCIATIONS]?.[key];
    if (tempData.length > 0) {
      tempData.splice(index, 1);
      setSignatureValue((pre) => ({
        ...pre,
        [SIGNATURE_MAP_KEY.ASSOCIATIONS]: {
          ...pre?.[SIGNATURE_MAP_KEY.ASSOCIATIONS],
          [key]: tempData,
        },
      }));
    }
  };

  useEffect(() => {
    fetchDataForActorOption();
    getTenantRolesAndTeams().then((res) => {
      const teams = res?.team?.team?.map((item) => ({
        value: item,
        label: _capitalize(item),
      }));
      setTeamOption([...teams]);
    });
  }, []);

  const ACTOR_MAPPING_VALUE = useMemo(
    () => createMap(actorOption),
    [actorOption]
  );
  const TEAM_MAPPING_VALUE = useMemo(() => createMap(teamOption), [teamOption]);
  const ACTOR_EXCLUDE_LIST = useMemo(
    () => getActors(signatures_expanded_list?.data || []),
    [signatures_expanded_list?.data]
  );

  const handleOnChangeWithKey = (key, e) => {
    setSignatureValue((pre) => ({
      ...pre,
      [key]: e.target.value,
    }));
    setErrorKey((pre) => ({ ...pre, [key]: false }));
  };

  const handleOnChangeSelectBox = (key, e) => {
    setSignatureValue((pre) => ({
      ...pre,
      [key]: e.value,
    }));
    setErrorKey((pre) => ({ ...pre, [key]: false }));
  };

  const addNewSignature = async (payload) => {
    const res = await addSignatureInMasterSignatures(
      payload,
      'Failed to create a new signature'
    );
    if (!res.error) {
      dispatch(
        updateViewState({
          stateKey: 'signatures_expanded_list',
          value: {
            ...signatures_expanded_list,
            data: [...(signatures_expanded_list?.data || []), res.response],
            count: [...(signatures_expanded_list?.data || []), res.response]
              .length,
          },
        })
      );
      addToast({
        title: 'Success',
        message: 'Successfully created a new signature',
        type: 'success',
      });
    } else {
      addToast({
        title: 'Something went wrong',
        message: res.error,
        type: 'error',
      });
    }
  };
  const updateSignature = async (payload) => {
    const editedSignatureIndex = signatures_expanded_list.data.findIndex(
      (field) => field.id === signatureValue.id
    );

    if (editedSignatureIndex !== -1) {
      const res = await updateSignatureInMasterSignatures(
        signatureValue.id,
        payload,
        'Failed to update the signature'
      );
      if (!res.error) {
        const updatedFields = [...signatures_expanded_list.data];
        updatedFields[editedSignatureIndex] = res.response.data;
        dispatch(
          updateViewState({
            stateKey: 'signatures_expanded_list',
            value: {
              ...signatures_expanded_list,
              data: [...updatedFields],
            },
          })
        );
        addToast({
          title: 'Success',
          message: 'Successfully updated the signature',
          type: 'success',
        });
      } else {
        addToast({
          title: 'Something went wrong',
          message: res.error,
          type: 'error',
        });
      }
    }
  };
  const onAddOrEditClick = () => {
    setIsLoading(true);
    const payload = createSignaturePayload(signatureValue);
    const { error } = createSignatureAtMasterList.validate(payload, {
      abortEarly: false,
    });
    if (!isEmpty(error)) {
      const tempError = processErrors(error.details);
      setErrorKey({ ...tempError });
      setIsLoading(false);
      return;
    }
    if (edit) {
      updateSignature(payload);
    } else {
      addNewSignature(payload);
    }
    setIsLoading(false);
    setOpenDrawer(false);
  };

  return (
    <Drawer
      size="lg"
      placement="right"
      onClose={setOpenDrawer}
      isOpen={openDrawer}
    >
      <DrawerContent>
        <DrawerHeader>
          <Flex alignItems="center" gap="10px">
            <IconButton
              colorScheme="blue"
              aria-label="Close Drawer"
              size="sm"
              borderRadius="50%"
              minW="1.5rem"
              height="1.5rem"
              variant="outline"
              icon={<ArrowBackIcon />}
              onClick={() => setOpenDrawer(false)}
            />
            <Text fontSize="16px">
              {edit ? 'Edit Signature' : 'Add New Signature'}
            </Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <CustomInput
            value={signatureValue?.[SIGNATURE_MAP_KEY.NAME]}
            onChange={(e) => {
              handleOnChangeWithKey(SIGNATURE_MAP_KEY.NAME, e);
            }}
            isMandatory
            label="Name"
            placeholder="Signature Name"
            isError={errorKey[SIGNATURE_MAP_KEY.NAME]}
            errorMessage="Name is required"
          />
          <CustomTextArea
            value={signatureValue?.[SIGNATURE_MAP_KEY.DESCRIPTION]}
            onChange={(e) => {
              handleOnChangeWithKey(SIGNATURE_MAP_KEY.DESCRIPTION, e);
            }}
            placeholder="Description Content"
            label="Description"
          />
          <CustomTextArea
            value={signatureValue?.[SIGNATURE_MAP_KEY.SIGNATURE]}
            onChange={(e) => {
              handleOnChangeWithKey(SIGNATURE_MAP_KEY.SIGNATURE, e);
            }}
            placeholder="Signature Content"
            label="Signature"
            isMandatory
            isError={errorKey[SIGNATURE_MAP_KEY.SIGNATURE]}
            errorMessage="Signature is required"
          />
          <CustomSelectBox
            value={getStatusObject(
              signatureValue?.[SIGNATURE_MAP_KEY.STATUS],
              STATUS_OPTION
            )}
            options={STATUS_OPTION}
            onChange={(e) => {
              handleOnChangeSelectBox(SIGNATURE_MAP_KEY.STATUS, e);
            }}
            label="Status"
            isMandatory
            isError={errorKey[SIGNATURE_MAP_KEY.STATUS]}
            errorMessage="Status is required"
          />
          <CustomSelectWithTag
            label={SIGNATURE_MAP_KEY.ACTORS}
            selectBoxOption={getNonMatchingElements(
              ACTOR_EXCLUDE_LIST,
              actorOption
            )}
            addDataForTags={addDataForTags}
            tagsData={signatureValue?.[SIGNATURE_MAP_KEY.ASSOCIATIONS]}
            tagsMapping={ACTOR_MAPPING_VALUE}
            deleteTags={deleteTags}
          />
          <CustomSelectWithTag
            label={SIGNATURE_MAP_KEY.TEAMS}
            selectBoxOption={teamOption}
            addDataForTags={addDataForTags}
            tagsData={signatureValue?.[SIGNATURE_MAP_KEY.ASSOCIATIONS]}
            tagsMapping={TEAM_MAPPING_VALUE}
            deleteTags={deleteTags}
          />
          <CustomSelectWithTag
            label={SIGNATURE_MAP_KEY.QUEUES}
            selectBoxOption={[]}
            addDataForTags={addDataForTags}
          />
          {errorKey[SIGNATURE_MAP_KEY.ASSOCIATIONS] && (
            <span className="field-error">
              Please add at least one item from Actor, Team, Queue
            </span>
          )}
        </DrawerBody>
        <DrawerFooter>
          <CustomButton
            variant="outline"
            pl="25px"
            pr="25px"
            buttonColor={LABELS.SIGNATURE_DRAWER_COLOR}
            buttonText={LABELS.ACTIONS.DISCARD}
            style={{
              border: 'none',
              background: COLORS.SIGNATURE_DRAWER_BG,
              height: '38px',
              marginRight: '10px',
              color: COLORS.SIGNATURE_DRAWER_COLOR,
            }}
            _hover={{ color: useColorModeValue(COLORS.BLACK, 'white') }}
            onClick={() => {
              setOpenDrawer(false);
            }}
            size="lg"
          />
          <CustomButton
            variant="solid"
            pl="25px"
            pr="25px"
            style={{ height: '38px' }}
            buttonColor="customBlue"
            buttonText={LABELS.ACTIONS.ADD}
            onClick={onAddOrEditClick}
            size="lg"
            isLoading={isLoading}
          />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

AddEditSignatureDrawer.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  setOpenDrawer: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  selectedSignatureValue: PropTypes.object,
};
AddEditSignatureDrawer.defaultProps = {
  edit: false,
  selectedSignatureValue: {},
};

export default AddEditSignatureDrawer;
