import React from 'react';
import { Box, FormLabel, useColorModeValue } from '@chakra-ui/react';
import { COLORS } from '../../../utils/enums/colors';
import CustomSelectBox from '../../../components/SelectBox/Select';
import SelectFieldFromTemplateWithDelete from '../../../components/selectFieldFromTemplateWithDelete';
import { getWholeOption } from '../../TicketTemplateWorkflow/helper';
import { removeDuplicateItem } from '../../../utils/helper/roleBasedAccess';

const renderCreateIssuesForm = (
  relatedTemplates,
  actionToPerform,
  handleChange,
  handleChanges,
  actionToPerformSelectedIndex,
  actionErrorData,
  teamQueueMap,
  fieldDefaultDataForCreateIssue,
  relationsData,
  selectedTemplate,
  actionState,
  hierarchies,
  fieldHierarchyMaps
) => {
  const relationshipOptionByItemType = (value) => {
    const options = [];
    relationsData.forEach((item) => {
      if (item.template_id_left === value) {
        options.push({
          value: item.relation_def.right_to_left,
          label: item.relation_def.right_to_left,
        });
      }
      if (item.template_id_right === value) {
        options.push({
          value: item.relation_def.left_to_right,
          label: item.relation_def.left_to_right,
        });
      }
    });
    options.push({ value: 'none', label: 'None' });
    return removeDuplicateItem(options);
  };

  const targetTemplateName = () => {
    if (actionState.linked_item_type) {
      const template = relatedTemplates.find(
        (template_) => template_.id === actionState.linked_item_type
      );
      return template?.name;
    }
    return '';
  };
  const getSimilaritiesField = () => {
    const value =
      relationsData.find(
        (relation) =>
          relation.relation_def.right_to_left ===
            actionState.linked_item_relation &&
          relation.template_id_right === actionState.linked_item_type
      ) ?? null;
    if (!value) return [];
    const { similarity } = value ?? {};
    if (!fieldDefaultDataForCreateIssue?.fieldList) {
      return [];
    }
    const fieldLabelMap = {};
    selectedTemplate?.fields.forEach((field) => {
      fieldLabelMap[field.id] = field.label;
    });

    const result = {};
    similarity.forEach((similarField) => {
      const leftFieldId = similarField?.left_field_id;
      const rightFieldId = similarField?.right_field_id;
      if (fieldLabelMap[leftFieldId]) {
        result[rightFieldId] = fieldLabelMap[leftFieldId];
      }
    });
    return result;
  };

  return (
    <Box marginTop="1rem" data-testid="DynamicRendererSlackRecordsBox">
      <CustomSelectBox
        label="Issue Type"
        options={relatedTemplates.map((template) => ({
          label: template.id === selectedTemplate?.id ? 'Self' : template.name,
          value: template.id,
        }))}
        placeholder="Select an Issue Type"
        value={actionToPerform.issue_type}
        onChange={(value) => {
          const events = [
            {
              value,
              path: `action_to_perform[${actionToPerformSelectedIndex}].issue_type`,
            },
            {
              value: [],
              path: `action_to_perform[${actionToPerformSelectedIndex}].create_issue`,
            },
          ];
          handleChanges(events);
        }}
        focusBorderColor={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
        id="DynamicRendererUpdateCreateCSelect"
      />
      {actionToPerform?.issue_type && (
        <Box marginTop="1rem">
          <CustomSelectBox
            label="Relationship"
            options={
              relationshipOptionByItemType(
                actionToPerform?.issue_type?.value
              ) || []
            }
            placeholder="Select an Issue Type"
            value={getWholeOption(
              actionToPerform?.relationship || 'none',
              relationshipOptionByItemType(actionToPerform?.issue_type?.value)
            )}
            onChange={({ value }) => {
              handleChange(
                value,
                `action_to_perform[${actionToPerformSelectedIndex}].relationship`
              );
            }}
            focusBorderColor={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
            id="DynamicRendererUpdateCreateCSelect"
          />
        </Box>
      )}
      {actionToPerform?.issue_type && (
        <>
          <FormLabel
            fontWeight={600}
            mt="1rem"
            data-testid="DynamicRendererUpdateCreateFormLabel"
          >
            Create Issue
            <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
          </FormLabel>
          <SelectFieldFromTemplateWithDelete
            key={actionToPerform?.issue_type?.value}
            fieldList={fieldDefaultDataForCreateIssue?.fieldList || []}
            teamQueueMap={teamQueueMap}
            value={actionToPerform?.create_issue || []}
            workFlowStatusList={
              fieldDefaultDataForCreateIssue?.workflowStatusList || []
            }
            onChange={(value) => {
              handleChange(
                value,
                `action_to_perform[${actionToPerformSelectedIndex}].create_issue`
              );
            }}
            isRequired
            preventFieldRepeat
            hierarchies={hierarchies}
            fieldHierarchyMap={fieldHierarchyMaps}
            similaritiesField={getSimilaritiesField()}
            parentFields={selectedTemplate?.fields || []}
            targetTemplateName={targetTemplateName()}
            sourceTemplateName={selectedTemplate.name}
            actorAssociationMap={fieldDefaultDataForCreateIssue.actors || []}
          />
        </>
      )}

      {actionErrorData?.create_issue ? (
        <span className="field-error">
          Field name & value can&apos;t be empty
        </span>
      ) : null}
    </Box>
  );
};

export default renderCreateIssuesForm;
