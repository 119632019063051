import { Box, Flex, FormLabel, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useStoreApi } from 'reactflow';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../styles/RestrictMoveRole.module.scss';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { LABELS } from '../../../utils/enums/labels';
import { WorkFlowNodeTypes } from '../../../utils/enums/types';
import {
  CAUTION_STATUS,
  COLORS,
  DONE_STATUS,
  FIXING_STATUS,
  IN_PROGRESS_STATUS,
  TO_DO_STATUS,
} from '../../../utils/enums/colors';
import SelectComponent from '../../../components/SelectBox/SelectComponent';
import { rulePermisstionOption } from '../../../utils/enums/selectOption';
import { addToListRestrictSpecialPermission } from '../../../redux/WorkFlowRuleSlice/WorkFlowRuleReducer';

const RestrictValidateSpecialPermissionContainer = () => {
  const dispatch = useDispatch();
  const { specialPermission } = useSelector((state) => state.workFlowRules);
  const [transitionState, setTransitionState] = useState({});
  const [permissionState, setPermissionState] = useState('');
  const flowStore = useStoreApi();
  const { edges, nodeInternals } = flowStore.getState();
  const innerEdgeList = edges
    .filter((i) => i.id !== 'edges_1')
    .map((i) => ({
      label: i.data?.label,
      value: i?.id,
      source: i?.source,
      target: i?.target,
    }));
  const determineBgColor = (type) => {
    if (type === WorkFlowNodeTypes.toDoNode) {
      return TO_DO_STATUS;
    }
    if (type === WorkFlowNodeTypes.toProgressNode) {
      return IN_PROGRESS_STATUS;
    }
    if (type === WorkFlowNodeTypes.toDoneNode) {
      return DONE_STATUS;
    }
    if (type === WorkFlowNodeTypes.toCautionNode) {
      return CAUTION_STATUS;
    }
    if (type === WorkFlowNodeTypes.toFixingNode) {
      return FIXING_STATUS;
    }
    return TO_DO_STATUS;
  };
  useEffect(() => {
    const matchingRestrictIssue = specialPermission.find(
      (issue) => issue.transition_id === transitionState?.value
    );
    if (matchingRestrictIssue) {
      setPermissionState(matchingRestrictIssue.permissionValue);
    }
  }, [transitionState.value]);
  return (
    <>
      <FormLabel
        marginTop="30px"
        className={styles.formLabelRestric}
        data-testid="RestrictValidateSpecialPermissionContainerTransFormLabel"
      >
        {LABELS.TITLE.FOR_TRANSTION}
      </FormLabel>
      <CustomSelectBox
        name=""
        value={transitionState}
        options={innerEdgeList}
        onChange={(e) => {
          setPermissionState('');
          setTransitionState(e);
        }}
        // eslint-disable-next-line react/no-unstable-nested-components
        getOptionLabel={(e) => (
          <Flex
            align="center"
            className="text-[14px] font-500"
            data-testid="RestrictValidateSpecialPermissionContainerBoxFlex"
          >
            <span className="ml-1">{e.label}</span>
            <Box
              className="ml-[10px] mr-[10px] text-white pl-[7px] pr-[7px]"
              bgColor={determineBgColor(nodeInternals.get(e.source)?.type)}
              data-testid="RestrictValidateSpecialPermissionContainerSrcBox"
            >
              {nodeInternals.get(e.source)?.data.label}
            </Box>
            {e?.label && <ArrowForwardIcon />}
            <Box
              className="ml-[10px] mr-[10px] text-white pl-[7px] pr-[7px]"
              bgColor={determineBgColor(nodeInternals.get(e.target)?.type)}
              data-testid="RestrictValidateSpecialPermissionContainerTargetBox"
            >
              {nodeInternals.get(e.target)?.data.label}
            </Box>
          </Flex>
        )}
        id="RestrictValidateSpecificPermissionSelectBox"
      />
      <Box
        className={styles.differentTransitionTitle}
        data-testid="RestrictValidateSpecialPermissionContainerRuleBox"
      >
        {LABELS.TITLE.DIFFERENT_TRANSITION_RULE}
      </Box>
      <FormLabel
        marginTop="13px"
        className={styles.formLabelRestric}
        data-testid="RestrictValidateSpecialPermissionContainerRestricFormLabel"
      >
        {LABELS.TITLE.CHECK_PERMISSION}
      </FormLabel>
      <SelectComponent
        options={rulePermisstionOption}
        value={permissionState}
        height="32px"
        handleChange={(value) => {
          setPermissionState(value);
          if (transitionState.value) {
            dispatch(
              addToListRestrictSpecialPermission({
                transitionId: transitionState.value,
                value,
              })
            );
          }
        }}
        focusBorderColor={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
      />
    </>
  );
};
export default RestrictValidateSpecialPermissionContainer;
