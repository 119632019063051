/* eslint-disable indent */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-case-declarations */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useRef, useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Table,
  TableContainer,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import AutoSizer from 'react-virtualized-auto-sizer';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { CopyIcon, DeleteIcon, EditIcon, ViewIcon } from '@chakra-ui/icons';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { useDispatch, useSelector } from 'react-redux';
import { FaStarOfLife } from 'react-icons/fa';
import { HiOutlineClipboardDocumentList } from 'react-icons/hi2';
import { IoSearch } from 'react-icons/io5';
import {
  BORDER_DARK,
  BORDER_LIGHT,
  COLORS,
  TH_LIGHT,
} from '../../utils/enums/colors';
// import CustomToolTip from '../../components/Tooltip/Tooltip';
import CustomToast from '../../components/Notification/Notification';
import { setActiveModal } from '../../redux/ModalReducer/ModalSlice';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import axiosInstance from '../../utils/axios/axios';
import TableColumnText from '../../components/Text/TableColumnText';
import { checkForV2 } from '../../utils/helper';
// import { stringFormatForBoundTo } from './utils';
import { isV1FieldType } from '../../utils/helper/fieldHelper';

const noActionAllowedForFieldMasterPage = (actionItem, fieldItem) =>
  actionItem?.page === 'edit_field_masterpage' && isV1FieldType(fieldItem);

const ReactWindowTable = ({
  hasNextPage,
  isNextPageLoading,
  items,
  title,
  loadNextPage,
  calculatedHight,
  columns,
  actions,
  onRowClick,
  sectionName,
}) => {
  const listRef = useRef(null);
  const { addToast } = CustomToast();

  // eslint-disable-next-line no-unused-vars
  const [tableheight, setTableHeight] = useState(700);
  // const [overflow, setOverflow] = useState(false);
  const itemCount = hasNextPage ? items?.length + 1 : items?.length;
  const loadMoreItems = isNextPageLoading ? () => { } : loadNextPage;
  const isItemLoaded = (index) => !hasNextPage || index < items.length;
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const viewState = useSelector((state) => state.viewState);

  const makeApiRequest = async ({
    method,
    targetUrl,
    data,
    defaultErrorMessage,
  }) => {
    if (method === 'get') {
      try {
        const res = await axiosInstance.get(targetUrl, { data });
        return {
          response: res?.data,
          error: null,
        };
      } catch (err) {
        return {
          response: null,
          error: err?.response?.data?.message || defaultErrorMessage,
        };
      }
    } else if (method === 'post') {
      try {
        const response = await axiosInstance.post(targetUrl, { data });
        return {
          response: response?.data,
          error: null,
        };
      } catch (err) {
        return {
          error: err?.response?.data?.message || defaultErrorMessage,
          response: null,
        };
      }
    } else if (method === 'patch') {
      try {
        const response = await axiosInstance.patch(targetUrl, { data });
        return {
          response: response?.data,
          error: null,
        };
      } catch (err) {
        return {
          error: err?.response?.data?.message || defaultErrorMessage,
          response: null,
        };
      }
    }
    return null;
  };
  const actionRender = (item, actionArr) =>
    actionArr?.map((i) => {
      if (i?.icon === 'editIcon' && i?.name === 'view_workFlow') {
        return (
          <EditIcon
            data-testid={`${sectionName === 'ticket_template_fields_list_view' ? 'templateFields' : sectionName}-$${item?.label || item?.name}-edit`}
            onClick={() => {
              const params = {
                sourceRoute: i.source_route,
                id: item.id,
                name: item.name,
              };
              const queryString = new URLSearchParams(params).toString();
              const url = i?.isUrlParams
                ? `${i.target}?${queryString}`
                : `${i.target}/${params.id}?name=${params.name}`;
              navigate(url);
            }}
          />
        );
      }
      if (i?.icon === 'editIcon') {
        if (noActionAllowedForFieldMasterPage(i, item)) {
          return null;
        }
        return (
          <EditIcon
            className="!h-4 !w-4"
            onClick={() => {
              if (i.target_type === 'on_click') {
                navigate(`${i.navigatetUrl}/${item.id}`);
              } else {
                dispatch(setActiveModal({ targetModalId: i.target_modal_id }));
                dispatch(
                  updateViewState({
                    stateKey: i.target_modal_id,
                    value: {
                      ...item,
                      labelKey: i.label_key,
                      masterType: i.master_type,
                    },
                  })
                );
              }
            }}
            data-testid={`${sectionName === 'ticket_template_fields_list_view' ? 'templateFields' : sectionName}-${item?.label || item?.name}-edit`}
          />
        );
      }
      if (i?.icon === 'deleteIcon' && !item?.wasApproved) {
        if (noActionAllowedForFieldMasterPage(i, item)) {
          return null;
        }
        return (
          <DeleteIcon
            className="ml-2 !h-4 !w-4"
            data-testid={`${sectionName === 'ticket_template_fields_list_view' ? 'templateFields' : sectionName}-$${item?.label || item?.name}-delete`}
            onClick={() => {
              dispatch(setActiveModal({ targetModalId: i.target_modal_id }));
              dispatch(
                updateViewState({
                  stateKey: i.target_modal_id,
                  value: {
                    ...item,
                    labelKey: i.label_key,
                    masterType: i.master_type,
                    listKey: i.listKey,
                    targetUrl: i.target_url,
                    method: i.method,
                  },
                })
              );
            }}
          />
        );
      }
      if (i?.icon === 'copyIcon') {
        if (noActionAllowedForFieldMasterPage(i, item)) {
          return null;
        }
        return (
          <CopyIcon
            className="ml-2"
            data-testid={`${sectionName === 'ticket_template_fields_list_view' ? 'templateFields' : sectionName}-${item?.label || item?.name}-clone`}
            onClick={async () => {
              dispatch(setActiveModal({ targetModalId: i.target_modal_id }));
              if (!checkForV2()) {
                const result = await makeApiRequest({
                  method: i.method,
                  targetUrl: i.target_url,
                  data: {
                    id: item.id,
                  },
                  defaultErrorMessage: 'Failed to clone',
                });
                if (!result) {
                  return;
                }
                if (result?.error) {
                  addToast({
                    type: 'error',
                    title: 'Something went wrong',
                    message: result?.error,
                  });
                  return;
                }
                if (!result?.response) {
                  return;
                }
                const updatedList = _.cloneDeep(viewState[i.listKey]);
                const COUNT_NO = viewState[i.listKey]?.count || 0;
                updatedList.data = [result.response, ...updatedList.data];
                if (COUNT_NO > 0) {
                  updatedList.count += 1;
                }
                addToast({
                  type: 'success',
                  title: 'Success',
                  message: `${i?.master_type} successfully cloned`,
                });
                return;
              }
              dispatch(
                updateViewState({
                  stateKey: i.target_modal_id,
                  value: {
                    id: item?.id,
                    title: item?.label || item?.name,
                    masterType: i.master_type,
                    listKey: i.listKey,
                    targetUrl: i.target_url,
                    method: i.method,
                  },
                })
              );
            }}
          />
        );
      }
      if (i?.icon === 'viewIcon') {
        return (
          <ViewIcon
            className="mr-2"
            onClick={() => {
              const params = {
                sourceRoute: i.source_route,
                id: item.id,
                name: item.name,
              };
              const queryString = new URLSearchParams(params).toString();
              const url = i?.isUrlParams
                ? `${i.target}?${queryString}`
                : `${i.target}/${params.id}?name=${params.name}`;
              navigate(url);
            }}
          />
        );
      }
      return null;
    });
  const getProcessedValue = (column, item) => {
    switch (column.type) {
      case 'actions':
        return <Box className="text-[#94A3B8]">{actionRender(item, actions)}</Box>;
      case 'date':
        let value = item[column.col_name];
        const dateObj = new Date(value);
        if (dateObj instanceof Date && !isNaN(dateObj)) {
          const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          };
          value = dateObj.toLocaleString('en-US', options).replace('at', ',');
        }
        return <TableColumnText text={value} />;
      case 'bound_to':
        const ASSOCIATION_OBJ = item.associations;
        const allValues = Object.values(ASSOCIATION_OBJ);
        const arrays = allValues.filter((prop) => Array.isArray(prop));
        const combinedArray = [].concat(...arrays);
        break;
      case 'TemplateFieldsIconList':
        const {
          type_based_attributes: {
            required,
            is_reportable: isReportable,
            is_searchable: isSearchable,
            include_in_report: isReportableField,
          }
        } = item;
        return (
          <Flex gap="3" color="#94A3B8">
          {isSearchable && <IoSearch className="h-4 w-4" />}
          {required && <FaStarOfLife className="h-3 w-3" />}
          {isReportable && <HiOutlineClipboardDocumentList className="h-4 w-4" />}
          {isReportableField && <HiOutlineClipboardDocumentList className="h-4 w-4" />}
          </Flex>
        );
      default:
        return <TableColumnText text={item[column?.col_name] || ''} />;
    }
    return undefined;
  };

  const renderTableRow = (item, style, index) => (
    <tr
      key={item?.id}
      style={style}
      className={`table-row text-[#6B7280] border-t border-b pl-3 ${useColorModeValue(
        'hover:bg-gray-100',
        'hover:bg-gray-600'
      )}`}
    >
      {columns.map((column) => (
        <td
          key={column?.col_name}
          style={{
            width: `${100 / columns?.length}%`,
            textAlign: column?.col_name === 'actions' ? 'center' : '',
            padding: '15px 0px',
            paddingLeft: column?.col_name === 'actions' ? '15px' : '0',
            boxSizing: 'borderBox',
            fontSize: '14px',
            color: useColorModeValue('#6B7280', 'white'),

          }}
          className="ellipsis"
          {...(onRowClick &&
            typeof onRowClick === 'function' && {
            onClick: (e) => {
              onRowClick({
                event: e,
                rowData: item,
                column,
                rowIndex: index,
              });
            },
          })}
        >
          {column?.cellRenderer && typeof column.cellRenderer === 'function'
            ? column.cellRenderer({
              rowData: item,
              column,
              rowIndex: index,
            })
            : getProcessedValue(column, item)}
        </td>
      ))}
    </tr>
  );

  useEffect(() => {
    const maxHeight = window.innerHeight - calculatedHight;
    if (items) {
      const calHeight = items.length * 50;
      if (calHeight < maxHeight) {
        setTableHeight(calHeight);
        // setOverflow(false);
      } else {
        setTableHeight(maxHeight);
        // setOverflow(true);
      }
    }
  }, [items]);
  const Item = ({ index, style }) =>
    isItemLoaded(index) && renderTableRow(items[index], style, index);

  return (
    <Box
      // style={{ height: `${tableheight}px`, minHeight: '100px' }}
      data-testid="ReactWindowTableContainerDiv"
      height="calc(100vh - 18rem)"
    >
      <TableContainer
        borderRadius="0px"
        // borderWidth="1.5px"
        borderTop="1.5"
        fontSize="14px"
        className="pl-3"
        borderColor={useColorModeValue(BORDER_LIGHT, BORDER_DARK)}
        color={useColorModeValue(COLORS.BLACK, COLORS.TEXT_DARK)}
        data-testid="ReactWindowTableContainer"
      >
        <Table variant="simple" data-testid="ReactWindowTable">
          <Thead data-testid="ReactWindowTableThead">
            <Tr data-testid="ReactWindowTableTr">
              {columns.map((column) => (
                <Th
                  color={useColorModeValue(TH_LIGHT, 'white')}
                  key={column?.col_name}
                  width={`${100 / columns?.length}%`}
                  textAlign={
                    column?.col_name === 'actions' ? 'center' : 'start'
                  }
                  p="18px 0px"
                  fontWeight="500"
                  fontSize="14px"
                  style={{ ...column?.headerStyle, textTransform: 'none' }}
                  data-testid="ReactWindowTableTh"
                >
                  {column?.headerRenderer &&
                    typeof column.headerRenderer === 'function'
                    ? column.headerRenderer({ column })
                    : _.startCase(
                      column?.col_name === 'log_field'
                        ? 'queue'
                        : column?.col_name
                    )}
                </Th>
              ))}
            </Tr>
          </Thead>
        </Table>
      </TableContainer>
      {/* <InfiniteLoader
        ref={listRef}
        isItemLoaded={isItemLoaded}
        itemCount={itemCount}
        loadMoreItems={loadMoreItems}
        threshold={1}
      >
        {({ onItemsRendered, ref }) => (
          <AutoSizer>
            {({ width }) => (
              <List
                className="List text-[#6B7280]"
                height={tableheight}
                itemCount={itemCount}
                itemSize={50}
                onItemsRendered={onItemsRendered}
                ref={ref}
                width={width}
                style={{
                  borderBottom: '1px solid #E2E8F0',
                  // borderTop: '0px',
                  // borderLeft: '0px',
                  // borderRight: '0px',
                  // borderRadius: '0px 0px 10px 10px',
                  fontSize: '14px',
                  fontWeight: '400',
                  // color: `${useColorModeValue("#6B7280", "white")}`
                  overflow: overflow ? 'auto' : 'hidden',
                }}
                data-testid="ReactWindowTableList"
              >
                {Item}
              </List>
            )}

          </AutoSizer>
        )}
      </InfiniteLoader> */}

      {itemCount > 0 ? (
        <InfiniteLoader
          ref={listRef}
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMoreItems}
          threshold={1}
        >
          {({ onItemsRendered, ref }) => (
            <AutoSizer>
              {({ width }) => (
                <List
                  className="List text-[#6B7280]"
                  height={window.innerHeight - calculatedHight}
                  itemCount={itemCount}
                  itemSize={50}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                  width={width}
                  style={{
                    // borderBottom: '1px solid #E2E8F0',
                    fontSize: '14px',
                    // scrollbarWidth: 'none',
                    fontWeight: '400',
                  }}
                  data-testid="ReactWindowTableList"
                >
                  {Item}
                </List>
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="calc(100vh - 18rem - 60px)" // Adjust this as per your header height
          fontSize="16px"
          color={useColorModeValue(COLORS.BLACK, COLORS.TEXT_DARK)}
          data-testid="NoRecordsMessage"
        >
          {`No Record Found For ${title || 'data'}`}
        </Box>
      )}
    </Box>
  );
};

ReactWindowTable.propTypes = {
  hasNextPage: PropTypes.bool.isRequired,
  isNextPageLoading: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  calculatedHight: PropTypes.number,
  loadNextPage: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  actions: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  sectionName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

ReactWindowTable.defaultProps = {
  onRowClick: undefined,
  calculatedHight: 350,
};

export default ReactWindowTable;
