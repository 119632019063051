import { LABELS, TEMPLATE_VALIDATE_KEY } from '../../../utils/enums/labels';
import { TemplateTypes } from '../../../utils/enums/types';

const createBaseSettings = (data, setFields, setIsError) => ({
  row1: [
    {
      label: LABELS.TEMPLATE_PROPERTIES.NAME,
      value: `${data?.name || ''}`,
      placeholder: '',
      required: true,
      key: TEMPLATE_VALIDATE_KEY.NAME,
      errorString: LABELS.TEMPLATE_ERROR.NAME,
      onChange: (value) => {
        setFields({ ...data, name: value });
        setIsError((pre) => ({ ...pre, name: !value }));
      },
    },
  ],
  row2: [
    {
      label: LABELS.TEMPLATE_PROPERTIES.SINGLE_TYPE,
      value: TemplateTypes.SINGLE,
    },
    {
      label: LABELS.TEMPLATE_PROPERTIES.NESTED_TYPE,
      value: TemplateTypes.NESTED,
    },
  ],

  row3: [
    {
      id: 3,
      label: LABELS.TEMPLATE_PROPERTIES.DESCRIPTION,
      value: data?.description,
      placeholder: '',
      onChange: (value) => setFields({ ...data, description: value }),
    },
  ],
});

export default createBaseSettings;
