import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);
const baseStyle = definePartsStyle(() => ({
  dialog: {
    borderRadius: '14px',
    bg: `white`,
    _dark: {
      bg: `gray.800`,
      color: 'white',
    },
  },
  dialogContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    fontSize: '29px',
    padding: '32px 32px 0px 35px',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
  },
  closeButton: {
    width: '45px',
    top: '35px',
    right: '28px',
  },
  body: {
    // padding: '1rem 2rem',
    padding: 0,
    margin: '1rem 2rem',
    maxHeight: '80%',
  },
  footer: {
    padding: '1rem 2rem',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
  },
}));
const modalTheme = defineMultiStyleConfig({
  baseStyle,
});

export default modalTheme;
