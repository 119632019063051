import _find from 'lodash/find';
import axiosInstance from '../axios/axios';

const TOP = 1000;

export const formatDateWithUTC = (timestamp) => {
  const date = new Date(timestamp);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(
    date
  );
  const year = date.getUTCFullYear().toString().slice(-2);

  return `${day}/${month}/${year}`;
};

export const capitalizeFirstLetter = (str) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const demo = () => {};

export const isTemplateDetailsScreen = (pathname = window.location.pathname) =>
  pathname.toLowerCase().includes('template-makers/template');

// TODO: Remove this hardcoded response
export const checkForV2 = () => true;
// process.env.V2_MODE === 'true' || process.env.V2_MODE === true;

export const camelCase = (str) =>
  // Using replace method with regEx
  str
    ?.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase()
    )
    .replace(/\s+/g, '');

export const getLabelByValue = (value, state) => {
  const items = _find(state, (item) => item.value === value);
  return items ? items.label : '';
};

const getData = async ({ endpoint, skip, select, filter = '' }) => {
  try {
    const url = `${endpoint}?$top=${TOP}&$skip=${skip}&$select=${select}${
      filter ? `&$filter=${filter}` : ''
    }`;
    const res = await axiosInstance.get(url);
    return {
      response: res.data,
      error: null,
    };
  } catch (err) {
    return {
      response: null,
      error: err.toString(),
    };
  }
};

export const getPaginatedData = async ({ endpoint, select, filter }) => {
  const data = [];
  let skip = 0;
  let hasNextPage = true;

  do {
    // eslint-disable-next-line no-await-in-loop
    const res = await getData({ endpoint, skip, select, filter });
    if (res.error) {
      hasNextPage = false;
    } else {
      const {
        response: { rows, count },
      } = res;

      // Appending the response to the array
      data.push(...rows);

      // Check if we have more records to be fetched
      if (data.length >= count) {
        hasNextPage = false;
      } else {
        skip += TOP;
        hasNextPage = true;
      }
    }
  } while (hasNextPage);

  return data;
};
export const isEmptyCondition = (and) => and?.length === 1 &&
         and[0]?.or?.length === 1 &&
         and[0]?.or[0]?.leftOperand === '' &&
         and[0]?.or[0]?.rightOperand === '' &&
         and[0]?.or[0]?.operator === '';
