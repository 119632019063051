/* eslint-disable import/prefer-default-export */
import { isNumber } from 'lodash';

// function to verify duplicate option (label or value)
export function isDuplicatesLabelValue(arr) {
  const labelSet = {};
  const valueSet = {};
  const errors = {};
  const rankSet = {};

  arr.forEach((obj) => {
    if (obj.rank && rankSet[obj.rank]) {
      errors.duplicateRank = { value: obj.label, error: 'Rank is duplicate' };
    } else {
      rankSet[obj.rank] = true;
    }
    if (obj.label && labelSet[obj.label.trim()]) {
      errors.duplicateLabel = { value: obj.label, error: 'Label is duplicate' };
    } else {
      labelSet[obj.label.trim()] = true;
    }

    if (obj.value && valueSet[obj.value.trim()]) {
      errors.duplicateValue = { value: obj.value, error: 'Value is duplicate' };
    } else {
      valueSet[obj.value.trim()] = true;
    }
  });
  return errors;
}

export const validateDropDownOptions = (data) => data.map((obj) => {
  const errors = {};

  // Validate rank
  if (!obj?.rank) errors.rank = 'Rank is required.';
  if (obj?.rank && obj?.rank <= 0) errors.rank = 'Rank must be a positive integer greater than zero.';

  // Validate label
  if (!obj.label?.trim()) {
    errors.label = 'Label is required.';
  }

  // Validate value
  if (!obj.value?.trim()) {
    errors.value = 'Value is required.';
  }

  return errors;
});
