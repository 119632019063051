import React, { useCallback, useMemo } from 'react';

import PropTypes from 'prop-types';
import AttachmentUpload from '../AttachmentUpload';
import CustomDrawer from '../../pages/TenantFilters/CustomDrawer';
import { mediaFormatSelectOpt2FileType } from '../../utils/enums/selectOption';

const HierarchyEditImageDrawer = ({ open, setOpen, imageUrlInfo, onSave }) => {
  // const [tempUrlInfo, setTempUrlInfo] = useState({ ...imageUrlInfo });

  const onCancel = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSave = useCallback(
    (imageRes) => {
      onSave(imageRes);
      onCancel();
    },
    [onSave, onCancel]
  );

  const onImageDelete = useCallback(
    (imageRes) => {
      // setTempUrlInfo({ ...imageRes });
      handleSave({ blobName: '', imageUrl: '' });
    },
    [handleSave]
  );

  const onImageUpload = useCallback(
    (imageRes) => {
      // setTempUrlInfo({ ...imageRes });
      handleSave({ blobName: imageRes[imageRes.length - 1], imageUrl: '' });
    },
    [handleSave]
  );

  const drawerBody = useMemo(
    () => (
      <>
        <div>
          <AttachmentUpload
            // imageUrl={tempUrlInfo?.imageUrl}
            blobName={[imageUrlInfo?.blobName]}
            onImageUpload={onImageUpload}
            onImageDelete={onImageDelete}
            maxFileSize={4 * 1024 * 1024}
            maxNoOfFileAllow={1}
            fileSupportForUpload={mediaFormatSelectOpt2FileType.images}
          />
        </div>

        {/* <div className="mt-4 flex gap-2 items-center justify-end">
          <CustomButton
            variant="outline"
            onClick={onCancel}
            buttonText="Cancel"
          />

          <CustomButton
            variant="solid"
            onClick={handleSave}
            colorScheme="blue"
            buttonText="Save"
          />
        </div> */}
      </>
    ),
    [imageUrlInfo, onImageUpload, onImageDelete]
  );

  return (
    <CustomDrawer
      title="Upload Image"
      open={open}
      closeDrawer={onCancel}
      drawerBody={drawerBody}
      showDrawerFooter={false}
    />
  );
};

HierarchyEditImageDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  imageUrlInfo: PropTypes.object,
};

HierarchyEditImageDrawer.defaultProps = {
  imageUrlInfo: {},
};

export default HierarchyEditImageDrawer;
