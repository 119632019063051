/* eslint-disable import/no-cycle */
import React from 'react';
import {
  AddIcon,
  ArrowBackIcon,
  CopyIcon,
  DeleteIcon,
  EditIcon,
  ViewIcon,
} from '@chakra-ui/icons';
import { ReactSVG } from 'react-svg';
import { updateModalList } from '../../redux/ModalReducer/ModalSlice';
import AccordionView from './AccordionView';
import Actions from './Actions';
import ContainerView from './ContainerView';
import ElementView from './ElementView';
import ListShort from './ListShort';
import ModalView from './ModalView';
import TextView from './TextView';
import store from '../../store/Store';
import TableView from './TableView';
import DropdownSelectBox from './DropdownSelectBox';
import CradRender from './CardRender';
import TabView from './TabView';
import TextAreaBox from './TextArea';
import NestedList from './NestedList';
import InputView from './InputView';
import DynamicTextView from './DynamicTextView';
import MultiSelectView from './MultiSelectView';
import SelectView from './SelectView';
import MultiSelectWithTags from './MultiSelectWithTagsView';
import ClearableDiv from './ClearableDiv';
import ExpressionsDivClearable from './ExpressionsDivClearable';
import SearchView from './Search';
import EmptyScreenView from './EmptyScreenView';
import ModalMultiTabView from './multiTabView';
import ListSearchView from './ListSearchView';
import FieldDefaults from './FieldDefault';
import DynamicDropDownView from './DynamicDropdownView';
import MetricsAndSLA from './MetricsAndSLA';
import Filters from '../TenantFilters/TemplateLevelFilter';
import WorkflowLogo from '../../assets/icons/TicketWorkflow.svg';
import JSONFileIcon from '../../assets/icons/Jsonfile.svg';
// import CustomEventAndTriggers from './customEventAndTriggers/CustomEventAndTriggers';
import WorkflowLinkButton from './WorkflowLinkButton';
import ApproveRejectFlow from './ApproveRejectFlow';
import LayoutViewAndTransition from './LayoutViewAndTransition';
import TemplateTab from './TemplatesTab';
import Locales from './Locales';
// import PreDefinedPermissions from './preDefinedPermissions/PreDefinedPermissions';
import Audience from './Audience';
import Dashboard from './Dashboard';
import ChildTemplates from './ChildTemplates';
import ConfigureTemplateViewContainer from '../TicketTemplateBuilder/ConfigureTemplateView';
import CountView from './CountView';
// import Relation from './Relation/Relation';
import Relation from './Relation';
import EmailAndBotMapping from './EmailAndBotMapping/EmailAndBotMapping';
import TicketRouting from '../TicketRouting';
import TemplatePermissionMap from '../TemplatePermissionMap';
import DependentFields from '../DependentsFields';
import TemplateNewEventAction from '../TemplateNewEventAction';
import MapHierarchy from '../MapHierarchy';
import DummyConditionCheck from '../DummyConditionCheck';
import QuickAction from '../QuickAction';
import { WorkflowMain } from '../TicketTemplateBuilder/WorkflowConfigModal/WorkFlowMain';
import SpecialFields from './ SpecialFields';
import MasterFieldDefaults from '../MasterFieldDefaults';
import CardLayouts from '../MasterScreen/CardLayouts';
import LinkLayout from '../MasterScreen/LinkLayout';
import BotUserBinding from '../MasterScreen/BotUserBinding';
import Triggers from '../MasterScreen/TicketTriggers/Triggers';
import BotIdentificationFields from '../MasterScreen/TicketTriggers/BotIdentificationFields';
export const sortByViewLayout = (items, viewLayout) => {
  if (JSON.stringify(viewLayout) === JSON.stringify({})) return items;
  let merged = [];

  for (let i = 0; i < viewLayout?.rows.length; i++) {
    merged.push({
      ...items.find(
        (itmInner) => itmInner?.props?.name === viewLayout?.rows[i].i
      ),
    });
  }
  merged = merged.filter((i) => JSON.stringify(i) !== JSON.stringify({}));
  return merged;
};

const calcWidth = (width, noOfCols) => `${(width / noOfCols) * 100}%`;

const getParticularLayout = (parentViewLayout, val) =>
  parentViewLayout?.rows?.filter((i) => i?.i === val?.name)[0];

export const viewBasedOnType = (
  val,
  childElements,
  parentViewLayout,
  parentName
) => {
  let renderedDiv = {};
  const { allModals } = store.getState();
  switch (val.type) {
    case 'list_short':
      renderedDiv = (
        <ListShort
          name={val?.name}
          columns={val?.columns}
          label={val?.label}
          actions={val?.actions}
          filter={val?.filter}
          entity={val?.entity}
          apiReq={val?.apiReq}
          condition={val?.condition}
          style={{
            width: calcWidth(
              getParticularLayout(parentViewLayout, val)?.w,
              parentViewLayout?.no_of_cols
            ),
          }}
        >
          {sortByViewLayout(childElements.flat(1), val?.view_layout)}
        </ListShort>
      );
      break;
    case 'button':
      renderedDiv = (
        <Actions
          name={val?.name}
          buttonText={val?.label}
          leftIcon={val?.icon}
        />
      );
      break;
    case 'accordion':
      renderedDiv = (
        <AccordionView
          name={val?.name}
          entity={val?.entity}
          filterKey={val?.filterKey}
          count={0}
          accordionTitle={val?.label}
          listKey={val?.listKey}
          condition={val?.condition}
          inputRightElementStyle={val?.inputRightElementStyle}
          searchContainerStyle={val?.searchContainerStyle}
        >
          {sortByViewLayout(childElements.flat(1), val?.view_layout)}
        </AccordionView>
      );
      break;
    case 'container':
      renderedDiv = (
        <ContainerView
          name={val?.name}
          label={val?.label}
          viewLayout={val?.view_layout}
          style={val?.style}
          condition={val?.condition}
          loadInitialData={val?.loadInitialData}
          enpPoint={val?.enpPoint}
          methodType={val?.methodType}
          viewStateName={val?.viewStateName}
          responsePath={val?.responsePath}
          show={val?.show}
          fallback={val?.fallback}
          isBasicSettingFields={val?.isBasicSettingFields}
        >
          {/* {sortByViewLayout(childElements, val?.view_layout)} */}
          {childElements}
        </ContainerView>
      );
      break;

    case 'modal':
      renderedDiv = (
        <ModalView
          name={val?.name}
          modalId={val?.modal_id}
          isOpen={val?.isOpen}
          viewLayout={val?.view_layout}
          modalTitle={val?.modal_title}
          isEdit={val?.isEdit}
          mandatoryFields={val?.mandatoryFields}
          condition={val?.condition}
        >
          {childElements.flat(1)}
        </ModalView>
      );
      store.dispatch(
        updateModalList([...allModals.allModalsList, renderedDiv])
      );
      break;
    case 'modal_multi_tab':
      renderedDiv = (
        <ModalMultiTabView
          name={val?.name}
          modalId={val?.modal_id}
          isOpen={val?.isOpen}
          viewLayout={val?.view_layout}
          modalTitle={val?.modal_title}
          isEdit={val?.isEdit}
          type={val?.type}
          tabIds={val?.tabIds}
          mandatoryFields={val?.mandatoryFields}
        >
          {childElements.flat(1)}
        </ModalMultiTabView>
      );
      store.dispatch(
        updateModalList([...allModals.allModalsList, renderedDiv])
      );
      break;
    case 'list_exp':
      renderedDiv = (
        <TableView
          columns={val?.columns}
          name={val?.name}
          type={val?.type}
          title={val?.title}
          calculatedHight={val?.calculatedHight}
          actions={val?.actions}
          viewLayout={val?.view_layout}
          apiReq={val?.apiReq}
          condition={val?.condition}
          filteredName={val?.filteredName || ''}
        >
          {sortByViewLayout(childElements.flat(1), val?.view_layout)}
        </TableView>
      );
      break;
    case 'card_view':
      renderedDiv = (
        <CradRender
          name={val?.name}
          actions={val?.actions}
          viewLayout={val?.view_layout}
          condition={val?.condition}
        >
          {sortByViewLayout(childElements.flat(1), val?.view_layout)}
        </CradRender>
      );
      break;
    case 'tab_view':
      renderedDiv = (
        <TabView
          name={val?.name}
          actions={val?.actions}
          tabTitle={val?.label}
          condition={val?.condition}
          size="md"
        >
          {childElements.flat(1)}
        </TabView>
      );
      break;
    case 'search':
      renderedDiv = (
        <SearchView
          name={val.name}
          placeholder={val?.label}
          size="sm"
          entity={val?.entity}
          filterKey={val?.filterKey}
          listKey={val?.listKey}
          style={val?.style}
          inputGroupStyle={val?.inputGroupStyle}
        />
      );
      break;
    case 'dropdown_select':
      renderedDiv = (
        <DropdownSelectBox name={val?.name} columns={val?.columns}>
          {sortByViewLayout(childElements.flat(1), val?.view_layout)}
        </DropdownSelectBox>
      );
      break;
    case 'multi_select_tags':
      renderedDiv = (
        <MultiSelectWithTags
          name={val?.name}
          columns={val?.columns}
          viewLayout={val?.view_layout}
          parentName={parentName}
        >
          {sortByViewLayout(childElements.flat(1), val?.view_layout)}
        </MultiSelectWithTags>
      );
      break;
    case 'text':
      renderedDiv = (
        <ElementView name={val?.name} style={val?.style}>
          {val?.name}
        </ElementView>
      );
      break;
    case 'nested_list':
      renderedDiv = (
        <NestedList
          name={val?.name}
          columns={val?.columns}
          label={val?.label}
          actions={val?.actions}
          filter={val?.filter}
          entity={val?.entity}
          style={val?.style}
          apiReq={val?.apiReq}
          childKey={val?.childKey}
        >
          {sortByViewLayout(childElements.flat(1), val?.view_layout)}
        </NestedList>
      );
      break;
    case 'empty_screen':
      renderedDiv = (
        <EmptyScreenView
          name={val?.name}
          icon={val?.icon}
          title={val?.title}
          subTitle={val?.subTitle}
          btnText={val?.btnText}
          targetModalId={val?.target_modal_id}
          condition={val?.condition}
        >
          {' '}
        </EmptyScreenView>
      );
      break;
    case 'field_default_tab':
      renderedDiv = <FieldDefaults label={val?.label} />;
      break;
    case 'metrics_and_sla_tab':
      renderedDiv = <MetricsAndSLA label={val?.label} isBasicSettingFields={val?.isBasicSettingFields} />;
      break;
    case 'work_flow':
      renderedDiv = <WorkflowMain label={val?.label} isBasicSettingFields={val?.isBasicSettingFields} />;
      break;
    case 'event_settings_tab':
      renderedDiv = <TemplateNewEventAction label={val?.label} isBasicSettingFields={val?.isBasicSettingFields} />;
      break;
    case 'runtime_filter_tab':
      renderedDiv = <Filters label={val?.label} isBasicSettingFields={val?.isBasicSettingFields} />;
      break;
    case 'configure_layout_tab':
      renderedDiv = <ConfigureTemplateViewContainer label={val?.label} isBasicSettingFields={val?.isBasicSettingFields} />;
      break;
    case 'layout_for_view_transition_tab':
      renderedDiv = <LayoutViewAndTransition label={val?.label} isBasicSettingFields={val?.isBasicSettingFields} />;
      break;
    case 'templates_tab':
      renderedDiv = <TemplateTab label={val?.label} isBasicSettingFields={val?.isBasicSettingFields} />;
      break;
    case 'locales_tab':
      renderedDiv = <Locales label={val?.label} isBasicSettingFields={val?.isBasicSettingFields} />;
      break;
    case 'permissions_tab':
      renderedDiv = <TemplatePermissionMap label={val?.label} isBasicSettingFields={val?.isBasicSettingFields} />;
      break;
    case 'audience_tab':
      renderedDiv = <Audience label={val?.label} />;
      break;
    case 'relation_tab':
      renderedDiv = <Relation label={val?.label} isBasicSettingFields={val?.isBasicSettingFields} />;
      break;
    case 'mapping_tab':
      renderedDiv = <EmailAndBotMapping label={val?.label} />;
      break;
    case 'dashboard_tab':
      renderedDiv = <Dashboard label={val?.label} isBasicSettingFields={val?.isBasicSettingFields} />;
      break;
    case 'child_templates_tab':
      renderedDiv = <ChildTemplates label={val?.label} show={val?.show} isBasicSettingFields={val?.isBasicSettingFields} />;
      break;
    case 'ticket_routing_tab':
      renderedDiv = <TicketRouting label={val?.label} isBasicSettingFields={val?.isBasicSettingFields} />;
      break;
    case 'dependent_fields_tab':
      renderedDiv = <DependentFields label={val?.label} />;
      break;
    case 'map_hierarchy_tab':
      renderedDiv = <MapHierarchy label={val?.label} isBasicSettingFields={val?.isBasicSettingFields} />;
      break;
    case 'condition_com_tab':
      renderedDiv = <DummyConditionCheck label={val?.label} isBasicSettingFields={val?.isBasicSettingFields} />;
      break;
    case 'quick_action_tab':
      renderedDiv = <QuickAction label={val?.label} isBasicSettingFields={val?.isBasicSettingFields} />;
      break;
    case 'special_fields_tab':
      renderedDiv = <SpecialFields label={val?.label} isBasicSettingFields={val?.isBasicSettingFields} />;
      break;
    case 'offers_tab':
      renderedDiv = <MasterFieldDefaults label={val?.label} isBasicSettingFields={val?.isBasicSettingFields} />;
      break;
          case 'card_layouts_tab':
      renderedDiv= <CardLayouts label={val?.label} isBasicSettingFields={val?.isBasicSettingFields} isBotIntegration={val?.isBotIntegration}/>
      break;
    case 'link_layout_tab':
      renderedDiv= <LinkLayout label={val?.label} isBasicSettingFields={val?.isBasicSettingFields} isBotIntegration={val?.isBotIntegration}/>
      break;
    case 'bot_user_binding_tab':
      renderedDiv= <BotUserBinding label={val?.label} isBasicSettingFields={val?.isBasicSettingFields} isBotIntegration={val?.isBotIntegration}/>
      break;
    case 'triggers_tab':
      renderedDiv= <Triggers label={val?.label} isBasicSettingFields={val?.isBasicSettingFields} isBotIntegration={val?.isBotIntegration} />
      break;
    case 'bot_identification_fields_tab':
      renderedDiv= <BotIdentificationFields label={val?.label} isBasicSettingFields={val?.isBasicSettingFields} isBotIntegration={val?.isBotIntegration}/>
      break;
    default:
      renderedDiv = null;
  }
  return renderedDiv;
};
const iconHandler = (icon) => {
  switch (icon) {
    case 'delete.ico':
      return <DeleteIcon />;
    case 'edit.ico':
      return <EditIcon mr="10px" />;
    case 'copy.ico':
      return <CopyIcon />;
    case 'view.ico':
      return <ViewIcon mr="10px" />;
    case 'add.ico':
      return <AddIcon mr="10px" />;
    case 'workflow.ico':
      return <ReactSVG src={WorkflowLogo} mr="10px" />;
    case 'jsonfile.ico':
      return <ReactSVG src={JSONFileIcon} mr="10px" />;
    case 'editWithOutMargin.ico':
      return <EditIcon />;
    case 'back.ico':
      return <ArrowBackIcon />;
    default:
      return null;
  }
};

export const viewsBasedOnType = (arr, parentName) => {
  const renderedDiv = [];
  for (let i = 0; i < arr?.length; i++) {
    const val = arr[i];
    switch (val.type) {
      case 'list_short':
        renderedDiv.push(
          <ListShort
            name={val?.name}
            columns={val?.columns}
            label={val?.label}
            actions={val?.actions}
            filter={val?.filter}
            entity={val?.entity}
          />
        );
        break;
      case 'count_div':
        renderedDiv.push(<CountView name={val?.name} listKey={val?.listKey} />);
        break;
      case 'button':
        renderedDiv.push(
          <Actions
            name={val?.name}
            buttonText={val?.label}
            leftIcon={iconHandler(val?.icon)}
            targetType={val?.target_type}
            type={val?.type}
            targetModalId={val?.target_modal_id}
            isModalFooter={val?.is_modal_footer}
            payloadKeysMaps={val?.payloadKeysMaps}
            method={val?.method}
            classname={val?.classname}
            style={val?.style}
            condition={val?.condition}
            targetKey={val?.target_key}
            targetObjKey={val?.target_obj_key}
            targetValKey={val?.target_val_key}
            lhs={val?.lhs}
            op={val?.op}
            rhs={val?.rhs}
            targetUrl={val?.target_url}
            listKey={val?.listKey}
            listIdentifier={val?.listIdentifier}
            targetRoute={val?.target_route} // targetRoute
            sourceRoute={val?.source_route} // source
            updatePayloadPath={val?.updatePayloadPath}
            // for api call action button this path is payloafKeyMap will be updated
            // and passed as request
            payloadStateKey={val?.payloadStateKey}
            // for api call action button this prop is used to load data from view state
            targetStatekey={val?.target_state_key}
            styleDark={val?.styleDark}
            tooltip={val?.tooltip}
            disabledCondition={val?.disabledCondition}
            masterType={val?.master_type}
            navigatetUrl={val?.navigatetUrl}
            dataTestId={val?.dataTestId}
          />
        );
        break;
      case 'text':
        renderedDiv.push(
          <TextView
            name={val?.name}
            style={val?.style}
            value={val?.value}
            condition={val?.condition}
          >
            {val?.value}
          </TextView>
        );
        break;
      case 'dynamic_text':
        renderedDiv.push(
          <DynamicTextView
            name={val?.name}
            style={val?.style}
            value={val?.value}
            condition={val?.condition}
          >
            {val?.name}
          </DynamicTextView>
        );
        break;
      case 'input':
        renderedDiv.push(
          <InputView
            name={val?.name}
            type={val?.inputType}
            value={val?.value || ''}
            onChange={() => {}}
            placeholder={val?.placeholder}
            parentName={parentName}
            label={val?.label}
            isMandatory={val?.isMandatory}
            linkedSlugField={val?.linked_slug_field}
            slugPrefix={val?.slug_prefix}
            condition={val?.condition}
          />
        );
        break;
      case 'multi_select':
        renderedDiv.push(
          <MultiSelectView
            name={val?.name}
            value={val?.value || ''}
            placeholder={val?.placeholder || ''}
            parentName={parentName}
            options={val?.options}
            condition={val?.condition}
            label={val.label}
            {...val}
          />
        );
        break;
      case 'textarea':
        renderedDiv.push(
          <TextAreaBox
            name={val?.name}
            placeholder={val?.placeholder}
            visibility={val?.visibility}
            condition={val?.condition}
            value={val?.value || ''}
            onChange={() => {}}
            parentName={parentName}
            label={val?.label}
            linkedSlugField={val?.linked_slug_field}
            {...val}
          />
        );
        break;
      case 'select':
        renderedDiv.push(
          <SelectView
            name={val?.name}
            value={val?.value}
            options={val?.options}
            condition={val?.condition}
            label={val?.label}
            parentName={parentName}
            {...val}
          />
        );
        break;
      case 'dynamic_dropdown':
        renderedDiv.push(
          <DynamicDropDownView
            name={val?.name}
            value={val?.value}
            optionsUrl={val?.optionsUrl}
            condition={val?.condition}
            label={val?.label}
            labelKey={val?.labelKey}
            valueKey={val?.valueKey}
            parentName={parentName}
            {...val}
          />
        );
        break;
      case 'card_view':
        renderedDiv.push(
          <CradRender
            name={val?.name}
            actions={val?.actions}
            elements={val?.elements}
            condition={val?.condition}
          />
        );
        break;
      case 'dropdown_select':
        renderedDiv.push(
          <DropdownSelectBox
            name={val?.name}
            columns={val?.columns}
            classname={val?.classname}
            condition={val?.condition}
          />
        );
        break;
      case 'multi_select_div':
        renderedDiv.push(
          <ClearableDiv
            name={val?.name}
            condition={val?.condition}
            value={val?.value}
            parentName={parentName}
            targetStatekey={val?.target_state_key}
          />
        );
        break;
      case 'expression_div':
        renderedDiv.push(
          <ExpressionsDivClearable
            name={val?.name}
            condition={val?.condition}
            value={val?.value}
            parentName={parentName}
            targetStatekey={val?.target_state_key}
            lhsKey={val?.lhs_key}
            opKey={val?.op_key}
            rhsKey={val?.rhs_key}
          />
        );
        break;
      case 'search':
        renderedDiv.push(
          <SearchView
            name={val.name}
            placeholder={val?.label}
            size="sm"
            entity={val?.entity}
            filterKey={val?.filterKey}
            listKey={val?.listKey}
            style={val?.style}
            condition={val?.condition}
          />
        );
        break;
      case 'list_search':
        renderedDiv.push(
          <ListSearchView
            name={val.name}
            placeholder={val?.placeholder}
            size="sm"
            entity={val?.entity}
            searchKey={val?.searchKey}
            listKey={val?.listKey}
            style={val?.style}
            condition={val?.condition}
            filterKey={val?.filterKey || ''}
          />
        );
        break;
      case 'workflow_linking':
        renderedDiv.push(
          <WorkflowLinkButton
            name={val.name}
            targetModalId={val?.target_modal_id}
            buttonText={val?.label}
            style={val?.style}
            size={val?.size}
          />
        );
        break;
      case 'approve_reject_dropdown':
        renderedDiv.push(
          <ApproveRejectFlow
            name={val.name}
            targetModalId={val?.target_modal_id}
            buttonText={val?.label}
            style={val?.style}
            size={val?.size}
          />
        );
        break;
      default:
        renderedDiv.push(<> Dummy</>);
    }
  }

  return renderedDiv;
};

export const formatStatus = (inputStatus) => {
  const formattedStatus = inputStatus
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return formattedStatus;
};
