// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._CJvq7OYShQbvHEL7JAF{height:auto;width:100%;position:relative;display:flex;justify-content:center;align-items:center;flex-wrap:wrap}.Qd6Dj3jqdXCE8ePDY0fg{color:#fff;font-weight:bold;height:100% !important;width:45px;font-size:25px;text-align:center;background-color:#90ee90;font-family:sans-serif;top:0;z-index:3;position:absolute !important}.OM7xQFcI_4enVZP2oUag{left:0 !important}.QtpADakt2zH9x_CFNcxD{right:0 !important}.uvrc0eA8OOrjpBEEppTO{width:auto;width:-moz-fit-content;width:fit-content;overflow:hidden;white-space:nowrap;text-align:center;display:flex;align-items:center;margin:0 45px}`, "",{"version":3,"sources":["webpack://./src/components/Carousel/carousel.module.scss"],"names":[],"mappings":"AAEA,sBACE,WAAA,CACA,UAAA,CACA,iBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,cAAA,CAGF,sBACE,UAAA,CACA,gBAAA,CACA,sBAAA,CACA,UAhBY,CAiBZ,cAAA,CACA,iBAAA,CACA,wBAAA,CACA,sBAAA,CACA,KAAA,CACA,SAAA,CACA,4BAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,UAAA,CACA,sBAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,aAAA","sourcesContent":["$buttonWidth: 45px;\n\n.carousel {\n  height: auto;\n  width: 100%;\n  position: relative;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: wrap;\n}\n\n.sliderButton {\n  color: white;\n  font-weight: bold;\n  height: 100% !important;\n  width: $buttonWidth;\n  font-size: 25px;\n  text-align: center;\n  background-color: lightgreen;\n  font-family: sans-serif;\n  top: 0;\n  z-index: 3;\n  position: absolute !important;\n}\n\n.switchLeft {\n  left: 0 !important;\n}\n\n.switchRight {\n  right: 0 !important;\n}\n\n.carouselbox {\n  width: auto;\n  width: -moz-fit-content;\n  width: fit-content;\n  overflow: hidden;\n  white-space: nowrap;\n  text-align: center;\n  display: flex;\n  align-items: center;\n  margin: 0 $buttonWidth;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"carousel": `_CJvq7OYShQbvHEL7JAF`,
	"sliderButton": `Qd6Dj3jqdXCE8ePDY0fg`,
	"switchLeft": `OM7xQFcI_4enVZP2oUag`,
	"switchRight": `QtpADakt2zH9x_CFNcxD`,
	"carouselbox": `uvrc0eA8OOrjpBEEppTO`
};
export default ___CSS_LOADER_EXPORT___;
