import axiosInstance from '../../utils/axios/axios';

const getTemplate = (itemTypeId) => new Promise((resolve) => {
  if (!itemTypeId) {
    resolve({ template: null, error: 'Failed to load template data' });
  }

  axiosInstance.get(
    `templates/list?$select=*&$filter=id eq ${itemTypeId}`
  ).then((response) => {
    const template = response?.data?.rows[0];
    if (template) {
      resolve({ template, error: null });
    } else {
      resolve({ template: null, error: 'Failed to load template data' });
    }
  }).catch(() => {
    resolve({ template: null, error: 'Failed to load template data' });
  });
});

const getOffers = (itemTypeId) => new Promise((resolve) => {
  if (!itemTypeId) {
    resolve({ offers: null, error: 'Failed to load offers' });
  }

  axiosInstance.get(
    `fieldDefaults/list?$select=*&$filter=item_type eq '${itemTypeId}'`
  ).then((response) => {
    const offers = response?.data?.rows || [];
    if (offers) {
      resolve({ offers, error: null });
    } else {
      resolve({ offers: null, error: 'Failed to load offers' });
    }
  }).catch(() => {
    resolve({ offers: null, error: 'Failed to load offers' });
  });
});

export {
  getTemplate,
  getOffers,
};
