/* eslint-disable consistent-return */
/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import CustomSelectBox from '../../components/SelectBox/Select';

const MultiSelectView = ({
  name,
  value,
  options,
  parentName,
  label,
  ...rest
}) => {
  const { viewState } = useSelector((state) => state);
  const dispatch = useDispatch();

  return (
    <div data-testid="MultiSelectViewDiv">
      <CustomSelectBox
        {...rest}
        name={name}
        label={label}
        isMulti
        value={get(viewState, `${parentName}.${value}`)}
        options={options}
        onChange={(val) => {
          dispatch(
            updateViewState({
              stateKey: parentName,
              value: { ...viewState[parentName], [value]: val },
            })
          );
        }}
        id="MultiSelectViewBox"
      />
    </div>
  );
};

MultiSelectView.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  parentName: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default MultiSelectView;
