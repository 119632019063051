import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import alltemplateService from './AllTemplateService';

export const getTemplateList = createAsyncThunk(
  'allTemplateList/getAllTemplatesApi',
  async (options, thunkAPI) => {
    try {
      const listData = await alltemplateService.getAllTemplatesApi(options);
      return listData?.allTemplatesList;
    } catch (error) {
      const { message } = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  allTemplatesList: [],
  isLoading: false,
  isError: false,
};

const allTemplates = createSlice({
  name: 'allTemplates',
  initialState,
  reducers: {
    reset: (state) => {
      state.allTemplatesList = [];
      state.isError = false;
      state.error = '';
    },
    resetError: (state) => {
      state.isError = false;
      state.error = '';
    },
    updateAllTemplateList: (state, action) => {
      state.allTemplatesList = action.payload.allTemplatesList;
    },
    addFieldstoTemplate: (state, action) => {
      state.allTemplatesList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTemplateList.pending, (state) => {
        state.isLoading = true;
        state.allTemplatesList = [];
        state.isError = false;
        state.error = '';
      })
      .addCase(getTemplateList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allTemplatesList = action.payload;
      })
      .addCase(getTemplateList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload;
      });
  },
});

export const { reset, resetError, updateAllTemplateList, addFieldstoTemplate } =
  allTemplates.actions;

export default allTemplates.reducer;
