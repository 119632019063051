import React from 'react';
import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { AddIcon } from '@chakra-ui/icons';
import { useDispatch } from 'react-redux';
import NoTemplateIcon from '../../assets/icons/NoTemplateIcon.svg';
import CustomButton from '../../components/Button/SimpleButton';
import { COLORS } from '../../utils/enums/colors';
import { setActiveModal } from '../../redux/ModalReducer/ModalSlice';
import styles from './crudStyle.module.scss';

const EmptyScreenView = ({ icon, title, subTitle, btnText, targetModalId }) => {
  const colorType = useColorModeValue('light', 'dark');
  const dispatch = useDispatch();

  const loadIcon = (iconName) => {
    switch (iconName) {
      case 'noTemplateIcon':
        return NoTemplateIcon;
      default:
        return null;
    }
  };

  return (
    <Box
      bg={useColorModeValue('white', 'gray.900')}
      height="calc(100vh - 80px)"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      display="flex"
      flex="1"
      justifyContent="center"
      data-testid="EmptyScreenViewContainerBox"
    >
      <Flex
        flex="1"
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        justifyContent="center"
        data-testid="EmptyScreenViewContainerFlex"
      >
        <Box
          bg={useColorModeValue('white', 'gray.900')}
          border="1px"
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          width="450px"
          height="330px"
          align="center"
          justifyContent="center"
          mt="-40"
          data-testid="EmptyScreenViewInnerContainerBox"
        >
          <Box
            bg={useColorModeValue('white', 'gray.900')}
            border="1px"
            width="fit-content"
            borderColor="#E2E3E4"
            borderRadius="10px"
            mt="10"
            data-testid="EmptyScreenViewReactSVGBox"
          >
            <ReactSVG
              src={loadIcon(icon)}
              className={`p-2 ${colorType === 'dark' ? styles.colorWhite : ''}`}
            />
          </Box>
          <Box
            className={styles.createHeadText}
            color={useColorModeValue('', 'white')}
            data-testid="EmptyScreenViewTitleBox"
          >
            {title}
          </Box>
          <div className={styles.paraText}>{subTitle}</div>
          <Box pt="6" pb="10" data-testid="EmptyScreenViewParatextBox">
            <CustomButton
              leftIcon={<AddIcon />}
              onClick={() => {
                dispatch(setActiveModal({ targetModalId }));
              }}
              buttonText={btnText || ''}
              variant="solid"
              width="85%"
              size="md"
              className={styles.createTemplateBtn}
              bg={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
              id="EmptyScreenViewAddIconButton"
            />
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

EmptyScreenView.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  targetModalId: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
};

export default EmptyScreenView;
