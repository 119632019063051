import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance, { axiosInstanceWithoutBaseUrl } from '../../utils/axios/axios';
const allTemplateUrl = 'dashboardV2/userinfo';
export const getUserInfoRole = createAsyncThunk(
  'userInfoRole/getUserInfoRole',
  async (options) => {
    const response = await axiosInstanceWithoutBaseUrl.get(allTemplateUrl, { params: options });
    return response.data;
  }
);
const userInfoRoleSlice = createSlice({
  name: 'userInfoRole',
  initialState: {
    data: { metaData: { roles: [] } }, // Adjust according to your API response
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfoRole.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUserInfoRole.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(getUserInfoRole.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});
export default userInfoRoleSlice.reducer;