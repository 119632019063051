import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import workFlowRuleService from './WorkFlowRuleService';

export const addToListRestrictSpecialPermission = createAsyncThunk(
  'workFlowRules/addToListRestrictSpecialPermission',
  async ({ transitionId, value }, thunkAPI) => {
    try {
      const { workFlowRules } = thunkAPI.getState();
      const updateStatus =
        await workFlowRuleService.addToListRestrictSpecialPermission(
          transitionId,
          value,
          workFlowRules.specialPermission
        );
      return updateStatus;
    } catch (error) {
      const { message } = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const addToListRestrictSpecificStatus = createAsyncThunk(
  'workFlowRules/addToListRestrictSpecificStatus',
  async ({ transitionId, value, keyType }, thunkAPI) => {
    try {
      const { workFlowRules } = thunkAPI.getState();
      const updateStatus =
        await workFlowRuleService.addOrUpdateRuleSpecificStatus(
          transitionId,
          value,
          keyType,
          workFlowRules.restrict_specific_status
        );
      return updateStatus;
    } catch (error) {
      const { message } = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const addToListRestrictMoveIssue = createAsyncThunk(
  'workFlowRules/addToListRestrictMoveIssue',
  async ({ transitionId, value, keyType }, thunkAPI) => {
    try {
      const { workFlowRules } = thunkAPI.getState();
      const updateStatus = await workFlowRuleService.addToListRestrictMoveIssue(
        transitionId,
        value,
        keyType,
        workFlowRules.restrict_issue
      );
      return updateStatus;
    } catch (error) {
      const { message } = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const removeFromListRestrictMoveIssue = createAsyncThunk(
  'workFlowRules/removeFromListRestrictMoveIssue',
  async ({ transitionId, value, keyType }, thunkAPI) => {
    try {
      const { workFlowRules } = thunkAPI.getState();
      const updateStatus =
        await workFlowRuleService.removeFromListRestrictMoveIssue(
          transitionId,
          value,
          keyType,
          workFlowRules.restrict_issue
        );
      return updateStatus;
    } catch (error) {
      const { message } = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const addToPermissionExpressionToRestrictMoveIssue = createAsyncThunk(
  'workFlowRules/addToPermissionExpressionToRestrictMoveIssue',
  async ({ transitionId, lhs, op, rhs }, thunkAPI) => {
    try {
      const { workFlowRules } = thunkAPI.getState();
      const updateStatus =
        await workFlowRuleService.addToPermissionExpressionToRestrictMoveIssue(
          transitionId,
          lhs,
          op,
          rhs,
          workFlowRules.restrict_issue
        );
      return updateStatus;
    } catch (error) {
      const { message } = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const removeFromToPermissionExpressionToRestrictMoveIssue =
  createAsyncThunk(
    'workFlowRules/removeFromToPermissionExpressionToRestrictMoveIssue',
    async ({ transitionId, lhs, op, rhs }, thunkAPI) => {
      try {
        const { workFlowRules } = thunkAPI.getState();
        const updateStatus =
          await workFlowRuleService.removeFromToPermissionExpressionToRestrictMoveIssue(
            transitionId,
            lhs,
            op,
            rhs,
            workFlowRules.restrict_issue
          );
        return updateStatus;
      } catch (error) {
        const { message } = error.response.data;
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
export const updatePermissionExpressionCondition = createAsyncThunk(
  'workFlowRules/updatePermissionExpressionCondition',
  async ({ transitionId, value }, thunkAPI) => {
    try {
      const { workFlowRules } = thunkAPI.getState();
      const updateStatus =
        await workFlowRuleService.updatePermissionExpressionCondition(
          transitionId,
          value,
          workFlowRules.restrict_issue
        );
      return updateStatus;
    } catch (error) {
      const { message } = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateSpecificValue = createAsyncThunk(
  'workFlowRules/updateSpecificValue',
  async ({ transitionId, fieldKey, fieldValue, keyValue, value }, thunkAPI) => {
    try {
      const { workFlowRules } = thunkAPI.getState();
      const updateStatus = await workFlowRuleService.updateSpecificValue(
        transitionId,
        fieldKey,
        fieldValue,
        keyValue,
        value,
        workFlowRules.specificValue
      );
      return updateStatus;
    } catch (error) {
      const { message } = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
const initialState = {
  restrict_issue: [
    {
      transition_id: 'reactflow__edge-start_2source_2-start_3target_1',
      list_restrict_type: {
        org: ['badrul'],
        teams: ['team1'],
        queues: [],
        users: ['333'],
        groups: ['111111'],
        actors: ['444'],
        roles: ['555'],
      },
      permissionExpression: {
        condition: 'must be one',
        list: [
          {
            lhs: 'Haq',
            op: '===',
            rhs: ' SK',
          },
          {
            lhs: 'hello',
            op: '===',
            rhs: 'Khan',
          },
        ],
      },
    },
  ],
  restrict_specific_status: [
    {
      transition_id: 'reactflow__edge-start_2source_2-start_3target_1',
      status_id: 'start_3',
      include_status: true,
      reverse_rule: false,
    },
  ],
  specialPermission: [],
  specificValue: [],
  ruleModalId: '',
  isLoading: false,
  isError: false,
  error: '',
};

const workFlowRules = createSlice({
  name: 'workFlowRules',
  initialState,
  reducers: {
    reset: (state) => {
      state.restrict_issue = [];
      state.restrict_specific_status = [];
      state.ruleModalId = '';
      state.isError = false;
      state.error = '';
    },
    resetError: (state) => {
      state.isError = false;
      state.error = '';
    },
    updateRuleModalId: (state, action) => {
      state.ruleModalId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addToListRestrictMoveIssue.fulfilled, (state, action) => {
        state.isLoading = false;
        state.restrict_issue = action.payload;
      })
      .addCase(removeFromListRestrictMoveIssue.fulfilled, (state, action) => {
        state.isLoading = false;
        state.restrict_issue = action.payload;
      })
      .addCase(
        addToPermissionExpressionToRestrictMoveIssue.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.restrict_issue = action.payload;
        }
      )
      .addCase(
        addToListRestrictSpecialPermission.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.specialPermission = action.payload;
        }
      )
      .addCase(
        removeFromToPermissionExpressionToRestrictMoveIssue.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.restrict_issue = action.payload;
        }
      )
      .addCase(
        updatePermissionExpressionCondition.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.restrict_issue = action.payload;
        }
      )
      .addCase(updateSpecificValue.fulfilled, (state, action) => {
        state.isLoading = false;
        state.specificValue = action.payload;
      })
      .addCase(addToListRestrictSpecificStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.restrict_specific_status = action.payload;
      })
      .addCase(addToListRestrictSpecificStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload;
      });
  },
});

export const { reset, resetError, updateRuleModalId } = workFlowRules.actions;
export default workFlowRules.reducer;
