import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomModal from '../../../components/Modal/Modal';
import CustomButton from '../../../components/Button/SimpleButton';
import { LABELS } from '../../../utils/enums/labels';
import { COLORS } from '../../../utils/enums/colors';
import { setActiveModal } from '../../../redux/ModalReducer/ModalSlice';
import WorkFlowConfigContain from './WorkFlowConfigContain';
import {
  makeApiCallForField,
  makeApiCallForWorkflowFromTemplate,
  makeApiCallForWorkflowList,
  reducerForState,
} from '../AddEditActorModal/helper';
import { isEmtpty } from '../utils/helper';
import { dummyDefaultRole } from '../../TicketTemplateWorkflow/DummyData';
import { checkForV2 } from '../../../utils/helper';
import { FieldTypes } from '../../../utils/enums/types';
import { patchDataForTemplate } from '../utils/templateAPIUtils';
import { DROPDOWN_TYPES, SPECIAL_FIELD_TYPE } from '../../TenantFilters/filters.constants';

const WorkflowConfigModal = ({
  isConfigModalOpen,
  setIsConfigModalOpen,
  templateName,
}) => {
  const [workflowState, dispatchWorkflow] = useReducer(reducerForState, {});
  const [workflowEditState, dispatchWorkflowEdit] = useReducer(
    reducerForState,
    {}
  );
  const [isLoading, setIsLoading] = useState(true);
  const [workflowMasterListOptions, setworkflowMasterListOptions] = useState(
    []
  );
  const [linkFieldOptions, setLinkFieldOptions] = useState([]);
  const dispatch = useDispatch();
  const { id } = useParams();
  const fetchWorkFlowFromTemplate = async () => {
    const response = await makeApiCallForWorkflowFromTemplate(id);
    if (response?.workflow) {
      const newObj = {
        name: response?.workflow?.name,
        workflow_rules: response?.workflow?.workflow_rules,
        workflow_status: response?.workflow?.workflow_status,
        workflow_category: response?.workflow?.workflow_category,
        workflow_transitions: response?.workflow?.workflow_transitions,
        workflow_field_liked_id: response?.workflow?.workflow_field_liked_id,
      };
      dispatchWorkflowEdit({ type: 'SET_STATE', value: newObj });
    } else {
      dispatchWorkflow({ type: 'SET_STATE', value: {} });
    }
    setIsLoading(false);
  };
  const fetchWorkflowListOption = async () => {
    const response = await makeApiCallForWorkflowList();
    const options = response?.map((item) => ({
      value: item.id,
      label: item.name,
      workflow_status: item.workflow_status,
      workflow_category: item.workflow_category,
      workflow_transitions: item.workflow_transitions,
    }));
    setworkflowMasterListOptions(options);
  };
  const fetchDataForFieldOption = async () => {
    const result = await makeApiCallForField(id);
    const option = result?.fields
      ?.filter((item) =>
        checkForV2()
          ? item?.type_based_attributes?.collection?.collection_type ===
          DROPDOWN_TYPES.SPECIAL_FIELD &&
            item?.type_based_attributes?.collection?.specialField ===
            SPECIAL_FIELD_TYPE.WORKFLOW
          : item.type === FieldTypes.DROPDOWN
      )
      ?.map((item) => ({
        value: item.id,
        label: item.label,
      }));
    setLinkFieldOptions(option);
  };
  useEffect(() => {
    fetchWorkFlowFromTemplate();
    fetchWorkflowListOption();
    fetchDataForFieldOption();
  }, []);

  const configWorkflowInTemplate = async () => {
    delete workflowState.id;
    const newData = {
      ...workflowState,
      workflow_rules: { list: [...dummyDefaultRole] },
      updated_on: new Date().toISOString(),
    };
    await patchDataForTemplate({
      data: newData,
      id,
      key: 'workflow',
      successMessage: 'Workflow Configuration Successful',
      defaultErrorMessage: 'Failed to configure workflow',
    });

    setIsConfigModalOpen(false);
    dispatch(setActiveModal({ targetModalId: undefined }));
  };
  let modalContent;
  // if (isLoading) {
  //   modalContent = (
  //     <Flex justify="center" align="center" height="100px">
  //       <CircularProgress isIndeterminate />
  //     </Flex>
  //   );
  // } else
  if (isEmtpty(workflowEditState)) {
    modalContent = (
      <WorkFlowConfigContain
        workflowState={workflowState}
        dispatchWorkflow={dispatchWorkflow}
        workflowMasterListOptions={workflowMasterListOptions}
        linkFieldOptions={linkFieldOptions}
      />
    );
  } else {
    modalContent = (
      // <WorkflowConfigEditContain
      //   workflowState={workflowEditState}
      //   dispatchWorkflow={dispatchWorkflowEdit}
      //   linkFieldOptions={linkFieldOptions}
      // />
      <WorkFlowConfigContain
        workflowState={workflowState}
        dispatchWorkflow={dispatchWorkflow}
        workflowMasterListOptions={workflowMasterListOptions}
        linkFieldOptions={linkFieldOptions}
      />
    );
  }
  return (
    <CustomModal
      id="selected_workflow_add_edit-modal"
      modalTitle={
        <>
          <h1>Workflow Configuration</h1>
          <Box className="text-base text-[#868e96]">{templateName}</Box>
        </>
      }
      modalContent={modalContent}
      modalFooter={
        <Flex gap="8px" pt="60px" data-testid="WorkConfigModalContainerFlex">
          <CustomButton
            variant="outline"
            pl="25px"
            pr="25px"
            buttonColor="#868E96"
            buttonText={LABELS.ACTIONS.CANCEL}
            style={{ border: 'none' }}
            _hover={{ color: useColorModeValue(COLORS.BLACK, 'white') }}
            onClick={() => {
              setIsConfigModalOpen(false);
              dispatch(setActiveModal({ targetModalId: undefined }));
            }}
            size="lg"
            id="WorkConfigModalCancelBtn"
          />
          <CustomButton
            variant="solid"
            pl="25px"
            pr="25px"
            buttonColor="customBlue"
            isDisabled={!workflowState?.name}
            buttonText="Connect Workflow"
            onClick={async () => {
              await configWorkflowInTemplate();
              window.location.reload();
            }}
            size="lg"
            id="WorkConfigModalConnectBtn"
          />
        </Flex>
      }
      open={isConfigModalOpen}
      setOpen={() => {
        setIsConfigModalOpen(!isConfigModalOpen);
        dispatch(setActiveModal({ targetModalId: undefined }));
      }}
    />
  );
};
WorkflowConfigModal.propTypes = {
  isConfigModalOpen: PropTypes.bool.isRequired,
  setIsConfigModalOpen: PropTypes.func.isRequired,
  templateName: PropTypes.string.isRequired,
};
export default WorkflowConfigModal;
