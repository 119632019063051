/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import styles from '../AddNewRoleModal/rolecontent.module.scss';
import {
  LABELS,
  ROLE_DESCRIPTIONS,
  ROLE_TITLE,
} from '../../../utils/enums/labels';
import CustomModalFooter from '../CreateEditFlow/CustomModalFooter';
import RoleOne from '../../../assets/icons/Role_1.svg';
import RestrictMoveRoleContainer from './RestrictMoveRoleContainer';
import { updateRuleModalId } from '../../../redux/WorkFlowRuleSlice/WorkFlowRuleReducer';
import {
  // addRuleToSelectedWorkFlow,
  addRuleToSelectedWorkFlowSate,
  changeSelectedRuleWithKey,
  initSelectedRule,
  removeRuleFromSelectedWorkFlowState,
  updateRuleToSelectedWorkFlowSate,
  updateStateOfWorkFlowWithKey,
} from '../../../redux/WorkFlowMainSlice/WorkFlowMainReducer';
import CustomModalFooterWithDelete from '../../../components/CustomModalFooterWithDelete';
import RuleDeleteModal from '../ShowEditEdge/RuleDeleteModal';
import { RULE_BASIC_VALIDATIONS } from '../../../utils/validationSchemas/workflow/rule';
import { validationErrorMessage } from '../../../utils/validationSchemas/validationSchemas';
import { APPROVAL_TYPES } from '../../../utils/enums/types';
import { CustomDrawer } from '../../TicketTemplateBuilder/ConfigureTemplateView/AddEditTemplateLayoutModal';

const getRuleDataForValidation = (selectedWorkFlowRule) => {
  // We are converting data from a string to a number so that we can use JOI validation
  const data = {
    ...selectedWorkFlowRule,
  };

  delete data.approvals;

  if (data.approvalsType === APPROVAL_TYPES.ALL_APPROVALS) {
    data.approvals = 1;
  }

  if (
    data.approvalsType === APPROVAL_TYPES.MIN_APPROVAL_COUNT &&
    !Number.isNaN(selectedWorkFlowRule.approvals)
  ) {
    data.approvals = parseInt(selectedWorkFlowRule.approvals, 10);
  }

  return data;
};

const RestricMoveRoleModal = ({
  isRestricMoveRole,
  setIsRestricMoveRole,
  edit,
}) => {
  const dispatch = useDispatch();
  const { selectedWorkFlowRule } = useSelector((state) => state.workFlowMain);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [errorData, setErrorData] = useState({});
  return (
    <>
      <CustomDrawer
        id="selected-field-modal"
        size="xl"
        drawerBody={
          <>
            <Flex pb="4" data-testid="RestricMoveRoleModalBoxFlex">
              <Box
                className={styles.boxSvg}
                bgColor="#FFF7EA"
                data-testid="RestricMoveRoleModalReactSvgBox"
              >
                <ReactSVG
                  src={`${RoleOne}`}
                  alt="image"
                  data-testid="RestricMoveRoleModalRoleReacrSvg"
                />
              </Box>

              <Box
                className={styles.cardbody}
                data-testid="RestricMoveRoleModalTitleBox"
              >
                <div
                  style={{ marginLeft: '20px' }}
                  data-testid="RestricMoveRoleModalTitleDiv"
                >
                  <Heading
                    className={styles.cardheading}
                    data-testid="RestricMoveRoleModalHeading"
                  >
                    {ROLE_TITLE.MOVE_ISSUE}
                  </Heading>
                  <Text
                    className={styles.carddesc}
                    data-testid="RestricMoveRoleModalDescText"
                  >
                    {ROLE_DESCRIPTIONS.MOVE_ISSUE}
                  </Text>
                </div>
              </Box>
            </Flex>
            <RestrictMoveRoleContainer
              errorData={errorData}
              setErrorData={setErrorData}
            />
          </>
        }
        drawerFooter={
          edit ? (
            <CustomModalFooterWithDelete
              btnLabel="Update Rule"
              pt="10px"
              // isDisabled={!selectedWorkFlowRule.transitionId}
              onCancelClick={() => {
                dispatch(
                  updateStateOfWorkFlowWithKey({
                    keyValue: 'selectedWorkFlowRule',
                    value: initSelectedRule,
                  })
                );
                setErrorData({});
                setIsRestricMoveRole(false);
              }}
              onAddClick={() => {
                const { error } = RULE_BASIC_VALIDATIONS.validate(
                  getRuleDataForValidation(selectedWorkFlowRule),
                  { abortEarly: false }
                );
                if (error) {
                  const errors = validationErrorMessage(error);
                  setErrorData({ ...errors });
                  return;
                }
                dispatch(updateRuleToSelectedWorkFlowSate({ dispatch }));
                setIsRestricMoveRole(false);
              }}
              onDeleteCllick={() => {
                setIsRestricMoveRole(false);
                setIsDeleteModal(true);
              }}
            />
          ) : (
            <CustomModalFooter
              btnLabel="Add Rule"
              pt="10px"
              // isDisabled={!selectedWorkFlowRule.transitionId}
              onCancelClick={() => {
                dispatch(updateRuleModalId(''));
                dispatch(
                  updateStateOfWorkFlowWithKey({
                    keyValue: 'selectedWorkFlowRule',
                    value: initSelectedRule,
                  })
                );
                setErrorData({});
                setIsRestricMoveRole(false);
              }}
              onAddClick={() => {
                dispatch(
                  changeSelectedRuleWithKey({
                    keyValue: 'id',
                    value: uuidv4(),
                  })
                );
                const { error } = RULE_BASIC_VALIDATIONS.validate(
                  getRuleDataForValidation(selectedWorkFlowRule),
                  { abortEarly: false }
                );
                if (error) {
                  const errors = validationErrorMessage(error);
                  setErrorData({ ...errors });
                } else {
                  dispatch(addRuleToSelectedWorkFlowSate({ dispatch }));
                  setIsRestricMoveRole(false);
                  dispatch(updateRuleModalId(''));
                }
              }}
            />
          )
        }
        modalTitle={edit ? LABELS.ACTIONS.EDIT_RULE : LABELS.ACTIONS.ADD_RULE}
        openDrawer={isRestricMoveRole}
        setOpenDrawer={() => {
          setIsRestricMoveRole(!isRestricMoveRole);
          dispatch(updateRuleModalId(''));
          dispatch(
            updateStateOfWorkFlowWithKey({
              keyValue: 'selectedWorkFlowRule',
              value: initSelectedRule,
            })
          );
          setErrorData({});
        }}
      />
      <RuleDeleteModal
        isDeleteModal={isDeleteModal}
        setIsDeleteModal={setIsDeleteModal}
        onConfirmation={() => {
          dispatch(removeRuleFromSelectedWorkFlowState({ dispatch }));
          setIsDeleteModal(false);
        }}
        onDeleteCancelClick={() => {
          setIsDeleteModal(false);
          setIsRestricMoveRole(true);
        }}
        title={LABELS.TITLE.DELETE_RULE_WARNING}
        subTitle={ROLE_TITLE.MOVE_ISSUE}
      />
    </>
  );
};
RestricMoveRoleModal.propTypes = {
  setIsRestricMoveRole: PropTypes.func.isRequired,
  isRestricMoveRole: PropTypes.bool.isRequired,
  edit: PropTypes.bool,
};
RestricMoveRoleModal.defaultProps = {
  edit: false,
};
export default RestricMoveRoleModal;
