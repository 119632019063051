/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';
import { useColorModeValue } from '@chakra-ui/react';
import customStyleFromRuleForSelect from '../../context/CustomTheme/CustomStyleFromRuleForSelect';

const CustomCreateSelectBox = ({
  options,
  id,
  isMulti,
  value,
  label,
  onChange,
  isError,
  errorMessage,
  onCreateOption,
  onInputChange,
  ...props
}) => {
  const ref = useRef(null);
  const parentContainer = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const findScrollParent = useCallback((element) => {
    if (!element) return null;

    const style = window.getComputedStyle(element);
    const overflowRegex = /(auto|scroll)/;

    if (
      overflowRegex.test(style.overflow) ||
      overflowRegex.test(style.overflowX) ||
      overflowRegex.test(style.overflowY)
    ) {
      return element;
    }

    return findScrollParent(element.parentElement);
  }, []);

  useEffect(() => {
    if (!ref.current || !menuOpen) {
      return;
    }

    let scrollElem = parentContainer.current;
    if (!scrollElem) {
      scrollElem = findScrollParent(ref.current);
      parentContainer.current = scrollElem;
    }
    const updateBoundingRect = () => {
      setMenuOpen(false);
    };

    scrollElem?.addEventListener('scroll', updateBoundingRect);

    // eslint-disable-next-line consistent-return
    return () => scrollElem?.removeEventListener('scroll', updateBoundingRect);
  }, [findScrollParent, menuOpen]);

  return (
    <div id={label} data-testid={id} ref={ref}>
      <div style={label ? { marginBottom: '.5rem' } : {}}>
        <label className="SCLabel">{label}</label>
        {props?.isMandatory && (
          <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
        )}
      </div>
      <CreatableSelect
        onInputChange={onInputChange}
        onCreateOption={onCreateOption}
        options={options}
        id="badCreate"
        onChange={onChange}
        value={value}
        isMulti={isMulti}
        styles={customStyleFromRuleForSelect}
        className={
          isError && errorMessage
            ? 'error-border-react-select'
            : useColorModeValue('', 'react-select-dark')
        }
        menuIsOpen={menuOpen}
        onMenuOpen={() => setMenuOpen(true)}
        onMenuClose={() => setMenuOpen(false)}
        {...props}
      />
      {isError && errorMessage && (
        <span className="field-error">{errorMessage}</span>
      )}
    </div>
  );
};

CustomCreateSelectBox.propTypes = {
  options: PropTypes.array,
  id: PropTypes.string,
  isMulti: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  isMandatory: PropTypes.bool,
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  onCreateOption: PropTypes.func,
  onInputChange: PropTypes.func,
};

CustomCreateSelectBox.defaultProps = {
  options: [],
  isMulti: false,
  id: '',
  label: '',
  value: undefined,
  isMandatory: false,
  isError: false,
  errorMessage: '',
  onCreateOption: () => {},
  onInputChange: () => {},
};

export default CustomCreateSelectBox;
