/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable-next-line no-lone-blocks */
import React from 'react';
import { useColorModeValue } from '@chakra-ui/color-mode';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _set from 'lodash/set';
import _get from 'lodash/get';
import _remove from 'lodash/remove';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';

import CustomInput from '../InputBox/Input';
import CustomSelect from '../SelectBox/Select';
import { DARK, LIGHT } from '../../utils/enums/colors';
// import CustomDatepicker from '../Datepicker/DatePicker';
import CustomDatepicker from '../Datepicker/AntdDatePicker';
import styles from './dynamicExpressionWithTags.module.scss';

const CustomField = ({ value, type, onChange, options }) => {
  const renderElement = () => {
    let el = null;
    switch (type) {
      case 'input':
        el = (
          <CustomInput
            type="text"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className={styles.inputBox}
            id="DynamicExpressionWithTagCInput"
          />
        );
        break;
      case 'input-disabled':
        el = (
          <CustomInput
            type="text"
            value={value}
            className={styles.inputBox}
            onChange={(e) => onChange(e.target.value)}
            isDisabled
            id="DynamicExpressionWithTag1CInput"
          />
        );
        break;
      case 'input-number':
        el = (
          <CustomInput
            type="number"
            className={styles.inputBox}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            id="DynamicExpressionWithTag2CInput"
          />
        );
        break;
      case 'select':
        el = (
          <CustomSelect
            options={options}
            value={value}
            onChange={(val) => onChange(val)}
            focusBorderColor={useColorModeValue(LIGHT, DARK)}
            id="DynamicExpressionWithTagCSelect"
          />
        );
        break;
      case 'multi-select':
        el = (
          <CustomSelect
            options={options}
            value={value}
            onChange={(val) => onChange(val)}
            isMulti
            focusBorderColor={useColorModeValue(LIGHT, DARK)}
            id="DynamicExpressionWith2TagCSelect"
          />
        );
        break;
      case 'date-picker':
        el = (
          <CustomDatepicker
            dateFormat="DD/MM/YYYY"
            selectedDate={value}
            onChange={(e) => {
              if (e) {
                onChange(e);
              } else {
                onChange('');
              }
            }}
          />
        );
        break;
      default:
    }
    return el;
  };
  return <>{renderElement()}</>;
};

CustomField.propTypes = {
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
};
export default CustomField;
