/* eslint-disable react/prop-types */
import { Box, Flex } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updateAllCompositeList } from '../../../redux/TicketTemplate/AllCompositeReducer/AllCompositeSlice';
import CustomButton from '../../../components/Button/SimpleButton';
import CustomModal from '../../../components/Modal/Modal';
import CreateFieldContent from './CompositeFieldContent';
import { TemplateTypes } from '../../../utils/enums/types';
import { LABELS } from '../../../utils/enums/labels';
import { addCompositeApi, editCompositeApi } from '../utils/helper';
import { workflowId } from '../../../utils/enums/axiosConstant';

const CreateCompositeModal = ({
  type,
  templateName,
  // data = {},
  edit = false,
  isTemplatedModel,
  setIsTemplatedModel,
}) => {
  const { id = '' } = useParams();
  const { allCompositeList = [] } = useSelector((state) => state.allComposite);
  const [selectedTicket, setSelectedTicket] = useState({});
  const [fields, setFields] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function updateObjectById(ids, arr) {
    return arr.map((obj) => {
      if (obj.id === ids) {
        // updates selected object
        setSelectedTicket(obj);
        return obj;
      }
      // if no template found
      // setSelectedTicket(null);
      return obj;
    });
  }

  useEffect(() => {
    if (id && edit) {
      updateObjectById(id, allCompositeList);
    }
  }, [id, allCompositeList]);

  useEffect(() => {
    if (edit) {
      setFields(selectedTicket);
    }
  }, [selectedTicket]);

  const addTemplate = async () => {
    let subItemsArr = [];
    if (fields?.templateType === TemplateTypes.NESTED) {
      subItemsArr = fields?.selectedTemplates?.map((i) => ({
        id: Math.random(),
        name: `${i?.value?.name} - ${fields?.name}`,
      }));
    }

    const datas = {
      name: fields?.name,
      children: subItemsArr,
      type: 'COMPOSITE_FIELDS',
      description: fields?.description,
      version: 'v1',
      workflow_id: workflowId,
      status: 'DRAFT',
      parent_id: null,
      previous_version_id: null,
      cloned_from: null,
      is_default: false,
      group_id: null,
      collabrators: null,
      is_marked_favourite: false,
    };

    let response = {};

    if (edit) {
      response = await editCompositeApi(datas, id);
    } else {
      response = await addCompositeApi(datas);
    }
    const editedList = allCompositeList.map((i) => {
      if (i.id === id) {
        return { ...i, ...datas };
      }
      return i;
    });

    dispatch(
      updateAllCompositeList({
        allCompositeList: edit ? editedList : [response, ...allCompositeList],
      })
    );
    navigate(
      `/template-builder/composite/${edit ? selectedTicket?.id : response.id}`
    );

    setIsTemplatedModel(false);
  };

  return (
    <CustomModal
      id="selected-field-modal"
      modalTitle={<h1>{templateName}</h1>}
      modalContent={
        <Box mt="7" data-testid="CreateCompositeModalBox">
          <CreateFieldContent
            type={type}
            fields={fields}
            setFields={setFields}
          />
        </Box>
      }
      modalFooter={
        <Flex gap="8px" data-testid="CreateCompositeModalFlex">
          <CustomButton
            variant="outline"
            buttonColor="customBlue"
            size="lg"
            buttonText={LABELS.ACTIONS.CANCEL}
            onClick={() => setIsTemplatedModel(false)}
            id="CreateCompositeModalCancelCBtn"
          />
          <CustomButton
            variant="solid"
            isDisabled={!fields?.name}
            buttonColor="customBlue"
            size="lg"
            buttonText={LABELS.ACTIONS.CONTINUE}
            onClick={() => {
              addTemplate();
            }}
            id="CreateCompositeModalContinueCBtn"
          />
        </Flex>
      }
      open={isTemplatedModel}
      setOpen={setIsTemplatedModel}
    />
  );
};

export default CreateCompositeModal;
