/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import CustomButton from '../../components/Button/SimpleButton';
import { COLORS } from '../../utils/enums/colors';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';

const MultiSelectWithTags = ({ name, parentName, viewLayout, children }) => {
  const { viewState } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [detailsItems, setDetailstems] = useState([]);
  const sortByViewLayout = (items, viewLayout) => {
    if (JSON.stringify(viewLayout) === JSON.stringify({})) return items;
    let merged = [];
    for (let i = 0; i < viewLayout?.rows?.length; i++) {
      merged.push({
        ...viewLayout?.rows[i],
        ...items.find(
          (itmInner) => itmInner?.props?.name === viewLayout?.rows[i].i
        ),
      });
    }
    merged = merged.filter((i) => JSON.stringify(i) !== JSON.stringify({}));
    return merged;
  };

  useEffect(() => {
    setDetailstems(sortByViewLayout(children, viewLayout));
  }, [viewState[parentName]]);

  const updateExpressionObj = (val) => {
    const targetParent = val?.targetStatekey ?? parentName;
    const parentState = viewState[targetParent] ?? {};
    const fieldData = viewState[name] ?? {};
    const targetRootKey = val?.targetKey;
    const targetLhsKey = val?.lhs;
    const targetOpkey = val?.op;
    const targetRhsKey = val?.rhs;
    if (
      !fieldData[targetLhsKey] ||
      !fieldData[targetOpkey] ||
      !fieldData[targetRhsKey]
    ) {
      return;
    }
    const rootObjList = parentState[targetRootKey] ?? [];
    const updatedRootObjList = [
      ...rootObjList,
      {
        id: Math.random(),
        [targetLhsKey]: fieldData[targetLhsKey],
        [targetOpkey]: fieldData[targetOpkey],
        [targetRhsKey]: fieldData[targetRhsKey],
      },
    ];
    const updatedState = {
      ...parentState,
      [targetRootKey]: updatedRootObjList,
    };
    dispatch(
      updateViewState({
        stateKey: targetParent,
        value: updatedState,
      })
    );
    dispatch(
      updateViewState({
        stateKey: name,
        value: undefined,
      })
    );
  };

  const updateMultiSelectObj = (val) => {
    const targetParent = val?.targetStatekey ?? parentName;
    const parentState = viewState[targetParent] ?? {};
    const fieldData = viewState[name] ?? {};
    const targetRootKey = val?.targetKey;
    const targetObjkey = val?.targetObjKey;
    const targetValKey = val?.targetValKey;
    if (!fieldData[targetObjkey] || !fieldData[targetValKey]) {
      return;
    }
    const rootObj = parentState[targetRootKey] ?? {};
    const updatedRootObj = {
      ...rootObj,
      [fieldData[targetObjkey]]: [
        ...(rootObj[fieldData[targetObjkey]] ?? []),
        fieldData[targetValKey],
      ],
    };
    const updatedState = {
      ...parentState,
      [targetRootKey]: { ...updatedRootObj },
    };
    dispatch(
      updateViewState({
        stateKey: targetParent,
        value: updatedState,
      })
    );
    dispatch(
      updateViewState({
        stateKey: name,
        value: undefined,
      })
    );
  };

  const renderButton = (val) => (
    <CustomButton
      width="100%"
      onClick={() =>
        val?.targetKey === 'expression'
          ? updateExpressionObj(val)
          : updateMultiSelectObj(val)
      }
      buttonText={val?.buttonText}
      color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
      borderColor={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
      variant="outline"
      mb="4"
      size={val?.size || 'sm'}
      style={val?.style}
      id="MultiSelectWithTagsViewTextBtn"
    />
  );

  return (
    <div data-testid="MultiSelectWithTagsDiv">
      <Grid
        templateColumns={`repeat(${viewLayout?.no_of_cols}, 1fr)`}
        gap={2.5}
        data-testid="MultiSelectWithTagsGrid"
      >
        {detailsItems?.map((i) => (
          <GridItem
            key={i.id}
            colSpan={i.w}
            data-testid="MultiSelectWithTagsGridItem"
          >
            {i.props.type === 'button' ? renderButton(i.props) : i}
          </GridItem>
        ))}
      </Grid>
    </div>
  );
};

MultiSelectWithTags.propTypes = {
  name: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
  viewLayout: PropTypes.object.isRequired,
};

export default MultiSelectWithTags;
