// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zbVx5OIB66WS2mmlv1t0{display:flex;align-items:center;gap:1rem}.iRDA83VOm4u669FI3pjw{border-radius:50% !important;min-width:1.5rem !important;height:1.5rem !important}.JE4FApgk_YjUN0sYd4Fq{margin-right:10px !important}.jrO0m_V3TfGtn17pSmdJ .custom__tablist{width:-moz-fit-content;width:fit-content}.jrO0m_V3TfGtn17pSmdJ .custom__tab{height:40px;font-size:14px;padding:4px 16px}`, "",{"version":3,"sources":["webpack://./src/pages/TenantFilters/filters.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,QAAA,CAGF,sBACE,4BAAA,CACA,2BAAA,CACA,wBAAA,CAGF,sBACE,4BAAA,CAGF,uCACM,sBAAA,CACA,iBAAA,CAGN,mCACM,WAAA,CACA,cAAA,CACA,gBAAA","sourcesContent":[".drawer__header {\n  display: flex;\n  align-items: center;\n  gap: 1rem;\n}\n\n.drawer__close__button {\n  border-radius: 50% !important;\n  min-width: 1.5rem !important;\n  height: 1.5rem !important;\n}\n\n.drawer__footer__close__button {\n  margin-right: 10px !important;\n}\n\n.filters__tab :global .custom__tablist {\n      width: -moz-fit-content;\n      width: fit-content;\n    }\n\n.filters__tab :global .custom__tab {\n      height: 40px;\n      font-size: 14px;\n      padding: 4px 16px;\n    }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drawer__header": `zbVx5OIB66WS2mmlv1t0`,
	"drawer__close__button": `iRDA83VOm4u669FI3pjw`,
	"drawer__footer__close__button": `JE4FApgk_YjUN0sYd4Fq`,
	"filters__tab": `jrO0m_V3TfGtn17pSmdJ`
};
export default ___CSS_LOADER_EXPORT___;
