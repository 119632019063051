const StatusTypes = {
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
};

const LifecycleStatusTypes = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  ARCHIVED: 'ARCHIVED',
  REJECTED: 'DRAFT',
  // REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
  PENDING_REVIEW: 'PENDING_REVIEW',
};

export { StatusTypes, LifecycleStatusTypes };
