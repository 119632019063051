import React, { useState, useEffect } from 'react';
import { Input, Box, InputGroup, InputRightElement, Button, Spinner, Text, Select, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody,Portal } from '@chakra-ui/react';
import { IoSearch } from 'react-icons/io5';
import { AddIcon } from '@chakra-ui/icons';
import { BsInfoCircle } from 'react-icons/bs';
import axiosInstance from '../../../../utils/axios/axios';
import { showToastMessage } from '../../../TicketTemplateBuilder/utils/templateAPIUtils';
import CustomTable from '../../../DynamicRenderer/customTable';
import './botIdentificationFields.scss';

const FieldsTableViewWithSearch = ({ columns, tableData, getFields, descriptionData, selectedTemplate, isFetching, setDescriptionData, dropdownFields, deletedFields, setDeletedFields, setDropdownFields }) => {
  const [filter, setFilter] = useState(tableData);
  const [fieldValue, setFieldValue] = useState('');
  const [dropdownValue, setDropdownValue] = useState('');
  const [descriptionPopover, setDescriptionPopover] = useState(false);
  const FIELDS_TOAST = {
    SUCCESS: 'Fields Updated Successfully',
    ERROR: 'Fields Not Updated'
  };
  useEffect(() => {
    setFilter(tableData);
  }, [tableData]);
  const handleChange = (e) => {
    const inputValue = e.target.value;
    setFieldValue(e.target.value);
    const filteredData = tableData.filter((item) =>
      item.slug.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilter(filteredData.length > 0 ? filteredData : []);
  };
  const onSaveDescription = async () => {
    const fields = selectedTemplate[0]?.fields;
    const descriptionValues = fields.map((item) => {
      const descriptionValue = descriptionData.find((element) => element?.id === item?.id)?.description;
      if (descriptionValue !== undefined) {
        item.description = descriptionValue;
        item.requiredForBot = true;
      }
      if (deletedFields && deletedFields.id) {
        item.requiredForBot = false;
      }
      return item;
    });
    const payload = { data: { fields: descriptionValues } };
    try {
      const response = await axiosInstance.patch(`templates/update?$filter=id%20eq%20${selectedTemplate[0]?.id}`, payload);
      if (response) {
        setDeletedFields([]);
        getFields();
        showToastMessage({
          title: 'Success',
          description: FIELDS_TOAST.SUCCESS,
          status: 'success',
        });
      }
    } catch (err) {
      setDeletedFields([]);
      showToastMessage({
        title: 'Error',
        description: FIELDS_TOAST.ERROR,
        status: 'error',
      });
      return [];
    }
  };
  const handleReset = () => {
    setFilter(tableData);
    setFieldValue('');
  };
  const onAddDropdownField = () => {
    const selectedField = dropdownFields.find((item) => item?.id.toString() === dropdownValue);
    const isExisting = descriptionData.filter((item) => item?.id === selectedField?.id).length > 0;
    selectedField !== undefined && !isExisting && setDescriptionData([...descriptionData, selectedField]);
  };
  const headerRenderer = (text) => (
        <Popover  placement="bottom-start" offset={[0, 10]} textTransform="none" isOpen={descriptionPopover} onOpen={() => setDescriptionPopover(true)} onClose={() => setDescriptionPopover(false)}  closeOnBlur={false}>
          <PopoverTrigger>
            <Box display="flex" alignItems="center">
            <Text me="5px" color="#111827" fontSize="14px">{text}</Text>
            <BsInfoCircle onMouseEnter={() => setDescriptionPopover(true)} onMouseLeave={() => setDescriptionPopover(false)} />
            </Box>
          </PopoverTrigger>
          <Portal>
            <PopoverContent zIndex="popover" position="absolute" onMouseEnter={() => setDescriptionPopover(true)} onMouseLeave={() => setDescriptionPopover(false)}>
              <PopoverHeader display="flex" gap="10px" p="10px" borderBottom="1px solid #E2E8F0" alignItems="center">
                <BsInfoCircle color="#E79B04" />
                <Text>Description For Bot:</Text>
              </PopoverHeader>
              <PopoverBody padding="10px" className="popover-body">
                <Text whiteSpace="normal" fontWeight="400" fontSize="14px" color="#111827">This field should contain a detailed explanation of the field's purpose and what kind of information it should contain.</Text>
                <Text whiteSpace="normal" fontWeight="400" fontSize="14px" mt="10px" color="#6B7280" className="popover-example">Eg. The email address of the customer, used for communication and account recovery.</Text>
              </PopoverBody>
            </PopoverContent>
            </Portal>
        </Popover>
  );
  const columnsData = columns.map((item) => {
    if (item.label === 'Description for Bot') {
      return { ...item, label: headerRenderer(item?.label) };
    }
    return item;
  });
  if (isFetching) {
    return (
      <Box height="calc(100vh - 18rem)" alignItems="center" flexDirection="column" display="flex" justifyContent="center">
        <Spinner size="md" />
        <Text>Fetching data</Text>
      </Box>
    );
  }
  return (
   <Box className="fields-section">
      <Box className="search-section" display="flex" gap="10px" justifyContent="end" my="2">
          <InputGroup w="500px">
            <Input placeholder="Search" value={fieldValue} onChange={handleChange} />
            <InputRightElement><IoSearch /></InputRightElement>
          </InputGroup>
          {tableData && <Button onClick={handleReset}>Reset</Button>}
          {tableData && <Button onClick={onSaveDescription}>Save</Button>}
      </Box>
      <Box mt="20px">
          <CustomTable
            height="calc(100vh - 18rem)"
            columns={columnsData}
            tableData={filter}
            onRowClick={() => {}}
            botIntegrationTable="true"
          />
      </Box>
      {
        dropdownFields && dropdownFields.length > 0 && (
        <Box display="flex" gap="20px" mt="10px" fontSize="14px">
            <Select onChange={(e) => setDropdownValue(e.target.value)} maxWidth="200px">
              <option value="">Select Field</option>
              {dropdownFields.map((item) => {
                 <option value={item?.id} key={item?.id}>{item?.label}</option>;
              })}
            </Select>
            <Button onClick={onAddDropdownField}>
              <AddIcon />
              {' '}
              Add field
            </Button>
        </Box>
        )}
   </Box>
  );
};

export default FieldsTableViewWithSearch;
