import React from 'react';
import { Box, Link, useColorModeValue } from '@chakra-ui/react';
import styles from './nav-layout.module.scss';

const FooterContent = () => (
  <Box
    textAlign="right"
    mt={1}
    fontSize="13px"
    p={3}
    bg={useColorModeValue('white', 'rgb(0, 0, 0)')}
    paddingRight="15px"
    className={styles.footer}
  >
    <Link href="https://www.rezolve.ai/" isExternal>
      copyrights@rezolve.ai
    </Link>
  </Box>
);

export default FooterContent;
