import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import _startCase from 'lodash/startCase';
import _camelCase from 'lodash/camelCase';
import { Box, Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import axiosInstance from '../../../utils/axios/axios';
import {
  getRelationDefinition,
  getRelations,
} from '../../DynamicRenderer/Relation/relation.service';
import {
  createTemplateIdMapping,
  getTemplateById,
} from '../../TemplateNewEventAction/helper';
import customStyleFromRuleForSelect from '../../../context/CustomTheme/CustomStyleFromRuleForSelect';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { DARK, LIGHT } from '../../../utils/enums/colors';
import {
  changeSelectedRuleWithKey,
  changeSelectedRuleWithMultiKeyValue,
} from '../../../redux/WorkFlowMainSlice/WorkFlowMainReducer';
import { getWholeOption } from '../helper';
import { getOperators } from '../../TenantFilters/filters.constants';
import ConditionsExpressions from '../../../components/ConditionsExpressions/ConditionsExpressions';
import { isEmptyCondition } from '../../../utils/helper';

const RestricLinkedTicketContain = ({ errorData, setErrorData }) => {
  const [workflowStatusOptionList, setWorkflowStatusOptionList] = useState([]);
  const dispatch = useDispatch();
  const { selectedWorkFlowRule } = useSelector((state) => state.workFlowMain);
  const [relationMap, setRelationMap] = useState({});
  const [relations, setRelations] = useState([]);
  const [allTemplateInTenente, setAllTemplateInTenente] = useState({});
  const [relationDefOptionList, setRelationDefOptionList] = useState([]);
  const { selectedTemplate } = useSelector(
    (reduxState) => reduxState.viewState
  );
  useEffect(() => {
    if (selectedTemplate) {
      getRelations(selectedTemplate.id).then((res) => {
        setRelations(res);
        const relationDefOptions = [...(res || [])].map((item) => ({
          label: _startCase(_camelCase(item.relation_def.left_to_right)),
          value: item.relation_def.left_to_right,
        }));
        setRelationDefOptionList(relationDefOptions);
      });
    }
  }, [selectedTemplate]);

  useEffect(() => {
    // getRelationDefinition().then((response) => {
    //   const optionList = response.flatMap((element) => {
    //     const options = [
    //       {
    //         label: _startCase(_camelCase(element.string_pair_a)),
    //         value: element.string_pair_a,
    //       },
    //     ];
    //     if (element.string_pair_b) {
    //       options.push({
    //         label: _startCase(_camelCase(element.string_pair_b)),
    //         value: element.string_pair_b,
    //       });
    //     }

    //     return options;
    //   });
    //   setRelationDefOptionList([...optionList]);
    // });

    const fetchData = async () => {
      const source = axios.CancelToken.source();
      try {
        const params = {
          $select: 'id, name',
        };
        const response = await axiosInstance.get('templates/list', {
          params,
          cancelToken: source.token,
        });
        if (response?.data?.rows) {
          const TEMP_TEMPLATE_RESPONSE = response?.data?.rows || [];
          const ID_LABEL_OBJ = TEMP_TEMPLATE_RESPONSE.reduce((acc, item) => {
            acc[item.id] = item.name;
            return acc;
          }, {});
          setAllTemplateInTenente(ID_LABEL_OBJ);
        }
      } catch (err) {
        console.warn(err);
      }
    };
    fetchData();
  }, [selectedTemplate.id]);

  useEffect(() => {
    const mapValue = createTemplateIdMapping(
      relations,
      relationDefOptionList,
      allTemplateInTenente,
      true
    );
    setRelationMap(mapValue);
  }, [relations, relationDefOptionList, allTemplateInTenente]);
  const handleErrorData = useCallback((keyName, val) => {
    setErrorData((prev) => {
      if (prev[keyName] && val) {
        return {
          ...prev,
          [keyName]: '',
        };
      }
      return prev;
    });
  }, []);
  useEffect(() => {
    if (selectedWorkFlowRule.relationItemId) {
      getTemplateById(selectedWorkFlowRule.relationItemId).then((data) => {
        const workflowStatus = [
          ...(data?.rows[0]?.workflow?.workflow_status || []),
        ]
          .filter((item) => item.id !== 'start_1')
          .map((status) => ({ value: status.id, label: status.data.label }));
        setWorkflowStatusOptionList(workflowStatus);
      });
    }
  }, [selectedWorkFlowRule.relationItemId]);
  return (
    <Box mt="30px">
      <Box>
        <CustomSelectBox
          options={relationDefOptionList}
          placeholder="Select Relation Type"
          value={getWholeOption(
            selectedWorkFlowRule.relationType,
            relationDefOptionList
          )}
          onChange={(value) => {
            handleErrorData('relationType', value.value);
            dispatch(
              changeSelectedRuleWithMultiKeyValue([
                {
                  keyValue: 'relationType',
                  value: value.value,
                },
                {
                  keyValue: 'relationItemId',
                  value: '',
                },
                {
                  keyValue: 'relationItemStatus',
                  value: '',
                },
                {
                  keyValue: 'relationItemStatusOperator',
                  value: '',
                },
              ])
            );
            setWorkflowStatusOptionList([]);
          }}
          focusBorderColor={useColorModeValue(LIGHT, DARK)}
          styles={customStyleFromRuleForSelect}
          isError={errorData?.relationType || false}
          errorMessage={errorData?.relationType || ''}
          id="DynamicRendererRelationType"
          label="Relation Type"
        />
      </Box>
      <Box mt="10px">
        <CustomSelectBox
          options={relationMap[selectedWorkFlowRule.relationType || ''] || []}
          placeholder="Select Trigger"
          value={getWholeOption(
            selectedWorkFlowRule.relationItemId,
            relationMap[selectedWorkFlowRule.relationType || ''] || []
          )}
          onChange={(value) => {
            handleErrorData('relationItemId', value.value);
            dispatch(
              changeSelectedRuleWithMultiKeyValue([
                {
                  keyValue: 'relationItemId',
                  value: value.value,
                },
                {
                  keyValue: 'relationItemStatus',
                  value: '',
                },
                {
                  keyValue: 'relationItemStatusOperator',
                  value: '',
                },
              ])
            );
            setWorkflowStatusOptionList([]);
          }}
          focusBorderColor={useColorModeValue(LIGHT, DARK)}
          styles={customStyleFromRuleForSelect}
          isError={errorData?.relationItemId || false}
          errorMessage={errorData?.relationItemId || ''}
          id="DynamicRendererRelationItem"
          label="Item Type"
        />
      </Box>
      {/* {!isEmpty(workflowStatusOptionList) && (
        <Grid templateColumns="repeat(2, 1fr)" gap="5px">
          <GridItem mt="10px">
            <CustomSelectBox
              options={workflowStatusOptionList || []}
              placeholder="Select Workflow Status"
              value={selectedWorkFlowRule.relationItemStatus || []}
              onChange={(value) => {
                handleErrorData('relationItemStatus', value);
                dispatch(
                  changeSelectedRuleWithKey({
                    keyValue: 'relationItemStatus',
                    value,
                  })
                );
              }}
              focusBorderColor={useColorModeValue(LIGHT, DARK)}
              styles={customStyleFromRuleForSelect}
              isError={errorData?.relationItemStatus || false}
              errorMessage={errorData?.relationItemStatus || ''}
              id="DynamicRendererRelationItemStatus"
              label="Status"
              isMulti
            />
          </GridItem>
          <GridItem mt="10px">
            <CustomSelectBox
              options={getOperators('DROPDOWN') || []}
              placeholder="Select Operator"
              value={getWholeOption(
                selectedWorkFlowRule.relationItemStatusOperator,
                getOperators('DROPDOWN') || []
              )}
              onChange={(value) => {
                handleErrorData('relationItemStatusOperator', value.value);
                dispatch(
                  changeSelectedRuleWithKey({
                    keyValue: 'relationItemStatusOperator',
                    value: value.value,
                  })
                );
              }}
              focusBorderColor={useColorModeValue(LIGHT, DARK)}
              styles={customStyleFromRuleForSelect}
              isError={errorData?.relationItemStatusOperator || false}
              errorMessage={errorData?.relationItemStatusOperator || ''}
              id="DynamicRendererRelationOperator"
              label="Operator"
            />
          </GridItem>
        </Grid>
      )} */}
      {selectedWorkFlowRule.relationItemId && (
        <Box>
          <ConditionsExpressions
            templateId={selectedWorkFlowRule.relationItemId}
            value={selectedWorkFlowRule?.conditions || {}}
            onChange={(value) => {
              const { and } = value || {};
              const updatedValue = isEmptyCondition(and) ? { and: [] } : value;
              handleErrorData('conditions', value);
              dispatch(
                changeSelectedRuleWithKey({
                  keyValue: 'conditions',
                  value: updatedValue,
                })
              );
            }}
            label="Conditions"
          />
          {errorData?.conditions ? (
            <span
              className="field-error"
              data-testid="RestrictSpecificValueContainerErrorSpan"
            >
              {errorData.conditions}
            </span>
          ) : null}
        </Box>
      )}
      {selectedWorkFlowRule.relationItemId && (
        <Box>
          <ConditionsExpressions
            templateId={selectedWorkFlowRule.relationItemId}
            value={selectedWorkFlowRule?.countCondition || {}}
            onChange={(value) => {
              const { and } = value || {};
              const updatedValue = isEmptyCondition(and) ? { and: [] } : value;
              handleErrorData('countCondition', value);
              dispatch(
                changeSelectedRuleWithKey({
                  keyValue: 'countCondition',
                  value: updatedValue,
                })
              );
            }}
            showCount
            label="Group Function Conditions"
          />
          {errorData?.countCondition ? (
            <span
              className="field-error"
              data-testid="RestrictSpecificValueContainerErrorSpan"
            >
              {errorData.countCondition}
            </span>
          ) : null}
        </Box>
      )}
    </Box>
  );
};
RestricLinkedTicketContain.propTypes = {
  errorData: PropTypes.object.isRequired,
  setErrorData: PropTypes.func.isRequired,
};
export default RestricLinkedTicketContain;
