import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import allFieldsetService from './AllFieldsetService';

export const getFieldsetList = createAsyncThunk(
  'allFieldsetsList/getAllFieldsetsApi',
  async (options, thunkAPI) => {
    try {
      const listData = await allFieldsetService.getAllFieldsetsApi(options);
      return listData;
    } catch (error) {
      const { message } = error.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  allFieldsetsList: [],
  isLoading: false,
  isError: false,
};

const allFieldsets = createSlice({
  name: 'allFieldsets',
  initialState,
  reducers: {
    reset: (state) => {
      state.allFieldsetsList = [];
      state.isError = false;
      state.error = '';
    },
    resetError: (state) => {
      state.isError = false;
      state.error = '';
    },
    updateAllFieldsetList: (state, action) => {
      state.allFieldsetsList = action.payload.allFieldsetsList;
    },
    addFieldstoFieldset: (state, action) => {
      state.allFieldsetsList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFieldsetList.pending, (state) => {
        state.isLoading = true;
        state.allFieldsetsList = [];
        state.isError = false;
        state.error = '';
      })
      .addCase(getFieldsetList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allFieldsetsList = action.payload;
      })
      .addCase(getFieldsetList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload;
      });
  },
});

export const { reset, resetError, updateAllFieldsetList, addFieldstoFieldset } =
  allFieldsets.actions;

export default allFieldsets.reducer;
