/* eslint-disable import/prefer-default-export */
import Joi from 'joi';

import { stringRequiredValidation } from '../validationSchemas';
import {
  ACTION_TYPE_VALUE,
  MANUAL_TRIGGERS,
  NOTIFY_ON_TYPES_VALUE,
  TIME_TRIGGERS,
  approvalOptionsValues,
} from '../../../pages/DynamicRenderer/customEventAndTriggers/customEventAndTriggers.constant';
import { checkForV2 } from '../../helper';

const basicEventValidations = Joi.object({
  event_name: Joi.string().required().messages({
    'string.empty': 'Event name is required',
    'any.required': 'Event name is required.',
  }),
  trigger: Joi.string().required().messages({
    'string.empty': 'Event trigger is required',
    'any.required': 'Event trigger is required.',
  }),
  trigger_type: Joi.string().required().messages({
    'string.empty': 'Trigger type is required',
    'any.required': 'Trigger type is required.',
  }),
  field: Joi.alternatives().conditional('trigger', {
    is: MANUAL_TRIGGERS[0].value,
    then: Joi.object({
      label: Joi.string().required(),
      value: Joi.string().required(),
    }).required(),
    otherwise: Joi.any(),
  }),
  transition_name: Joi.alternatives().conditional('trigger', {
    is: MANUAL_TRIGGERS[2].value,
    then: stringRequiredValidation,
    otherwise: Joi.any(),
  }),
  event: Joi.alternatives().conditional('trigger', {
    is: TIME_TRIGGERS[0].value,
    then: stringRequiredValidation,
    otherwise: Joi.any(),
  }),
  amount_of_time: Joi.alternatives().conditional('trigger', {
    is: TIME_TRIGGERS[0].value,
    then: stringRequiredValidation,
    otherwise: Joi.any(),
  }),
  start_time: Joi.alternatives().conditional('trigger', {
    is: TIME_TRIGGERS[3].value,
    then: stringRequiredValidation,
    otherwise: Joi.any(),
  }),
}).unknown();

const fieldValidation = Joi.array()
  .items(
    Joi.object({
      field: Joi.string().required(),
      value: Joi.string().required(),
    }).required()
  )
  .required();

// const emailTemplateValidation = Joi.alternatives().conditional('action_type', {
//   is: ACTION_TYPE_VALUE.SEND_EMAIL,
//   then: Joi.string().required().messages({
//     'string.empty': 'Email template is required',
//     'any.required': 'Email template is required.',
//   }),
//   otherwise: Joi.forbidden(),
// });

const actionTypeValidation = Joi.object()
  .keys({
    to: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.SEND_EMAIL,
      then: Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .messages({
          'string.empty': 'This field is required',
          'string.email': 'Field must be a valid email address.',
          'any.required': 'This field is required.',
        }),
      otherwise: Joi.any(),
    }),
    subject: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.SEND_EMAIL,
      then: Joi.string().required().messages({
        'string.empty': 'Subject is required',
        'any.required': 'Subject is required.',
      }),
      otherwise: Joi.any(),
    }),
    template: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.SEND_EMAIL,
      then: Joi.string().required().messages({
        'string.empty': 'Email template is required',
        'any.required': 'Email template is required.',
      }),
      otherwise: Joi.any(),
    }),
    create_issue: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.CREATE_ISSUES,
      then: fieldValidation.messages({
        'any.required': 'Create issue is required',
      }),
      otherwise: Joi.any(),
    }),
    notify_on: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.NOTIFY_ON_TEAM_OR_SLACK,
      then: stringRequiredValidation,
      otherwise: Joi.any(),
    }),
    channel_id: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.NOTIFY_ON_TEAM_OR_SLACK,
      then: stringRequiredValidation,
      otherwise: Joi.any(),
    }),
    notification_message: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.NOTIFY_ON_TEAM_OR_SLACK,
      then: stringRequiredValidation,
      otherwise: Joi.any(),
    }),
    team_id: Joi.alternatives().conditional('notify_on', {
      is: NOTIFY_ON_TYPES_VALUE.TEAMS,
      then: Joi.string().required().messages({
        'string.empty': 'Team id is required',
        'any.required': 'Team id is required',
      }),
      otherwise: Joi.any(),
    }),
    // status: Joi.alternatives().conditional('action_type', {
    //   is: ACTION_TYPE_VALUE.LOG_METRICS,
    //   then: stringRequiredValidation,
    //   otherwise: Joi.any(),
    // }),
    script_type: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.RUN_SCRIPT,
      then: stringRequiredValidation,
      otherwise: Joi.any(),
    }),
    script_to_run: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.RUN_SCRIPT,
      then: stringRequiredValidation,
      otherwise: Joi.any(),
    }),
    url: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.TRIGGER_WEBHOOK,
      then: Joi.string().uri().required().messages({
        'string.empty': 'This field is required',
        'string.uri': 'Enter a valid URL',
        'any.required': 'This field is required.',
      }),
      otherwise: Joi.any(),
    }),
    request_type: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.TRIGGER_WEBHOOK,
      then: stringRequiredValidation,
      otherwise: Joi.any(),
    }),
    insert_data: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.TRIGGER_WEBHOOK,
      then: stringRequiredValidation,
      otherwise: Joi.any(),
    }),
    update_record: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.UPDATE_RECORDS,
      then: fieldValidation.messages({
        'any.required': 'Create issue is required',
      }),
      otherwise: Joi.any(),
    }),
    queue_field: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.QUEUE_ASSIGNMENT,
      then: Joi.string().messages({
        'string.empty': 'This field is required',
        'any.required': 'Queue field is required',
      }),
      otherwise: Joi.any(),
    }),
    actor_field: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.ACTOR_ASSIGNMENT,
      then: Joi.string().messages({
        'any.required': 'Actor field is required',
      }),
      otherwise: Joi.any(),
    }),
    type_of_approval: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.INITIATE_APPROVAL_FLOW,
      then: Joi.string().messages({
        'any.required': 'Type of approval is required',
      }),
      otherwise: Joi.any(),
    }),
    number_of_approvals_required: Joi.alternatives().conditional(
      'action_type',
      {
        is: ACTION_TYPE_VALUE.INITIATE_APPROVAL_FLOW,
        then: Joi.alternatives().conditional('type_of_approval', {
          is: approvalOptionsValues.flat,
          then: Joi.number().required().messages({
            'number.base': 'Number of approvals field must be a number',
            'any.required': 'Number of approvals is required',
          }),
          otherwise: Joi.any(),
        }),
        otherwise: Joi.any(),
      }
    ),
    levels: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.INITIATE_APPROVAL_FLOW,
      then: Joi.alternatives().conditional('type_of_approval', {
        is: approvalOptionsValues.heirarchical,
        then: Joi.number().required().messages({
          'number.base': 'levels field must be a number',
          'any.required': 'levels is required',
        }),
        otherwise: Joi.any(),
      }),
      otherwise: Joi.any(),
    }),
    flat_permitted_roles: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.INITIATE_APPROVAL_FLOW,
      then: Joi.alternatives().conditional('type_of_approval', {
        is: approvalOptionsValues.flat,
        then: Joi.object({
          roles: Joi.array().min(1),
        }).required(),
        otherwise: Joi.any(),
      }),
      otherwise: Joi.any(),
    }),
    hierarchical_permitted_roles: Joi.alternatives().conditional(
      'action_type',
      {
        is: ACTION_TYPE_VALUE.INITIATE_APPROVAL_FLOW,
        then: Joi.alternatives().conditional('type_of_approval', {
          is: approvalOptionsValues.heirarchical,
          then: Joi.object({
            roles: Joi.array().min(1),
          }).required(),
          otherwise: Joi.any(),
        }),
        otherwise: Joi.any(),
      }
    ),
  })
  .unknown();

const actionTypeValidationForV2 = Joi.object()
  .keys({
    to: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.SEND_EMAIL,
      then: Joi.string(),
      otherwise: Joi.any(),
    }),
    subject: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.SEND_EMAIL,
      then: Joi.string(),
      otherwise: Joi.any(),
    }),
    template: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.SEND_EMAIL,
      then: Joi.string().required().messages({
        'string.empty': 'Email template is required',
        'any.required': 'Email template is required.',
      }),
      otherwise: Joi.any(),
    }),
    create_issue: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.CREATE_ISSUES,
      then: fieldValidation.messages({
        'any.required': 'Create issue is required',
      }),
      otherwise: Joi.any(),
    }),
    notify_on: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.NOTIFY_ON_TEAM_OR_SLACK,
      then: stringRequiredValidation,
      otherwise: Joi.any(),
    }),
    channel_id: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.NOTIFY_ON_TEAM_OR_SLACK,
      then: stringRequiredValidation,
      otherwise: Joi.any(),
    }),
    notification_message: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.NOTIFY_ON_TEAM_OR_SLACK,
      then: stringRequiredValidation,
      otherwise: Joi.any(),
    }),
    team_id: Joi.alternatives().conditional('notify_on', {
      is: NOTIFY_ON_TYPES_VALUE.TEAMS,
      then: Joi.string().required().messages({
        'string.empty': 'Team id is required',
        'any.required': 'Team id is required',
      }),
      otherwise: Joi.any(),
    }),
    // status: Joi.alternatives().conditional('action_type', {
    //   is: ACTION_TYPE_VALUE.LOG_METRICS,
    //   then: stringRequiredValidation,
    //   otherwise: Joi.any(),
    // }),
    script_type: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.RUN_SCRIPT,
      then: stringRequiredValidation,
      otherwise: Joi.any(),
    }),
    script_to_run: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.RUN_SCRIPT,
      then: stringRequiredValidation,
      otherwise: Joi.any(),
    }),
    url: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.TRIGGER_WEBHOOK,
      then: Joi.string().uri().required().messages({
        'string.empty': 'This field is required',
        'string.uri': 'Enter a valid URL',
        'any.required': 'This field is required.',
      }),
      otherwise: Joi.any(),
    }),
    request_type: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.TRIGGER_WEBHOOK,
      then: stringRequiredValidation,
      otherwise: Joi.any(),
    }),
    insert_data: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.TRIGGER_WEBHOOK,
      then: stringRequiredValidation,
      otherwise: Joi.any(),
    }),
    update_record: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.UPDATE_RECORDS,
      then: fieldValidation.messages({
        'any.required': 'Create issue is required',
      }),
      otherwise: Joi.any(),
    }),
    queue_field: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.QUEUE_ASSIGNMENT,
      then: Joi.string().messages({
        'string.empty': 'This field is required',
        'any.required': 'Queue field is required',
      }),
      otherwise: Joi.any(),
    }),
    actor_field: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.ACTOR_ASSIGNMENT,
      then: Joi.string().messages({
        'any.required': 'Actor field is required',
      }),
      otherwise: Joi.any(),
    }),
    type_of_approval: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.INITIATE_APPROVAL_FLOW,
      then: Joi.string().messages({
        'any.required': 'Type of approval is required',
      }),
      otherwise: Joi.any(),
    }),
    number_of_approvals_required: Joi.alternatives().conditional(
      'action_type',
      {
        is: ACTION_TYPE_VALUE.INITIATE_APPROVAL_FLOW,
        then: Joi.alternatives().conditional('type_of_approval', {
          is: approvalOptionsValues.flat,
          then: Joi.number().required().messages({
            'number.base': 'Number of approvals field must be a number',
            'any.required': 'Number of approvals is required',
          }),
          otherwise: Joi.any(),
        }),
        otherwise: Joi.any(),
      }
    ),
    levels: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.INITIATE_APPROVAL_FLOW,
      then: Joi.alternatives().conditional('type_of_approval', {
        is: approvalOptionsValues.heirarchical,
        then: Joi.number().required().messages({
          'number.base': 'levels field must be a number',
          'any.required': 'levels is required',
        }),
        otherwise: Joi.any(),
      }),
      otherwise: Joi.any(),
    }),
    flat_permitted_roles: Joi.alternatives().conditional('action_type', {
      is: ACTION_TYPE_VALUE.INITIATE_APPROVAL_FLOW,
      then: Joi.alternatives().conditional('type_of_approval', {
        is: approvalOptionsValues.flat,
        then: Joi.object({
          roles: Joi.array().min(1),
        }).required(),
        otherwise: Joi.any(),
      }),
      otherwise: Joi.any(),
    }),
    hierarchical_permitted_roles: Joi.alternatives().conditional(
      'action_type',
      {
        is: ACTION_TYPE_VALUE.INITIATE_APPROVAL_FLOW,
        then: Joi.alternatives().conditional('type_of_approval', {
          is: approvalOptionsValues.heirarchical,
          then: Joi.object({
            roles: Joi.array().min(1),
          }).required(),
          otherwise: Joi.any(),
        }),
        otherwise: Joi.any(),
      }
    ),
  })
  .unknown();
const validateActionToPerformCompleteData =
  checkForV2() ?
    Joi.array().items(actionTypeValidationForV2) : Joi.array().items(actionTypeValidation);

export const validateCompleteEventData = Joi.array().items(
  basicEventValidations.append({
    action_to_perform: validateActionToPerformCompleteData,
  })
);
