import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import {
  LABELS,
  ROLE_DESCRIPTIONS,
  ROLE_TITLE,
} from '../../../utils/enums/labels';
import styles from '../AddNewRoleModal/rolecontent.module.scss';
import RoleFour from '../../../assets/icons/Role_4.svg';
import CustomModalFooter from '../CreateEditFlow/CustomModalFooter';
import { updateRuleModalId } from '../../../redux/WorkFlowRuleSlice/WorkFlowRuleReducer';
import RestrictValidateSpecialPermissionContainer from './RestrictValidateSpecialPermissionContainer';
import { CustomDrawer } from '../../TicketTemplateBuilder/ConfigureTemplateView/AddEditTemplateLayoutModal';

const RestrictValidateSpecialPermissionModal = ({
  isRestricSpecialRulePermission,
  setIsRestricSpecialRulePermission,
}) => {
  const dispatch = useDispatch();
  return (
    <CustomDrawer
      id="selected-field-modal"
      modalTitle={LABELS.ACTIONS.ADD_RULE}
      drawerBody={
        <>
          <Flex
            data-testid="RestrictValidateSpecialPermissionModalBoxFLex"
          >
            <Box
              className={styles.boxSvg}
              bgColor="#FFF7EA"
              data-testid="RestrictValidateSpecialPermissionModalSvgBox"
            >
              <ReactSVG
                src={`${RoleFour}`}
                alt="image"
                data-testid="RestrictValidateSpecialPermissionModalReactSvg"
              />
            </Box>

            <Box
              className={styles.cardbody}
              data-testid="RestrictValidateSpecialPermissionModalCardbodyBox"
            >
              <div
                style={{ marginLeft: '20px' }}
                data-testid="RestrictValidateSpecialPermissionModalCardbodyDiv"
              >
                <Heading
                  className={styles.cardheading}
                  data-testid="RestrictValidateSpecialPermissionModalCardbodyHeading"
                >
                  {ROLE_TITLE.SPECIAL_PERMISSION}
                </Heading>
                <Text
                  className={styles.carddesc}
                  data-testid="RestrictValidateSpecialPermissionModalDescText"
                >
                  {ROLE_DESCRIPTIONS.SPECIAL_PERMISSION}
                </Text>
              </div>
            </Box>
          </Flex>
          <RestrictValidateSpecialPermissionContainer />
        </>
      }
      drawerFooter={
        <CustomModalFooter
          btnLabel={LABELS.ACTIONS.ADD_RULE}
          pt="10px"
          isDisabled={false}
          onCancelClick={() => {
            dispatch(updateRuleModalId(''));
            setIsRestricSpecialRulePermission(false);
          }}
          onAddClick={() => {
            dispatch(updateRuleModalId(''));
            setIsRestricSpecialRulePermission(false);
          }}
        />
      }
      openDrawer={isRestricSpecialRulePermission}
      setOpenDrawer={setIsRestricSpecialRulePermission}
    />
  );
};
RestrictValidateSpecialPermissionModal.propTypes = {
  setIsRestricSpecialRulePermission: PropTypes.func.isRequired,
  isRestricSpecialRulePermission: PropTypes.bool.isRequired,
};
export default RestrictValidateSpecialPermissionModal;
