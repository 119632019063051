/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import axios from 'axios';
import { isEmpty } from 'lodash';
import axiosInstance from '../../../utils/axios/axios';
import { DROPDOWN_TYPES, SPECIAL_FIELD_TYPE } from '../../TenantFilters/filters.constants';

const useFetchTemplate = () => {
  const [templateOptionList, setTemplateOptionList] = useState([]);
  const [fieldMapByTemplateId, setFieldMapByTemplateId] = useState({});
  const [loading, setLoading] = useState(true);
  const [errorFromApi, setErrorFromApi] = useState(null);
  const [slaMapByTemplateId, setSlaMapByTemplateId] = useState({});
  const [metricMapByTemplateId, setMetricMapByTemplateId] = useState({});
  const [fieldListOptionWithData, setFieldListOptionWithData] = useState({});
  const [hierarchyIdWithItem, setHierarchyIdWithItem] = useState({
    hierarchyMapWithItem: {},
    dependentFieldMapWithItem: {}
  });
  const transformDataForHierarchy = (inputData) => {
    const output = {};
    const output2 = {};

    inputData.forEach((item) => {
      if (item.field_hierarchy_map) {
        item.field_hierarchy_map.forEach((fhMap) => {
          const hierarchyListId = fhMap.heirachyListId;
          output[hierarchyListId] = output[hierarchyListId] || [];
          if (!output[hierarchyListId].some((i) => i.value === item.id)) {
            output[hierarchyListId].push({
              value: item.id,
              label: item.name,
              uuidColumn: item.uuid_column,
            });
          }
        });

        const uniqueDetails = new Map();

        item.field_hierarchy_map.forEach((fhMap) => {
          fhMap.map.forEach((mapId) => {
            if (!uniqueDetails.has(mapId)) {
              const fieldDetail = item.fields.find((field) => field.id === mapId);
              if (fieldDetail) {
                uniqueDetails.set(mapId, fieldDetail);
              }
            }
          });
        });

        output2[item.id] = Array.from(uniqueDetails.values())
          .map((field) => ({ value: field.id, label: field.label }));
      }
    });

    return { output, output2 };
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        const params = {
          $select: 'id, name, fields, slas, uuid_column, metric, workflow, field_hierarchy_map',
        };
        const response = await axiosInstance.get('templates/list', {
          params,
          cancelToken: source.token,
        });
        if (response?.data?.rows) {
          const obj = transformDataForHierarchy(response?.data?.rows || []);
          setHierarchyIdWithItem({
            hierarchyMapWithItem: obj.output,
            dependentFieldMapWithItem: obj.output2
          });
          const TEMP_TEMPLATE_RESPONSE = response?.data?.rows || [];
          const TEMPLATE_OPTION = TEMP_TEMPLATE_RESPONSE.map((item) => ({
            value: item.id,
            label: item.name,
            uuidColumn: item.uuid_column,
          }));
          const MAP_FIELD_BY_TEMPLATE_ID = TEMP_TEMPLATE_RESPONSE.reduce(
            (acc, item) => {
              if (item.fields && item.fields.length > 0) {
                acc[item.id] = {
                  TEXT: [],
                  EMAIL: [],
                  NUMBER: [],
                  DATE: [],
                  BOOLEAN: [],
                  TAGS: [],
                  DROPDOWN: [],
                  TEXT_AREA: [],
                  actor: [],
                  source: [],
                  queue: [],
                  workflowState: [],
                  MEDIA: []
                };

                item.fields.forEach((field) => {
                  const { type, id, label, type_based_attributes, scope } = field;

                  if (acc[item.id][type] && (scope === 'TICKET' || isEmpty(scope))) {
                    if (type === 'DROPDOWN' && type_based_attributes?.collection?.collection_type === DROPDOWN_TYPES.SPECIAL_FIELD) {
                      if (type_based_attributes?.collection?.specialField
                        === SPECIAL_FIELD_TYPE.ACTORS) {
                        acc[item.id].actor.push({ value: id, label });
                      } else if (type_based_attributes?.collection?.specialField
                        === SPECIAL_FIELD_TYPE.SOURCE) {
                        acc[item.id].source.push({ value: id, label });
                      } else if (type_based_attributes?.collection?.specialField
                        === SPECIAL_FIELD_TYPE.QUEUE) {
                        acc[item.id].queue.push({ value: id, label });
                      } else if (type_based_attributes?.collection?.specialField
                        === SPECIAL_FIELD_TYPE.WORKFLOW) {
                        acc[item.id].workflowState.push({ value: id, label });
                      }
                    } else if (type === 'DROPDOWN' && type_based_attributes?.collection?.collection_type === DROPDOWN_TYPES.STATIC) {
                      if (
                        type_based_attributes?.collection?.collection_type
                        !== DROPDOWN_TYPES.DYNAMIC
                      ) {
                        acc[item.id].DROPDOWN.push({ value: id,
                          label,
                          data: type_based_attributes?.collection?.staticValues || [] });
                      }
                    } else if (type === 'TAGS' && type_based_attributes?.collection?.collection_type === DROPDOWN_TYPES.STATIC) {
                      acc[item.id].TAGS.push({ value: id, label });
                    } else if (type === 'MEDIA') {
                      acc[item.id][type].push({ value: id, label });
                    } else if (type !== 'DROPDOWN' && type !== 'TAGS') {
                      acc[item.id][type].push({ value: id, label });
                    }
                  }
                });
              } else {
                acc[item.id] = {
                  TEXT: [],
                  EMAIL: [],
                  NUMBER: [],
                  DATE: [],
                  BOOLEAN: [],
                  TAGS: [],
                  DROPDOWN: [],
                  TEXT_AREA: [],
                  actor: [],
                  source: [],
                  queue: [],
                  workflowState: [],
                  MEDIA: []
                };
              }
              return acc;
            },
            {}
          );

          const MAP_SLA_BY_TEMPLATE_ID = TEMP_TEMPLATE_RESPONSE.reduce(
            (acc, item) => {
              if (item.slas) {
                const TEMP = Object.values(item.slas).flatMap((array) =>
                  array.map(({ id, name }) => ({ value: id, label: name }))
                );
                acc[item.id] = [...TEMP];
              } else {
                acc[item.id] = [];
              }
              return acc;
            },
            {}
          );

          const MAP_METRIC_BY_TEMPLATE_ID = TEMP_TEMPLATE_RESPONSE.reduce(
            (acc, item) => {
              if (item.metric) {
                acc[item.id] = item?.metric?.map((i) => ({
                  label: i?.name,
                  value: i?.id,
                }));
              } else {
                acc[item.id] = [];
              }
              return acc;
            },
            {}
          );
          const FIELD_LIST_OPTION_WITH_DATA = TEMP_TEMPLATE_RESPONSE.reduce(
            (acc, item) => {
              if (item.fields && item.fields.length > 0) {
                const WORKFLOW_STATUS = item.workflow || {};
                const TEMP = item.fields
                  .map((field) => ({
                    value: field.id,
                    label: field.label,
                    data: field,
                    workflowData: { ...WORKFLOW_STATUS }
                  }));
                acc[item.id] = [...TEMP];
              } else {
                acc[item.id] = [];
              }
              return acc;
            },
            {}
          );
          setMetricMapByTemplateId(MAP_METRIC_BY_TEMPLATE_ID);
          setSlaMapByTemplateId(MAP_SLA_BY_TEMPLATE_ID);
          setTemplateOptionList(TEMPLATE_OPTION);
          setFieldMapByTemplateId(MAP_FIELD_BY_TEMPLATE_ID);
          setFieldListOptionWithData(FIELD_LIST_OPTION_WITH_DATA);
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          setErrorFromApi(err);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      source.cancel();
    };
  }, []);

  return {
    templateOptionList,
    fieldMapByTemplateId,
    loading,
    errorFromApi,
    slaMapByTemplateId,
    metricMapByTemplateId,
    fieldListOptionWithData,
    hierarchyIdWithItem
  };
};

export default useFetchTemplate;
