/* eslint-disable import/no-cycle */
import CustomToast from '../../../components/Notification/Notification';
import axiosInstance from '../../../utils/axios/axios';
import store from '../../../store/Store';
import {
  checkForCollaborators,
  checkPermissions,
  enableViewerIfUserNotFound,
  isCurrentUserTemplateOwner,
} from '../../../utils/helper/roleBasedAccess';
import { TEMPLATE_TOOLTIPS } from '../../../utils/enums/labels';

const { addToast } = CustomToast();

export const showToastMessage = ({ status, title, description }) => {
  addToast({
    title,
    message: description,
    type: status,
  });
};

export const getTemplateData = async ({ id, field }) => {
  const endpoint = `templates/list?$top=1&$skip=0&$select=${field}&$filter=id eq ${id}`;
  try {
    const response = await axiosInstance.get(endpoint);
    return {
      response: response?.data,
      error: null,
    };
  } catch (error) {
    return {
      response: null,
      error: error?.response?.data?.message,
    };
  }
};

const templateData = {};

export const checkPermissionForTemplatesWithData = ({
  data,
  key = 'viewers',
  displayToastMessage = false,
}) => {
  const accessConsumers = data?.properties?.permissions?.[key];
  // if user info not found
  if (key === 'viewers' && enableViewerIfUserNotFound()) {
    return null;
  }
  // If owner is the current user then allow access
  if (isCurrentUserTemplateOwner(data)) {
    return null;
  }
  // if collaborator is the current user then allow access
  if (checkForCollaborators(data)) {
    return null;
  }

  // If editors/viewers are not available then allow editing
  // const isEmpty =
  //   !accessConsumers ||
  //   (typeof accessConsumers === 'object' &&
  //     !Object.keys(accessConsumers).some((p) => accessConsumers[p]?.length));

  // if (isEmpty) {
  //   return null;
  // }

  // If the user doesn't have the permission then throw error
  if (!checkPermissions(accessConsumers)) {
    if (displayToastMessage) {
      showToastMessage({
        description: TEMPLATE_TOOLTIPS.EDIT_RESTRICTION,
        status: 'error',
      });
    }
    return {
      response: null,
      error: TEMPLATE_TOOLTIPS.EDIT_RESTRICTION,
    };
  }
  return null;
};

/** This can be used for filtering viewers for a list vew */
export const canViewTemplate = ({ data }) => {
  const viewers = checkPermissionForTemplatesWithData({
    data,
    key: 'viewers',
    showToastMessage: false,
  });

  const editors = checkPermissionForTemplatesWithData({
    data,
    key: 'editors',
    showToastMessage: false,
  });

  return !viewers || !editors;
};

const checkPermission = async ({ key, id, displayToastMessage }) => {
  let data = store?.getState()?.viewState?.selectedTemplate;
  const templateId = data?.id;

  /*
   * If the template available in selectedTemplate
     is not the template to which we are trying to make the API call
   */
  if (!templateId || templateId?.toString() !== id?.toString()) {
    if (templateData[id]) {
      data = templateData[id];
    } else {
      const res = await getTemplateData({ id, field: '*' });
      data = res?.response?.rows?.[0];
      // Saving the data for future use in an object
      Object.assign(templateData, {
        [id]: data,
      });
    }
  }

  return checkPermissionForTemplatesWithData({
    data,
    key,
    displayToastMessage,
  });
};

export const checkIfUserCanView = async ({ id }) => {
  const res = await checkPermission({
    key: 'viewers',
    id,
    displayToastMessage: false,
  });
  return res;
};

export const checkIfUserCanEdit = async ({ displayToastMessage, id }) => {
  const res = await checkPermission({
    key: 'editors',
    id,
    displayToastMessage,
  });
  return res;
};

export const triggerPatchApi = async ({
  id,
  requestBody,
  defaultErrorMessage,
  successMessage,
  displayToastMessage,
  bypassEditCheck,
}) => {
  const endpoint = `/templates/update?$filter=id eq ${id}`;

  if (!bypassEditCheck) {
    // Checking if the user has the Edit access
    const res = await checkIfUserCanEdit({ displayToastMessage, id });
    if (res?.error) {
      return res;
    }
  }

  try {
    const response = await axiosInstance.patch(endpoint, requestBody);

    if (displayToastMessage) {
      showToastMessage({
        title: 'Success',
        description: successMessage,
        status: 'success',
      });
    }

    return {
      response: response?.data,
      error: null,
    };
  } catch (error) {
    const errorMessage = error?.response?.data?.message || defaultErrorMessage;

    if (displayToastMessage) {
      showToastMessage({
        title: 'Something went wrong',
        description: errorMessage,
        status: 'error',
      });
    }

    return {
      response: null,
      error: errorMessage,
    };
  }
};

export const patchDataForTemplate = async ({
  id,
  data,
  key,
  defaultErrorMessage,
  successMessage,
  displayToastMessage = true,
  bypassEditCheck = false,
}) => {
  const requestBody = {
    data: {
      [key]: data,
    },
  };
  const res = await triggerPatchApi({
    id,
    requestBody,
    defaultErrorMessage,
    successMessage,
    displayToastMessage,
    bypassEditCheck,
  });
  return res;
};

export const patchMultipleFieldsForTemplate = async ({
  id,
  payload,
  defaultErrorMessage,
  successMessage,
  displayToastMessage = true,
  bypassEditCheck = false,
}) => {
  const requestBody = {
    data: payload,
  };

  const res = await triggerPatchApi({
    id,
    requestBody,
    defaultErrorMessage,
    successMessage,
    displayToastMessage,
    bypassEditCheck,
  });
  return res;
};
export const triggerPatchApiWithPath = async ({
  id,
  requestBody,
  defaultErrorMessage,
  successMessage,
  displayToastMessage,
  bypassEditCheck,
}) => {
  const endpoint = `/templates/updateWithPath?$filter=id eq ${id}`;

  if (!bypassEditCheck) {
    // Checking if the user has the Edit access
    const res = await checkIfUserCanEdit({ displayToastMessage, id });
    if (res?.error) {
      return res;
    }
  }

  try {
    const response = await axiosInstance.patch(endpoint, requestBody);

    if (displayToastMessage) {
      showToastMessage({
        title: 'Success',
        description: successMessage,
        status: 'success',
      });
    }

    return {
      response: response?.data,
      error: null,
    };
  } catch (error) {
    const errorMessage = error?.response?.data?.message || defaultErrorMessage;

    if (displayToastMessage) {
      showToastMessage({
        title: 'Something went wrong',
        description: errorMessage,
        status: 'error',
      });
    }

    return {
      response: null,
      error: errorMessage,
    };
  }
};
export const patchDataForTemplateWithPath = async ({
  id,
  data,
  defaultErrorMessage,
  successMessage,
  displayToastMessage = true,
  bypassEditCheck = false,
}) => {
  const requestBody = {
    ...data,
  };

  const res = await triggerPatchApiWithPath({
    id,
    requestBody,
    defaultErrorMessage,
    successMessage,
    displayToastMessage,
    bypassEditCheck,
  });
  return res;
};
