/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { renderFieldsBasedonType } from './utils/RenderFieldsBasedOnType';

const CollapsibleSectionLayout = ({ item, previewRenderer, isExpand }) => (
  <Accordion
    width="100%"
    allowToggle
    data-testid="CollapsibleSectionLayoutAccordion"
    defaultIndex={isExpand}
  >
    <AccordionItem data-testid="CollapsibleSectionLayoutAccordionItem">
      <h2>
        <AccordionButton data-testid="CollapsibleSectionLayoutAccordionButton">
          <Box
            as="span"
            flex="1"
            textAlign="left"
            data-testid="CollapsibleSectionLayoutNameBox"
          >
            {!item?.hideSectionLabel && item?.sectionName}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel
        pb={4}
        data-testid="CollapsibleSectionLayoutAccordionPanel"
      >
        <Grid
          templateColumns="repeat(12, 1fr)"
          flex="1 1 0px"
          gap={4}
          py="4"
          pl="2"
          pr="2"
          data-testid="CollapsibleSectionLayoutItemGrid"
        >
          {item?.type === 'component' ? (
            previewRenderer(item?.subItems)
          ) : !item?.subItems?.length ? (
            <GridItem
              colSpan={12}
              data-testid="CollapsibleSectionLayoutGridItem"
            >
              {}
            </GridItem>
          ) : (
            item?.subItems?.map((subItem) => (
              <Box
                key={subItem.id}
                gridColumnEnd={`span ${subItem.width}`}
                display="block"
                alignItems="center"
                justifyContent="space-between"
                data-testid="CollapsibleSectionLayoutSubItemBox"
              >
                <>
                  <Box p="2">{subItem?.label}</Box>
                  {renderFieldsBasedonType(subItem)}
                </>
              </Box>
            ))
          )}
        </Grid>
      </AccordionPanel>
    </AccordionItem>
  </Accordion>
);

CollapsibleSectionLayout.propTypes = {
  item: PropTypes.array.isRequired,
  previewRenderer: PropTypes.func.isRequired,
  isExpand: PropTypes.array,
};
CollapsibleSectionLayout.defaultProps = {
  isExpand: [],
};
export default CollapsibleSectionLayout;
