import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, FormLabel } from '@chakra-ui/react';
import { Editor } from '@monaco-editor/react';

const CodeEditor = ({ label, onChange, value, height }) => {
  const editorRef = useRef();
  const onMount = (editor) => {
    editorRef.current = editor;
    editor.focus();
  };
  return (
    <Box>
      {label && <FormLabel className="SCLabel">{label}</FormLabel>}
      <Editor
        height={height}
        value={value}
        onChange={(data) => {
          onChange(data);
        }}
        onMount={onMount}
        defaultLanguage="HTML"
        options={{
          selectOnLineNumbers: true,
          roundedSelection: false,
          readOnly: false,
          cursorStyle: 'line',
          automaticLayout: true,
        }}
      />
    </Box>
  );
};

CodeEditor.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  height: PropTypes.string,
};
CodeEditor.defaultProps = {
  height: '200px',
  value: '',
};
export default CodeEditor;
