import RoleOneIcon from '../../../assets/icons/Role_1.svg';
import RoleTwoIcon from '../../../assets/icons/Role_2.svg';
import RoleThreeIcon from '../../../assets/icons/Role_3.svg';
// import RoleFourIcon from '../../../assets/icons/Role_4.svg';
// import RoleFiveIcon from '../../../assets/icons/Role_5.svg';
import RoleSixIcon from '../../../assets/icons/Role_6.svg';
import { ROLE_DESCRIPTIONS, ROLE_TITLE } from '../../../utils/enums/labels';
import { checkForV2 } from '../../../utils/helper';

// eslint-disable-next-line import/prefer-default-export
export const APPLYED_ALL_ROLE = [
  {
    image: RoleOneIcon,
    label: ROLE_TITLE.MOVE_ISSUE,
    modalId: 'moveIssue',
    description: ROLE_DESCRIPTIONS.MOVE_ISSUE,
  },
  checkForV2() && {
    image: RoleTwoIcon,
    label: ROLE_TITLE.SPECIFIC_STATUS,
    modalId: 'specificStatus',
    description: ROLE_DESCRIPTIONS.SPECIFIC_STATUS,
  },
  {
    image: RoleThreeIcon,
    label: ROLE_TITLE.SPECIFIC_VALUE,
    modalId: 'specificValue',
    description: ROLE_DESCRIPTIONS.SPECIFIC_VALUE,
  },
  // {
  //   image: RoleFourIcon,
  //   label: ROLE_TITLE.SPECIAL_PERMISSION,
  //   modalId: 'specialPermission',
  //   description: ROLE_DESCRIPTIONS.SPECIAL_PERMISSION,
  // },
  // {
  //   image: RoleFiveIcon,
  //   label: ROLE_TITLE.ASSIGN_ISSUE,
  //   modalId: 'assignIssue',
  //   description: ROLE_DESCRIPTIONS.ASSIGN_ISSUE,
  // },
  {
    image: RoleSixIcon,
    label: ROLE_TITLE.RESTRICT_LINKED_TICKET,
    modalId: 'restrictLinkedTicket',
    description: ROLE_DESCRIPTIONS.RESTRICT_LINKED_TICKET,
  },
];
