import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import { useSelector, useDispatch } from 'react-redux';
import QuickActionTableViewWithSearch from './QuickActionTableViewWithSearch';
import {
  COLUMNS_QUICK_ACTION,
  QUICK_ACTION_DATA_KEYS,
  createPayloadForQuickAction,
  createQuickActionValidation,
} from './quickAction.constants';
import EditQuickActionDrawer from './EditQuickActionDrawer';
import { processErrors } from '../DynamicRenderer/AddEditSignatureDrawer/utils';
import { patchDataForTemplate } from '../TicketTemplateBuilder/utils/templateAPIUtils';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import { setActiveModal } from '../../redux/ModalReducer/ModalSlice';
import { processErrorWithMessage } from '../TemplateNewEventAction/helper';

const QuickAction = () => {
  const [edit, setEdit] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [errorWithPathAsKey, setErrorWithPathAsKey] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { selectedTemplate } = useSelector((state) => state.viewState || {});
  const dispatch = useDispatch();

  const genericCopy = async (value) => {
    const communicationList = _cloneDeep(
      selectedTemplate?.[[QUICK_ACTION_DATA_KEYS.QUICK_ACTIONS]] || []
    );
    const rowIndex = communicationList.findIndex((e) => e.id === value.id);
    const newName = _get(communicationList, `[${rowIndex}].name`, '');
    const toastSuccessMsg = `Failed to clone quick action`;
    const toastFailedMsg = `Successfully cloned the quick action`;
    dispatch(setActiveModal({ targetModalId: 'crudTemplateCloneModal' }));
    dispatch(
      updateViewState({
        stateKey: 'crudTemplateCloneModal',
        value: {
          id: selectedTemplate.id,
          patchData: {
            data: value,
            toastSuccessMsg,
            toastFailedMsg,
          },
          title: newName,
          masterType: `quick action`,
          listKey: 'selectedTemplate',
          targetUrl: `/templates/update?$filter=id eq ${selectedTemplate.id}`,
          method: 'patch',
        },
      })
    );
  };
  const onEdit = (rowData) => {
    setEdit(true);
    setSelectedRowData(rowData);
    setOpenEditDrawer(true);
  };

  const onClone = (rowData) => {
    genericCopy(rowData);
  };

  const onDelete = (val) => {
    dispatch(setActiveModal({ targetModalId: 'deleteModal' }));
    dispatch(
      updateViewState({
        stateKey: 'deleteModal',
        value: {
          ...val,
          labelKey: 'name',
          masterType: QUICK_ACTION_DATA_KEYS.QUICK_ACTIONS,
          listKey: 'selectedTemplate',
          targetUrl: '/templates/update?$filter=id eq ${id}',
          method: 'patch',
        },
      })
    );
  };

  const createQuickAction = async (payload) => {
    setIsLoading(true);
    const newData = [
      ...(selectedTemplate?.[QUICK_ACTION_DATA_KEYS.QUICK_ACTIONS] || []),
      { ...payload },
    ];

    const res = await patchDataForTemplate({
      id: selectedTemplate.id,
      data: newData,
      key: QUICK_ACTION_DATA_KEYS.QUICK_ACTIONS,
      defaultErrorMessage: 'Failed to create a quick action',
      successMessage: 'Successfully created quick action',
    });

    if (!res.error) {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: res?.response?.data,
        })
      );
    }
    setIsLoading(false);
    setOpenEditDrawer(false);
    setSelectedRowData({});
    setEdit(false);
  };
  const updateQuickAction = async (payload) => {
    setIsLoading(true);
    const { id } = selectedRowData;

    const editedFieldIndex = [
      ...(selectedTemplate?.[QUICK_ACTION_DATA_KEYS.QUICK_ACTIONS] || []),
    ].findIndex((item) => item.id === id);

    if (editedFieldIndex !== -1) {
      const updatedFields = [
        ...(selectedTemplate?.[QUICK_ACTION_DATA_KEYS.QUICK_ACTIONS] || []),
      ];
      updatedFields[editedFieldIndex] = { ...payload };

      const res = await patchDataForTemplate({
        id: selectedTemplate.id,
        data: updatedFields,
        key: QUICK_ACTION_DATA_KEYS.QUICK_ACTIONS,
        defaultErrorMessage: 'Failed to update the quick action',
        successMessage: 'Successfully updated the quick action',
      });

      if (!res.error) {
        dispatch(
          updateViewState({
            stateKey: 'selectedTemplate',
            value: res?.response?.data,
          })
        );
      }
    }
    setIsLoading(false);
    setOpenEditDrawer(false);
    setSelectedRowData({});
    setEdit(false);
  };

  const addQuickAction = (data) => {
    const checkNameUnique =
      [...(selectedTemplate?.[QUICK_ACTION_DATA_KEYS.QUICK_ACTIONS] || [])]?.map(
        (ins) => ins.name
      ) || [];
    const payload = createPayloadForQuickAction(data);
    const { error } = createQuickActionValidation(
      edit ? [] : checkNameUnique
    ).validate(payload, {
      abortEarly: false,
    });
    if (!isEmpty(error)) {
      setIsLoading(false);
      const tempError = processErrorWithMessage(error.details);
      setErrorWithPathAsKey(tempError);
      return;
    }
    if (edit) {
      updateQuickAction(payload);
    } else {
      createQuickAction(payload);
    }
  };

  return (
    <div>
      <QuickActionTableViewWithSearch
        placeholder="Search"
        columns={COLUMNS_QUICK_ACTION({ onEdit, onDelete, onClone })}
        tableData={[
          ...(selectedTemplate?.[QUICK_ACTION_DATA_KEYS.QUICK_ACTIONS] || []),
        ]}
        searchKey="name"
        onRowClick={() => {}}
        label="Quick Action"
        addButtonClick={() => {
          setEdit(false);
          setOpenEditDrawer(true);
        }}
        showNoRecord
      />
      {openEditDrawer && (
        <EditQuickActionDrawer
          openDrawer={openEditDrawer}
          setOpenDrawer={setOpenEditDrawer}
          selectedRowData={selectedRowData}
          onSaveData={addQuickAction}
          errorWithPathAsKey={errorWithPathAsKey}
          setErrorWithPathAsKey={setErrorWithPathAsKey}
          isLoading={isLoading}
          clearSelectedData={setSelectedRowData}
        />
      )}
    </div>
  );
};

export default QuickAction;
