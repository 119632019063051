import React from 'react';
import { Tooltip } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const CustomToolTip = ({ toolTipText, placement, children, id }) => (
  <Tooltip data-testid={id} placement={placement} hasArrow label={toolTipText}>
    {children}
  </Tooltip>
);

CustomToolTip.propTypes = {
  placement: PropTypes.string,
  toolTipText: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

CustomToolTip.defaultProps = {
  placement: 'right-start',
};

export default CustomToolTip;
