import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';
// import CustomButton from '../Button/SimpleButton';
import { LABELS } from '../../utils/enums/labels';
import { DangerCustomButton, PrimaryCustomButton, SecondaryCustomButton } from '../Button/PrimarySecondaryButton';

const CustomModalFooterWithDelete = ({
  onCancelClick,
  onAddClick,
  onDeleteCllick,
  btnLabel,
  isDisabled,
  pt,
}) => (
  <Flex
    pt={pt}
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    width="100%"
    data-testid="CustomModalFooterWithDeleteFlex"
  >
    <Box>
      <DangerCustomButton
        isDisabled={false}
        buttonText="Delete"
        onClick={onDeleteCllick}
        id="CustomModalFooterWithDelete"
      />
    </Box>
    <Flex gap="3">
      <SecondaryCustomButton
        buttonText={LABELS.ACTIONS.CANCEL}
        onClick={onCancelClick}
        id="CustomModalFooterWithDeleteCancel"
        padding="0px 25px"
      />
      <PrimaryCustomButton
        isDisabled={isDisabled}
        buttonText={btnLabel}
        onClick={onAddClick}
        id="CustomModalFooterWithDeleteBtnLabel"
      />
    </Flex>
  </Flex>
);
CustomModalFooterWithDelete.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onDeleteCllick: PropTypes.func.isRequired,
  btnLabel: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  pt: PropTypes.string,
};
CustomModalFooterWithDelete.defaultProps = {
  pt: '1rem',
};
export default CustomModalFooterWithDelete;
