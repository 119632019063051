/* eslint-disable import/prefer-default-export */
const checkIsStatusReferenced = (status, template) => {
  if (status === undefined || template === undefined) {
    return '';
  }

  const statusLabel = status?.data?.label;
  const statusId = status?.id;

  const references = [];

  const fieldReferences = [];
  if (template?.fields) {
    template?.fields?.forEach((field) => {
      if ((JSON.stringify(field.permissions?.expression) || '').includes(statusLabel || statusId)) {
        fieldReferences.push(field.label);
      }
    });

    if (fieldReferences.length > 0) {
      references.push(`Fields - (${fieldReferences.join(', ')})`);
    }
  }

  if (template?.metric) {
    const metricReferences = [];
    template?.metric?.forEach((metric) => {
      if ((JSON.stringify(metric) || '').includes(statusLabel) ||
        (JSON.stringify(metric) || '').includes(statusId)) {
        metricReferences.push(metric.name);
      }
    });

    if (metricReferences.length > 0) {
      references.push(`Metrics - (${metricReferences.join(', ')})`);
    }
  }

  if (template?.slas) {
    const slaReferences = [];
    Object.keys(template?.slas)?.forEach((metric) => {
      template.slas[metric].forEach((sla) => {
        if ((JSON.stringify(sla) || '').includes(statusLabel) ||
          (JSON.stringify(sla) || '').includes(statusId)) {
          slaReferences.push(sla.name);
        }
      });
    });

    if (slaReferences.length > 0) {
      references.push(`SLAs - (${slaReferences.join(', ')})`);
    }
  }

  if (template?.events?.events) {
    const eventReferences = [];
    template?.events?.events?.forEach((event) => {
      if ((JSON.stringify(event) || '').includes(statusLabel) ||
        (JSON.stringify(event) || '').includes(statusId)) {
        eventReferences.push(event.event_name);
      }
    });

    if (eventReferences.length > 0) {
      references.push(`Events - (${eventReferences.join(', ')})`);
    }
  }

  if (template?.events?.actions) {
    const actionReferences = [];
    template?.events?.actions?.forEach((action) => {
      if ((JSON.stringify(action) || '').includes(statusLabel) ||
        (JSON.stringify(action) || '').includes(statusId)) {
        actionReferences.push(action.action_name);
      }
    });
    if (actionReferences.length > 0) {
      references.push(`Actions - (${actionReferences.join(', ')})`);
    }
  }

  if (template?.filters) {
    const filterReferences = [];
    template?.filters.forEach((filter) => {
      if ((JSON.stringify(filter) || '').includes(statusLabel) ||
        (JSON.stringify(filter) || '').includes(statusId)) {
        filterReferences.push(filter.name);
      }
    });
    if (filterReferences.length > 0) {
      references.push(`Filters - (${filterReferences.join(', ')})`);
    }
  }

  if (template?.ticket_routing) {
    const routingReferences = [];
    template?.ticket_routing.forEach((route) => {
      if ((JSON.stringify(route) || '').includes(statusLabel) ||
        (JSON.stringify(route) || '').includes(statusId)) {
        routingReferences.push(template.fields?.find(
          (field) => field.id === route.queue_field_id).label
        );
      }
    });
    if (routingReferences.length > 0) {
      references.push(`Routes - (${routingReferences.join(', ')})`);
    }
  }

  return references.join(', ');
};
function checkRankInStatus(nodes, rank) {
  const ranks = nodes.map((node) => node?.data?.rank || undefined)
    .filter((rankValue) => rankValue !== undefined);
  const rankCount = ranks.filter((rankValue) => rankValue === rank).length;
  return {
    isPresent: rankCount > 0,
    isDuplicate: rankCount > 1
  };
}

export {
  checkIsStatusReferenced,
  checkRankInStatus
};
