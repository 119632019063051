import { useCallback, useRef, useLayoutEffect } from 'react';

const useEventCallback = (fn) => {
  const callbackRef = useRef(() => {
    throw new Error('Cannot call an event handler while rendering.');
  });

  useLayoutEffect(() => {
    callbackRef.current = fn;
  }, [fn]);

  const stableCallback = useCallback(
    (...args) => callbackRef.current(...args),
    [callbackRef]
  );

  return stableCallback;
};

export default useEventCallback;
