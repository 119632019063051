import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store/Store';
import App from './App';
import './index.scss';
import { ThemeProvider } from './context/ThemeContext';
/* tailwind css work on css or scss so we can't change index.scss to index.modular.scss */

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <ThemeProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ThemeProvider>
  </Provider>
);
