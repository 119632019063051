import React from 'react';
import PropTypes from 'prop-types';
// import { useDispatch, useSelector } from 'react-redux';
import { LABELS } from '../../../utils/enums/labels';
import RoleContent from './RoleContent';
import { CustomDrawer } from '../../TicketTemplateBuilder/ConfigureTemplateView/AddEditTemplateLayoutModal';
// import CustomModalFooter from '../CreateEditFlow/CustomModalFooter';
// import { updateRuleModalId } from '../../../redux/WorkFlowRuleSlice/WorkFlowRuleReducer';

const AddNewRoleModal = ({
  isAddRole,
  setIsAddRole,
  // roleModalOpen,
  handleRuleModalOpen,
  addedRules,
}) => (
  // const dispatch = useDispatch();
  // const { ruleModalId } = useSelector((state) => state.workFlowRules);
  <CustomDrawer 
      id="selected-field-modal"
      drawerBody={
      <RoleContent
        handleRuleModalOpen={handleRuleModalOpen}
        addedRules={addedRules}
      />
    } 
      modalTitle={LABELS.ACTIONS.ADD_RULE}
      openDrawer={isAddRole} 
      setOpenDrawer={setIsAddRole}
      />
);
AddNewRoleModal.propTypes = {
  isAddRole: PropTypes.bool.isRequired,
  setIsAddRole: PropTypes.func.isRequired,
  // roleModalOpen: PropTypes.string.isRequired,
  handleRuleModalOpen: PropTypes.func.isRequired,
  addedRules: PropTypes.object,
};

AddNewRoleModal.defaultProps = {
  addedRules: null,
};

export default AddNewRoleModal;
